import React from 'react';
import { useTranslation } from 'react-i18next';
import { deleteText } from '../../../i18n/keys';
import './FileImagePreview.scss';
import closeIcon from '../../../assets/icons/close-circle.svg';
import attachmentIcon from '../../../assets/icons/attachment-icon.svg';
import classNames from 'classnames';

const FileImagePreview = ({ item, onDeleteClicked, preview }) => {
  const { t } = useTranslation();

  const disablePropagation = (e) => {
    e.stopPropagation();
  };

  function getImageUrl() {
    return item.objectUrl ? item.objectUrl : item.url;
  }

  return (
    <div className="file-image-preview" onClick={disablePropagation}>
      {preview && (
        <div className="file-image-preview-image-container">
          <img
            src={
              item.file.type.startsWith('image')
                ? getImageUrl()
                : attachmentIcon
            }
            onError={(event) => {
              event.target.src = item.file.type.startsWith('image')
                ? item.url
                : attachmentIcon;
              event.onerror = null;
            }}
            alt={item.name}
            className={classNames({
              icon: true,
              'icon--attachment': !item.file.type.startsWith('image'),
            })}
          />
          <div className="delete-icon">
            <img
              src={closeIcon}
              alt={t(deleteText)}
              onClick={() => onDeleteClicked(item)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileImagePreview;
