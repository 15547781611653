import React from 'react';
import './RefundDetailPopup.scss';

import BottomSheet from '../../../../../common/BottomSheet/BottomSheet';
import RefundTimeline from '../RefundTimeline';
import { Table } from 'antd';
import PriceLabel from '../../../../../common/currency/priceLabel';

import Image from '../../../../../common/Image';
import CoinIcon from '../../../../../../assets/icons/coin-icon.svg';
import InfoMessage from '../InfoMessage';
import {
  bankRefNoText,
  claimLastModifiedDateMillaText,
  claimLastModifiedDateTrText,
  estimatedRefundDateText,
  productImageText,
  refundAmountText,
  refundDetailText,
} from '../../../../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import { MILLA_APP_NAME } from '../../../../../../constants/chatAssistantConstant';
import AppService from '../../../../../../services/app.service';
import classNames from 'classnames';

const RefundDetailPopup = ({ claimDetails, visible, handleClose }) => {
  const { t } = useTranslation();
  const isMultipleRow = (row) => row?.coin && row.refundAmount;
  const isFullCoin = (row) => row?.coin && !row.refundAmount;

  const rowRenderer = (row, callback) => {
    if (!callback) return;

    if (isFullCoin(row)) {
      return <>{callback(row.coin)}</>;
    } else if (isMultipleRow(row)) {
      return (
        <>
          <>{callback(row, true)}</>
          <>{callback(row.coin)}</>
        </>
      );
    }
    return callback(row);
  };

  const columns = [
    {
      title: t(productImageText),
      dataIndex: 'productImageUrl',
      width: 80,
      align: 'center',
      render: (_, row) => {
        return (
          <div className="image-wrapper">
            <div className="image">
              <Image src={row.productImageUrl || '-'} alt="product-image" />
            </div>
          </div>
        );
      },
    },
    {
      title: t(refundAmountText),
      dataIndex: '',
      width: 100,
      align: 'center',
      render: (_, row) => {
        const amountRow = ({
          rowItem,
          isMultipleRow = false,
          isCoin = false,
        }) => (
          <div
            className={classNames('total-refund-wrapper', {
              'multiple-row': isMultipleRow,
            })}>
            {!!rowItem.refundAmount && (
              <div className="refund-currency">
                <PriceLabel
                  currency={rowItem.currency}
                  price={rowItem.refundAmount}
                />
                {isCoin && !!rowItem.refundQuantity && (
                  <div>{`(${rowItem.refundQuantity} Coin)`}</div>
                )}
              </div>
            )}
            {!!rowItem.refundTargetImageUrl && !isCoin && (
              <>
                <div className="refund-target-image">
                  <Image
                    isHorizantolFallback
                    src={rowItem.refundTargetImageUrl}
                    alt="bank-image"
                  />
                </div>
                {!!rowItem.maskedCardNumber && (
                  <span className="card-no">{rowItem.maskedCardNumber}</span>
                )}
              </>
            )}
            {isCoin && (
              <>
                <div className="refund-target-image">
                  <Image isHorizantolFallback src={CoinIcon} alt="bank-image" />
                </div>
                <span className="card-no">Coin</span>
              </>
            )}
            {!!rowItem.refundTargetText && (
              <span>{rowItem.refundTargetText}</span>
            )}
          </div>
        );

        if (isFullCoin(row)) {
          return <>{amountRow({ rowItem: row.coin, isCoin: true })}</>;
        } else if (isMultipleRow(row)) {
          return (
            <>
              <>{amountRow({ rowItem: row, isMultipleRow: true })}</>
              <>{amountRow({ rowItem: row.coin, isCoin: true })}</>
            </>
          );
        }
        return amountRow({ rowItem: row });
      },
    },
    {
      title:
        AppService.getAppName() === MILLA_APP_NAME
          ? t(claimLastModifiedDateMillaText)
          : t(claimLastModifiedDateTrText),
      dataIndex: 'claimLastModifiedDate',
      width: 110,
      align: 'center',
      render: (_, row) => {
        const claimLastModifiedDateRow = (rowItem, isMultipleRow = false) => {
          return (
            <span
              className={classNames('', {
                'multiple-row': isMultipleRow,
              })}>
              {rowItem.claimLastModifiedDate || '-'}
            </span>
          );
        };
        return rowRenderer(row, claimLastModifiedDateRow);
      },
    },
    {
      title: t(estimatedRefundDateText),
      dataIndex: 'estimatedClaimDate',
      width: 110,
      align: 'center',
      render: (_, row) => {
        const estimatedClaimDate = (rowItem, isMultipleRow = false) => {
          return (
            <span
              className={classNames('estimated-refund-date', {
                'multiple-row': isMultipleRow,
              })}>
              {rowItem.estimatedClaimDate || '-'}
            </span>
          );
        };
        return rowRenderer(row, estimatedClaimDate);
      },
    },
    {
      title: t(bankRefNoText),
      dataIndex: 'referenceNumber',
      width: 115,
      align: 'center',
      render: (_, row) => {
        const referenceNumber = (rowItem, isMultipleRow = false) => {
          return (
            <span
              className={classNames('', {
                'multiple-row': isMultipleRow,
              })}>
              {rowItem.referenceNumber || '-'}
            </span>
          );
        };

        return rowRenderer(row, referenceNumber);
      },
    },
  ];

  return (
    <BottomSheet
      isOpen={visible}
      title={t(refundDetailText)}
      className="refund-detail-bottom-sheet"
      onClose={handleClose}
      onCloseButtonClick={handleClose}>
      <div className="refund-detail-content">
        <RefundTimeline
          showLabel
          isLarge
          progressInfo={{
            status: claimDetails[0]?.status,
            companyImageUrl: claimDetails[0]?.companyImageUrl,
          }}
        />

        <Table
          className="refund-detail-table"
          columns={columns}
          dataSource={claimDetails}
          bordered
          pagination={false}
          scroll={{
            y: 190,
            x: 300,
          }}
        />
        <InfoMessage message={claimDetails[0]?.info} />
      </div>
    </BottomSheet>
  );
};

export default RefundDetailPopup;
