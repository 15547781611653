import {
  DEV,
  PLATFORM_ANDROID,
  PLATFORM_IOS,
  PLATFORM_MWEB,
  PLATFORM_WEB,
} from '../constants';
import {
  APP_VERSION,
  ASSISTANT_SCROLL_POSITION,
  BUILD_NAME,
  CALCULATED_STATE_MACHINE_ID,
  CHANNEL_ID,
  CHANNEL_ID_WITHOUT_DEFAULT,
  CHAT_BOT_HISTORY,
  CLASSIFIED_SELLER_CHANNEL_ID,
  CLICKED_TOPIC_EVENT,
  COURIER_CHANNEL_ID,
  COURIER_SEGMENT_ID,
  CURRENT_PAGE,
  CUSTOM_FIELDS,
  CUSTOMER_ORDER_CHANNEL_ID,
  DEFAULT_CHANNEL_ID,
  DEFAULT_LANGUAGE,
  DEFAULT_STOREFRONTID,
  DELIVERED_PRODUCT_PROBLEM_IMAGE,
  DODO_CHANNEL_ID,
  DOLAP_CUSTOMER_CHANNEL_ID,
  EVENT_NAME,
  GENERIC_EVENT_NAME,
  INSTANT_ORDER_CHANNEL_ID,
  INT_CUSTOMER_CHANNEL_ID,
  INT_SELLER_CHANNEL_ID,
  INTERNATIONAL_CUSTOMER_INFO,
  INTERNATIONAL_MAIL_PAGE_OPENED,
  IS_ASSISTANT_EXIT_BUTTON,
  IS_EVENT_CHANGED,
  IS_GUEST_USER,
  IS_INTERNATIONAL,
  IS_SURVEY_OPEN,
  LANGUAGE,
  LAST_CHAT_TOPIC,
  LIVE_CHAT_CLICKED,
  LIVE_CHAT_CONFIG,
  LIVECHAT_SCROLL_POSITION,
  MEAL_ORDER_CHANNEL_ID,
  MEDIA_CENTER_TOKEN,
  MENU_TYPE,
  PREV_STATE_MACHINE_ID,
  ORDER_CHANNEL_ID,
  ORDER_ID,
  PID_NAME,
  PLATFORM_NAME,
  REFRESH_TOKEN_SELLER,
  RETURN_PAGE,
  SEGMENT_ID,
  SELLER_CHANNEL_ID,
  SELLER_GROCERY_PICKER_APP,
  SELLER_SINGLE_USE_TOKEN,
  SELLER_STICKY_ASSISTANT,
  SHIPMENT_NUMBER,
  SID_NAME,
  START_REQUEST,
  START_REQUEST_FOR_EVENT_CHANGE,
  STATE_MACHINE_ID,
  STOREFRONT_ID,
  SUB_CHANNEL,
  TOKEN_NAME,
  VERSION,
  COUNTRY_CODE,
  ASSISTANT_PROPERTIES,
  APP_NAME,
  ASSISTANT_ID,
  LC_MEMBER,
  ORDER_NUMBER,
  CLIENT_CORRELATION_ID,
  SEARCH_LOCATION,
  CHAT_BOT_URL,
  CLICKED_LAST_EVENT_FOR_SURVEY,
} from '../constants/sessionStorageConstants';
import {
  ANDROID_INSTANT_EXIT_ASSISTANT_URL,
  ANDROID_MEAL_EXIT_ASSISTANT_URL,
  CDN_BASE_URL,
  CHANNEL_BASED_CLOSE_ASSISTANT_ANDROID_BUILD_NAME,
  CLOSE_ASSISTANT_ANDROID_BUILD_NAME,
  CLOSE_ASSISTANT_IOS_BUILD_NAME,
  COUNTRY_CODE_AZ,
  COURIER_EXIT_URL,
  CUSTOMER_COUNTRY_CODE_PROPERTY_KEY,
  DEFAULT_POPUP_WAITING_TIME_IN_SECONDS,
  DEFAULT_UPLOAD_SIZE,
  ENGLISH_SUPPORT_VERSION_1,
  EXIT_ASSISTANT_URL,
  EXIT_CLASSIFIED_SELLER_URL,
  EXIT_DODO_URL,
  EXIT_DOLAP_ASSISTANT_URL,
  EXIT_INTERNATIONAL_ASSISTANT_SELLER_URL,
  EXIT_INTERNATIONAL_ASSISTANT_URL,
  GET_INTERNATIONAL_SEND_EMAIL_RESULT,
  GET_SAVE_SURVEY_RESULT_FOR_CHAT_BOT,
  GET_SHIPMENT_DETAIL,
  GET_SURVEY_OPEN_FOR_CHAT_BOT,
  INSTANT_EXIT_URL,
  LANGUAGE_CODE_EN,
  MEAL_EXIT_URL,
  MILLA_APP_NAME,
  ORDER_BOX_VERSION_1,
  MEAL_SELLER_APP_EXIT_URL,
  SELLER_EXIT_URL,
  SELLER_PICKER_EXIT_URL,
  TGO_APP_NAME,
  TRUE,
  TY_EXIT_URL,
  UPLOAD_VERSION_1,
  MEAL_SELLER_APP_NAME,
} from '../constants/chatAssistantConstant';
import utils from '../utils';
import Utils from '../utils';
import chatBotService from './chatbot.service';
import {
  changeUrlEvent,
  openLinkEvent,
  sendPartnerSSOEvent,
} from '@seller/gilgamesh-web-utils';
import chatGatewayService from '../services/chatgateway.service';
import stickyClientAdapter from '../clientAdapter/stickyClientAdapter';
import {
  STYLING_MAP,
  DYNAMIC_STYLING_CHANNELS,
} from '../constants/assistantStylingConstants';
import { replaceWithCurrentDomain } from './env.service';
import { v4 as uuidv4 } from 'uuid';

class AppService {
  static async setTokenAndStartRequestForCustomerSticky() {
    return new Promise((resolve, reject) => {
      stickyClientAdapter
        .getCookieFromTopHost(['COOKIE_TY.Entrance', 'claimItems'])
        .then((cookieValues) => {
          if (!!cookieValues) {
            if (!!cookieValues.get('COOKIE_TY.Entrance')) {
              const tokenWithKey = cookieValues
                .get('COOKIE_TY.Entrance')
                .split('&')
                .find((row) => row.startsWith('tx='));

              const token = tokenWithKey.split('=')[1];
              this.setToken(token);
            }

            if (!!cookieValues.get('claimItems')) {
              if (
                this.getStartRequestForEventChange() !==
                cookieValues.get('claimItems')
              ) {
                this.setIsEventChanged(true);
              }

              this.setStartRequestForEventChange(
                cookieValues.get('claimItems')
              );

              this.setStartRequest(
                Buffer.from(cookieValues.get('claimItems'), 'base64')
              );

              stickyClientAdapter.removeCookieFromTopHost([
                {
                  cookieKey: 'claimItems',
                  path: '/',
                  domain: replaceWithCurrentDomain(
                    process.env.REACT_APP_TRENDYOL_CUSTOMER_COOKE_DOMAIN
                  ),
                },
              ]);
            }
          }
          resolve();
        })
        .catch(() => reject(new Error('Error saving the userInfo')));
    });
  }

  static async setAsyncToken(token) {
    return new Promise((resolve, reject) => {
      this.setToken(token);
      resolve();
    });
  }

  static isSegmentCourier() {
    let segmentId = this.getSegmentId();
    return segmentId === COURIER_SEGMENT_ID;
  }

  static async setCurrentPage(current_page) {
    window.customStorage.setItem(CURRENT_PAGE, current_page);
  }

  static getCurrentPage() {
    return window.customStorage.getItem(CURRENT_PAGE);
  }

  static setPlatform(platform) {
    window.customStorage.setItem(PLATFORM_NAME, platform);
  }

  static setSellerSingleUseToken(singleUseToken) {
    window.customStorage.setItem(SELLER_SINGLE_USE_TOKEN, singleUseToken);
  }

  static getSellerSingleUseToken() {
    return window.customStorage.getItem(SELLER_SINGLE_USE_TOKEN);
  }

  static getPlatform() {
    return window.customStorage.getItem(PLATFORM_NAME);
  }

  static setChannelId(channelId) {
    window.customStorage.setItem(CHANNEL_ID, channelId);
  }

  static setChannelIdWithoutDefault(channelId) {
    window.customStorage.setItem(CHANNEL_ID_WITHOUT_DEFAULT, channelId);
  }

  static getIsStickyAssistant() {
    return window.customStorage.getItem(SELLER_STICKY_ASSISTANT);
  }

  static setIsStickyAssistant(isStickyAssistant) {
    window.customStorage.setItem(SELLER_STICKY_ASSISTANT, isStickyAssistant);
  }

  static getIsSellerGroceryPickerApp() {
    return window.customStorage.getItem(SELLER_GROCERY_PICKER_APP);
  }

  static getIsMealSellerApp() {
    return (
      this.getAppName() === MEAL_SELLER_APP_NAME &&
      this.getOrderChannelId() === MEAL_ORDER_CHANNEL_ID
    );
  }

  static getReturnPage() {
    let returnPage = window.customStorage.getItem(RETURN_PAGE);
    if (returnPage) {
      return returnPage;
    } else {
      return 'Orders';
    }
  }

  static setReturnPage(returnPage) {
    window.customStorage.setItem(RETURN_PAGE, returnPage);
  }

  static setAppName(appName) {
    window.customStorage.setItem(APP_NAME, appName);
  }

  static getAppName() {
    let appName = window.customStorage.getItem(APP_NAME);
    if (!appName) {
      return '';
    }
    return appName;
  }

  static setLcMember(lcMember) {
    window.customStorage.setItem(LC_MEMBER, lcMember);
  }

  static getLcMember() {
    let lcMember = window.customStorage.getItem(LC_MEMBER);
    if (!lcMember) {
      return '';
    }
    return lcMember;
  }

  static setIsSellerGroceryPickerApp(isSellerGroceryPickerApp) {
    window.customStorage.setItem(
      SELLER_GROCERY_PICKER_APP,
      isSellerGroceryPickerApp
    );
  }

  static getAssistantScrollPosition() {
    let scrollPosition = window.customStorage.getItem(
      ASSISTANT_SCROLL_POSITION
    );
    if (!scrollPosition) {
      return 0;
    }
    return scrollPosition;
  }

  static setAssistantScrollPosition(scrollPosition) {
    window.customStorage.setItem(ASSISTANT_SCROLL_POSITION, scrollPosition);
  }

  static getLiveChatScrollPosition() {
    let scrollPosition = window.customStorage.getItem(LIVECHAT_SCROLL_POSITION);
    if (!scrollPosition) {
      return 0;
    }
    return scrollPosition;
  }

  static setLiveChatScrollPosition(scrollPosition) {
    window.customStorage.setItem(LIVECHAT_SCROLL_POSITION, scrollPosition);
  }

  static removeChannelId() {
    sessionStorage.removeItem(CHANNEL_ID);
  }

  static getChannelId() {
    let channelId = window.customStorage.getItem(CHANNEL_ID);
    if (!channelId) {
      channelId = DEFAULT_CHANNEL_ID;
    }
    return channelId;
  }

  static setSubChannel(subChannel) {
    window.customStorage.setItem(SUB_CHANNEL, subChannel);
  }

  static getSubChannel() {
    let subChannel = window.customStorage.getItem(SUB_CHANNEL);
    if (!subChannel) {
      subChannel = '';
    }
    return subChannel;
  }

  static getChannelIdWithoutDefault() {
    return window.customStorage.getItem(CHANNEL_ID_WITHOUT_DEFAULT);
  }

  static getSegmentId() {
    let segmentId = window.customStorage.getItem(SEGMENT_ID);
    if (
      null === segmentId ||
      'null' === segmentId ||
      undefined === segmentId ||
      'undefined' === segmentId
    ) {
      return '';
    }
    return window.customStorage.getItem(SEGMENT_ID);
  }

  static setSegmentId(segmentId) {
    window.customStorage.setItem(SEGMENT_ID, segmentId);
  }

  static setEventName(eventName) {
    window.customStorage.setItem(EVENT_NAME, eventName);
  }

  static getEventName() {
    return window.customStorage.getItem(EVENT_NAME);
  }

  static setChatBotUrl(chatBotUrl) {
    window.customStorage.setItem(CHAT_BOT_URL, chatBotUrl);
  }

  static getChatBotUrl() {
    let chatBotUrl = window.customStorage.getItem(CHAT_BOT_URL);
    if (
      null === chatBotUrl ||
      'null' === chatBotUrl ||
      undefined === chatBotUrl ||
      'undefined' === chatBotUrl
    ) {
      return '';
    }
    return chatBotUrl;
  }

  static setGenericEventName(genericEventName) {
    genericEventName && this.setEventName('GET_GENERIC_STATE');
    window.customStorage.setItem(GENERIC_EVENT_NAME, genericEventName);
  }

  static getGenericEventName() {
    return window.customStorage.getItem(GENERIC_EVENT_NAME);
  }

  static setMenuType(menuType) {
    window.customStorage.setItem(MENU_TYPE, menuType);
  }

  static getMenuType() {
    return window.customStorage.getItem(MENU_TYPE);
  }

  static getBuildName() {
    return window.customStorage.getItem(BUILD_NAME);
  }

  static setBuildName(buildName) {
    window.customStorage.setItem(BUILD_NAME, buildName);
  }

  static getAppVersion() {
    return window.customStorage.getItem(APP_VERSION);
  }

  static setAppVersion(appVersion) {
    window.customStorage.setItem(APP_VERSION, appVersion);
  }

  static getCountryCode() {
    let countryCode = window.customStorage.getItem(COUNTRY_CODE);
    if (
      null === countryCode ||
      'null' === countryCode ||
      undefined === countryCode ||
      'undefined' === countryCode
    ) {
      return '';
    }
    return countryCode;
  }

  static setCountryCode(countryCode) {
    window.customStorage.setItem(COUNTRY_CODE, countryCode);
  }

  static setStateMachineId(stateMachineId) {
    window.customStorage.setItem(STATE_MACHINE_ID, stateMachineId);
  }

  static getStateMachineId() {
    return window.customStorage.getItem(STATE_MACHINE_ID);
  }

  static setSearchLocation(search) {
    window.customStorage.setItem(SEARCH_LOCATION, search);
  }

  static getSearchLocation() {
    return window.customStorage.getItem(SEARCH_LOCATION);
  }

  static setClientCorrelationId() {
    window.customStorage.setItem(CLIENT_CORRELATION_ID, uuidv4());
  }

  static getClientCorrelationId() {
    if (!window.customStorage.getItem(CLIENT_CORRELATION_ID)) {
      this.setClientCorrelationId();
    }
    return window.customStorage.getItem(CLIENT_CORRELATION_ID);
  }

  static setPrevStateMachineId(stateMachineId) {
    window.customStorage.setItem(PREV_STATE_MACHINE_ID, stateMachineId);
  }

  static getPrevStateMachineId() {
    return window.customStorage.getItem(PREV_STATE_MACHINE_ID);
  }

  static setVersion(version) {
    window.customStorage.setItem(VERSION, version);
  }

  static getVersion() {
    return window.customStorage.getItem(VERSION);
  }

  static setProperties(version) {
    window.customStorage.setItem(ASSISTANT_PROPERTIES, version);
  }

  static getProperties() {
    return window.customStorage.getItem(ASSISTANT_PROPERTIES);
  }

  static setLastChatTopic(lastChatTopic) {
    window.customStorage.setItem(LAST_CHAT_TOPIC, lastChatTopic);
  }

  static getLastChatTopic() {
    return window.customStorage.getItem(LAST_CHAT_TOPIC);
  }

  static getLanguage() {
    let language = window.customStorage.getItem(LANGUAGE);
    if (!language) {
      language = DEFAULT_LANGUAGE;
    }
    return language;
  }

  static setLanguage(language) {
    window.customStorage.setItem(LANGUAGE, language);
  }

  static isRtl() {
    let language = window.customStorage.getItem(LANGUAGE);
    if (!language) {
      language = DEFAULT_LANGUAGE;
    }
    return language === 'ar';
  }

  static setStorefrontId(storefrontid) {
    window.customStorage.setItem(STOREFRONT_ID, storefrontid);
  }

  static getStoreFrontId() {
    if (window.customStorage.getItem(STOREFRONT_ID)) {
      return window.customStorage.getItem(STOREFRONT_ID);
    } else {
      return DEFAULT_STOREFRONTID;
    }
  }

  static setToken(token) {
    window.customStorage.setItem(TOKEN_NAME, token);
  }

  static setVerificationTokensToCookie(tokens) {
    if (tokens.accessToken !== '' && tokens.refreshToken !== '') {
      this.setRefreshTokenSeller(tokens.refreshToken);
      this.setSellerAccessToken(tokens.accessToken);
    } else {
      return null;
    }
  }

  static setSellerAccessToken(accessToken) {
    let decodedJWT = utils.decodeJWT(accessToken);
    const expireTime = new Date(
      decodedJWT.exp * 1000 - 60 * 1000
    ).toUTCString(); // a minute early for network latency

    const accessTokenSeller = {
      accessTokenSeller: accessToken,
      expireTime: expireTime,
    };

    this.setToken(accessToken);
    window.customStorage.setItem(
      'access_token_seller',
      JSON.stringify(accessTokenSeller)
    );
  }

  static setRefreshTokenSeller(refreshToken) {
    window.sessionStorage.setItem(REFRESH_TOKEN_SELLER, refreshToken);
  }

  static setMediaCenterToken(refreshToken) {
    window.sessionStorage.setItem(MEDIA_CENTER_TOKEN, refreshToken);
  }

  static getMediaCenterToken() {
    return window.sessionStorage.getItem(MEDIA_CENTER_TOKEN);
  }

  static isSingleTokenRequest(qString) {
    return qString.singleUseToken !== undefined;
  }

  static getSellerRefreshToken() {
    return window.sessionStorage.getItem(REFRESH_TOKEN_SELLER);
  }

  static getToken() {
    let token = window.customStorage.getItem(TOKEN_NAME);
    return token === 'undefined' || token === 'null' || token === null
      ? ''
      : token;
  }

  static setDeliveredProductProblemImage(image) {
    window.customStorage.setItem(DELIVERED_PRODUCT_PROBLEM_IMAGE, image);
  }

  static clearDeliveredProductProblemImage() {
    window.customStorage.setItem(DELIVERED_PRODUCT_PROBLEM_IMAGE, '');
  }

  static getDeliveredProductProblemImage() {
    return window.customStorage.getItem(DELIVERED_PRODUCT_PROBLEM_IMAGE);
  }

  static getPid() {
    if (process.env.REACT_APP_ENV === DEV) {
      return 'test-env-pid';
    }
    let pid = window.customStorage.getItem(PID_NAME);
    if (
      null === pid ||
      'null' === pid ||
      undefined === pid ||
      'undefined' === pid
    ) {
      return '';
    }
    return pid;
  }

  static setPid(pid) {
    window.customStorage.setItem(PID_NAME, pid);
  }

  static getSid() {
    if (process.env.REACT_APP_ENV === DEV) {
      return 'test-env-sid';
    }
    let sid = window.customStorage.getItem(SID_NAME);
    if (
      null === sid ||
      'null' === sid ||
      undefined === sid ||
      'undefined' === sid
    ) {
      return '';
    }
    return window.customStorage.getItem(SID_NAME);
  }

  static setSid(sid) {
    window.customStorage.setItem(SID_NAME, sid);
  }

  static getShipmentNumber() {
    return window.customStorage.getItem(SHIPMENT_NUMBER);
  }

  static setShipmentNumber(shipmentNumber) {
    window.customStorage.setItem(SHIPMENT_NUMBER, shipmentNumber);
  }

  static getOrderNumber() {
    const orderNumber = window.customStorage.getItem(ORDER_NUMBER);
    if (orderNumber) {
      return orderNumber.toString();
    }
    return null;
  }

  static setOrderNumber(orderNumber) {
    window.customStorage.setItem(ORDER_NUMBER, orderNumber);
  }

  static getOrderId() {
    return window.customStorage.getItem(ORDER_ID);
  }

  static setOrderId(orderId) {
    window.customStorage.setItem(ORDER_ID, orderId);
  }

  static processSingleUseToken = (singleUseToken) => {
    return chatGatewayService.getVerificationTokens(singleUseToken);
  };

  static liveChatConfig = () => {
    return chatGatewayService.getLiveChatConfig();
  };

  static assistantConfig = async () => {
    return chatGatewayService.getAssistantConfig();
  };

  static navigateOnParentHost(deepLinkUrl) {
    if (process.env.REACT_APP_ENV === DEV) {
      window.open(
        `${process.env.REACT_APP_TRENDYOL_URL}${deepLinkUrl}`,
        '_blank'
      );
    } else if (this.getIsStickyAssistant()) {
      if (
        deepLinkUrl.includes(this.getReferrerDomain()) ||
        deepLinkUrl.startsWith('/')
      ) {
        this.openUrlInSameTab(deepLinkUrl);
      } else {
        this.openUrlInNewTab(deepLinkUrl);
      }
    } else {
      //Android, IOS
      this.navigateDeepLinkOnMobile(deepLinkUrl);
    }
  }

  static getReferrerDomain() {
    const url = document.createElement('a');
    url.href = document.referrer;
    return url.hostname;
  }

  static navigateDeepLinkOnMobile(deepLinkUrl) {
    let channelId = this.getChannelId();
    if (
      channelId === SELLER_CHANNEL_ID &&
      this.getIsSellerGroceryPickerApp() !== true &&
      this.getIsMealSellerApp() !== true
    ) {
      const url = new URL(deepLinkUrl);

      if (url.hostname.includes(process.env.REACT_APP_PARTNER_DOMAIN)) {
        sendPartnerSSOEvent({ redirectPath: url.pathname, inApp: true });
      } else {
        if (deepLinkUrl.includes(CDN_BASE_URL)) {
          openLinkEvent(deepLinkUrl);
        } else {
          changeUrlEvent(deepLinkUrl);
        }
      }
    } else {
      window.open(deepLinkUrl, '_blank');
    }
  }

  static getIsAssistantExitButtonEnabled() {
    let isEnabled = window.customStorage.getItem(IS_ASSISTANT_EXIT_BUTTON);
    return isEnabled === 'undefined' ||
      isEnabled === 'null' ||
      isEnabled === null ||
      isEnabled === undefined
      ? TRUE
      : isEnabled;
  }

  static setIsAssistantExitButtonEnabled(isAssistantExitButtonEnabled) {
    window.customStorage.setItem(
      IS_ASSISTANT_EXIT_BUTTON,
      isAssistantExitButtonEnabled
    );
  }

  static exitAssistant = async () => {
    let channelId = await this.getChannelId();
    let orderChannelId = this.getOrderChannelId();

    if (AppService.getIsStickyAssistant()) {
      AppService.closeStickyPopup();
    } else if (
      this.getAppName() === TGO_APP_NAME ||
      this.getAppName() === MILLA_APP_NAME
    ) {
      window.location.href = this.getExitDeeplink();
    } else if (channelId === COURIER_CHANNEL_ID) {
      window.location.href = COURIER_EXIT_URL;
    } else if (channelId === SELLER_CHANNEL_ID) {
      if (this.getIsMealSellerApp()) {
        window.location.href = MEAL_SELLER_APP_EXIT_URL;
      } else {
        if (AppService.getIsSellerGroceryPickerApp()) {
          window.location.href = SELLER_PICKER_EXIT_URL;
        } else {
          window.location.href = SELLER_EXIT_URL;
        }
      }
    } else if (channelId === INT_CUSTOMER_CHANNEL_ID) {
      window.location.href = EXIT_INTERNATIONAL_ASSISTANT_URL;
    } else if (channelId === INT_SELLER_CHANNEL_ID) {
      window.location.href = EXIT_INTERNATIONAL_ASSISTANT_SELLER_URL;
    } else if (channelId === DOLAP_CUSTOMER_CHANNEL_ID) {
      window.location.href =
        EXIT_DOLAP_ASSISTANT_URL + AppService.getReturnPage();
    } else if (channelId === DODO_CHANNEL_ID) {
      window.location.href = EXIT_DODO_URL;
    } else if (channelId === CLASSIFIED_SELLER_CHANNEL_ID) {
      window.location.href = EXIT_CLASSIFIED_SELLER_URL;
    } else {
      let buildName =
        this.getBuildName() &&
        utils.buildNameParserForTrendyolApp(this.getBuildName());
      let platform = this.getPlatform();
      if (
        platform === PLATFORM_ANDROID &&
        buildName >= CHANNEL_BASED_CLOSE_ASSISTANT_ANDROID_BUILD_NAME
      ) {
        if (MEAL_ORDER_CHANNEL_ID === orderChannelId) {
          window.location.href = ANDROID_MEAL_EXIT_ASSISTANT_URL;
        } else if (INSTANT_ORDER_CHANNEL_ID === orderChannelId) {
          window.location.href = ANDROID_INSTANT_EXIT_ASSISTANT_URL;
        } else {
          window.location.href = EXIT_ASSISTANT_URL;
        }
      } else if (
        platform === PLATFORM_ANDROID &&
        buildName >= CLOSE_ASSISTANT_ANDROID_BUILD_NAME
      ) {
        window.location.href = EXIT_ASSISTANT_URL;
      } else if (
        platform === PLATFORM_IOS &&
        buildName >= CLOSE_ASSISTANT_IOS_BUILD_NAME
      ) {
        if (MEAL_ORDER_CHANNEL_ID === orderChannelId) {
          window.location.href = MEAL_EXIT_URL;
        } else if (INSTANT_ORDER_CHANNEL_ID === orderChannelId) {
          window.location.href = INSTANT_EXIT_URL;
        } else {
          window.location.href = EXIT_ASSISTANT_URL;
        }
      } else {
        if (MEAL_ORDER_CHANNEL_ID === orderChannelId) {
          window.location.href = MEAL_EXIT_URL;
        } else if (INSTANT_ORDER_CHANNEL_ID === orderChannelId) {
          window.location.href = INSTANT_EXIT_URL;
        } else if (CUSTOMER_ORDER_CHANNEL_ID === orderChannelId) {
          window.location.href = EXIT_ASSISTANT_URL;
        } else {
          window.location.href = TY_EXIT_URL;
        }
      }
    }
  };

  static setOrderChannelId = async (orderChannelId) => {
    window.customStorage.setItem(ORDER_CHANNEL_ID, orderChannelId);
  };

  static getOrderChannelId = () => {
    let orderChannelId = window.customStorage.getItem(ORDER_CHANNEL_ID);
    if (
      {} === orderChannelId ||
      null === orderChannelId ||
      'null' === orderChannelId ||
      undefined === orderChannelId ||
      'undefined' === orderChannelId
    ) {
      return null;
    }
    return parseInt(orderChannelId);
  };

  static setStartRequest = async (startRequest) => {
    window.customStorage.setItem(START_REQUEST, startRequest);
  };

  static getStartRequest = () => {
    let claimItems = window.customStorage.getItem(START_REQUEST);

    if ({} === claimItems || claimItems == null) {
      return null;
    }
    return claimItems;
  };

  static setStartRequestForEventChange = async (startRequest) => {
    window.customStorage.setItem(START_REQUEST_FOR_EVENT_CHANGE, startRequest);
  };

  static getStartRequestForEventChange = () => {
    return window.customStorage.getItem(START_REQUEST_FOR_EVENT_CHANGE);
  };

  static async isChatBotSurveyWillOpen() {
    if (!this.getStateMachineId()) {
      return {};
    }

    const request = {
      event: GET_SURVEY_OPEN_FOR_CHAT_BOT,
    };

    let response = await chatBotService.execute(request);
    return new Promise((resolve, reject) => {
      response && response.data && response.data
        ? resolve(response.data)
        : reject(response);
    });
  }

  static async sendChatBotSurvey(rate, comment) {
    const request = {
      event: GET_SAVE_SURVEY_RESULT_FOR_CHAT_BOT,
      data: {
        rate: rate,
        comment: comment,
      },
    };
    let response = await chatBotService.execute(request);
    return new Promise(async (resolve, reject) => {
      response && response.data && response.data.data
        ? resolve(response.data.data)
        : reject(response);
    });
  }

  static async sendInternationalEmail(sendEmailData) {
    const request = {
      event: GET_INTERNATIONAL_SEND_EMAIL_RESULT,
      data: sendEmailData,
    };
    let response = await chatBotService.execute(request);
    return new Promise(async (resolve, reject) => {
      response && response.data && response.data.succeed
        ? resolve(response.data.succeed)
        : reject(response);
    });
  }

  static setIsLiveChatDeepLinkClicked(isClicked) {
    window.customStorage.setItem(LIVE_CHAT_CLICKED, isClicked);
  }

  static getIsLiveChatDeepLinkClicked() {
    return window.customStorage.getItem(LIVE_CHAT_CLICKED);
  }

  static setIsSurveyOpen(isOpen) {
    window.customStorage.setItem(IS_SURVEY_OPEN, isOpen);
  }

  static getIsSurveyOpen() {
    return window.customStorage.getItem(IS_SURVEY_OPEN);
  }

  static getIsEventChanged() {
    return window.customStorage.getItem(IS_EVENT_CHANGED);
  }

  static setIsEventChanged(isEventChanged) {
    window.customStorage.setItem(IS_EVENT_CHANGED, isEventChanged);
  }

  static setClickedTopicEvent(event) {
    window.customStorage.setItem(CLICKED_TOPIC_EVENT, event);
  }

  static getClickedTopicEvent() {
    return window.customStorage.getItem(CLICKED_TOPIC_EVENT);
  }

  static setClickedLastEventForSurvey(event) {
    window.customStorage.setItem(CLICKED_LAST_EVENT_FOR_SURVEY, event);
  }

  static getClickedLastEventForSurvey() {
    return window.customStorage.getItem(CLICKED_LAST_EVENT_FOR_SURVEY);
  }

  static minimizeStickyPopup = async () => {
    window.top.postMessage('trendyolAssistantStickyMinButtonClick', '*');
  };

  static clearAndCloseStickyAfterLogout() {
    if (
      this.getToken() &&
      this.getIsStickyAssistant() &&
      this.getChannelIdWithoutDefault() === DEFAULT_CHANNEL_ID
    ) {
      stickyClientAdapter
        .getCookieFromTopHost(['COOKIE_TY.Entrance', 'claimItems'])
        .then((cookieValues) => {
          if (!!cookieValues.get('COOKIE_TY.Entrance')) {
            const tokenWithKey = cookieValues
              .get('COOKIE_TY.Entrance')
              .split('&')
              .find((row) => row.startsWith('tx='));

            const token = tokenWithKey.split('=')[1];

            if (token !== this.getToken()) {
              this.closeStickyPopup();
            }
          }
        });
    }
  }

  static openFile(url) {
    window.top.postMessage(
      {
        event_id: 'trendyolAssistantShowDocumentClick',
        data: {
          url: url,
        },
      },
      '*'
    );
  }

  static openUrlInNewTab(url) {
    window.top.postMessage(
      {
        event_id: 'trendyolAssistantOpenUrlInNewTabClick',
        data: {
          url: url,
        },
      },
      '*'
    );
  }

  static openUrlInSameTab(url) {
    window.top.postMessage(
      {
        event_id: 'trendyolAssistantOpenUrlClick',
        data: {
          url: url,
        },
      },
      '*'
    );
  }

  static closeStickyPopup() {
    sessionStorage.clear();
    window.top.postMessage(
      'trendyolAssistantStickyCloseAssistantButtonClick',
      '*'
    );
  }

  static sendSecureKeyToParentHost(secureKey) {
    if (
      this.getPlatform() === PLATFORM_ANDROID &&
      utils.ifFunctionExistsOnNativeAndroidInterface('saveChatKey')
    ) {
      window.NativeAndroidInterface.saveChatKey(secureKey); // Android listens this event
    }
  }

  static getChatBotHistory() {
    return window.customStorage.getItem(CHAT_BOT_HISTORY);
  }

  static isInternational() {
    return window.customStorage.getItem(IS_INTERNATIONAL);
  }

  static setLiveChatConfig(liveChatConfig) {
    window.customStorage.setItem(LIVE_CHAT_CONFIG, liveChatConfig);
  }

  static getLiveChatConfig() {
    return window.customStorage.getItem(LIVE_CHAT_CONFIG);
  }

  static setAssistantId(liveChatConfig) {
    window.customStorage.setItem(ASSISTANT_ID, liveChatConfig);
  }

  static getAssistantId() {
    let assistantId = window.customStorage.getItem(ASSISTANT_ID);
    if (!assistantId) {
      assistantId = '';
    }
    return assistantId;
  }

  static englishSupport() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const segmentId = this.getSegmentId();
    const orderChannelId = this.getOrderChannelId();
    const selectedOrderChannelId = !orderChannelId ? 1 : orderChannelId;

    let isEnabled = false;
    let version = ENGLISH_SUPPORT_VERSION_1;

    configMap.get('assistantFlags').forEach((assistantFlagConfig) => {
      if (
        segmentId === assistantFlagConfig.segmentId &&
        selectedOrderChannelId === assistantFlagConfig.orderChannelId
      ) {
        isEnabled = assistantFlagConfig.isEnglishSupportEnabled;
        if (assistantFlagConfig.englishSupportVersion) {
          version = assistantFlagConfig.englishSupportVersion;
        }
      }
    });

    return { isEnabled, version };
  }

  static getLanguageByCountryCode(countryCode) {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const countryCodeLanguageMap =
      configMap.get('commonFlags').countryCodeLanguageMap || {};

    const isExists = countryCode in countryCodeLanguageMap;

    const { language = null, languageOptions = null } =
      countryCodeLanguageMap[countryCode] || {};

    return { isExists, language, languageOptions };
  }

  static getLanguageByBlockedLanguage(sourceLanguage) {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const blockedLanguageMap =
      configMap.get('commonFlags').blockedLanguageMap || {};

    const isExists = sourceLanguage in blockedLanguageMap;

    const targetLanguage = blockedLanguageMap[sourceLanguage];

    return { isExists, targetLanguage };
  }

  static getShipmentCountryInfoProperties() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    let showShipmentCountryInfo = false;
    const currentLanguage = this.getLanguage();
    let languageOptions = null;
    const eventName = this.getEventName();
    const appName = this.getAppName();
    const millaConfig = configMap.get('milla');

    const properties = this.getProperties();
    if (!properties) {
      return {
        showShipmentCountryInfo: false,
        currentLanguage,
        languageOptions,
      };
    }

    const propertiesMap = new Map(Object.entries(this.getProperties()));
    if (
      this.getChannelId() === DEFAULT_CHANNEL_ID &&
      GET_SHIPMENT_DETAIL !== eventName &&
      LANGUAGE_CODE_EN !== currentLanguage &&
      (!millaConfig.isMillaAppSeperated || appName !== MILLA_APP_NAME) &&
      (COUNTRY_CODE_AZ === this.getCountryCode() ||
        COUNTRY_CODE_AZ ===
          propertiesMap.get(CUSTOMER_COUNTRY_CODE_PROPERTY_KEY))
    ) {
      const countryCodeLanguageInfo =
        this.getLanguageByCountryCode(COUNTRY_CODE_AZ);

      if (countryCodeLanguageInfo) {
        languageOptions = countryCodeLanguageInfo.languageOptions;
      }

      if (languageOptions && languageOptions.length > 0) {
        showShipmentCountryInfo = true;
      }
    }

    return { showShipmentCountryInfo, currentLanguage, languageOptions };
  }

  static isProactivePageEnabled() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const segmentId = this.getSegmentId();
    const orderChannelId = this.getOrderChannelId();
    const selectedOrderChannelId = !orderChannelId ? 1 : orderChannelId;

    let isProactivePageEnabled = false;
    configMap.get('assistantFlags').forEach((assistantFlagConfig) => {
      if (
        segmentId === assistantFlagConfig.segmentId &&
        selectedOrderChannelId === assistantFlagConfig.orderChannelId
      ) {
        const proactivePopup = assistantFlagConfig.proactivePopup;
        isProactivePageEnabled =
          proactivePopup?.enabled &&
          proactivePopup?.supportedLanguages?.includes(this.getLanguage());
      }
    });

    return isProactivePageEnabled;
  }

  static getExitDeeplink() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const segmentId = this.getSegmentId();
    const orderChannelId = this.getOrderChannelId();
    const selectedOrderChannelId = !orderChannelId ? 1 : orderChannelId;
    const platform = this.getPlatform();
    const appName = this.getAppName();

    let exitDeeplinkResult = '';
    configMap.get('assistantFlags').forEach((assistantFlagConfig) => {
      if (
        segmentId === assistantFlagConfig.segmentId &&
        selectedOrderChannelId === assistantFlagConfig.orderChannelId
      ) {
        const exitDeeplinks = assistantFlagConfig.exitDeeplink;
        if (exitDeeplinks) {
          exitDeeplinks.forEach((exitDeeplink) => {
            if (
              platform === exitDeeplink.platform &&
              appName === exitDeeplink.appName
            ) {
              exitDeeplinkResult = exitDeeplink.url;
            }
          });
        }
      }
    });

    return exitDeeplinkResult;
  }

  static waitingTimeToOpenPopUpInMilliseconds() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const segmentId = this.getSegmentId();
    const orderChannelId = this.getOrderChannelId();
    const selectedOrderChannelId = !orderChannelId ? 1 : orderChannelId;

    let waitingTimeToOpenPopUp = DEFAULT_POPUP_WAITING_TIME_IN_SECONDS;

    configMap.get('assistantFlags').forEach((assistantFlagConfig) => {
      if (
        segmentId === assistantFlagConfig.segmentId &&
        selectedOrderChannelId === assistantFlagConfig.orderChannelId &&
        assistantFlagConfig.waitingTimeToOpenPopUp
      ) {
        waitingTimeToOpenPopUp = assistantFlagConfig.waitingTimeToOpenPopUp;
      }
    });

    return waitingTimeToOpenPopUp * 1000;
  }

  static getArabicAllowedStorefronts() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const menaConfig = configMap.get('mena');

    if (!menaConfig.isArabicInputActive) {
      return [];
    }

    return menaConfig.arabicInputAllowedStorefronts;
  }

  static getClaimUploadTargetVersion() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const segmentId = this.getSegmentId();
    const orderChannelId = this.getOrderChannelId();

    let claimUploadTargetVersion = UPLOAD_VERSION_1;

    configMap.get('assistantFlags').forEach((assistantFlagConfig) => {
      if (
        segmentId === assistantFlagConfig.segmentId &&
        (!orderChannelId ||
          orderChannelId === assistantFlagConfig.orderChannelId)
      ) {
        claimUploadTargetVersion = assistantFlagConfig.claimUploadTargetVersion;
      }
    });

    return claimUploadTargetVersion;
  }

  static getUploadSizeLimitByFileType(fileType) {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const isGuest = this.isGuestUser() === true;

    let uploadSizeLimit = DEFAULT_UPLOAD_SIZE;

    configMap.get('commonFlags').fileUploadSize.forEach((commonFlagConfig) => {
      if (
        commonFlagConfig.fileType === fileType &&
        commonFlagConfig.isGuest === isGuest
      ) {
        uploadSizeLimit = commonFlagConfig.size;
      }
    });

    return uploadSizeLimit;
  }

  static isOrderBoxEnabled() {
    if (this.isGuestUser()) {
      return false;
    }

    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const segmentId = this.getSegmentId();
    const orderChannelId = this.getOrderChannelId();

    let isOrderBoxEnabled = false;

    configMap.get('liveChatFlags').forEach((liveChatFlagsConfig) => {
      if (
        segmentId === liveChatFlagsConfig.segmentId &&
        (!orderChannelId ||
          orderChannelId === liveChatFlagsConfig.orderChannelId)
      ) {
        isOrderBoxEnabled = liveChatFlagsConfig.isOrderBoxEnabled;
      }
    });

    return isOrderBoxEnabled;
  }

  static getOrderBoxVersion() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const segmentId = this.getSegmentId();
    const orderChannelId = this.getOrderChannelId();
    const selectedOrderChannelId = !orderChannelId ? 1 : orderChannelId;

    let orderBoxVersion = ORDER_BOX_VERSION_1;

    configMap.get('liveChatFlags').forEach((liveChatFlagsConfig) => {
      if (
        segmentId === liveChatFlagsConfig.segmentId &&
        selectedOrderChannelId === liveChatFlagsConfig.orderChannelId &&
        liveChatFlagsConfig.orderBoxVersion
      ) {
        orderBoxVersion = liveChatFlagsConfig.orderBoxVersion;
      }
    });

    return orderBoxVersion;
  }

  static isLiveChatV2Enabled() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const segmentId = this.getSegmentId();
    const tmpOrderChannelId = this.getOrderChannelId();
    const orderChannelId = !tmpOrderChannelId ? 1 : tmpOrderChannelId;
    return configMap
      .get('liveChatFlags')
      .find(
        (survey) =>
          segmentId === survey.segmentId &&
          orderChannelId === survey.orderChannelId
      )?.isLiveChatSurveyV2Enabled;
  }

  static checkChatBotSurveyV2Enabled() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const segmentId = this.getSegmentId();
    const tmpOrderChannelId = this.getOrderChannelId();
    const orderChannelId = !tmpOrderChannelId ? 1 : tmpOrderChannelId;
    return configMap
      .get('assistantFlags')
      .find(
        (survey) =>
          segmentId === survey.segmentId &&
          orderChannelId === survey.orderChannelId
      )?.isChatBotSurveyV2Enabled;
  }

  static getAcceptedLiveChatFileTypes() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const segmentId = this.getSegmentId();
    const assistantId = this.getAssistantId();
    const tmpOrderChannelId = this.getOrderChannelId();
    const orderChannelId = !tmpOrderChannelId ? 1 : tmpOrderChannelId;

    const fileUpload = configMap
      .get('liveChatFlags')
      .find(
        (survey) =>
          assistantId === survey.assistantId ||
          (segmentId === survey.segmentId &&
            orderChannelId === survey.orderChannelId)
      )?.fileUpload;

    if (!fileUpload) {
      return false;
    }

    if (this.isGuestUser() === true) {
      return false;
    }

    let isBuildEligible = this.isBuildEligible(fileUpload.minBuildName);

    if (!isBuildEligible) {
      return false;
    }

    return fileUpload.acceptedFileTypes;
  }

  static isBuildEligible(minBuildNames) {
    const compareVersions = (version1, version2) => {
      const v1Parts = version1.split('.').map(Number);
      const v2Parts = version2.split('.').map(Number);
      const maxLength = Math.max(v1Parts.length, v2Parts.length);

      for (let i = 0; i < maxLength; i++) {
        const part1 = i < v1Parts.length ? v1Parts[i] : 0;
        const part2 = i < v2Parts.length ? v2Parts[i] : 0;

        if (part1 > part2) return true;
        if (part1 < part2) return false;
      }

      return true;
    };

    if (
      this.getPlatform() === PLATFORM_IOS &&
      minBuildNames &&
      minBuildNames.ios
    ) {
      return compareVersions(this.getBuildName(), minBuildNames.ios);
    }

    if (
      this.getPlatform() === PLATFORM_ANDROID &&
      minBuildNames &&
      minBuildNames.android
    ) {
      return compareVersions(this.getBuildName(), minBuildNames.android);
    }

    return true;
  }

  static getReConnectWaitingSeconds() {
    const DEFAULT_WAITING_SECOND = 15;
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const segmentId = this.getSegmentId();
    const tmpOrderChannelId = this.getOrderChannelId();
    const orderChannelId = !tmpOrderChannelId ? 1 : tmpOrderChannelId;
    const reConnectWaitingSeconds = configMap
      .get('liveChatFlags')
      .find(
        (survey) =>
          segmentId === survey.segmentId &&
          orderChannelId === survey.orderChannelId
      )?.reConnectWaitingSeconds;
    return reConnectWaitingSeconds
      ? reConnectWaitingSeconds
      : DEFAULT_WAITING_SECOND;
  }

  static getLiveChatSystemJoinedMessage() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const segmentId = this.getSegmentId();
    const lang = this.getLanguage();
    const appName = this.getAppName();

    let message = '';

    configMap
      .get('echoActiveUserSegments')
      .forEach((echoActiveUserSegmentConfig) => {
        if (segmentId === echoActiveUserSegmentConfig.segmentId)
          echoActiveUserSegmentConfig.systemJoinedMessages.forEach(
            (systemJoinedMessage) => {
              const messageAppName = systemJoinedMessage.appName
                ? systemJoinedMessage.appName
                : '';

              if (
                lang === systemJoinedMessage.lang &&
                appName === messageAppName
              ) {
                message = systemJoinedMessage.message;
              }
            }
          );
      });

    return message;
  }

  static getLiveChatExitModalMessage() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const segmentId = this.getSegmentId();
    const lang = this.getLanguage();

    let message = '';

    configMap
      .get('echoActiveUserSegments')
      .forEach((echoActiveUserSegmentConfig) => {
        if (
          segmentId === echoActiveUserSegmentConfig.segmentId &&
          echoActiveUserSegmentConfig.exitModalMessages !== undefined
        )
          echoActiveUserSegmentConfig.exitModalMessages.forEach(
            (exitModalMessage) => {
              if (lang === exitModalMessage.lang) {
                message = exitModalMessage.message;
              }
            }
          );
      });

    return message;
  }

  static getAssistantSurveyMessage() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));
    const segmentId = this.getSegmentId();
    const lang = this.getLanguage();
    const appName = this.getAppName();

    let message = '';

    configMap
      .get('echoActiveUserSegments')
      .forEach((echoActiveUserSegmentConfig) => {
        if (
          segmentId === echoActiveUserSegmentConfig.segmentId &&
          echoActiveUserSegmentConfig.assistantSurveyMessages !== undefined
        )
          echoActiveUserSegmentConfig.assistantSurveyMessages.forEach(
            (assistantSurveyMessage) => {
              const messageAppName = assistantSurveyMessage.appName
                ? assistantSurveyMessage.appName
                : '';

              if (
                lang === assistantSurveyMessage.lang &&
                appName === messageAppName
              ) {
                message = assistantSurveyMessage.message;
              }
            }
          );
      });

    return message;
  }

  static setInternational(value) {
    window.customStorage.setItem(IS_INTERNATIONAL, value);
  }

  static setInternationalCustomerInfo(value) {
    window.customStorage.setItem(INTERNATIONAL_CUSTOMER_INFO, value);
  }

  static getInternationalCustomerInfo() {
    return window.customStorage.getItem(INTERNATIONAL_CUSTOMER_INFO);
  }

  static isAuthenticatedInternationalUser() {
    return this.isInternational() && !this.isGuestUser();
  }

  static setIsGuestUser(value) {
    window.customStorage.setItem(IS_GUEST_USER, value);
  }

  static isGuestUser() {
    return window.customStorage.getItem(IS_GUEST_USER);
  }

  static setIsInternationalSendEmailOpened(value) {
    window.customStorage.setItem(INTERNATIONAL_MAIL_PAGE_OPENED, value);
  }

  static isInternationalSendEmailOpened() {
    return window.customStorage.getItem(INTERNATIONAL_MAIL_PAGE_OPENED);
  }

  static canAddCredentials() {
    return (
      this.isInternational() ||
      (this.getChannelId() === DEFAULT_CHANNEL_ID &&
        this.getPlatform() === PLATFORM_MWEB &&
        this.getOrderChannelId() === CUSTOMER_ORDER_CHANNEL_ID) ||
      this.getChannelId() === SELLER_CHANNEL_ID
    );
  }

  static setEchoCustomFields(customFields) {
    window.customStorage.setItem(CUSTOM_FIELDS, customFields);
  }

  static getEchoCustomFields() {
    return window.customStorage.getItem(CUSTOM_FIELDS);
  }

  static isDynamicStylingChannel() {
    return DYNAMIC_STYLING_CHANNELS.includes(
      this.getChannelId() || DEFAULT_CHANNEL_ID
    );
  }

  static isPlatformWeb() {
    return this.getPlatform() === PLATFORM_WEB;
  }

  static getDynamicStyleValue(key) {
    if (STYLING_MAP.get(this.getAppName())) {
      return STYLING_MAP.get(this.getAppName())[key];
    }

    if (STYLING_MAP.get(this.getChannelId())) {
      return STYLING_MAP.get(this.getChannelId())[key];
    }
    return STYLING_MAP.get(DEFAULT_CHANNEL_ID)[key];
  }

  static getInternationalMailSubjects() {
    const configMap = new Map(Object.entries(this.getLiveChatConfig()));

    return configMap.get('internationalMailSubjects');
  }

  static setCalculatedStateMachineId(calculatedStateMachineId) {
    window.customStorage.setItem(
      CALCULATED_STATE_MACHINE_ID,
      calculatedStateMachineId
    );
  }

  static getCalculatedStateMachineId() {
    let stateMachineId = this.getStateMachineId();
    if (stateMachineId) {
      return stateMachineId;
    }

    let calculatedStateMachineId = window.customStorage.getItem(
      CALCULATED_STATE_MACHINE_ID
    );
    if (!calculatedStateMachineId) {
      calculatedStateMachineId = Utils.uuid();
      this.setCalculatedStateMachineId(calculatedStateMachineId);
      return calculatedStateMachineId;
    }

    return calculatedStateMachineId;
  }
}

export default AppService;
