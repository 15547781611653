import React from 'react';
import LeftArrow from '../leftArrow';
import RightArrow from '../rightArrow';
import classNames from 'classnames';
import AppService from '../../../services/app.service';
import './ListArrows.scss';

const ListArrows = ({
  prevDisable,
  nextDisable,
  handleClickRight,
  handleClickLeft,
}) => {
  return (
    <>
      {!prevDisable && (
        <LeftArrow
          handleClickLeft={
            AppService.isRtl() ? handleClickRight : handleClickLeft
          }
          className={classNames({ rtl: AppService.isRtl() })}
        />
      )}
      {!nextDisable && (
        <RightArrow
          handleClickRight={
            AppService.isRtl() ? handleClickLeft : handleClickRight
          }
          className={classNames({ rtl: AppService.isRtl() })}
        />
      )}
    </>
  );
};
export default ListArrows;
