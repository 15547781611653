import React, { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import * as PropTypes from 'prop-types';
import './StaticText.scss';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
import { ReactComponent as DownArrow } from '../../../assets/icons/chevron-down.svg';
import { ReactComponent as UpArrow } from '../../../assets/icons/chevron-up.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { showLessContent, showMoreContent } from '../../../i18n/keys';

const StaticText = ({
  componentData,
  scrollToBottom,
  isRenderedFromOutside = false,
  setShouldResizeIframe = () => {},
}) => {
  const { t } = useTranslation();

  const messages = componentData.message;
  const renderAsHTML = componentData.renderHtml;
  const showMoreEnabled = componentData.showMore;

  const [isExpanded, setIsExpanded] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const virtualDivRef = useRef();
  const showMoreEnableHeight = 250;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (isRenderedFromOutside) {
      setShouldResizeIframe(true);
    }
  };

  useEffect(() => {
    if (isExpanded && !isRenderedFromOutside) {
      scrollToBottom(100);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (showMoreEnabled) {
      const contentHeight = calculateContentHeight();
      if (contentHeight > showMoreEnableHeight) {
        setShowMore(true);
        if (!isRenderedFromOutside) {
          scrollToBottom(100, contentHeight - showMoreEnableHeight);
        }

        const virtualDivDom =
          document.getElementsByClassName('virtual-div')?.[0];

        if (virtualDivDom) document.body.removeChild(virtualDivDom);
      }
    }
  }, []);

  const calculateContentHeight = () => {
    const virtualDiv = document.createElement('div');
    virtualDiv.innerHTML = renderAsHTML
      ? renderHtml(messages)
      : renderToString(renderContent());
    virtualDivRef.current = virtualDiv;
    virtualDiv.className = 'virtual-div';
    virtualDiv.style.position = 'absolute';
    virtualDiv.style.visibility = 'hidden';
    virtualDiv.style.top = '0px';
    document.body.appendChild(virtualDiv);
    return virtualDiv.offsetHeight;
  };

  const renderHtml = (data) => {
    const sanitizedHTML = DOMPurify.sanitize(data);
    return sanitizedHTML;
  };

  const renderContent = () => {
    return messages.split('\r\n').map((item, i) => {
      if (item === '') {
        return <br />;
      }
      return (
        <p key={i} className="text" data-testid="message-text">
          <ReactMarkdown children={item} />
        </p>
      );
    });
  };

  return (
    <div className="static-text" data-testid="static-text">
      <div className="container">
        <div className="content-wrapper">
          <div className="bubble">
            <div className="message">
              <div
                className={classNames({
                  content: true,
                  'content--expanded': isExpanded && showMore,
                  'content--collapsed': !isExpanded && showMore,
                  'content--noShowMore': !showMore,
                })}>
                {renderAsHTML ? (
                  <div
                    className={classNames({
                      htmlArea: true,
                      'htmlArea--expanded': isExpanded && showMore,
                      'htmlArea--collapsed': !isExpanded && showMore,
                    })}
                    dangerouslySetInnerHTML={{ __html: renderHtml(messages) }}
                  />
                ) : (
                  <>{renderContent()}</>
                )}
              </div>
              {showMore && (
                <div onClick={toggleExpand} className="show-more">
                  <div className="toggle">
                    {isExpanded ? (
                      <>
                        {t(showLessContent)} <UpArrow />
                      </>
                    ) : (
                      <>
                        {t(showMoreContent)} <DownArrow />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StaticText.propTypes = {
  componentData: PropTypes.object.isRequired,
};

export default React.memo(StaticText);
