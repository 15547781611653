import './MobileSelect.scss';

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import closeIcon from '../../../assets/icons/close-v2.svg';
import Radio from '@tx-components/radio';
import { PLATFORM_ANDROID, PLATFORM_IOS } from '../../../constants';
import { closeButtonText, selectOrderMsg } from '../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';

const MobileSelect = ({
  type = PLATFORM_ANDROID,
  items = [],
  value,
  onChange,
  label,
  keyValue = 'value',
  textValue = 'label',
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const [internalValue, setInternalValue] = useState(value);

  const handleSelectItem = () => {
    onChange(internalValue);
    onClose();
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    if (type === PLATFORM_ANDROID && internalValue !== value)
      onChange(internalValue);
    //eslint-disable-next-line
  }, [internalValue]);

  return createPortal(
    <div
      className={classNames('mobile-select-backdrop', {
        active: isOpen,
        android: type === PLATFORM_ANDROID,
        ios: type === PLATFORM_IOS,
      })}
      onClick={onClose}>
      <div className="bottom-modal" onClick={(e) => e.stopPropagation()}>
        <div
          className="bottom-modal-header"
          justifyContent="space-between"
          alignItems="center">
          {/* {type === PLATFORM_IOS && (
            <button
              className="close-button"
              mode="link"
              color="#273142"
              onClick={onClose}>
              {t(closeButtonText)}
            </button>
          )} */}
          <span className="label">{label}</span>
          {/* {type === PLATFORM_IOS && (
            <button
              className="select-button"
              mode="link"
              color="#F27A1A"
              onClick={handleSelectItem}>
              {t(selectOrderMsg)}
            </button>
          )} */}
          {type === PLATFORM_ANDROID && (
            <button className="close-button" onClick={onClose}>
              <img src={closeIcon} width={14} height={14} alt="close" />
            </button>
          )}
        </div>
        {/* {type === PLATFORM_IOS && (
          <div column className="select-items">
            {items.map((item) => (
              <div
                className={classNames('select-item', {
                  active: item[keyValue] === internalValue,
                  disabled: !!item.disabled,
                })}
                key={item[keyValue]}
                onClick={() => setInternalValue(item[keyValue])}>
                {item[textValue]}
              </div>
            ))}
          </div>
        )} */}
        {type === PLATFORM_ANDROID && (
          <div column className="select-items">
            {items.map((item) => (
              <div
                className={classNames('select-item', {
                  active: item[keyValue] === internalValue,
                  disabled: !!item.disabled,
                })}
                key={item[keyValue]}
                onClick={() => {
                  internalValue && item[keyValue] === internalValue
                    ? setInternalValue()
                    : setInternalValue(item[keyValue]);
                }}>
                <Radio
                  checked={item[keyValue] === internalValue}
                  onChange={() =>
                    internalValue && item[keyValue] === internalValue
                      ? setInternalValue()
                      : setInternalValue(item[keyValue])
                  }
                  disabled={!!item.disabled}
                  readOnly
                />
                <span className="select-item-name">{item[textValue]}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>,
    document.getElementById('root')
  );
};

export default MobileSelect;
