import React, { useMemo } from 'react';
import './Ticket.scss';
import Swiper, { SwiperSlide } from '../../swiper/Swiper';
import { deliveryNumberText } from '../../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import usePersistentState from '../../../common/usePersistentState';
import classnames from 'classnames';
import ClaimInfo from '../../../../assets/icons/claim-info.svg';
import TicketItem from '../../ticketListing/TicketItem';

const Ticket = ({ data }) => {
  const { t } = useTranslation();
  const [showNavigation, setShowNavigation] = usePersistentState(
    'ticketId',
    'showNavigation',
    true
  );

  const { items } = data;
  const groupItems = useMemo(() => {
    const newItems = [];

    for (let i = 0; i < items.length; i++) {
      const groupIndex = Math.floor(i / 3);

      const innerIndex = i % 3;

      if (!newItems[groupIndex]) {
        newItems[groupIndex] = [];
      }

      newItems[groupIndex][innerIndex] = items[i];
    }
    setShowNavigation(newItems.length > 1);
    return newItems;
  }, [items]);

  return (
    <div
      className={classnames([
        'welcoming-ticket',
        { 'welcoming-ticket-without-swipe': !showNavigation },
      ])}>
      <Swiper showNavigation={showNavigation}>
        {groupItems &&
          groupItems.map((items) => {
            return (
              <SwiperSlide>
                {items.map((item, i) => {
                  return <TicketItem item={item} />;
                })}
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default Ticket;
