const PRIMARY_DOMAIN = 'trendyol.com';
const ALLOWED_DOMAINS = ['trendyol-milla.com', 'trendyolmilla.com.tr'];

export const replaceWithCurrentDomain = (inputURL) => {
  const currentDomain = ALLOWED_DOMAINS.find((domain) =>
    window.location.origin.includes(domain)
  );

  if (currentDomain) {
    return inputURL.replace(PRIMARY_DOMAIN, currentDomain);
  }

  return inputURL;
};
