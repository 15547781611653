import React, { useEffect, useState } from 'react';

const AutoTextArea = React.forwardRef((props, ref) => {
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const [parentHeight, setParentHeight] = useState('auto');

  useEffect(() => {
    if (ref.current) {
      if (props.message === '') {
        setParentHeight('36px');
        setTextAreaHeight('36px');
      } else {
        let height = ref.current.scrollHeight;
        setParentHeight(height <= 115 ? `${height}px` : '115px');
        setTextAreaHeight(height <= 115 ? `${height}px` : '115px');
      }
    }
  }, [props.message]);

  const onChangeHandler = (event) => {
    if (ref.current) {
      setTextAreaHeight('auto');
      let height = ref.current.scrollHeight;
      setParentHeight(height <= 115 ? `${height}px` : '115px');
      if (props.onChange) {
        props.onChange(event);
      }
    }
  };

  return (
    <div
      className={props.parentstyle}
      style={{
        minHeight: parentHeight,
        width: '100%',
        lineHeight: '1',
      }}>
      <textarea
        {...props}
        value={props.message}
        ref={ref}
        rows="1"
        style={{
          height: textAreaHeight,
        }}
        onKeyDown={props.onKeyDown}
        onChange={onChangeHandler}
      />
    </div>
  );
});

export default AutoTextArea;
