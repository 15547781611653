import './Timeline.scss';
import classNames from 'classnames';
import React, { useContext } from 'react';
import utils from '../../../utils';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import ReactMarkdown from 'react-markdown';
import appService from '../../../services/app.service';
import OperationButton from '../operationButton/OperationButton';
import Bubble from '../../layout/bubble/Bubble';
import TimelineCircle from './components/TimelineCircle';

const Timeline = ({ id, componentData }) => {
  const chatBotContext = useContext(ChatBotContext);
  const { requestForDeepLink } = chatBotContext;
  const { label, detail, deepLink, bubbleMessage } = componentData;

  function getCargoButtonComponentData() {
    return {
      buttons: [
        {
          text: deepLink.text,
          buttonType: 'Primary',
          iconUrl: deepLink.iconUrl,
          componentsId: null,
          genericStateEvent: null,
          outline: true,
          weight: 1,
        },
      ],
      hasDetail: false,
      label: deepLink.label,
    };
  }

  function onCargoLinkClicked() {
    return utils.handleClickDeeplink(
      deepLink.event,
      deepLink.eventLabel,
      deepLink.url,
      requestForDeepLink
    );
  }

  return (
    <div className="timeline">
      <Bubble title={label} isBottomRounded={false}>
        <div
          className={classNames({
            'timeline-wrapper': true,
            'timeline-wrapper--small': detail.progressBar.length > 4,
          })}>
          <div className="date-info-message">
            <span
              className={classNames({
                text: true,
                'no-date': detail.detailDate === '',
                international: appService.isInternational(),
              })}>
              {detail.detailLabel}:
            </span>
            {detail.detailDate && (
              <span className="value"> {detail.detailDate}</span>
            )}
          </div>
          <div className="caret" />
          <div
            className={classNames([
              'progress-type',
              'timeline-container',
              'progress-type-' + detail.progressType,
            ])}>
            <ul
              className={classNames('multi-steps', {
                international: appService.isInternational(),
              })}>
              {(detail?.progressBar || []).map((barInfo, i) => (
                <TimelineCircle
                  isActive={barInfo.processed}
                  text={barInfo.text}
                  date={barInfo.date}
                  isInternational={appService.isInternational()}
                  progressType={detail.progressType}
                  lastIsActive={
                    !!(
                      barInfo.processed &&
                      detail.progressBar[i + 1] &&
                      detail.progressBar[i + 1].processed === false
                    )
                  }
                  language={'language-' + appService.getLanguage()}
                />
              ))}
            </ul>
          </div>
          {detail.progressInfo?.length > 0 && (
            <>
              <div className="caret" />
              <div
                className={classNames({
                  'info-message ': true,
                })}>
                <img
                  className="alert-icon"
                  src={detail.infoMessageIconUrl}
                  alt="alert-icon"
                />
                <div>
                  <ReactMarkdown>{detail.progressInfo}</ReactMarkdown>
                </div>
              </div>
            </>
          )}
        </div>
      </Bubble>

      {bubbleMessage && (
        <Bubble
          className="operation-button-bubble"
          title={bubbleMessage}></Bubble>
      )}
      {deepLink && deepLink.url && (
        <OperationButton
          id={id}
          componentData={getCargoButtonComponentData()}
          onClick={() => onCargoLinkClicked()}
          showHover={true}
          showLabel={true}
        />
      )}
    </div>
  );
};

export default Timeline;
