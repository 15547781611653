import React, { useEffect } from 'react';
import Bubble from '../../../layout/bubble/Bubble';
import './WrongDelivered.scss';
import { useTranslation } from 'react-i18next';
import {
  branchNameText,
  wrongDeliveredPersonNameText,
} from '../../../../i18n/keys';
import classNames from 'classnames';
import usePersistentState from '../../../common/usePersistentState';

const WrongDelivered = ({ id, componentData, isSelectable = true, select }) => {
  const { t } = useTranslation();
  const { requestButtonData } = componentData;

  const [branchName, setBranchName] = usePersistentState(id, 'branchName', '');
  const [deliveredPerson, setDeliveredPerson] = usePersistentState(
    id,
    'deliveredPerson',
    ''
  );
  const [isDone, setIsDone] = usePersistentState(id, 'isDone', false);

  useEffect(() => {
    setIsDone(branchName && deliveredPerson);
  }, [branchName, deliveredPerson]);

  const onClickWrongDelivered = () => {
    if (isSelectable && isDone) {
      const requestPayload = {
        event: requestButtonData.event,
        data: {
          branchName: branchName,
          deliveredPersonName: deliveredPerson,
        },
      };
      select(requestPayload);
    }
  };
  return (
    <Bubble>
      <div
        className={classNames(['wrong-delivered', { disabled: !isSelectable }])}
        data-testid="wrong-delivered">
        <div className="wrong-delivered-row">
          <span className="text">{t(branchNameText)}</span>
          <span className="mandatory">*</span>
          <div className="input-area">
            <input
              data-testid="wrong-delivered-branch"
              type="text"
              value={branchName}
              className="input-bar"
              disabled={!isSelectable}
              onChange={(e) => setBranchName(e.target.value)}
            />
          </div>
        </div>
        <div className="wrong-delivered-row">
          <span className="text">{t(wrongDeliveredPersonNameText)}</span>
          <span className="mandatory">*</span>
          <div className="input-area">
            <input
              data-testid="wrong-delivered-person"
              type="text"
              value={deliveredPerson}
              className="input-bar"
              disabled={!isSelectable}
              onChange={(e) => setDeliveredPerson(e.target.value)}
            />
          </div>
        </div>
        <div className="wrong-delivered-row">
          <button
            data-testid="request-button"
            className={classNames([
              'request-button',
              {
                'request-button--active': isDone,
                'request-button--passive': !isDone,
              },
            ])}
            disabled={!isSelectable && !isDone}
            onClick={onClickWrongDelivered}>
            {requestButtonData.text}
          </button>
        </div>
      </div>
    </Bubble>
  );
};

export default WrongDelivered;
