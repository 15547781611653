import React from 'react';
import * as PropTypes from 'prop-types';
import './CancelSimulationPreview.scss';
import {
  orderImageLabel,
  productText,
  quantityText,
} from '../../../../i18n/keys';
import { useTranslation } from 'react-i18next';

const CancelSimulationPreviewItem = ({ componentData }) => {
  const { t } = useTranslation();

  const items = componentData;
  return (
    <div className="cancel-simulation-preview">
      <div className="content">
        <div className="body">
          {items.slice(0, 4).map((selectedItem, index) => {
            return index && index > 2 ? (
              <div key={index} className="order-item more-then">
                <div className="image-wrapper" data-testid="image-wrapper">
                  <div className="more-item">
                    <div className="text">
                      <span className="count" data-testid="product-count">
                        +{items.length - 3}
                      </span>{' '}
                      <span> {t(productText)}</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div key={index} className="order-item less-then">
                <div className="image-wrapper">
                  <img
                    className="image"
                    src={selectedItem.imageUrl}
                    alt={t(orderImageLabel)}
                  />
                </div>
                <div className="quantity" data-testid="product-quantity">
                  {selectedItem.quantity + ' ' + t(quantityText)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

CancelSimulationPreviewItem.propTypes = {
  componentData: PropTypes.array.isRequired,
};
export default CancelSimulationPreviewItem;
