import React from 'react';
import { useTranslation } from 'react-i18next';
import { currencyText } from '../../../i18n/keys';

const PriceLabel = ({ price, currency, isDiscount = false }) => {
  const { t } = useTranslation();
  return (
    <>
      {currency ? (
        <div>
          {isDiscount ? '-' : ''} {price} {currency}
        </div>
      ) : (
        <div>
          {isDiscount ? '-' : ''} {price} {t(currencyText)}
        </div>
      )}
    </>
  );
};

export default PriceLabel;
