import classNames from 'classnames';
import React, { useMemo } from 'react';
import './TimelineCircle.scss';

const TimelineCircleColorMap = {
  claimInfo: {
    international: {
      active: {
        innerColor: '#d7735f',
      },
    },
    notInternational: {
      active: {
        innerColor: '#9b0000',
      },
    },
  },
  shipmentInfo: {
    international: {
      active: {
        innerColor: '#22ab6d',
      },
    },
    notInternational: {
      active: {
        innerColor: '#0BC15C',
      },
    },
  },
  ticketInfo: {
    international: {
      active: {
        innerColor: '#22ab6d',
      },
    },
    notInternational: {
      active: {
        innerColor: '#0BC15C',
      },
    },
  },
};

const timelineProgressTypes = {
  shipmentInfo: 'shipmentInfo',
  claimInfo: 'claimInfo',
  ticketInfo: 'ticketInfo',
};

const hexWithOpacity = (hexColor) => {
  hexColor = hexColor.replace('#', '');

  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  const newR = Math.round(r * 0.6 + 255 * 0.4);
  const newG = Math.round(g * 0.6 + 255 * 0.4);
  const newB = Math.round(b * 0.6 + 255 * 0.4);

  const newHex = `#${newR.toString(16).padStart(2, '0')}${newG
    .toString(16)
    .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;

  return newHex;
};

const TimelineCircle = ({
  isActive = true,
  isInternational = false,
  progressType = timelineProgressTypes.shipmentInfo,
  text = '',
  date = '',
  lastIsActive = false,
  language = 'language-tr',
}) => {
  const circleColors = useMemo(() => {
    if (isActive) {
      if (progressType === timelineProgressTypes.shipmentInfo) {
        if (isInternational) {
          return TimelineCircleColorMap.shipmentInfo.international.active;
        } else {
          return TimelineCircleColorMap.shipmentInfo.notInternational.active;
        }
      } else if (progressType === timelineProgressTypes.ticketInfo) {
        if (isInternational) {
          return TimelineCircleColorMap.ticketInfo.international.active;
        } else {
          return TimelineCircleColorMap.ticketInfo.notInternational.active;
        }
      } else {
        if (isInternational) {
          return TimelineCircleColorMap.claimInfo.international.active;
        } else {
          return TimelineCircleColorMap.claimInfo.notInternational.active;
        }
      }
    } else {
      return {
        innerColor: '#FFFFFF',
        outerColor: '#C6C6C6',
      };
    }
  }, [isActive, isInternational, progressType]);

  return (
    <span
      className={classNames('timeline-item', {
        international: isInternational,
        'is-active': isActive,
        'last-is-active': lastIsActive,
        [progressType]: progressType,
        [language]: language,
      })}>
      <div className="radio-item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none">
          {isActive ? (
            <g filter={`url(#filter0_shadow)`}>
              <circle
                cx="13"
                cy="13"
                r="8"
                fill={isActive ? '#ffffff' : circleColors.outerColor}
              />
            </g>
          ) : (
            <circle
              cx="13"
              cy="13"
              r="8"
              fill={isActive ? '#ffffff' : circleColors.outerColor}
            />
          )}
          <circle cx="13" cy="13" r="5" fill={circleColors.innerColor} />
          <defs>
            <filter
              id="filter0_shadow"
              x="0"
              y="0"
              width="26"
              height="26"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feDropShadow
                dx="0"
                dy="0"
                stdDeviation="2.5"
                flood-color={hexWithOpacity(circleColors.innerColor)}
              />
            </filter>
          </defs>
        </svg>
      </div>
      <span className="text">{text}</span>
      {/*<span className="date">{date}</span>*/}
      {date && <span className="date">{date}</span>}
    </span>
  );
};

export default TimelineCircle;
