import { useEffect, useMemo } from 'react';
import { VALIDATION_RULE_TYPES } from '../../../constants/ratingSurveyConstants';

const useValidation = ({ rules, value, ref }) => {
  const requiredRule = useMemo(
    () =>
      rules?.find(
        (rule) => rule.type === VALIDATION_RULE_TYPES.required && rule.required
      ),
    [rules]
  );

  const maxRangeRule = useMemo(
    () => rules?.find((rule) => rule.type === VALIDATION_RULE_TYPES.max_range),
    [rules]
  );

  const minRangeRule = useMemo(
    () => rules?.find((rule) => rule.type === VALIDATION_RULE_TYPES.min_range),
    [rules]
  );

  const validation = useMemo(() => {
    if (requiredRule && !value) {
      return { isValid: false, message: requiredRule.message };
    }
    if (maxRangeRule && (value || '').length > maxRangeRule.max) {
      return { isValid: false, message: maxRangeRule.message };
    }
    if (minRangeRule && (value || '').length < minRangeRule.min) {
      return { isValid: false, message: minRangeRule.message };
    }

    return { isValid: true };
  }, [maxRangeRule, minRangeRule, requiredRule, value]);

  const validate = () => validation.isValid;

  useEffect(() => {
    if (ref) {
      ref.validate = validate;
    }
    // eslint-disable-next-line
  }, [validate]);

  return { validation, validate, requiredRule, maxRangeRule, minRangeRule };
};

export default useValidation;
