import React from 'react';
import './ProcessedPage.scss';

import successIcon from '../../../assets/icons/sticky-success.svg';
import failedIcon from '../../../assets/icons/failed-icon.svg';

import classNames from 'classnames';
import appService from '../../../services/app.service';

const ProcessedPage = ({
  title,
  text,
  btnText,
  isFailedPage = false,
  btnEvent,
  createTicketSuccessPageBtn = false,
}) => {
  return (
    <div className="sticky-success-container">
      <div className="content-wrapper">
        <div className="inner-content-wrapper">
          <div className="image-container">
            <img
              src={isFailedPage ? failedIcon : successIcon}
              alt="success"
              className="success-image"
            />
          </div>
          <div className="success-title">{title}</div>
          <div className="success-text">{text}</div>
        </div>
      </div>
      <div className="button-container">
        <div
          className={classNames([
            ' modal__buttons__continue',
            { 'ticket-create-btn': createTicketSuccessPageBtn },
          ])}
          onClick={() => btnEvent()}>
          {btnText}
        </div>
      </div>
    </div>
  );
};

export default ProcessedPage;
