import './SelectableItems.scss';

import React, { useEffect } from 'react';
import classnames from 'classnames';
import Bubble from '../../layout/bubble/Bubble';
import SelectableItem from './SelectableItem';
import { BUTTON_PRIMARY, BUTTON_SELECTED_NO } from '../../../constants';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import usePersistentState from '../../common/usePersistentState';

const SelectableItems = ({ id, componentData, isSelectable, select }) => {
  const { items, buttons, label } = componentData;
  const [selected, setSelected] = usePersistentState(id, 'selected', null);

  useEffect(() => {
    if (items.length === 1) {
      setSelected(items[0].listingId);
    }
  }, [items]);

  const onChangeSelectedItem = (listingId) => {
    setSelected(listingId);
  };

  const handleButtonSelectable = (button) => {
    if (button.buttonType === BUTTON_SELECTED_NO) {
      return true;
    } else {
      return selected !== null;
    }
  };

  const handleButtonClick = (button) => {
    if (isSelectable) {
      if (button.buttonType === BUTTON_SELECTED_NO) {
        let request = {
          event: button.event,
        };
        select(request);
      } else if (selected !== null) {
        let request = {
          event: button.event,
          data: {
            itemList: [selected],
          },
        };
        select(request);
      }
    }
  };

  const getButtonPropsByType = (buttonType) => {
    switch (buttonType) {
      case BUTTON_PRIMARY:
        return {
          colorType: 'primary',
        };
      case BUTTON_SELECTED_NO:
        return {
          colorType: 'default',
        };
      default:
        return {
          colorType: 'default',
        };
    }
  };

  return (
    <div className="selectable-simulation-wrapper">
      <Bubble title={label} isBottomRounded={false}>
        <div
          className={classnames([
            'selectable-simulation-order',
            { disabled: !isSelectable },
          ])}>
          {items.map((selectableItem, i) => {
            return (
              <SelectableItem
                key={`${selectableItem.shipmentNumber}-${i}`}
                listingId={selectableItem.listingId}
                imgSource={selectableItem.images[0]}
                name={selectableItem.name}
                variantName={selectableItem.variantName}
                onChangeSelectedItem={onChangeSelectedItem}
                isChecked={selectableItem.listingId === selected}
              />
            );
          })}
          <ButtonGroup>
            {buttons &&
              buttons.map((button) => {
                return (
                  <ButtonGroup.Button
                    title={button.text}
                    event={button.event}
                    isSelectable={handleButtonSelectable(button)}
                    showLabel={false}
                    onClick={() => handleButtonClick(button)}
                    {...getButtonPropsByType(button.buttonType)}
                    outline={button.outline}
                  />
                );
              })}
          </ButtonGroup>
        </div>
      </Bubble>
    </div>
  );
};

export default SelectableItems;
