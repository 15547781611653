import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  agentDisconnected,
  agentJoined,
  liveChatConnecting,
  liveChatOffline,
  liveChatReconnecting,
  liveChatTitle,
} from '../../../i18n/keys';
import appService from '../../../services/app.service';
import './EchoLiveChatHeader.scss';
import AgentIcon from '../../../assets/icons/agent-icon.png';
import { ChatStatus, useEchoLiveChat } from '../context/echoLiveChatContext';
import EchoHeaderOrderIcon from './EchoHeaderOrderIcon';
import EchoHeaderMinimizeIcon from './EchoHeaderMinimizeIcon';
import EchoHeaderCloseIcon from './EchoHeaderCloseIcon';

const EchoLiveChatHeader = () => {
  const { t } = useTranslation();
  const { showOrdersModal, chatStatus } = useEchoLiveChat();

  return (
    <div
      className={classNames('live-chat-header', {
        'header-position': showOrdersModal,
        dynamic: appService.isDynamicStylingChannel(),
      })}>
      <div className="header-wrapper">
        <div className="live-chat-icon">
          <img
            className="live-chat-img"
            src={appService.getDynamicStyleValue('customer-service-icon')}
            alt="chat_icon"
          />
        </div>
        <div className="info">
          <div className="title">
            {t(appService.getDynamicStyleValue('livechat-title'))}
          </div>
          <div className="status">
            <div
              className={classNames({
                'dot ': true,
                'dot--connected': chatStatus === ChatStatus.CONNECTED,
                'dot--not_connected': chatStatus === ChatStatus.ENDED,
                'dot--connecting':
                  chatStatus !== ChatStatus.CONNECTED &&
                  chatStatus !== ChatStatus.ENDED,
              })}
            />
            <div className="text">
              {chatStatus === ChatStatus.AGENT_DISCONNECTED
                ? t(agentDisconnected)
                : chatStatus === ChatStatus.RECONNECTING ||
                  chatStatus === ChatStatus.AGENT_DISCONNECTED ||
                  chatStatus === ChatStatus.REDIRECTED
                ? t(liveChatReconnecting)
                : chatStatus === ChatStatus.CONNECTING
                ? t(liveChatConnecting)
                : chatStatus === ChatStatus.CONNECTED
                ? t(agentJoined)
                : t(liveChatOffline)}
            </div>
          </div>
        </div>
      </div>
      <div className="header-right">
        <EchoHeaderOrderIcon />
        <EchoHeaderMinimizeIcon />
        <EchoHeaderCloseIcon />
      </div>
    </div>
  );
};

export default EchoLiveChatHeader;
