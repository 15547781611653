import React from 'react';
import { Input } from 'antd';
import { MaskedInput } from 'antd-mask-input';

export default function TextInput({ item, onChange, ...rest }) {
  const handleMaskInputChange = (e) => {
    if (e && e.unmaskedValue === '') {
      onChange(null);
      return;
    }
    onChange(e);
  };

  if (item.mask) {
    return (
      <MaskedInput
        mask={item.mask}
        placeholder={item.placeholder}
        onChange={handleMaskInputChange}
        {...rest}
      />
    );
  }
  return (
    <Input
      className="assistant-input"
      placeholder={item.placeholder}
      onChange={onChange}
      {...rest}
    />
  );
}
