import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  claimStatusCargoText,
  coinText,
  currencyText,
  showMoreShipment,
  totalClaimAmountText,
} from '../../../../i18n/keys';
import Alert from '../../../../assets/icons/alert.svg';
import AlertBox from '../../../layout/alert/Alert';
import './claimStatusDetail.scss';
import ClaimDetailShipment from './claimDetailShipments';
import appService from '../../../../services/app.service';
import usePersistentState from '../../../common/usePersistentState';
import classnames from 'classnames';
import OperationButton from '../../operationButton/OperationButton';
import utils from '../../../../utils';
import ChatBotContext from '../../../../context/chatbot/chatbotContext';
import QrCode from '../claimCodes/components/QrCode/QrCode';
import { TRUE } from '../../../../constants/chatAssistantConstant';
import Bubble from '../../../layout/bubble/Bubble';
import Info from '../../../layout/info/Info';
import { ReactComponent as PhoneLaptopIcon } from '../../../../assets/icons/phone-laptop.svg';
import { ReactComponent as CoinEarnedIcon } from '../../../../assets/icons/coin-earned.svg';
import { ReactComponent as ChevronInternationalIcon } from '../../../../assets/icons/chevron-international.svg';
import RefundDetailPopup from './components/RefundDetailPopup';

const ClaimStatusDetail = ({
  id,
  componentData,
  isRenderedFromOutside = false,
  setShouldResizeIframe = () => {},
}) => {
  const chatBotContext = useContext(ChatBotContext);
  const { requestForDeepLink } = chatBotContext;
  const [isShowMore, setIsShowMore] = usePersistentState(
    id,
    'isShowMore',
    false
  );
  const { t } = useTranslation();
  const {
    claimDetailButtonText,
    cargoTrackingLink,
    expireDate,
    detailItemList,
    refundInfo,
    additionalInformationLink,
    additionalInformationText,
    additionalRefundInfoMessage,
    klarnaPayLaterInfo,
  } = componentData?.claimDetail;

  const { returnLabelDeepLink } = componentData;
  const { qrCodeDeepLink } = componentData;
  const [isVisible, setIsVisible] = usePersistentState(id, 'isVisible', false);

  const { label } = componentData;

  const [showRefundDetailPopup, setShowRefundDetailPopup] = useState(false);

  const handleCargoLink = (cargoLink) => {
    appService.navigateOnParentHost(cargoLink);
  };

  const handleSeeMore = () => {
    setIsShowMore(false);
    if (isRenderedFromOutside) {
      setShouldResizeIframe(true);
    }
  };

  const handleCloseRefundDetailPopup = () => {
    setShowRefundDetailPopup(false);
  };

  function getReturnLabelButtonComponentData() {
    return {
      buttons: [
        {
          text: returnLabelDeepLink.text,
          buttonType: 'Primary',
          componentsId: null,
          genericStateEvent: null,
          outline: false,
          weight: 1,
        },
      ],
      hasDetail: false,
      label: returnLabelDeepLink.label,
    };
  }

  function onReturnLabelClicked() {
    return utils.handleClickDeeplink(
      returnLabelDeepLink.event,
      returnLabelDeepLink.eventLabel,
      returnLabelDeepLink.url,
      requestForDeepLink
    );
  }

  function handleKlarnaRedirection() {
    appService.navigateOnParentHost(klarnaPayLaterInfo?.link);
  }

  const getClaimDetails = () =>
    detailItemList.map((item) => item.claimDetailProgressInfo).filter(Boolean);

  useEffect(() => {
    if (detailItemList.length > 2) {
      setIsShowMore(true);
    }
  }, []);

  useEffect(() => {
    if (isVisible === false) {
      appService.setIsAssistantExitButtonEnabled(TRUE);
    }
  }, [isVisible]);

  return (
    <div className="claim-status-main-wrapper">
      <Bubble title={label} isBottomRounded={false}>
        {klarnaPayLaterInfo && (
          <div className="claim-status-klarna-wrapper">
            <span>
              <PhoneLaptopIcon />
            </span>
            <div className="klarna-message-wrapper">
              <span>{klarnaPayLaterInfo.message}</span>
              <button
                className="klarna-button"
                onClick={handleKlarnaRedirection}
                type="button">
                {klarnaPayLaterInfo.linkText}
                <span>
                  <ChevronInternationalIcon />
                </span>
              </button>
            </div>
          </div>
        )}
        {additionalRefundInfoMessage && (
          <div className="additional-message">
            <Info message={additionalRefundInfoMessage} handInfo green />
          </div>
        )}
        <div className="claim-status-wrapper">
          <div className="claim-status-detail">
            <div className="claim-status-detail-wrapper">
              {refundInfo && refundInfo.amount !== 0 && (
                <div className="refund-info">
                  <div
                    className={classnames([
                      'amount',
                      {
                        dynamic: appService.isDynamicStylingChannel(),
                      },
                    ])}>
                    <span className="text">{t(totalClaimAmountText)}:</span>
                    <span className="code">
                      {refundInfo.amount}{' '}
                      {refundInfo.currency
                        ? refundInfo.currency
                        : t(currencyText)}
                      {!!refundInfo.quantity &&
                        ' (' + refundInfo.quantity + ' ' + t(coinText) + ')'}
                    </span>
                  </div>
                  {refundInfo.additionalRefundAmountMessage && (
                    <AlertBox
                      message={refundInfo.additionalRefundAmountMessage}
                      info
                      theme
                      background={true}
                    />
                  )}
                </div>
              )}
              {cargoTrackingLink && (
                <div
                  className="cargo-link"
                  onClick={() => handleCargoLink(cargoTrackingLink)}>
                  {cargoTrackingLink && (
                    <span className="link">
                      {t(claimStatusCargoText)}
                      <i className="arrow right" />
                    </span>
                  )}
                </div>
              )}
              {additionalInformationLink && (
                <div
                  className="cargo-link"
                  onClick={() => handleCargoLink(additionalInformationLink)}>
                  {additionalInformationLink && (
                    <span className="link">
                      {additionalInformationText}
                      <i className="arrow right" />
                    </span>
                  )}
                </div>
              )}
              {qrCodeDeepLink && qrCodeDeepLink.url && (
                <QrCode
                  item={qrCodeDeepLink}
                  isVisible={isVisible}
                  setQrCodeVisibleState={setIsVisible}
                />
              )}
              {returnLabelDeepLink && returnLabelDeepLink.url && (
                <OperationButton
                  id={id}
                  componentData={getReturnLabelButtonComponentData()}
                  onClick={() => onReturnLabelClicked()}
                  showHover={true}
                  showLabel={true}
                  className={'pick'}
                />
              )}
              {expireDate && (
                <div className="claim-cargo-date expire-date">
                  <img className="alert-icon" alt="uyari-iconu" src={Alert} />
                  <span>{expireDate}</span>
                </div>
              )}
              {refundInfo &&
                refundInfo.amount !== 0 &&
                claimDetailButtonText && (
                  <button
                    className="show-claim-detail-button"
                    onClick={() => setShowRefundDetailPopup(true)}
                    type="button">
                    <span className="coin-icon">
                      <CoinEarnedIcon />
                    </span>
                    <span>{claimDetailButtonText}</span>
                    <span className="chevron-icon">
                      <ChevronInternationalIcon />
                    </span>
                  </button>
                )}
            </div>
            {detailItemList && detailItemList.length > 2 && isShowMore ? (
              <div>
                <ClaimDetailShipment
                  refundInfoAmount={refundInfo?.amount}
                  detailItemList={detailItemList.slice(0, 2)}
                  handleCargoLink={handleCargoLink}
                />
                <div className="see-more" onClick={() => handleSeeMore()}>
                  {t(showMoreShipment)}
                  <i className="arrow down" />
                </div>
              </div>
            ) : (
              <ClaimDetailShipment
                refundInfoAmount={refundInfo?.amount}
                detailItemList={detailItemList}
                handleCargoLink={handleCargoLink}
              />
            )}
          </div>
        </div>
      </Bubble>

      {showRefundDetailPopup && (
        <RefundDetailPopup
          visible={showRefundDetailPopup}
          handleClose={handleCloseRefundDetailPopup}
          claimDetails={getClaimDetails()}
        />
      )}
    </div>
  );
};

export default ClaimStatusDetail;
