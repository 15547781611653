import React from 'react';
import * as PropTypes from 'prop-types';
import alert from '../../../../../../assets/icons/alert.svg';

const OrderDetailLastOperation = ({ lastOperationMessages }) => {
  const lastMessage = lastOperationMessages.replace(/[\[\]]/g, ''); //Remove all square brackets
  return (
    <div className="order-detail-last-operation">
      <div className="order-detail-wrapper">
        <div className="order-detail__last-operation">
          <img
            src={alert}
            className="order-detail__last-operation__icon"
            alt="icon"
          />
          <div className="order-detail__last-operation__message">
            {lastMessage}
          </div>
        </div>
      </div>
    </div>
  );
};

OrderDetailLastOperation.propTypes = {
  lastOperationMessages: PropTypes.string.isRequired,
};

export default OrderDetailLastOperation;
