import React, { forwardRef, useEffect } from 'react';
import Bubble from '../../../layout/bubble/Bubble';
import {
  branchText,
  dateSelect,
  dateText,
  placeHolderbranch,
  saveText,
} from '../../../../i18n/keys';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './ClaimComplaint.scss';
import usePersistentState from '../../../common/usePersistentState';
import { DataType } from '../../../../constants/chatAssistantConstant';
import Button from '../../buttonGroup/Button';
import TextInput from '../../../common/TextInput';
import InputLabel from '../../../common/InputLabel';

const ClaimComplaint = ({ id, select, isSelectable, componentData }) => {
  const ref = React.createRef();
  const { t } = useTranslation();
  const [branch, setBranch] = usePersistentState(id, 'branch', '');
  const [isActive, setIsActive] = usePersistentState(id, 'isActive', false);
  const [date, setDate] = usePersistentState(id, 'date', '', DataType.DATE);

  useEffect(() => {
    checkNewComplaint();
  }, [branch, date]);

  const checkNewComplaint = () => {
    if (branch !== '' && date !== '') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const handleBranch = (e) => {
    setBranch(e.target.value);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const handleClaimComplaint = () => {
    if (isActive) {
      let request = {
        data: {
          cargoBranchName: branch,
          issueDate: date,
        },
        event: componentData.event,
      };
      select(request);
    }
  };

  const CustomInput = forwardRef(({ onClick, value }, ref) => {
    return (
      <button
        className={classNames({
          'assistant-input': true,
          passive: !isSelectable,
        })}
        onClick={onClick}
        data-testid="date">
        {value === '' ? t(dateSelect) : value}
      </button>
    );
  });

  return (
    <Bubble>
      <div className="claim-complaint">
        <TextInput
          label={t(branchText)}
          required
          type="text"
          value={branch}
          placeholder={t(placeHolderbranch)}
          onChange={handleBranch}
          disabled={!isSelectable}
          alt={t(branchText)}
        />
        <div className="date">
          <InputLabel text={t(dateText)} required />
          <DatePicker
            selected={date}
            onChange={handleDateChange}
            ref={ref}
            customInput={<CustomInput />}
            dateFormat="dd/MM/yyyy"
            disabled={!isSelectable}
            maxDate={new Date()}
          />
        </div>
        <div className="action-buttons">
          <Button
            colorType="primary"
            outline={false}
            isSelectable={isSelectable && isActive}
            disabled={!isSelectable && !isActive}
            data-testid="request-button"
            onClick={() => handleClaimComplaint()}>
            {t(saveText)}
          </Button>
        </div>
      </div>
    </Bubble>
  );
};

export default ClaimComplaint;
