import React, { useContext } from 'react';
import classNames from 'classnames';
import Button from '../../common/Button';
import './SubjectButton.scss';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import utils from '../../../utils';
import { useTranslation } from 'react-i18next';
import { newText } from '../../../i18n/keys';
import appService from '../../../services/app.service';
import usePersistentState from '../../common/usePersistentState';
import Bubble from '../../layout/bubble/Bubble';

export default React.forwardRef(function (props, ref) {
  const {
    id,
    select,
    componentData,
    history,
    isRenderedFromOutside = false,
  } = props;
  const [isSelected, setIsSelected] = usePersistentState(
    id,
    'isSelected',
    false
  );
  const chatBotContext = useContext(ChatBotContext);
  const { requestForDeepLink } = chatBotContext;
  const { deepLinks, topicDataList } = componentData;
  const { t } = useTranslation();

  const buttonClick = (data) => {
    if (!isRenderedFromOutside) {
      appService.setClickedTopicEvent(data.event);
      appService.setClickedLastEventForSurvey(data.event);
      appService.setLastChatTopic(data.title);

      if (data.event === 'GET_CONVERSATION_KEYBOARD_FOCUS' && ref.current) {
        props.scrollToBottom(300);
        ref.current.focus();
        ref.current.style.border = '1px solid #f27a1a';

        setTimeout(() => (ref.current.style.border = '1px solid white'), 2000);
      } else {
        let request = {
          event: data.event,
          isMainTopic: data.isMainTopic,
          hiddenEvent: data.hiddenEvent,
          componentsId: data.componentsId,
          genericStateEvent: data.genericStateEvent,
        };
        if (!data.isMainTopic) {
          request.requestItem = data;
        }
        select(request);
        setIsSelected(true);
      }
    }
  };

  const renderButtonContent = (data) => {
    return (
      <>
        <span className="button-title">{data.title}</span>
        {data.isNewTopic && <span className="stamp">{t(newText)}</span>}
      </>
    );
  };

  return (
    <div className="subject-button" data-testid="subject-button">
      <Bubble
        title={componentData.label}
        isBordered={false}
        isBottomRounded={false}>
        <div className="container">
          <div className="body">
            <div className="choices">
              {topicDataList &&
                topicDataList.map((data, index) => {
                  return (
                    <Button
                      data={data}
                      key={index}
                      title={renderButtonContent(data)}
                      iconUrl={data.iconUrl}
                      className={classNames({
                        choice: true,
                        'choice--selected': data.selected,
                        'main-topic': data.isMainTopic,
                        'status-active': !isSelected,
                        'status-passive': isSelected,
                        dynamic: appService.isDynamicStylingChannel(),
                      })}
                      onClick={() => buttonClick(data)}
                    />
                  );
                })}
              {deepLinks &&
                deepLinks.map((data, index) => {
                  return (
                    <Button
                      data={data}
                      key={index}
                      title={data.text}
                      iconUrl={data.iconUrl}
                      className={classNames({
                        choice: true,
                        'choice--selected': data.selected,
                        'main-topic': data.isMainTopic,
                        'status-active': !isSelected,
                        'status-passive': isSelected,
                      })}
                      onClick={() =>
                        utils.handleClickDeeplink(
                          data.event,
                          data.eventLabel,
                          data.url,
                          requestForDeepLink,
                          history
                        )
                      }
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </Bubble>
    </div>
  );
});
