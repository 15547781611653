import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './EchoChatInputBar.scss';
import {
  chatInputBarPlaceholderMessage,
  imageReadError,
  inputLanguageNotSupported,
} from '../../../i18n/keys';

import SendIcon from '../../../assets/icons/send-icon-v2.svg';
import Attachment from '../../../assets/icons/attachment-icon-v2.svg';

import utils from '../../../utils';
import AutoTextArea from '../../common/AutoTextArea';
import appService from '../../../services/app.service';
import usePersistentState from '../../common/usePersistentState';
import { ReactComponent as ErrorIconWhite } from '../../../assets/icons/circle-info-white.svg';
import { ENTER_KEYCODE, PLATFORM_WEB } from '../../../constants';
import Utils from '../../../utils';
import MessageBox from '../../common/Modal/MessageBox';
import { message as toastMessage } from 'antd';
import CustomToast from '../../layout/customToast';

const EchoChatInputBar = ({
  id = 'echoLiveChatInputBar',
  onMessageChange,
  onMessage,
  onMedia,
  disabled = false,
  allowUploads = true,
  connected = false,
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = usePersistentState(id, 'message', '');
  const messageInput = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (onMessageChange) {
      if (Utils.isInputArabic(message)) {
        return;
      }
      onMessageChange(message);
    }
  }, [message]);

  const onChange = (value) => {
    if (Utils.isInputArabic(value)) {
      MessageBox.Error({
        msg: t(inputLanguageNotSupported),
      });
      return;
    }
    setMessage(value);
  };

  const sendMessage = (event) => {
    event.preventDefault();
    if (message) {
      if (onMessage) {
        onMessage(message);
      }
      setMessage('');
    }
  };

  const onKeyDown = (e) => {
    if (
      e.keyCode === ENTER_KEYCODE &&
      e.shiftKey === false &&
      PLATFORM_WEB === appService.getPlatform()
    ) {
      sendMessage(e);
    }
  };

  const [messageApi, contextHolder] = toastMessage.useMessage();

  function getToastIcon() {
    return (
      <span
        role="img"
        aria-label="exclamation-circle"
        className="anticon anticon-exclamation-circle">
        <ErrorIconWhite />
      </span>
    );
  }

  const onMediaUpload = (e) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      const acceptedFileTypes = getAcceptedFileTypes();

      if (!acceptedFileTypes.includes(e.target.files[0].type)) {
        messageApi
          .open({
            type: 'warning',
            content: t(imageReadError),
            duration: 3,
            icon: getToastIcon(),
          })
          .then((r) => {});
        return;
      }

      const messageType = utils.getFileType(e.target.files[0]);
      const file = e.target.files[0];
      if (messageType && file) {
        if (onMedia) {
          onMedia({
            fileType: messageType,
            file,
          }).catch((e) => console.error('media upload error', e));
        }
        setMessage('');
      }
    }
  };

  const handleFocus = () => {
    messageInput.current.focus();
  };

  const handleOpenFileInput = () => {
    fileInputRef.current.click();
  };

  function getAcceptedFileTypes() {
    let acceptedFileTypes = appService.getAcceptedLiveChatFileTypes();

    return acceptedFileTypes
      ? acceptedFileTypes
      : process.env.REACT_APP_ACCEPTED_ATTACHMENT_TYPES_FOR_LIVE_CHAT;
  }

  return (
    <>
      <div className="echo-chat-input-bar">
        <div className="echo-chat-input-bar__area">
          <form onSubmit={(e) => sendMessage(e)} className="form-wrapper">
            <div className="input-area">
              <AutoTextArea
                message={message}
                className="inputbar"
                placeholder={connected ? t(chatInputBarPlaceholderMessage) : ''}
                onChange={(e) => onChange(e.target.value)}
                parentstyle="inputbar-parent"
                onKeyDown={onKeyDown}
                disabled={disabled}
                data-testid="text-input"
                ref={messageInput}
              />
              <div className="actions">
                {allowUploads ? (
                  <label
                    onClick={handleOpenFileInput}
                    className={classNames({
                      upload: true,
                      'upload--disabled': !connected,
                    })}>
                    <img src={Attachment} alt="send" className="upload-icon" />
                  </label>
                ) : (
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '15px',
                    }}
                  />
                )}
              </div>
            </div>

            <button
              type="submit"
              className={classNames({
                'send-button': true,
                'send-button--active': !disabled,
                'send-button--disabled': disabled,
                dynamic: appService.isDynamicStylingChannel(),
              })}
              onClick={handleFocus}
              data-testid="send-button"
              disabled={disabled}>
              <img src={SendIcon} alt="attach" className="button-icon" />
            </button>
            <input
              ref={fileInputRef}
              className="file-input-chat"
              type="file"
              disabled={disabled}
              onChange={(e) => onMediaUpload(e)}
              accept={getAcceptedFileTypes()}
              onClick={(e) => {
                e.target.value = null;
              }}
              data-testid="echo-media-input"
            />
          </form>
          <CustomToast contextHolder={contextHolder} />
        </div>
      </div>
    </>
  );
};

export default EchoChatInputBar;
