import React from 'react';
import './EchoLiveChatCloseModal.scss';
import CloseButtonAssistant from '../../../assets/icons/close-button-assistant.svg';
import appService from '../../../services/app.service';
import {
  continueLiveChatText,
  exitAndOpenSurvey,
  exitLiveChatModalText,
} from '../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const EchoLiveChatCloseModal = ({ show, onContinue, onExit }) => {
  const { t } = useTranslation();

  function getExitModalMessage() {
    return appService.getLiveChatExitModalMessage();
  }

  return (
    <div className={`modal ${show ? 'open-modal' : 'close-modal'}`}>
      <div className="content">
        <div className="close-wrapper" onClick={() => onContinue()}>
          <img
            onClick={() => onContinue()}
            src={CloseButtonAssistant}
            className="close-icon"
            alt="close"
          />
        </div>
        <div className="modal__text">
          {getExitModalMessage() === ''
            ? t(exitLiveChatModalText)
            : getExitModalMessage()}
        </div>
        <div className="modal__seconds" />
        <div className="modal__buttons">
          <div
            className={classNames([
              'modal__buttons__continue',
              {
                dynamic: appService.isDynamicStylingChannel(),
              },
            ])}
            onClick={() => onContinue()}>
            {t(continueLiveChatText)}
          </div>
          <div className="modal__buttons__end" onClick={() => onExit()}>
            {t(exitAndOpenSurvey)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EchoLiveChatCloseModal;
