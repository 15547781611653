import React from 'react';
import './RefundTimeline.scss';
import { ReactComponent as BankIcon } from '../../../../../../assets/icons/bank.svg';
import { ReactComponent as CheckIcon } from '../../../../../../assets/icons/check-3.svg';
import { ReactComponent as TimeIcon } from '../../../../../../assets/icons/time-circle.svg';
import classNames from 'classnames';
import Image from '../../../../../common/Image';
import { useTranslation } from 'react-i18next';
import {
  refundProgressBankInfo,
  refundProgressMillaInfo,
  refundProgressTrInfo,
} from '../../../../../../i18n/keys';
import AppService from '../../../../../../services/app.service';
import { MILLA_APP_NAME } from '../../../../../../constants/chatAssistantConstant';

const RefundTimeline = ({
  progressInfo,
  showLabel = false,
  isLarge = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className="refund-timeline-wrapper">
      <div
        className={classNames('refund-timeline', {
          rtl: AppService.isRtl(),
        })}>
        <div
          className={classNames('refund-icon-wrapper company-icon', {
            'icon-large': isLarge,
          })}>
          <CheckIcon className="status-icon" />
          <div className="company-image">
            <Image
              isHorizantolFallback={true}
              src={progressInfo?.companyImageUrl}
              alt="company"
            />
          </div>
        </div>
        <span className="refund-text">{progressInfo?.status}</span>
        <div
          className={classNames('refund-icon-wrapper bank-icon', {
            'icon-large': isLarge,
          })}>
          <TimeIcon className="status-icon time-icon" />
          <BankIcon />
        </div>
      </div>

      {showLabel && (
        <div className="refund-timeline-label-wrapper">
          <span className="refund-timeline-label-wrapper__label">
            {AppService.getAppName() === MILLA_APP_NAME
              ? t(refundProgressMillaInfo)
              : t(refundProgressTrInfo)}
          </span>
          <span className="refund-timeline-label-wrapper__label">
            {t(refundProgressBankInfo)}
          </span>
        </div>
      )}
    </div>
  );
};

export default RefundTimeline;
