import React, { useCallback, useEffect } from 'react';
import CloseIcon from '../../../assets/icons/close.svg';
import {
  MESSAGE_TYPE_IMAGE,
  MESSAGE_TYPE_PDF,
} from '../../../constants/liveChatConstants';
import './EchoMediaPreview.scss';
import appService from '../../../services/app.service';

const EchoMediaPreview = ({ show, setShow, mediaInfo, setMediaInfo }) => {
  const closeMedia = useCallback(() => {
    setShow(false);
    setMediaInfo(null);
  }, [setShow, setMediaInfo]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        // 27 === ESC
        closeMedia();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [closeMedia]);

  useEffect(() => {
    if (
      appService.getIsStickyAssistant() &&
      show &&
      mediaInfo.type === MESSAGE_TYPE_IMAGE
    ) {
      appService.openFile(mediaInfo.url);
      closeMedia();
    }
  }, [show, mediaInfo]);

  if (show) {
    if (
      appService.getIsStickyAssistant() &&
      mediaInfo.type === MESSAGE_TYPE_IMAGE
    ) {
      return null;
    } else {
      return (
        <div className="media-preview" data-testid="media-preview">
          <div className="media-preview__header">
            <div className="media-preview__header__area">
              <img
                src={CloseIcon}
                className="media-preview__header__area__close-icon"
                alt="close"
                onClick={closeMedia}
              />
            </div>
          </div>
          {mediaInfo.type === MESSAGE_TYPE_IMAGE && (
            <div
              className="media-preview__media-area"
              style={{
                backgroundImage: 'url(' + mediaInfo.url + ')',
              }}
            />
          )}
          {mediaInfo.type === MESSAGE_TYPE_PDF && (
            <div className="media-preview__media-area">
              <object
                data={mediaInfo.url}
                type="application/pdf"
                className="media-preview__media-area__pdf">
                <embed src={mediaInfo.url} type="application/pdf" />
              </object>
            </div>
          )}
        </div>
      );
    }
  } else {
    return null;
  }
};

export default EchoMediaPreview;
