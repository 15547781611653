import './SelectableItem.scss';

import React from 'react';
import classnames from 'classnames';
import Checkbox from '@tx-components/checkbox';

const SelectableItem = ({
  listingId,
  name,
  variantName,
  imgSource,
  onChangeSelectedItem,
  isChecked,
}) => {
  const onChangeSelected = (e) => {
    onChangeSelectedItem(listingId, e);
  };
  const onClickHeader = () => {
    onChangeSelected(!isChecked);
  };

  return (
    <div
      data-testid="selectable-item-order"
      className={classnames([
        'selectable-item-order',
        { selected: isChecked },
      ])}>
      <div className="selectable-item-header" onClick={onClickHeader}>
        <div className="checkbox-container">
          <Checkbox onChange={onChangeSelected} checked={isChecked} />
        </div>
        <div className="selectable-item-image">
          <img src={imgSource} alt="selectable-item" />
        </div>
        <div className="selectable-item-title-container">
          <div className="selectable-item-title" title={name}>
            {name}
          </div>
          <div className="selectable-item-variant-name">{variantName}</div>
        </div>
      </div>
    </div>
  );
};

export default SelectableItem;
