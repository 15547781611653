import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import './NestedDeepLink.scss';
import utils from '../../../utils';
import ReactMarkdown from 'react-markdown';
import Bubble from '../../layout/bubble/Bubble';
import classNames from 'classnames';

const NestedDeepLink = (componentData) => {
  const { history } = componentData;
  let nestedDeepLinkData = componentData.componentData;

  const {
    label,
    mainDeepLinkText,
    mainDeepLinkIconUrl,
    mainDeepLinkUrl,
    mainDeepLinkEvent,
    mainDeepLinkEventLabel,
    deepLinks,
  } = nestedDeepLinkData;

  const chatBotContext = useContext(ChatBotContext);
  const { requestForDeepLink } = chatBotContext;

  const makeReasonBold = (message) => {
    const regex = /'([A-Z0-9].*)'/gm;
    var match = regex.exec(message);

    return match !== null
      ? match.input.replace(match[1], '**' + match[1] + '**')
      : message;
  };

  return (
    <div className="nested-deeplink-container">
      <Bubble title={label} isBottomRounded={false}>
        <div
          className={classNames('nested-deeplink', {
            'multiple-links': (deepLinks || []).length > 0,
          })}
          data-testid="nested-deeplink">
          {deepLinks &&
            deepLinks.map((data, index) => {
              return (
                <div className="container" key={index}>
                  <div className="content-wrapper">
                    <div className="deeplink-text">
                      <ReactMarkdown children={makeReasonBold(data.text)} />
                    </div>
                    <div
                      className="nested-deeplink-button"
                      onClick={() =>
                        utils.handleClickDeeplink(
                          data.deepLinkEvent,
                          data.deepLinkEventLabel,
                          data.deepLinkUrl,
                          requestForDeepLink,
                          history
                        )
                      }>
                      <span> {data.deepLinkText}</span>
                      {mainDeepLinkIconUrl && (
                        <img
                          src={mainDeepLinkIconUrl}
                          alt="nested-deeplink_icon"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Bubble>
      {mainDeepLinkUrl !== undefined && (
        <div className="main-deeplink-info">
          <button
            className="main-deeplink-button"
            onClick={() =>
              utils.handleClickDeeplink(
                mainDeepLinkEvent,
                mainDeepLinkEventLabel,
                mainDeepLinkUrl,
                requestForDeepLink,
                history
              )
            }>
            {mainDeepLinkIconUrl && (
              <img src={mainDeepLinkIconUrl} alt="nested-deeplink_icon" />
            )}
            <div className="title">{mainDeepLinkText}</div>
          </button>
        </div>
      )}
    </div>
  );
};

NestedDeepLink.propTypes = {
  componentData: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default withRouter(NestedDeepLink);
