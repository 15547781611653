import * as keys from './keys';

export default {
  translation: {
    [`${keys.appTitle}`]: 'Asystent Trendyol',
    [`${keys.appTitleTgoApp}`]: 'Asystent',
    [`${keys.appTitleMilla}`]: 'Asystent',
    [`${keys.appTabTitle}`]: 'Asystent Trendyol',
    [`${keys.liveChatTitle}`]: 'Czat na żywo',
    [`${keys.liveChatTitleTgoApp}`]: 'Czat na żywo',
    [`${keys.liveChatTitleMilla}`]: 'Czat na żywo',
    [`${keys.liveChatTabTitle}`]: 'Czat na żywo',
    [`${keys.chatbotErrorMsg}`]: 'Wystąpił błąd systemu. Spróbuj ponownie.',
    [`${keys.liveChatLoadingMessage}`]:
      'Nawiązujemy połączenie z konsultantem obsługi klienta. Prosimy o cierpliwość.',
    [`${keys.agentsBusyMessage}`]:
      'Wszyscy konsultanci aktualnie obsługują innych klientów. Prosimy o cierpliwość.',
    [`${keys.liveChatConnecting}`]: 'Łączenie…',
    [`${keys.systemJoined}`]: 'Przydzielanie konsultanta…',
    [`${keys.agentDisconnected}`]: 'Przydzielanie nowego konsultanta…',
    [`${keys.agentJoined}`]: 'Online',
    [`${keys.systemJoinedMessage}`]: 'Witaj na czacie na żywo Trendyol.',
    [`${keys.liveChatOffline}`]: 'Offline',
    [`${keys.makeUploadedContentBigger}`]: 'Pokaż w większym formacie',
    [`${keys.timeOutFirstPart}`]: 'Czat zostanie zakończony w ciągu',
    [`${keys.timeOutSecondPart}`]: 'sek.',
    [`${keys.timeOutThirdPart}`]:
      'ze względu na brak aktywności z Twojej strony.',
    [`${keys.closeLiveChatButton}`]: 'Zakończ czat',
    [`${keys.fileNameNotValidError}`]: 'Nazwa pliku jest nieprawidłowa.',
    [`${keys.fileEmptyError}`]: 'Zawartość przesłanego pliku jest pusta.',
    [`${keys.continueLiveChatButton}`]: 'Chcę dalej rozmawiać',
    [`${keys.liveChatRefused}`]:
      'To konto prowadzi aktualnie inną rozmowę. W razie potrzeby spróbuj ponownie później.',
    [`${keys.maxTimeOutThresholdExceedMessage}`]:
      'System automatycznie zakończył czat ze względu na brak aktywności przez dłuższy czas. Dziękujemy za zrozumienie.',
    [`${keys.agentLeftMessage}`]: 'Pracownik obsługi zakończył czat.',
    [`${keys.chatConnectionErrorMessage}`]: 'Wystąpił błąd. Spróbuj ponownie.',
    [`${keys.chatInputBarPlaceholderMessage}`]: 'Napisz wiadomość…',
    [`${keys.resendMediaMessage}`]: 'Spróbuj ponownie.',
    [`${keys.resendMediaMessageForEmptyImage}`]: 'Prześlij ponownie.',
    [`${keys.unAuthorizedMessage}`]:
      'Nie masz uprawnień dostępu do tej strony.',
    [`${keys.surveyMessage}`]: 'Czy rozmowa spełniła Twoje oczekiwania?',
    [`${keys.trendyolAsistantSurveyMessage}`]:
      'Czy Asystent Trendyol spełnia Twoje oczekiwania?',
    [`${keys.surveyCommentMessage}`]: 'Chcesz coś dodać?',
    [`${keys.surveyRatingMessage}`]: 'Oceń Asystenta Trendyol',
    [`${keys.liveChatSurveyRatingMessage}`]: 'Oceń naszego konsultanta',
    [`${keys.surveyRatingBadMessage}`]: 'Bardzo źle',
    [`${keys.surveyRatingGoodMessage}`]: 'Bardzo dobrze',
    [`${keys.surveyRateButton}`]: 'Prześlij',
    [`${keys.surveyErrorModalTitle}`]: 'WYSTĄPIŁ BŁĄD ANKIETY',
    [`${keys.surveyErrorRatingEmpty}`]: 'Wprowadź ocenę.',
    [`${keys.surveyErrorServiceError}`]: 'Wystąpił błąd. Spróbuj ponownie.',
    [`${keys.surveySuccessModalTitle}`]: 'OTRZYMALIŚMY OCENĘ',
    [`${keys.surveySuccessModalMessage}`]: 'Dziękujemy za udział w ankiecie!',
    [`${keys.liveChatNewMessageAppTitle}`]: 'Masz nową wiadomość…',
    [`${keys.errorPageMessage}`]: 'Wystąpił błąd systemu. Spróbuj ponownie.',
    [`${keys.closeOnErrorButton}`]: 'Zamknij',
    [`${keys.reconnectOnErrorButton}`]: 'Połącz ponownie',
    [`${keys.liveChatReconnecting}`]: 'Ponowne nawiązywanie połączenia…',
    [`${keys.nameText}`]: 'Imię',
    [`${keys.lastnameText}`]: 'Nazwisko',
    [`${keys.outOfWorkOurMessage}`]:
      'Obecnie nasi konsultanci ds. obsługi klienta są poza godzinami pracy. Możesz zostawić wiadomość. Skontaktujemy się tak szybko, jak to możliwe.',
    [`${keys.invalidTokenInfoText}`]:
      'Nie możemy przeanalizować Twoich danych. Wyjdź z aplikacji i zaloguj się ponownie.',
    [`${keys.newText}`]: 'NOWOŚĆ',
    [`${keys.emailText}`]: 'E-mail',
    [`${keys.continueLiveChatText}`]: 'Powrót',
    [`${keys.exitLiveChatModalText}`]: 'Na pewno chcesz zakończyć czat?',
    [`${keys.exitAndOpenSurvey}`]: 'Potwierdź zakończenie czatu',
    [`${keys.clientReconnectingMessage}`]:
      'Drogi kliencie, ze względu na niską jakość połączenia musieliśmy ponownie przekierować Cię do konsultanta. Czat zostanie wznowiony. Uprzejmie prosimy o cierpliwość i dziękujemy za zrozumienie.',
    [`${keys.clientRedirectedMessage}`]:
      'Drogi kliencie, ze względu na niską jakość połączenia musieliśmy przekierować Cię do innego konsultanta. Historia rozmowy zostanie udostępniona nowo wyznaczonemu przedstawicielowi. Prosimy o cierpliwość.',
    [`${keys.stickySurveyTitle}`]:
      'Czy Asystent Trendyol spełnia Twoje oczekiwania?',
    [`${keys.stickySurveyRateMessage}`]: 'Oceń nas',
    [`${keys.stickySurveyCommentMessage}`]: 'Chcesz coś dodać?',
    [`${keys.stickySurveySuccessTitle}`]: 'Otrzymaliśmy Twoją ocenę!',
    [`${keys.stickySurveySuccessText}`]:
      'Dziękujemy za wystawienie oceny. Twoja opinia pozwoli nam poprawić jakość usług.',
    [`${keys.stickySurveySuccessButtonText}`]: 'Zamknij',
    [`${keys.placeHolderSurname}`]: 'Wprowadź nazwisko',
    [`${keys.placeHolderName}`]: 'Wprowadź imię',
    [`${keys.yourEmailText}`]: 'E-mail',
    [`${keys.placeholderEmail}`]: 'Wprowadź adres e-mail',
    [`${keys.languageText}`]: 'Język',
    [`${keys.placeholderLanguage}`]: 'Wybierz język',
    [`${keys.deliveryNumberText}`]: 'Numer przesyłki',
    [`${keys.placeholderDeliveryNumber}`]: 'Wprowadź numer przesyłki',
    [`${keys.startChatText}`]: 'Rozpocznij czat',
    [`${keys.internationalTicketCreateSucceedTitle}`]: 'Dziękujemy za kontakt',
    [`${keys.internationalTicketCreateSucceedText}`]:
      'Otrzymaliśmy Twoją wiadomość. Przyjrzymy się sytuacji i wkrótce się skontaktujemy.',
    [`${keys.internationalTicketCreateFailedTitle}`]: '',
    [`${keys.internationalTicketCreateFailedText}`]:
      'Wystąpił błąd. Spróbuj ponownie.',
    [`${keys.closeButtonText}`]: 'Zamknij',
    [`${keys.retryButtonText}`]: 'Spróbuj ponownie',
    [`${keys.placeholderSubject}`]: 'Wprowadź temat',
    [`${keys.subjectText}`]: 'Temat',
    [`${keys.internationalSubjectImageText}`]: 'Prześlij zdjęcia',
    [`${keys.mailDescriptionText}`]:
      'Prosimy o szczegółowe przedstawienie sytuacji',
    [`${keys.mailSubmitText}`]: 'Prześlij',
    [`${keys.placeHolderDescriptionText}`]: 'Wprowadź opis',
    [`${keys.englishLangText}`]: 'angielski',
    [`${keys.germanLangText}`]: 'niemiecki',
    [`${keys.englishLangTextShort}`]: 'ENG',
    [`${keys.selectOrderMsg}`]: 'Wybierz',
    [`${keys.shipmentNo}`]: 'Nr przesyłki',
    [`${keys.orderSelectedMsg}`]: 'Wybrano',
    [`${keys.quantityText}`]: 'Szt.',
    [`${keys.claimOrderClaimableIsNotDone}`]: 'Wypełnij poniższe pola.',
    [`${keys.claimQuantityText}`]: 'Określ ilość',
    [`${keys.claimReasonText}`]: 'Powód',
    [`${keys.countAndPriceText}`]: 'Ilość i cena:',
    [`${keys.claimOrderReason}`]: 'Wybierz powód zwrotu',
    [`${keys.selectProductImageText}`]: 'Prześlij zdjęcia (maks. {{count}})',
    [`${keys.internationalMailSelectProductImageText}`]:
      'Prześlij zdjęcia (maks. {{count}})',
    [`${keys.claimOrderDescription}`]: 'Dodaj szczegóły',
    [`${keys.claimSimulationPreviewQuantity}`]: 'Ilość',
    [`${keys.claimSimulationPreviewProduct}`]: 'Produkt',
    [`${keys.claimOrderFillToContinue}`]:
      'Aby kontynuować, wypełnij pola dotyczące liczby produktów i przyczyny zwrotu.',
    [`${keys.claimQuantityText}`]: 'Określ ilość',
    [`${keys.optionalFileInfoText}`]: 'Prześlij zdjęcia (opcjonalnie)',
    [`${keys.imageFormatMismatchErrorText}`]:
      'Można przesyłać wyłącznie pliki JPG i PNG.',
    [`${keys.imageMaxSizeErrorText}`]:
      'Rozmiar pliku nie powinien przekraczać {{size}} MB.',
    [`${keys.imageCountErrorText}`]:
      'Maksymalna liczba przesłanych plików: {{count}}.',
    [`${keys.imageReadError}`]:
      'Nie udało się przetworzyć pliku. Spróbuj ponownie później.',
    [`${keys.imageUploadError}`]:
      'Wystąpił błąd podczas przesyłania pliku. Spróbuj ponownie.',
    [`${keys.shipmentSubtitleTotal}`]: 'Całkowita kwota',
    [`${keys.shipmentSubtitleItems}`]: 'Produkt',
    [`${keys.orderNo}`]: 'Nr zamówienia',
    [`${keys.seeAllAnnouncement}`]: 'Zobacz wszystkie ogłoszenia',
    [`${keys.showMoreTicket}`]: 'Pokaż więcej',
    [`${keys.notificationsTitle}`]: 'Powiadomienia',
    [`${keys.totalClaimAmountText}`]: 'Całkowita kwota zwrotu',
    [`${keys.claimAmountText}`]: 'Kwota zwrotu',
    [`${keys.claimCoinAmountText}`]: 'Zwrócone Trendyol Coins',
    [`${keys.coinText}`]: 'Trendyol Coins',
    [`${keys.coinUsageMessage}`]: 'Wykorzystano',
    [`${keys.coinUsageOrderMessage}`]: 'na to zamówienie!',
    [`${keys.coinsWording}`]: 'coins',
    [`${keys.coinQuantityAndAmountInfo}`]:
      '{{quantity}} {{coinsWording}} ({{amount}} {{currency}})',
    [`${keys.claimStatusCargoText}`]: 'Gdzie jest mój zwrot?',
    [`${keys.showMoreShipment}`]: 'Pokaż więcej produktów',
    [`${keys.showMoreShipment}`]: 'Pokaż więcej produktów',
    [`${keys.orderText}`]: 'Moje zamówienia',
    [`${keys.ordersText}`]: 'Moje zamówienia',
    [`${keys.currencyText}`]: '€',
    [`${keys.copyText}`]: 'Kopiuj',
    [`${keys.copiedText}`]: 'Skopiowano',
    [`${keys.deliveryDateText}`]: 'Data dostawy:',
    [`${keys.paymentInfoText}`]: 'Informacje dotyczące płatności',
    [`${keys.subtotalText}`]: 'Suma częściowa',
    [`${keys.cargoText}`]: 'Wysyłka',
    [`${keys.totalText}`]: 'Razem',
    [`${keys.deliveryAddressText}`]: 'Adres dostawy',
    [`${keys.orderDate}`]: 'Data zamówienia',
    [`${keys.orderSummary}`]: 'Podsumowanie zamówienia',
    [`${keys.productText}`]: 'przedmiot',
    [`${keys.linkCopied}`]: 'Skopiowano numer zamówienia',
    [`${keys.billingAddressText}`]: 'Adres rozliczeniowy',
    [`${keys.detail}`]: 'Szczegóły',
    [`${keys.deliveryTimeText}`]: 'Data dostawy:',
    [`${keys.addExtraImage}`]: 'Dodaj zdjęcie',
    [`${keys.chatbotErrorMsgKey}`]: 'Błąd!',
    [`${keys.chatbotShortErrorMsg}`]: 'Coś poszło nie tak.',
    [`${keys.collectionPointText}`]: 'Punkt odbioru',
    [`${keys.commercialText}`]: 'Sprzedaż fakturowana przez firmę',
    [`${keys.fastMarketText}`]: 'Szybki rynek',
    [`${keys.mealText}`]: 'Posiłek',
    [`${keys.groupDealText}`]: 'Kupuj ze znajomymi',
    [`${keys.wardrobeText}`]: 'DOLAP',
    [`${keys.inputLanguageNotSupported}`]:
      'Wsparcie jest obecnie dostępne tylko w języku angielskim. Wprowadź treść zapytania po angielsku.',
    [`${keys.myOrderDetail}`]: 'Szczegóły mojego zamówienia',
    [`${keys.arabicLangText}`]: 'arabski',
    [`${keys.showMoreContent}`]: 'Pokaż więcej',
    [`${keys.showLessContent}`]: 'Pokaż mniej',
    [`${keys.bulgariaLangText}`]: 'bułgarski',
    [`${keys.polandLangText}`]: 'polski',
    [`${keys.slovakiaLangText}`]: 'słowacki',
    [`${keys.romaniaLangText}`]: 'rumuński',
    [`${keys.czechiaLangText}`]: 'czeski',
    [`${keys.hungaryLangText}`]: 'węgierski',
    [`${keys.greeceLangText}`]: 'grecki',
    [`${keys.cancelOrderSelectProduct}`]:
      'Wybierz produkt i przyczynę rezygnacji z zakupu.',
    [`${keys.cancelOrderSelectReason}`]: 'Wybierz powód rezygnacji z zakupu',
    [`${keys.selectReason}`]: 'Wybierz powód',
    [`${keys.other}`]: 'Wyjaśnienie',
    [`${keys.explanation}`]: 'Wyjaśnij, dlaczego chcesz zrezygnować z produktu',
    [`${keys.userDisconnectedOnBackground}`]:
      'Ten czat został zamknięty, ponieważ opuszczono aplikację podczas rozmowy lub nie było na niej żadnej aktywności przez dłuższy czas. Dziękujemy za zrozumienie.',
    [`${keys.refundDetailText}`]: 'Zobacz szczegóły dotyczące zwrotu',
    [`${keys.refundProgressBankInfo}`]:
      'Transakcja musi zostać przetworzona przez Twój bank',
    [`${keys.productImageText}`]: 'Zdjęcie produktu',
    [`${keys.refundAmountText}`]: 'Kwota zwrotu',
    [`${keys.claimLastModifiedDateTrText}`]:
      'Dzień, w którym Trendyol wysłał Ci zwrot',
    [`${keys.claimLastModifiedDateMillaText}`]:
      'Dzień, w którym Trendyolmilla wysłał Ci zwrot',
    [`${keys.estimatedRefundDateText}`]: 'Szacowany okres zwrotu',
    [`${keys.bankRefNoText}`]: 'Numer referencyjny banku',
    [`${keys.refundProgressTrInfo}`]: 'Trendyol dokonał zwrotu środków',
    [`${keys.refundProgressMillaInfo}`]: 'Trendyolmilla dokonał zwrotu środków',
  },
};
