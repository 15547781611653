import * as keys from './keys';

export default {
  translation: {
    [`${keys.appTitle}`]: 'Trendyol Assistant',
    [`${keys.appTitleDodo}`]: 'DODO',
    [`${keys.appTitleTgoApp}`]: 'Assistant',
    [`${keys.appTitleMilla}`]: 'Assistant',
    [`${keys.appTabTitle}`]: 'Trendyol Assistant',
    [`${keys.liveChatTitle}`]: 'Live Chat',
    [`${keys.liveChatTitleDodo}`]: 'Dolap Live Chat',
    [`${keys.liveChatTitleTgoApp}`]: 'Live Chat',
    [`${keys.liveChatTitleMilla}`]: 'Live Chat',
    [`${keys.liveChatTabTitle}`]: 'Live Chat',
    [`${keys.chatbotErrorMsg}`]:
      'A system error has occurred. Please try again.',
    [`${keys.liveChatLoadingMessage}`]:
      'Connecting to our customer service representative, please hold on..',
    [`${keys.agentsBusyMessage}`]:
      'Currently all our representatives are serving other customers, please hold on..',
    [`${keys.liveChatConnecting}`]: 'Connecting...',
    [`${keys.systemJoined}`]: 'Assigning a Customer Representative...',
    [`${keys.agentDisconnected}`]: 'Assigning a New Representative....',
    [`${keys.agentJoined}`]: 'Online',
    [`${keys.systemJoinedMessage}`]: 'Welcome to Trendyol Live Chat.',
    [`${keys.liveChatOffline}`]: 'Offline',
    [`${keys.makeUploadedContentBigger}`]: 'Show in a bigger format',
    [`${keys.timeOutFirstPart}`]: 'This chat will be terminated within ',
    [`${keys.timeOutSecondPart}`]: 'seconds',
    [`${keys.timeOutThirdPart}`]: ' as you have not written anything for long.',
    [`${keys.closeLiveChatButton}`]: 'End Chat',
    [`${keys.continueLiveChatButton}`]: 'Continue the Chat',
    [`${keys.fileNameNotValidError}`]: 'File name is not valid.',
    [`${keys.fileEmptyError}`]: 'The uploaded file content is empty.',
    [`${keys.liveChatRefused}`]:
      'Currently a different conversation is being made over this account. You can try again later if you still need.',
    [`${keys.maxTimeOutThresholdExceedMessage}`]:
      'This chat was terminated automatically by the system since it has been left idle for long . Thank you for your understanding.',
    [`${keys.agentLeftMessage}`]:
      'Customer representative has terminated the chat.',
    [`${keys.chatConnectionErrorMessage}`]:
      'An error has occurred, please try again.',
    [`${keys.chatInputBarPlaceholderMessage}`]: 'Write a message...',
    [`${keys.resendMediaMessage}`]: 'Please try again.',
    [`${keys.resendMediaMessageForEmptyImage}`]:
      'You are required to upload again.',
    [`${keys.unAuthorizedMessage}`]:
      'You are not authorized to access this page.',
    [`${keys.surveyMessage}`]:
      'Are you satisfied with the conversation you have just made?',
    [`${keys.trendyolAsistantSurveyMessage}`]:
      'Are you satisfied with Trendyol Assistant?',
    [`${keys.surveyCommentMessage}`]: 'Anything else to add?',
    [`${keys.surveyRatingMessage}`]: 'Rate your Trendyol Assistant experience',
    [`${keys.liveChatSurveyRatingMessage}`]: 'Rate our representative',
    [`${keys.surveyRatingBadMessage}`]: 'Very Bad',
    [`${keys.surveyRatingGoodMessage}`]: 'Very Good',
    [`${keys.surveyRateButton}`]: 'Submit',
    [`${keys.surveyErrorModalTitle}`]: 'SURVEY ERROR OCCURRED',
    [`${keys.surveyErrorRatingEmpty}`]: 'Please enter a rate.',
    [`${keys.surveyErrorServiceError}`]:
      'An error has occurred, please try again.',
    [`${keys.surveySuccessModalTitle}`]: 'ASSESSMENT SUCCESSFUL',
    [`${keys.surveySuccessModalMessage}`]:
      'Thank you for your participation in our survey!',
    [`${keys.liveChatNewMessageAppTitle}`]: 'You have a new message...',
    [`${keys.errorPageMessage}`]:
      'A system error has occurred. Please try again.',
    [`${keys.closeOnErrorButton}`]: 'Close',
    [`${keys.reconnectOnErrorButton}`]: 'Reconnect',
    [`${keys.liveChatReconnecting}`]: 'Reconnecting...',
    [`${keys.nameText}`]: 'First Name',
    [`${keys.lastnameText}`]: 'Last Name',
    [`${keys.outOfWorkOurMessage}`]:
      'Our customer service representatives are out of business hours. You may forward your message. We will get back to you as soon as possible.',
    [`${keys.invalidTokenInfoText}`]:
      'We cannot analyze your information. Please exit the application and relogin.',
    [`${keys.newText}`]: 'NEW',
    [`${keys.emailText}`]: 'E-mail',
    [`${keys.continueLiveChatText}`]: 'Go back',
    [`${keys.exitLiveChatModalText}`]: 'Are you sure you want to end chat?',
    [`${keys.exitAndOpenSurvey}`]: 'Confirm end chat',
    [`${keys.clientReconnectingMessage}`]:
      'Dear customer, we are referring you to the customer representative again due to your slow Internet connection. Your chat will be resumed. We kindly ask you to hold, and thank you for your understanding.',
    [`${keys.clientRedirectedMessage}`]:
      'Dear customer, we are transferring you to a different customer representative due to the slow internet connection. Conversation history in this call will be transferred to the new agent. Please wait, thank you for your understanding.',
    [`${keys.stickySurveyTitle}`]: 'Are you satisfied with Trendyol Assistant?',
    [`${keys.stickySurveyRateMessage}`]: 'Please rate us',
    [`${keys.stickySurveyCommentMessage}`]: 'Anything else to add?',
    [`${keys.stickySurveySuccessTitle}`]: 'We Received Your Assessment!',
    [`${keys.stickySurveySuccessText}`]:
      'Thank you for the assessment you have made for us to improve ourselves.',
    [`${keys.stickySurveySuccessButtonText}`]: 'Close',

    [`${keys.placeHolderSurname}`]: 'Enter Last Name',
    [`${keys.placeHolderName}`]: 'Enter First Name',
    [`${keys.yourEmailText}`]: 'E-Mail',
    [`${keys.placeholderEmail}`]: 'Enter E-mail',
    [`${keys.languageText}`]: 'Language',
    [`${keys.placeholderLanguage}`]: 'Select a Language',
    [`${keys.deliveryNumberText}`]: 'Delivery Number',
    [`${keys.placeholderDeliveryNumber}`]: 'Enter Delivery Number',
    [`${keys.startChatText}`]: 'Start chat',
    [`${keys.internationalTicketCreateSucceedTitle}`]:
      'Thanks for reaching out',
    [`${keys.internationalTicketCreateSucceedText}`]:
      'We received your e-mail. We will look into it and get back to you soon',
    [`${keys.internationalTicketCreateFailedTitle}`]: '',
    [`${keys.internationalTicketCreateFailedText}`]:
      'An error has occurred, please try again.',
    [`${keys.closeButtonText}`]: 'Close',
    [`${keys.retryButtonText}`]: 'Try Again',
    [`${keys.placeholderSubject}`]: 'Enter Subject',
    [`${keys.subjectText}`]: 'Subject',
    [`${keys.internationalSubjectImageText}`]: 'Upload Images',
    [`${keys.mailDescriptionText}`]: 'Write your message in detail',
    [`${keys.mailSubmitText}`]: 'Submit',
    [`${keys.placeHolderDescriptionText}`]: 'Enter a description',
    [`${keys.englishLangText}`]: 'English',
    [`${keys.germanLangText}`]: 'German',
    [`${keys.englishLangTextShort}`]: 'ENG',
    [`${keys.selectOrderMsg}`]: 'Select',
    [`${keys.shipmentNo}`]: 'Delivery No',
    [`${keys.orderSelectedMsg}`]: 'Selected',
    [`${keys.quantityText}`]: 'Piece',
    [`${keys.claimOrderClaimableIsNotDone}`]: 'Please fill the fields below.',
    [`${keys.claimQuantityText}`]: 'Select Quantity',
    [`${keys.claimReasonText}`]: 'Reason',
    [`${keys.countAndPriceText}`]: 'Quantity & Price: ',
    [`${keys.claimOrderReason}`]: 'Select return reason',
    [`${keys.cancelOrderSelectProduct}`]:
      'Please select the item and the cancellation reason.',
    [`${keys.cancelOrderSelectReason}`]:
      'Please select the cancellation reason',
    [`${keys.selectReason}`]: 'Select a reason',
    [`${keys.other}`]: 'Explanation',
    [`${keys.explanation}`]:
      'Enter an explanation about the item you want to cancel',
    [`${keys.selectProductImageText}`]: 'Upload images ({{count}} at most)',
    [`${keys.internationalMailSelectProductImageText}`]:
      'Upload images ({{count}} at most)',

    [`${keys.claimOrderDescription}`]: 'Please add details',
    [`${keys.claimSimulationPreviewQuantity}`]: 'Quantity',
    [`${keys.claimSimulationPreviewProduct}`]: 'Item',
    [`${keys.claimOrderFillToContinue}`]:
      'Please fill the quantity and return reason fields completely in order to proceed.',
    [`${keys.claimQuantityText}`]: 'Select quantity',
    [`${keys.optionalFileInfoText}`]: 'Upload images (optional)',
    [`${keys.imageFormatMismatchErrorText}`]:
      'You can only upload JPG and PNG files.',
    [`${keys.imageMaxSizeErrorText}`]:
      'File size should not exceed {{size}} MB.',
    [`${keys.imageCountErrorText}`]: 'You can upload up to {{count}} files.',
    [`${keys.imageReadError}`]:
      'The file could not be processed. Please try again.',
    [`${keys.imageUploadError}`]:
      'An error occurred while uploading the file. Please try again.',
    [`${keys.shipmentSubtitleTotal}`]: 'Total amount',
    [`${keys.shipmentSubtitleItems}`]: 'Item',
    [`${keys.orderNo}`]: 'Order No',
    [`${keys.seeAllAnnouncement}`]: 'See All Announcements',
    [`${keys.showMoreTicket}`]: 'Show More',
    [`${keys.notificationsTitle}`]: 'Notifications',
    [`${keys.totalClaimAmountText}`]: 'Total refunded amount',
    [`${keys.claimAmountText}`]: 'Refunded amount',
    [`${keys.claimCoinAmountText}`]: 'Refunded Trendyol Coins',
    [`${keys.coinText}`]: 'Trendyol Coins',
    [`${keys.coinUsageMessage}`]: 'You used',
    [`${keys.coinUsageOrderMessage}`]: 'on this order!',
    [`${keys.coinsWording}`]: 'coins',
    [`${keys.coinQuantityAndAmountInfo}`]:
      '{{quantity}} {{coinsWording}} ({{amount}} {{currency}})',
    [`${keys.coinEarnedMessage}`]: "You've earned",
    [`${keys.coinEarnedOrderMessage}`]: 'from this order!',
    [`${keys.coinEarnedQuantityAndAmountInfo}`]:
      '{{quantity}} {{coinsWording}} ({{value}} {{currency}})',
    [`${keys.claimStatusCargoText}`]: 'Where is my return?',
    [`${keys.showMoreShipment}`]: 'Show More Products',
    [`${keys.showMoreShipment}`]: 'Show More Items',
    [`${keys.orderText}`]: 'My Orders',
    [`${keys.ordersText}`]: 'My Orders',
    [`${keys.currencyText}`]: '€',
    [`${keys.copyText}`]: 'Copy',
    [`${keys.copiedText}`]: 'Copied',
    [`${keys.deliveryDateText}`]: 'Delivery Date:',
    [`${keys.paymentInfoText}`]: 'Payment Information',
    [`${keys.subtotalText}`]: 'Subtotal',
    [`${keys.cargoText}`]: 'Shipping',
    [`${keys.codServiceFeeText}`]: 'COD Service Fee',
    [`${keys.totalText}`]: 'Total',
    [`${keys.deliveryAddressText}`]: 'Delivery Address',
    [`${keys.orderDate}`]: 'Order Date',
    [`${keys.orderSummary}`]: 'Order Summary',
    [`${keys.productText}`]: 'item',
    [`${keys.linkCopied}`]: 'Order number copied',
    [`${keys.billingAddressText}`]: 'Invoice Address',
    [`${keys.detail}`]: 'Details',
    [`${keys.deliveryTimeText}`]: 'Delivery Date:',
    [`${keys.addExtraImage}`]: 'Add images',
    [`${keys.chatbotErrorMsgKey}`]: 'Error!',
    [`${keys.chatbotShortErrorMsg}`]: 'Something went wrong.',
    [`${keys.collectionPointText}`]: 'Pickup Point',
    [`${keys.commercialText}`]: 'Corporate Invoiced Sale',
    [`${keys.fastMarketText}`]: 'Fast Market',
    [`${keys.mealText}`]: 'Meal',
    [`${keys.groupDealText}`]: 'Buy With Friends',
    [`${keys.wardrobeText}`]: 'DOLAP',
    [`${keys.inputLanguageNotSupported}`]:
      'At the moment, our support services are available in English only. Please type your inquiry in English.',
    [`${keys.myOrderDetail}`]: 'My Order Detail',
    [`${keys.arabicLangText}`]: 'Arabic',
    [`${keys.bulgariaLangText}`]: 'Bulgaria',
    [`${keys.greeceLangText}`]: 'Greece',
    [`${keys.hungaryLangText}`]: 'Hungary',
    [`${keys.czechiaLangText}`]: 'Czechia',
    [`${keys.romaniaLangText}`]: 'Romania',
    [`${keys.polandLangText}`]: 'Poland',
    [`${keys.slovakiaLangText}`]: 'Slovakia',
    [`${keys.showMoreContent}`]: 'Show More',
    [`${keys.showLessContent}`]: 'Show Less',
    [`${keys.comboboxDefaultValue}`]: 'Choose Campaign',
    [`${keys.packageText}`]: 'Package',
    [`${keys.itemText}`]: 'Item',
    [`${keys.userDisconnectedOnBackground}`]:
      'This conversation has been terminated because you either left the application during the conversation or have been inactive for a long time. Thank you for your understanding.',
    [`${keys.refundDetailText}`]: 'Payment Details',
    [`${keys.refundProgressBankInfo}`]:
      'Pending action from your financial institution',
    [`${keys.productImageText}`]: 'Product image',
    [`${keys.refundAmountText}`]: 'Refund amount',
    [`${keys.claimLastModifiedDateTrText}`]:
      'Date on which Trendyol issued your refund',
    [`${keys.claimLastModifiedDateMillaText}`]:
      'Date on which Trendyolmilla issued your refund',
    [`${keys.estimatedRefundDateText}`]: 'Estimated refund period',
    [`${keys.bankRefNoText}`]: 'Bank reference number',
    [`${keys.refundProgressTrInfo}`]: 'Trendyol issued your refund',
    [`${keys.refundProgressMillaInfo}`]: 'Trendyolmilla issued your refund',
  },
};
