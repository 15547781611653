import React from 'react';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Form } from 'antd';
import FormItem from '../form/FormItem';
import './DynamicField.scss';
import { deleteText } from '../../../i18n/keys';
import { useTranslation } from 'react-i18next';

function DynamicField(props) {
  const { t } = useTranslation();
  return (
    <Form.List name="items" rules={[{ required: false }]}>
      {(fields, { add, remove }) => {
        fields.length === 0 && add();
        return (
          <div className="dynamic-field-container">
            {fields.map((field, index) => (
              <div key={field.key} className="dynamic-field-item">
                {fields.length > 1 && index !== 0 ? <Divider /> : null}
                {fields.length > 1 ? (
                  <div className="close-outlined-icon">
                    <Button
                      shape="round"
                      onClick={() => remove(index)}
                      disabled={!props.allowSendRequest}
                      icon={
                        <CloseOutlined
                          style={{ fontSize: '10px', alignContent: 'center' }}
                        />
                      }>
                      {t(deleteText)}
                    </Button>
                  </div>
                ) : null}
                {props.items.map((item, itemIndex) => (
                  <FormItem
                    id={props.id}
                    name={[index, item.name]}
                    key={`form-item-${itemIndex}`}
                    item={item}
                    disabled={!props.allowSendRequest}
                    formListIndex={index}
                    lang={props.lang}
                    form={props.form}
                  />
                ))}
              </div>
            ))}
            {fields.length < props.maxItemCount && (
              <Button
                className="add-new-item-button"
                type="text"
                onClick={() => add()}
                disabled={!props.allowSendRequest}>
                <PlusCircleOutlined className="plus-circle-outlined-icon" />
                {props.addItemLabel}
              </Button>
            )}
          </div>
        );
      }}
    </Form.List>
  );
}

export default DynamicField;
