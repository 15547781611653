import * as keys from './keys';

export default {
  translation: {
    [`${keys.appTitle}`]: 'Βοηθός της Trendyol',
    [`${keys.appTitleTgoApp}`]: 'Βοηθός',
    [`${keys.appTitleMilla}`]: 'Βοηθός',
    [`${keys.appTabTitle}`]: 'Βοηθός της Trendyol',
    [`${keys.liveChatTitle}`]: 'Ζωντανή συνομιλία',
    [`${keys.liveChatTitleTgoApp}`]: 'Ζωντανή συνομιλία',
    [`${keys.liveChatTitleMilla}`]: 'Ζωντανή συνομιλία',
    [`${keys.liveChatTabTitle}`]: 'Ζωντανή συνομιλία',
    [`${keys.chatbotErrorMsg}`]:
      'Παρουσιάστηκε ένα σφάλμα συστήματος. Προσπαθήστε ξανά.',
    [`${keys.liveChatLoadingMessage}`]:
      'Σύνδεση με τον εκπρόσωπο εξυπηρέτησης πελατών μας, παρακαλώ περιμένετε...',
    [`${keys.agentsBusyMessage}`]:
      'Όλοι οι εκπρόσωποί μας εξυπηρετούν προς το παρόν άλλους πελάτες, παρακαλούμε περιμένετε...',
    [`${keys.liveChatConnecting}`]: 'Σύνδεση...',
    [`${keys.systemJoined}`]: 'Ανάθεση σε εκπρόσωπο εξυπηρέτησης πελατών...',
    [`${keys.agentDisconnected}`]: 'Ανάθεση σε νέο εκπρόσωπο....',
    [`${keys.agentJoined}`]: 'Συνδεδεμένος',
    [`${keys.systemJoinedMessage}`]: 'Καλώς ήρθατε στο Live Chat της Trendyol.',
    [`${keys.liveChatOffline}`]: 'Αποσυνδεδεμένος',
    [`${keys.makeUploadedContentBigger}`]: 'Εμφάνιση σε μεγαλύτερο μορφή',
    [`${keys.timeOutFirstPart}`]: 'Αυτή η συνομιλία θα τερματιστεί εντός',
    [`${keys.timeOutSecondPart}`]: 'δευτερόλεπτων',
    [`${keys.timeOutThirdPart}`]:
      'καθώς δεν έχετε γράψει τίποτα για αρκετό λεπτά.',
    [`${keys.fileNameNotValidError}`]: 'Το όνομα αρχείου δεν είναι έγκυρο.',
    [`${keys.fileEmptyError}`]:
      'Το περιεχόμενο του αρχείου που ανεβάσατε είναι κενό.',
    [`${keys.closeLiveChatButton}`]: 'Τέλος συνομιλίας',
    [`${keys.continueLiveChatButton}`]: 'Συνεχίστε να συνομιλείτε',
    [`${keys.liveChatRefused}`]:
      'Μια διαφορετική συνομιλία διεξάγεται προς το παρόν σε αυτόν τον λογαριασμό. Προσπαθήστε ξανά αργότερα, αν εξακολουθείτε να θέλετε.',
    [`${keys.maxTimeOutThresholdExceedMessage}`]:
      'Αυτή η συνομιλία τερματίστηκε αυτόματα από το σύστημα, καθώς ήταν αδρανής για αρκετό διάστημα. Σας ευχαριστούμε για την κατανόησή σας.',
    [`${keys.agentLeftMessage}`]:
      'Ο εκπρόσωπος εξυπηρέτησης πελατών τερμάτισε τη συνομιλία.',
    [`${keys.chatConnectionErrorMessage}`]:
      'Παρουσιάστηκε ένα σφάλμα, προσπαθήστε ξανά.',
    [`${keys.chatInputBarPlaceholderMessage}`]: 'Γράψτε ένα μήνυμα...',
    [`${keys.resendMediaMessage}`]: 'Προσπαθήστε ξανά.',
    [`${keys.resendMediaMessageForEmptyImage}`]: 'Ανεβάστε ξανά.',
    [`${keys.unAuthorizedMessage}`]:
      'Δεν έχετε δικαίωμα πρόσβασης σε αυτή τη σελίδα.',
    [`${keys.surveyMessage}`]:
      'Είστε ικανοποιημένοι με τη συνομιλία που μόλις είχατε;',
    [`${keys.trendyolAsistantSurveyMessage}`]:
      'Είστε ικανοποιημένοι με τον Βοηθό της Trendyol;',
    [`${keys.surveyCommentMessage}`]: 'Έχετε να προσθέσετε κάτι άλλο;',
    [`${keys.surveyRatingMessage}`]:
      'Βαθμολογήστε την εμπειρία σας με τον Βοηθό της Trendyol',
    [`${keys.liveChatSurveyRatingMessage}`]: 'Βαθμολογήστε τον εκπρόσωπό μας',
    [`${keys.surveyRatingBadMessage}`]: 'Πολύ κακή',
    [`${keys.surveyRatingGoodMessage}`]: 'Πολύ καλή',
    [`${keys.surveyRateButton}`]: 'Υποβολή',
    [`${keys.surveyErrorModalTitle}`]: 'ΠΡΟΕΚΥΨΕ ΣΦΑΛΜΑ ΣΤΗΝ ΕΡΕΥΝΑ',
    [`${keys.surveyErrorRatingEmpty}`]: 'Εισάγετε μια βαθμολογία.',
    [`${keys.surveyErrorServiceError}`]:
      'Παρουσιάστηκε ένα σφάλμα, προσπαθήστε ξανά.',
    [`${keys.surveySuccessModalTitle}`]: 'ΕΠΙΤΥΧΗΣ ΑΞΙΟΛΟΓΗΣΗ',
    [`${keys.surveySuccessModalMessage}`]:
      'Σας ευχαριστούμε για τη συμμετοχή σας στην έρευνά μας!',
    [`${keys.liveChatNewMessageAppTitle}`]: 'Έχετε ένα νέο μήνυμα...',
    [`${keys.errorPageMessage}`]:
      'Παρουσιάστηκε ένα σφάλμα συστήματος. Προσπαθήστε ξανά.',
    [`${keys.closeOnErrorButton}`]: 'Κλείσιμο',
    [`${keys.reconnectOnErrorButton}`]: 'Επανασύνδεση',
    [`${keys.liveChatReconnecting}`]: 'Επανασύνδεση...',
    [`${keys.nameText}`]: 'Όνομα',
    [`${keys.lastnameText}`]: 'Επώνυμο',
    [`${keys.outOfWorkOurMessage}`]:
      'Οι εκπρόσωποι εξυπηρέτησης πελατών μας είναι εκτός ωραρίου λειτουργίας. Μπορείτε να προωθήσετε το μήνυμά σας. Θα επικοινωνήσουμε μαζί σας το συντομότερο δυνατό.',
    [`${keys.invalidTokenInfoText}`]:
      'Δεν μπορούμε να αναλύσουμε τις πληροφορίες σας. Αποχωρήστε από την εφαρμογή και συνδεθείτε ξανά.',
    [`${keys.newText}`]: 'ΝΕΑ',
    [`${keys.emailText}`]: 'Ηλεκτρονικό ταχυδρομείο',
    [`${keys.continueLiveChatText}`]: 'Επιστροφή',
    [`${keys.exitLiveChatModalText}`]:
      'Είστε σίγουροι ότι θέλετε να τερματίσετε τη συνομιλία;',
    [`${keys.exitAndOpenSurvey}`]: 'Tέλος συνομιλίας',
    [`${keys.clientReconnectingMessage}`]:
      'Αγαπητέ πελάτη, σας παραπέμπουμε και πάλι στον υπεύθυνο εξυπηρέτησης πελατών λόγω της αργής σύνδεσής σας στο διαδίκτυο. Η συνομιλία σας θα συνεχιστεί. Σας παρακαλούμε να περιμένετε και σας ευχαριστούμε για την κατανόησή σας.',
    [`${keys.clientRedirectedMessage}`]:
      'Αγαπητέ πελάτη, σας μεταφέρουμε σε έναν άλλο εκπρόσωπο εξυπηρέτησης πελατών λόγω της αργής σύνδεσης στο διαδίκτυο. Το ιστορικό συνομιλιών για αυτή την κλήση θα μεταφερθεί στον νέο υπεύθυνο. Παρακαλώ περιμένετε, σας ευχαριστούμε για την κατανόησή σας.',
    [`${keys.stickySurveyTitle}`]:
      'Είστε ικανοποιημένοι με τον Βοηθό της Trendyol;',
    [`${keys.stickySurveyRateMessage}`]: 'Βαθμολογήστε μας',
    [`${keys.stickySurveyCommentMessage}`]: 'Έχετε να προσθέσετε κάτι άλλο;',
    [`${keys.stickySurveySuccessTitle}`]: 'Λάβαμε την αξιολόγησή σας!',
    [`${keys.stickySurveySuccessText}`]:
      'Σας ευχαριστούμε για την αξιολόγηση που κάνατε για να βελτιωθούμε.',
    [`${keys.stickySurveySuccessButtonText}`]: 'Κλείσιμο',
    [`${keys.placeHolderSurname}`]: 'Εισάγετε το επώνυμο',
    [`${keys.placeHolderName}`]: 'Εισάγετε το όνομα',
    [`${keys.yourEmailText}`]: 'Ηλεκτρονικό ταχυδρομείο',
    [`${keys.placeholderEmail}`]: 'Εισάγετε το ηλεκτρονικό ταχυδρομείο',
    [`${keys.languageText}`]: 'Γλώσσα',
    [`${keys.placeholderLanguage}`]: 'Επιλέξτε μια γλώσσα',
    [`${keys.deliveryNumberText}`]: 'Αριθμός παράδοσης',
    [`${keys.placeholderDeliveryNumber}`]: 'Εισάγετε τον αριθμό παράδοσης',
    [`${keys.startChatText}`]: 'Έναρξη συνομιλίας',
    [`${keys.internationalTicketCreateSucceedTitle}`]:
      'Ευχαριστούμε για την επικοινωνία',
    [`${keys.internationalTicketCreateSucceedText}`]:
      'Λάβαμε το ηλεκτρονικό σας μήνυμα. Θα το εξετάσουμε και θα επικοινωνήσουμε μαζί σας σύντομα.',
    [`${keys.internationalTicketCreateFailedTitle}`]: '',
    [`${keys.internationalTicketCreateFailedText}`]:
      'Παρουσιάστηκε ένα σφάλμα, προσπαθήστε ξανά.',
    [`${keys.closeButtonText}`]: 'Κλείσιμο',
    [`${keys.retryButtonText}`]: 'Προσπαθήστε ξανά',
    [`${keys.placeholderSubject}`]: 'Εισάγετε το θέμα',
    [`${keys.subjectText}`]: 'Θέμα',
    [`${keys.internationalSubjectImageText}`]: 'Μεταφόρτωση εικόνων',
    [`${keys.mailDescriptionText}`]: 'Γράψτε λεπτομερώς το μήνυμά σας',
    [`${keys.mailSubmitText}`]: 'Υποβολή',
    [`${keys.placeHolderDescriptionText}`]: 'Εισάγετε μια περιγραφή',
    [`${keys.englishLangText}`]: 'Αγγλικά',
    [`${keys.germanLangText}`]: 'Γερμανικά',
    [`${keys.englishLangTextShort}`]: 'ENG',
    [`${keys.selectOrderMsg}`]: 'Επιλέξτε',
    [`${keys.shipmentNo}`]: 'Αρ. παράδοσης',
    [`${keys.orderSelectedMsg}`]: 'Επιλεγμένο',
    [`${keys.quantityText}`]: 'Τεμάχιο',
    [`${keys.claimOrderClaimableIsNotDone}`]: 'Συμπληρώστε τα παρακάτω πεδία.',
    [`${keys.claimQuantityText}`]: 'Επιλέξτε ποσότητα',
    [`${keys.claimReasonText}`]: 'Λόγος',
    [`${keys.countAndPriceText}`]: 'Ποσότητα & Τιμή:',
    [`${keys.claimOrderReason}`]: 'Επιλέξτε λόγο επιστροφής',
    [`${keys.selectProductImageText}`]:
      'Μεταφόρτωση εικόνων ({{count}} το πολύ)',
    [`${keys.internationalMailSelectProductImageText}`]:
      'Μεταφόρτωση εικόνων ({{count}} το πολύ)',
    [`${keys.claimOrderDescription}`]: 'Προσθέστε λεπτομέρειες',
    [`${keys.claimSimulationPreviewQuantity}`]: 'Ποσότητα',
    [`${keys.claimSimulationPreviewProduct}`]: 'Προϊόν',
    [`${keys.claimOrderFillToContinue}`]:
      'Συμπληρώστε πλήρως τα πεδία "ποσότητα" και "λόγος επιστροφής" για να προχωρήσετε.',
    [`${keys.claimQuantityText}`]: 'Επιλέξτε ποσότητα',
    [`${keys.optionalFileInfoText}`]: 'Μεταφόρτωση εικόνων (προαιρετικά)',
    [`${keys.imageFormatMismatchErrorText}`]:
      'Μπορείτε να ανεβάσετε μόνο αρχεία JPG και PNG.',
    [`${keys.imageMaxSizeErrorText}`]:
      'Το μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα {{size}} MB.',
    [`${keys.imageCountErrorText}`]:
      'Μπορείτε να ανεβάσετε έως και {{count}} αρχεία.',
    [`${keys.imageReadError}`]:
      'Δεν ήταν δυνατή η επεξεργασία του αρχείου. Προσπαθήστε ξανά.',
    [`${keys.imageUploadError}`]:
      'Προέκυψε σφάλμα κατά τη μεταφόρτωση του αρχείου. Προσπαθήστε ξανά.',
    [`${keys.shipmentSubtitleTotal}`]: 'Συνολικό ποσό',
    [`${keys.shipmentSubtitleItems}`]: 'Προϊόν',
    [`${keys.orderNo}`]: 'Αριθμός παραγγελίας.',
    [`${keys.seeAllAnnouncement}`]: 'Δείτε όλες τις ανακοινώσεις',
    [`${keys.showMoreTicket}`]: 'Εμφάνιση περισσότερων',
    [`${keys.notificationsTitle}`]: 'Ειδοποιήσεις',
    [`${keys.totalClaimAmountText}`]: 'Συνολικό ποσό επιστροφής',
    [`${keys.claimAmountText}`]: 'Επιστρεφόμενο χρηματικό ποσό',
    [`${keys.claimCoinAmountText}`]: 'Επιστρεφόμενα Trendyol Coin',
    [`${keys.coinText}`]: 'Trendyol Coin',
    [`${keys.coinUsageMessage}`]: 'Χρησιμοποιήσατε',
    [`${keys.coinUsageOrderMessage}`]: 'σε αυτή την παραγγελία!',
    [`${keys.coinsWording}`]: 'coins',
    [`${keys.coinQuantityAndAmountInfo}`]:
      '{{quantity}} {{coinsWording}} ({{amount}} {{currency}})',
    [`${keys.claimStatusCargoText}`]: 'Πού είναι η επιστροφή μου;',
    [`${keys.showMoreShipment}`]: 'Εμφάνιση περισσότερων προϊόντων',
    [`${keys.showMoreShipment}`]: 'Εμφάνιση περισσότερων προϊόντων',
    [`${keys.orderText}`]: 'Οι παραγγελίες μου',
    [`${keys.ordersText}`]: 'Οι παραγγελίες μου',
    [`${keys.currencyText}`]: '€',
    [`${keys.copyText}`]: 'Αντιγραφή',
    [`${keys.copiedText}`]: 'Αντιγράφηκε',
    [`${keys.deliveryDateText}`]: 'Ημερομηνία παράδοσης:',
    [`${keys.paymentInfoText}`]: 'Πληροφορίες πληρωμής',
    [`${keys.subtotalText}`]: 'Υποσύνολο',
    [`${keys.cargoText}`]: 'Αποστολή',
    [`${keys.totalText}`]: 'Σύνολο',
    [`${keys.deliveryAddressText}`]: 'Διεύθυνση παράδοσης',
    [`${keys.orderDate}`]: 'Ημερομηνία παραγγελίας',
    [`${keys.orderSummary}`]: 'Περίληψη παραγγελίας',
    [`${keys.productText}`]: 'αντικείμενο',
    [`${keys.linkCopied}`]: 'Ο αριθμός παραγγελίας αντιγράφηκε',
    [`${keys.billingAddressText}`]: 'Διεύθυνση τιμολογίου',
    [`${keys.detail}`]: 'Λεπτομέρειες',
    [`${keys.deliveryTimeText}`]: 'Ημερομηνία παράδοσης:',
    [`${keys.addExtraImage}`]: 'Προσθήκη εικόνων',
    [`${keys.chatbotErrorMsgKey}`]: 'Σφάλμα!',
    [`${keys.chatbotShortErrorMsg}`]: 'Κάτι πήγε στραβά.',
    [`${keys.collectionPointText}`]: 'Σημείο παραλαβής',
    [`${keys.commercialText}`]: 'Εταιρική πώληση με τιμολόγιο',
    [`${keys.fastMarketText}`]: 'Γρήγορη αγορά',
    [`${keys.mealText}`]: 'Γεύμα',
    [`${keys.groupDealText}`]: 'Αγοράστε με φίλους',
    [`${keys.wardrobeText}`]: 'DOLAP',
    [`${keys.inputLanguageNotSupported}`]:
      'Οι υπηρεσίες υποστήριξής μας είναι προς το παρόν διαθέσιμες μόνο στα αγγλικά. Πληκτρολογήστε το ερώτημά σας στα αγγλικά.',
    [`${keys.myOrderDetail}`]: 'Οι λεπτομέρειες της παραγγελίας μου',
    [`${keys.arabicLangText}`]: 'Αραβικά',
    [`${keys.showMoreContent}`]: 'Εμφάνιση περισσότερων',
    [`${keys.showLessContent}`]: 'Εμφάνιση λιγότερων',
    [`${keys.bulgariaLangText}`]: 'Βουλγαρικά',
    [`${keys.polandLangText}`]: 'Πολωνικά',
    [`${keys.slovakiaLangText}`]: 'Σλοβακικά',
    [`${keys.romaniaLangText}`]: 'Ρουμανικά',
    [`${keys.czechiaLangText}`]: 'Τσέχικα',
    [`${keys.hungaryLangText}`]: 'Ουγγρικά',
    [`${keys.greeceLangText}`]: 'Ελληνικά',
    [`${keys.cancelOrderSelectProduct}`]:
      'Επιλέξτε τα προϊόντα και τον λόγο ακύρωσης.',
    [`${keys.cancelOrderSelectReason}`]: 'Επιλέξτε τον λόγο ακύρωσης',
    [`${keys.selectReason}`]: 'Επιλέξτε έναν λόγο',
    [`${keys.other}`]: 'Αιτιολογία',
    [`${keys.explanation}`]:
      'Εισάγετε μια αιτιολογία για το προϊόν που θέλετε να ακυρώσετε',
    [`${keys.userDisconnectedOnBackground}`]:
      'Αυτή η συνομιλία τερματίστηκε επειδή είτε αποχωρήσατε από την εφαρμογή κατά τη διάρκεια της συνομιλίας είτε ήσασταν ανενεργός/ή για μεγάλο χρονικό διάστημα. Σας ευχαριστούμε για την κατανόηση.',
    [`${keys.refundDetailText}`]: 'Προβολή λεπτομερειών επιστροφής',
    [`${keys.refundProgressBankInfo}`]:
      'Εκκρεμότητα ενεργειών από την τράπεζά σας',
    [`${keys.productImageText}`]: 'Φωτογραφία προϊόντος',
    [`${keys.refundAmountText}`]: 'Ποσό επιστροφής',
    [`${keys.claimLastModifiedDateTrText}`]:
      'Ημερομηνία επιστροφής χρημάτων από την Trendyol',
    [`${keys.claimLastModifiedDateMillaText}`]:
      'Ημερομηνία επιστροφής χρημάτων από την Trendyolmilla',
    [`${keys.estimatedRefundDateText}`]:
      'Εκτιμώμενος χρόνος επιστροφής χρημάτων',
    [`${keys.bankRefNoText}`]: 'Αριθ. συναλλαγής',
    [`${keys.refundProgressTrInfo}`]: 'Επιστροφή χρημάτων από την Trendyol',
    [`${keys.refundProgressMillaInfo}`]:
      'Επιστροφή χρημάτων από την Trendyolmilla',
  },
};
