import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import utils from '../../../utils';
import './ClientMessage.scss';
import classNames from 'classnames';
import appService from '../../../services/app.service';
import DOMPurify from 'dompurify';

const ClientMessage = ({
  requestMessage,
  isLiveChat,
  isAgentJoined,
  timestamp,
}) => {
  const [date, setDate] = useState(null);

  const renderHtml = (data) => {
    return DOMPurify.sanitize(data);
  };

  useEffect(() => {
    if (typeof timestamp === 'number') {
      const date = new Date(timestamp);
      setDate(utils.getTimeFromDate(date));
    } else {
      setDate(utils.getCurrentTime());
    }
  }, []);

  return (
    <div
      className={classNames([
        'live-chat-client-message',
        {
          dynamic: appService.isDynamicStylingChannel(),
        },
      ])}
      data-testid="live-chat-client-message">
      <div className="live-chat-client-message__container">
        <div
          className={classNames({
            'live-chat-client-message__container__bubble': true,
            'live-chat-client-message__container__bubble--agent-not-joined':
              !isAgentJoined,
          })}>
          <div
            className="live-chat-client-message__container__bubble__message"
            dangerouslySetInnerHTML={{ __html: renderHtml(requestMessage) }}
          />

          {isLiveChat && date ? (
            <div
              className="live-chat-client-message__container__bubble__time"
              data-testid="date">
              {date}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

ClientMessage.propTypes = {
  requestMessage: PropTypes.string.isRequired,
  isLiveChat: PropTypes.bool.isRequired,
  isAgentJoined: PropTypes.bool.isRequired,
};

export default React.memo(ClientMessage);
