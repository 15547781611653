import { useEffect, useState } from 'react';

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('click', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('click', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const useIframeComponentData = (componentRef) => {
  const [outsideData, setOutSideData] = useState();
  const [shouldResizeIframe, setShouldResizeIframe] = useState(false);

  const onResize = () => {
    const height = componentRef.current?.scrollHeight;

    if (height) {
      window.parent.postMessage({ type: 'iframe-height', height }, '*');
    }
    setShouldResizeIframe(false);
  };

  const handleGetData = (e) => {
    if (e.data && e.data.type === 'component-data') {
      setOutSideData(e.data.componentData);
    }
  };

  useEffect(() => {
    if (!outsideData?.isStaticHeight) {
      onResize();
      window.addEventListener('resize', onResize);
      window.addEventListener('load', onResize);
    }

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('load', onResize);
    };
  }, [outsideData, shouldResizeIframe]);

  useEffect(() => {
    window.addEventListener('message', handleGetData);
    return () => {
      window.removeEventListener('message', handleGetData);
    };
  }, []);

  return { outsideData, setShouldResizeIframe };
};
