import React, { useContext } from 'react';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import Utils from '../../../utils';
import utils from '../../../utils';
import usePersistentState from '../../common/usePersistentState';
import './ButtonWithParams.scss';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import appService from '../../../services/app.service';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Bubble from '../../layout/bubble/Bubble';

const ButtonWithParams = (data) => {
  const { id, componentData, history } = data;
  const { buttonsWithParams, label } = componentData;
  const [selectedButtonIndex] = usePersistentState(
    id,
    'selectedButtonIndex',
    -1
  );
  const chatBotContext = useContext(ChatBotContext);
  const { requestForDeepLink } = chatBotContext;

  const selectedButton = (button) => {
    appService.setEchoCustomFields([button.buttonParams]);

    utils.handleClickDeeplink(
      button.event,
      button.collectEvent,
      button.url,
      requestForDeepLink,
      history
    );
  };

  return (
    <div className="button-with-params">
      <Bubble title={label} isBottomRounded={false} isBordered={false}>
        <ButtonGroup>
          {buttonsWithParams &&
            buttonsWithParams.map((button, index) => {
              const buttonType = button.buttonType;
              let colorType = Utils.getColorType(buttonType);
              let outline = button.outline !== false;

              return (
                <ButtonGroup.Button
                  key={button.text}
                  isSelectable={data.showHover}
                  data-testid="operation-button"
                  outline={outline}
                  colorType={colorType}
                  selected={selectedButtonIndex === index}
                  iconLeft={Utils.getButtonIcon(buttonType, button.iconUrl)}
                  onClick={() => selectedButton(button)}>
                  {button.text}
                </ButtonGroup.Button>
              );
            })}
        </ButtonGroup>
      </Bubble>
    </div>
  );
};

ButtonWithParams.propTypes = {
  data: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default withRouter(ButtonWithParams);
