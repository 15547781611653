import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Bubble from '../../../layout/bubble/Bubble';
import NumberFormat from 'react-number-format';
import chatBotService from '../../../../services/chatbot.service';
import Select from '../../../../components/Select';
import classnames from 'classnames';
import locationIcon from '../../../../assets/icons/location-icon.svg';
import './newAddress.scss';
import {
  addressText,
  confirmAddressText,
  districtText,
  lastnameText,
  nameText,
  placeHolderAddress,
  placeHolderDistrict,
  placeHolderName,
  placeHolderNeighborhood,
  placeHolderProvince,
  placeHolderSurname,
  provinceText,
  selectRegisteredAddressText,
  streetText,
  telephoneText,
} from '../../../../i18n/keys';
import usePersistentState from '../../../common/usePersistentState';
import ButtonGroup from '../../buttonGroup/ButtonGroup';
import {
  ONLY_LETTER_REGEX,
  ONLY_ONE_SPACE_REGEX,
} from '../../../../constants/validationRegexConstants';
import TextInput from '../../../common/TextInput';
import InputLabel from '../../../common/InputLabel';
import TextArea from '../../../common/TextArea';

const NewAddress = ({ id, componentData, select, isSelectable }) => {
  const { t } = useTranslation();
  const [name, setName] = usePersistentState(id, 'name', '');
  const [lastname, setLastName] = usePersistentState(id, 'lastname', '');
  const [telephone, setTelephone] = usePersistentState(id, 'telephone', '');
  const [provinceName, setProvinceName] = usePersistentState(
    id,
    'provinceName',
    ''
  );
  const [provinceId, setProvinceId] = usePersistentState(
    id,
    'provinceId',
    '-1'
  );
  const [provinceCode, setProvinceCode] = usePersistentState(
    id,
    'provinceCode',
    ''
  );
  const [districtName, setDistrictName] = usePersistentState(
    id,
    'districtName',
    ''
  );
  const [districtId, setDistrictId] = usePersistentState(
    id,
    'districtId',
    '-1'
  );
  const [districts, setDistricts] = usePersistentState(id, 'districts', []);
  const [streetName, setStreetName] = usePersistentState(id, 'streetName', '');
  const [streetId, setStreetId] = usePersistentState(id, 'streetId', '-1');
  const [streetPostCode, setStreetPostCode] = usePersistentState(
    id,
    'streetPostCode',
    ''
  );
  const [neighborhoods, setNeighborhoods] = usePersistentState(
    id,
    'neighborhoods',
    []
  );
  const [address, setAddress] = usePersistentState(id, 'address', '');
  const [isActive, setIsActive] = usePersistentState(id, 'isActive', false);

  const MAX_CHARACTER_LIMIT_FOR_NAME_SURNAME = 50;
  const MAX_CHARACTER_LIMIT = 248;

  useEffect(() => {
    checkNewAddress();
  });

  const getProvince = () => {
    const cities = [];
    componentData.cities.map((province) =>
      cities.push({
        value: province.id,
        label: province.name,
      })
    );
    return cities;
  };

  const getDistrict = () => {
    let districtList = [];
    districts.map((district) =>
      districtList.push({
        value: district.id,
        label: district.name,
      })
    );
    return districtList;
  };

  const getStreet = () => {
    let neighborhoodsList = [];
    neighborhoods.map((street) =>
      neighborhoodsList.push({
        value: street.id,
        label: street.name,
      })
    );
    return neighborhoodsList;
  };

  const checkNewAddress = () => {
    if (
      name !== '' &&
      lastname !== '' &&
      telephone.length === 9 &&
      provinceId !== '-1' &&
      districtId !== '-1' &&
      streetId !== '-1' &&
      address !== '' &&
      address.length > 10 &&
      address.length < 496
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const handleNewAddress = () => {
    if (isSelectable && isActive) {
      setIsActive(false);
      let request = {
        event: componentData.event,
        data: {
          addressModel: {
            address: address,
            cityId: provinceId,
            city: provinceName,
            cityCode: provinceCode,
            districtId: districtId,
            district: districtName,
            neighborhoodId: streetId,
            neighborhood: streetName,
            phone: '05' + telephone,
            firstName: name,
            lastName: lastname,
            postalCode: streetPostCode,
          },
        },
      };
      select(request);
    }
  };

  const handleTelephone = (e) => {
    setTelephone(e.value);
  };

  const handleSelectAddress = () => {
    if (isSelectable) {
      setIsActive(false);

      let request = {
        event: componentData.addressUpdateEvent,
      };
      select(request);
    }
  };

  const handleProvince = (id) => {
    let city = componentData.cities.find((city) => city.id === id);
    setProvinceId(id);
    setProvinceName(city.name);
    setProvinceCode(city.code);
    setDistrictId('-1');
    setDistrictName('');
    setStreetId('-1');
    setStreetName('');
    chatBotService
      .getDistricts(id)
      .then((response) => {
        setDistricts(response);
      })
      .catch(() => {});
  };

  const handleStreet = (id) => {
    let neighborhood = neighborhoods.find((city) => city.id === id);
    setStreetName(neighborhood.name);
    setStreetId(id);
    setStreetPostCode(neighborhood.postCode);
  };

  const handleDistricts = (id) => {
    let district = districts.find((d) => d.id === id);
    setDistrictId(id);
    setDistrictName(district.name);
    setStreetId('-1');
    setStreetName('');
    chatBotService
      .getNeighborhoods(provinceId, id)
      .then((response) => {
        setNeighborhoods(response);
      })
      .catch(() => {});
  };

  const onNameSurnameKeyDown = (e) => {
    let nameAttr = e.target.name;

    if (e.keyCode === 8) {
      if (e.target.value.length > MAX_CHARACTER_LIMIT_FOR_NAME_SURNAME) {
        let newValue = e.target.value.substring(0, e.target.value.length - 1);
        if (nameAttr === 'name') {
          setName(newValue);
        } else if (nameAttr === 'lastname') {
          setLastName(newValue);
        }
      }
    }
  };

  const handleName = (value) => {
    if (value.length >= MAX_CHARACTER_LIMIT_FOR_NAME_SURNAME) {
      value = value.substring(0, MAX_CHARACTER_LIMIT_FOR_NAME_SURNAME);
    }
    let newValue = value.replace(ONLY_LETTER_REGEX, '');

    newValue = newValue.replace(ONLY_ONE_SPACE_REGEX, '');
    setName(newValue);
  };

  const handleLastname = (value) => {
    if (value.length >= MAX_CHARACTER_LIMIT_FOR_NAME_SURNAME) {
      value = value.substring(0, MAX_CHARACTER_LIMIT_FOR_NAME_SURNAME);
    }
    let newValue = value.replace(ONLY_LETTER_REGEX, '');
    newValue = newValue.replace(ONLY_ONE_SPACE_REGEX, '');

    setLastName(newValue);
  };

  const handleAddressArea = (value) => {
    if (value.length >= MAX_CHARACTER_LIMIT) {
      value = value.substring(0, MAX_CHARACTER_LIMIT);
    }
    let newValue = value.replace(ONLY_ONE_SPACE_REGEX, '');
    setAddress(newValue);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 8) {
      if (address.length > MAX_CHARACTER_LIMIT) {
        let newAddress = address.substring(0, address.length - 1);
        setAddress(newAddress);
      }
    }
  };

  return (
    <div
      className={classnames([
        'new-address-wrapper',
        { disabled: !isSelectable },
      ])}>
      <Bubble
        isBordered={true}
        title={componentData.title}
        isBottomRounded={false}>
        <TextInput
          label={t(nameText)}
          required
          data-testid="name"
          value={name}
          placeholder={t(placeHolderName)}
          onKeyDown={onNameSurnameKeyDown}
          onChange={(e) => handleName(e.target.value)}
          disabled={!isSelectable}
        />
        <TextInput
          label={t(lastnameText)}
          required
          data-testid="lastname"
          value={lastname}
          placeholder={t(placeHolderSurname)}
          onKeyDown={onNameSurnameKeyDown}
          onChange={(e) => handleLastname(e.target.value)}
          disabled={!isSelectable}
        />
        <div className="telephone">
          <InputLabel text={t(telephoneText)} required />
          <NumberFormat
            data-testid="telephone"
            disabled={!isSelectable}
            className="assistant-input"
            format="0 (5##) ### ## ##"
            mask="_"
            onValueChange={(value) => handleTelephone(value)}
            value={telephone}
            allowEmptyFormatting
          />
        </div>
        <div className="province-district">
          <div className="province">
            <div className="select-province">
              <Select
                options={getProvince()}
                data-testid="select-province"
                label={t(provinceText)}
                required
                placeholder={t(placeHolderProvince)}
                value={provinceId === '-1' ? undefined : provinceId}
                onChange={handleProvince}
                disabled={!isSelectable}
              />
            </div>
          </div>
          <div className="district">
            <div className="select-district">
              <Select
                options={getDistrict()}
                data-testid="select-district"
                label={t(districtText)}
                required
                placeholder={t(placeHolderDistrict)}
                value={districtId === '-1' ? undefined : districtId}
                onChange={handleDistricts}
                disabled={!isSelectable}
              />
            </div>
          </div>
        </div>
        <div className="street">
          <Select
            options={getStreet()}
            data-testid="select-street"
            label={t(streetText)}
            required
            placeholder={t(placeHolderNeighborhood)}
            value={streetId === '-1' ? undefined : streetId}
            onChange={handleStreet}
            disabled={!isSelectable}
          />
        </div>
        <div className="address-field">
          <InputLabel text={t(addressText)} required />
          <TextArea
            data-testid="address"
            value={address}
            className="assistant-textarea"
            placeholder={t(placeHolderAddress)}
            onChange={(e) => handleAddressArea(e.target.value)}
            onKeyDown={onKeyDown}
            disabled={!isSelectable}
          />
        </div>
        <ButtonGroup>
          <ButtonGroup.Button
            isSelectable={isSelectable && isActive}
            colorType={'primary'}
            data-testid="button-confirm"
            onClick={() => handleNewAddress()}
            outline={false}
            disabled={!isSelectable || !isActive}>
            {t(confirmAddressText)}
          </ButtonGroup.Button>
          <ButtonGroup.Button
            isSelectable={isSelectable}
            iconLeft={locationIcon}
            colorType={'primary'}
            data-testid="button-select-registered"
            onClick={() => handleSelectAddress()}
            outline={true}
            disabled={!isSelectable}>
            {t(selectRegisteredAddressText)}
          </ButtonGroup.Button>
        </ButtonGroup>
      </Bubble>
    </div>
  );
};

export default NewAddress;
