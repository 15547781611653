import * as keys from './keys';

export default {
  translation: {
    [`${keys.appTitle}`]: 'Trendyol asszisztens',
    [`${keys.appTitleTgoApp}`]: 'Asszisztens',
    [`${keys.appTitleMilla}`]: 'Asszisztens',
    [`${keys.appTabTitle}`]: 'Trendyol asszisztens',
    [`${keys.liveChatTitle}`]: 'Élő chat',
    [`${keys.liveChatTitleTgoApp}`]: 'Élő chat',
    [`${keys.liveChatTitleMilla}`]: 'Élő chat',
    [`${keys.liveChatTabTitle}`]: 'Élő chat',
    [`${keys.chatbotErrorMsg}`]: 'Rendszerhiba történt. Kérjük, próbáld újra.',
    [`${keys.liveChatLoadingMessage}`]:
      'Kapcsolódás ügyfélszolgálati munkatársunkhoz, kérjük, tartsd...',
    [`${keys.agentsBusyMessage}`]:
      'Jelenleg az összes munkatársunk más ügyfeleket szolgál ki, kérjük, tartsd...',
    [`${keys.liveChatConnecting}`]: 'Kapcsolódás...',
    [`${keys.systemJoined}`]: 'Ügyfélszolgálati munkatárs kirendelése...',
    [`${keys.agentDisconnected}`]: 'Új munkatárs kirendelése....',
    [`${keys.agentJoined}`]: 'Online',
    [`${keys.systemJoinedMessage}`]:
      'Üdvözlünk a Trendyol élő csevegőszolgáltatásában.',
    [`${keys.liveChatOffline}`]: 'Offline',
    [`${keys.makeUploadedContentBigger}`]: 'Jelenítsd meg nagyobb formátumban',
    [`${keys.timeOutFirstPart}`]: 'A csevegés az alábbi időn belül megszűnik',
    [`${keys.timeOutSecondPart}`]: 'másodperc',
    [`${keys.timeOutThirdPart}`]: 'mivel egy ideje nem írtál semmit sem.',
    [`${keys.closeLiveChatButton}`]: 'Csevegés befejezése',
    [`${keys.continueLiveChatButton}`]: 'Csevegjünk tovább',
    [`${keys.fileNameNotValidError}`]: 'A fájlnév nem érvényes.',
    [`${keys.fileEmptyError}`]: 'A feltöltött fájl tartalma üres.',
    [`${keys.liveChatRefused}`]:
      'Ezzel a felhasználói fiókkal jelenleg egy másik beszélgetés zajlik. Kérjük, próbáld újra később, ha még mindig szükséged van segítségre.',
    [`${keys.maxTimeOutThresholdExceedMessage}`]:
      'Ezt a csevegést a rendszer automatikusan megszakította, mivel egy ideig nem voltál aktív. Megértésedet köszönjük.',
    [`${keys.agentLeftMessage}`]:
      'Az ügyfélszolgálati munkatárs megszakította a csevegést.',
    [`${keys.chatConnectionErrorMessage}`]:
      'Hiba történt. Kérjük, próbáld újra.',
    [`${keys.chatInputBarPlaceholderMessage}`]: 'Írj üzenetet...',
    [`${keys.resendMediaMessage}`]: 'Kérjük, próbáld újra.',
    [`${keys.resendMediaMessageForEmptyImage}`]: 'Kérjük, töltsd fel újra.',
    [`${keys.unAuthorizedMessage}`]: 'Nem vagy jogosult az oldal elérésére.',
    [`${keys.surveyMessage}`]: 'Elégedett vagy az iménti beszélgetéssel?',
    [`${keys.trendyolAsistantSurveyMessage}`]:
      'Elégedett vagy a Trendyol Asszisztenssel?',
    [`${keys.surveyCommentMessage}`]: 'Van még bármi hozzáfűzni valód?',
    [`${keys.surveyRatingMessage}`]:
      'Értékeld tapasztalatodat a Trendyol Asszisztenssel',
    [`${keys.liveChatSurveyRatingMessage}`]: 'Értékeld a munkatársunkat',
    [`${keys.surveyRatingBadMessage}`]: 'Nagyon rossz',
    [`${keys.surveyRatingGoodMessage}`]: 'Nagyon jó',
    [`${keys.surveyRateButton}`]: 'Beküldés',
    [`${keys.surveyErrorModalTitle}`]: 'HIBA TÖRTÉNT A FELMÉRÉS SORÁN',
    [`${keys.surveyErrorRatingEmpty}`]: 'Kérjük, adj meg egy értékelést.',
    [`${keys.surveyErrorServiceError}`]: 'Hiba történt. Kérjük, próbáld újra.',
    [`${keys.surveySuccessModalTitle}`]: 'SIKERES ÉRTÉKELÉS',
    [`${keys.surveySuccessModalMessage}`]:
      'Köszönjük, hogy részt vettél a felmérésünkben!',
    [`${keys.liveChatNewMessageAppTitle}`]: 'Új üzeneted van...',
    [`${keys.errorPageMessage}`]: 'Rendszerhiba történt. Kérjük, próbáld újra.',
    [`${keys.closeOnErrorButton}`]: 'Bezárás',
    [`${keys.reconnectOnErrorButton}`]: 'Újrakapcsolódás',
    [`${keys.liveChatReconnecting}`]: 'Újrakapcsolódás folyamatban...',
    [`${keys.nameText}`]: 'Keresztnév',
    [`${keys.lastnameText}`]: 'Vezetéknév',
    [`${keys.outOfWorkOurMessage}`]:
      'Ügyfélszolgálati munkatársaink jelenleg zárva vannak. Üzenetedet továbbíthatod, amire a lehető leghamarabb válaszolunk.',
    [`${keys.invalidTokenInfoText}`]:
      'Információidat nem tudjuk elemezni. Kérjük, lépj ki az alkalmazásból, és jelentkezz be újra.',
    [`${keys.newText}`]: 'ÚJ',
    [`${keys.emailText}`]: 'E-mail',
    [`${keys.continueLiveChatText}`]: 'Lépj vissza',
    [`${keys.exitLiveChatModalText}`]:
      'Biztosan szeretnéd befejezni a csevegést?',
    [`${keys.exitAndOpenSurvey}`]: 'Ki akarsz lépni?',
    [`${keys.clientReconnectingMessage}`]:
      'Kedves ügyfelünk, lassú internetkapcsolatod miatt ismét az ügyfélszolgálati munkatársunkhoz irányítunk. A csevegés folytatódik, kérjük, tartsd. Megértésedet köszönjük.',
    [`${keys.clientRedirectedMessage}`]:
      'Kedves ügyfelünk, a lassú internetkapcsolat miatt átkapcsolunk egy másik munkatárshoz. A hívás előzményei átkerülnek az új ügyintézőhöz. Kérjük, várj - megértésedet köszönjük.',
    [`${keys.stickySurveyTitle}`]: 'Elégedett vagy a Trendyol Asszisztenssel?',
    [`${keys.stickySurveyRateMessage}`]: 'Kérjük, értékelj minket',
    [`${keys.stickySurveyCommentMessage}`]: 'Van még bármi hozzáfűzni valód?',
    [`${keys.stickySurveySuccessTitle}`]: 'Megkaptuk az értékelésedet!',
    [`${keys.stickySurveySuccessText}`]:
      'Köszönjük az értékelést, amelyet rra használunk, hogy javíthassunk magunkon.',
    [`${keys.stickySurveySuccessButtonText}`]: 'Bezárás',
    [`${keys.placeHolderSurname}`]: 'Vezetéknév megadása',
    [`${keys.placeHolderName}`]: 'Keresztnév megadása',
    [`${keys.yourEmailText}`]: 'E-mail',
    [`${keys.placeholderEmail}`]: 'E-mail-cím megadása',
    [`${keys.languageText}`]: 'Nyelv',
    [`${keys.placeholderLanguage}`]: 'Nyelv kiválasztása',
    [`${keys.deliveryNumberText}`]: 'Szállítási szám',
    [`${keys.placeholderDeliveryNumber}`]: 'Szállítási szám megadása',
    [`${keys.startChatText}`]: 'Csevegés indítása',
    [`${keys.internationalTicketCreateSucceedTitle}`]:
      'Köszönjük, hogy kapcsolatba léptél velünk',
    [`${keys.internationalTicketCreateSucceedText}`]:
      'Megkaptuk az e-mailedet. Utánanézünk, és hamarosan jelentkezünk.',
    [`${keys.internationalTicketCreateFailedTitle}`]: '',
    [`${keys.internationalTicketCreateFailedText}`]:
      'Hiba történt. Kérjük, próbáld újra.',
    [`${keys.closeButtonText}`]: 'Bezárás',
    [`${keys.retryButtonText}`]: 'Próbáld újra',
    [`${keys.placeholderSubject}`]: 'Tárgy megadása',
    [`${keys.subjectText}`]: 'Tárgy',
    [`${keys.internationalSubjectImageText}`]: 'Képek feltöltése',
    [`${keys.mailDescriptionText}`]: 'Írd le részletesen az üzenetedet',
    [`${keys.mailSubmitText}`]: 'Beküldés',
    [`${keys.placeHolderDescriptionText}`]: 'Leírás megadása',
    [`${keys.englishLangText}`]: 'Angol',
    [`${keys.germanLangText}`]: 'Német',
    [`${keys.englishLangTextShort}`]: 'ANGOL',
    [`${keys.selectOrderMsg}`]: 'Kiválasztás',
    [`${keys.shipmentNo}`]: 'Szállítási szám',
    [`${keys.orderSelectedMsg}`]: 'Kiválasztott',
    [`${keys.quantityText}`]: 'Darab',
    [`${keys.claimOrderClaimableIsNotDone}`]:
      'Kérjük, töltsd ki az alábbi mezőket.',
    [`${keys.claimQuantityText}`]: 'Mennyiség kiválasztása',
    [`${keys.claimReasonText}`]: 'Indoklás',
    [`${keys.countAndPriceText}`]: 'Mennyiség és ár:',
    [`${keys.claimOrderReason}`]: 'Válaszd ki a visszaküldés okát',
    [`${keys.selectProductImageText}`]:
      'Képek feltöltése (legfeljebb {{count}})',
    [`${keys.internationalMailSelectProductImageText}`]:
      'Képek feltöltése (legfeljebb {{count}})',
    [`${keys.claimOrderDescription}`]: 'Kérjük, add meg a részleteket',
    [`${keys.claimSimulationPreviewQuantity}`]: 'Mennyiség',
    [`${keys.claimSimulationPreviewProduct}`]: 'Tétel',
    [`${keys.claimOrderFillToContinue}`]:
      'Kérjük, a folytatáshoz töltsd ki a mennyiséget és a visszaküldés okát tartalmazó mezőket.',
    [`${keys.claimQuantityText}`]: 'Mennyiség kiválasztása',
    [`${keys.optionalFileInfoText}`]: 'Képek feltöltése (opcionális)',
    [`${keys.imageFormatMismatchErrorText}`]:
      'Csak JPG és PNG fájlokat tölthetsz fel.',
    [`${keys.imageMaxSizeErrorText}`]:
      'A fájl mérete nem haladhatja meg a {{size}} MB-ot.',
    [`${keys.imageCountErrorText}`]:
      'Legfeljebb {{count}} fájlt tölthetsz fel.',
    [`${keys.imageReadError}`]:
      'A fájlt nem sikerült feldolgozni. Kérjük, próbáld újra.',
    [`${keys.imageUploadError}`]:
      'Hiba történt a fájlm feltöltése közben. Kérjük, próbáld újra.',
    [`${keys.shipmentSubtitleTotal}`]: 'Teljes összeg',
    [`${keys.shipmentSubtitleItems}`]: 'Tétel',
    [`${keys.orderNo}`]: 'Rendelési szám.',
    [`${keys.seeAllAnnouncement}`]: 'Összes közlemény megtekintése',
    [`${keys.showMoreTicket}`]: 'Többet mutasson',
    [`${keys.notificationsTitle}`]: 'Értesítések',
    [`${keys.totalClaimAmountText}`]: 'Teljes visszatérített összeg',
    [`${keys.claimAmountText}`]: 'Visszatérített összege',
    [`${keys.claimCoinAmountText}`]: 'Visszatérített Trendyol Coin',
    [`${keys.coinText}`]: 'Trendyol Coin',
    [`${keys.coinUsageMessage}`]: 'Ezt használtad',
    [`${keys.coinUsageOrderMessage}`]: 'ezen a megrendelésen!',
    [`${keys.coinsWording}`]: 'érmék',
    [`${keys.coinQuantityAndAmountInfo}`]:
      '{{quantity}} ({{coinsWording}} {{amount}}){{currency}}',
    [`${keys.claimStatusCargoText}`]: 'Hol a visszaküldésem?',
    [`${keys.showMoreShipment}`]: 'További termékek megjelenítése',
    [`${keys.showMoreShipment}`]: 'További termékek megjelenítése',
    [`${keys.orderText}`]: 'Megrendeléseim',
    [`${keys.ordersText}`]: 'Megrendeléseim',
    [`${keys.currencyText}`]: '€',
    [`${keys.copyText}`]: 'Másolás',
    [`${keys.copiedText}`]: 'Másolva',
    [`${keys.deliveryDateText}`]: 'Szállítási határidő:',
    [`${keys.paymentInfoText}`]: 'Fizetési információk',
    [`${keys.subtotalText}`]: 'Részösszeg',
    [`${keys.cargoText}`]: 'Szállítás',
    [`${keys.totalText}`]: 'Összesen',
    [`${keys.deliveryAddressText}`]: 'Szállítási cím',
    [`${keys.orderDate}`]: 'Megrendelés dátuma',
    [`${keys.orderSummary}`]: 'Összefoglaló a rendelésről',
    [`${keys.productText}`]: 'áru',
    [`${keys.linkCopied}`]: 'Megrendelési szám másolva',
    [`${keys.billingAddressText}`]: 'Számla címe',
    [`${keys.detail}`]: 'Részletek',
    [`${keys.deliveryTimeText}`]: 'Szállítási határidő:',
    [`${keys.addExtraImage}`]: 'Képek hozzáadása',
    [`${keys.chatbotErrorMsgKey}`]: 'Hiba!',
    [`${keys.chatbotShortErrorMsg}`]: 'Valami hiba történt.',
    [`${keys.collectionPointText}`]: 'Átvételi pont',
    [`${keys.commercialText}`]: 'Vállalatilag számlázott értékesítés',
    [`${keys.fastMarketText}`]: 'Gyors piac',
    [`${keys.mealText}`]: 'Étkezés',
    [`${keys.groupDealText}`]: 'Vásárolj barátokkal',
    [`${keys.wardrobeText}`]: 'DOLAP',
    [`${keys.inputLanguageNotSupported}`]:
      'Segítségnyújtásunk jelenleg csak angol nyelven érhető el. Kérjük, kérdésedet angol nyelven írd le.',
    [`${keys.myOrderDetail}`]: 'Megrendelésem részletei',
    [`${keys.arabicLangText}`]: 'Arab',
    [`${keys.showMoreContent}`]: 'Többet mutasson',
    [`${keys.showLessContent}`]: 'Kevesebb mutatása',
    [`${keys.bulgariaLangText}`]: 'Bolgár',
    [`${keys.polandLangText}`]: 'Lengyel',
    [`${keys.slovakiaLangText}`]: 'Szlovák',
    [`${keys.romaniaLangText}`]: 'Román',
    [`${keys.czechiaLangText}`]: 'Cseh',
    [`${keys.hungaryLangText}`]: 'Magyar',
    [`${keys.greeceLangText}`]: 'Görög',
    [`${keys.cancelOrderSelectProduct}`]:
      'Válaszd ki a tételt és a törlés okát.',
    [`${keys.cancelOrderSelectReason}`]: 'Válaszd ki a törlés okát',
    [`${keys.selectReason}`]: 'Válassz okot',
    [`${keys.other}`]: 'Magyarázat',
    [`${keys.explanation}`]:
      'Adj magyarázatot a törölni kívánt tételre vonatkozóan',
    [`${keys.userDisconnectedOnBackground}`]:
      'A beszélgetést lezártuk, mert elhagytad az alkalmazást a beszélgetés során vagy sokáig inaktív voltál. Megértésedet köszönjük!',
    [`${keys.refundDetailText}`]: 'Visszatérítés megtekintése',
    [`${keys.refundProgressBankInfo}`]: 'A pénzintézeted intézkedésére vár.',
    [`${keys.productImageText}`]: 'Termékkép',
    [`${keys.refundAmountText}`]: 'Visszatérítés összege',
    [`${keys.claimLastModifiedDateTrText}`]:
      'A Trendyol elindította a visszatérítést',
    [`${keys.claimLastModifiedDateMillaText}`]:
      'A Trendyolmilla elindította a visszatérítést',
    [`${keys.estimatedRefundDateText}`]: 'A visszatérítés becsült ideje',
    [`${keys.bankRefNoText}`]: 'Banki hivatkozási szám',
    [`${keys.refundProgressTrInfo}`]:
      'A Trendyol elindította a visszatérítést.',
    [`${keys.refundProgressMillaInfo}`]:
      'A Trendyolmilla elindította a visszatérítést.',
  },
};
