import * as keys from './keys';
import { userDisconnectedOnBackground } from './keys';

export default {
  translation: {
    [`${keys.appTitle}`]: 'Trendyol Asistan',
    [`${keys.appTitleDodo}`]: 'DODO',
    [`${keys.appTitleTgoApp}`]: 'Asistan',
    [`${keys.appTitleMilla}`]: 'Asistan',
    [`${keys.appTabTitle}`]: 'Trendyol Asistan',
    [`${keys.liveChatTitle}`]: 'Canlı Yardım',
    [`${keys.liveChatTitleDodo}`]: 'Dolap Canlı Yardım',
    [`${keys.liveChatTitleTgoApp}`]: 'Canlı Yardım',
    [`${keys.liveChatTitleMilla}`]: 'Canlı Yardım',
    [`${keys.liveChatTabTitle}`]: 'Canlı Yardım',
    [`${keys.chatbotErrorMsg}`]: 'Bir hata oluştu, lütfen tekrar deneyin',
    [`${keys.selectOrderMsg}`]: 'Seç',
    [`${keys.orderSelectedMsg}`]: 'Seçildi',
    [`${keys.liveChatLoadingMessage}`]:
      'Müşteri temsilcimize bağlanıyorsunuz, lütfen bekleyin..',
    [`${keys.agentsBusyMessage}`]:
      'Şu an tüm temsilcilerimiz başka müşterilere hizmet vermektedir, lütfen bekleyin..',
    [`${keys.liveChatConnecting}`]: 'Bağlanıyor...',
    [`${keys.systemJoined}`]: 'Müşteri Temsilcisi Atanıyor...',
    [`${keys.agentDisconnected}`]: 'Yeni Temsilci Atanıyor...',
    [`${keys.agentJoined}`]: 'Çevrimiçi',
    [`${keys.systemJoinedMessage}`]: 'Canlı Yardım’a Hoş Geldiniz.',
    [`${keys.liveChatOffline}`]: 'Çevrimdışı',
    [`${keys.makeUploadedContentBigger}`]: 'Daha Büyük Göster',
    [`${keys.timeOutFirstPart}`]:
      ' Uzun süredir herhangi bir şey yazmadığınız için görüşmemiz',
    [`${keys.timeOutSecondPart}`]: 'Saniye',
    [`${keys.timeOutThirdPart}`]: ' içinde sonlanacaktır.',
    [`${keys.closeLiveChatButton}`]: 'Görüşmeyi Bitir',
    [`${keys.continueLiveChatButton}`]: 'Görüşmeye Devam Et',
    [`${keys.liveChatRefused}`]:
      'Bu hesap üzerinden şu anda farklı bir görüşme yapılmaktadır. İhtiyacınızın devam etmesi halinde bir süre sonra tekrar deneyebilirsiniz.',
    [`${keys.maxTimeOutThresholdExceedMessage}`]:
      'Uzun süredir işlem yapılmayan görüşmeniz sistem tarafından otomatik sonlandırılmıştır. Anlayışınız için teşekkür ederiz.',
    [`${keys.agentLeftMessage}`]: 'Müşteri Temsilcisi görüşmeyi sonlandırdı.',
    [`${keys.chatConnectionErrorMessage}`]:
      'Sistemsel bir hata oluştu, lütfen tekrar deneyin.',
    [`${keys.chatInputBarPlaceholderMessage}`]: 'Mesajınızı yazınız...',
    [`${keys.resendMediaMessage}`]: 'Tekrar Dene',
    [`${keys.resendMediaMessageForEmptyImage}`]: 'Tekrar yükleme yapmalısın.',
    [`${keys.unAuthorizedMessage}`]: 'Bu sayfaya girmeye yetkiniz yok',
    [`${keys.surveyMessage}`]:
      'Müşteri temsilcimizden aldığınız hizmetten memnun kaldınız mı?',
    [`${keys.trendyolAsistantSurveyMessage}`]:
      'Trendyol Asistan hizmetinden ne derece memnun kaldınız?',
    [`${keys.surveyCommentMessage}`]: 'Görüş ve Önerilerinizi Yazınız',
    [`${keys.surveyRatingMessage}`]: 'Lütfen Puan Seçin',
    [`${keys.liveChatSurveyRatingMessage}`]: 'Lütfen Puan Seçin',
    [`${keys.surveyRatingBadMessage}`]: 'Çok Kötü',
    [`${keys.surveyRatingGoodMessage}`]: 'Çok İyi',
    [`${keys.surveyRateButton}`]: 'Değerlendir',
    [`${keys.surveyErrorModalTitle}`]: 'ANKET HATASI OLUŞTU',
    [`${keys.surveyErrorRatingEmpty}`]: 'Lütfen puan giriniz.',
    [`${keys.surveyErrorServiceError}`]:
      'Bir hata oluştu, lütfen tekrar deneyin.',
    [`${keys.surveySuccessModalTitle}`]: 'DEĞERLENDİRME İŞLEMİ BAŞARILI',
    [`${keys.surveySuccessModalMessage}`]:
      'Anketimize katıldığınız için teşekkür ederiz!',
    [`${keys.liveChatNewMessageAppTitle}`]: 'Yeni mesajınız var...',
    [`${keys.errorPageMessage}`]:
      'Bir hata oluştu, lütfen daha sonra tekrar deneyin.',
    [`${keys.closeOnErrorButton}`]: 'Kapat',
    [`${keys.reconnectOnErrorButton}`]: 'Yeniden Bağlan',
    [`${keys.liveChatReconnecting}`]: 'Tekrar Bağlanıyor...',
    [`${keys.other}`]: 'Ek not',
    [`${keys.quantity}`]: 'Adet',
    [`${keys.orderText}`]: 'Siparişlerim',
    [`${keys.nameText}`]: 'Ad',
    [`${keys.lastnameText}`]: 'Soyad',
    [`${keys.nameSurnameText}`]: 'Ad Soyad',
    [`${keys.telephoneText}`]: 'Telefon',
    [`${keys.provinceText}`]: 'İl',
    [`${keys.districtText}`]: 'İlçe',
    [`${keys.streetText}`]: 'Mahalle',
    [`${keys.addressText}`]: 'Adres',
    [`${keys.addressTitleText}`]: 'Adres Başlığı',
    [`${keys.confirmAddressText}`]: 'Adresi Onayla',
    [`${keys.confirmOtpAddress}`]: 'Onayla',
    [`${keys.confirmOtpAddressNewCode}`]: 'Kodu Tekrar Gönder',
    [`${keys.selectRegisteredAddressText}`]: 'Kayıtlı Adreslerimden Seç',
    [`${keys.branchText}`]: 'Şube',
    [`${keys.dateText}`]: 'Tarih',
    [`${keys.saveText}`]: 'Kaydet',
    [`${keys.claimOrderSelectProduct}`]: '{0} Ürünü seç ve devam et',
    [`${keys.claimOrderSelectProducts}`]: 'Ürünleri seç ve devam et',
    [`${keys.claimOrderFillToContinue}`]:
      'Devam etmek için seçtiğin ürünün adet ve iade nedeni bilgilerini eksiksiz doldurmalısın.',
    [`${keys.claimOrderClaimableIsNotDone}`]:
      'Lütfen aşağıdaki bilgileri doldur.',
    [`${keys.claimOrderDescription}`]: 'Açıklama yaz',
    [`${keys.claimOrderReason}`]: 'İade nedeni seç',
    [`${keys.cancelOrderSelectProduct}`]:
      'İptal etmek istediğin ürünü aşağıdan seçebilirsin',
    [`${keys.cancelOrderSelectReason}`]: 'İptal Nedenini Seç',
    [`${keys.explanation}`]: 'Açıklama',
    [`${keys.selectReason}`]: 'İptal Nedeni',
    [`${keys.quantityText}`]: 'Adet',
    [`${keys.productText}`]: 'ürün',
    [`${keys.claimCodeText}`]: 'İade Kodu',
    [`${keys.branchNameText}`]: 'İlgili Şube Adı',
    [`${keys.wrongDeliveredPersonNameText}`]:
      'Teslimatın yapıldığını görüntülediğin isim soyisim',
    [`${keys.ordersText}`]: 'Siparişlerim',
    [`${keys.myOrderDetail}`]: 'Sipariş Detayım',
    [`${keys.outOfWorkOurMessage}`]:
      'Mesai saatlerinin dışında olduğumuzdan seni müşteri temsilcisine yönlendiremiyorum, anlayışın için teşekkürler. Mesai saatlerimiz: Hafta içi: 8:30-00:00 Hafta sonu: 10:00-00:00',
    [`${keys.claimAmountText}`]: 'İade Edilen Tutar',
    [`${keys.countAndPriceText}`]: 'Adet & Fiyat: ',
    [`${keys.totalClaimAmountText}`]: 'Toplam İade Edilen Tutar',
    [`${keys.claimReasonText}`]: 'Sebep',
    [`${keys.claimRejectReasonLabel}`]: 'İade Reddedilme Sebebi',
    [`${keys.transitionScreenWaitingAreaText}`]:
      'Bekleyenler arasındaki sıran:',
    [`${keys.claimStatusCargoText}`]: 'İade Kargom Nerede?',
    [`${keys.showMoreShipment}`]: 'Daha Fazla Ürün Göster',
    [`${keys.cargoInfoText}`]: 'Kargom Nerede?',
    [`${keys.invalidTokenInfoText}`]:
      'Bilgilerini çözümleyemiyoruz. Uygulamadan çıkıp tekrar giriş yapmanı rica ederiz.',
    [`${keys.newText}`]: 'YENİ',
    [`${keys.addText}`]: 'Ekle',
    [`${keys.loadedText}`]: 'Dosya seçildi',
    [`${keys.addNewContactText}`]: 'Yeni İletişim Bilgisi Ekle',
    [`${keys.updateContactText}`]: 'İletişim Bilgisini Güncelle',
    [`${keys.deliveredProductProblemImageAgentScreenLabel}`]:
      'Şikayet edilen ürün görseli:',
    [`${keys.orderNo}`]: 'Sipariş No',
    [`${keys.shipmentNo}`]: 'Teslimat No',
    [`${keys.contactTypeText}`]: 'İletişim Tipi',
    [`${keys.emailText}`]: 'Email',
    [`${keys.contactDescription}`]: 'Açıklama',
    [`${keys.comboboxDefaultValue}`]: 'Seçiniz',
    [`${keys.continueLiveChatText}`]: 'Görüşmeye Devam Et',
    [`${keys.exitLiveChatModalText}`]:
      "Canlı Yardım'dan çıkmak istediğine emin misin?",
    [`${keys.exitAndOpenSurvey}`]: 'Çıkış Yap ve Temsilciyi Puanla',
    [`${keys.clientReconnectingMessage}`]:
      'Değerli müşterimiz internet bağlantınızdaki yavaşlık nedeniyle sizi yeniden müşteri temsilcisine aktarıyoruz. Görüşmeniz kaldığı yerden devam edecektir. Beklemenizi rica eder, anlayışınız için teşekkür ederiz.',
    [`${keys.clientRedirectedMessage}`]:
      'Değerli müşterimiz internet bağlantısındaki yavaşlık nedeniyle sizi farklı bir müşteri temsilcisine aktarıyoruz. Bu görüşmedeki konuşma geçmişi yeni temsilciye aktarılacaktır. Beklemenizi rica eder, anlayışınız için teşekkür ederiz.',
    [`${keys.selectProductImageText}`]:
      'Ürün görseli seçin (En fazla {{count}} adet)',
    [`${keys.deleteText}`]: 'Sil',
    [`${keys.optionalFileInfoText}`]: 'Dilersen görsel ekleyebilirsin.',
    [`${keys.imageMaxSizeErrorText}`]: "Dosya boyutu {{size}}MB'ı geçemez.",
    [`${keys.imageFormatMismatchErrorText}`]:
      'Sadece JPG ve PNG formatlarında görsel ekleyebilirsin.',
    [`${keys.imageCountErrorText}`]: 'En fazla {{count}} dosya seçebilirsin.',
    [`${keys.imageReadError}`]: 'Dosya okunamadı.',
    [`${keys.fileNameNotValidError}`]: 'Dosya ismi okunamadı.',
    [`${keys.fileEmptyError}`]: 'Yüklenen dosyanın içeriği boş.',
    [`${keys.imageUploadError}`]:
      'Resim yüklenirken bir hata oluştu. Lütfen tekrar deneyiniz.',
    [`${keys.goDeliveredTicketProductsFillToContinue}`]:
      'Devam etmek için seçtiğin ürünün açıklaması, görseli, detayı ve adet bilgilerini eksiksiz doldurmalısın.',
    [`${keys.seller}`]: 'Satıcı',
    [`${keys.me}`]: 'Ben',
    [`${keys.street}`]: 'Cadde / Sokak',
    [`${keys.reasonText}`]: 'Değişiklik Nedeni',
    [`${keys.descriptionText}`]: 'Açıklama',
    [`${keys.buildingNoText}`]: 'Bina No',
    [`${keys.apartmentNoText}`]: 'Daire No',
    [`${keys.neighborhoodText}`]: 'Mahalle',
    [`${keys.cancelBtnText}`]: 'Vazgeç',
    [`${keys.createTicket}`]: 'Talebini Oluştur',
    [`${keys.stickySurveyTitle}`]: 'Trendyol Asistan’dan memnun kaldın mı?',
    [`${keys.stickySurveyRateMessage}`]: 'Lütfen bizi değerlendir',
    [`${keys.stickySurveyCommentMessage}`]: 'Görüş ve önerilerini yazabilirsin',
    [`${keys.stickySurveySuccessTitle}`]: 'Değerlendirmeni Aldık!',
    [`${keys.stickySurveySuccessText}`]:
      'Kendimizi geliştirebilmemiz için yaptığınız değerlendirmeye teşekkür ederiz.',
    [`${keys.stickySurveySuccessButtonText}`]: 'Kapat',

    [`${keys.placeHolderName}`]: 'Adını gir',
    [`${keys.placeHolderSurname}`]: 'Soyadını gir',
    [`${keys.placeHolderProvince}`]: 'İl Seç',
    [`${keys.placeHolderDistrict}`]: 'İlçe Seç',
    [`${keys.placeHolderNeighborhood}`]: 'Mahalle Seç',
    [`${keys.placeHolderStreet}`]: 'Sokak Seç',
    [`${keys.placeHolderAddress}`]: 'Adresini gir(10 karakterden uzun olmalı)',
    [`${keys.placeHolderAddressTitle}`]: 'Adres başlığını gir',
    [`${keys.placeHolderSelectReason}`]: 'Değişklik nedeni seç',
    [`${keys.placeHolderDescription}`]: 'Açıklama giriniz',
    [`${keys.placeHolderBuildingNo}`]: 'Bina no gir',
    [`${keys.placeHolderApartmentNo}`]: 'Daire no gir',
    [`${keys.badCodeErrorMsg}`]:
      "Girmiş olduğun doğrulama kodu hatalıdır. SMS'de gönderdiğimiz doğrulama kodunu kontrol ederek tekrar deneyebilirsin.",
    [`${keys.reasonSelectText}`]: 'Neden Seçiniz',
    [`${keys.orderImageLabel}`]: 'Sipariş görseli',
    [`${keys.chatbotShortErrorMsg}`]: 'Bir şeyler ters gitti.',
    [`${keys.chatbotErrorMsgKey}`]: 'Hata!',
    [`${keys.dateSelect}`]: 'Tarih Seç',
    [`${keys.placeHolderbranch}`]: 'Şube adını gir',
    [`${keys.fileLoadedText}`]: 'Dosya Yüklendi',
    [`${keys.uploadFileText}`]: 'Dosya Yükle',
    [`${keys.collectionPointText}`]: 'Gel Al Noktası',
    [`${keys.groupDealText}`]: 'Arkadaşlarınla Al',
    [`${keys.commercialText}`]: 'Kurumsal Faturalı',
    [`${keys.wardrobeText}`]: 'DOLAP',
    [`${keys.moreProductText}`]: 'Ürün Daha',
    [`${keys.barcodeText}`]: 'Barkod',
    [`${keys.placeHolderBarcodeText}`]: 'Ürün barkodunuzu girin',
    [`${keys.placeHolderDescriptionText}`]: 'Açıklama giriniz.',
    [`${keys.orderDate}`]: 'Sipariş Tarihi',
    [`${keys.orderSummary}`]: 'Sipariş Özeti',
    [`${keys.totalText}`]: 'Toplam',
    [`${keys.currencyText}`]: 'TL',
    [`${keys.deliveryDateText}`]: 'Teslimat Tarihi:',
    [`${keys.deliveryTimeText}`]: 'Teslimat Süresi:',
    [`${keys.deliveryNumberText}`]: 'Teslimat Numarası:',
    [`${keys.deliveryAddressText}`]: 'Teslimat Adresi',
    [`${keys.billingAddressText}`]: 'Fatura Adresi',
    [`${keys.yourEmailText}`]: 'Email',
    [`${keys.placeholderEmail}`]: 'Email Girin',
    [`${keys.languageText}`]: 'Dil',
    [`${keys.placeholderLanguage}`]: 'Dil Seçin',
    [`${keys.placeholderDeliveryNumber}`]: 'Teslimat Numarası Girin:',
    [`${keys.startChatText}`]: 'Chate başlayın',
    [`${keys.closeButtonText}`]: 'Kapat',
    [`${keys.retryButtonText}`]: 'Tekrar Dene',
    [`${keys.placeholderSubject}`]: 'Konu Gir',
    [`${keys.subjectText}`]: 'Konu',
    [`${keys.mailDescriptionText}`]: 'Please describe your question in detail',
    [`${keys.mailSubmitText}`]: 'Gönder',
    [`${keys.paymentInfoText}`]: 'Ödeme Bilgileri',
    [`${keys.subtotalText}`]: 'Ara Toplam',
    [`${keys.cargoText}`]: 'Kargo Ücreti',
    [`${keys.englishLangText}`]: 'İngilizce',
    [`${keys.englishLangTextShort}`]: 'ENG',
    [`${keys.germanLangText}`]: 'Almanca',
    [`${keys.dateErrorText}`]: '* Lütfen geçerli bir tarih giriniz.',
    [`${keys.claimQuantityText}`]: 'Adet seç',
    [`${keys.shipmentSubtitleTotal}`]: 'Toplam',
    [`${keys.shipmentSubtitleItems}`]: 'Ürün',
    [`${keys.claimSimulationPreviewQuantity}`]: 'Adet',
    [`${keys.claimSimulationPreviewProduct}`]: 'ürün',
    [`${keys.claimSubtitleTotal}`]: 'Toplam',
    [`${keys.claimSubtitleItems}`]: 'Ürün',
    [`${keys.seeAllAnnouncement}`]: 'Tüm Duyuruları Gör',
    [`${keys.showMoreTicket}`]: 'Daha Fazla Göster',
    [`${keys.notificationsTitle}`]: 'Bilgilendirmeler',
    [`${keys.copyText}`]: 'Kopyala',
    [`${keys.copiedText}`]: 'Kopyalandı',
    [`${keys.linkCopied}`]: 'Sipariş numarası kopyalandı',
    [`${keys.detail}`]: 'Detay',
    [`${keys.addExtraImage}`]: 'Ek Görsel Ekle',
    [`${keys.fastMarketText}`]: 'HIZLI MARKET',
    [`${keys.mealText}`]: 'YEMEK',
    [`${keys.minumumOrderQuantityHeader}`]: 'Minimum Sipariş Adedi',
    [`${keys.minumumOrderQuantityMessage}`]:
      'ile belirtilen ürünleriniz için bu işlemi onayladığında ilgili tüm ürünleriniz iptal edilecektir.',
    [`${keys.differentSubject}`]: 'Farklı Bir Konu İçin Buradayım',
    [`${keys.inputLanguageNotSupported}`]:
      'Buradan sadece Türkçe olarak hizmet veriyoruz.',
    [`${keys.azLanguageDescription}`]: ' Azerbaycan adresine sipariş ver',
    [`${keys.trLanguageDescription}`]: 'Türkiye adresine sipariş ver',
    [`${keys.shipmentCountry}`]: 'Teslimat Ülkesi:',
    [`${keys.changeShipmentCountry}`]: 'Değiştirin',
    [`${keys.azerbeijan}`]: 'Azerbaycan',
    [`${keys.turkey}`]: 'Türkiye',
    [`${keys.delivery}`]: 'Teslimat',
    [`${keys.showMoreContent}`]: 'Daha Fazla Göster',
    [`${keys.showLessContent}`]: 'Daha Az Göster',
    [`${keys.approvalCode}`]: 'Onay Kodu',
    [`${keys.seconds}`]: 'saniye',
    [`${keys.onlyDigits}`]: 'Yalnızca rakam girebilirsiniz.',
    [`${keys.maxDigitLength}`]: 'En fazla 19 haneli rakam girebilirsiniz.',
    [`${keys.multiValueInputLabel}`]:
      '{{fieldName}} eklemek için önce değeri girip sonra Enter veya Space(Boşluk) tuşuna basınız.',
    [`${keys.multiValueMaxItemCount}`]:
      'En fazla {{count}} adet sipariş no eklenebilir, en son girdiğiniz değeri silmelisiniz.',
    [`${keys.productImageText}`]: 'Ürün Görseli',
    [`${keys.refundAmountText}`]: 'İade Tutarı',
    [`${keys.estimatedRefundDateText}`]: 'Tahmini İade Tarihi',
    [`${keys.bankRefNoText}`]: 'Banka Referans Numarası',
    [`${keys.refundDetailText}`]: 'Ödeme Detayı',
    [`${keys.claimLastModifiedDateMillaText}`]:
      "Trendyolmilla'nın Finansal Kurumuna İade Ettiği Tarih",
    [`${keys.claimLastModifiedDateTrText}`]:
      "Trendyol'un Finansal Kurumuna İade Ettiği Tarih",
    [`${keys.refundProgressBankInfo}`]:
      'İşlem finansal kurumunuz tarafından tamamlanır.',
    [`${keys.refundProgressTrInfo}`]: 'Trendyol, iadeni iletti',
    [`${keys.refundProgressMillaInfo}`]: 'Trendyolmilla, iadeni iletti',
    [`${keys.userDisconnectedOnBackground}`]:
      'Görüşme sırasında uygulamadan ayrıldığınız veya uzun süredir aktif olmadığınız için bu görüşme sonlandırıldı. Anlayışınız için teşekkür ederiz.',
  },
};
