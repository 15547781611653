import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import {
  closeLiveChatButton,
  continueLiveChatButton,
  timeOutFirstPart,
  timeOutSecondPart,
  timeOutThirdPart,
} from '../../../i18n/keys';
import appService from '../../../services/app.service';
import './EchoTimeOutWarning.scss';

const getTimestamp = () => {
  return Math.ceil(Date.now() / 1000);
};

const EchoTimeOutWarning = ({
  show = false,
  targetTimestamp,
  onExit,
  onContinue,
  onTimeout,
}) => {
  const timer = useRef(null);
  const [time, setTime] = useState(
    Math.max(targetTimestamp - getTimestamp(), 0)
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      timer.current = setInterval(() => {
        const diff = targetTimestamp - getTimestamp();
        if (diff <= 0) {
          if (timer.current) {
            clearInterval(timer.current);
          }
          setTime(0);
          if (onTimeout) onTimeout();
        } else {
          setTime(diff);
        }
      }, 1000);
      return () => {
        if (timer.current) {
          clearInterval(timer.current);
        }
      };
    } else {
      if (timer.current) {
        clearInterval(timer.current);
      }
    }
  }, [timer, show, targetTimestamp]);

  const onClickExit = () => {
    if (onExit) {
      onExit();
    }
  };

  const onClickContinue = () => {
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <div className="timeout-warning">
      <div className="timeout-warning__text">{t(timeOutFirstPart)}</div>
      <div className="timeout-warning__seconds">
        {time} {t(timeOutSecondPart)}
      </div>
      <div className="timeout-warning__text">{t(timeOutThirdPart)}</div>
      <div className="timeout-warning__buttons">
        <div className="timeout-warning__buttons__end" onClick={onClickExit}>
          {t(closeLiveChatButton)}
        </div>
        <div
          className={classNames([
            'timeout-warning__buttons__continue',
            {
              dynamic: appService.isDynamicStylingChannel(),
            },
          ])}
          onClick={onClickContinue}>
          {t(continueLiveChatButton)}
        </div>
      </div>
    </div>
  );
};

export default EchoTimeOutWarning;
