import React from 'react';
import Radio from '@tx-components/radio';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { addNewContactText, updateContactText } from '../../../../i18n/keys';
import './Contacts.scss';
import Garbage from '../../../../assets/icons/garbage.svg';
import usePersistentState from '../../../common/usePersistentState';
import Bubble from '../../../layout/bubble/Bubble';
import ButtonGroup from '../../buttonGroup/ButtonGroup';
import plusIcon from '../../../../assets/icons/plus-icon.svg';

const Contacts = ({ id, componentData, select, isSelectable }) => {
  const { label, contacts, buttons } = componentData;
  const [selectedContact, setSelectedContact] = usePersistentState(
    id,
    'selectedContact',
    ''
  );
  const [isActive, setIsActive] = usePersistentState(id, 'isActive', false);
  const { t } = useTranslation();

  const onChangeContact = (value) => {
    if (isSelectable) {
      setSelectedContact(value);
      setIsActive(true);
    }
  };

  const handleButtons = (event) => {
    sendEventRequest(event);
  };

  const handleRemoveButton = (event, contactId) => {
    sendRemoveEventRequest(event, contactId);
  };

  const sendEventRequest = (event) => {
    let request = {
      data: {
        selectedContactId: selectedContact,
      },
      event: event,
    };
    select(request);
  };

  const sendRemoveEventRequest = (event, contactId) => {
    let request = {
      data: {
        selectedContactId: contactId,
      },
      event: event,
    };
    select(request);
  };

  return (
    <div
      className={classNames({
        'seller-contact': true,
        done: !isSelectable,
      })}>
      <Bubble title={label} isBottomRounded={false}>
        <div className="seller-contact-wrapper">
          <div className="body">
            <div className="slider-wrapper">
              <div className="items">
                {contacts &&
                  contacts.map((contact, i) => {
                    return (
                      <div
                        className={classNames({
                          'order-item': true,
                          'contact-item': true,
                          'radio-active': isSelectable,
                          'radio-passive':
                            !isSelectable || contact.id !== selectedContact,
                          'radio-selected': contact.id === selectedContact,
                        })}
                        onClick={(e) => onChangeContact(contact.id)}
                        key={i}>
                        <div
                          className={classNames({
                            'contact-wrapper': true,
                            'contact-wrapper--passive': !isSelectable,
                            'contact-wrapper--active': isSelectable,
                          })}>
                          <div className="contact-info">
                            <div className="contact-titles">
                              <div className="radio-wrapper">
                                <Radio
                                  checked={contact.id === selectedContact}
                                />
                                <span
                                  className={classNames({
                                    selected: contact.id === selectedContact,
                                  })}>
                                  {contact.title}
                                </span>
                              </div>

                              {buttons &&
                                buttons.map((button) => {
                                  if (button.buttonType === 'Remove') {
                                    return (
                                      <img
                                        alt="contact-garbage-icon"
                                        className={classNames({
                                          'contact-garbage-icon': true,
                                          'contact-garbage-icon--passive':
                                            !isSelectable,
                                          'contact-garbage-icon--active':
                                            isSelectable,
                                        })}
                                        disabled={!isActive}
                                        src={Garbage}
                                        onClick={() =>
                                          handleRemoveButton(
                                            button.event,
                                            contact.id
                                          )
                                        }
                                        key={i + button.buttonType}
                                      />
                                    );
                                  }
                                })}
                            </div>
                            <div className="contact-body">
                              <div className="name">
                                {contact.firstName + ' ' + contact.lastName}
                              </div>
                              <div className="contact-data">
                                {contact.email}
                              </div>
                              <div className="phone">{contact.phoneNumber}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <ButtonGroup>
            {buttons &&
              buttons.map((button, i) => {
                if (button.buttonType === 'Select') {
                  return (
                    <ButtonGroup.Button
                      isSelectable={isActive}
                      colorType="primary"
                      className="update-contact-button"
                      onClick={() => handleButtons(button.event)}
                      outline={false}
                      disabled={!isActive}>
                      {t(updateContactText)}
                    </ButtonGroup.Button>
                  );
                } else if (button.buttonType === 'New') {
                  return (
                    <ButtonGroup.Button
                      className="new-contact-button"
                      isSelectable={true}
                      colorType="primary"
                      onClick={() => handleButtons(button.event)}
                      outline={true}
                      iconLeft={plusIcon}
                      disabled={false}>
                      {t(addNewContactText)}
                    </ButtonGroup.Button>
                  );
                }
              })}
          </ButtonGroup>
        </div>
      </Bubble>
    </div>
  );
};

export default Contacts;
