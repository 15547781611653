import React, { useContext } from 'react';
import appService from '../../../services/app.service';
import { pages, PLATFORM_WEB } from '../../../constants';
import liveChatService from '../../../services/livechat.service';
import { SURVEY_SECURE_KEY } from '../../../constants/sessionStorageConstants';
import CloseIcon from '../../../assets/icons/close.svg';
import { ChatStatus, useEchoLiveChat } from '../context/echoLiveChatContext';
import liveChatContext from '../../../context/liveChat/liveChatContext';
import chatgatewayService from '../../../services/chatgateway.service';

const EchoHeaderCloseIcon = () => {
  const { endChat, agentStatus, chatStatus, setShowCloseModal } =
    useEchoLiveChat();
  const { setIsSurveyOpened } = useContext(liveChatContext);

  const onExitClicked = async () => {
    let platform = await appService.getPlatform();
    if (platform !== PLATFORM_WEB || appService.getIsStickyAssistant()) {
      let surveySecureKey = await liveChatService.getSecureKey();
      if (!surveySecureKey || agentStatus === 'NOT_CONNECTED') {
        await endChat();

        if (appService.getIsStickyAssistant()) {
          await appService.closeStickyPopup();
        } else {
          await appService.exitAssistant();
        }
      } else {
        await window.customStorage.setItem(SURVEY_SECURE_KEY, surveySecureKey);
        if (chatStatus !== ChatStatus.ENDED) {
          setShowCloseModal(true);
        } else {
          const surveyEligibility =
            await chatgatewayService.getChatBotSurveyEligibility(
              surveySecureKey
            );
          if (surveyEligibility.data.eligibility) {
            await appService.setCurrentPage(pages.LIVECHAT_SURVEY);
            setIsSurveyOpened(true);
          } else if (appService.getIsStickyAssistant()) {
            await appService.closeStickyPopup();
          } else {
            await appService.exitAssistant();
          }
        }
      }
    } else {
      appService.closePopup();
    }
  };

  return (
    <div className="close-wrapper" onClick={onExitClicked}>
      <img src={CloseIcon} className="close-icon" alt="close" />
    </div>
  );
};

export default EchoHeaderCloseIcon;
