import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import './FormattedInput.scss';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import usePersistentState from '../../common/usePersistentState';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { dateErrorText } from '../../../i18n/keys';
import Bubble from '../../layout/bubble/Bubble';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import classNames from 'classnames';

const FormattedInput = ({ id, componentData, isSelectable }) => {
  const { t } = useTranslation();

  let formattedInputComponentData = componentData;
  const buttons = formattedInputComponentData.buttons;
  const label = formattedInputComponentData.label;
  const inputText = formattedInputComponentData.inputLabel;
  const placeHolder = formattedInputComponentData.placeHolder;

  const [dateError, setDateError] = usePersistentState(id, 'dateError', false);
  const [formattedInputText, setFormattedInputText] = usePersistentState(
    id,
    'formattedInputText',
    ''
  );
  const [isActive, setIsActive] = usePersistentState(id, 'isActive', false);
  const chatBotContext = useContext(ChatBotContext);

  const { requestToChatBot } = chatBotContext;

  const handleButtons = (e) => {
    sendEventData(e);
  };

  const sendEventData = (e) => {
    const executeEventRequest = {
      event: e,
      data: {
        input: formattedInputText,
      },
    };
    requestToChatBot(executeEventRequest);
  };

  function checkDate(value) {
    let dateArray = value
      .replace(/\s/g, '')
      .split('/')
      .map((date) => {
        return parseInt(date);
      });

    let currentYear = new Date().getFullYear();
    if (
      dateArray[0] <= 31 &&
      dateArray[1] <= 12 &&
      dateArray[2] < currentYear &&
      dateArray[2] > 999
    ) {
      setIsActive(true);
    } else {
      if (dateArray[2] && dateArray[2].toString().length === 4) {
        setDateError(true);
      }

      setIsActive(false);
    }
  }

  function handleFormattedText(value) {
    setDateError(false);

    if (value.toString() === '__ / __ / ____') {
      setFormattedInputText('');
    } else {
      checkDate(value);
      setFormattedInputText(value);
    }
  }

  return (
    <Bubble title={label} isBottomRounded={false}>
      <div className="formatted-input-page" data-testid="deeplink">
        <div className="formatted-input-main">
          <div className="container">
            <div className="content-wrapper">
              <div className="formatted-input-info">
                <div className="assistant-label">{inputText}</div>
                <NumberFormat
                  disabled={!isSelectable}
                  className={classNames('assistant-input', {
                    'assistant-error': true,
                  })}
                  format={
                    formattedInputText.toString().length === 0
                      ? undefined
                      : '## / ## / ####'
                  }
                  mask="_"
                  placeholder={placeHolder}
                  onValueChange={(value) =>
                    handleFormattedText(value.formattedValue)
                  }
                  value={formattedInputText}
                  allowEmptyFormatting
                />
                {dateError && (
                  <div className="date-error">{t(dateErrorText)}</div>
                )}
              </div>

              <ButtonGroup>
                {buttons.map((buttonData) => {
                  let colorType =
                    buttonData.buttonType === 'Yes' ? 'primary' : 'cancel';

                  return (
                    <ButtonGroup.Button
                      onClick={() => handleButtons(buttonData.event)}
                      key={buttonData.userSegment}
                      data-testid={buttonData.userSegment}
                      isSelectable={isSelectable}
                      colorType={colorType}
                      outline={buttonData.outline}
                      disabled={
                        !isSelectable ||
                        (!isActive && buttonData.buttonType === 'Yes')
                      }>
                      {buttonData.text}
                    </ButtonGroup.Button>
                  );
                })}
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>
    </Bubble>
  );
};

export default withRouter(FormattedInput);
