import React, { useContext, useEffect, useRef, useState } from 'react';
import './ClaimCodes.scss';
import ClaimCodeItem from './components/ClaimCodeItem';
import CommonContext from '../../../../context/common/commonContext';
import chatbotService from '../../../../services/chatbot.service';
import { ModalModel } from '../../../../constants/models';
import { Spin } from 'antd';
import ChatBotContext from '../../../../context/chatbot/chatbotContext';
import usePersistentState from '../../../common/usePersistentState';
import { useTranslation } from 'react-i18next';
import {
  chatbotErrorMsgKey,
  chatbotShortErrorMsg,
} from '../../../../i18n/keys';
import ListArrows from '../../../common/ListArrows';
import AppService from '../../../../services/app.service';

const ClaimCodes = ({
  isRenderedFromOutside = false,
  componentData,
  currentIndex,
  showHover,
  isSelectable,
  id,
}) => {
  const { t } = useTranslation();
  const itemRef = useRef(null);
  const chatBotContext = useContext(ChatBotContext);
  const {
    setLastActiveShipmentComponentIndex,
    lastActiveShipmentComponentIndex,
    requestToChatBot,
  } = chatBotContext;
  const [prevDisable, setPrevDisable] = usePersistentState(
    id,
    'prevDisable',
    true
  );
  const [nextDisable, setNextDisable] = usePersistentState(
    id,
    'nextDisable',
    false
  );

  const [current, setCurrent] = useState(null);
  const [page, setPage] = usePersistentState(id, 'page');
  const [claimItemData, setClaimItemData] = usePersistentState(
    id,
    'claimItemData',
    []
  );
  const [event, setEvent] = usePersistentState(id, 'event', '');
  const [claimItemStatusName, setClaimItemStatusName] = usePersistentState(
    id,
    'claimItemStatusName',
    null
  );
  const [isLoading, setIsLoading] = usePersistentState(id, 'isLoading', false);
  const [last, setLast] = usePersistentState(id, 'last', false);
  const commonContext = useContext(CommonContext);
  const { setErrors } = commonContext;
  const [totalPage, setTotalPage] = usePersistentState(id, 'totalPage', 0);
  const [orderItemActive, setOrderItemActive] = usePersistentState(
    id,
    'orderItemActive',
    true
  );
  const [selectedClaimId, setSelectedClaimId] = usePersistentState(
    id,
    'selectedClaimId',
    null
  );

  useEffect(() => {
    setCurrent(itemRef.current);
    let event =
      componentData && componentData.claimItems && componentData.claimItems[0]
        ? componentData.claimItems[0].event
        : '';
    setEvent(event);
    setClaimItemData(componentData.claimItems);
    setIsLoading(true);
    setPage(componentData.page);
    setTotalPage(componentData.totalPage);
    setClaimItemStatusName(componentData.claimItemStatusName);
    setLastActiveShipmentComponentIndex(currentIndex);
  }, []);

  useEffect(() => {
    setOrderItemActive(currentIndex === lastActiveShipmentComponentIndex);
  }, [lastActiveShipmentComponentIndex]);

  useEffect(() => {
    if (
      componentData.claimItems.length < 10 &&
      componentData.totalPage >= page + 1
    ) {
      setNextDisable(true);
      loadClaimData(
        page,
        event,
        componentData.claimItemStatusName,
        componentData.claimItems
      );
    } else {
      setIsLoading(false);
    }
  }, [totalPage]);

  useEffect(() => {
    if (current && !isLoading && !last) {
      current.addEventListener('scroll', handleScroll);
      return () => current.removeEventListener('scroll', handleScroll);
    }
  });

  const loadClaimData = (page, event, claimItemStatusName, data) => {
    if (totalPage <= page + 1) {
      setNextDisable(true);
      setIsLoading(false);
      setLast(true);
      return;
    }

    setLast(false);
    setIsLoading(true);
    chatbotService
      .getClaimItems(
        page + 1,
        componentData.pageSize,
        event,
        claimItemStatusName
      )
      .then((response) => {
        setClaimItemData([...data, ...response.claimItems]);
        setPage(response.page);
        setIsLoading(false);
        setNextDisable(false);
        if (response.totalPage) {
          setTotalPage(response.totalPage);
        }
        if (
          response.claimItems.length === 0 ||
          response.claimItems === [] ||
          response.claimItems === null
        ) {
          setLast(true);
          setNextDisable(true);
        }
      })
      .catch(() => {
        const errorModal = new ModalModel(t(chatbotErrorMsgKey), [
          t(chatbotShortErrorMsg),
        ]);
        setErrors(errorModal);
      });
  };

  const handleScroll = () => {
    let newScrollLeft = AppService.isRtl()
      ? current.scrollLeft - current.offsetWidth / 2
      : current.scrollLeft + current.offsetWidth / 2;
    checkButtons(newScrollLeft);
  };

  const handleClickLeft = () => {
    let newScrollLeft = current.scrollLeft - current.offsetWidth / 2;
    current.scrollLeft = newScrollLeft;
    setNextDisable(false);
    checkButtons(newScrollLeft);
  };

  const handleClickRight = () => {
    let newScrollLeft = current.scrollLeft + current.offsetWidth / 2;
    current.scrollLeft = newScrollLeft;
    checkButtons(newScrollLeft);
  };

  const checkButtons = (newScrollLeft) => {
    let nextCheck =
      (AppService.isRtl() ? -1 : 1) * newScrollLeft + current.offsetWidth >=
      current.scrollWidth;
    if (nextCheck) {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      loadClaimData(page, event, claimItemStatusName, claimItemData);
    }
    let prevCheck = newScrollLeft <= 0;
    setPrevDisable(prevCheck);
  };

  return (
    <div className="claim-codes">
      <div className="slider-wrapper">
        <div className="items" ref={itemRef} data-testid="scroll-area">
          {claimItemData &&
            claimItemData.map((claimCodeItem, i) => {
              return (
                <ClaimCodeItem
                  id={id + 'ClaimCodeItem' + i}
                  isRenderedFromOutside={isRenderedFromOutside}
                  claimCodeItem={claimCodeItem}
                  key={id + 'ClaimCodeItem' + i}
                  currentIndex={currentIndex}
                  showHover={showHover}
                  shouldSelect={isSelectable}
                  select={requestToChatBot}
                  selectedClaimId={selectedClaimId}
                  setSelectedClaimId={setSelectedClaimId}
                  orderItemActive={orderItemActive}
                />
              );
            })}
        </div>
        {isLoading && !last && (
          <div className="spin">
            <Spin />
          </div>
        )}
        <ListArrows
          prevDisable={prevDisable}
          nextDisable={nextDisable}
          handleClickLeft={handleClickLeft}
          handleClickRight={handleClickRight}
        />
      </div>
    </div>
  );
};

export default ClaimCodes;
