import * as keys from './keys';

export default {
  translation: {
    [`${keys.appTitle}`]: 'TRENDYOL ASSISTENT',
    [`${keys.appTabTitle}`]: 'Trendyol Assistent',
    [`${keys.liveChatTitle}`]: 'Live-Chat',
    [`${keys.liveChatTabTitle}`]: 'Live-Chat',
    [`${keys.chatbotErrorMsg}`]:
      'Ein Systemfehler ist aufgetreten. Bitte erneut versuchen',
    [`${keys.liveChatLoadingMessage}`]:
      'Du wirst mit unserem Kundendienst verbunden, bitte habe etwas Geduld.',
    [`${keys.agentsBusyMessage}`]:
      'All unsere Kundenbetreuer sind zur Zeit im Gespräch, bitte habe etwas Geduld.',
    [`${keys.liveChatConnecting}`]: 'Verbindung wird hergestellt',
    [`${keys.systemJoined}`]: 'Der Kundenvertreter wird zugewiesen...',
    [`${keys.agentDisconnected}`]: 'Ein neuer Vertreter wird zugewiesen...',
    [`${keys.agentJoined}`]: 'Online',
    [`${keys.systemJoinedMessage}`]:
      'Herzlich willkommen beim Trendyol Live Chat.',
    [`${keys.liveChatOffline}`]: 'Offline',
    [`${keys.makeUploadedContentBigger}`]: 'Größer anzeigen',
    [`${keys.timeOutFirstPart}`]:
      'Da du länger nichts geschrieben hast, endet dieser Chat in ',
    [`${keys.timeOutSecondPart}`]: 'Sekunden',
    [`${keys.timeOutThirdPart}`]: '',
    [`${keys.closeLiveChatButton}`]: 'Chat beenden',
    [`${keys.continueLiveChatButton}`]: 'Chat fortsetzen',
    [`${keys.liveChatRefused}`]:
      'Über dieses Konto findet derzeit ein anderes Gespräch statt. Bei Bedarf kannst du es später noch einmal versuchen.',
    [`${keys.maxTimeOutThresholdExceedMessage}`]:
      'Das Gespräch, in dem längere Zeit keine Aktivität stattfand, wurde vom System automatisch beendet. Vielen Dank für dein Verständnis.',
    [`${keys.agentLeftMessage}`]:
      'Der Kundenbetreuer hat das Gespräch beendet.',
    [`${keys.fileNameNotValidError}`]: 'Dateiname ist ungültig.',
    [`${keys.fileEmptyError}`]: 'Der hochgeladene Dateiinhalt ist leer.',
    [`${keys.chatConnectionErrorMessage}`]:
      'Ein Fehler ist aufgetreten, bitte erneut versuchen.',
    [`${keys.chatInputBarPlaceholderMessage}`]: 'Eine Nachricht schreiben...',
    [`${keys.resendMediaMessage}`]: 'Bitte erneut versuchen',
    [`${keys.resendMediaMessageForEmptyImage}`]: 'Du musst es neu laden.',
    [`${keys.unAuthorizedMessage}`]:
      'Du hast keine Berechtigung, auf diese Seite zuzugreifen',
    [`${keys.surveyMessage}`]: 'Warst du mit dem letzten Gespräch zufrieden?',
    [`${keys.trendyolAsistantSurveyMessage}`]:
      'Bist du zufrieden mit dem Trendyol Assistenten?',
    [`${keys.surveyCommentMessage}`]: 'Gibt es sonst noch etwas?',
    [`${keys.surveyRatingMessage}`]:
      'Bewerte deine Erfahrung mit Trendyol Assistant',
    [`${keys.liveChatSurveyRatingMessage}`]: 'Bewerte unseren Vertreter',
    [`${keys.surveyRatingBadMessage}`]: 'Sehr schlecht',
    [`${keys.surveyRatingGoodMessage}`]: 'Sehr gut',
    [`${keys.surveyRateButton}`]: 'Senden',
    [`${keys.surveyErrorModalTitle}`]:
      'BEI DER UMFRAGE IST EIN FEHLER AUFGETRETEN',
    [`${keys.surveyErrorRatingEmpty}`]: 'Bitte eine Punktzahl wählen.',
    [`${keys.surveyErrorServiceError}`]:
      'Ein Fehler ist aufgetreten, bitte erneut versuchen.',
    [`${keys.surveySuccessModalTitle}`]:
      'DIE BEWERTUNG WURDE ERFOLGREICH ABGEGEBEN',
    [`${keys.surveySuccessModalMessage}`]:
      'Vielen Dank für deine Teilnahme an unserer Umfrage!',
    [`${keys.liveChatNewMessageAppTitle}`]: 'Du hast eine neue Nachricht...',
    [`${keys.errorPageMessage}`]:
      'Ein Systemfehler ist aufgetreten. Bitte erneut versuchen',
    [`${keys.closeOnErrorButton}`]: 'Schließen',
    [`${keys.reconnectOnErrorButton}`]: 'Neu verbinden',
    [`${keys.liveChatReconnecting}`]: 'Die Verbindung wird wieder aufgebaut...',
    [`${keys.nameText}`]: 'Vorname',
    [`${keys.lastnameText}`]: 'Nachname',
    [`${keys.outOfWorkOurMessage}`]:
      'Leider ist unser Kundenservice zur Zeit nicht erreichbar. Deine Nachricht wurde weitergeleitet. Wir werden uns in Kürze bei dir melden.',
    [`${keys.invalidTokenInfoText}`]:
      'Wir können deine Daten nicht analysieren. Bitte beende die Anwendung und melde dich erneut an.',
    [`${keys.newText}`]: 'NEU',
    [`${keys.emailText}`]: 'E-mail',
    [`${keys.continueLiveChatText}`]: 'Zurück',
    [`${keys.exitLiveChatModalText}`]:
      'Bist du sicher, dass du den Chat beenden willst?',
    [`${keys.exitAndOpenSurvey}`]: 'Bestätige das Beenden des Chats',
    [`${keys.clientReconnectingMessage}`]:
      'Aufgrund der langsamen Internetverbindung versuchen wir erneut, dich zum Kundendienst weiterzuleiten. Das Gespräch wird an dem Punkt fortgesetzt, an dem es unterbrochen wurde. Vielen Dank für deine Geduld.',
    [`${keys.clientRedirectedMessage}`]:
      'Sehr geehrter Kunde, aufgrund der langsamen Internetverbindung leiten wir Sie an einen anderen Kundenbetreuer weiter. Der Gesprächsverlauf in diesem Anruf wird an den neuen Agenten übertragen. Bitte warten Sie, danke für Ihr Verständnis.',
    [`${keys.stickySurveyTitle}`]:
      'Bist du zufrieden mit dem Trendyol Assistenten?',
    [`${keys.stickySurveyRateMessage}`]: 'Bewerte bitte unseren Service',
    [`${keys.stickySurveyCommentMessage}`]: 'Gibt es sonst noch etwas?',
    [`${keys.stickySurveySuccessTitle}`]: 'Wir haben deine Bewertung erhalten!',
    [`${keys.stickySurveySuccessText}`]:
      'Vielen Dank für deine Bewertung, die uns dabei hilft, unseren Service immer besser zu machen.',
    [`${keys.stickySurveySuccessButtonText}`]: 'Schließen',

    [`${keys.placeHolderSurname}`]: 'Nachnamen eingeben',
    [`${keys.placeHolderName}`]: 'Vornamen eingeben',
    [`${keys.yourEmailText}`]: 'E-Mail',
    [`${keys.placeholderEmail}`]: 'E-Mail eingeben',
    [`${keys.languageText}`]: 'Sprache',
    [`${keys.placeholderLanguage}`]: 'Wähle eine Sprache',
    [`${keys.deliveryNumberText}`]: 'Liefernummer',
    [`${keys.placeholderDeliveryNumber}`]: 'Liefernummer eingeben',
    [`${keys.startChatText}`]: 'Chat starten',
    [`${keys.internationalTicketCreateSucceedTitle}`]:
      'Vielen Dank für die Kontaktaufnahme.',
    [`${keys.internationalTicketCreateSucceedText}`]:
      'Wir haben deine E-Mail erhalten. Wir werden uns in Kürze bei dir melden.',
    [`${keys.internationalTicketCreateFailedTitle}`]: '',
    [`${keys.internationalTicketCreateFailedText}`]:
      'Ein Fehler ist aufgetreten, bitte erneut versuchen.',
    [`${keys.closeButtonText}`]: 'Schließen',
    [`${keys.retryButtonText}`]: 'Bitte versuche es nochmal.',
    [`${keys.placeholderSubject}`]: 'Betreff eingeben',
    [`${keys.subjectText}`]: 'Betreff',
    [`${keys.internationalSubjectImageText}`]: 'Bilder Hochladen',
    [`${keys.mailDescriptionText}`]: 'Bitte schreibe deine Nachricht hier.',
    [`${keys.mailSubmitText}`]: 'Senden',
    [`${keys.placeHolderDescriptionText}`]: 'Beschreibung eingeben',
    [`${keys.englishLangText}`]: 'Englisch',
    [`${keys.germanLangText}`]: 'Deutsch',
    [`${keys.englishLangTextShort}`]: 'ENG',
    [`${keys.selectOrderMsg}`]: 'Auswählen',
    [`${keys.shipmentNo}`]: 'Liefernummer',
    [`${keys.orderSelectedMsg}`]: 'Ausgewählt',
    [`${keys.quantityText}`]: 'Artikel',
    [`${keys.claimOrderClaimableIsNotDone}`]:
      'Bitte fülle die Felder unten aus.',
    [`${keys.claimQuantityText}`]: 'Select Quantity',
    [`${keys.claimOrderReason}`]: 'Rückgabegründe auswählen',
    [`${keys.selectProductImageText}`]: 'Bilder hochladen (max. {{count}})',
    [`${keys.internationalMailSelectProductImageText}`]:
      'Bilder hochladen (max. {{count}})',
    [`${keys.claimOrderDescription}`]: 'Bitte Details hinzufügen',
    [`${keys.claimSimulationPreviewQuantity}`]: 'Menge',
    [`${keys.claimSimulationPreviewProduct}`]: 'Artikel',
    [`${keys.claimOrderFillToContinue}`]:
      'Um fortzufahren, fülle bitte die Menge und den Rücksendegrund für das von dir gewählte Produkt vollständig aus.',
    [`${keys.claimQuantityText}`]: 'Menge einstellen',
    [`${keys.optionalFileInfoText}`]: 'Bilder hochladen (Fakultativ)',
    [`${keys.imageFormatMismatchErrorText}`]:
      'Es können nur JPG- und PNG-Dateien hochgeladen werden.',
    [`${keys.imageMaxSizeErrorText}`]:
      'Die Dateigröße darf {{size}} MB nicht überschreiten',
    [`${keys.imageCountErrorText}`]:
      'Es können nur bis zu {{count}} Dateien hochgeladen werden.',
    [`${keys.imageReadError}`]:
      'Die Datei konnte nicht verarbeitet werden. Bitte versuche es erneut.',
    [`${keys.imageUploadError}`]:
      'Beim Hochladen der Datei ist ein Fehler aufgetreten. Bitte versuche es erneut.',
    [`${keys.shipmentSubtitleTotal}`]: 'Gesamt',
    [`${keys.shipmentSubtitleItems}`]: 'Artikel',
    [`${keys.orderNo}`]: 'Bestellnummer',
    [`${keys.seeAllAnnouncement}`]: 'Alle Ankündigungen Anzeigen',
    [`${keys.showMoreTicket}`]: 'Show More',
    [`${keys.notificationsTitle}`]: 'Ankündigungen',
    [`${keys.countAndPriceText}`]: 'Anzahl & Preis: ',
    [`${keys.totalClaimAmountText}`]: 'Erstatteter Gesamtbetrag',
    [`${keys.claimReasonText}`]: 'Grund der Rückgabe',
    [`${keys.claimAmountText}`]: 'Erstatteter Betrag',
    [`${keys.claimCoinAmountText}`]: 'Erstattete Trendyol Coins',
    [`${keys.coinText}`]: 'Trendyol Coins',
    [`${keys.claimStatusCargoText}`]: 'Wo ist meine Rücksendung?',
    [`${keys.showMoreShipment}`]: 'Mehr Artikel anzeigen',
    [`${keys.orderText}`]: 'Meine Bestellungen',
    [`${keys.ordersText}`]: 'Meine Bestellungen',
    [`${keys.currencyText}`]: '€',
    [`${keys.copyText}`]: 'Kopieren',
    [`${keys.copiedText}`]: 'Kopiert',
    [`${keys.deliveryDateText}`]: 'Lieferdatum:',
    [`${keys.paymentInfoText}`]: 'Zahlungsinformationen',
    [`${keys.subtotalText}`]: 'Zwischensumme',
    [`${keys.cargoText}`]: 'Versandkosten',
    [`${keys.totalText}`]: 'Gesamt',
    [`${keys.deliveryAddressText}`]: 'Lieferadresse',
    [`${keys.billingAddressText}`]: 'Rechnungsadresse',
    [`${keys.orderDate}`]: 'Bestelldatum',
    [`${keys.orderSummary}`]: 'Bestellübersicht',
    [`${keys.quantityText}`]: 'Menge',
    [`${keys.linkCopied}`]: 'Bestellnummer kopiert',
    [`${keys.detail}`]: 'Details',
    [`${keys.productText}`]: 'Artikel',
    [`${keys.deliveryTimeText}`]: 'Lieferdatum:',
    [`${keys.addExtraImage}`]: 'Fotos hinzufügen',
    [`${keys.myOrderDetail}`]: 'Meine Bestelldetails',
    [`${keys.inputLanguageNotSupported}`]:
      'Unser Support ist derzeit nur auf Deutsch verfügbar. Bitte sende deine Nachricht auf Deutsch.',
    [`${keys.showMoreContent}`]: 'Mehr Anzeigen',
    [`${keys.showLessContent}`]: 'Weniger Anzeigen',
    [`${keys.cancelOrderSelectProduct}`]:
      'Bitte wähle den Artikel aus und gib uns den Stornierungsgrund an.',
    [`${keys.cancelOrderSelectReason}`]:
      'Bitte wähle den Grund für die Stornierung.',
    [`${keys.selectReason}`]: 'Grund auswählen',
    [`${keys.other}`]: 'Erläuterung',
    [`${keys.explanation}`]:
      'Bitte erkläre uns hier, warum du den Artikel stornieren möchtest.',
    [`${keys.userDisconnectedOnBackground}`]:
      'Diese Unterhaltung wurde beendet, weil du entweder während des Chats die App verlassen hast oder eine lange Zeit nicht geantwortet hast. Vielen Dank für dein Verständnis.',
    [`${keys.refundDetailText}`]: 'Details zur Rückerstattung anzeigen',
    [`${keys.refundProgressBankInfo}`]: 'Ausstehende Maßnahmen deiner Bank',
    [`${keys.productImageText}`]: 'Bild zum Artikel',
    [`${keys.refundAmountText}`]: 'Rückerstattungssumme',
    [`${keys.claimLastModifiedDateTrText}`]:
      'Datum, an dem Trendyol deine Rückerstattung veranlasst hat',
    [`${keys.claimLastModifiedDateMillaText}`]:
      'Datum, an dem Trendyolmilla deine Rückerstattung veranlasst hat',
    [`${keys.estimatedRefundDateText}`]:
      'Voraussichtliche Dauer der Rückerstattung',
    [`${keys.bankRefNoText}`]: 'Referenznummer der Bank',
    [`${keys.refundProgressTrInfo}`]:
      'Trendyol hat deine Rückerstattung veranlasst',
    [`${keys.refundProgressMillaInfo}`]:
      'Trendyolmilla hat deine Rückerstattung veranlasst',
  },
};
