import React, { Fragment, useContext } from 'react';
import ChatBotContext from '../../../../context/chatbot/chatbotContext';
import './footerButton.scss';
import * as PropTypes from 'prop-types';
import ButtonGroup from '../../buttonGroup/ButtonGroup';

const FooterButton = ({ componentData, index }) => {
  const chatBotContext = useContext(ChatBotContext);
  const { requestToChatBot, isRequesting } = chatBotContext;

  const handleClick = (request) => {
    if (!isRequesting) {
      requestToChatBot(request);
    }
  };

  function renderButtons(data, i) {
    if (!data) return;

    let weight = data.weight ? data.weight : 1;

    return (
      <ButtonGroup.Button
        isSelectable={!isRequesting}
        key={i}
        colorType={'default'}
        iconLeft={data.iconUrl}
        title={data.text}
        onClick={() => handleClick(data)}
        weight={weight}
      />
    );
  }

  return (
    <div className="footer-button-container">
      {componentData && componentData.map(renderButtons)}
    </div>
  );
};

FooterButton.propTypes = {
  componentData: PropTypes.array.isRequired,
};

export default FooterButton;
