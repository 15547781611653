import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import Typing from '../typing/Typing';
import utils from '../../../utils';
import './ServerMessage.scss';
import ChatBotIcon from '../../common/ChatBotIcon/ChatBotIcon';
import appService from '../../../services/app.service';
import DOMPurify from 'dompurify';

const MUSTACHE_REGEX = /\{\{(.+?)\}\}/g;

const ServerMessage = ({
  message,
  typing,
  isLiveChat,
  isIconShow,
  timestamp,
  isEcho,
  setEmbeddedContent,
}) => {
  const [date, setDate] = useState(null);

  const renderHtml = (data) => {
    return DOMPurify.sanitize(data);
  };

  useEffect(() => {
    if (typeof timestamp === 'number') {
      const date = new Date(timestamp);
      setDate(utils.getTimeFromDate(date));
    }
    setDate(utils.getCurrentTime());
  }, []);

  const renderTypingIcon = () => {
    if (isLiveChat) {
      if (isIconShow) {
        return (
          <div className="server-message-icon">
            <img
              className="icon-image"
              src={appService.getDynamicStyleValue('customer-service-icon')}
              alt="chat_icon"
            />
          </div>
        );
      } else {
        return <div className="icon-placeholder" />;
      }
    }
  };

  const renderEmbbedMessage = () => {
    const arr = message.templateMessage.split(MUSTACHE_REGEX);

    return (
      <div>
        {arr.map((str) => {
          const item = message.contentMap?.[str];
          return item ? (
            <span
              className="embedded-clickable"
              onClick={() =>
                setEmbeddedContent?.({ ...item, header: message.header })
              }>
              {item.text}
            </span>
          ) : (
            str
          );
        })}
      </div>
    );
  };

  return (
    <div className="server-message" data-testid="server-message">
      <div className="server-message__container">
        {renderTypingIcon()}
        {!isLiveChat && typing && <ChatBotIcon />}
        <div className="server-message__container__bubble">
          {typing ? (
            <Typing />
          ) : (
            <div className="server-message__container__bubble__message">
              {message.isEmbedded
                ? renderEmbbedMessage()
                : message.split('\n').map((item, i) => {
                    return isEcho ? (
                      <p
                        key={i}
                        className="server-message__container__bubble__message__p"
                        dangerouslySetInnerHTML={{ __html: renderHtml(item) }}
                      />
                    ) : (
                      <p
                        key={i}
                        className="server-message__container__bubble__message__p">
                        {item}
                      </p>
                    );
                  })}
            </div>
          )}
          {isLiveChat && date ? (
            <div
              className="server-message__container__bubble__time"
              data-testid="date">
              {date}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

ServerMessage.propTypes = {
  message: PropTypes.string.isRequired,
  typing: PropTypes.bool.isRequired,
  isLiveChat: PropTypes.bool.isRequired,
};

export default React.memo(ServerMessage);
