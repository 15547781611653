import React, { useState } from 'react';
import { claimAmountText, claimReasonText } from '../../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import { FALLBACK_IMAGE_URL } from '../../../../constants';
import ClaimInfo from '../../../../assets/icons/claim-info.svg';
import ClaimCard from '../../../../assets/icons/claim-card.svg';
import ClaimWallet from '../../../../assets/icons/claim-wallet.svg';
import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';

const GoClaimDetailShipment = ({ id, detailItemList }) => {
  const { t } = useTranslation();
  const [imageFailed, setImageFailed] = useState(false);

  const fallbackImage = () => {
    setImageFailed(true);
  };

  const getImageByRefundTarget = (shipment) => {
    if (shipment.refundTargetText && shipment.refundTargetText === 'Card') {
      return ClaimCard;
    } else if (
      shipment.refundTargetText &&
      shipment.refundTargetText === 'Wallet'
    ) {
      return ClaimWallet;
    } else {
      return ClaimInfo;
    }
  };

  return (
    <div className="claim-detail-wrapper" data-testid="claim-detail-shipment">
      {detailItemList &&
        detailItemList.map((shipment, i) => {
          return (
            <>
              <div className="claim-detail-body" key={i}>
                <div className="details">
                  <div className="image__wrap">
                    {imageFailed
                      ? shipment.imageUrl && (
                          <img
                            src={FALLBACK_IMAGE_URL}
                            className="image"
                            alt="shipment-image"
                          />
                        )
                      : shipment.imageUrl && (
                          <img
                            src={shipment.imageUrl}
                            className="image"
                            alt="shipment-image"
                            onError={fallbackImage}
                          />
                        )}
                  </div>
                  <div className="detail">
                    <div
                      className={classnames([
                        'status',
                        {
                          'status-rejected':
                            shipment.status.type === 'REJECTED',
                        },
                      ])}>
                      <span
                        className={classnames([
                          'status-type',
                          {
                            'status-type-rejected':
                              shipment.status.type === 'REJECTED',
                            'status-waiting':
                              shipment.status.type === 'WAITING_IN_ACTION',
                          },
                        ])}>
                        {shipment.status.text}
                      </span>
                      {shipment.claimLastModifiedDate && (
                        <span
                          className={classnames([
                            'status-date',
                            {
                              'status-date-rejected':
                                shipment.status.type === 'REJECTED',
                            },
                          ])}>
                          {shipment.claimLastModifiedDate}
                        </span>
                      )}
                    </div>
                    {shipment.productName && (
                      <div className="product-name-wrapper">
                        <span className="text">{shipment.productName}</span>
                      </div>
                    )}
                    {shipment.reasonText && (
                      <div className="reason-wrapper">
                        <span className="text">{t(claimReasonText)}:</span>
                        <span className="reason">{shipment.reasonText}</span>
                      </div>
                    )}
                    {!!shipment.refundAmount && (
                      <div className="refund-amount">
                        <span className="text">{t(claimAmountText)}:</span>
                        <span className="refund-data">
                          {shipment.refundAmount + ' ' + shipment.currency}
                        </span>
                      </div>
                    )}
                    {shipment.refundInfoMessage && (
                      <div className="refund-infos">
                        <div className="refund-message">
                          {ReactHtmlParser(shipment.refundInfoMessage)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <hr />
            </>
          );
        })}
    </div>
  );
};

export default GoClaimDetailShipment;
