export const ADD_MESSAGE = 'ADD_MESSAGE';
export const MODIFY_MESSAGE = 'MODIFY_MESSAGE';
export const IS_AGENT_JOINED = 'IS_AGENT_JOINED';
export const LAST_MESSAGE_POSITION = 'LAST_MESSAGE_POSITION';
export const IS_SYSTEM_JOINED = 'IS_SYSTEM_JOINED';
export const IS_AGENT_OR_SYSTEM_JOINED_AT_LEAST_ONCE =
  'IS_AGENT_OR_SYSTEM_JOINED_AT_LEAST_ONCE';
export const IS_CONNECTING = 'SET_IS_CONNECTING';
export const IS_TYPING = 'SET_IS_TYPING';
export const IS_TIMEOUT = 'SET_IS_TIMEOUT';
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE';
export const COUNT_DOWN_TIMER_START_VALUE = 'SET_COUNT_DOWN_TIMER_START_VALUE';
export const SET_IS_USER_ALLOWED_TO_TYPE = 'SET_IS_USER_ALLOWED_TO_TYPE';
export const SET_LIVE_CHAT_MODAL = 'SET_LIVE_CHAT_MODAL';
export const SET_MEDIA_UPLOAD_STATUS = 'SET_MEDIA_UPLOAD_STATUS';
export const SET_SHOW_MEDIA_PREVIEW = 'SET_SHOW_MEDIA_PREVIEW';
export const CLEAR_LIVE_CHAT_CONTEXT = 'CLEAR_LIVE_CHAT_CONTEXT';
export const IS_LITE_MODE = 'IS_LITE_MODE';
export const IS_MODAL_OPEN = 'IS_MODAL_OPEN';
export const SET_IS_RECONNECTING = 'SET_IS_RECONNECTING';
export const SET_IS_AGENT_DISCONNECTED = 'SET_IS_AGENT_DISCONNECTED';
export const SET_IS_OUT_OF_WORK_HOURS = 'SET_IS_OUT_OF_WORK_HOURS';
export const SET_IS_SURVEY_OPENED = 'SET_IS_SURVEY_OPENED';
export const SET_IS_MODAL_ACTIVE = 'SET_IS_MODAL_ACTIVE';
export const SET_IS_END_CHAT = 'SET_IS_END_CHAT';
