import React from 'react';
import { orderText } from '../../../i18n/keys';
import appService from '../../../services/app.service';
import { useEchoLiveChat } from '../context/echoLiveChatContext';
import BoxIcon from '../../../assets/icons/box.svg';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

const EchoHeaderOrderIcon = () => {
  const { t } = useTranslation();
  const { active, isOutOfWorkHours, setShowOrdersModal } = useEchoLiveChat();

  const onClickMyOrders = () => {
    setShowOrdersModal(true);
  };

  if (!appService.isOrderBoxEnabled()) {
    return null;
  }

  if (isOutOfWorkHours || !active) {
    return null;
  }

  return (
    <div
      className={classnames([
        'order-icon-wrapper',
        {
          'order-icon-wrapper_web': appService.isPlatformWeb(),
        },
      ])}
      onClick={onClickMyOrders}>
      <img className="order-icon" alt="order-package-open-icon" src={BoxIcon} />
      <div className="header-info-title">{t(orderText)}</div>
    </div>
  );
};

export default EchoHeaderOrderIcon;
