import {
  COURIER_CHANNEL_ID,
  DEFAULT_CHANNEL_ID,
  DODO_CHANNEL_ID,
  DOLAP_CUSTOMER_CHANNEL_ID,
  INFLUENCER_SEGMENT_ID,
  INSTANT_ORDER_CHANNEL_ID,
  INT_CUSTOMER_CHANNEL_ID,
  INT_SELLER_CHANNEL_ID,
  MEAL_ORDER_CHANNEL_ID,
  SELLER_CHANNEL_ID,
} from '../constants/sessionStorageConstants';
import { replaceWithCurrentDomain } from './env.service';
import appService from './app.service';

export const calculateChatBotUrl = (channelid, orderChannelId, segmentId) => {
  const conditions = [
    {
      condition: isInstantSellerAssistant,
      url: process.env.REACT_APP_SELLER_INSTANT_CHATBOT_URL,
    },
    {
      condition: isMealSellerAssistant,
      url: process.env.REACT_APP_SELLER_MEAL_CHATBOT_URL,
    },
    {
      condition: () => channelid === SELLER_CHANNEL_ID,
      url: process.env.REACT_APP_SELLER_CHATBOT_URL,
    },
    {
      condition: () => segmentId === INFLUENCER_SEGMENT_ID,
      url: process.env.REACT_APP_INFLUENCER_CHATBOT_URL,
    },
    {
      condition: () => isInstantAssistant(orderChannelId, channelid),
      url: process.env.REACT_APP_INSTANT_CHATBOT_URL,
    },
    {
      condition: () => isMealAssistant(orderChannelId, channelid),
      url: process.env.REACT_APP_MEAL_CHATBOT_URL,
    },
    {
      condition: () => channelid === DEFAULT_CHANNEL_ID,
      url: process.env.REACT_APP_CUSTOMER_CHATBOT_URL,
    },
    {
      condition: () => channelid === COURIER_CHANNEL_ID,
      url: process.env.REACT_APP_COURIER_CHATBOT_URL,
    },
    {
      condition: () => channelid === INT_CUSTOMER_CHANNEL_ID,
      url: process.env.REACT_APP_INT_CUSTOMER_CHATBOT_URL,
    },
    {
      condition: () => channelid === DOLAP_CUSTOMER_CHANNEL_ID,
      url: process.env.REACT_APP_DOLAP_CUSTOMER_CHATBOT_URL,
    },
    {
      condition: () => channelid === INT_SELLER_CHANNEL_ID,
      url: process.env.REACT_APP_INT_SELLER_CHATBOT_URL,
    },
    {
      condition: () => channelid === DODO_CHANNEL_ID,
      url: process.env.REACT_APP_DODO_CHATBOT_URL,
    },
  ];

  for (const { condition, url } of conditions) {
    if (condition(orderChannelId, channelid, segmentId)) {
      return replaceWithCurrentDomain(url);
    }
  }

  return replaceWithCurrentDomain(process.env.REACT_APP_CHATBOT_URL);
};

function isMealSellerAssistant(orderChannelId, channelid) {
  return (
    orderChannelId === MEAL_ORDER_CHANNEL_ID && channelid === SELLER_CHANNEL_ID
  );
}

function isInstantSellerAssistant(orderChannelId, channelid) {
  return (
    orderChannelId === INSTANT_ORDER_CHANNEL_ID &&
    channelid === SELLER_CHANNEL_ID
  );
}

function isInstantAssistant(orderChannelId, channelid) {
  return (
    orderChannelId === INSTANT_ORDER_CHANNEL_ID &&
    channelid === DEFAULT_CHANNEL_ID
  );
}

function isMealAssistant(orderChannelId, channelid) {
  return (
    orderChannelId === MEAL_ORDER_CHANNEL_ID && channelid === DEFAULT_CHANNEL_ID
  );
}
