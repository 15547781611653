import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './InternationalSendEmail.scss';

import appService from '../../../../services/app.service';

import classNames from 'classnames';
import usePersistentState from '../../../common/usePersistentState';
import {
  EMAIL_REGEX,
  ONLY_LETTER_REGEX,
  ONLY_NUMBERIC_REGEX,
} from '../../../../constants/validationRegexConstants';
import Select from '../../../Select';
import StickyHeader from '../../../chatAssistant/stickyHeader/StickyHeader';
import ProcessedPage from '../../processedPage/ProcessedPage';
import {
  closeOnErrorButton,
  deliveryNumberText,
  inputLanguageNotSupported,
  internationalMailSelectProductImageText,
  internationalSubjectImageText,
  internationalTicketCreateFailedText,
  internationalTicketCreateFailedTitle,
  internationalTicketCreateSucceedText,
  internationalTicketCreateSucceedTitle,
  lastnameText,
  mailDescriptionText,
  mailSubmitText,
  nameText,
  placeholderDeliveryNumber,
  placeHolderDescriptionText,
  placeholderEmail,
  placeHolderName,
  placeholderSubject,
  placeHolderSurname,
  retryButtonText,
  subjectText,
  yourEmailText,
} from '../../../../i18n/keys';
import ChatBotContext from '../../../../context/chatbot/chatbotContext';
import FileInput from '../../../chatAssistant/fileInput/FileInput';
import {
  INTERNATIONAL_MAIL_IMAGE_ACCEPTED_FORMATS,
  INTERNATIONAL_MAIL_UPLOAD_MAX_FILE_COUNT,
  INTERNATIONAL_MAIL_UPLOAD_MAX_FILE_SIZE,
} from '../../../../constants';
import Alert from '../../../layout/alert/Alert';
import InputLabel from '../../../common/InputLabel/InputLabel';
import TextArea from '../../../common/TextArea/TextArea';
import ButtonGroup from '../../../chatAssistant/buttonGroup/ButtonGroup';
import Utils from '../../../../utils';

const InternationalSendEmail = ({ id = 'internationalCreateTicket' }) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = usePersistentState(id, 'firstname', '');
  const [lastName, setLastName] = usePersistentState(id, 'lastname', '');
  const [email, setEmail] = usePersistentState(id, 'email', '');
  const [subject, setSubject] = usePersistentState(id, 'subject', '');
  const [isImageRequired, setIsImageRequired] = usePersistentState(
    id,
    'isImageRequired',
    false
  );
  const [submitError, setSubmitError] = usePersistentState(
    id,
    'submitError',
    false
  );
  const [isSubmitted, setIsSubmitted] = usePersistentState(
    id,
    'isSubmitted',
    false
  );
  const [description, setDescription] = usePersistentState(
    id,
    'description',
    ''
  );
  const [deliveryNumber, setDeliveryNumber] = usePersistentState(
    id,
    'deliverynumber',
    appService.getShipmentNumber() ? appService.getShipmentNumber() : ''
  );

  const [selectedFiles, setSelectedFiles] = usePersistentState(
    id,
    'selectedFiles',
    []
  );
  const [fileUploadErrors, setFileUploadErrors] = usePersistentState(
    id,
    'fileUploadErrors',
    []
  );

  const [isDone, setIsDone] = usePersistentState(id, 'isDone', false);
  const [isArabicErrorOccured, setIsArabicErrorOccured] = usePersistentState(
    id,
    'isArabicErrorOccured',
    false
  );

  const chatBotContext = useContext(ChatBotContext);
  const { setIsChatBotSurvey } = chatBotContext;

  function setIsDoneForAuthenticatedUser() {
    return setIsDone(
      subject && description && (!isImageRequired || selectedFiles.length > 0)
    );
  }

  useEffect(() => {
    if (appService.isAuthenticatedInternationalUser()) {
      setIsDoneForAuthenticatedUser();
    } else {
      setIsDone(
        firstName &&
          lastName &&
          subject &&
          description &&
          email &&
          validateEmail(email) &&
          (!isImageRequired || selectedFiles.length > 0)
      );
    }
  }, [
    firstName,
    lastName,
    email,
    subject,
    description,
    setIsDone,
    selectedFiles,
  ]);

  function validateEmail(email) {
    return EMAIL_REGEX.test(String(email).toLowerCase());
  }

  const handleFirstName = (value) => {
    if (value.length >= 80) {
      value = value.substring(0, 80);
    }
    let newValue = value.replace(ONLY_LETTER_REGEX, '');
    setFirstName(newValue);
  };

  const handleLastName = (value) => {
    if (value.length >= 80) {
      value = value.substring(0, 80);
    }
    let newValue = value.replace(ONLY_LETTER_REGEX, '');
    setLastName(newValue);
  };

  const handleEmail = (value) => {
    setEmail(value);
  };

  const handleSubject = (value) => {
    setSubject(value);
    let subject = appService
      .getInternationalMailSubjects()
      .find((s) => s.id === value);

    setIsImageRequired(subject.isImageRequired);
  };

  const handleDeliveryNumber = (value) => {
    if (value.length >= 14) {
      value = value.substring(0, 14);
    }
    let newValue = value.replace(ONLY_NUMBERIC_REGEX, '');
    setDeliveryNumber(newValue);
  };

  const onCloseAssistant = async () => {
    const checkSurvey = await appService.isChatBotSurveyWillOpen();
    if (checkSurvey.openSurvey) {
      setIsChatBotSurvey(true);
    } else {
      if (appService.getIsStickyAssistant()) {
        appService.closeStickyPopup();
      } else {
        await appService.exitAssistant();
      }
    }
  };

  const onRedirectToMailPage = () => {
    appService.setIsInternationalSendEmailOpened(true);
    setIsSubmitted(false);
  };

  const getSubjects = () => {
    return appService.getInternationalMailSubjects().map((subject) => {
      return {
        value: subject.id,
        label: subject.names[appService.getLanguage()],
      };
    });
  };

  async function handleSubmit() {
    const emailData = {
      firstName,
      lastName,
      email,
      subject: subject.label,
      description: description,
      deliveryNumber,
      files: selectedFiles,
    };
    await appService
      .sendInternationalEmail(emailData)
      .then(() => {})
      .catch((e) => {
        setSubmitError(true);
      })
      .finally(() => setIsSubmitted(true));
  }

  if (isSubmitted) {
    return (
      <div className="sticky-survey-success-page">
        <StickyHeader
          onCloseAssistant={onCloseAssistant}
          showSubHeader={false}
        />
        <ProcessedPage
          title={
            submitError
              ? t(internationalTicketCreateFailedTitle)
              : t(internationalTicketCreateSucceedTitle)
          }
          text={
            submitError
              ? t(internationalTicketCreateFailedText)
              : t(internationalTicketCreateSucceedText)
          }
          btnText={submitError ? t(retryButtonText) : t(closeOnErrorButton)}
          createTicketSuccessPageBtn={true}
          isFailedPage={submitError}
          btnEvent={submitError ? onRedirectToMailPage : onCloseAssistant}
        />
      </div>
    );
  }

  const handleChangeDescription = (value) => {
    if (Utils.isInputArabic(value)) {
      setIsArabicErrorOccured(true);
      return;
    }
    setIsArabicErrorOccured(false);
    setDescription(value);
  };

  const onImagesChange = (images) => {
    setSelectedFiles(images.map((image) => image.response.data));
  };

  const onFileUploadError = (errors) => {
    setFileUploadErrors(errors);
  };

  return (
    <div className="international-create-ticket-wrapper">
      <StickyHeader onCloseAssistant={onCloseAssistant} showSubHeader={false} />
      <div className="international-create-ticket-container">
        <div className="content-wrapper">
          <div className="inner-content-wrapper">
            <div
              className={classNames({
                'name-fields': true,
                row: true,
                authenticated: appService.isAuthenticatedInternationalUser(),
              })}>
              <div className="first-name">
                <InputLabel text={t(nameText)} required />
                <input
                  type="text"
                  value={firstName}
                  className="assistant-input"
                  placeholder={t(placeHolderName)}
                  onChange={(e) => handleFirstName(e.target.value)}
                  alt={t(nameText)}
                />
              </div>
              <div className="last-name">
                <InputLabel text={t(lastnameText)} required />
                <div className="input-area">
                  <input
                    type="text"
                    value={lastName}
                    className="assistant-input"
                    placeholder={t(placeHolderSurname)}
                    onChange={(e) => handleLastName(e.target.value)}
                    alt={t(lastnameText)}
                  />
                </div>
              </div>
            </div>
            <div
              className={classNames({
                email: true,
                row: true,
                authenticated: appService.isAuthenticatedInternationalUser(),
              })}>
              <InputLabel text={t(yourEmailText)} required />
              <input
                type="text"
                value={email}
                className="assistant-input"
                placeholder={t(placeholderEmail)}
                onChange={(e) => handleEmail(e.target.value)}
                alt={t(yourEmailText)}
              />
            </div>
            <div className="subject row">
              <div className="input-area">
                <Select
                  label={t(subjectText)}
                  required
                  options={getSubjects()}
                  placeholder={t(placeholderSubject)}
                  value={subject}
                  onChange={handleSubject}
                />
              </div>
            </div>
            <div className="file input row">
              <InputLabel
                text={t(internationalSubjectImageText)}
                required={isImageRequired}
              />

              <div className="international-file-input-area">
                <FileInput
                  id={id + 'FileInput'}
                  placeholder={t(internationalMailSelectProductImageText, {
                    count: INTERNATIONAL_MAIL_UPLOAD_MAX_FILE_COUNT,
                  })}
                  onImagesChange={onImagesChange}
                  onError={onFileUploadError}
                  maxFileCount={INTERNATIONAL_MAIL_UPLOAD_MAX_FILE_COUNT}
                  maxFileSize={INTERNATIONAL_MAIL_UPLOAD_MAX_FILE_SIZE}
                  accept={INTERNATIONAL_MAIL_IMAGE_ACCEPTED_FORMATS}
                  useCdn={true}
                  preview={true}
                  lang={appService.getLanguage()}
                />
              </div>
            </div>

            {!!fileUploadErrors.length && (
              <div className="alert-message">
                <Alert messages={fileUploadErrors} />
              </div>
            )}

            <div className="description row">
              <InputLabel text={t(mailDescriptionText)} required />

              <TextArea
                placeholder={t(placeHolderDescriptionText)}
                value={description}
                maxLength="500"
                onChange={(e) => handleChangeDescription(e.target.value)}
              />
            </div>
            {!appService.getShipmentNumber() && (
              <div className="delivery-number row">
                <InputLabel text={t(deliveryNumberText)} />
                <input
                  type="text"
                  value={deliveryNumber}
                  className="assistant-input"
                  placeholder={t(placeholderDeliveryNumber)}
                  onChange={(e) => handleDeliveryNumber(e.target.value)}
                  alt={t(deliveryNumberText)}
                />
              </div>
            )}
          </div>
          {isArabicErrorOccured && (
            <div className="row">
              <Alert message={t(inputLanguageNotSupported)} />
            </div>
          )}
          <ButtonGroup>
            <ButtonGroup.Button
              colorType="primary"
              outline={false}
              isSelectable={isDone}
              disabled={!isDone}
              onClick={handleSubmit}>
              {t(mailSubmitText)}
            </ButtonGroup.Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default InternationalSendEmail;
