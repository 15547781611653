import React, { useEffect } from 'react';
import classNames from 'classnames';
import {
  claimCodeText,
  orderSelectedMsg,
  selectOrderMsg,
  shipmentNo,
  shipmentSubtitleItems,
  shipmentSubtitleTotal,
} from '../../../../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import { FALLBACK_IMAGE_URL, REJECTED } from '../../../../../../constants';
import utils from '../../../../../../utils';
import appService from '../../../../../../services/app.service';
import usePersistentState from '../../../../../common/usePersistentState';
import './ClaimCodeItem.scss';

const ClaimCodeItem = ({
  id,
  claimCodeItem,
  select,
  orderItemActive,
  selectedClaimId,
  setSelectedClaimId,
  isRenderedFromOutside = false,
}) => {
  const [imageFailed, setImageFailed] = usePersistentState(
    id,
    'imageFailed',
    false
  );
  const { t } = useTranslation();
  const [isSelectedClaimCode, setIsSelectedClaimCode] = usePersistentState(
    id,
    'isSelectedClaimCode',
    false
  );

  const {
    cargoTrackingNumber,
    event,
    claimId,
    imageUrls = [],
    shipmentNumber,
    productNames = [],
    status = {},
    orderDate,
    isCollectionPoint,
    showCargoTrackingNumber = true,
    orderId,
    orderNumber,
  } = claimCodeItem;

  const { type } = status;
  const typeForUserLower = (type || '').toLowerCase();

  useEffect(() => {
    if (orderItemActive) {
      setIsSelectedClaimCode(selectedClaimId === claimId);
    }
  }, [selectedClaimId]);

  const onSelect = () => {
    if (orderItemActive) {
      appService.setShipmentNumber(shipmentNumber);
      appService.setOrderNumber(orderNumber);
      let request = {
        event: event,
        data: {
          claimId,
          cargoTrackingNumber,
          shipmentNumber,
          orderId,
        },
      };
      setSelectedClaimId(claimId);
      select(request);
    }
  };

  const fallbackImage = () => {
    setImageFailed(true);
  };

  let linkStyle;
  if (orderItemActive) {
    linkStyle = { cursor: 'pointer' };
  } else {
    linkStyle = { cursor: 'unset', border: '1px solid #e4e4e4' };
  }

  const getStatusIconUrl = (status) => {
    let src = '';
    try {
      src = require(`../../../../../../assets/icons/status/${status}.svg`);
      if (src.default) {
        src = src.default;
      }
    } catch (error) {
      src = FALLBACK_IMAGE_URL;
    }
    return src;
  };

  const renderImages = (imageUrls) => {
    return utils.renderShipmentImagesForAvatarComponent(
      imageUrls,
      fallbackImage,
      imageFailed
    );
  };

  const renderStamp = () => {
    if (isCollectionPoint) {
      return <span>GEL AL NOKTASI</span>;
    }
  };

  return (
    <div
      className={classNames({
        'claim-code-item-container': true,
        'claim-code-item-container--selected': isSelectedClaimCode,
        'claim-code-item-container--hover': true,
        'claim-code-item-container--readonly': isRenderedFromOutside,
        dynamic: appService.isDynamicStylingChannel(),
      })}
      onClick={() => onSelect(claimCodeItem)}
      style={linkStyle}
      data-testid="orderitem">
      <div
        className={classNames({
          body: true,
          'body--selected': isSelectedClaimCode,
        })}>
        <div className="details">
          <div className="shipment-info">
            <div
              className={classNames({
                area: true,
                'area--delivered': type !== REJECTED,
                'area--voided': type === REJECTED,
                'area--delivered--dynamic':
                  appService.isDynamicStylingChannel(),
              })}>
              <img
                className={classNames('status-icon', typeForUserLower)}
                src={getStatusIconUrl(typeForUserLower)}
              />
              <span>{status && status.text}</span>
            </div>
          </div>
          <div className="avatars">{renderImages(imageUrls)}</div>
          <div className="detail">
            <div className="title" data-testid="title">
              {productNames[0]}
            </div>
            {imageUrls.length > 1 && (
              <div
                className={classNames({
                  subtitle: true,
                  'subtitle--dynamic': appService.isDynamicStylingChannel(),
                })}>
                {t(shipmentSubtitleTotal) +
                  ' ' +
                  productNames.length +
                  ' ' +
                  t(shipmentSubtitleItems)}
              </div>
            )}
          </div>
        </div>
        <div className="instant-delivery">{renderStamp()}</div>
        <div className="claim-detail">
          <div className="order-date">{orderDate}</div>
          <div className="shipment-number-info">
            <div className="shipment-number-label">{t(shipmentNo)}</div>
            <div className="shipment-number-label">:</div>
            <div className="shipment-number">{shipmentNumber}</div>
          </div>
          <div className="cargo-tracking-number">
            {showCargoTrackingNumber && cargoTrackingNumber && (
              <div>
                <div className="claim-code">{t(claimCodeText)}:</div>
                <div className="claim-code-value">
                  {utils.splitValueWithSpace(cargoTrackingNumber, 4)}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames({
            pick: true,
            'pick--selected': isSelectedClaimCode,
            'pick--dynamic': appService.isDynamicStylingChannel(),
          })}>
          <div className="title" data-testid="pick-title">
            {isSelectedClaimCode ? t(orderSelectedMsg) : t(selectOrderMsg)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimCodeItem;
