import './ClaimCargo.scss';

import React, { useEffect } from 'react';
import classNames from 'classnames';
import Radio from '@tx-components/radio';
import { BUTTON_SELECTED_NO } from '../../../../constants';
import Bubble from '../../../layout/bubble/Bubble';
import OperationButton from '../../operationButton/OperationButton';
import usePersistentState from '../../../common/usePersistentState';

const ClaimCargo = ({
  id,
  componentData,
  isSelectable,
  select,
  showHover,
  isRenderedFromOutside = false,
}) => {
  const [selectedCargoId, setSelectedCargoId] = usePersistentState(
    id,
    'selectedCargoId',
    ''
  );

  useEffect(() => {
    if (componentData.cargoProviderList.length >= 1) {
      setSelectedCargoId(componentData.cargoProviderList[0].id);
    }
  }, []);

  const handleButtonClick = (button) => {
    if (isSelectable) {
      if (button.type === BUTTON_SELECTED_NO) {
        let request = {
          event: button.event,
        };
        select(request);
      } else if (selectedCargoId) {
        let request = {
          event: button.event,
          data: {
            cargoCompanyId: selectedCargoId,
          },
        };
        select(request);
      }
    }
  };

  const onChangeCargo = (cargoCompanyId) => {
    setSelectedCargoId(cargoCompanyId);
  };

  return (
    <div
      className={classNames(['claim-cargo', { disabled: !isSelectable }])}
      data-testid="claim-cargo">
      <Bubble title={componentData.label} isBottomRounded={false}>
        <div className="items">
          <div className="claim-cargo-items">
            {componentData &&
              componentData.cargoProviderList &&
              componentData.cargoProviderList.map((cargo) => (
                <div
                  className={classNames([
                    'claim-cargo-item',
                    {
                      'claim-cargo-item--selected':
                        cargo.id === selectedCargoId,
                      'claim-cargo-item--not-selected':
                        selectedCargoId && cargo.id !== selectedCargoId,
                    },
                  ])}
                  key={cargo.id}
                  data-testid="claim-cargo-item"
                  onClick={(e) => onChangeCargo(cargo.id)}>
                  <div className="cargo-name">
                    <Radio
                      className="cargo-radio"
                      checked={cargo.id === selectedCargoId}></Radio>
                    <span
                      className={classNames({
                        selected: cargo.id === selectedCargoId,
                      })}>
                      {cargo.name}
                    </span>
                  </div>
                  <div className="cargo-image">
                    <img
                      className="cargo-icon"
                      src={cargo.imageUrl}
                      alt="cargo-image"
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <OperationButton
          isRenderedFromOutside={isRenderedFromOutside}
          componentData={componentData}
          onClick={handleButtonClick}
          showHover={showHover}
          showLabel={false}
        />
      </Bubble>
    </div>
  );
};

export default ClaimCargo;
