// Chat Bot API ClientMessage Display Types
export const ANSWER = 'ANSWER';
export const SINGLE_SELECT = 'SINGLE_SELECT';
export const IMAGE = 'IMAGE';
export const CANCEL_SIMULATION_PREVIEW_ITEM = 'CANCEL_SIMULATION_PREVIEW_ITEM';
export const RETURN_SIMULATION_PREVIEW_ITEM = 'RETURN_SIMULATION_PREVIEW_ITEM';

// Choice Decision
export const VOTE_SELECTED_YES = '_UP';
export const VOTE_SELECTED_NO = '_DOWN';

// Choice Topics
export const CLAIM_CANCEL_SIMULATION_UP = 'CLAIM_CANCEL_SIMULATION_UP';
export const CLAIM_CANCEL_SIMULATION_DOWN = 'CLAIM_CANCEL_SIMULATION_DOWN';
export const CLAIM_RETURN_SIMULATION_UP = 'CLAIM_RETURN_SIMULATION_UP';
export const CLAIM_RETURN_SIMULATION_DOWN = 'CLAIM_RETURN_SIMULATION_DOWN';

// Shipment Statuss
export const WARN = 'Warn';
export const WARN_YELLOW = 'Warn Yellow';
export const CANCELLED = 'Cancelled';
export const SUCCESS = 'Success';
export const CREATED = 'CREATED';
export const REJECTED = 'REJECTED';

// Fallback Image
export const FALLBACK_IMAGE_URL =
  'https://cdn.dsmcdn.com/assistant/defaultThumb.jpeg';

// Deep Link
export const LIVE_CHAT_LINK = '/customer-support';
export const ORDER_PAGE_LINK = '/Hesabim#/Siparislerim';
export const CLOSE_WINDOW_LINK = '/';

// Environment Names
export const DEV = 'development';
export const PLATFORM_WEB = 'Web';
export const PLATFORM_MWEB = 'MWeb';
export const PLATFORM_IOS = 'IosV2';
export const PLATFORM_ANDROID = 'AndroidV2';
export const EVENT_CHANNEL = 'web';
export const LIVE_CHAT_PAGE = '/live-chat';

// Delphoi Events
export const EVENT_NAME = 'chatBot';
export const EVENT_ACTION_DECISION_COMPONENT = 'decisionQuestion';
export const EVENT_USER_AGENT_COMPONENT = 'userAgentComponent';
export const EVENT_ORDERS_PAGE_COMPONENT = 'ordersPageClick';

export const BUTTON_SELECTED_YES = 'Yes';
export const BUTTON_SELECTED_NO = 'No';
export const BUTTON_PRIMARY = 'Primary';
export const BUTTON_SECONDARY = 'Secondary';
export const BUTTON_CANCEL = 'Cancel';
export const BUTTON_YES_BLACK = 'YesBlack';
export const BUTTON_NO_BLACK = 'NoBlack';
export const BUTTON_NEW = 'New';

//page redirect
export const pages = {
  CHATBOT: 'chatBot',
  LIVECHAT: 'liveChat',
  CHATBOT_SURVEY: 'chatBotSurvey',
  LIVECHAT_SURVEY: 'chatBotSurvey',
};

//keycodes
export const ENTER_KEYCODE = 13;

//Http status code
export const UNAUTHORIZED_HTTP_STATUS_CODE = 401;
export const CONFLICT_HTTP_STATUS_CODE = 409;

//claim item photo required
export const PHOTO_NOT_REQUIRED = 1;
export const PHOTO_OPTIONAL = 2;
export const PHOTO_REQUIRED = 3;

//claim item video required
export const VIDEO_NOT_REQUIRED = 1;
export const VIDEO_OPTIONAL = 2;
export const VIDEO_REQUIRED = 3;

export const CLAIM_UPLOAD_MAX_FILE_COUNT = 3;
export const CLAIM_VIDEO_UPLOAD_MAX_FILE_COUNT = 1;
export const INTERNATIONAL_MAIL_UPLOAD_MAX_FILE_COUNT = 3;
export const INTERNATIONAL_MAIL_UPLOAD_MAX_FILE_SIZE = 5;
export const CLAIM_UPLOAD_VIDEO_ACCEPTED_FORMATS = 'video/mp4,video/quicktime';
export const INTERNATIONAL_MAIL_IMAGE_ACCEPTED_FORMATS =
  'image/jpg,image/jpeg,image/png';
export const CLAIM_UPLOAD_MAX_FILE_SIZE = 5; // mb
export const CLAIM_VIDEO_UPLOAD_MAX_FILE_SIZE = 40; // mb
export const CLAIM_UPLOAD_IMAGE_ACCEPTED_FORMATS =
  'image/jpg,image/jpeg,image/png';

export const GO_DELIVERED_PRODUCT_IMAGE_UPLOAD_ACCEPTED_FORMATS =
  'image/jpg,image/jpeg,image/png';

export const GO_DELIVERED_PRODUCT_RECEIPT_ACCEPTED_FORMATS =
  'image/jpg,image/jpeg,image/png,application/pdf';

export const INTERNATIONAL_SUFFIX = 'international';

export const ANNOUNCEMENT_TYPE_DEFAULT = 'Default';

export const USER_APP_STATE_BACKGROUND = 'BACKGROUND';
export const USER_APP_STATE_FOREGROUND = 'FOREGROUND';

export const MIN_UPLOAD_SIZE = 1;
