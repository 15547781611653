import React from 'react';

const RightArrow = ({ handleClickRight, className = '' }) => {
  return (
    <span
      data-testid="right-arrow"
      onClick={() => handleClickRight()}
      className={'jc-right ' + className}>
      <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
      </svg>
    </span>
  );
};

export default RightArrow;
