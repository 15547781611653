import './ClaimableItem.scss';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '../../../../../../Select';
import Checkbox from '@tx-components/checkbox';
import classnames from 'classnames';
import {
  claimOrderDescription,
  claimOrderReason,
  claimQuantityText,
  optionalFileInfoText,
  quantityText,
  selectProductImageText,
  selectProductVideoText,
  fileInputVideoInfo,
} from '../../../../../../../i18n/keys';
import FileInput from '../../../../../fileInput/FileInput';
import {
  CLAIM_UPLOAD_IMAGE_ACCEPTED_FORMATS,
  CLAIM_UPLOAD_MAX_FILE_COUNT,
  CLAIM_UPLOAD_MAX_FILE_SIZE,
  CLAIM_VIDEO_UPLOAD_MAX_FILE_COUNT,
  CLAIM_VIDEO_UPLOAD_MAX_FILE_SIZE,
  CLAIM_UPLOAD_VIDEO_ACCEPTED_FORMATS,
  PHOTO_NOT_REQUIRED,
  PHOTO_OPTIONAL,
  PHOTO_REQUIRED,
  VIDEO_NOT_REQUIRED,
  VIDEO_REQUIRED,
} from '../../../../../../../constants';
import Alert from '../../../../../../layout/alert/Alert';
import AlertIcon from '../../../../../../../assets/icons/alert.svg';

import usePersistentState from '../../../../../../common/usePersistentState';
import appService from '../../../../../../../services/app.service';
import TextArea from '../../../../../../common/TextArea/TextArea';
import NextComponent from '../../../../../nextComponent/NextComponent';

const { Option } = Select;

const ClaimableItem = ({
  id,
  lineItemId,
  listingId,
  imgSource,
  title,
  totalQuantity,
  reasons = [],
  isReasonRendered,
  variantName,
  onChangeSelectedItem,
  onChangeDoneItem,
  preSelectedExternalReasonId,
  selectedQuantity,
  preDescription,
  firstSelectedItemId,
  onFirstSelectedItemIdChange,
  firstSelectedReasonDetailId,
  onFirstSelectedReasonDetailIdChange,
  maxDescriptionLength,
  applyFirstSelectedReasonDetailToOthers,
  showHover,
  select,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = usePersistentState(
    id,
    'selected',
    preSelectedExternalReasonId && preSelectedExternalReasonId !== -1
  );
  const [claimQuantity, setClaimQuantity] = usePersistentState(
    id,
    'claimQuantity'
  );
  const [reasonId, setReasonId] = usePersistentState(id, 'reasonId');
  const [reasonDetailId, setReasonDetailId] = usePersistentState(
    id,
    'reasonDetailId'
  );
  const [reason, setReason] = usePersistentState(id, 'reason');
  const [description, setDescription] = usePersistentState(
    id,
    'description',
    ''
  );
  const [quantityList, setQuantityList] = usePersistentState(
    id,
    'quantityList',
    []
  );
  const [descriptionAvailableMap, setDescriptionAvailableMap] =
    usePersistentState(id, 'descriptionAvailableMap', {});
  const [descriptionAvailable, setDescriptionAvailable] = usePersistentState(
    id,
    'descriptionAvailable',
    false
  );
  const [isDone, setIsDone] = usePersistentState(id, 'isDone', false);
  const [images, setImages] = usePersistentState(id, 'images', []);
  const [videos, setVideos] = usePersistentState(id, 'videos', []);
  const [imageUploadErrors, setImageUploadErrors] = usePersistentState(
    id,
    'imageUploadErrors',
    []
  );
  const [videoUploadErrors, setVideoUploadErrors] = usePersistentState(
    id,
    'videoUploadErrors',
    []
  );

  useEffect(() => {
    if (applyFirstSelectedReasonDetailToOthers) {
      setReasonDetailId(firstSelectedReasonDetailId);
    }
  }, [firstSelectedReasonDetailId]);

  // Easy claim's handler. After reading json cookie, we set these fields to UI automatically.
  useEffect(() => {
    if (preSelectedExternalReasonId && preSelectedExternalReasonId !== -1) {
      const reasonIdFound = getReasonIdByExternalId(
        preSelectedExternalReasonId
      );

      const preSelectedReason = reasons.find(
        (item) => item.id === reasonIdFound
      );

      setReasonId(reasonIdFound);
      setReason(preSelectedReason);
      setDescriptionAvailable(preSelectedReason.descriptionAvailable);
      setClaimQuantity(selectedQuantity);

      if (preDescription && preSelectedReason.descriptionAvailable) {
        setDescription(preDescription);
      }
    }
  }, []);

  useEffect(() => {
    const newQuantityList = [];
    for (let i = 0; i < totalQuantity; i++) {
      newQuantityList.push(i + 1);
    }
    setQuantityList(newQuantityList);
  }, [totalQuantity]);

  useEffect(() => {
    if (selected) {
      if (totalQuantity === 1) {
        setClaimQuantity(1);
      }

      if (reasons && reasons.length === 1) {
        setReason(reasons[0]);
        setReasonId(reasons[0].id);
        setDescriptionAvailable(reasons[0].descriptionAvailable);
      }
    }
  }, [selected]);

  useEffect(() => {
    const availableMap = {};
    reasons
      .filter((reason) => reason.descriptionAvailable)
      .forEach((reason) => {
        availableMap[reason.id] = true;
      });
    setDescriptionAvailableMap(availableMap);
  }, [reasons]);

  const getReasonExternalId = () => {
    let selectedReason = reasons.find((r) => {
      return r.id === reasonId;
    });
    if (selectedReason) {
      return selectedReason.externalReasonId;
    }
    return undefined;
  };

  const getReasonIdByExternalId = (externalReasonId) => {
    let selectedReason = reasons.find((r) => {
      return r.externalReasonId === externalReasonId;
    });
    if (selectedReason) {
      return selectedReason.id;
    }
    return undefined;
  };

  const checkIsPhotoRequired = () => {
    if (reason.imageRequired === PHOTO_REQUIRED) return images.length > 0;
    return true;
  };

  const checkIsVideoRequired = () => {
    if (reason.videoRequired === VIDEO_REQUIRED) return videos.length > 0;
    return true;
  };

  const isReasonDetailIdDone = () => {
    return (
      !reason.claimReasonDetailModel ||
      !reason.claimReasonDetailModel.rendered ||
      (reason.claimReasonDetailModel && reasonDetailId)
    );
  };

  useEffect(() => {
    const done =
      claimQuantity &&
      reasonId &&
      isReasonDetailIdDone() &&
      (description || !descriptionAvailable) &&
      checkIsPhotoRequired() &&
      checkIsVideoRequired();
    setIsDone(done);

    onChangeDoneItem({
      lineItemId,
      listingId,
      quantity: claimQuantity,
      reasonId,
      reasonDetailId,
      externalReasonId: getReasonExternalId(),
      totalQuantity,
      descriptionAvailable,
      description,
      done,
      images,
      variantName,
      title,
      videos,
    });
  }, [
    claimQuantity,
    reasonId,
    reasonDetailId,
    description,
    descriptionAvailable,
    images,
    imageUploadErrors,
    videos,
    videoUploadErrors,
  ]);

  const resetState = () => {
    setClaimQuantity();
    setReasonId();
    setReason();
    setDescription('');
  };

  const onChangeSelected = (e) => {
    let checked = e;
    setSelected(checked);
    onChangeSelectedItem(lineItemId, e);
    if (!checked) {
      resetState();
    }

    if (checked && totalQuantity === 1) {
      setClaimQuantity(1);
    }
  };

  const onClickHeader = () => {
    onChangeSelected(!selected);
  };

  const onChangeReason = (reasonId) => {
    const reason = reasons.find((item) => item.id === reasonId);
    setReasonId(reasonId);
    setReason(reason);
    setDescriptionAvailable(descriptionAvailableMap[reasonId]);
  };

  const onChangeReasonDetail = (reasonDetailId) => {
    setReasonDetailId(reasonDetailId);
    onFirstSelectedItemIdChange(id);
    onFirstSelectedReasonDetailIdChange(reasonDetailId);
  };

  const onChangeQuantity = (value) => {
    setClaimQuantity(value);
  };

  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const onImagesChange = (images) => {
    setImages(images.map((image) => image.response.data));
  };

  const onVideoChange = (videos) => {
    setVideos(videos.map((video) => video.response));
  };

  const onImageUploadError = (errors) => setImageUploadErrors(errors);

  const onVideoUploadError = (errors) => setVideoUploadErrors(errors);

  const isItemReasonDetailDisabled = () => {
    return (
      applyFirstSelectedReasonDetailToOthers &&
      firstSelectedItemId &&
      firstSelectedItemId !== id
    );
  };

  const getSubReasonInfo = () => {
    return reason.claimReasonDetailModel?.items?.find(
      (reasonDetail) => reasonDetail.id === reasonDetailId
    )?.info;
  };

  return (
    <div
      data-testid="claimable-item-order"
      className={classnames([
        'claimable-item-order',
        { selected, done: isDone },
        {
          international: appService.isInternational(),
        },
      ])}>
      <div className="claimable-item-header" onClick={onClickHeader}>
        <div>
          <Checkbox
            data-testid="claimable-item-checkbox"
            className="claimable-item-checkbox"
            onChange={onChangeSelected}
            checked={selected}
          />
        </div>
        <div className="claimable-item-image">
          <img src={imgSource} />
        </div>
        <div className="claimable-item-title-container">
          <div className="claimable-item-title" title={title}>
            {title}
          </div>
          <div className="claimable-item-variant-name">{variantName}</div>
        </div>
      </div>
      {selected && (
        <div className="claimable-item-body">
          <div className="claimable-item-row">
            <Select
              options={quantityList.map((quantity) => ({
                value: quantity,
                label: `${quantity} ${t(quantityText)}`,
              }))}
              placeholder={t(claimQuantityText)}
              onChange={onChangeQuantity}
              value={claimQuantity}
            />
          </div>
          {isReasonRendered !== false && (
            <div className="claimable-item-row">
              <Select
                options={reasons.map((reason) => ({
                  value: reason.id,
                  label: reason.name,
                }))}
                placeholder={t(claimOrderReason)}
                onChange={onChangeReason}
                value={reasonId}
                disabled={reasons && reasons.length === 1}
              />
            </div>
          )}
          {reason &&
            reason.claimReasonDetailModel &&
            reason.claimReasonDetailModel.rendered && (
              <div>
                <div className="claimable-item-row">
                  <Select
                    options={reason.claimReasonDetailModel.items.map(
                      (reasonDetail) => ({
                        value: reasonDetail.id,
                        label: reasonDetail.name,
                      })
                    )}
                    placeholder={reason.claimReasonDetailModel.placeHolder}
                    required
                    onChange={onChangeReasonDetail}
                    value={reasonDetailId}
                    disabled={
                      (reason.claimReasonDetailModel.items &&
                        reason.claimReasonDetailModel.items.length === 1) ||
                      isItemReasonDetailDisabled()
                    }
                  />
                  {getSubReasonInfo() && (
                    <div className="alert-container">
                      <img
                        src={AlertIcon}
                        className="alert-icon"
                        alt="alert-icon"
                      />
                      <span>{getSubReasonInfo()}</span>
                    </div>
                  )}
                </div>
                <div className="claimable-item-row">
                  {reason.claimReasonDetailModel.warningInfo &&
                    isItemReasonDetailDisabled() && (
                      <div className="alert-container">
                        <img src={AlertIcon} className="alert-icon" />
                        <span>{reason.claimReasonDetailModel.warningInfo}</span>
                      </div>
                    )}
                </div>
              </div>
            )}
          {reason &&
            reason.infoComponents &&
            reason.infoComponents.map((nextComponent) => (
              <NextComponent
                next={nextComponent}
                showHover={showHover}
                select={select}
              />
            ))}
          {reason && reason.imageRequired !== PHOTO_NOT_REQUIRED && (
            <div className="claimable-item-row">
              <FileInput
                id={id + 'FileInput'}
                placeholder={t(selectProductImageText, {
                  count: CLAIM_UPLOAD_MAX_FILE_COUNT,
                })}
                onImagesChange={onImagesChange}
                onError={onImageUploadError}
                isRequired={reason.imageRequired === PHOTO_REQUIRED}
                maxFileCount={CLAIM_UPLOAD_MAX_FILE_COUNT}
                maxFileSize={CLAIM_UPLOAD_MAX_FILE_SIZE}
                accept={CLAIM_UPLOAD_IMAGE_ACCEPTED_FORMATS}
                useCdn={true}
                fileId={listingId}
                uploadVersion={appService.getClaimUploadTargetVersion()}
              />
            </div>
          )}
          {!!imageUploadErrors.length && (
            <div className="claimable-item-row">
              <Alert messages={imageUploadErrors} />
            </div>
          )}
          {reason &&
            reason.imageRequired !== PHOTO_NOT_REQUIRED &&
            reason.disableImageInfo === undefined && (
              <div className="claimable-item-row">
                {reason.imageInfo ? (
                  <Alert message={reason.imageInfo} info />
                ) : (
                  t(optionalFileInfoText)
                )}
              </div>
            )}

          {reason &&
            reason?.videoRequired &&
            reason.videoRequired !== VIDEO_NOT_REQUIRED && (
              <div className="claimable-item-row">
                <FileInput
                  id={id + 'FileInput_Video'}
                  placeholder={t(selectProductVideoText, {
                    count: CLAIM_VIDEO_UPLOAD_MAX_FILE_COUNT,
                  })}
                  onVideoChange={onVideoChange}
                  onError={onVideoUploadError}
                  isRequired={reason.videoRequired === VIDEO_REQUIRED}
                  maxFileCount={CLAIM_VIDEO_UPLOAD_MAX_FILE_COUNT}
                  maxFileSize={CLAIM_VIDEO_UPLOAD_MAX_FILE_SIZE}
                  accept={CLAIM_UPLOAD_VIDEO_ACCEPTED_FORMATS}
                  useCdn={true}
                  fileId={listingId}
                  compressImage={false}
                  uploadVersion={appService.getClaimUploadTargetVersion()}
                />
                <div className="alert-container">
                  <img
                    src={AlertIcon}
                    className="alert-icon"
                    alt="alert-icon"
                  />
                  <span>{t(fileInputVideoInfo)}</span>
                </div>
              </div>
            )}

          {!!videoUploadErrors.length && (
            <div className="claimable-item-row">
              <Alert messages={videoUploadErrors} />
            </div>
          )}

          {reason &&
            ((reason.descriptionShouldBeRendered === undefined &&
              descriptionAvailable) ||
              reason.descriptionShouldBeRendered) && (
              <div className="claimable-item-row">
                <TextArea
                  required={descriptionAvailable ? true : false}
                  placeholder={t(claimOrderDescription)}
                  data-testid="claimable-item-description"
                  className="assistant-textarea"
                  value={description}
                  maxLength={maxDescriptionLength}
                  onChange={onChangeDescription}
                />
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default ClaimableItem;
