import React from 'react';
import { A11y, Navigation, Pagination } from 'swiper';
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './Swiper.scss';
import appService from '../../../services/app.service';
import classNames from 'classnames';

const Swiper = ({ showNavigation = true, onSlideChange, onInit, children }) => {
  return (
    <ReactSwiper
      modules={[Navigation, Pagination, A11y]}
      navigation={showNavigation}
      onSlideChange={onSlideChange}
      onInit={onInit}
      loop={showNavigation}
      pagination={{ clickable: true }}
      className={classNames('chat-swiper', {
        rtl: appService.isRtl(),
      })}>
      {children}
    </ReactSwiper>
  );
};

export { SwiperSlide };
export default Swiper;
