import React, { useCallback } from 'react';
import classNames from 'classnames';
import './Button.scss';
import appService from '../../../services/app.service';

const Button = ({
  title,
  children,
  iconLeft,
  iconRight,
  onClick,
  weight = 1,
  colorType = 'default',
  isSelectable = true,
  disabled = false,
  selected = false,
  outline = true,
  className = '',
  ...rest
}) => {
  const buttonClassNames = classNames('button-group-button', {
    [className]: true,
    [colorType]: !!colorType,
    [`weight-${weight}`]: true,
    active: isSelectable,
    passive: !isSelectable,
    selected: selected,
    disabled: disabled,
    outline: outline,
    dynamic: appService.isDynamicStylingChannel(),
  });

  const renderIcon = useCallback((icon, position) => {
    if (typeof icon === 'string') {
      return (
        <span className="button-icon-container">
          <img src={icon} className={`icon-${position}`} alt="button-icon" />
        </span>
      );
    }
    return icon;
  }, []);

  return (
    <button
      className={buttonClassNames}
      onClick={onClick}
      disabled={disabled}
      {...rest}>
      <span className="text">
        {iconLeft && renderIcon(iconLeft, 'left')}
        {title}
        {children}
        {iconRight && (
          <span className="icon">{renderIcon(iconRight, 'right')}</span>
        )}
      </span>
    </button>
  );
};

export default React.memo(Button);
