import './RealtimeTranslationMessage.scss';

import { ReactComponent as CircleInfo } from '../../../../assets/icons/circle-info.svg';
import { ReactComponent as ChevronDown } from '../../../../assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../../../assets/icons/chevron-up.svg';

import React, { useState } from 'react';

const RealtimeTranslationMessage = ({ customFields: { header, message } }) => {
  const [hideMessage, setHideMessage] = useState(false);
  return (
    <div className="realtime-translation-container">
      <div className="realtime-translation-header-wrapper">
        <div className="realtime-translation-header-info">
          {<CircleInfo />}
          <span className="realtime-translation-header">{header}</span>
        </div>
        <div
          className="chevron-icon"
          onClick={() => setHideMessage((hide) => !hide)}>
          {hideMessage ? <ChevronDown /> : <ChevronUp />}
        </div>
      </div>
      {!hideMessage && (
        <div className="realtime-translation-message">{message}</div>
      )}
    </div>
  );
};

export default RealtimeTranslationMessage;
