import React, { useContext, useEffect } from 'react';
import { shipmentNo } from '../../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import usePersistentState from '../../../common/usePersistentState';
import BaseListingItem from '../BaseListingItem';
import classNames from 'classnames';
import TicketStatusIcon from '../../icons/TicketStatusIcon';
import chatBotContext from '../../../../context/chatbot/chatbotContext';

const TicketListingItem = ({
  id,
  ticket,
  ticketItemActive,
  selectedTicketId,
  setSelectedTicketId,
  buttonLabel,
  isRenderedFromOutside = false,
}) => {
  const { t } = useTranslation();

  const { selectOrder, requestToChatBot } = useContext(chatBotContext);

  const [listingItem, setListingItem] = usePersistentState(
    id,
    'listingItem',
    undefined
  );

  const {
    ticketId,
    ticketName,
    flowId,
    sla,
    imageUrls = [],
    humanReadableCreateDate,
    status,
    statusKey,
    shipmentNumber,
    orderNumber,
    listingNumberLabel,
    event,
  } = ticket;

  useEffect(() => {
    const item = {
      label: ticketName,
      imageUrls: imageUrls,
      listingNumber: shipmentNumber,
      orderNumber: orderNumber,
      listingNumberLabel: listingNumberLabel
        ? listingNumberLabel
        : t(shipmentNo),
      listingDate: humanReadableCreateDate,
      status: {
        type: 'success',
        text: status,
      },
      buttonLabel: buttonLabel,
    };
    setListingItem(item);
  }, [ticket]);

  const onSelect = () => {
    if (ticketItemActive) {
      let request = {
        event: event,
        data: {
          ticketId: ticketId,
          sla: sla,
          flowId: flowId,
          statusKey: statusKey,
          shipmentNumber: shipmentNumber,
          orderNumber: orderNumber,
        },
      };
      if (orderNumber) {
        selectOrder(request);
      } else {
        requestToChatBot(request);
      }
      setSelectedTicketId(ticketId);
    }
  };

  return (
    <>
      {listingItem && (
        <BaseListingItem
          id={ticketId}
          isRenderedFromOutside={isRenderedFromOutside}
          selectedId={selectedTicketId}
          active={ticketItemActive}
          draft={false}
          flex={true}
          onSelect={onSelect}
          item={{
            ...listingItem,
            status: {
              ...listingItem.status,
              icon: (
                <TicketStatusIcon
                  name={statusKey}
                  className={classNames({
                    'status-icon': true,
                    'status-icon--success': true,
                  })}
                />
              ),
            },
          }}
        />
      )}
    </>
  );
};

export default TicketListingItem;
