import React, { useState } from 'react';

import MultipleItemFormContext from './MultipleItemFormContext';

const MultipleItemFormProvider = ({ children }) => {
  const [lazyOptionsObject, setLazyOptionsObject] = useState({});
  const [sendValueItems, setSendValueItems] = useState([]);

  return (
    <MultipleItemFormContext.Provider
      value={{
        lazyOptionsObject,
        setLazyOptionsObject,
        sendValueItems,
        setSendValueItems,
      }}>
      {children}
    </MultipleItemFormContext.Provider>
  );
};

export default MultipleItemFormProvider;
