import Utils from '../utils';

export default (type, outline) => {
  const types = {
    Primary: {
      outline: null,
      colorType: 'primary',
    },
    Yes: {
      outline: false,
      colorType: 'primary',
    },
    YesBlack: {
      outline: false,
      colorType: 'primary',
    },
    No: {
      outline: true,
      colorType: 'cancel',
    },
    NoBlack: {
      outline: true,
      colorType: 'cancel',
    },
    Cancel: {
      outline: true,
      colorType: 'cancel',
    },
    Info: {
      outline: true,
      colorType: 'cancel',
    },
  };

  const buttonOutline = outline !== false;

  let buttonType = types[type] || {
    outline: buttonOutline,
    colorType: Utils.getColorType(type),
  };

  if (buttonType.outline === null) {
    buttonType.outline = buttonOutline;
  }

  return buttonType;
};
