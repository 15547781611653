import {
  ADD_MESSAGE,
  CLEAR_LIVE_CHAT_CONTEXT,
  COUNT_DOWN_TIMER_START_VALUE,
  IS_AGENT_JOINED,
  IS_AGENT_OR_SYSTEM_JOINED_AT_LEAST_ONCE,
  IS_CONNECTING,
  IS_LITE_MODE,
  IS_MODAL_OPEN,
  IS_SYSTEM_JOINED,
  IS_TIMEOUT,
  IS_TYPING,
  LAST_MESSAGE_POSITION,
  MODIFY_MESSAGE,
  SET_ALERT_MESSAGE,
  SET_IS_AGENT_DISCONNECTED,
  SET_IS_END_CHAT,
  SET_IS_MODAL_ACTIVE,
  SET_IS_OUT_OF_WORK_HOURS,
  SET_IS_RECONNECTING,
  SET_IS_SURVEY_OPENED,
  SET_IS_USER_ALLOWED_TO_TYPE,
  SET_LIVE_CHAT_MODAL,
  SET_MEDIA_UPLOAD_STATUS,
  SET_SHOW_MEDIA_PREVIEW,
} from './liveChatTypes';
import { ModalModel } from '../../constants/models';
import appService from '../../services/app.service';

const actualReducer = (state, action) => {
  switch (action.type) {
    case SET_IS_OUT_OF_WORK_HOURS:
      return {
        ...state,
        isOutOfWorkHours: action.payload,
      };
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };

    case SET_IS_AGENT_DISCONNECTED:
      return {
        ...state,
        isAgentDisconnected: action.payload,
      };

    case IS_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: action.payload,
      };

    case SET_IS_RECONNECTING:
      return {
        ...state,
        isReconnecting: action.payload,
      };

    case MODIFY_MESSAGE:
      return {
        ...state,
        messages: state.messages.map((existingMessage, index) =>
          index === action.payload.messageIndex
            ? action.payload.newMessage
            : existingMessage
        ),
      };

    case IS_AGENT_JOINED:
      return {
        ...state,
        isAgentJoined: action.payload,
      };

    case IS_SYSTEM_JOINED:
      return {
        ...state,
        isSystemJoined: action.payload,
      };

    case IS_AGENT_OR_SYSTEM_JOINED_AT_LEAST_ONCE:
      return {
        ...state,
        isAgentOrSystemJoinedAtLeastOnce: action.payload,
      };

    case IS_LITE_MODE:
      return {
        ...state,
        isLiteMode: action.payload,
      };

    case SET_SHOW_MEDIA_PREVIEW:
      return {
        ...state,
        showMediaPreview: action.payload,
      };

    case SET_MEDIA_UPLOAD_STATUS:
      return {
        ...state,
        allowedToUploadMedia: action.payload,
      };

    case SET_LIVE_CHAT_MODAL:
      return {
        ...state,
        liveChatModal: action.payload,
      };

    case IS_CONNECTING:
      return {
        ...state,
        isConnecting: action.payload,
      };

    case IS_TYPING:
      return {
        ...state,
        isTyping: action.payload,
      };

    case IS_TIMEOUT:
      return {
        ...state,
        isTimeOut: action.payload,
      };

    case COUNT_DOWN_TIMER_START_VALUE:
      return {
        ...state,
        countDownTimerStartValue: action.payload,
      };

    case SET_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: action.payload,
      };

    case SET_IS_USER_ALLOWED_TO_TYPE:
      return {
        ...state,
        isUserAllowedToType: action.payload,
      };

    case LAST_MESSAGE_POSITION:
      return {
        ...state,
        lastMessagePosition: action.payload,
      };

    case SET_IS_SURVEY_OPENED:
      return {
        ...state,
        isSurveyOpened: action.payload,
      };

    case CLEAR_LIVE_CHAT_CONTEXT:
      return {
        ...state,
        messages: [],
        isSystemJoined: false,
        isAgentJoined: false,
        isConnecting: true,
        isTyping: false,
        isTimeOut: false,
        alertMessage: null,
        isUserAllowedToType: false,
        allowedToUploadMedia: false,
        showMediaPreview: { show: false, media: null },
        liveChatError: new ModalModel(null, []),
        isLiteMode: false,
        isReconnecting: false,
        isAgentDisconnected: false,
        lastMessagePosition: null,
      };

    case SET_IS_MODAL_ACTIVE:
      return {
        ...state,
        isModalActive: action.payload,
      };

    case SET_IS_END_CHAT:
      return {
        ...state,
        isEndChat: action.payload,
      };

    default:
      return state;
  }
};

const liveChatPersistentReducer = (state, action) => {
  const nextState = actualReducer(state, action);
  if (appService.getIsStickyAssistant()) {
    setTimeout(() => {
      window.sessionStorage.setItem('liveChatState', JSON.stringify(nextState));
    }, 0);
  }

  return nextState;
};

export default liveChatPersistentReducer;
