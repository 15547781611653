import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  makeUploadedContentBigger,
  resendMediaMessage,
} from '../../../i18n/keys';
import ExclamationIcon from '../../../assets/icons/circle-error.svg';
import {
  MESSAGE_TYPE_DOCUMENT,
  MESSAGE_TYPE_IMAGE,
  MESSAGE_TYPE_PDF,
  MESSAGE_TYPE_VIDEO,
} from '../../../constants/liveChatConstants';
import ReactTooltip from 'react-tooltip';
import AttachmentIcon from '../../../assets/icons/attach-preview.svg';
import PlayIcon from '../../../assets/icons/play-preview.svg';
import './EchoMedia.scss';

import appService from '../../../services/app.service';
import utils from '../../../utils';

const EchoMedia = ({ message, onPreview, onReSend }) => {
  const { t } = useTranslation();
  const { direction, message: url, systemPayload } = message;

  const [date, setDate] = useState(null);

  useEffect(() => {
    if (typeof message.createdAt === 'number') {
      const date = new Date(message.createdAt);
      setDate(utils.getTimeFromDate(date));
    } else {
      setDate(utils.getCurrentTime());
    }
  }, []);

  const previewMedia = () => {
    if (
      !systemPayload.isLoading &&
      !systemPayload.isFailed &&
      systemPayload.fileType !== MESSAGE_TYPE_DOCUMENT &&
      systemPayload.fileType !== MESSAGE_TYPE_VIDEO
    ) {
      if (onPreview) {
        onPreview({ url, type: systemPayload.fileType });
      }
    }
  };

  const reSendMedia = () => {
    if (onReSend) {
      onReSend(message);
    }
  };

  const getFailReasonText = () => {
    return t(resendMediaMessage);
  };

  return (
    <div
      className={classNames({
        'echo-media': true,
        'echo-media--client': direction === 'outgoing',
        'live-chat-client-message': direction === 'outgoing',
        'server-message': direction === 'incoming',
        'echo-media--server': direction === 'incoming',
      })}
      data-testid="media">
      <div
        className={classNames({
          'echo-content-wrapper': true,
          'echo-content-wrapper--failed':
            !systemPayload.isLoading && systemPayload.isFailed,
        })}>
        <div
          className={classNames({
            'echo-content': true,
            'echo-content--failed':
              !systemPayload.isLoading && systemPayload.isFailed,
          })}>
          {url ? (
            <div className="echo-img-area" data-testid="media-content-img-area">
              <img
                className={classNames({
                  image: true,
                  'image-preview':
                    systemPayload.fileType === MESSAGE_TYPE_PDF ||
                    systemPayload.fileType === MESSAGE_TYPE_DOCUMENT ||
                    systemPayload.fileType === MESSAGE_TYPE_VIDEO,
                  'image-sending':
                    systemPayload.isLoading && !systemPayload.isFailed,
                  'image-failed':
                    !systemPayload.isLoading && systemPayload.isFailed,
                  'with-zoom': systemPayload.fileType === MESSAGE_TYPE_IMAGE,
                })}
                src={
                  systemPayload.fileType === MESSAGE_TYPE_IMAGE
                    ? url
                    : systemPayload.fileType === MESSAGE_TYPE_VIDEO
                    ? PlayIcon
                    : AttachmentIcon
                }
                alt="uploadedImage"
                onClick={previewMedia}
              />
              {systemPayload.isLoading && !systemPayload.isFailed ? (
                <div className="sending">
                  <div
                    className={classNames([
                      'loader',
                      {
                        dynamic: appService.isDynamicStylingChannel(),
                      },
                    ])}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          {systemPayload.fileType === MESSAGE_TYPE_IMAGE && (
            <div className="zoom" onClick={previewMedia}>
              {t(makeUploadedContentBigger)}
            </div>
          )}
        </div>
        <div className="echo-content-wrapper__time">{date}</div>
      </div>
      <div
        className={classNames({
          'echo-progress': true,
          'echo-progress--upload-failed':
            !systemPayload.isLoading && systemPayload.isFailed,
        })}>
        {systemPayload.isLoading || systemPayload.isFailed ? (
          <div className="echo-upload-failed">
            <img
              src={ExclamationIcon}
              className="img"
              alt="upload-fail"
              onClick={reSendMedia}
              data-tip={getFailReasonText()}
            />
            <ReactTooltip effect="solid" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(EchoMedia);
