import React, { useMemo } from 'react';

import en from 'world_countries_lists/data/countries/en/world.json';

import 'antd/dist/antd.css';
import 'antd-country-phone-input/dist/index.css';
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';

import './PhoneInput.scss';

const MAX_PHONE_CHAR_LENGTH = 20;

export default function PhoneInput({ item, value, onChange, ...rest }) {
  const initialPhoneValue = useMemo(() => {
    const initValue = {
      short: item.defaultCode,
    };
    if (!value) {
      return initValue;
    }
    return value;
  }, [value]);

  const onCountryPhoneChange = (value) => {
    if (value.phone === '') {
      onChange(null);
    } else {
      if (
        isNumeric(value.phone) &&
        value.phone.length <= MAX_PHONE_CHAR_LENGTH
      ) {
        onChange(value);
      } else {
        onChange(null);
      }
    }
  };

  function isNumeric(str) {
    if (typeof str != 'string') return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  return (
    <ConfigProvider locale={en}>
      <CountryPhoneInput
        defaultValue={initialPhoneValue}
        inline
        className="country-phone-input"
        onChange={onCountryPhoneChange}
        {...rest}
      />
    </ConfigProvider>
  );
}
