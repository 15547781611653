import { useState } from 'react';
import MobileSelect from './MobileSelect';
import { Select as TxSelect, SelectItem } from '@tx-components/select';
import InputLabel from '../common/InputLabel/InputLabel';
import appService from '../../services/app.service';
import {
  PLATFORM_ANDROID,
  PLATFORM_IOS,
  PLATFORM_MWEB,
  PLATFORM_WEB,
} from '../../constants';
import './Select.scss';
import classNames from 'classnames';
import AppService from '../../services/app.service';

const Select = ({
  options,
  value,
  label,
  required = false,
  keyValue = 'value',
  textValue = 'label',
  placeholder,
  onChange,
  onClose,
  small = false,
  searchable = false,
  disabled = false,
  className,
  ...rest
}) => {
  const [showMobileSelect, setShowMobileSelect] = useState(false);
  const type = searchable
    ? PLATFORM_MWEB
    : appService.getPlatform() || PLATFORM_ANDROID;

  const handleOnClose = (e) => {
    e.stopPropagation();
    setShowMobileSelect(false);
    onClose();
  };

  const handleMobileSelectClick = (e) => {
    if (type !== PLATFORM_WEB && type !== PLATFORM_MWEB) {
      setShowMobileSelect(true);
    } else {
    }
  };

  return (
    <div
      className={classNames('select-wrapper', {
        small: small,
        disabled,
        [className]: className,
      })}>
      <InputLabel text={label} required={required} />

      <TxSelect
        placeholder={placeholder}
        className={classNames({ required })}
        value={value === '-1' ? undefined : value}
        onChange={onChange}
        searchable={searchable}
        selectScrollerProps={{
          scrollbarMode: 'adjacent',
          scrollColor: AppService.getDynamicStyleValue('color') || '#f27a1a',
        }}
        usePortal
        portalClassName={classNames('tx-components-select-item-container', {
          small,
        })}
        {...rest}
        {...(type !== PLATFORM_WEB &&
          type !== PLATFORM_MWEB && {
            onSelectClick: handleMobileSelectClick,
          })}>
        {options.map((item) => (
          <SelectItem
            key={item[keyValue]}
            value={item[keyValue]}
            disabled={item.disabled || false}
            onChange={onChange}>
            {item[textValue]}
          </SelectItem>
        ))}
      </TxSelect>

      {[PLATFORM_ANDROID, PLATFORM_IOS].includes(type) && (
        <MobileSelect
          isOpen={showMobileSelect}
          items={options}
          value={value === '-1' ? undefined : value}
          label={label || placeholder}
          keyValue={keyValue}
          textValue={textValue}
          type={PLATFORM_ANDROID}
          onChange={onChange}
          onClose={handleOnClose}
        />
      )}
    </div>
  );
};

Select.defaultProps = {
  value: undefined,
  items: [],
  onChange: () => {},
  onClose: () => {},
  label: '',
};

export default Select;
