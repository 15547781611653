import React from 'react';
import './Typing.scss';

const Typing = () => {
  return (
    <div className="typing" data-testid="typing">
      <span className="typing__dot" />
      <span className="typing__dot" />
      <span className="typing__dot" />
    </div>
  );
};

export default Typing;
