import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import usePersistentState from '../../../common/usePersistentState';
import ListArrows from '../../../common/ListArrows';
import './TicketListing.scss';
import AppService from '../../../../services/app.service';
import TicketListingItem from './TicketListingItem';
import StaticText from '../../staticText/StaticText';

function TicketListing({ id, componentData, isRenderedFromOutside = false }) {
  const { items, label } = componentData;

  const inputEl = useRef(null);
  const [prevDisable, setPrevDisable] = usePersistentState(
    id,
    'prevDisable',
    true
  );
  const [nextDisable, setNextDisable] = usePersistentState(
    id,
    'nextDisable',
    true
  );
  const [scrollReady, setScrollReady] = usePersistentState(
    id,
    'scrollReady',
    false
  );

  const [current, setCurrent] = useState(null);
  const [last, setLast] = usePersistentState(id, 'last', false);
  const [selectedTicketId, setSelectedTicketId] = usePersistentState(
    id,
    'selectedTicketId',
    null
  );

  useEffect(() => {
    setCurrent(inputEl.current);
  }, []);

  useEffect(() => {
    if (current && !last) {
      current.addEventListener('scroll', handleScroll);
      setScrollReady(true);
      return () => current.removeEventListener('scroll', handleScroll);
    }
  });

  useEffect(() => {
    if (scrollReady && current && current.clientWidth < current.scrollWidth) {
      setNextDisable(false);
    }
  }, [scrollReady]);

  const handleScroll = () => {
    let newScrollLeft = AppService.isRtl()
      ? current.scrollLeft - current.offsetWidth / 2
      : current.scrollLeft + current.offsetWidth / 2;
    checkButtons(newScrollLeft);
  };

  const handleClickLeft = () => {
    let newScrollLeft = current.scrollLeft - current.offsetWidth / 2;
    current.scrollLeft = newScrollLeft;
    checkButtons(newScrollLeft);
  };

  const handleClickRight = () => {
    let newScrollLeft = current.scrollLeft + current.offsetWidth / 2;
    current.scrollLeft = newScrollLeft;
    checkButtons(newScrollLeft);
  };

  const checkButtons = (newScrollLeft) => {
    let nextCheck =
      (AppService.isRtl() ? -1 : 1) * newScrollLeft + current.offsetWidth >=
      current.scrollWidth;

    if (nextCheck) {
      setLast(true);
      setNextDisable(true);
    }

    let prevCheck = newScrollLeft <= 0;
    setPrevDisable(prevCheck);
    if (prevCheck) {
      setNextDisable(false);
    }
  };
  return (
    <div className="tickets order-container">
      <div className="ticket__items">
        <div className="label">
          <StaticText componentData={{ message: label }} />
        </div>
        <div className="slider-wrapper">
          <div className="items" ref={inputEl}>
            {items &&
              items.map((ticket, i) => {
                return (
                  <TicketListingItem
                    id={ticket.ticketId}
                    isRenderedFromOutside={isRenderedFromOutside}
                    ticket={ticket}
                    key={ticket.ticketId}
                    ticketItemActive={true}
                    selectedTicketId={selectedTicketId}
                    setSelectedTicketId={setSelectedTicketId}
                  />
                );
              })}
          </div>

          <ListArrows
            prevDisable={prevDisable}
            nextDisable={nextDisable}
            handleClickLeft={handleClickLeft}
            handleClickRight={handleClickRight}
          />
        </div>
      </div>
    </div>
  );
}

TicketListing.propTypes = {
  componentData: PropTypes.array.isRequired,
};

export default TicketListing;
