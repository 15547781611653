import ClientMessage from '../chatAssistant/clientMessage/ClientMessage';
import OperationButton from '../chatAssistant/operationButton/OperationButton';
import Video from '../chatAssistant/video/Video';
import DeepLink from '../chatAssistant/deepLink/DeepLink';
import StaticText from '../chatAssistant/staticText/StaticText';
import { Route, Switch } from 'react-router-dom';
import ComponentFactory from '../chatAssistant/ComponentFactory';

import { useRef } from 'react';
import { useIframeComponentData } from '../../hooks';
import ChatAssistantPreview from '../pages/chatAssistant/ChatAssistantPreview';

const COMPONENTS = {
  // INITIALS
  'client-message': ClientMessage,
  'operation-button': OperationButton,
  video: Video,
  'deep-link': DeepLink,
  'static-text': StaticText,
};

const EXCEPTION_PATHS = [];

function changeStringCase(str) {
  return str?.replace(/([a-z])([A-Z])/g, '$1-$2')?.toLowerCase();
}

function removeExceptionalKeys(exceptions) {
  exceptions.map((key) => delete COMPONENTS[key]);
}

const ComponentPreviewRoutes = ({ history }) => {
  try {
    const additionalComponents = ComponentFactory.getComponentList();
    Object.keys(additionalComponents).forEach((key) => {
      const kebabKey = changeStringCase(key);
      COMPONENTS[kebabKey] = additionalComponents[key];
    });

    if (EXCEPTION_PATHS || EXCEPTION_PATHS.length > 0) {
      removeExceptionalKeys(EXCEPTION_PATHS);
    }
  } catch (e) {
    console.error('Error while fetching components:', e);
  }
  const componentRef = useRef(null);
  const { outsideData, setShouldResizeIframe } =
    useIframeComponentData(componentRef);

  return (
    <Switch>
      {Object.entries(COMPONENTS).map(([path, Component], i) => {
        return (
          <Route
            key={i}
            path={`/preview/${path}`}
            render={() =>
              outsideData ? (
                <div className="outside-render" ref={componentRef}>
                  <Component
                    isRenderedFromOutside
                    componentData={outsideData}
                    setShouldResizeIframe={setShouldResizeIframe}
                  />
                </div>
              ) : (
                <></>
              )
            }
          />
        );
      })}
      <Route
        path={`/preview/snapshot`}
        render={() =>
          outsideData ? (
            <div
              className="outside-render outside-render-snapshot"
              ref={componentRef}>
              <ChatAssistantPreview
                history={history}
                outsideData={outsideData}
              />
            </div>
          ) : (
            <></>
          )
        }
      />
    </Switch>
  );
};

export default ComponentPreviewRoutes;
