import './GoDeliveredProductsTicket.scss';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
  claimOrderSelectProduct,
  claimOrderSelectProducts,
  goDeliveredTicketProductsFillToContinue,
} from '../../../../i18n/keys';
import Bubble from '../../../layout/bubble/Bubble';
import GoDeliveredProduct from './GoDeliveredProduct';
import usePersistentState from '../../../common/usePersistentState';
import ButtonGroup from '../../buttonGroup/ButtonGroup';
import WarningInfo from '../../../common/WarningInfo';

const GoDeliveredProductsTicket = ({
  id,
  componentData,
  isSelectable,
  select,
}) => {
  const { t } = useTranslation();
  const { products, button, bubbleTitle, reasons } = componentData;
  const [selecteds, setSelecteds] = usePersistentState(id, 'selecteds', []);
  const [dones, setDones] = usePersistentState(id, 'dones', []);
  const [isDone, setIsDone] = usePersistentState(id, 'isDone', false);

  const onChangeSelectedItem = (productId, selected) => {
    if (selected && !selecteds.includes(productId)) {
      setSelecteds([...selecteds, productId]);
    } else if (!selected) {
      setSelecteds(selecteds.filter((s) => s !== productId));
    }
  };
  const onChangeDoneItem = (item) => {
    if (item.done) {
      setDones([
        ...dones.filter((doneItem) => doneItem.productId !== item.productId),
        item,
      ]);
    } else {
      setDones(
        dones.filter((doneItem) => doneItem.productId !== item.productId)
      );
    }
  };

  useEffect(() => {
    setIsDone(selecteds.length !== 0 && selecteds.length === dones.length);
  }, [selecteds, dones]);

  const onClickCreateGoDeliveredProductTicket = () => {
    if (isSelectable && isDone) {
      const requestPayload = {
        event: button.event,
        data: {
          selectedProducts: dones.map((doneItem) => ({
            ...doneItem,
          })),
        },
      };
      select(requestPayload);
    }
  };

  return (
    <div className="go-delivered-products-wrapper">
      <Bubble title={bubbleTitle} isBottomRounded={false}>
        <div
          className={classnames([
            'go-delivered-products',
            { disabled: !isSelectable },
          ])}>
          {products.map((product, i) => (
            <GoDeliveredProduct
              id={id + 'GoDeliveredProduct' + i}
              productId={product.productId}
              productImageUrl={product.productImageUrl}
              productName={product.productName}
              receiptImagePlaceHolderText={
                product.productReceiptImagePlaceholderText
              }
              productImagePlaceholderText={product.productImagePlaceholderText}
              imageWarningMessage={product.imageWarningMessage}
              descriptionPlaceholderText={product.descriptionPlaceholderText}
              productQuantity={product.quantity}
              lineItemIds={product.lineItemIds}
              onChangeSelectedItem={onChangeSelectedItem}
              onChangeDoneItem={onChangeDoneItem}
              reasons={reasons}
            />
          ))}
        </div>
        <ButtonGroup>
          <ButtonGroup.Button
            isSelectable={isDone}
            colorType="primary"
            onClick={onClickCreateGoDeliveredProductTicket}
            outline={false}
            disabled={!isDone}>
            {button.text ||
              (selecteds.length > 0
                ? t(claimOrderSelectProduct).replace('{0}', selecteds.length)
                : t(claimOrderSelectProducts))}
          </ButtonGroup.Button>
        </ButtonGroup>
        {selecteds.length > 0 && !isDone && (
          <WarningInfo text={t(goDeliveredTicketProductsFillToContinue)} />
        )}
      </Bubble>
    </div>
  );
};

export default GoDeliveredProductsTicket;
