import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import './SellerBarcodeInput.scss';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import barcodeInfo from '../../../assets/icons/barcode-info.svg';
import usePersistentState from '../../common/usePersistentState';
import { useTranslation } from 'react-i18next';
import { barcodeText, placeHolderBarcodeText } from '../../../i18n/keys';
import Bubble from '../../layout/bubble/Bubble';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import TextInput from '../../common/TextInput';

const SellerBarcodeInput = ({ id, componentData, isSelectable }) => {
  const { t } = useTranslation();

  let sellerBarcodeInputData = componentData;
  const buttons = sellerBarcodeInputData.buttons;
  const label = sellerBarcodeInputData.label;
  const infoText = sellerBarcodeInputData.text;
  const [barcodeNumber, setBarcodeNumber] = usePersistentState(
    id,
    'barcodeNumber',
    ''
  );
  const [isActive, setIsActive] = usePersistentState(id, 'isActive', false);
  const chatBotContext = useContext(ChatBotContext);

  const { requestToChatBot } = chatBotContext;

  const handleButtons = (e) => {
    sendEventData(e);
  };

  const sendEventData = (e) => {
    const executeEventRequest = {
      event: e,
      data: {
        barcodeNumber: barcodeNumber,
      },
    };

    requestToChatBot(executeEventRequest);
  };

  function handleBarcodeNumber(value) {
    const parsedValue = value.replace(/\s/g, '');
    setIsActive(parsedValue !== '');
    setBarcodeNumber(parsedValue);
  }

  const renderInfoText = () => {
    return (
      <div className="info-wrapper">
        <span className="info-img-container">
          <img src={barcodeInfo} className="info-img" />
        </span>
        <div className="info-text">{infoText}</div>
      </div>
    );
  };

  return (
    <Bubble title={label} isBottomRounded={false}>
      <div className="seller-barcode-input" data-testid="deeplink">
        <div className="seller-barcode-main">
          <div className="seller-barcode-info">
            <TextInput
              label={t(barcodeText)}
              onChange={(e) => handleBarcodeNumber(e.target.value)}
              disabled={!isSelectable}
              value={barcodeNumber}
              placeholder={t(placeHolderBarcodeText)}
              type="text"
              maxLength="40"
            />
            {infoText && renderInfoText()}
            <ButtonGroup>
              {buttons.map((button, index) => (
                <ButtonGroup.Button
                  key={index}
                  isSelectable={
                    button.buttonType !== 'Yes' ||
                    (button.buttonType === 'Yes' && isActive && isSelectable)
                  }
                  disabled={
                    !isSelectable || (!isActive && button.buttonType === 'Yes')
                  }
                  outline={button.outline}
                  colorType={button.buttonType === 'Yes' ? 'primary' : 'cancel'}
                  onClick={() => handleButtons(button.event)}>
                  {button.text}
                </ButtonGroup.Button>
              ))}
            </ButtonGroup>
          </div>
        </div>
      </div>
    </Bubble>
  );
};

export default withRouter(SellerBarcodeInput);
