import './Header.scss';

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import classnames from 'classnames';
import classNames from 'classnames';

import { englishLangTextShort } from '../../../i18n/keys';

import appService from '../../../services/app.service';
import chatbotService from '../../../services/chatbot.service';

import ChatBotContext from '../../../context/chatbot/chatbotContext';
import CommonContext from '../../../context/common/commonContext';
import { PLATFORM_WEB } from '../../../constants';
import Utils from '../../../utils';

import BellIcon from '../../../assets/icons/bell.svg';
import ENFlag from '../../../assets/icons/en-flag.svg';
import CloseButtonAssistant from '../../../assets/icons/close-button-assistant.svg';
import LeftArrow from '../../../assets/icons/chevron-left.svg';
import {
  ENGLISH_SUPPORT_VERSION_2,
  GET_ENGLISH_SUPPORT,
  GET_INITIAL,
  GET_SHIPMENT_DETAIL,
  LANGUAGE_CODE_EN,
} from '../../../constants/chatAssistantConstant';
import i18n from 'i18next';
import AzCountryChange from '../AzComponents/AzChangeDelivery/AzCountryChange';
import liveChatService from '../../../services/livechat.service';

const Header = ({
  isSurveyOpened = false,
  showFront = false,
  showShipmentCountryInfo = false,
  availableShipmentLanguages,
}) => {
  const { t } = useTranslation();
  const chatBotContext = useContext(ChatBotContext);
  const {
    setIsChatBotSurvey,
    error,
    setIsOpenNotification,
    isOpenNotification,
    setNotifications,
    isStartRequest,
    notifications,
    setProactiveInfo,
    isShowLanguage,
    setShowLanguage,
    requestToChatBot,
    clearChatHistory,
  } = chatBotContext;

  const commonContext = useContext(CommonContext);
  const { nextStateDelayTimeoutId } = commonContext;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setShowLanguage(appService.englishSupport().isEnabled);
    setLoading(true);
    if (isStartRequest) {
      chatbotService.getChatBotNotifications().then((response) => {
        setNotifications(response);
        setLoading(false);
      });

      if (appService.isProactivePageEnabled()) {
        chatbotService
          .getProactiveInfo()
          .then(setProactiveInfo)
          .catch((error) => console.error(error))
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [isStartRequest]);

  const onExitClicked = async () => {
    window.clearTimeout(nextStateDelayTimeoutId);
    if (isSurveyOpened || error) {
      await appService.exitAssistant();
    } else {
      const checkSurvey = await appService.isChatBotSurveyWillOpen();
      if (checkSurvey.openSurvey) {
        setIsChatBotSurvey(true);
      } else {
        await appService.exitAssistant();
      }
    }
  };

  useEffect(() => {
    if (isOpenNotification) {
      setShowLanguage(false);
    } else {
      setShowLanguage(appService.englishSupport().isEnabled);
    }
  }, [isOpenNotification]);

  const onNotificationClicked = async () => {
    if (notifications && !_.isEmpty(notifications)) {
      if (notifications?.tabs && notifications?.tabs.length > 0) {
        setIsOpenNotification(!isOpenNotification);
      }
    }
  };

  const onEngSupportClick = async () => {
    const request = {
      event: GET_ENGLISH_SUPPORT,
    };

    requestToChatBot(request);
  };

  const onChangeLanguage = async (languageCode) => {
    const request = {
      event: 'START',
    };
    const eventName = appService.getEventName();
    if (GET_SHIPMENT_DETAIL === eventName) {
      appService.setEventName(GET_SHIPMENT_DETAIL);
    } else {
      appService.setEventName(GET_INITIAL);
    }

    const acceptedLanguage = Utils.getAcceptepLanguage(languageCode);
    appService.setLanguage(acceptedLanguage);
    await i18n.changeLanguage(acceptedLanguage);

    if (LANGUAGE_CODE_EN === acceptedLanguage) {
      appService.setPrevStateMachineId(appService.getStateMachineId());
    }

    clearChatHistory();
    window.clearTimeout(nextStateDelayTimeoutId);
    requestToChatBot(request);
  };

  const onBackNotification = async () => {
    setIsOpenNotification(false);
  };

  const iconDecider = () => {
    if (isOpenNotification) {
      return (
        <img
          onClick={() => onBackNotification()}
          src={LeftArrow}
          className="close-icon"
          alt="back"
        />
      );
    }
    return (
      <img
        onClick={() => onExitClicked()}
        src={CloseButtonAssistant}
        className="close-icon"
        alt="close"
      />
    );
  };

  return (
    <div
      className={classNames({
        'chat-bot-header': true,
        'chat-bot-header-front': showFront,
      })}
      data-testid="chat-bot-header">
      <div className="chat-bot-header-body">
        <div className="chat-bot-header-columns">
          {appService.getPlatform() !== PLATFORM_WEB && (
            <div className="header-column">{iconDecider()}</div>
          )}

          <div className="header-column">
            <div className={classnames(['chat-icon-wrapper'])}>
              <img
                className="chat-icon"
                alt="chat-icon"
                src={appService.getDynamicStyleValue('icon-link')}
              />
            </div>
            <div className="title">
              {t(appService.getDynamicStyleValue('app-title'))}
            </div>
          </div>
        </div>
        <div className="chat-bot-header-columns">
          {!loading && !isSurveyOpened && (
            <>
              {Utils.isLanguageTr() && (
                <div
                  className={classnames([
                    'language-wrapper',
                    {
                      invisible: !isShowLanguage,
                    },
                  ])}
                  onClick={() =>
                    appService.englishSupport().version ===
                    ENGLISH_SUPPORT_VERSION_2
                      ? onChangeLanguage(LANGUAGE_CODE_EN)
                      : onEngSupportClick()
                  }>
                  <img src={ENFlag} className="language-icon" alt="languages" />
                  <span className="language-text">
                    {t(englishLangTextShort)}
                  </span>
                </div>
              )}
              {Utils.isShowHeaderNotification(notifications) && (
                <div
                  className="notification-wrapper"
                  onClick={() => onNotificationClicked()}>
                  <img
                    src={BellIcon}
                    className="notification-icon"
                    alt="notifications"
                  />
                  {notifications?.totalUnreadCount > 0 && (
                    <span className="notification-count">
                      {notifications?.totalUnreadCount}
                    </span>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {showShipmentCountryInfo && (
        <AzCountryChange
          openedFromSticky={false}
          languages={availableShipmentLanguages}
        />
      )}
    </div>
  );
};

export default Header;
