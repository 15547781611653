import React from 'react';
import './Footer.scss';
import FooterButton from './footerButton/FooterButton';
import DeepLink from '../deepLink/DeepLink';
import * as PropTypes from 'prop-types';
import ButtonGroup from '../buttonGroup/ButtonGroup';

const Footer = ({ footerComponents, isRenderedFromOutside = false }) => {
  const returnComponentInfo = (component) => {
    component.isFooter = true;
    return component;
  };
  return (
    <ButtonGroup responsive={false} className="footer">
      {footerComponents.length &&
        footerComponents.map((component, i) => {
          if (component.componentType === 'Button') {
            return (
              <FooterButton
                index={i}
                componentData={component.componentData.buttons}
              />
            );
          }

          if (component.componentType === 'DeepLink') {
            return (
              <DeepLink
                componentData={returnComponentInfo(component.componentData)}
              />
            );
          }
        })}
    </ButtonGroup>
  );
};

Footer.propTypes = {
  footerComponents: PropTypes.array.isRequired,
};

export default Footer;
