import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { showMoreShipment } from '../../../../i18n/keys';
import './GoClaimStatusDetail.scss';
import GoClaimDetailShipment from './GoClaimDetailShipments';
import usePersistentState from '../../../common/usePersistentState';
import Bubble from '../../../layout/bubble/Bubble';

const GoClaimStatusDetail = ({
  id,
  componentData,
  setShouldResizeIframe = () => {},
  isRenderedFromOutside = false,
}) => {
  const [isShowMore, setIsShowMore] = usePersistentState(
    id,
    'isShowMore',
    false
  );
  const { t } = useTranslation();
  const { detailItemList } = componentData?.claimDetail;

  const { label } = componentData;
  useEffect(() => {
    if (detailItemList.length > 2) {
      setIsShowMore(true);
    }
  }, []);

  const handleSeeMore = () => {
    setIsShowMore(false);
    if (isRenderedFromOutside) {
      setShouldResizeIframe(true);
    }
  };

  return (
    <Bubble
      title={label}
      isBottomRounded={false}
      className="go-claim-status-detail-bubble">
      <div className="go-claim-status-wrapper">
        <div className="go-claim-status-detail">
          {detailItemList && detailItemList.length > 2 && isShowMore ? (
            <div>
              <GoClaimDetailShipment
                id={'GoClaimDetailShipment' + id}
                detailItemList={detailItemList.slice(0, 2)}
              />
              <div className="go-see-more" onClick={() => handleSeeMore()}>
                {t(showMoreShipment)}
              </div>
            </div>
          ) : (
            <GoClaimDetailShipment detailItemList={detailItemList} />
          )}
        </div>
      </div>
    </Bubble>
  );
};

export default GoClaimStatusDetail;
