import React from 'react';

const Button = ({ data, iconUrl, onClick, title, ...props }) => {
  return (
    <button {...props} onClick={() => onClick(data)}>
      <div className="text">
        {iconUrl && <img src={iconUrl} alt={'subject-button'} />}
        {title}
      </div>
    </button>
  );
};

export default Button;
