import './Embedded.scss';

import React, { useEffect, useRef } from 'react';
import BottomSheet from '../../../common/BottomSheet/BottomSheet';
import axios from 'axios';

const Embedded = ({ embeddedContent, setEmbeddedContent }) => {
  const hostRef = useRef(null);

  const handleCloseEmbeddedModal = () => setEmbeddedContent(false);

  const fetchEmbeddedContent = () => {
    if (embeddedContent?.url && hostRef?.current) {
      axios
        .get(embeddedContent.url)
        .then(({ data }) => {
          const shadowRoot = hostRef.current.attachShadow({ mode: 'open' });
          shadowRoot.innerHTML = data;
        })
        .catch(handleCloseEmbeddedModal);
    }
  };

  useEffect(() => {
    fetchEmbeddedContent();
  }, []);

  return (
    <BottomSheet
      isOpen
      className="embedded-modal-bottomsheet"
      title={embeddedContent?.header}
      onClose={handleCloseEmbeddedModal}
      onCloseButtonClick={handleCloseEmbeddedModal}>
      <div ref={hostRef} className="embedded-iframe" />
    </BottomSheet>
  );
};

export default Embedded;
