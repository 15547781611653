import React from 'react';
import './WarningInfo.scss';
import AlertIcon from '../../../assets/icons/alert.svg';

const WarningInfo = ({ text = '' }) => (
  <div className="warning-info">
    <img src={AlertIcon} className="alert-icon" alt="warning-icon" />
    <span>{text}</span>
  </div>
);

export default WarningInfo;
