import React from 'react';
import InputLabel from '../InputLabel';
import classNames from 'classnames';
import './TextInput.scss';

const TextInput = ({
  className = '',
  label = '',
  text = '',
  required = false,
  ...rest
}) => (
  <div className={classNames('text-input', { [className]: className })}>
    <InputLabel text={label} required={required} />
    <input type="text" className="assistant-input" {...rest} />
  </div>
);

export default TextInput;
