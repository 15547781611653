export const appTitle = 'appTitle';
export const appTitleDodo = 'appTitleDodo';
export const appTitleTgoApp = 'appTitleTgoApp';
export const appTitleMilla = 'appTitleMilla';
export const appTabTitle = 'appTabTitle';
export const liveChatTitle = 'liveChatTitle';
export const liveChatTitleDodo = 'liveChatTitleDodo';
export const liveChatTitleTgoApp = 'liveChatTitleTgoApp';
export const liveChatTitleMilla = 'liveChatTitleMilla';
export const liveChatTabTitle = 'liveChatTabTitle';
export const chatbotErrorMsg = 'chatbotErrorMsg';
export const selectOrderMsg = 'selectOrderMsg';
export const orderSelectedMsg = 'orderSelectedMsg';
export const liveChatConnecting = 'liveChatConnecting';
export const liveChatReconnecting = 'liveChatReconnecting';
export const agentJoined = 'agentJoined';
export const systemJoined = 'systemJoined';
export const systemJoinedMessage = 'systemJoinedMessage';
export const liveChatOffline = 'liveChatOffline';
export const liveChatLoadingMessage = 'liveChatLoadingMessage';
export const agentsBusyMessage = 'agentsBusyMessage';
export const makeUploadedContentBigger = 'makeUploadedContentBigger';
export const timeOutFirstPart = 'timeOutFirstPart';
export const timeOutSecondPart = 'timeOutSecondPart';
export const timeOutThirdPart = 'timeOutThirdPart';
export const closeLiveChatButton = 'closeLiveChatButton';
export const continueLiveChatButton = 'continueLiveChatButton';
export const liveChatRefused = 'liveChatRefused';
export const ordersText = 'ordersText';
export const copyText = 'copyText';
export const copiedText = 'copiedText';
export const chatInputBarPlaceholderMessage = 'chatInputBarPlaceholderMessage';
export const maxTimeOutThresholdExceedMessage =
  'maxTimeOutThresholdExceedMessage';
export const agentLeftMessage = 'agentLeftMessage';
export const chatConnectionErrorMessage = 'chatConnectionErrorMessage';
export const unAuthorizedMessage = 'unAuthorizedMessage';
export const resendMediaMessage = 'resendMediaMessage';
export const resendMediaMessageForEmptyImage =
  'resendMediaMessageForEmptyImage';
export const liveChatNewMessageAppTitle = 'liveChatNewMessageAppTitle';
export const surveyMessage = 'surveyMessage';
export const trendyolAsistantSurveyMessage = 'trendyolAsistantSurveyMessage';
export const surveyCommentMessage = 'surveyCommentMessage';
export const surveyRatingBadMessage = 'surveyRatingBadMessage';
export const surveyRatingGoodMessage = 'surveyRatingGoodMessage';
export const surveyRatingMessage = 'surveyRatingMessage';
export const liveChatSurveyRatingMessage = 'liveChatSurveyRatingMessage';
export const surveyRateButton = 'surveyRateButton';
export const surveyErrorModalTitle = 'surveyErrorModalTitle';
export const surveyErrorRatingEmpty = 'surveyErrorRatingEmpty';
export const surveyErrorServiceError = 'surveyErrorServiceError';
export const surveySuccessModalTitle = 'surveySuccessModalTitle';
export const surveySuccessModalMessage = 'surveySuccessModalMessage';
export const errorPageMessage = 'errorPageMessage';
export const closeOnErrorButton = 'closeOnErrorButton';
export const reconnectOnErrorButton = 'reconnectOnErrorButton';
export const agentDisconnected = 'agentDisconnected';
export const quantity = 'quantity';
export const orderText = 'orderText';
export const nameText = 'nameText';
export const lastnameText = 'lastnameText';
export const nameSurnameText = 'nameSurnameText';
export const telephoneText = 'telephoneText';
export const provinceText = 'provinceText';
export const districtText = 'districtText';
export const streetText = 'streetText';
export const addressText = 'addressText';
export const addressTitleText = 'addressTitleText';
export const confirmAddressText = 'confirmAddressText';
export const confirmOtpAddress = 'confirmOtpAddress';
export const confirmOtpAddressNewCode = 'confirmOtpAddressNewCode';
export const selectRegisteredAddressText = 'selectRegisteredAdress';
export const other = 'other';
export const branchText = 'branchText';
export const dateText = 'dateText';
export const saveText = 'saveText';
export const claimOrderSelectProduct = 'claimSelectProduct';
export const claimOrderSelectProducts = 'claimSelectProducts';
export const claimOrderFillToContinue = 'claimFillToContinue';
export const claimOrderClaimableIsNotDone = 'claimOrderClaimableIsNotDone';
export const claimOrderDescription = 'claimOrderDescription';
export const claimOrderReason = 'claimOrderReason';
export const claimCodeText = 'claimCodeText';
export const totalClaimAmountText = 'totalClaimAmountText';
export const claimAmountText = 'claimAmountText';
export const claimCoinAmountText = 'claimCoinAmountText';
export const coinText = 'coinText';
export const coinQuantityAndAmountInfo = 'coinQuantityAndAmountInfo';
export const coinsWording = 'coinsWording';
export const coinUsageMessage = 'coinUsageMessage';
export const coinUsageOrderMessage = 'coinUsageOrderMessage';
export const coinEarnedMessage = 'coinEarnedMessage';
export const coinEarnedOrderMessage = 'coinEarnedOrderMessage';
export const coinEarnedQuantityAndAmountInfo =
  'coinEarnedQuantityAndAmountInfo';
export const countAndPriceText = 'countAndPriceText';
export const claimReasonText = 'claimReasonText';
export const claimRejectReasonLabel = 'claimRejectReasonLabel';
export const bankRefNoText = 'bankRefNoText';
export const cancelOrderSelectProduct = 'cancelOrderSelectProduct';
export const cancelOrderSelectReason = 'cancelOrderSelectReason';
export const explanation = 'explanation';
export const selectReason = 'selectReason';
export const quantityText = 'quantityText';
export const productText = 'productText';
export const branchNameText = 'branchName';
export const wrongDeliveredPersonNameText = 'wrongDeliveredPersonName';
export const outOfWorkOurMessage = 'outOfWorkOurMessage';
export const transitionScreenWaitingAreaText =
  'transitionScreenWaitingAreaText';
export const claimStatusCargoText = 'claimStatusCargoText';
export const showMoreShipment = 'showMoreShipment';
export const cargoInfoText = 'cargoInfoText';
export const invalidTokenInfoText = 'invalidTokenInfoText';
export const newText = 'newText';
export const addText = 'addText';
export const loadedText = 'loadedText';
export const orderNo = 'orderNo';
export const shipmentNo = 'shipmentNo';
export const deliveredProductProblemImageAgentScreenLabel =
  'deliveredProductProblemImageAgentScreenLabel';
export const addNewContactText = 'addNewContactText';
export const updateContactText = 'updateContactText';
export const contactTypeText = 'contactTypeText';
export const emailText = 'emailText';
export const contactDescription = 'contactDescription';
export const comboboxDefaultValue = 'comboboxDefaultValue';
export const continueLiveChatText = 'continueLiveChatText';
export const exitAndOpenSurvey = 'exitAndOpenSurvey';
export const exitLiveChatModalText = 'exitLiveChatModalText';
export const clientReconnectingMessage = 'clientReconnectingMessage';
export const clientRedirectedMessage = 'clientRedirectedMessage';
export const selectProductImageText = 'selectProductImageText';
export const selectProductVideoText = 'selectProductVideoText';
export const internationalMailSelectProductImageText =
  'internationalMailSelectProductImageText';
export const deleteText = 'deleteText';
export const optionalFileInfoText = 'optionalFileInfoText';
export const imageMaxSizeErrorText = 'imageMaxSizeErrorText';
export const imageFormatMismatchErrorText = 'imageFormatMismatchErrorText';
export const imageCountErrorText = 'imageCountErrorText';
export const fileUploadVideoErrorText = 'fileUploadVideoErrorText';
export const imageReadError = 'imageReadError';
export const fileNameNotValidError = 'fileNameNotValidError';
export const fileEmptyError = 'fileEmptyError';
export const imageUploadError = 'imageUploadError';
export const goDeliveredTicketProductsFillToContinue =
  'goDeliveredTicketProductsFillToContinue';
export const seller = 'seller';
export const me = 'me';
export const neighborhoodText = 'neighborhoodText';
export const apartmentNoText = 'apartmentNoText';
export const buildingNoText = 'buildingNoText';
export const descriptionText = 'descriptionText';
export const reasonText = 'reasonText';
export const street = 'street';
export const cancelBtnText = 'cancelBtnText';
export const createTicket = 'createTicket';
export const stickySurveyTitle = 'stickySurveyTitle';
export const stickySurveyRateMessage = 'stickySurveyRateMessage';
export const stickySurveyCommentMessage = 'stickySurveyCommentMessage';
export const stickySuccessSurveyMessage = 'stickySuccessSurveyMessage';
export const stickySurveySuccessTitle = 'stickySurveySuccessTitle';
export const stickySurveySuccessText = 'stickySurveySuccessText';
export const stickySurveySuccessButtonText = 'stickySurveySuccessButtonText';
export const internationalTicketCreateSucceedText =
  'internationalTicketCreateSucceedText';
export const internationalTicketCreateSucceedTitle =
  'internationalTicketCreateSucceedTitle';
export const internationalTicketCreateFailedText =
  'internationalTicketCreateFailedText';
export const internationalTicketCreateFailedTitle =
  'internationalTicketCreateFailedTitle';

export const placeHolderName = 'placeHolderName';
export const fileInputVideoInfo = 'fileInputVideoInfo';
export const placeHolderSurname = 'placeHolderSurname';
export const placeHolderProvince = 'placeHolderProvince';
export const placeHolderDistrict = 'placeHolderDistrict';
export const placeHolderNeighborhood = 'placeHolderNeighborhood';
export const placeHolderStreet = 'placeHolderStreet';
export const placeHolderAddress = 'placeHolderAddress';
export const placeHolderAddressTitle = 'placeHolderAddressTitle';
export const placeHolderSelectReason = 'placeHolderSelectReason';
export const placeHolderDescription = 'placeHolderDescription';
export const placeHolderBuildingNo = 'placeHolderBuildingNo';
export const placeHolderApartmentNo = 'placeHolderApartmentNo';
export const badCodeErrorMsg = 'badCodeErrorMsg';
export const reasonSelectText = 'reasonSelectText';
export const orderImageLabel = 'orderImageLabel';
export const chatbotShortErrorMsg = 'chatbotShortErrorMsg';
export const chatbotErrorMsgKey = 'chatbotErrorMsgKey';
export const dateSelect = 'dateSelect';
export const placeHolderbranch = 'placeHolderbranch';
export const fileLoadedText = 'fileLoadedText';
export const uploadFileText = 'uploadFileText';
export const collectionPointText = 'collectionPointText';
export const groupDealText = 'groupDealText';
export const commercialText = 'commercialText';
export const wardrobeText = 'wardrobeText';
export const moreProductText = 'moreProductText';
export const barcodeText = 'barcodeText';
export const placeHolderBarcodeText = 'placeHolderBarcodeText';
export const placeHolderDescriptionText = 'placeHolderDescriptionText';
export const orderDate = 'orderDate';
export const orderSummary = 'orderSummary';
export const totalText = 'totalText';
export const currencyText = 'currencyText';
export const deliveryDateText = 'deliveryDateText';
export const deliveryTimeText = 'deliveryTimeText';
export const deliveryNumberText = 'deliveryNumberText';
export const deliveryAddressText = 'deliveryAddressText';
export const billingAddressText = 'billingAddressText';
export const yourEmailText = 'yourEmailText';
export const languageText = 'languageText';
export const placeholderLanguage = 'placeholderLanguage';
export const placeholderDeliveryNumber = 'placeholderDeliveryNumber';
export const startChatText = 'startChatText';
export const placeholderEmail = 'placeholderEmail';
export const closeButtonText = 'closeButtonText';
export const retryButtonText = 'retryButtonText';
export const placeholderSubject = 'placeholderSubject';
export const subjectText = 'subjectText';
export const internationalSubjectImageText = 'internationalSubjectImageText';
export const mailDescriptionText = 'mailDescriptionText';
export const mailSubmitText = 'mailSubmitText';
export const paymentInfoText = 'paymentInfo';
export const subtotalText = 'subtotalText';
export const cargoText = 'cargoText';
export const codServiceFeeText = 'codServiceFeeText';
export const englishLangText = 'englishLangText';
export const germanLangText = 'germanLangText';
export const arabicLangText = 'arabicLangText';
export const bulgariaLangText = 'bulgariaLangText';
export const greeceLangText = 'greeceLangText';
export const hungaryLangText = 'hungaryLangText';
export const czechiaLangText = 'czechiaLangText';
export const romaniaLangText = 'romaniaLangText';
export const polandLangText = 'polandLangText';
export const slovakiaLangText = 'slovakiaLangText';
export const englishLangTextShort = 'englishLangTextShort';
export const dateErrorText = 'dateErrorText';
export const claimQuantityText = 'claimQuantityText';
export const shipmentSubtitleTotal = 'shipmentSubtitleTotal';
export const shipmentSubtitleItems = 'shipmentSubtitleItems';
export const claimSimulationPreviewQuantity = 'claimSimulationPreviewQuantity';
export const claimSimulationPreviewProduct = 'claimSimulationPreviewProduct';
export const claimSubtitleTotal = 'claimSubtitleTotal';
export const claimSubtitleItems = 'claimSubtitleItems';
export const seeAllAnnouncement = 'seeAllAnnouncement';
export const showMoreTicket = 'showMoreTicket';
export const notificationsTitle = 'notificationsTitle';
export const linkCopied = 'linkCopied';
export const detail = 'detail';
export const addExtraImage = 'addExtraImage';
export const fastMarketText = 'fastMarketText';
export const mealText = 'mealText';
export const minumumOrderQuantityHeader = 'minumumOrderQuantityHeader';
export const minumumOrderQuantityMessage = 'minumumOrderQuantityMessage';
export const differentSubject = 'differentSubject';
export const myOrderDetail = 'myOrderDetail';
export const inputLanguageNotSupported = 'inputLanguageNotSupported';
export const azLanguageDescription = 'azLanguageDescription';
export const trLanguageDescription = 'trLanguageDescription';
export const changeShipmentCountry = 'changeShipmentCountry';
export const shipmentCountry = 'shipmentCountry';
export const turkey = 'turkey';
export const azerbeijan = 'azerbeijan';
export const delivery = 'delivery';
export const showMoreContent = 'showMoreContent';
export const showLessContent = 'showLessContent';
export const approvalCode = 'approvalCode';
export const seconds = 'seconds';
export const multiValueInputLabel = 'multiValueInputLabel';
export const onlyDigits = 'onlyDigits';
export const maxDigitLength = 'maxDigitLength';
export const multiValueMaxItemCount = 'multiValueMaxItemCount';
export const packageText = 'packageText';
export const itemText = 'itemText';

export const productImageText = 'productImageText';
export const refundAmountText = 'refundAmountText';
export const estimatedRefundDateText = 'estimatedRefundDateText';
export const claimLastModifiedDateMillaText = 'claimLastModifiedDateMillaText';
export const claimLastModifiedDateTrText = 'claimLastModifiedDateTrText';
export const refundDetailText = 'refundDetailText';
export const refundProgressBankInfo = 'refundProgressBankInfo';
export const refundProgressTrInfo = 'refundProgressTrInfo';
export const refundProgressMillaInfo = 'refundProgressMillaInfo';

export const userDisconnectedOnBackground = 'userDisconnectedOnBackground';
