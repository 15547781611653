import React from 'react';
import { useTranslation } from 'react-i18next';
import './Info.scss';
import { ReactComponent as InfoIcon } from '../../../assets/icons/circle-info.svg';
import { ReactComponent as HandInfo } from '../../../assets/icons/hand-info.svg';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

const Info = ({
  messages,
  message,
  localize = false,
  background = true,
  green = false,
  info = true,
  handInfo = false,
}) => {
  const { t } = useTranslation();

  const infoMessages = messages ? messages : message ? [message] : [];

  if (!infoMessages.length) {
    return null;
  }

  return (
    <div
      className={classNames(['layout-info', { background, green }])}
      role="info">
      <div className={classNames(['layout-info-icon', { green, info }])}>
        {handInfo && <HandInfo />}
        {!handInfo && !handInfo && <InfoIcon />}
      </div>
      <div className={classNames(['layout-info-message', { green, info }])}>
        {infoMessages.map((message, index) => {
          return (
            <div
              key={`info-${index}`}
              role="info-message"
              className={classNames([
                'layout-info-message-row',
                { green, info },
              ])}>
              {localize ? t(message) : <ReactMarkdown children={message} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Info;
