import React, { useEffect } from 'react';
import TextArea from 'antd/es/input/TextArea';

export default function TextInputArea({ item, onChange, ...rest }) {
  useEffect(() => {
    if (item.defaultValue) {
      onChange(item.defaultValue);
    }
  }, []);

  return (
    <TextArea
      autoSize={{ minRows: 2, maxRows: 3 }}
      placeholder={item.placeholder}
      defaultValue={item.defaultValue}
      {...rest}
      className="assistant-textarea"
      onChange={onChange}
    />
  );
}
