import React from 'react';
import './TicketItem.scss';
import { deliveryNumberText } from '../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import ClaimInfo from '../../common/ClaimInfo';

const TicketItem = ({ item, fromState = false }) => {
  const { t } = useTranslation();

  return (
    <div className={classnames(['ticket-base', { 'from-state': fromState }])}>
      <div className="ticket-body">
        <div className="info-wrapper">
          <div className="item-info">
            <span className="ticket-title">{item.ticketName}</span>
            <div className="status">{item.status}</div>
          </div>
          {item.shipmentNumber && (
            <div className="shipmentNumber">
              <label>{t(deliveryNumberText)}</label> {item.shipmentNumber}
            </div>
          )}
          <div className="sla-container">
            <div className="sla">{item.estimatedSla}</div>
            <span className="date">{item.createDate}</span>
          </div>
        </div>
      </div>
      {item.additionalInfo && <ClaimInfo text={item.additionalInfo} />}
    </div>
  );
};

export default TicketItem;
