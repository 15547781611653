import React, { useContext, useEffect } from 'react';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import Utils from '../../../utils';
import './Renderer.scss';
import ComponentFactory from '../../chatAssistant/ComponentFactory';
import usePersistentState from '../../common/usePersistentState';

export default React.forwardRef(function SubjectButton(props, ref) {
  const {
    components,
    currentIndex,
    history,
    scrollToBottom,
    isRenderedFromOutside = false,
  } = props;
  const chatBotContext = useContext(ChatBotContext);
  const { requestToChatBot, currentChatIndex, isRequesting, totalErrorCount } =
    chatBotContext;

  const [showHover, setShowHover] = usePersistentState(
    'Renderer',
    'showHover',
    true
  );
  const [isSelectable, setIsSelectable] = usePersistentState(
    'Renderer',
    'isSelectable',
    false
  );

  useEffect(() => {
    let show = Utils.shouldSelect(
      currentIndex,
      currentChatIndex,
      totalErrorCount,
      isRequesting
    );
    setShowHover(show);
    setIsSelectable(show);
  }, [currentChatIndex, totalErrorCount, currentIndex, isRequesting]);

  const select = (request) => {
    if (request && request.isMainTopic) {
      requestToChatBot(request);
    } else {
      let shouldSelect = Utils.shouldSelect(
        currentIndex,
        currentChatIndex,
        totalErrorCount,
        isRequesting
      );
      shouldSelect && requestToChatBot(request);
    }
  };

  return (
    <div className="chat-bot-message" data-testid="chat-bot-message">
      {components &&
        components.map((componentInfo, index) => {
          let componentData = {
            select: select,
            id: componentInfo.id,
            key: index,
            currentIndex: currentIndex,
            showHover: showHover,
            componentData: componentInfo.componentData,
            isFooter: false,
            isSelectable: isSelectable,
            ref: ref,
            history: history,
            scrollToBottom,
            isRenderedFromOutside,
          };

          let component = ComponentFactory.get(componentInfo.componentType);
          return React.createElement(component, componentData);
        })}
    </div>
  );
});
