import React, { useEffect } from 'react';
import {
  GO_DELIVERED_PRODUCT_RECEIPT_ACCEPTED_FORMATS,
  MIN_UPLOAD_SIZE,
} from '../../../../constants';
import FileInput from '../../fileInput/FileInput';
import usePersistentState from '../../../common/usePersistentState';
import Alert from '../../../layout/alert/Alert';
import { UPLOAD_VERSION_1 } from '../../../../constants/chatAssistantConstant';

import './FormFileInput.scss';
import InputLabel from '../../../common/InputLabel/InputLabel';
import DeepLink from '../../deepLink/DeepLink';
import { imageFormatMismatchErrorText } from '../../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import { read } from 'xlsx';

export default function FormFileInput({ id, item, onChange, lang }) {
  const { i18n } = useTranslation();
  const fixedTranslator = i18n.getFixedT(lang ? lang : i18n.language);

  const [imageUploadErrors, setImageUploadErrors] = usePersistentState(
    id,
    'imageUploadErrors',
    []
  );

  const [acceptedDocumentInfo, setAcceptedDocumentInfo] = usePersistentState(
    id,
    'acceptedDocumentInfo',
    {}
  );

  const [payloadWithExcel, setPayloadWithExcel] = usePersistentState(
    id,
    'payloadWithExcel'
  );

  useEffect(() => {
    const maxFileCount = item.parseExcel ? MIN_UPLOAD_SIZE : item.maxFileCount;
    if (item.acceptedDocument) {
      setAcceptedDocumentInfo({
        type: item.acceptedDocument.type,
        message: item.acceptedDocument.message,
        maxFileCount: maxFileCount,
      });
    } else {
      setAcceptedDocumentInfo({
        type: GO_DELIVERED_PRODUCT_RECEIPT_ACCEPTED_FORMATS,
        message: fixedTranslator(imageFormatMismatchErrorText),
        maxFileCount: maxFileCount,
      });
    }
  }, [item]);

  useEffect(() => {
    if (item.parseExcel && payloadWithExcel) {
      onChange(payloadWithExcel);
    }
  }, [payloadWithExcel]);

  const onUploadedImagesChange = (images) => {
    if (!item.parseExcel) {
      onChange(images.map((image) => image.response.data));
    }
  };

  const onUploadedDocumentChange = (file, images) => {
    if (item.parseExcel) {
      if (file) {
        const documentUrls = images.map((image) => image.response.data);
        parseExcelFile(file.file, item.excelCells, documentUrls);
      } else {
        onChange(null);
      }
    }
  };

  const onImageUploadError = (errors) => {
    setImageUploadErrors(errors);
  };

  function parseExcelFile(file, parameters, images) {
    const response = [];
    const reader = new FileReader();

    reader.onload = function (e) {
      const data = e.target.result;
      const workbook = read(data, { type: 'binary' });

      parameters.forEach((param) => {
        const { parameterName, sheetName, cellAddress } = param;
        const cellAddresses = cellAddress.split(';');
        const values = [];

        const sheet = workbook.Sheets[sheetName];

        cellAddresses.forEach((cellAddress) => {
          if (sheet[cellAddress]) {
            values.push(sheet[cellAddress].v);
          }
        });

        response.push({ parameter: parameterName, values });
      });
      setPayloadWithExcel({
        urls: images,
        excelCellValues: response,
      });
    };
    reader.readAsBinaryString(file);
  }

  return (
    <div className="form-file-input-container">
      {item.previewDeepLink && (
        <InputLabel
          text={item.label}
          required={(item.rules || []).some((rule) => rule.required === true)}>
          <div className="file-deeplink-end-label">
            <DeepLink componentData={item.previewDeepLink} />
          </div>
        </InputLabel>
      )}

      <div className="image-upload-body">
        <div className="image-upload-row">
          <FileInput
            id={id + 'FileInput'}
            placeholder={item.placeholder}
            onImagesChange={onUploadedImagesChange}
            onDocumentChange={onUploadedDocumentChange}
            onError={onImageUploadError}
            maxFileCount={acceptedDocumentInfo.maxFileCount}
            useCdn={true}
            accept={acceptedDocumentInfo.type}
            documentTypeValidationMessage={acceptedDocumentInfo.message}
            preview={true}
            isRequired={true}
            uploadVersion={
              item.uploadVersion ? item.uploadVersion : UPLOAD_VERSION_1
            }
            isAsteriskShown={false}
            compressImage={item.compressImage}
            lang={lang}
          />
        </div>
        {!!imageUploadErrors.length && (
          <div className="image-upload-row">
            <Alert messages={imageUploadErrors} />
          </div>
        )}
      </div>
    </div>
  );
}
