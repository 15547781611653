import React, { useContext, useEffect } from 'react';
import './QrCode.scss';
import classnames from 'classnames';
import { Modal } from 'antd';
import appService from '../../../../../../services/app.service';
import { FALSE } from '../../../../../../constants/chatAssistantConstant';
import ChatBotContext from '../../../../../../context/chatbot/chatbotContext';

const QrCode = ({ item, isVisible, setQrCodeVisibleState }) => {
  const chatBotContext = useContext(ChatBotContext);
  const { requestForDeepLink } = chatBotContext;
  const showModal = () => {
    setQrCodeVisibleState(true);
    onQrCodeClicked();
  };
  const handleCancel = () => {
    setQrCodeVisibleState(false);
  };

  function onQrCodeClicked() {
    const deepLinkRequest = {
      event: item.event,
      data: {
        deepLinkEventLabel: item.eventLabel,
      },
    };
    requestForDeepLink(deepLinkRequest);
  }

  useEffect(() => {
    if (isVisible === true) {
      appService.setIsAssistantExitButtonEnabled(FALSE);
    }
  }, [isVisible]);

  useEffect(() => {
    document.addEventListener('handleAndroidBackButton', (e) => {
      handleCancel();
    });
    return () => {
      return () => document.removeEventListener('handleAndroidBackButton');
    };
  }, [isVisible]);

  useEffect(() => {
    document.addEventListener('handleRightSwipeAction', (e) => {
      handleCancel();
    });
    return () => {
      return () => document.removeEventListener('handleRightSwipeAction');
    };
  }, [isVisible]);

  return (
    <div className={classnames(['qr-code-base'])}>
      <div className="qr-code-body">
        <div className="details">
          <div className="image__wrap">
            <img
              src={item.iconUrl}
              alt="qr-code-search-image"
              className="qr-sample-image"
              onClick={showModal}
            />
            <Modal
              title={'QR Code'}
              visible={isVisible}
              onCancel={handleCancel}
              footer={null}
              centered>
              <div className="qr-code-image-wrapper">
                <img src={item.url} alt="qr-code-image" className="image" />
              </div>
            </Modal>
          </div>
          <div className="detail">
            <div className="info-wrapper">
              <div className="item-info">
                <span className="qr-code-title">{item.label}</span>
              </div>
              <div className="qr-code-text">{item.text}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCode;
