import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Bubble from '../../../layout/bubble/Bubble';
import './OrderProblemsRequest.scss';
import OrderProblemsProduct from './OrderProblemsProduct';
import Select from '../../../Select';
import ChatBotContext from '../../../../context/chatbot/chatbotContext';
import {
  fileInputVideoInfo,
  selectProductImageText,
  selectProductVideoText,
} from '../../../../i18n/keys';
import Utils from '../../../../utils';
import usePersistentState from '../../../common/usePersistentState';
import {
  CLAIM_UPLOAD_IMAGE_ACCEPTED_FORMATS,
  CLAIM_UPLOAD_MAX_FILE_COUNT,
  CLAIM_UPLOAD_MAX_FILE_SIZE,
  CLAIM_UPLOAD_VIDEO_ACCEPTED_FORMATS,
  CLAIM_VIDEO_UPLOAD_MAX_FILE_COUNT,
  CLAIM_VIDEO_UPLOAD_MAX_FILE_SIZE,
  VIDEO_NOT_REQUIRED,
  VIDEO_REQUIRED,
} from '../../../../constants';
import FileInput from '../../fileInput/FileInput';
import AlertIcon from '../../../../assets/icons/alert.svg';
import Alert from '../../../layout/alert/Alert';
import appService from '../../../../services/app.service';
import TextArea from '../../../common/TextArea';
import ButtonGroup from '../../buttonGroup/ButtonGroup';

const OrderProblemsRequest = ({ id, componentData, currentIndex }) => {
  const { t } = useTranslation();
  const chatBotContext = useContext(ChatBotContext);
  const { requestToChatBot, currentChatIndex, totalErrorCount, isRequesting } =
    chatBotContext;

  const [selectedReason, setSelectedReason] = usePersistentState(
    id,
    'selectedReason',
    '-1'
  );

  const [selectedDemount, setSelectedDemount] = usePersistentState(
    id,
    'selectedDemount',
    '-1'
  );
  const [totalSelectedShipment, setTotalSelectedShipment] = usePersistentState(
    id,
    'totalSelectedShipment',
    []
  );
  const [isActive, setIsActive] = usePersistentState(id, 'isActive', false);

  const [images, setImages] = usePersistentState(id, 'images', []);
  const [videos, setVideos] = usePersistentState(id, 'videos', []);
  const [videoUploadErrors, setVideoUploadErrors] = usePersistentState(
    id,
    'videoUploadErrors',
    []
  );
  const [imageUploadErrors, setImageUploadErrors] = usePersistentState(
    id,
    'imageUploadErrors',
    []
  );

  const [additionalNote, setAdditionalNote] = usePersistentState(
    id,
    'additionalNote',
    ''
  );

  const {
    productModelData,
    comboboxData,
    freeTextData,
    requestButtonData,
    infoText,
    label,
    fileRequired,
    videoRequired,
  } = componentData;

  let shouldSelect = Utils.shouldSelect(
    currentIndex,
    currentChatIndex,
    totalErrorCount,
    isRequesting
  );

  useEffect(() => {
    totalSelectedShipment.itemList = [];
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      freeTextData &&
      freeTextData[0] &&
      freeTextData[0].isMandatory === true &&
      (!additionalNote || additionalNote.length <= 0)
    ) {
      setIsActive(false);
    } else if (selectedReason === '-1') {
      setIsActive(false);
    } else if (!!fileRequired && images.length <= 0) {
      setIsActive(false);
    } else if (!!videoRequired === VIDEO_NOT_REQUIRED && videos.length <= 0) {
      setIsActive(false);
    } else if (totalSelectedShipment.length > 0) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [
    totalSelectedShipment,
    selectedDemount,
    selectedReason,
    additionalNote,
    freeTextData,
    images,
    videos,
  ]);

  const selectShipment = (shipment, selectedQuantity) => {
    let newShipment = {
      listingId: shipment.listingId,
      quantity: selectedQuantity,
    };
    let indexOfItem = totalSelectedShipment.findIndex(
      (item) => item.listingId === shipment.listingId
    );
    if (indexOfItem === -1) {
      setTotalSelectedShipment([...totalSelectedShipment, newShipment]);
    } else {
      let newTotalItems = totalSelectedShipment.filter(
        (item) => item.listingId !== shipment.listingId
      );
      if (newTotalItems === undefined || newTotalItems === []) {
        setTotalSelectedShipment([newShipment]);
      } else {
        setTotalSelectedShipment([...newTotalItems, newShipment]);
      }
    }
  };

  const unSelectShipment = (shipment) => {
    let newTotalItems = totalSelectedShipment.filter(
      (item) => item.listingId !== shipment.listingId
    );
    if (newTotalItems !== undefined && newTotalItems !== []) {
      setTotalSelectedShipment(newTotalItems);
    }
  };

  const getReasons = (reasonsData) =>
    reasonsData.map((reason) => ({ value: reason.id, label: reason.name }));

  const handleReasonSelect = (value) => {
    setSelectedReason(value);
  };

  const handleDemountSelect = (value) => {
    setSelectedDemount(value);
  };

  const handleTicketRequest = () => {
    if (shouldSelect && isActive) {
      let request = {
        event: requestButtonData.event,
        data: {
          shipmentNumber: componentData.shipmentNumber,
          orderNumber: componentData.orderNumber,
          reasonId: selectedReason,
          demounted: selectedDemount,
          additionalNote: additionalNote,
          images: images,
          items: totalSelectedShipment,
          videos: videos,
        },
      };

      requestToChatBot(request);
      setIsActive(false);
    }
  };

  const onImagesChange = (images) => {
    setImages(images.map((image) => image.response.data));
  };

  const onImageUploadError = (errors) => {
    setImageUploadErrors(errors);
  };

  const onChangeAdditionalNote = (e) => {
    setAdditionalNote(e.target.value);
  };

  const onVideoChange = (videos) => {
    setVideos(videos.map((video) => video.response));
  };
  const onVideoUploadError = (errors) => setVideoUploadErrors(errors);

  return (
    <div className="order-problems-product-request">
      <Bubble title={label} isBottomRounded={false}>
        <div className="order-problems-items">
          {productModelData.productModels.map((product, i) => (
            <OrderProblemsProduct
              id={id + 'OrderProblemsProduct' + i}
              product={product}
              selectShipment={selectShipment}
              unSelectShipment={unSelectShipment}
              shouldSelect={shouldSelect}
            />
          ))}
        </div>
        {comboboxData &&
          comboboxData.map((section, i) => (
            <>
              {section.requestFieldName === 'reasonId' && (
                <div className="request-problem">
                  <div className="select-reason">
                    <Select
                      value={
                        selectedReason === '-1' ? undefined : selectedReason
                      }
                      label={section.placeHolder}
                      onChange={handleReasonSelect}
                      disabled={!shouldSelect}
                      placeholder={section.placeHolder}
                      options={getReasons(section.comboboxModels)}
                    />
                  </div>
                  {section.infoMap && section.infoMap[selectedReason] && (
                    <div className="form-item-info">
                      <img
                        src={AlertIcon}
                        className="alert-icon"
                        alt="warning-icon"
                      />
                      <span>{section.infoMap[selectedReason]}</span>
                    </div>
                  )}
                </div>
              )}
              {section.requestFieldName === 'demountedId' && (
                <div className="request-problem">
                  <div className="select-reason">
                    <Select
                      value={
                        selectedDemount === '-1' ? undefined : selectedDemount
                      }
                      onChange={handleDemountSelect}
                      disabled={!shouldSelect}
                      placeholder={section.placeHolder}
                      options={getReasons(section.comboboxModels)}
                    />
                  </div>
                </div>
              )}
            </>
          ))}
        <FileInput
          id={id + 'FileInput'}
          placeholder={t(selectProductImageText, {
            count: CLAIM_UPLOAD_MAX_FILE_COUNT,
          })}
          onImagesChange={onImagesChange}
          onError={onImageUploadError}
          isRequired={!!fileRequired}
          maxFileCount={CLAIM_UPLOAD_MAX_FILE_COUNT}
          maxFileSize={CLAIM_UPLOAD_MAX_FILE_SIZE}
          accept={CLAIM_UPLOAD_IMAGE_ACCEPTED_FORMATS}
          useCdn={true}
          uploadVersion={appService.getClaimUploadTargetVersion()}
        />
        {!!imageUploadErrors.length && (
          <div className="image-upload-error">
            <Alert messages={imageUploadErrors} />
          </div>
        )}

        {videoRequired && videoRequired !== VIDEO_NOT_REQUIRED && (
          <div className="other-wrapper">
            <FileInput
              id={id + 'FileInput_Video'}
              placeholder={t(selectProductVideoText, {
                count: CLAIM_VIDEO_UPLOAD_MAX_FILE_COUNT,
              })}
              onVideoChange={onVideoChange}
              onError={onVideoUploadError}
              isRequired={videoRequired === VIDEO_REQUIRED}
              maxFileCount={CLAIM_VIDEO_UPLOAD_MAX_FILE_COUNT}
              maxFileSize={CLAIM_VIDEO_UPLOAD_MAX_FILE_SIZE}
              accept={CLAIM_UPLOAD_VIDEO_ACCEPTED_FORMATS}
              useCdn={true}
              compressImage={false}
              uploadVersion={appService.getClaimUploadTargetVersion()}
            />
            <div className="alert-container">
              <img src={AlertIcon} className="alert-icon" alt="alert-icon" />
              <span>{t(fileInputVideoInfo)}</span>
            </div>
          </div>
        )}

        {!!videoUploadErrors.length && (
          <div className="claimable-item-row">
            <Alert messages={videoUploadErrors} />
          </div>
        )}

        {freeTextData &&
          freeTextData.map((freeText, i) => (
            <div className="other-wrapper" key={i}>
              <TextArea
                value={additionalNote}
                placeholder={freeText.placeHolder}
                required
                disabled={!shouldSelect}
                onChange={onChangeAdditionalNote}
              />
            </div>
          ))}
        <ButtonGroup>
          <ButtonGroup.Button
            isSelectable={isActive}
            colorType="primary"
            onClick={handleTicketRequest}
            outline={false}
            disabled={!isActive || !shouldSelect}>
            {requestButtonData.text}
          </ButtonGroup.Button>
        </ButtonGroup>
        {infoText && (
          <div className="form-item-info last">
            <img src={AlertIcon} className="alert-icon" alt="warning-icon" />
            <span>{infoText}</span>
          </div>
        )}
      </Bubble>
    </div>
  );
};

export default OrderProblemsRequest;
