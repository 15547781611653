import React, { useContext, useMemo, useRef } from 'react';
import { Form } from 'antd';
import './Form.scss';
import Utils from '../../../utils';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import Bubble from '../../layout/bubble/Bubble';
import FormItem from './FormItem';
import Alert from '../../layout/alert/Alert';
import usePersistentState from '../../common/usePersistentState';
import ButtonGroup from '../buttonGroup/ButtonGroup';

const ChatForm = ({ id, componentData, currentIndex }) => {
  const chatBotContext = useContext(ChatBotContext);
  const formRef = useRef();
  const { event, label, submitLabel, infoText, items } = componentData;

  const { requestToChatBot, currentChatIndex, totalErrorCount, isRequesting } =
    chatBotContext;
  const [form] = Form.useForm();

  const formId = useMemo(() => Utils.uuid(), []);
  const [values, setValues] = usePersistentState(id, 'values', {});

  const initialValues = useMemo(() => {
    for (const item of items) {
      if (item && item.name && item.defaultValue) {
        values[item.name] = item.defaultValue;
      }
    }
    return values;
  }, [items]);

  const allowSendRequest = Utils.shouldSelect(
    currentIndex,
    currentChatIndex,
    totalErrorCount,
    isRequesting
  );

  const handleSubmit = (values) => {
    if (!allowSendRequest) {
      return;
    }
    requestToChatBot({ event, data: values });
  };

  return (
    <Bubble title={label} isBottomRounded={false}>
      <div className={'form-container'}>
        <Form
          ref={formRef}
          name={formId}
          form={form}
          id={formId}
          layout="vertical"
          initialValues={initialValues}
          onFinish={handleSubmit}
          onValuesChange={(_, values) => setValues(values)}>
          {items.map((item, index) => (
            <FormItem
              shouldUpdate
              id={id}
              key={`form-item-${index}`}
              item={item}
              form={form}
              disabled={!allowSendRequest}
            />
          ))}
          <Form.Item shouldUpdate>
            {() => {
              const isButtonDisabled = () => {
                if (!allowSendRequest) {
                  return true;
                }
                if (formRef.current) {
                  return (
                    !formRef.current.isFieldsTouched(true) ||
                    formRef.current
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length > 0
                  );
                } else {
                  return items.some(
                    (item) => item.rules && item.rules.length > 0
                  );
                }
              };

              return (
                <ButtonGroup.Button
                  type="submit"
                  colorType="primary"
                  outline={false}
                  disabled={isButtonDisabled()}
                  isSelectable={!isButtonDisabled()}>
                  {submitLabel}
                </ButtonGroup.Button>
              );
            }}
          </Form.Item>
        </Form>
        <Alert localize={false} message={infoText} />
      </div>
    </Bubble>
  );
};

export default ChatForm;
