import React, { useContext, useMemo, useState } from 'react';
import './ClaimClusterPreviewItem.scss';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import {
  quantity,
  showLessContent,
  showMoreContent,
} from '../../../../i18n/keys';
import { ReactComponent as ArrowUp } from '../../../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down.svg';
import Image from '../../../common/Image';
import ButtonGroup from '../../buttonGroup/ButtonGroup';
import chatBotContext from '../../../../context/chatbot/chatbotContext';

const DEFAULT_VISIBLE_COUNT = 2;
const DEFAULT_FULLY_VISIBLE_COUNT = 1;

const ClaimClusterPreviewItem = ({
  componentData,
  isRenderedFromOutside = false,
}) => {
  const { t } = useTranslation();
  const { requestToChatBot } = useContext(chatBotContext);
  const { buttonLabel, event, claimClusterPreviewInfoList } = componentData;

  const [isExpanded, setIsExpanded] = useState(false);

  const displayedItems = useMemo(
    () =>
      isExpanded
        ? claimClusterPreviewInfoList
        : claimClusterPreviewInfoList?.slice(0, DEFAULT_VISIBLE_COUNT),
    [isExpanded]
  );

  const toggleProducts = () => {
    setIsExpanded(!isExpanded);
  };

  const onClickConfirm = () => {
    if (!isRenderedFromOutside) {
      requestToChatBot({ event });
    }
  };

  return (
    <div className="claim-cluster-preview-list">
      <div className="bubble">
        <div
          className={classNames([
            'claim-info-list-wrapper',
            {
              expanded: isExpanded,
              collapsed:
                claimClusterPreviewInfoList?.length >
                  DEFAULT_FULLY_VISIBLE_COUNT && !isExpanded,
            },
          ])}>
          <div className="claim-info-list">
            {displayedItems?.map((item, i) => {
              return (
                <div key={i} className="claim-info-wrapper">
                  <div className="product-header">
                    <div className="product-image">
                      <Image src={item.imageUrl} alt="product" />
                    </div>
                    <div className="product-content">
                      <span className="product-content__title">
                        {item.productName}
                      </span>
                      <span className="product-content__quantity">
                        {`${item.quantity} ${t(quantity)}`}
                      </span>
                    </div>
                  </div>
                  <div className="claim-info-description">
                    {item.description}
                  </div>

                  {i < claimClusterPreviewInfoList?.length - 1 && (
                    <hr className="divider"></hr>
                  )}
                </div>
              );
            })}
          </div>
          {claimClusterPreviewInfoList?.length >
            DEFAULT_FULLY_VISIBLE_COUNT && (
            <div className="expand-wrapper" onClick={toggleProducts}>
              <span>
                {isExpanded ? t(showLessContent) : t(showMoreContent)}
              </span>
              {isExpanded ? (
                <ArrowUp className="show-more-less-icon" />
              ) : (
                <ArrowDown className="show-more-less-icon" />
              )}
            </div>
          )}
        </div>
      </div>
      {!!buttonLabel && (
        <ButtonGroup.Button
          colorType="primary"
          outline={false}
          onClick={onClickConfirm}>
          {buttonLabel}
        </ButtonGroup.Button>
      )}
    </div>
  );
};
export default ClaimClusterPreviewItem;
