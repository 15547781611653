import './RatingSurvey.scss';

import React, { useContext, useEffect, useState } from 'react';
import appService from '../../../services/app.service';
import usePersistentState from '../../common/usePersistentState';
import ChatGatewayService from '../../../services/chatgateway.service';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import liveChatService from '../../../services/livechat.service';
import { useTranslation } from 'react-i18next';
import ProcessedPage from '../processedPage/ProcessedPage';
import Header from '../../chatAssistant/header/Header';
import StickyHeader from '../../chatAssistant/stickyHeader/StickyHeader';
import RatingSurveyArea, {
  COMPONENT_TYPES,
} from '../../ratingSurvey/ratingSurveyArea/RatingSurveyArea';
import {
  chatbotShortErrorMsg,
  retryButtonText,
  stickySurveySuccessButtonText,
  stickySurveySuccessText,
  stickySurveySuccessTitle,
  surveyErrorServiceError,
} from '../../../i18n/keys';
import classNames from 'classnames';
import ChatBotService from '../../../services/chatbot.service';

const RatingSurvey = () => {
  const { t } = useTranslation();

  const [surveyContent, setSurveyContent] = useState();
  const [formValues, setFormValues] = usePersistentState(
    'stickyRatingSurvey',
    'formValues',
    {}
  );
  const [formTouched, setFormTouched] = usePersistentState(
    'stickyRatingSurvey',
    'formTouched',
    {}
  );
  const [isSendingSurvey, setIsSendingSurvey] = usePersistentState(
    'stickySurvey',
    'isSendingSurvey',
    false
  );

  const chatBotContext = useContext(ChatBotContext);
  const [isSuccess, setIsSuccess] = useState();
  const { isChatBotSurvey } = chatBotContext;

  const getSurveyData = () => {
    if (isChatBotSurvey) {
      ChatBotService.getChatBotSurveyConfig()
        .then(({ data }) => setSurveyContent(data))
        .catch((error) => {
          console.error(error);
          setSurveyContent(false);
        });
    } else {
      ChatGatewayService.getSurvey()
        .then(({ data }) => setSurveyContent(data))
        .catch((error) => {
          console.error(error);
          setSurveyContent(false);
        });
    }
  };

  const handleChangeFormValue = (key, value) =>
    setFormValues({ ...formValues, [key]: value });

  const handleChangeFormTouched = (key, value) =>
    setFormTouched({ ...formTouched, [key]: value });

  const toggleFormTouched = (formItems, isTouched) => {
    const touched = formItems.reduce((acc, formItem) => {
      if (formItem.type === COMPONENT_TYPES.STARS) {
        acc[formItem.id] = {
          rate: isTouched,
          chips: isTouched,
        };
      } else {
        acc[formItem.id] = isTouched;
      }
      return acc;
    }, {});

    setFormTouched({ ...formTouched, ...touched });
  };

  const initializeFormValues = (formItems) => {
    const values = formItems.reduce((acc, formItem) => {
      if (formItem.type === COMPONENT_TYPES.STARS) {
        acc[formItem.id] = {
          rate: formItem.selectedValue,
          chips: [],
        };
      } else {
        acc[formItem.id] = formItem.selectedValue;
      }
      return acc;
    }, {});
    setFormValues({ ...formValues, ...values });
  };

  const initializeFormTouched = (formItems) =>
    toggleFormTouched(formItems, false);

  const preparePostData = (formItems) => {
    return formItems.reduce((acc, formItem) => {
      acc.push({
        key: formItem.id,
        type: formItem.type,
        value: formValues[formItem.id],
      });
      return acc;
    }, []);
  };

  const handleSendSurvey = () => {
    const results = preparePostData(surveyContent.formItems);

    const star = results.find(
      (r) => r.key === 'agentStar' || r.key === 'botStar'
    );

    if (!star || !star.value.rate || star.value.rate === 0) {
      setIsSuccess(undefined);
      return;
    }

    setIsSendingSurvey(true);

    if (isChatBotSurvey) {
      const data = {
        shipmentNumber: appService.getShipmentNumber()
          ? parseInt(appService.getShipmentNumber())
          : null,
        results,
      };
      ChatBotService.sendChatBotSurvey(data)
        .then(() => {
          window.onbeforeunload = null;
          liveChatService.removeSurveySecureKey();
          setIsSuccess(true);
        })
        .catch(() => {
          setIsSuccess(false);
        })
        .finally(() => {
          setIsSendingSurvey(false);
        });
      //TODO: Use ChatBot endpoint to post
    } else {
      const data = {
        orderId: appService.getShipmentNumber()
          ? parseInt(appService.getShipmentNumber())
          : null,
        chatId:
          liveChatService.getSecureKey() ||
          liveChatService.getSurveySecureKey(),
        results,
      };
      ChatGatewayService.sendSurvey(data)
        .then(() => {
          window.onbeforeunload = null;
          liveChatService.removeSurveySecureKey();
          setIsSuccess(true);
        })
        .catch(() => {
          setIsSuccess(false);
        })
        .finally(() => {
          setIsSendingSurvey(false);
        });
    }
  };

  const onCloseAssistant = async () => {
    if (appService.getIsStickyAssistant()) {
      appService.closeStickyPopup();
    } else {
      await appService.exitAssistant();
    }
  };

  const PROCESS_MAP = {
    SUCCESS: {
      title: t(stickySurveySuccessTitle),
      messages: [t(stickySurveySuccessText)],
      buttonText: t(stickySurveySuccessButtonText),
      buttonEvent: onCloseAssistant,
    },
    ERROR: {
      title: t(chatbotShortErrorMsg),
      messages: [t(surveyErrorServiceError)],
      buttonText: t(retryButtonText),
      buttonEvent: handleSendSurvey,
    },
  };

  const renderGetSurveyErrorModal = () => (
    <div className="sticky-survey-success-page">
      <ProcessedPage
        isFailedPage
        title={t(chatbotShortErrorMsg)}
        text={[t(surveyErrorServiceError)]}
        btnText={t(retryButtonText)}
        btnEvent={getSurveyData}
      />
    </div>
  );

  const renderProcessModal = () => {
    let { title, messages, buttonText, buttonEvent } =
      PROCESS_MAP[isSuccess ? 'SUCCESS' : 'ERROR'];
    return (
      <div className="sticky-survey-success-page">
        <ProcessedPage
          isFailedPage={!isSuccess}
          title={title}
          text={messages}
          btnText={buttonText}
          btnEvent={buttonEvent}
        />
      </div>
    );
  };

  useEffect(() => {
    getSurveyData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (surveyContent?.formItems) {
      initializeFormValues(surveyContent.formItems);
      initializeFormTouched(surveyContent.formItems);
    }
    // eslint-disable-next-line
  }, [surveyContent]);

  return (
    <div
      className={classNames([
        'rating-survey',
        { dynamic: appService.isDynamicStylingChannel() },
      ])}>
      {appService.getIsStickyAssistant() || appService.isInternational() ? (
        <StickyHeader
          onCloseAssistant={onCloseAssistant}
          showSubHeader={false}
        />
      ) : (
        <Header isSurveyOpened={true} />
      )}
      {surveyContent === false
        ? renderGetSurveyErrorModal()
        : isSuccess === undefined
        ? surveyContent?.formItems && (
            <RatingSurveyArea
              formItems={surveyContent.formItems}
              formValues={formValues}
              formTouched={formTouched}
              submitButtonText={surveyContent.submitButtonText}
              isSendingSurvey={isSendingSurvey}
              onChange={handleChangeFormValue}
              onTouch={handleChangeFormTouched}
              toggleFormTouched={toggleFormTouched}
              onSubmit={handleSendSurvey}
            />
          )
        : renderProcessModal()}
    </div>
  );
};

export default RatingSurvey;
