import React, { useCallback } from 'react';
import classNames from 'classnames';
import './OrderQuestion.scss';

import deeplinkIcon from '../../../../assets/icons/messages-blue.svg';
import appService from '../../../../services/app.service';
import ChatBotService from '../../../../services/chatbot.service';
import { me, seller, shipmentNo } from '../../../../i18n/keys';
import { useTranslation } from 'react-i18next';

const USER_TYPE = 'USER';
const SELLER_TYPE = 'SELLER';

const OrderQuestion = ({ data }) => {
  const { t } = useTranslation();

  const { items } = data;
  const { messages, allMessagesButton } = items[0];

  const sendEventData = (eventData) => {
    const executeEventRequest = {
      event: eventData.event,
      data: {
        eventLabel: eventData.eventLabel,
        eventAction: eventData.eventAction,
      },
    };

    ChatBotService.execute(executeEventRequest);
  };

  const onAllMessagesClicked = useCallback(() => {
    sendEventData(allMessagesButton);
    appService.navigateOnParentHost(allMessagesButton.url);
  }, [allMessagesButton]);

  const onItemClicked = (item) => () => {
    sendEventData(item);
    appService.navigateOnParentHost(item.deepLink);
  };

  const getLastMessagePrefix = (item) => {
    if (item.lastMessageOwner === USER_TYPE) {
      return t(me) + ': ';
    }

    if (item.lastMessageOwner === SELLER_TYPE) {
      return t(seller) + ': ';
    }

    return '';
  };

  return (
    <div className="welcoming-order-question">
      {messages &&
        messages.map((item, i) => {
          const rowClassName = classNames({
            row: true,
            unread: !item.isSeen,
          });

          return (
            <div
              key={i}
              className="order-question-body"
              onClick={onItemClicked(item)}>
              <span
                className={classNames({
                  dot: true,
                  unread: !item.isSeen,
                })}
              />
              <div className="info-wrapper">
                <div className={rowClassName}>
                  <div className="seller-name">{item.sellerName}</div>
                  <div className="message-date">{item.lastMessageDate}</div>
                </div>
                <div className={rowClassName}>
                  <div className="shipment-number">
                    {t(shipmentNo)}: {item.shipmentNumber}
                  </div>
                </div>
                <div className={rowClassName}>
                  <div className="last-message">
                    {getLastMessagePrefix(item)}
                    {item.lastMessage}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

      <div className="all-messages" onClick={onAllMessagesClicked}>
        <img className="img" src={deeplinkIcon} alt="deeplink_icon" />
        <div className="title">{allMessagesButton.text}</div>
      </div>
    </div>
  );
};

export default OrderQuestion;
