import React, { useEffect, useMemo, useRef, useState } from 'react';
import './EchoLiveChatMessages.scss';
import appService from '../../services/app.service';
import ClientMessage from '../layout/clientMessage/ClientMessage';
import ServerMessage from '../layout/serverMessage/ServerMessage';
import {
  SystemMessageType,
  SystemPayloadType,
} from '@customer-service/echo-messaging-lib';
import EchoMedia from './components/EchoMedia';
import RealtimeTranslationMessage from './components/RealtimeTranslationMessage/RealtimeTranslationMessage';
import { useEchoLiveChat } from './context/echoLiveChatContext';

const EchoLiveChatMessages = ({
  messages,
  isTyping,
  isAgentJoined,
  setShowMediaModal,
  setMediaInfo,
  onReSend,
}) => {
  const { setEmbeddedContent } = useEchoLiveChat();
  const chatMessagesRef = useRef(null);
  const [isFirstScrollComplete, setIsFirstScrollComplete] = useState(false);
  const [isFirstMaxScrollComplete, setIsFirstMaxScrollComplete] =
    useState(false);

  const realTimeMessage = useMemo(
    () =>
      messages?.find(
        (message) =>
          message?.systemPayload &&
          message.systemPayload?.type ===
            SystemMessageType.REALTIME_TRANSLATION_NOTIFICATION &&
          message.systemPayload?.customFields
      ),
    [messages]
  );

  useEffect(() => {
    window.onmessage = function (e) {
      if (
        e.data === 'stickyAssistantMaximized' &&
        appService.getLiveChatScrollPosition() !== 0 &&
        !isFirstMaxScrollComplete
      ) {
        setTimeout(() => {
          scrollToPositionY(appService.getLiveChatScrollPosition());
          setIsFirstMaxScrollComplete(true);
        }, 100);
      }
    };
  }, []);

  useEffect(() => {
    function handleScrollYChange() {
      if (chatMessagesRef.current) {
        const lastScrollY = chatMessagesRef.current.scrollTop;

        if (lastScrollY) {
          appService.setLiveChatScrollPosition(lastScrollY);
        }
      }
    }
    if (appService.getIsStickyAssistant()) {
      window.addEventListener('scroll', handleScrollYChange, true);
      return () => {
        return () => window.removeEventListener('scroll', handleScrollYChange);
      };
    }
  }, []);

  const scrollToPositionY = (scrollPositionY) => {
    chatMessagesRef.current.scrollTo({
      top: scrollPositionY,
    });
  };

  const scrollToBottom = () => {
    if (chatMessagesRef.current && chatMessagesRef.current.scrollTo) {
      chatMessagesRef.current.scrollTo({
        top: chatMessagesRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const listener = (e) => {
      chatMessagesRef.current &&
        chatMessagesRef.current.scrollTo({
          top: chatMessagesRef.current.scrollTop + e.detail.keyboardHeight,
          behavior: 'smooth',
        });
    };

    document.addEventListener('onIOSKeyboardOpen', listener);

    return () => {
      document.removeEventListener('onIOSKeyboardOpen', listener);
    };
  }, []);

  useEffect(() => {
    if (!isFirstScrollComplete) {
      scrollToPositionY(appService.getLiveChatScrollPosition());
      setIsFirstScrollComplete(true);
    } else {
      scrollToBottom();
    }
  }, [messages, isTyping]);

  const handleIconShow = (messages, index) => {
    if (index === 0) {
      return true;
    } else if (messages[index - 1].direction === 'incoming') {
      return false;
    }
    return true;
  };

  const onClickPreview = (mediaInfo) => {
    setMediaInfo(mediaInfo);
    setTimeout(() => {
      setShowMediaModal(true);
    }, 0);
  };

  return (
    <div className="live-chat-messages-container">
      {realTimeMessage && (
        <div className="realtime-translation-popup-container">
          <RealtimeTranslationMessage
            customFields={realTimeMessage?.systemPayload?.customFields}
          />
        </div>
      )}
      <div
        className="live-chat-messages"
        ref={chatMessagesRef}
        data-testid="echo-ive-chat-messages">
        {messages &&
          messages
            .filter(
              (message) =>
                message?.systemPayload?.type !==
                SystemMessageType.REALTIME_TRANSLATION_NOTIFICATION
            )
            .map((message, index) => {
              if (
                message.direction === 'outgoing' &&
                message.systemPayload &&
                message.systemPayload.type === SystemPayloadType.ATTACHMENT
              ) {
                return (
                  <EchoMedia
                    message={message}
                    key={index}
                    onPreview={onClickPreview}
                    onReSend={onReSend}
                  />
                );
              } else if (message.direction === 'outgoing') {
                return (
                  <ClientMessage
                    timestamp={message.createdAt}
                    requestMessage={message.message}
                    isLiveChat={true}
                    isAgentJoined={isAgentJoined}
                    key={index}
                  />
                );
              } else if (message.direction === 'incoming') {
                return (
                  <ServerMessage
                    timestamp={message.createdAt}
                    message={message.message}
                    typing={false}
                    isLiveChat={true}
                    isEcho={true}
                    key={index}
                    isIconShow={handleIconShow(messages, index)}
                    setEmbeddedContent={setEmbeddedContent}
                  />
                );
              }
              return null;
            })}
        {isTyping && (
          <ServerMessage
            message=""
            typing
            key="typing"
            isLiveChat={true}
            isIconShow={true}
          />
        )}
      </div>
    </div>
  );
};

export default EchoLiveChatMessages;
