import React, { useState } from 'react';
import '../OrderModalDetail.scss';
import OrderDetailLastOperation from '../orderDetailLastOperation/OrderDetailLastOperation';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  claimSimulationPreviewQuantity,
  currencyText,
  deliveryDateText,
  deliveryNumberText,
  deliveryTimeText,
} from '../../../../../../i18n/keys';

import utils from '../../../../../../utils';
import { Divider } from 'antd';
import appService from '../../../../../../services/app.service';
import {
  ORDER_BOX_VERSION_1,
  ORDER_BOX_VERSION_2,
} from '../../../../../../constants/chatAssistantConstant';
import StatusIcon from '../../../../../chatAssistant/icons/statusIcon';
import {
  CANCELLED,
  CREATED,
  SUCCESS,
  WARN_YELLOW,
} from '../../../../../../constants';
import PriceLabel from '../../../../../common/currency/priceLabel';

const OrderDetailShipment = ({ order }) => {
  const { t } = useTranslation();
  const [imageFailed, setImageFailed] = useState(false);

  const getOrderDeliveryInfo = (shipment) => {
    return (
      <div className="order-detail-shipment-estimated-date">
        {shipment.estimatedDeliveryDate !== '' && (
          <div className="text">
            {shipment.status.typeForUser === 'Delivered'
              ? t(deliveryDateText)
              : t(deliveryTimeText)}
          </div>
        )}
        <div className="value">{shipment.estimatedDeliveryDate}</div>
      </div>
    );
  };

  const fallbackImage = () => {
    setImageFailed(true);
  };

  const renderImages = (imageUrls) => {
    if (!imageUrls || imageUrls.length <= 0) return;

    return utils.renderShipmentImagesForAvatarComponent(
      [imageUrls[0]],
      fallbackImage,
      imageFailed
    );
  };

  const getPriceInfo = (product) => {
    return (
      <div
        className={classNames([
          'order-detail-shipment-product-info__cp-price__buy',
          {
            dynamic: appService.isDynamicStylingChannel(),
          },
        ])}>
        <PriceLabel price={product.sellingPrice} currency={product.currency} />
      </div>
    );
  };
  return (
    <>
      {order.shipmentPreviewList &&
        order.shipmentPreviewList.map((shipment, i) => (
          <React.Fragment key={i}>
            {shipment.lastOperationMessage && (
              <OrderDetailLastOperation
                lastOperationMessages={shipment.lastOperationMessage}
              />
            )}
            <div className="order-detail">
              <div className="order-detail-wrapper detail">
                <div className="order-detail-shipment">
                  <div className="detail-shipment-info">
                    <div className="order-detail-shipment-info">
                      <div className="order-detail-shipment-info__wrapper">
                        <div className="order-detail-shipment-info__delivery">
                          <div className="text">{t(deliveryNumberText)}</div>
                          <div className="value">
                            #{shipment.shipmentNumber}
                          </div>
                        </div>
                        {getOrderDeliveryInfo(shipment)}
                      </div>
                    </div>
                    {appService.getOrderBoxVersion() ===
                      ORDER_BOX_VERSION_1 && (
                      <div className="status-wrapper">
                        <StatusIcon
                          name={(
                            shipment.status.typeForUser || ''
                          ).toLowerCase()}
                          className={classNames({
                            'status-icon': true,
                            'status-icon--success':
                              shipment.status.type === SUCCESS ||
                              shipment.status.type === CREATED,
                            'status-icon--warn-yellow':
                              shipment.status.type === WARN_YELLOW,
                            'status-icon--cancelled':
                              shipment.status.type === CANCELLED,
                          })}
                        />
                        <div
                          className={classNames({
                            'order-shipment-status': true,
                            success: shipment.status.type === 'Success',
                            warn: shipment.status.type === 'Warn',
                            cancelled: shipment.status.type === 'Cancelled',
                            warn_yellow: shipment.status.type === 'Warn Yellow',
                          })}>
                          {shipment.status.text}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="order-detail-shipment-products">
                    {shipment.products &&
                      shipment.products.map((product, i) => (
                        <div className="shipment-product-wrapper">
                          <div className="shipment-product" key={i}>
                            <div className="detail">
                              {appService.getOrderBoxVersion() ===
                                ORDER_BOX_VERSION_2 &&
                                product.status && (
                                  <div className="product-status-wrapper">
                                    <StatusIcon
                                      name={(
                                        product.status.typeForUser || ''
                                      ).toLowerCase()}
                                      className={classNames({
                                        'status-icon': true,
                                        'status-icon--success':
                                          product.status.type === SUCCESS ||
                                          product.status.type === CREATED,
                                        'status-icon--warn-yellow':
                                          product.status.type === WARN_YELLOW,
                                        'status-icon--cancelled':
                                          product.status.type === CANCELLED,
                                      })}
                                    />
                                    <div
                                      className={classNames({
                                        'shipment-product-status': true,
                                        success:
                                          product.status.type === 'Success',
                                        warn: product.status.type === 'Warn',
                                        cancelled:
                                          shipment.status.type === 'Cancelled',
                                        warn_yellow:
                                          shipment.status.type ===
                                          'Warn Yellow',
                                      })}>
                                      {product.status.text}
                                    </div>
                                  </div>
                                )}
                              {product.name && (
                                <div className="product-name">
                                  <div className="value">{product.name}</div>
                                </div>
                              )}

                              {appService.getOrderBoxVersion() ===
                                ORDER_BOX_VERSION_2 &&
                                product.quantity && (
                                  <div className="product-quantity">
                                    <div className="text">
                                      {t(claimSimulationPreviewQuantity)}{' '}
                                    </div>
                                    <div className="value">
                                      {product.quantity}
                                    </div>
                                  </div>
                                )}
                              <div className="order-detail-shipment-product-info__cp">
                                <div className="order-detail-shipment-product-info__cp-price">
                                  {getPriceInfo(product)}
                                </div>
                              </div>
                            </div>
                            <div className="product-image-container">
                              <div className="body">
                                <div className="details">
                                  <div className="avatars">
                                    {renderImages(product.images)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Divider />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
    </>
  );
};

OrderDetailShipment.prototype = {
  order: PropTypes.object.isRequired,
};

export default OrderDetailShipment;
