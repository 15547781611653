import React, { useMemo, useRef } from 'react';
import FileImagePreview from './FileImagePreview';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import {
  fileUploadVideoErrorText,
  imageCountErrorText,
  imageFormatMismatchErrorText,
  imageMaxSizeErrorText,
  imageReadError,
  fileEmptyError,
  fileNameNotValidError,
  imageUploadError,
} from '../../../i18n/keys';
import './FileInput.scss';
import Utils from '../../../utils';
import chatGatewayService from '../../../services/chatgateway.service';
import usePersistentState from '../../common/usePersistentState';
import classNames from 'classnames';
import appService from '../../../services/app.service';
import { UPLOAD_VERSION_1 } from '../../../constants/chatAssistantConstant';
import AttachmentIcon from '../../../assets/icons/attachment-icon.svg';
import CloseIcon from '../../../assets/icons/close-circle-v2.svg';
import { addExtraImage } from '../../../i18n/keys';
import MediaCenterService from '../../../services/mediacenter.service';

const FileInput = ({
  id,
  placeholder,
  onImagesChange,
  onDocumentChange,
  onVideoChange,
  onError,
  isRequired,
  maxFileCount = 1,
  useCdn = false,
  accept = 'image/jpg,image/jpeg,image/png',
  documentTypeValidationMessage = undefined,
  maxFileSize = 5,
  preview = true,
  uploadVersion = UPLOAD_VERSION_1,
  fileId,
  isAsteriskShown = true,
  compressImage = true,
  lang = undefined,
}) => {
  const { i18n, t } = useTranslation();
  const fixedTranslator = i18n.getFixedT(lang ? lang : i18n.language);
  const isVideoUpload = useMemo(() => accept.includes('video'), [accept]);
  const [images, setImages] = usePersistentState(id, 'images', []);
  const [videos, setVideos] = usePersistentState(id, 'videos', []);
  const [isLoading, setIsLoading] = usePersistentState(id, 'isLoading', false);
  const containerRef = useRef();

  const prepareLazyImages = (images) => {
    let lazyImages = [];

    images.map((image) =>
      lazyImages.push({
        url: image.response.data,
        name: image.name,
        objectUrl: image.url,
        file: {
          type: image.file.type,
          size: image.file.size,
          name: image.file.name,
        },
        response: {
          data: image.response.data,
        },
      })
    );
    return lazyImages;
  };

  const onFileInputAreaClicked = (e) => {
    if (!containerRef.current) return;
    const fileInputElement =
      containerRef.current.querySelector('.file-input-input');
    // clear value
    fileInputElement.value = '';
    // trigger file select
    fileInputElement.click();
  };

  const onImageDelete = (item) => {
    const newImages = images.filter((image) => image.url !== item.url);
    setImages(newImages);

    if (onError) onError([]);
    if (onImagesChange) onImagesChange(newImages);
    if (onDocumentChange) onDocumentChange(undefined, newImages);
  };

  const onVideosDelete = (e) => {
    e.preventDefault();
    setVideos([]);
    if (onError) onError([]);
    if (isVideoUpload && onVideoChange) onVideoChange([]);
  };

  const validateMimeType = (file) => {
    return accept.split(',').includes(file.type);
  };

  const validateSize = (file) => {
    return file.size < 1024 * 1024 * maxFileSize;
  };

  const validateFileIsExist = (file) => {
    return file;
  };

  const validateFileNameIsExist = (file) => {
    return file.name;
  };

  const validateFileEmpty = (file) => {
    return file.size === 0;
  };

  const uploadVideoToMediaCenter = async (file) => {
    const res = await MediaCenterService.uploadVideo(file);
    return res.videoSourceURL;
  };

  const onFileInputChange = async (e) => {
    const errors = [];
    const newImages = [];

    for (let fileInput of e.target.files) {
      if (
        !isVideoUpload &&
        compressImage &&
        Utils.isCompressableImage(fileInput, maxFileSize)
      ) {
        setIsLoading(true);
        fileInput = await Utils.getCompressedImage(fileInput, maxFileSize);
      }

      if (!validateFileIsExist(fileInput)) {
        errors.push(fixedTranslator(imageReadError));
      } else if (!validateFileNameIsExist(fileInput)) {
        errors.push(fixedTranslator(fileNameNotValidError));
      } else if (validateFileEmpty(fileInput)) {
        errors.push(fixedTranslator(fileEmptyError));
      } else if (!validateSize(fileInput)) {
        errors.push(
          fixedTranslator(imageMaxSizeErrorText, { size: maxFileSize })
        );
      } else if (!validateMimeType(fileInput)) {
        errors.push(
          documentTypeValidationMessage ||
            fixedTranslator(imageFormatMismatchErrorText)
        );
      } else {
        newImages.push({
          url: URL.createObjectURL(fileInput),
          name: fileInput.name,
          file: fileInput,
        });
      }
    }

    if (newImages.length + images.length > maxFileCount) {
      errors.push(
        fixedTranslator(imageCountErrorText, { count: maxFileCount })
      );
    } else {
      if (isVideoUpload) {
        // Video Upload to MediaCenter
        setIsLoading(true);
        const requests = newImages.map((image) => {
          const uploadAndMergeResponse = async () => {
            const response = await uploadVideoToMediaCenter(image.file);
            return {
              response,
            };
          };
          return uploadAndMergeResponse();
        });
        try {
          const uploadResponses = await Promise.all(requests);
          const mergedVideos = [...uploadResponses];
          setVideos(mergedVideos);
          if (onVideoChange) onVideoChange(mergedVideos);
        } catch (error) {
          errors.push(fixedTranslator(fileUploadVideoErrorText));
        } finally {
          setIsLoading(false);
        }
      } else {
        // Upload to Chat CDN
        if (useCdn) {
          setIsLoading(true);
          const requests = newImages.map((image) => {
            const uploadAndMergeResponse = async () => {
              const response = await chatGatewayService.sendFile(
                image.file,
                '',
                fileId,
                uploadVersion
              );
              return {
                ...image,
                response: response.data,
              };
            };
            return uploadAndMergeResponse();
          });

          try {
            const uploadResponses = await Promise.all(requests);
            const mergedImages = [...images, ...uploadResponses];
            const lazyMergedImages = prepareLazyImages(mergedImages);
            setImages(lazyMergedImages);
            if (onImagesChange) onImagesChange(mergedImages);
            if (onDocumentChange) onDocumentChange(newImages[0], mergedImages);
          } catch (error) {
            errors.push(fixedTranslator(imageUploadError));
          } finally {
            setIsLoading(false);
          }
        } else {
          const mergedImages = [...images, ...newImages];
          const lazyMergedImages = prepareLazyImages(mergedImages);
          setImages(lazyMergedImages);
          if (onImagesChange) onImagesChange(mergedImages);
          if (onDocumentChange) onDocumentChange(newImages[0], mergedImages);
        }
      }
    }
    if (onError) onError(errors);
  };

  return (
    <div className="file-input">
      <Spin size="small" spinning={isLoading}>
        <div className="file-input-container" ref={containerRef}>
          <div
            className={classNames({
              'file-input-area': true,
              'file-input-area-uploaded':
                images.length && images.length === maxFileCount,
              international: appService.isInternational(),
            })}
            onClick={onFileInputAreaClicked}>
            {isRequired &&
              isAsteriskShown &&
              !(isVideoUpload && videos?.length > 0) && (
                <span className="assistant-mandatory">*</span>
              )}
            <div className="file-input-placeholder">
              {isVideoUpload && videos?.length > 0
                ? videos[0]?.response || ''
                : placeholder}
            </div>
          </div>
          {isVideoUpload && videos?.length > 0 ? (
            <img
              src={CloseIcon}
              className="attachment-icon"
              alt="attachment-icon"
              width={16}
              height={16}
              onClick={onVideosDelete}
            />
          ) : (
            <img
              src={AttachmentIcon}
              className="attachment-icon"
              alt="attachment-icon"
              width={16}
              height={16}
            />
          )}

          <input
            type="file"
            name="files"
            className="file-input-input"
            multiple={maxFileCount > 1}
            accept={accept}
            onChange={onFileInputChange}
          />
        </div>
      </Spin>
      {images?.length > 0 && (
        <div className="image-previews">
          {images.length > 0 && images.length < maxFileCount && (
            <div
              className="placeholder-preview"
              onClick={onFileInputAreaClicked}>
              <img
                src={AttachmentIcon}
                alt="attachment-icon"
                className="attachment-icon"
              />
              <span> {t(addExtraImage)}</span>
            </div>
          )}
          {images.map((item, index) => {
            return (
              <FileImagePreview
                key={'preview' + index}
                preview={preview}
                item={item}
                onDeleteClicked={onImageDelete}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FileInput;
