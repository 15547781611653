import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import ChatBotContext from './chatbotContext';
import ChatBotReducer from './chatbotReducer';
import chatBotService from '../../services/chatbot.service';
import utils from '../../utils';
import {
  CHATBOT_ERROR,
  CHATBOT_REQUEST,
  ERROR_CHATBOT_REQUEST,
  SET_ANNOUNCEMENT,
  SET_CHATBOT_SURVEY,
  SET_CID_FOR_CLICK_EVENTS,
  SET_CLICK_EVENT_ORDER,
  SET_DELIVERED_PRODUCT_PROBLEM_IMAGE_INDEX,
  SET_IS_REQUESTING,
  SET_IS_STATEMACHINE_ID_EXIST,
  SET_LAST_ACTIVE_SHIPMENT_COMPONENT_INDEX,
  SET_NOTIFICATIONS,
  SET_SHOW_LANGUAGE_SUPPORT,
  SET_START_REQUEST,
  SET_TYPING,
  USER_ANSWER,
  CLEAR_CHAT,
  SET_PROACTIVE_INFO,
} from './chatbotTypes';
import { chatbotErrorMsg } from '../../i18n/keys';
import { SINGLE_SELECT, UNAUTHORIZED_HTTP_STATUS_CODE } from '../../constants';
import appService from '../../services/app.service';
import {
  APP_VERSION,
  BUILD_NAME,
  CHANNEL_ID,
  CHANNEL_ID_WITHOUT_DEFAULT,
  EVENT_NAME,
  GENERIC_EVENT_NAME,
  IS_GUEST_USER,
  LANGUAGE,
  PREV_STATE_MACHINE_ID,
  ORDER_CHANNEL_ID,
  PID_NAME,
  PLATFORM_NAME,
  SEGMENT_ID,
  SELLER_SINGLE_USE_TOKEN,
  SELLER_STICKY_ASSISTANT,
  SID_NAME,
  STOREFRONT_ID,
  SUB_CHANNEL,
  TOKEN_NAME,
  VERSION,
  ASSISTANT_PROPERTIES,
  LIVE_CHAT_CONFIG,
  APP_NAME,
  ORDER_ID,
  SHIPMENT_NUMBER,
} from '../../constants/sessionStorageConstants';
import { GET_SHIPMENT_DETAIL } from '../../constants/chatAssistantConstant';

const initChatBotReducer = (initialState) => {
  const stateStr = window.sessionStorage.getItem('state');

  let persistedState = {};
  if (stateStr) {
    persistedState = JSON.parse(stateStr);
  }

  return { ...initialState, ...persistedState };
};

const ChatBotState = (props) => {
  const initialState = {
    chat: [],
    chatBotUserAnswers: [],
    typing: false,
    currentChatIndex: 0,
    isRequesting: false,
    totalErrorCount: 0,
    cid: 0,
    clickEventOrder: 0,
    footer: [],
    isChatBotSurvey: false,
    isOpenNotification: false,
    lastActiveShipmentComponentIndex: 0,
    error: false,
    notifications: {},
    isStartRequest: false,
    notificationActiveTabName: null,
    deliveredProductProblemImageIndex: -1,
    isStateMachineIdExist: false,
    isShowLanguage: false,
    proactiveInfo: null,
  };

  const [state, dispatch] = useReducer(
    ChatBotReducer,
    initialState,
    initChatBotReducer
  );
  const { t } = useTranslation();

  const requestToChatBot = async (request) => {
    setTyping();
    setIsRequesting(true);
    setChoiceSelected(request.requestItem, true);
    return chatBotService
      .execute(request)
      .then((res) => {
        if (res?.data?.stateMachineId) {
          setIsStateMachineIdExist(true);
        } else {
          setIsStateMachineIdExist(false);
        }

        dispatch({ type: CHATBOT_REQUEST, payload: res.data });
      })
      .catch((err) => {
        if (
          request.event === 'START' &&
          err.response &&
          err.response.status === UNAUTHORIZED_HTTP_STATUS_CODE
        ) {
          dispatch({ type: ERROR_CHATBOT_REQUEST });
        }
        setErrorMessage();
        setChoiceSelected(request, false);
      });
  };

  const setLastActiveShipmentComponentIndex = (componentIndex) => {
    dispatch({
      type: SET_LAST_ACTIVE_SHIPMENT_COMPONENT_INDEX,
      payload: componentIndex,
    });
  };

  const setDeliveredProductProblemImageIndex = (imageIndex) => {
    dispatch({
      type: SET_DELIVERED_PRODUCT_PROBLEM_IMAGE_INDEX,
      payload: imageIndex,
    });
  };

  const setIsStateMachineIdExist = async (isStateMachineIdExist) => {
    dispatch({
      type: SET_IS_STATEMACHINE_ID_EXIST,
      payload: isStateMachineIdExist,
    });
  };

  const setIsChatBotSurvey = async (isSurveyOpen) => {
    dispatch({ type: SET_CHATBOT_SURVEY, payload: isSurveyOpen });
  };

  const setNotifications = async (notifications) => {
    dispatch({ type: SET_NOTIFICATIONS, payload: notifications });
  };

  const setProactiveInfo = async (proactiveInfo) => {
    dispatch({ type: SET_PROACTIVE_INFO, payload: proactiveInfo });
  };

  const setIsStartRequest = async (isStartRequest) => {
    dispatch({ type: SET_START_REQUEST, payload: isStartRequest });
  };

  const setShowLanguage = async (isShowLanguageSupport) => {
    dispatch({
      type: SET_SHOW_LANGUAGE_SUPPORT,
      payload: isShowLanguageSupport,
    });
  };

  const setIsOpenNotification = async (
    isOpenNotification,
    notificationActiveTabName
  ) => {
    dispatch({
      type: SET_ANNOUNCEMENT,
      payload: { isOpenNotification, notificationActiveTabName },
    });
  };

  const requestToServer = async (request) => {
    setIsRequesting(true);
    setChoiceSelected(request.requestItem, true);
    try {
      let res = await chatBotService.execute(request);
      dispatch({ type: CHATBOT_REQUEST, payload: res.data });
    } catch (e) {
      setErrorMessage();
      setChoiceSelected(request, false);
    }
  };

  const requestForDeepLink = async (request) => {
    try {
      await chatBotService.execute(request);
    } catch (e) {
      setErrorMessage();
    }
  };

  const setChoiceSelected = (requestItem, isSelected) => {
    if (requestItem) {
      requestItem.selected = isSelected;
      requestItem.date = utils.getCurrentDateTime();
      dispatch({ type: USER_ANSWER, payload: requestItem });
    }
  };

  const setCidForClickEvents = (cid) => {
    dispatch({ type: SET_CID_FOR_CLICK_EVENTS, payload: cid });
  };

  const setClickEventOrder = (index) => {
    dispatch({ type: SET_CLICK_EVENT_ORDER, payload: index });
  };

  const setIsRequesting = (isRequesting) => {
    dispatch({ type: SET_IS_REQUESTING, payload: isRequesting });
  };

  const selectOrder = async (request) => {
    try {
      await requestToChatBot(request);
      appService.setShipmentNumber(request.data.shipmentNumber);
      appService.setOrderNumber(request.data.orderNumber);
    } catch (e) {
      setErrorMessage();
    }
  };

  const setTyping = () => dispatch({ type: SET_TYPING });

  const setErrorMessage = () => {
    dispatch({
      type: CHATBOT_ERROR,
      payload: {
        answers: [
          {
            displayType: SINGLE_SELECT,
            message: t(chatbotErrorMsg),
          },
        ],
      },
    });
  };

  const clearChat = async () => {
    dispatch({ type: CLEAR_CHAT });
  };

  const clearChatHistory = async () => {
    clearChat();
    setNotifications({});
    setIsStartRequest(false);
    setIsStateMachineIdExist(false);

    const keysToNotRemove = [
      TOKEN_NAME,
      SEGMENT_ID,
      PLATFORM_NAME,
      IS_GUEST_USER,
      VERSION,
      PREV_STATE_MACHINE_ID,
      LANGUAGE,
      SELLER_STICKY_ASSISTANT,
      EVENT_NAME,
      SID_NAME,
      PID_NAME,
      SELLER_SINGLE_USE_TOKEN,
      CHANNEL_ID,
      CHANNEL_ID_WITHOUT_DEFAULT,
      SUB_CHANNEL,
      GENERIC_EVENT_NAME,
      BUILD_NAME,
      APP_VERSION,
      STOREFRONT_ID,
      ORDER_CHANNEL_ID,
      ASSISTANT_PROPERTIES,
      LIVE_CHAT_CONFIG,
      APP_NAME,
    ];

    if (GET_SHIPMENT_DETAIL === appService.getEventName()) {
      keysToNotRemove.push(ORDER_ID);
      keysToNotRemove.push(SHIPMENT_NUMBER);
    }

    clearSessionStorageItems(keysToNotRemove);
  };

  function clearSessionStorageItems(keysToNotRemove) {
    const items = window.customStorage.getItems();
    items.forEach(function (key) {
      if (!keysToNotRemove.includes(key)) {
        window.customStorage.removeItem(key);
      }
    });
  }

  return (
    <ChatBotContext.Provider
      value={{
        chat: state.chat,
        typing: state.typing,
        isRequesting: state.isRequesting,
        currentChatIndex: state.currentChatIndex,
        chatBotUserAnswers: state.chatBotUserAnswers,
        error: state.error,
        totalErrorCount: state.totalErrorCount,
        cid: state.cid,
        clickEventOrder: state.clickEventOrder,
        footer: state.footer,
        isChatBotSurvey: state.isChatBotSurvey,
        deliveredProductProblemImageIndex:
          state.deliveredProductProblemImageIndex,
        lastActiveShipmentComponentIndex:
          state.lastActiveShipmentComponentIndex,
        isOpenNotification: state.isOpenNotification,
        notifications: state.notifications,
        proactiveInfo: state.proactiveInfo,
        isStartRequest: state.isStartRequest,
        notificationActiveTabName: state.notificationActiveTabName,
        isStateMachineIdExist: state.isStateMachineIdExist,
        isShowLanguage: state.isShowLanguage,
        setIsOpenNotification: setIsOpenNotification,
        setIsChatBotSurvey: setIsChatBotSurvey,
        setNotifications: setNotifications,
        setProactiveInfo: setProactiveInfo,
        setIsStartRequest: setIsStartRequest,
        requestToChatBot,
        setIsRequesting,
        selectOrder,
        setCidForClickEvents,
        setClickEventOrder,
        requestToServer,
        requestForDeepLink,
        setLastActiveShipmentComponentIndex,
        setDeliveredProductProblemImageIndex,
        setShowLanguage: setShowLanguage,
        setChat: clearChat,
        clearChatHistory,
      }}>
      {props.children}
    </ChatBotContext.Provider>
  );
};

ChatBotState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChatBotState;
