class stickyClientAdapter {
  static setup(event_id, eventData, callBack) {
    window.addEventListener('message', function handleSetup(e) {
      if (e.data) {
        if (e.data.event_id === event_id) {
          callBack(e.data);
        }
        this.removeEventListener('message', handleSetup);
      }
    });

    window.top.postMessage(eventData, '*');
  }

  static getCookieFromTopHost(cookieKeys) {
    return new Promise((resolve, reject) => {
      this.setup(
        'returnCookieFromTopHostEvent',
        {
          event_id: 'getCookieFromTopHostEvent',
          data: {
            cookieKeys: cookieKeys,
          },
        },
        (event) => {
          if (event.data && event.data.value) {
            resolve(event.data.value);
          } else {
            reject(new Error("Can't get userInfo from parent"));
          }
        }
      );
    });
  }

  static removeCookieFromTopHost(cookies) {
    const eventData = {
      event_id: 'removeCookieFromTopHostEvent',
      data: {
        cookies: cookies,
      },
    };
    window.top.postMessage(eventData, '*');
  }
}

export default stickyClientAdapter;
