import React from 'react';

import './CallButton.scss';
import ChatBotService from '../../../services/chatbot.service';
import Bubble from '../../layout/bubble/Bubble';

const CallButton = ({ componentData }) => {
  const { iconUrl, phone, text, event, label, eventLabel } = componentData;

  const onCallClick = () => {
    if (event) {
      const executeEventRequest = {
        event: event,
        data: {
          phoneNumber: phone,
          actionEventLabel: eventLabel,
        },
      };

      ChatBotService.execute(executeEventRequest);
    }
  };

  return label ? (
    <div className="call-button">
      <Bubble title={label} isBordered={false}>
        <a
          className="call-button"
          data-testid={'call-button-telNo'}
          onClick={() => onCallClick()}
          href={`tel:${phone}`}>
          <div className="content-wrapper">
            {iconUrl && (
              <img
                className="img"
                src={iconUrl}
                alt="icon"
                data-testid={'call-button-img'}
              />
            )}
            <span> {text}</span>
          </div>
        </a>
      </Bubble>
    </div>
  ) : (
    <a
      className="call-button"
      data-testid={'call-button-telNo'}
      onClick={() => onCallClick()}
      href={`tel:${phone}`}>
      <div className="content-wrapper">
        {iconUrl && (
          <img
            className="img"
            src={iconUrl}
            alt="icon"
            data-testid={'call-button-img'}
          />
        )}
        <span> {text}</span>
      </div>
    </a>
  );
};

export default CallButton;
