import React, { useContext } from 'react';
import StartSelectedIcon from '../../../assets/icons/star-selected.svg';
import StartUnSelectedIcon from '../../../assets/icons/star-unselected.svg';
import RequestingIcon from '../../../assets/icons/refresh-page-option.svg';
import angryRate from '../../../assets/icons/angry.svg';
import happyRate from '../../../assets/icons/happy.svg';
import sadRate from '../../../assets/icons/sad.svg';
import inloveRate from '../../../assets/icons/in-love.svg';
import confusedRate from '../../../assets/icons/confused.svg';
import emojiDot from '../../../assets/icons/emoji-dot.svg';

import { useTranslation } from 'react-i18next';
import chatGatewayService from '../../../services/chatgateway.service';
import appService from '../../../services/app.service';
import liveChatService from '../../../services/livechat.service';
import { ModalModel, SendSurveyRequest } from '../../../constants/models';
import CommonContext from '../../../context/common/commonContext';
import classNames from 'classnames';
import './StickySurvey.scss';
import StickyHeader from '../../chatAssistant/stickyHeader/StickyHeader';
import {
  inputLanguageNotSupported,
  stickySurveyCommentMessage,
  stickySurveyRateMessage,
  stickySurveySuccessButtonText,
  stickySurveySuccessText,
  stickySurveySuccessTitle,
  stickySurveyTitle,
  surveyErrorModalTitle,
  surveyErrorRatingEmpty,
  surveyErrorServiceError,
  surveyMessage,
  surveyRateButton,
  surveySuccessModalTitle,
  trendyolAsistantSurveyMessage,
} from '../../../i18n/keys';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import CustomModal from '../../layout/customModal/CustomModal';
import { MODAL_ERROR } from '../../../constants/modalTypes';
import ProcessedPage from '../processedPage/ProcessedPage';
import usePersistentState from '../../common/usePersistentState';
import Utils from '../../../utils';
import MessageBox from '../../common/Modal/MessageBox';

const StickySurvey = () => {
  const { t } = useTranslation();
  const [comment, setComment] = usePersistentState(
    'stickySurvey',
    'comment',
    ''
  );

  const [rating, setRating] = usePersistentState('stickySurvey', 'rating', 0);
  const [lastRating, setLastRating] = usePersistentState(
    'stickySurvey',
    'lastRating',
    0
  );
  const [isRateClicked, setIsRateClicked] = usePersistentState(
    'stickySurvey',
    'isRateClicked',
    false
  );
  const [isSendingSurvey, setIsSendingSurvey] = usePersistentState(
    'stickySurvey',
    'isSendingSurvey',
    false
  );
  const [isSuccessfullyCompleted, setSuccessfullyCompleted] =
    usePersistentState('stickySurvey', 'isSuccessfullyCompleted', false);

  const commonContext = useContext(CommonContext);
  const {
    setErrors,
    clearErrors,
    setSuccesses,
    errors,
    successes,
    setIsSendSurveySuccess,
  } = commonContext;
  const chatBotContext = useContext(ChatBotContext);
  const { isChatBotSurvey } = chatBotContext;

  const surveyMessageText = isChatBotSurvey
    ? getAssistatSurveyMessage()
    : t(surveyMessage);

  function getAssistatSurveyMessage() {
    return appService.getAssistantSurveyMessage() === ''
      ? t(trendyolAsistantSurveyMessage)
      : appService.getAssistantSurveyMessage();
  }

  const onMouseLeave = () => {
    !isRateClicked ? setLastRating(0) : setLastRating(rating);
  };

  const onMouseOver = (number) => {
    setLastRating(number);
  };

  const onRatingClick = () => {
    setIsRateClicked(true);
    setRating(lastRating);
  };

  const onCloseAssistant = async () => {
    if (appService.getIsStickyAssistant()) {
      appService.closeStickyPopup();
    } else {
      await appService.exitAssistant();
    }
  };

  const onCloseErrorModal = async () => {
    setErrors(new ModalModel('', []));
  };

  const onCommentChange = (comment) => {
    if (Utils.isInputArabic(comment)) {
      MessageBox.Error({
        msg: t(inputLanguageNotSupported),
      });
      return;
    }
    setComment(comment);
  };

  const createSurveyRequest = () => {
    let orderId = null;
    const surveySecureKey = liveChatService.getSurveySecureKey();
    const secureKey = liveChatService.getSecureKey() || surveySecureKey;
    if (appService.getShipmentNumber()) {
      orderId = parseInt(appService.getShipmentNumber());
    }
    return new SendSurveyRequest(secureKey, rating, comment, orderId);
  };

  const sendSurvey = () => {
    if (rating === 0 && !isSendingSurvey) {
      const errorModal = new ModalModel(t(surveyErrorModalTitle), [
        t(surveyErrorRatingEmpty),
      ]);
      setErrors(errorModal);
    } else {
      clearErrors();
      if (isChatBotSurvey) {
        chatBotSurveySend();
      } else {
        if (isSendingSurvey === true) {
          return;
        }

        let sendSurveyRequest = createSurveyRequest();
        setIsSendingSurvey(true);
        chatGatewayService
          .sendSurvey(sendSurveyRequest)
          .then(() => {
            window.onbeforeunload = null;
            const successModal = new ModalModel(t(surveySuccessModalTitle));
            setSuccesses(successModal);
            setIsSendSurveySuccess(true);
            setIsSendingSurvey(false);
            liveChatService.removeSurveySecureKey();
          })
          .catch(() => {
            const errorModal = new ModalModel(t(surveyErrorModalTitle), [
              t(surveyErrorServiceError),
            ]);
            setErrors(errorModal);
            setIsSendingSurvey(false);
          });
      }
    }
  };

  const chatBotSurveySend = () => {
    setIsSendingSurvey(true);
    appService
      .sendChatBotSurvey(rating, comment)
      .then(() => {
        window.onbeforeunload = null;
        const successModal = new ModalModel(t(surveySuccessModalTitle));
        setSuccesses(successModal);
        setIsSendSurveySuccess(true);
        setSuccessfullyCompleted(true);
      })
      .catch(() => {
        const errorModal = new ModalModel(t(surveyErrorModalTitle), [
          t(surveyErrorServiceError),
        ]);
        setErrors(errorModal);
      })
      .finally(() => {
        setIsSendingSurvey(false);
      });
  };

  if (successes.title || isSuccessfullyCompleted) {
    return (
      <div className="sticky-survey-success-page">
        <StickyHeader
          onCloseAssistant={onCloseAssistant}
          showSubHeader={false}
        />
        <ProcessedPage
          title={t(stickySurveySuccessTitle)}
          text={t(stickySurveySuccessText)}
          btnText={t(stickySurveySuccessButtonText)}
          btnEvent={onCloseAssistant}
          createTicketSuccessPageBtn={appService.isInternational()}
        />
      </div>
    );
  } else {
    return (
      <div className="sticky-survey">
        <StickyHeader
          onCloseAssistant={onCloseAssistant}
          showSubHeader={false}
        />
        <div className="sticky-survey-rate-area">
          <div className="sticky-survey-rate-area__message">
            {surveyMessageText}
          </div>
          <div className="sticky-survey-rate-area__rate">
            <div className="sticky-survey-rate-area__rate__message">
              {t(stickySurveyRateMessage)}
            </div>
            <div className="sticky-survey-rate-area__rate__stars">
              {[
                { number: 1, icon: angryRate },
                { number: 2, icon: sadRate },
                { number: 3, icon: confusedRate },
                { number: 4, icon: happyRate },
                { number: 5, icon: inloveRate },
              ].map((rate) => {
                return (
                  <div
                    className="sticky-survey-rate-area__rate__stars__star"
                    key={rate.number}
                    data-testid={`star${rate.number}`}
                    onMouseOver={() => onMouseOver(rate.number)}
                    onMouseLeave={onMouseLeave}
                    onTouchStart={() => onMouseOver(rate.number)}
                    onClick={() => onRatingClick()}>
                    <img
                      src={
                        lastRating >= rate.number
                          ? StartSelectedIcon
                          : StartUnSelectedIcon
                      }
                      alt="star"
                      className="sticky-survey-rate-area__rate__stars__star__img"
                      data-testid={
                        lastRating >= rate.number
                          ? 'selectedStarIcon'
                          : 'unselectedStarIcon'
                      }
                    />
                    <img
                      src={emojiDot}
                      alt="emoji-dot"
                      className="sticky-survey-rate-area__rate__stars__star__emojidot"
                    />
                    <img
                      src={rate.icon}
                      alt="emoji"
                      className="sticky-survey-rate-area__rate__stars__star__emoji"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="sticky-survey-rate-area__comment">
            <textarea
              className="sticky-survey-rate-area__comment__text-area"
              rows="4"
              cols="50"
              data-testid="survey-comment"
              value={comment}
              onChange={(e) => onCommentChange(e.target.value)}
              maxLength="128"
              placeholder={t(stickySurveyCommentMessage)}
            />
          </div>
          <div className="sticky-survey-rate-area__send">
            <div
              className={classNames({
                'sticky-survey-rate-area__send__button': true,
                'sticky-survey-rate-area__send__button--requesting':
                  isSendingSurvey,
                dynamic: appService.isDynamicStylingChannel(),
              })}
              onClick={() => {
                sendSurvey();
              }}
              data-testid="send-button">
              {isSendingSurvey && (
                <img
                  src={RequestingIcon}
                  className="sticky-survey-rate-area__send__button__loader"
                  alt="loading"
                />
              )}
              {t(surveyRateButton)}
            </div>
          </div>
        </div>
        {errors.title && (
          <CustomModal
            modalModel={errors}
            onCloseModal={onCloseErrorModal}
            type={MODAL_ERROR}
            isChatBotSurvey={isChatBotSurvey}
          />
        )}
      </div>
    );
  }
};

export default StickySurvey;
