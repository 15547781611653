import 'antd/dist/antd.css';
import './ChatAssistant.scss';

import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Renderer from '../../chatAssistant/renderer/Renderer';
import Footer from '../../chatAssistant/footer/Footer';
import VoteFooter from '../../chatAssistant/footer/voteFooter/VoteFooter';
import InputBar from '../../chatAssistant/inputBar';
import appService from '../../../services/app.service';
import { IMAGE } from '../../../constants';

const Container = ({ children }) => {
  return (
    <div className="vote-conversation-wrapper vote-conversation-wrapper-snapshot">
      {children}
    </div>
  );
};

const ChatAssistantPreview = ({ history, outsideData }) => {
  const chat = [outsideData] ?? [];

  const chatMessagesRef = useRef(null);
  const messageInputRef = useRef(null);
  const [isFirstScrollComplete, setIsFirstScrollComplete] = useState(false);

  useEffect(() => {
    function handleScrollYChange() {
      if (chatMessagesRef.current) {
        const lastScrollY = chatMessagesRef.current.scrollTop;

        if (lastScrollY) {
          appService.setAssistantScrollPosition(lastScrollY);
        }
      }
    }

    if (appService.getIsStickyAssistant()) {
      window.addEventListener('scroll', handleScrollYChange, true);
      return () => {
        return () => window.removeEventListener('scroll', handleScrollYChange);
      };
    }
  }, []);

  useEffect(() => {
    if (
      typeof chatMessagesRef.current.scrollTo !== 'undefined' &&
      chat &&
      chat.length > 0
    ) {
      const lastChat = chat[chat.length - 1].answers;
      let timeoutToScroll = 0;
      lastChat &&
        lastChat.forEach((answer) => {
          if (answer.displayType === IMAGE) {
            timeoutToScroll = 300;
          }
        });
      // scroll to bottom whenever chat array or typing updates
      if (!isFirstScrollComplete) {
        scrollToPositionY(appService.getAssistantScrollPosition());
        setIsFirstScrollComplete(true);
      } else {
        scrollToBottom(timeoutToScroll);
      }
    }

    if (chat.length !== 0 && chat[0].isGuestUser != null) {
      appService.setIsGuestUser(chat[0].isGuestUser);
    }
    // eslint-disable-next-line
  }, [chat]);

  const scrollTo = (addScrollHeight) => {
    const addHeight = addScrollHeight ? addScrollHeight : 0;
    chatMessagesRef.current.scrollTo({
      top: chatMessagesRef.current.scrollHeight + addHeight,
      behavior: 'smooth',
    });
  };

  const scrollToPositionY = (scrollPositionY) => {
    chatMessagesRef.current &&
      chatMessagesRef.current.scrollTo({
        top: scrollPositionY,
      });
  };

  const scrollToBottom = (timeoutToScroll, addScrollHeight) => {
    if (isFirstScrollComplete) {
      if (timeoutToScroll > 0) {
        setTimeout(() => {
          scrollTo(addScrollHeight);
        }, timeoutToScroll);
      } else {
        scrollTo();
      }
    }
  };

  const renderFooter = useCallback((footer) => {
    if (footer && footer.length > 0) {
      return (
        <Container>
          {footer.map((footerItem) => {
            if (footerItem.componentType === 'Footer') {
              return (
                <Footer
                  isRenderedFromOutside
                  footerComponents={footerItem.componentData}
                />
              );
            } else if (footerItem.componentType === 'VoteFooter') {
              return (
                <VoteFooter
                  isRenderedFromOutside
                  data={footerItem.componentData}
                />
              );
            } else if (footerItem.componentType === 'Conversation') {
              return (
                <InputBar
                  id={footerItem.id}
                  componentData={footerItem.componentData}
                  scrollToBottom={scrollToBottom}
                  ref={messageInputRef}
                  maxLength="150"
                  isRenderedFromOutside
                />
              );
            } else return undefined;
          })}
        </Container>
      );
    }
  }, []);

  return (
    <>
      <div
        className="chatbot"
        ref={chatMessagesRef}
        data-testid="chatbot-shaphot">
        <Renderer
          components={chat[0].components.components}
          key={1}
          currentIndex={1}
          ref={messageInputRef}
          history={history}
          scrollToBottom={scrollToBottom}
          isRenderedFromOutside
        />
      </div>
      {renderFooter(chat[0].footerComponents.components)}
    </>
  );
};

export default ChatAssistantPreview;
