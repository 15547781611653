import React, { useMemo } from 'react';
import { Form } from 'antd';
import FormItemFactory from './FormItemFactory';
import useRules from './hooks/useRules';
import Alert from '../../../assets/icons/alert.svg';
import './FormItem.scss';
import classNames from 'classnames';

const FormItem = ({ item, disabled, name, formListIndex, lang, form }) => {
  const rules = useRules(item);

  const InputComponent = useMemo(() => {
    return FormItemFactory.getComponent(item.type);
  }, [item.type]);

  const getDependantValue = (dependantField) => {
    if (formListIndex !== undefined) {
      if (
        form.getFieldsValue() &&
        form.getFieldsValue().items &&
        form.getFieldsValue().items[formListIndex]
      ) {
        return form.getFieldsValue().items[formListIndex][dependantField];
      }
    } else {
      return form.getFieldValue(dependantField);
    }
  };

  const getLabelMessageOfDependantField = (form) => {
    return (
      <div className="form-item-info">
        <img src={Alert} className="alert-icon" alt="alert-icon" />
        <span>
          {item.dependantInfoMap[getDependantValue(item.infoDependantField)]}
        </span>
      </div>
    );
  };

  if (item.dependantField) {
    if (!item.initialVisible && !getDependantValue(item.dependantField))
      return null;
    return (
      <div
        className={classNames('assistant-form-item', {
          horizontal: item.isHorizontal,
        })}>
        <Form.Item
          name={name ? name : item.name}
          rules={rules}
          label={item.label}>
          <InputComponent
            name={name}
            item={item}
            disabled={disabled}
            form={form}
            lang={lang}
            dependentValue={getDependantValue(item.dependantField)}
          />
        </Form.Item>
      </div>
    );
  }

  if (item.visibleDependantField) {
    return (
      <div
        className={classNames('assistant-form-item', {
          horizontal: item.isHorizontal,
        })}>
        <Form.Item shouldUpdate={true} noStyle>
          {(form) =>
            item.dependantVisibleIds &&
            item.dependantVisibleIds.some(
              (id) => id === getDependantValue(form, item.visibleDependantField)
            ) ? (
              <div>
                <Form.Item
                  label={item.label}
                  name={name ? name : item.name}
                  rules={rules}>
                  <InputComponent item={item} disabled={disabled} />
                </Form.Item>
                {item.infoDependantField &&
                  item.dependantInfoMap[
                    getDependantValue(form, item.infoDependantField)
                  ] &&
                  getLabelMessageOfDependantField(form)}
                {item.infoLabel && (
                  <div className="form-item-info">
                    <img src={Alert} className="alert-icon" alt="alert-icon" />
                    <span>{item.infoLabel}</span>
                  </div>
                )}
              </div>
            ) : null
          }
        </Form.Item>
      </div>
    );
  }

  return (
    <div
      className={classNames('assistant-form-item', {
        horizontal: item.isHorizontal,
      })}>
      <Form.Item
        label={!!!item.previewDeepLink ? item.label : undefined}
        name={name ? name : item.name}
        rules={rules}>
        <InputComponent item={item} disabled={disabled} lang={lang} />
      </Form.Item>
      {item.infoDependantField && (
        <Form.Item shouldUpdate={true} noStyle>
          {(form) =>
            item.dependantInfoMap[
              getDependantValue(form, item.infoDependantField)
            ] && getLabelMessageOfDependantField(form)
          }
        </Form.Item>
      )}
      {item.infoLabel && (
        <div className="form-item-info">
          <img src={Alert} className="alert-icon" alt="alert-icon" />
          <span>{item.infoLabel}</span>
        </div>
      )}
      {item.type === 'DATA_DISPLAY' && <hr className="divider"></hr>}
    </div>
  );
};

export default FormItem;
