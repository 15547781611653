import appService from '../../../services/app.service';
import { PLATFORM_WEB } from '../../../constants';
import minimizeIcon from '../../../assets/icons/minimize-btn.svg';
import React from 'react';

const EchoHeaderMinimizeIcon = () => {
  const onMinimizeClick = () => {
    appService.minimizeStickyPopup();
  };

  if (
    !(
      appService.getIsStickyAssistant() &&
      appService.getPlatform() === PLATFORM_WEB
    )
  ) {
    return null;
  }

  return (
    <div className="minimize-wrapper" onClick={onMinimizeClick}>
      <img src={minimizeIcon} className="minimize-icon" alt="minimize" />
    </div>
  );
};

export default EchoHeaderMinimizeIcon;
