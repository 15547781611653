import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import RouteDecider from './components/routeDecider/RouteDecider';
import './App.scss';
import { ConfigProvider } from 'antd';
import ThemeProvider from '@tx-components/theme-provider';
import { TX_COMPONENTS_THEME } from './styles/tx-theme';

const config = {
  // set default props for Select component
  Select: {
    dropdownStyle: { top: 0 },
  },
};

const App = () => {
  return (
    <div className="app">
      <ConfigProvider {...config}>
        <ThemeProvider theme={TX_COMPONENTS_THEME}>
          <BrowserRouter basename={process.env.REACT_APP_BASENAME || ''}>
            <Switch>
              <RouteDecider exact path="/" />
              <RouteDecider exact path="/live-chat" />
              <RouteDecider exact path="/international-send-email" />
              <RouteDecider exact path="/survey" />
              <RouteDecider exact path="/preview/*" />
              <Route exact path="*" render={() => <Redirect to="/" />} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </ConfigProvider>
    </div>
  );
};

export default App;
