import React from 'react';

const LeftArrow = ({ handleClickLeft, className = '' }) => {
  return (
    <span
      data-testid="left-arrow"
      onClick={() => handleClickLeft()}
      className={'jc-left ' + className}>
      <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
      </svg>
    </span>
  );
};

export default LeftArrow;
