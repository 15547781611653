import React from 'react';
import './Bubble.scss';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import Alert from '../alert/Alert';

const Bubble = ({
  title,
  titleFragment,
  labelType,
  children,
  isBordered = true,
  isBottomRounded = true,
  className = '',
}) => {
  const info = labelType === 'Info';

  const renderContent = () => {
    return title.split('\r\n').map((item, i) => {
      if (item === '') {
        return <br />;
      }
      return (
        <p key={i}>
          <ReactMarkdown children={item} />
        </p>
      );
    });
  };

  return (
    <div className={`bubble-container ${className}`}>
      {(title || titleFragment) && (
        <div
          className={classNames('title', {
            'bottom-rounded': isBottomRounded,
            'no-info': !info,
            info: info,
          })}>
          <>
            {info ? (
              <Alert message={title} info background={false} />
            ) : titleFragment ? (
              titleFragment
            ) : (
              <>{renderContent()}</>
            )}
          </>
        </div>
      )}
      {React.Children.toArray(children)?.length > 0 && (
        <div
          className={classNames('bubble', {
            borderless: !isBordered,
            info: info,
            withTitle: (title || titleFragment) && isBordered,
          })}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Bubble;
