import React, { useEffect } from 'react';
import Bubble from '../../layout/bubble/Bubble';
import classNames from 'classnames';
import './ConfirmOtpAddress.scss';
import NumberFormat from 'react-number-format';
import chatBotService from '../../../services/chatbot.service';
import usePersistentState from '../../common/usePersistentState';
import {
  badCodeErrorMsg,
  chatbotErrorMsg,
  confirmOtpAddress,
  confirmOtpAddressNewCode,
  approvalCode,
  seconds,
} from '../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '../buttonGroup/ButtonGroup';

const ConfirmOtpAddress = ({ id, componentData, select, isSelectable }) => {
  const { t } = useTranslation();

  const [confirmCode, setConfirmCode] = usePersistentState(
    id,
    'confirmCode',
    ''
  );
  const [countTime, setCountTime] = usePersistentState(id, 'countTime', -1);
  const [isResendVisible, setIsResendVisible] = usePersistentState(
    id,
    'isResendVisible',
    false
  );

  const [warningMessage, setWarningMessage] = usePersistentState(
    id,
    'warningMessage',
    ''
  );
  const [isRequestActive, setIsRequestActive] = usePersistentState(
    id,
    'isRequestActive',
    false
  );
  const [intervalId, setIntervalId] = usePersistentState(id, 'intervalId', 0);
  const { shipmentNumber, orderNumber, orderId, phoneNumber } = componentData;

  useEffect(() => {
    setCountTime(componentData.timeoutSecond);
  }, []);

  useEffect(() => {
    clearInterval(intervalId);
  }, [isSelectable]);

  useEffect(() => {
    if (!countTime) {
      setIsResendVisible(true);
      return;
    }
    const intervalId = setInterval(() => {
      setCountTime(countTime - 1);
    }, 1000);
    setIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [countTime]);

  const changedOtpValue = (value) => {
    setConfirmCode(value);
    if (value.length === 6) {
      setIsRequestActive(true);
    } else {
      setIsRequestActive(false);
    }
  };

  const handleResendCode = () => {
    if (isResendVisible && isSelectable) {
      setIsResendVisible(false);
      setConfirmCode('');
      chatBotService
        .reSendOtpCode(
          shipmentNumber,
          orderNumber,
          orderId,
          phoneNumber,
          componentData.event
        )
        .then((response) => {
          if (response.status === 200) {
            setWarningMessage('');
            setIsResendVisible(false);
            setCountTime(componentData.timeoutSecond);
          }
        })
        .catch((err) => {
          setWarningMessage(t(chatbotErrorMsg));
        });
    }
  };

  const sendOtpCode = () => {
    if (confirmCode.length === 6 && isSelectable && isRequestActive) {
      chatBotService
        .checkOtpCodeValidation(
          confirmCode,
          shipmentNumber,
          orderNumber,
          orderId,
          phoneNumber,
          componentData.event
        )
        .then((response) => {
          if (response.status === 200) {
            setWarningMessage('');
            setIsRequestActive(true);
            let request = {
              data: {
                otpCode: confirmCode,
              },
              event: componentData.event,
            };
            select(request);
            clearInterval(intervalId);
          } else if (response.status === 400) {
            setWarningMessage(t(badCodeErrorMsg));
            setIsRequestActive(false);
          }
        })
        .catch((err) => {
          setWarningMessage(t(badCodeErrorMsg));
          setIsRequestActive(false);
        });
    }
  };

  function renderButtons() {
    return (
      <ButtonGroup>
        <ButtonGroup.Button
          isSelectable={isSelectable && isRequestActive}
          colorType="primary"
          data-testid="request-button"
          onClick={sendOtpCode}
          outline={false}
          disabled={!isSelectable || !isRequestActive}>
          {t(confirmOtpAddress)}
        </ButtonGroup.Button>
      </ButtonGroup>
    );
  }

  return (
    <div className="confirm-otp-address">
      <Bubble title={componentData.label} isBottomRounded={false}>
        <div className="header">
          <div className="counter">
            {t(approvalCode)} ({countTime} {t(seconds)})
          </div>
          {isResendVisible && (
            <div
              data-testid="button-resend"
              className={classNames({
                resend: true,
                'resend--active': isResendVisible && isSelectable,
                'resend--passive': !isResendVisible && !isSelectable,
              })}
              onClick={handleResendCode}>
              {t(confirmOtpAddressNewCode)}
            </div>
          )}
        </div>
        <div className="body">
          <NumberFormat
            data-testid="input-confirm"
            disabled={!isSelectable}
            className="assistant-input"
            format="######"
            mask="_"
            value={confirmCode}
            onValueChange={(e) => changedOtpValue(e.value)}
            allowEmptyFormatting
          />
          {warningMessage && <div className="warning">{warningMessage}</div>}
        </div>
        <div className="action-buttons">{renderButtons()}</div>
      </Bubble>
    </div>
  );
};

export default ConfirmOtpAddress;
