import MultiValueInput from '@tx-components/multi-value-input';
import React, { useEffect, useState } from 'react';
import './MultiValueInput.scss';
import { useTranslation } from 'react-i18next';
import {
  maxDigitLength,
  multiValueInputLabel,
  multiValueMaxItemCount,
  onlyDigits,
} from '../../../../i18n/keys';
import circleInfoFilled from '../../../../assets/icons/circle-info-filled.svg';

export default function AssistantMultiValueInput({ item, value, onChange }) {
  const { t } = useTranslation();

  const maxItemCount = item.maxItemCount || 20;
  const [validatedValue, setValidatedValue] = useState([]);
  const [errorMessage, setErrorMessage] = useState();

  const validationChecker = (currentValue) => {
    if (validatedValue.length + 1 > maxItemCount) {
      setErrorMessage(
        t(multiValueMaxItemCount, {
          count: maxItemCount,
        })
      );
      return false;
    }

    if (
      item.formInputType?.toLowerCase() === 'number' &&
      /^\d+$/.test(currentValue) === false
    ) {
      setErrorMessage(t(onlyDigits));
      return false;
    }

    if (
      item.formInputType?.toLowerCase() === 'number' &&
      currentValue.toString().length > 18
    ) {
      setErrorMessage(t(maxDigitLength));
      return false;
    }

    if (errorMessage) setErrorMessage();

    const condition = validatedValue.includes(currentValue);
    return !condition;
  };

  const handleOnChange = (newValue) => {
    onChange(newValue || []);
  };

  useEffect(() => {
    setValidatedValue(value || []);
  }, [value]);

  return (
    <div className="assistant-multivalue-input">
      <MultiValueInput
        validate={validationChecker}
        clearable
        multipleValueType="text"
        textKeyCodes={[32, 188, 13]}
        value={validatedValue?.join(',') ?? ''}
        onChange={handleOnChange}
        placeholder={item.placeholder}
      />

      {errorMessage && (
        <div className="error-validation-message">
          <img src={circleInfoFilled} className="info-icon" alt="info" />
          {errorMessage}
        </div>
      )}

      <div className="info-label">
        {t(multiValueInputLabel, {
          fieldName: item.label,
        })}
      </div>
    </div>
  );
}
