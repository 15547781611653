import { useCallback, useEffect, useRef, useState } from 'react';

export const useLazyEffect = (cb, dep) => {
  const initializeRef = useRef(false);

  useEffect((...args) => {
    if (initializeRef.current) {
      cb(...args);
    } else {
      initializeRef.current = true;
    }
  }, dep);
};

export default function useIsTyping(text, fn) {
  const [isTyping, setIsTypingState] = useState(false);
  const isTypingRef = useRef(false);
  const timeoutRef = useRef();

  const setIsTyping = useCallback((value) => {
    isTypingRef.current = value;
    setIsTypingState(value);
  }, []);

  const stopTyping = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (isTypingRef.current) {
        setIsTyping(false);
      }
    }, 3000);
  }, [setIsTyping]);

  useEffect(() => {
    if (text && text.length > 0) {
      setIsTyping(true);
      stopTyping();
    } else {
      setIsTyping(false);
    }
  }, [setIsTyping, stopTyping, text]);

  useLazyEffect(() => {
    fn(isTyping);
  }, [isTyping]);
}
