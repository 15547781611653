import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { billingAddressText } from '../../../../../../i18n/keys';

const ShipmentBillAddress = ({ info }) => {
  const { t } = useTranslation();

  return (
    <div className="bill-address">
      <div className="bill-address__name">{t(billingAddressText)}</div>
      <div className="bill-address__shipto">{info.billToFirstAndLastName}</div>
      <div className="bill-address__info">{info.billToAddress}</div>
      <div className="bill-address__city">{info.billToCityAndDistrict}</div>
    </div>
  );
};

ShipmentBillAddress.propTypes = {
  info: PropTypes.object.isRequired,
};

export default ShipmentBillAddress;
