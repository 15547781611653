export const TX_COMPONENTS_THEME = {
  colors: {
    default: '#eff1f4',
    success: '#2ccd76',
    secondary: '#3556a7',
    info: '#EFF1F4',
    all: {
      'border-color': '#dfe3e7',
    },
  },
  components: {},
};
