// Agent Message Types
export const LEFT = 1;
export const AGENT_JOINED = 2;
export const TEXT = 3;
export const START_TYPING = 4;
export const STOP_TYPING = 5;
export const DISCONNECT = 6;
export const REFUSED = 7;
export const TIMEOUT = 8;
export const CONTINUE = 9;
export const END_CHAT = 10;
export const MAX_TIMEOUT_THRESHOLD_EXCEED = 11;
export const SYSTEM_JOINED = 12;
export const REFUSE_EXISTING_TOPIC = 13;
export const HEART_BEAT = 14;
export const AGENT_DISCONNECTED = 15;
export const CHAT_ENDED_BY_CUSTOMER = 18;

// Message Types
export const MESSAGE_FROM_CLIENT = 'client';
export const MESSAGE_FROM_SERVER = 'server';
export const MESSAGE_TYPE_TEXT = 'text';
export const MESSAGE_TYPE_IMAGE = 'image';
export const MESSAGE_TYPE_PDF = 'pdf';
export const MESSAGE_TYPE_DOCUMENT = 'document';
export const MESSAGE_TYPE_VIDEO = 'video';
