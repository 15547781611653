import React from 'react';
import classNames from 'classnames';
import Bubble from '../../layout/bubble/Bubble';
import Radio from '@tx-components/radio';
import usePersistentState from '../../common/usePersistentState';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import Utils from '../../../utils';
import { BUTTON_PRIMARY } from '../../../constants';
import './Address.scss';

const Address = ({
  id,
  componentData,
  select,
  isSelectable,
  isRenderedFromOutside = false,
}) => {
  const [selectedAddress, setSelectedAddress] = usePersistentState(
    id,
    'selectedAddress',
    ''
  );
  const [isActive, setIsActive] = usePersistentState(id, 'isActive', false);

  const onChangeAddress = (value) => {
    if (isSelectable) {
      setSelectedAddress(value);
      setIsActive(true);
    }
  };

  const handleButtons = (event, buttonType) => {
    if (buttonType === 'Select' && selectedAddress !== '') {
      newRequest(event);
    } else if (buttonType === 'New') {
      newRequest(event);
    }
  };

  const newRequest = (event) => {
    const selectedAddressObj =
      componentData.userAddresses &&
      componentData.userAddresses.find(
        (addrw) => addrw.addressId === selectedAddress
      );
    const selectedAddressName = selectedAddressObj
      ? selectedAddressObj.addressName
      : '';
    let request = {
      data: {
        selectedAddressId: selectedAddress,
        selectedAddressName: selectedAddressName,
      },
      event: event,
    };
    select(request);
  };

  function renderButtons() {
    return (
      <ButtonGroup>
        {componentData &&
          componentData.buttons.map((button, index) => {
            const addressButtonType = button.buttonType;
            const buttonType =
              addressButtonType === 'Select'
                ? BUTTON_PRIMARY
                : addressButtonType;
            const outline = addressButtonType !== 'Select';
            const colorType = Utils.getColorType(buttonType);

            return (
              <ButtonGroup.Button
                key={index + button.buttonType}
                data-testid="address-selection"
                className="address-selection-container"
                isSelectable={
                  (isSelectable &&
                    (addressButtonType === 'Select' ? isActive : true)) ||
                  !isRenderedFromOutside
                }
                outline={outline}
                weight={3}
                colorType={colorType}
                iconLeft={Utils.getButtonIcon(buttonType, button.iconUrl)}
                onClick={() => handleButtons(button.event, button.buttonType)}
                disabled={
                  !isSelectable || (addressButtonType === 'Select' && !isActive)
                }>
                {button.text}
              </ButtonGroup.Button>
            );
          })}
      </ButtonGroup>
    );
  }

  return (
    <div className="address">
      <Bubble isBottomRounded={false} title={componentData.label}>
        <div className="slider-wrapper">
          <div className="items">
            {componentData.userAddresses &&
              componentData.userAddresses.map((address, i) => {
                return (
                  <div
                    className={classNames('order-item address-item', {
                      'address-item': true,
                      'radio-active': isSelectable,
                      'radio-passive':
                        (!isSelectable &&
                          address.addressId !== selectedAddress) ||
                        !isRenderedFromOutside,
                      'radio-selected': address.addressId === selectedAddress,
                    })}
                    onClick={(e) => onChangeAddress(address.addressId)}
                    key={i}>
                    <div className="address-wrapper">
                      <div className="address-info">
                        <div className="address-title">
                          <Radio
                            checked={address.addressId === selectedAddress}
                          />
                          <div className="address-title-data">
                            {address.addressName}
                          </div>
                        </div>
                        <div className="address-info">
                          <div className="name">{address.userName}</div>
                          <div className="address-data">{address.address}</div>
                        </div>
                        <div className="telephone">{address.phoneNumber}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="action-buttons">
          {componentData.buttons && renderButtons()}
        </div>
      </Bubble>
    </div>
  );
};

export default Address;
