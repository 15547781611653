import uuid from 'uuid/v4';
import jwt from 'jsonwebtoken';
import _ from 'lodash';
import appService from '../services/app.service';
import * as c from '../constants';
import {
  BUTTON_CANCEL,
  BUTTON_NEW,
  BUTTON_PRIMARY,
  BUTTON_SECONDARY,
  BUTTON_SELECTED_NO,
  BUTTON_SELECTED_YES,
  CLOSE_WINDOW_LINK,
  FALLBACK_IMAGE_URL,
  LIVE_CHAT_LINK,
  LIVE_CHAT_PAGE,
  pages,
  PLATFORM_ANDROID,
  PLATFORM_IOS,
} from '../constants';
import {
  MESSAGE_TYPE_DOCUMENT,
  MESSAGE_TYPE_IMAGE,
  MESSAGE_TYPE_PDF,
  MESSAGE_TYPE_VIDEO,
} from '../constants/liveChatConstants';
import React, { Fragment } from 'react';
import { LANGUAGE_CODE_TR } from '../constants/chatAssistantConstant';
import {
  ACCEPTED_LANGUAGE,
  DEFAULT_LANGUAGE,
} from '../constants/sessionStorageConstants';
import plusIcon from '../assets/icons/plus-icon.svg';

class Utils {
  static renderShipmentImages = (images = [], fallbackImage, imageFailed) => {
    return images.slice(0, 3).map((imageUrl, i) => {
      return imageFailed ? (
        <img
          key={imageUrl}
          src={FALLBACK_IMAGE_URL}
          className="image"
          alt="ürün-resmi"
        />
      ) : (
        <img
          src={imageUrl}
          className="image"
          alt="ürün-resmi"
          onError={fallbackImage}
        />
      );
    });
  };

  static renderShipmentImagesForAvatarComponent = (
    images = [],
    fallbackImage,
    imageFailed
  ) => {
    return images.slice(0, 3).map((imageUrl, i) => {
      if (imageFailed) {
        return (
          <div className="avatar">
            <img
              key={imageUrl}
              src={FALLBACK_IMAGE_URL}
              className="image"
              alt="ürün-resmi"
            />
          </div>
        );
      }

      if (i === 0 && images.length > 3) {
        return (
          <div className="avatar-last">
            <img
              src={imageUrl}
              className="image"
              alt="ürün-resmi"
              onError={fallbackImage}
            />
            <div className="background">
              <div className="text">
                +{images.length - 2}
                <div>Ürün</div>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div className="avatar">
          <img
            src={imageUrl}
            className="image"
            alt="ürün-resmi"
            onError={fallbackImage}
          />
        </div>
      );
    });
  };

  static async handleClickDeeplink(
    event,
    eventLabel,
    url,
    requestForDeepLink,
    history
  ) {
    const deepLinkRequest = {
      event: event,
      data: {
        deepLinkEventLabel: eventLabel,
      },
    };
    requestForDeepLink(deepLinkRequest);
    if (url === LIVE_CHAT_LINK) {
      appService.setIsLiveChatDeepLinkClicked(true);
      await appService.setCurrentPage(pages.LIVECHAT);
      history.push(LIVE_CHAT_PAGE);
    } else if (url === CLOSE_WINDOW_LINK) {
      appService.closePopup();
    } else {
      appService.navigateOnParentHost(url);
    }
  }

  static shouldSelect(
    currentIndex,
    currentChatIndex,
    totalErrorCount,
    isRequesting
  ) {
    if (currentIndex + totalErrorCount === currentChatIndex && !isRequesting) {
      return true;
    } else {
      return false;
    }
  }

  static generateCidForClickEvents() {
    return uuid();
  }

  static uuid() {
    return uuid();
  }

  static getEventActionNameForClickEvent(choice, clickEventOrder) {
    if (typeof choice.deeplinkUrl === 'undefined') {
      const topic = choice.topic;
      let isUpOrDown = '';
      for (let i = topic.length - 5; i < topic.length; i++) {
        isUpOrDown = isUpOrDown + topic.charAt(i);
      }
      if (
        isUpOrDown.includes(c.VOTE_SELECTED_YES) ||
        isUpOrDown.includes(c.VOTE_SELECTED_NO)
      ) {
        return c.EVENT_ACTION_DECISION_COMPONENT;
      } else {
        return `level${clickEventOrder}ChapterClick`;
      }
    } else if (choice.deeplinkUrl && choice.deeplinkUrl === c.LIVE_CHAT_LINK) {
      return c.EVENT_USER_AGENT_COMPONENT;
    } else if (choice.deeplinkUrl && choice.deeplinkUrl === c.ORDER_PAGE_LINK) {
      return c.EVENT_ORDERS_PAGE_COMPONENT;
    }
  }

  static getUserIdFromJwt() {
    return jwt.decode(appService.getToken()).userId;
  }

  static getCourierIdFromJwt() {
    return jwt.decode(appService.getToken()).sub;
  }

  static getSupplierIdFromJwt() {
    if (appService.getToken()) {
      return jwt.decode(appService.getToken()).supplierId;
    }

    return jwt.decode(appService.getSellerRefreshToken())?.supplierId;
  }

  static getCurrentTime() {
    let today = new Date();
    return this.getTimeFromDate(today);
  }

  static getTimeFromDate(date) {
    let minutes = '';
    if (date.getMinutes() < 10) {
      minutes = '0' + date.getMinutes();
    } else {
      minutes = date.getMinutes();
    }
    return date.getHours() + ':' + minutes;
  }

  static getCurrentDateTime() {
    return new Date();
  }

  static getFileType(file) {
    if (file.type === 'application/pdf') {
      return MESSAGE_TYPE_PDF;
    } else if (
      file.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      return MESSAGE_TYPE_DOCUMENT;
    } else if (file.type.includes('video')) {
      return MESSAGE_TYPE_VIDEO;
    } else {
      return MESSAGE_TYPE_IMAGE;
    }
  }

  static removeQueryStringsFromAddressBar() {
    let uri = window.location.toString();
    if (uri.indexOf('?') > 0) {
      let cleaUri = uri.substring(0, uri.indexOf('?'));
      window.history.replaceState({}, document.title, cleaUri);
    }
  }

  static ifFunctionExistsOnNativeAndroidInterface(functionName) {
    if (window.NativeAndroidInterface) {
      if (typeof window.NativeAndroidInterface[functionName] === 'function') {
        return true;
      }
    }
    return false;
  }

  static isSocketOpen(socket, isLiteMode) {
    if (isLiteMode) {
      return true;
    } else if (
      !isLiteMode &&
      socket &&
      socket.readyState &&
      (socket.readyState === WebSocket.CLOSING ||
        socket.readyState === WebSocket.CLOSED)
    ) {
      return false;
    } else {
      return true;
    }
  }

  static buildNameParseToInt(buildName) {
    return parseInt(buildName.replace(/\D/g, ''));
  }

  //Android Build Name 7.0.0.232 returns 232
  //Ios Build Name 432 returns 432
  //Using for trendyol app
  static buildNameParserForTrendyolApp(buildName) {
    let platform = appService.getPlatform();

    if (platform === PLATFORM_IOS) {
      return parseInt(buildName);
    } else if (platform === PLATFORM_ANDROID) {
      return parseInt(buildName.split('.').pop());
    }
    return parseInt(buildName.replace(/\D/g, ''));
  }

  static getTokenFromCookie() {
    if (typeof document !== 'undefined' && document.cookie) {
      const tokenParse = document.cookie
        .split('; ')
        .find((row) => row.startsWith('token='));

      if (!!tokenParse) {
        const token = tokenParse.split('=')[1];
        return token ? token : false;
      }
    }
    return false;
  }

  static getStartRequestFromCookie() {
    if (typeof document !== 'undefined' && document.cookie) {
      const startRequestBase64Parse = document.cookie
        .split('; ')
        .find((row) => row.startsWith('claimItems='));

      if (!!startRequestBase64Parse) {
        const claimItemsBase64 = startRequestBase64Parse.split('=')[1];
        return claimItemsBase64
          ? Buffer.from(claimItemsBase64, 'base64')
          : false;
      } else {
        return false;
      }
    }
    return false;
  }

  static async toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  static decodeJWT = (token) => {
    try {
      return JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
    } catch (e) {
      return null;
    }
  };

  static newLineToBreak(str) {
    if (!str || typeof str !== 'string') return '';

    return str.split(/(?:\r\n|\r|\n)/).reduce(
      (arr, line, index) =>
        arr.concat(
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ),
      []
    );
  }

  static splitValueWithSpace(cargoTrackingNumber, splitSize) {
    const ret = [];
    let index;
    if (cargoTrackingNumber === null) {
      return '';
    }
    const len = cargoTrackingNumber.length;

    for (index = 0, len; index < len; index += splitSize) {
      if (len - index < 2 * splitSize) {
        //combine remaining characters to last part
        ret.push(cargoTrackingNumber.substr(index, len - index));
        break;
      } else {
        ret.push(cargoTrackingNumber.substr(index, splitSize));
      }
    }

    return ret.join(' ');
  }

  static getAcceptepLanguage(language) {
    const isLanguageAccepted = ACCEPTED_LANGUAGE.includes(language);
    return isLanguageAccepted ? language : DEFAULT_LANGUAGE;
  }

  static getColorType(buttonType) {
    let colorType = 'default';
    if (buttonType === BUTTON_SELECTED_YES) {
      colorType = 'primary';
    } else if (buttonType === BUTTON_SELECTED_NO) {
      colorType = 'cancel';
    } else if (buttonType === BUTTON_PRIMARY) {
      colorType = 'primary';
    } else if (buttonType === BUTTON_CANCEL) {
      colorType = 'cancel';
    } else if (buttonType === BUTTON_SECONDARY) {
      colorType = 'secondary';
    } else if (buttonType === BUTTON_NEW) {
      colorType = 'primary';
    }
    return colorType;
  }

  static getButtonIcon(buttonType, iconUrl) {
    switch (buttonType) {
      case BUTTON_NEW:
        return plusIcon;
      default:
        return iconUrl;
    }
  }

  static isShowHeaderNotification = (notifications) => {
    if (notifications && !_.isEmpty(notifications)) {
      if (notifications?.tabs && notifications?.tabs.length > 0) {
        return true;
      }
    }
    return false;
  };

  static getOS() {
    var userAgent = window.navigator.userAgent,
      platform =
        window.navigator?.userAgentData?.platform || window.navigator.platform,
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'theme-ios';
    } else if (/Android/.test(userAgent)) {
      os = 'theme-android';
    } else {
      os = 'theme-desktop';
    }

    return os;
  }

  static isFileSizeInLimit(fileType, file) {
    const fileSizeLimit = appService.getUploadSizeLimitByFileType(fileType);
    return file.size <= 1024 * 1024 * fileSizeLimit;
  }

  static isArrayNotEmpty(arr) {
    return Array.isArray(arr) && arr.length > 0;
  }

  static isLanguageTr() {
    return LANGUAGE_CODE_TR === appService.getLanguage();
  }

  static isInputArabic(input) {
    const ignoredStorefronts = appService.getArabicAllowedStorefronts();

    if (ignoredStorefronts.some((sf) => sf === appService.getStoreFrontId())) {
      return false;
    }

    var arabicRegex =
      /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
    return arabicRegex.test(input);
  }

  static isCompressableImage(file, maxFileSize) {
    if (
      file.size > 1024 * 1024 * maxFileSize &&
      file.type.startsWith('image/')
    ) {
      return true;
    }
    return false;
  }

  static async getCompressedImage(file, maxFileSize) {
    return new Promise((resolve) => {
      if (!file.type.startsWith('image/')) {
        resolve(file);
        return;
      }

      const image = new Image();
      const reader = new FileReader();

      reader.onload = function (e) {
        image.onload = function () {
          const canvas = document.createElement('canvas');
          const maxSize = maxFileSize * 1024 * 1024;

          let width = image.width;
          let height = image.height;

          let strategy = 'quality'; // Default strategy

          // Determine the strategy based on file type
          if (file.type === 'image/png') {
            strategy = 'dimensions';
          }

          if (strategy === 'quality') {
            let targetQuality = 0.9;

            function processWithQuality(quality) {
              canvas.width = width;
              canvas.height = height;

              const context = canvas.getContext('2d');
              context.drawImage(image, 0, 0, width, height);

              canvas.toBlob(
                function (blob) {
                  const resizedFile = new File([blob], file.name, {
                    type: file.type,
                  });

                  if (resizedFile.size <= maxSize) {
                    resolve(resizedFile);
                  } else if (quality > 0.1) {
                    processWithQuality(quality - 0.1);
                  } else {
                    resolve(resizedFile);
                  }
                },
                file.type,
                quality
              );
            }

            processWithQuality(targetQuality);
          } else if (strategy === 'dimensions') {
            while (width * height > maxSize) {
              width *= 0.9;
              height *= 0.9;
            }

            canvas.width = width;
            canvas.height = height;

            const context = canvas.getContext('2d');
            context.drawImage(image, 0, 0, width, height);

            canvas.toBlob(function (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
              });
              resolve(resizedFile);
            }, file.type);
          }
        };

        image.src = e.target.result;
      };

      reader.readAsDataURL(file);
    });
  }
}

export default Utils;
