import React from 'react';
import './ButtonGroup.scss';
import Button from './Button';
import classNames from 'classnames';

const ButtonGroup = ({ children, className = '', responsive = true }) => {
  return (
    <div
      className={classNames('button-group-container', {
        [className]: true,
        responsive: responsive,
      })}>
      {children}
    </div>
  );
};

const MemoizedButtonGroup = React.memo(ButtonGroup);

MemoizedButtonGroup.Button = Button;

export default MemoizedButtonGroup;
