import './CancelSimulation.scss';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Bubble from '../../../layout/bubble/Bubble';
import CancelableItem from './components/cancelableItem';
import {
  cancelOrderSelectProduct,
  cancelOrderSelectReason,
  explanation,
  minumumOrderQuantityHeader,
  minumumOrderQuantityMessage,
  other,
  selectReason,
} from '../../../../i18n/keys';
import usePersistentState from '../../../common/usePersistentState';
import ButtonGroup from '../../buttonGroup/ButtonGroup';
import Alert from '../../../layout/alert/Alert';
import Select from '../../../Select';
import TextArea from '../../../common/TextArea/TextArea';
import InputLabel from '../../../common/InputLabel/InputLabel';

const CancelSimulation = ({ id, componentData, isSelectable, select }) => {
  const { t } = useTranslation();

  const { items = [], reasons = [], event, submitButtonLabel } = componentData;

  const [reasonId, setReasonId] = usePersistentState(id, 'reasonId', '-1');
  const [selecteds, setSelecteds] = usePersistentState(id, 'selecteds', []);
  const [selectedItems, setSelectedItems] = useState([]);
  const [dones, setDones] = usePersistentState(id, 'dones', []);
  const [isDone, setIsDone] = usePersistentState(id, 'isDone', false);
  const [description, setDescription] = usePersistentState(
    id,
    'description',
    ''
  );
  const [descriptionAvailableVal, setDescriptionAvailableVal] =
    usePersistentState(id, 'descriptionAvailableVal', false);

  useEffect(() => {
    setIsDone(
      selecteds.length !== 0 &&
        selecteds.length === dones.length &&
        reasonId !== '-1'
    );
  }, [selecteds, dones, reasonId]);

  const onChangeSelectedItem = (listingId, item, isChecked) => {
    if (isChecked && !selecteds.includes(listingId)) {
      setSelecteds([...selecteds, listingId]);
      setSelectedItems([...selectedItems, item]);
    } else if (!isChecked) {
      setSelecteds(selecteds.filter((s) => s !== listingId));
      setSelectedItems(selectedItems.filter((s) => s.listingId !== listingId));
    }
  };

  const onChangeDoneItem = (item) => {
    if (item.done && item.checked) {
      setDones([
        ...dones.filter((doneItem) => doneItem.listingId !== item.listingId),
        item,
      ]);
    } else {
      setDones(
        dones.filter((doneItem) => doneItem.listingId !== item.listingId)
      );
    }
  };

  const onChangeReason = (val) => {
    setReasonId(val);
  };

  useEffect(() => {
    reasons.forEach((reason) => {
      if (reason.id === reasonId) {
        if (reason.descriptionAvailable) {
          setDescriptionAvailableVal(true);
        } else {
          setDescriptionAvailableVal(false);
        }
      }
    });
  }, [reasonId]);

  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const onClickClaimOrder = () => {
    if (isSelectable && isDone) {
      const requestPayload = {
        event,
        data: {
          itemList: dones.map((doneItem) => ({
            quantity: doneItem.quantity,
            totalQuantity: doneItem.totalQuantity,
            salesCampaignId: doneItem.salesCampaignId,
            listingId: doneItem.listingId,
            shipmentNumber: doneItem.shipmentNumber,
          })),
          ...(descriptionAvailableVal ? { description } : {}),
          reasonId,
        },
      };

      select(requestPayload);
    }
  };

  return (
    <div
      className={classNames([
        'cancel-simulation-order',
        { disabled: !isSelectable },
      ])}>
      <Bubble title={t(cancelOrderSelectProduct)} isBottomRounded={false}>
        <div className="slider-wrapper">
          <div className="items">
            {items.map((item, i) => (
              <CancelableItem
                id={id + 'elableItem' + i}
                item={item}
                imgSource={item.images[0]}
                onChangeSelectedItem={onChangeSelectedItem}
                onChangeDoneItem={onChangeDoneItem}
                listingId={item.listingId}
                totalQuantity={item.quantity}
                salesCampaignId={item.salesCampaignId}
                shipmentNumber={item.shipmentNumber}
                key={item.listingId}
                variantName={item.variantName}
              />
            ))}
          </div>
        </div>
        {selectedItems.some((s) => s.minOrderQuantity) && (
          <div className="min-order-quantity">
            <Alert
              message={
                <div>
                  <b>{t(minumumOrderQuantityHeader)} </b>
                  {t(minumumOrderQuantityMessage)}
                </div>
              }
              background={false}
              orange
            />
          </div>
        )}
        <div className="cancelable-item-row">
          <Select
            label={t(cancelOrderSelectReason)}
            data-testid="cancelable-item-reason"
            placeholder={t(selectReason)}
            options={reasons.map((reason) => ({
              value: reason.id,
              label: reason.name,
            }))}
            onChange={onChangeReason}
            value={reasonId}
          />
        </div>
        <div className="cancelable-item-row">
          {descriptionAvailableVal && (
            <>
              <InputLabel text={t(other)} />
              <TextArea
                placeholder={t(explanation)}
                value={description}
                maxLength="250"
                onChange={onChangeDescription}
                data-testid="description-area"
              />
            </>
          )}
        </div>
        <div className="cancelable-item-row">
          <ButtonGroup.Button
            isSelectable={isDone && isSelectable}
            colorType="primary"
            data-testid="request-button"
            onClick={onClickClaimOrder}
            outline={false}
            disabled={!isSelectable}>
            {submitButtonLabel}
          </ButtonGroup.Button>
        </div>
      </Bubble>
    </div>
  );
};

export default CancelSimulation;
