export const CHATBOT_REQUEST = 'CHATBOT_REQUEST';
export const USER_ANSWER = 'USER_ANSWER';
export const SET_TYPING = 'SET_TYPING';
export const SET_IS_REQUESTING = 'SET_IS_REQUESTING';
export const CHATBOT_ERROR = 'CHATBOT_ERROR';
export const CHATBOT_API_DOWN = 'CHATBOT_API_DOWN';
export const SET_CID_FOR_CLICK_EVENTS = 'SET_CID_FOR_CLICK_EVENTS';
export const SET_CLICK_EVENT_ORDER = 'SET_CLICK_EVENT_ORDER';
export const SET_CHATBOT_SURVEY = 'SET_CHATBOT_SURVEY';
export const SET_LAST_ACTIVE_SHIPMENT_COMPONENT_INDEX =
  'SET_LAST_ACTIVE_SHIPMENT_COMPONENT_INDEX';
export const ERROR_CHATBOT_REQUEST = 'ERROR_CHATBOT_REQUEST';
export const SET_DELIVERED_PRODUCT_PROBLEM_IMAGE_INDEX =
  'SET_DELIVERED_PRODUCT_PROBLEM_IMAGE_INDEX';
export const SET_ANNOUNCEMENT = 'SET_ANNOUNCEMENT';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_PROACTIVE_INFO = 'SET_PROACTIVE_INFO';
export const SET_START_REQUEST = 'SET_START_REQUEST';
export const SET_IS_STATEMACHINE_ID_EXIST = 'SET_IS_STATEMACHINE_ID_EXIST';
export const SET_SHOW_LANGUAGE_SUPPORT = 'SET_SHOW_LANGUAGE_SUPPORT';
export const SET_ASSISTANT_EXIT_BUTTON = 'SET_ASSISTANT_EXIT_BUTTON';
export const CLEAR_CHAT = 'CLEAR_CHAT';
