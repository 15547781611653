import * as keys from './keys';

export default {
  translation: {
    [`${keys.appTitle}`]: 'Asistent Trendyol',
    [`${keys.appTitleTgoApp}`]: 'Asistent',
    [`${keys.appTitleMilla}`]: 'Asistent',
    [`${keys.appTabTitle}`]: 'Asistent Trendyol',
    [`${keys.liveChatTitle}`]: 'Živý čet',
    [`${keys.liveChatTitleTgoApp}`]: 'Živý čet',
    [`${keys.liveChatTitleMilla}`]: 'Živý čet',
    [`${keys.liveChatTabTitle}`]: 'Živý čet',
    [`${keys.chatbotErrorMsg}`]: 'Vyskytla sa systémová chyba. Skús to znova.',
    [`${keys.liveChatLoadingMessage}`]:
      'Spájame ťa s naším zástupcom služieb zákazníkom, čakaj, prosím...',
    [`${keys.agentsBusyMessage}`]:
      'Všetci naši zástupcovia sa momentálne venujú iným zákazníkom, počkaj, prosím...',
    [`${keys.liveChatConnecting}`]: 'Spája sa...',
    [`${keys.systemJoined}`]: 'Priraďuje sa zástupca pre zákazníkov...',
    [`${keys.agentDisconnected}`]: 'Priraďuje sa nový zástupca....',
    [`${keys.agentJoined}`]: 'Online',
    [`${keys.systemJoinedMessage}`]:
      'Vitaj v živom chate spoločnosti Trendyol.',
    [`${keys.liveChatOffline}`]: 'Offline',
    [`${keys.makeUploadedContentBigger}`]: 'Zobraziť vo väčšom formáte',
    [`${keys.timeOutFirstPart}`]: 'Tento čet sa ukončí za',
    [`${keys.timeOutSecondPart}`]: 's',
    [`${keys.timeOutThirdPart}`]: 'pretože si nejaký čas nič nenapísal(a).',
    [`${keys.closeLiveChatButton}`]: 'Ukončiť čet',
    [`${keys.continueLiveChatButton}`]: 'Pokračovať v čete',
    [`${keys.fileNameNotValidError}`]: 'Názov súboru nie je platný.',
    [`${keys.fileEmptyError}`]: 'Obsah nahraného súboru je prázdny.',
    [`${keys.liveChatRefused}`]:
      'V rámci tohto účtu momentálne prebieha iná konverzácia. Ak to budeš naďalej potrebovať, skús to neskôr.',
    [`${keys.maxTimeOutThresholdExceedMessage}`]:
      'Tento čet bol automaticky ukončený systémom, pretože istý čas zostal nečinný. Ďakujeme za pochopenie.',
    [`${keys.agentLeftMessage}`]: 'Zástupca pre zákazníkov čet ukončil.',
    [`${keys.chatConnectionErrorMessage}`]: 'Vyskytla sa chyba. Skús to znova.',
    [`${keys.chatInputBarPlaceholderMessage}`]: 'Napíš správu...',
    [`${keys.resendMediaMessage}`]: 'Skús to znova.',
    [`${keys.resendMediaMessageForEmptyImage}`]: 'Zopakuj nahrávanie.',
    [`${keys.unAuthorizedMessage}`]:
      'Nemáš autorizáciu získať prístup k tejto stránke.',
    [`${keys.surveyMessage}`]:
      'Uspokojila ťa konverzácia, ktorú si práve dokončil(a)?',
    [`${keys.trendyolAsistantSurveyMessage}`]:
      'Uspokojil ťa Asistent Trendyol?',
    [`${keys.surveyCommentMessage}`]: 'Chceš pridať ešte niečo?',
    [`${keys.surveyRatingMessage}`]:
      'Ohodnoť svoju skúsenosť s Asistentom Trendyol',
    [`${keys.liveChatSurveyRatingMessage}`]: 'Ohodnoť nášho zástupcu',
    [`${keys.surveyRatingBadMessage}`]: 'Veľmi zlé',
    [`${keys.surveyRatingGoodMessage}`]: 'Veľmi dobré',
    [`${keys.surveyRateButton}`]: 'Odoslať',
    [`${keys.surveyErrorModalTitle}`]: 'VYSKYTLA SA CHYBA PRIESKUMU',
    [`${keys.surveyErrorRatingEmpty}`]: 'Zadaj hodnotenie.',
    [`${keys.surveyErrorServiceError}`]: 'Vyskytla sa chyba. Skús to znova.',
    [`${keys.surveySuccessModalTitle}`]: 'HODNOTENIE ÚSPEŠNÉ',
    [`${keys.surveySuccessModalMessage}`]:
      'Ďakujeme za účasť na našom prieskume!',
    [`${keys.liveChatNewMessageAppTitle}`]: 'Máš novú správu...',
    [`${keys.errorPageMessage}`]: 'Vyskytla sa systémová chyba. Skús to znova.',
    [`${keys.closeOnErrorButton}`]: 'Zatvoriť',
    [`${keys.reconnectOnErrorButton}`]: 'Znova pripojiť',
    [`${keys.liveChatReconnecting}`]: 'Znova sa pripája...',
    [`${keys.nameText}`]: 'Krstné meno',
    [`${keys.lastnameText}`]: 'Priezvisko',
    [`${keys.outOfWorkOurMessage}`]:
      'Zástupcovia služieb zákazníkom sú mimo pracovných hodín. Svoju správu môžeš preposlať. Čo najskôr ti odpovieme.',
    [`${keys.invalidTokenInfoText}`]:
      'Tvoje informácie nedokážeme analyzovať. Opusti aplikáciu a prihlás sa znova.',
    [`${keys.newText}`]: 'NOVINKA',
    [`${keys.emailText}`]: 'E-mail',
    [`${keys.continueLiveChatText}`]: 'Vrátiť sa',
    [`${keys.exitLiveChatModalText}`]: 'Naozaj chceš čet ukončiť?',
    [`${keys.exitAndOpenSurvey}`]: 'Potvrdiť ukončenie četu',
    [`${keys.clientReconnectingMessage}`]:
      'Vážený zákazník, z dôvodu pomalého internetového pripojenia ťa znova odkazujeme k zástupcovi služieb zákazníkom. Tvoj čet bude pokračovať. Žiadame ťa o strpenie a ďakujeme za pochopenie.',
    [`${keys.clientRedirectedMessage}`]:
      'Vážený zákazník, z dôvodu pomalého internetového pripojenia ťa presmerujeme k inému zástupcovi služieb zákazníkom. História konverzácie pre tento čet sa prenesie k novému zástupcovi. Čakaj, prosím, ďakujeme za pochopenie.',
    [`${keys.stickySurveyTitle}`]: 'Uspokojil ťa Asistent Trendyol?',
    [`${keys.stickySurveyRateMessage}`]: 'Ohodnoť nás, prosím',
    [`${keys.stickySurveyCommentMessage}`]: 'Chceš pridať ešte niečo?',
    [`${keys.stickySurveySuccessTitle}`]: 'Dostali sme tvoje hodnotenie!',
    [`${keys.stickySurveySuccessText}`]:
      'Ďakujeme za hodnotenie, ktorým nám umožňuješ zlepšovať sa.',
    [`${keys.stickySurveySuccessButtonText}`]: 'Zatvoriť',
    [`${keys.placeHolderSurname}`]: 'Zadaj priezvisko',
    [`${keys.placeHolderName}`]: 'Zadaj krstné meno',
    [`${keys.yourEmailText}`]: 'E-mail',
    [`${keys.placeholderEmail}`]: 'Zadaj e-mail',
    [`${keys.languageText}`]: 'Jazyk',
    [`${keys.placeholderLanguage}`]: 'Vyber si jazyk',
    [`${keys.deliveryNumberText}`]: 'Číslo doručenia',
    [`${keys.placeholderDeliveryNumber}`]: 'Zadaj číslo doručenia',
    [`${keys.startChatText}`]: 'Spustiť čet',
    [`${keys.internationalTicketCreateSucceedTitle}`]:
      'Ďakujeme za kontaktovanie',
    [`${keys.internationalTicketCreateSucceedText}`]:
      'Dostali sme tvoj e-mail. Pozrieme si ho a čoskoro sa ti ozveme.',
    [`${keys.internationalTicketCreateFailedTitle}`]: '',
    [`${keys.internationalTicketCreateFailedText}`]:
      'Vyskytla sa chyba. Skús to znova.',
    [`${keys.closeButtonText}`]: 'Zatvoriť',
    [`${keys.retryButtonText}`]: 'Skúsiť znova',
    [`${keys.placeholderSubject}`]: 'Zadaj predmet',
    [`${keys.subjectText}`]: 'Predmet',
    [`${keys.internationalSubjectImageText}`]: 'Nahraj obrázky',
    [`${keys.mailDescriptionText}`]: 'Podrobne napíš správu',
    [`${keys.mailSubmitText}`]: 'Odoslať',
    [`${keys.placeHolderDescriptionText}`]: 'Zadaj opis',
    [`${keys.englishLangText}`]: 'Angličtina',
    [`${keys.germanLangText}`]: 'Nemčina',
    [`${keys.englishLangTextShort}`]: 'ENG',
    [`${keys.selectOrderMsg}`]: 'Vybrať',
    [`${keys.shipmentNo}`]: 'Č. doručenia',
    [`${keys.orderSelectedMsg}`]: 'Vybrané',
    [`${keys.quantityText}`]: 'Kus',
    [`${keys.claimOrderClaimableIsNotDone}`]: 'Vyplň polia nižšie.',
    [`${keys.claimQuantityText}`]: 'Vyber množstvo',
    [`${keys.claimReasonText}`]: 'Dôvod',
    [`${keys.countAndPriceText}`]: 'Množstvo a cena:',
    [`${keys.claimOrderReason}`]: 'Vyber dôvod vrátenia',
    [`${keys.selectProductImageText}`]: 'Nahraj obrázky (najviac {{count}})',
    [`${keys.internationalMailSelectProductImageText}`]:
      'Nahraj obrázky (najviac {{count}})',
    [`${keys.claimOrderDescription}`]: 'Pridaj podrobnosti',
    [`${keys.claimSimulationPreviewQuantity}`]: 'Množstvo',
    [`${keys.claimSimulationPreviewProduct}`]: 'Položka',
    [`${keys.claimOrderFillToContinue}`]:
      'Ak chceš pokračovať, správne vyplň polia o množstve a dôvode vrátenia.',
    [`${keys.claimQuantityText}`]: 'Vyber množstvo',
    [`${keys.optionalFileInfoText}`]: 'Nahraj obrázky (nepovinné)',
    [`${keys.imageFormatMismatchErrorText}`]:
      'Nahrať môžeš iba súbory JPG a PNG.',
    [`${keys.imageMaxSizeErrorText}`]:
      'Veľkosť súboru nesmie presiahnuť {{size}} MB.',
    [`${keys.imageCountErrorText}`]:
      'Maximálny počet súborov, ktoré môžeš nahrať, je {{count}}.',
    [`${keys.imageReadError}`]: 'Súbor sa nepodarilo spracovať. Skús to znova.',
    [`${keys.imageUploadError}`]:
      'Pri nahrávaní súboru sa vyskytla chyba. Skús to znova.',
    [`${keys.shipmentSubtitleTotal}`]: 'Celková suma',
    [`${keys.shipmentSubtitleItems}`]: 'Položka',
    [`${keys.orderNo}`]: 'Č. objednávky',
    [`${keys.seeAllAnnouncement}`]: 'Zobraziť všetky oznámenia',
    [`${keys.showMoreTicket}`]: 'Zobraziť viac',
    [`${keys.notificationsTitle}`]: 'Upozornenia',
    [`${keys.totalClaimAmountText}`]: 'Celková refundovaná suma',
    [`${keys.claimAmountText}`]: 'Vrátená suma',
    [`${keys.claimCoinAmountText}`]: 'Vrátené Coiny Trendyol',
    [`${keys.coinText}`]: 'Coiny Trendyol',
    [`${keys.coinUsageMessage}`]: 'Použité',
    [`${keys.coinUsageOrderMessage}`]: 'na túto objednávku!',
    [`${keys.coinsWording}`]: 'mince',
    [`${keys.coinQuantityAndAmountInfo}`]:
      '{{quantity}} {{coinsWording}} ({{amount}} {{currency}})',
    [`${keys.claimStatusCargoText}`]: 'Kde je moje vrátenie tovaru?',
    [`${keys.showMoreShipment}`]: 'Zobraziť viac produktov',
    [`${keys.showMoreShipment}`]: 'Zobraziť viac položiek',
    [`${keys.orderText}`]: 'Moje objednávky',
    [`${keys.ordersText}`]: 'Moje objednávky',
    [`${keys.currencyText}`]: '€',
    [`${keys.copyText}`]: 'Skopírovať',
    [`${keys.copiedText}`]: 'Skopírované',
    [`${keys.deliveryDateText}`]: 'Dátum doručenia:',
    [`${keys.paymentInfoText}`]: 'Platobné údaje',
    [`${keys.subtotalText}`]: 'Medzisúčet',
    [`${keys.cargoText}`]: 'Doručenie',
    [`${keys.totalText}`]: 'Spolu',
    [`${keys.deliveryAddressText}`]: 'Adresa doručenia',
    [`${keys.orderDate}`]: 'Dátum objednávky',
    [`${keys.orderSummary}`]: 'Súhrn objednávky',
    [`${keys.productText}`]: 'položka',
    [`${keys.linkCopied}`]: 'Číslo objednávky skopírované',
    [`${keys.billingAddressText}`]: 'Adresa faktúry',
    [`${keys.detail}`]: 'Podrobnosti',
    [`${keys.deliveryTimeText}`]: 'Dátum doručenia:',
    [`${keys.addExtraImage}`]: 'Pridať obrázky',
    [`${keys.chatbotErrorMsgKey}`]: 'Chyba!',
    [`${keys.chatbotShortErrorMsg}`]: 'Vyskytol sa problém.',
    [`${keys.collectionPointText}`]: 'Miesto vyzdvihnutia',
    [`${keys.commercialText}`]: 'Firemný fakturovaný predaj',
    [`${keys.fastMarketText}`]: 'Rýchly trh',
    [`${keys.mealText}`]: 'Jedlo',
    [`${keys.groupDealText}`]: 'Nakupuj s priateľmi',
    [`${keys.wardrobeText}`]: 'DOLAP',
    [`${keys.inputLanguageNotSupported}`]:
      'Naše služby podpory sú momentálne dostupné iba v angličtine. Svoju žiadosť, prosím, napíš po anglicky.',
    [`${keys.myOrderDetail}`]: 'Podrobnosti mojej objednávky',
    [`${keys.arabicLangText}`]: 'Arabčina',
    [`${keys.showMoreContent}`]: 'Zobraziť viac',
    [`${keys.showLessContent}`]: 'Zobraziť menej',
    [`${keys.bulgariaLangText}`]: 'Bulharčina',
    [`${keys.polandLangText}`]: 'Poľština',
    [`${keys.slovakiaLangText}`]: 'Slovenčina',
    [`${keys.romaniaLangText}`]: 'Rumunčina',
    [`${keys.czechiaLangText}`]: 'Čeština',
    [`${keys.hungaryLangText}`]: 'Maďarčina',
    [`${keys.greeceLangText}`]: 'Gréčtina',
    [`${keys.cancelOrderSelectProduct}`]:
      'Vyber, prosím, položku a dôvod zrušenia.',
    [`${keys.cancelOrderSelectReason}`]: 'Vyber, prosím, dôvod zrušenia',
    [`${keys.selectReason}`]: 'Vyber dôvod',
    [`${keys.other}`]: 'Vysvetlenie',
    [`${keys.explanation}`]: 'Zadaj vysvetlenie k položke, ktorú chceš zrušiť',
    [`${keys.userDisconnectedOnBackground}`]:
      'Táto konverzácia bola ukončená, pretože si počas nej opustil/-a aplikáciu alebo dlho trval čas odozvy. Ďakujeme za pochopenie.',
    [`${keys.refundDetailText}`]: 'Zobraziť podrobnosti o vrátení peňazí',
    [`${keys.refundProgressBankInfo}`]:
      'Čaká sa na spracovanie tvojou finančnou inštitúciou',
    [`${keys.productImageText}`]: 'Obrázok produktu',
    [`${keys.refundAmountText}`]: 'Vrátená suma',
    [`${keys.claimLastModifiedDateTrText}`]:
      'Dátum, ku ktorému ti spoločnosť Trendyol vrátila peniaze',
    [`${keys.claimLastModifiedDateMillaText}`]:
      'Dátum, ku ktorému ti spoločnosť Trendyolmilla vrátila peniaze',
    [`${keys.estimatedRefundDateText}`]: 'Odhadovaný čas vrátenia peňazí',
    [`${keys.bankRefNoText}`]: 'Referenčné číslo banky',
    [`${keys.refundProgressTrInfo}`]: 'Spoločnosť Trendyol ti vrátila peniaze',
    [`${keys.refundProgressMillaInfo}`]:
      'Spoločnosť Trendyolmilla ti vrátila peniaze',
  },
};
