import React, { useContext, useRef } from 'react';
import './Attachment.scss';
import classNames from 'classnames';
import AttachmentIcon from '../../../assets/icons/attachment-icon.svg';
import Garbage from '../../../assets/icons/garbage.svg';
import { useTranslation } from 'react-i18next';
import { addText, loadedText } from '../../../i18n/keys';
import chatGatewayService from '../../../services/chatgateway.service';
import appService from '../../../services/app.service';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import usePersistentState from '../../common/usePersistentState';
import Button from '../buttonGroup/Button';
import Bubble from '../../layout/bubble/Bubble';

const Attachment = ({
  id,
  isSelectable,
  componentData,
  select,
  currentIndex,
}) => {
  const { t } = useTranslation();
  const [attachment, setAttachment] = usePersistentState(id, 'attachment', '');
  const [isAttachmentLoad, setIsAttachmentLoad] = usePersistentState(
    id,
    'isAttachmentLoad',
    false
  );
  const containerRef = useRef();

  const chatBotContext = useContext(ChatBotContext);
  const { setDeliveredProductProblemImageIndex } = chatBotContext;

  const { label, placeHolder } = componentData;

  const onMediaUpload = (e) => {
    let file = e.target.files[0];

    setAttachment(file);
    setIsAttachmentLoad(true);
  };

  const handleAttachmentToGarbage = () => {
    setAttachment('');
    setIsAttachmentLoad(false);
  };

  const onFileInputAreaClicked = (e) => {
    if (!containerRef.current) return;
    const fileInputElement =
      containerRef.current.querySelector('.attachment-input');
    // clear value
    fileInputElement.value = '';
    // trigger file select
    fileInputElement.click();
  };

  const sendAttachment = () => {
    if (isSelectable && isAttachmentLoad) {
      chatGatewayService
        .sendFile(attachment, '', '')
        .then((res) => {
          const link = res.data.data;
          appService.setDeliveredProductProblemImage(link);
          setDeliveredProductProblemImageIndex(currentIndex);
        })
        .catch(() => {});
      let request = {
        event: componentData.event,
        data: {
          file: attachment,
        },
      };
      select(request);
    }
  };

  return (
    <Bubble title={label} isBottomRounded={false}>
      <div
        className={classNames(['attachment', { disabled: !isSelectable }])}
        ref={containerRef}>
        <div className="body">
          {attachment ? (
            <div className="garbage-wrapper">
              <img
                alt="garbage-icon"
                className={classNames({
                  'garbage-icon': true,
                })}
                src={Garbage}
                onClick={() => handleAttachmentToGarbage()}
              />
            </div>
          ) : (
            <div className="placeholder" />
          )}
          <button className="actions" onClick={onFileInputAreaClicked}>
            <label className="action-text">
              <span className="assistant-mandatory">*</span>
              {isAttachmentLoad ? t(loadedText) : placeHolder}
            </label>
            <label
              className={classNames({
                actions__upload: true,
                'actions__upload--disabled': !isAttachmentLoad,
              })}>
              <img
                src={AttachmentIcon}
                alt="attachment-icon"
                className="icon"
              />
              <input
                type="file"
                className="attachment-input"
                disabled={false}
                onChange={(e) => onMediaUpload(e)}
                accept={
                  process.env.REACT_APP_ACCEPTED_ATTACHMENT_TYPES_FOR_IMAGE
                }
                onClick={(e) => {
                  e.target.value = null;
                }}
                data-testid="media-input"
              />
            </label>
          </button>
          <Button
            className="send-button"
            colorType="primary"
            outline={false}
            isSelectable={isAttachmentLoad}
            disabled={!isAttachmentLoad}
            onClick={sendAttachment}>
            {t(addText)}
          </Button>
        </div>
      </div>
    </Bubble>
  );
};

export default Attachment;
