import React, { useReducer } from 'react';
import * as PropTypes from 'prop-types';
import LiveChatContext from './liveChatContext';
import LiveChatReducer from './LiveChatReducer';
import {
  ADD_MESSAGE,
  CLEAR_LIVE_CHAT_CONTEXT,
  COUNT_DOWN_TIMER_START_VALUE,
  IS_AGENT_JOINED,
  IS_AGENT_OR_SYSTEM_JOINED_AT_LEAST_ONCE,
  IS_CONNECTING,
  IS_LITE_MODE,
  IS_MODAL_OPEN,
  IS_SYSTEM_JOINED,
  IS_TIMEOUT,
  IS_TYPING,
  LAST_MESSAGE_POSITION,
  MODIFY_MESSAGE,
  SET_ALERT_MESSAGE,
  SET_IS_AGENT_DISCONNECTED,
  SET_IS_END_CHAT,
  SET_IS_MODAL_ACTIVE,
  SET_IS_OUT_OF_WORK_HOURS,
  SET_IS_RECONNECTING,
  SET_IS_SURVEY_OPENED,
  SET_IS_USER_ALLOWED_TO_TYPE,
  SET_LIVE_CHAT_MODAL,
  SET_MEDIA_UPLOAD_STATUS,
  SET_SHOW_MEDIA_PREVIEW,
} from './liveChatTypes';
import { ModalModel } from '../../constants/models';

const initLiveChatReducer = (initialState) => {
  const stateStr = window.sessionStorage.getItem('liveChatState');

  let persistedState = {};
  if (stateStr) {
    persistedState = JSON.parse(stateStr);
  }

  return { ...initialState, ...persistedState };
};

const LiveChatState = (props) => {
  const initialState = {
    messages: [],
    isAgentJoined: false,
    isSystemJoined: false,
    isAgentOrSystemJoinedAtLeastOnce: false,
    isConnecting: true,
    isTyping: false,
    isTimeOut: false,
    alertMessage: null,
    isUserAllowedToType: false,
    allowedToUploadMedia: false,
    showMediaPreview: { show: false, media: null },
    liveChatModal: new ModalModel(null, []),
    isLiteMode: false,
    isModalOpen: false,
    isReconnecting: false,
    isAgentDisconnected: false,
    isOutOfWorkHours: false,
    isSurveyOpened: false,
    isModalActive: false,
    isEndChat: false,
    lastMessagePosition: null,
    countDownTimerStartValue: process.env.REACT_APP_CHAT_SESSION_TIMEOUT,
  };
  const [state, dispatch] = useReducer(
    LiveChatReducer,
    initialState,
    initLiveChatReducer
  );

  const setIsOutOfWorkHours = (isOutOfWorkHours) => {
    dispatch({ type: SET_IS_OUT_OF_WORK_HOURS, payload: isOutOfWorkHours });
  };

  const setIsModalActive = (isModalActive) => {
    dispatch({ type: SET_IS_MODAL_ACTIVE, payload: isModalActive });
  };

  const setIsEndChat = (isEndChat) => {
    dispatch({ type: SET_IS_END_CHAT, payload: isEndChat });
  };

  const setIsSurveyOpened = (isSurveyOpened) => {
    dispatch({ type: SET_IS_SURVEY_OPENED, payload: isSurveyOpened });
  };

  const setIsReconnecting = (isReconnecting) => {
    dispatch({ type: SET_IS_RECONNECTING, payload: isReconnecting });
  };

  const setIsAgentDisconnected = (isAgentDisconnected) => {
    dispatch({ type: SET_IS_AGENT_DISCONNECTED, payload: isAgentDisconnected });
  };

  const setIsModalOpen = (isModalOpen) => {
    dispatch({ type: IS_MODAL_OPEN, payload: isModalOpen });
  };

  const addMessage = (message) => {
    dispatch({ type: ADD_MESSAGE, payload: message });
  };

  const modifyMessageAtIndex = (newMessage, messageIndex) => {
    dispatch({ type: MODIFY_MESSAGE, payload: { newMessage, messageIndex } });
  };

  const setCountDownTimerStartValue = (countDownTimerStartValue) => {
    dispatch({
      type: COUNT_DOWN_TIMER_START_VALUE,
      payload: countDownTimerStartValue,
    });
  };

  const setIsTimeOut = (isTimeOut) => {
    dispatch({ type: IS_TIMEOUT, payload: isTimeOut });
  };

  const setIsLiteMode = (isLiteMode) => {
    dispatch({ type: IS_LITE_MODE, payload: isLiteMode });
  };

  const setShowMediaPreview = (show, media) => {
    dispatch({ type: SET_SHOW_MEDIA_PREVIEW, payload: { show, media } });
  };

  const setMediaUploadStatus = (status) => {
    dispatch({ type: SET_MEDIA_UPLOAD_STATUS, payload: status });
  };

  const setIsSystemJoined = (isSystemJoined) => {
    dispatch({ type: IS_SYSTEM_JOINED, payload: isSystemJoined });
  };

  const setIsAgentJoined = (isAgentJoined) => {
    dispatch({ type: IS_AGENT_JOINED, payload: isAgentJoined });
  };

  const setLastMessagePosition = (lastMessagePosition) => {
    dispatch({ type: LAST_MESSAGE_POSITION, payload: lastMessagePosition });
  };

  const setIsAgentOrSystemJoinedAtLeastOnce = (
    isAgentOrSystemJoinedAtLeastOnce
  ) => {
    dispatch({
      type: IS_AGENT_OR_SYSTEM_JOINED_AT_LEAST_ONCE,
      payload: isAgentOrSystemJoinedAtLeastOnce,
    });
  };

  const setIsConnecting = (isConnecting) => {
    dispatch({ type: IS_CONNECTING, payload: isConnecting });
  };

  const setIsTyping = (isTyping) => {
    dispatch({ type: IS_TYPING, payload: isTyping });
  };

  const setAlertMessage = (alertMessage) => {
    dispatch({ type: SET_ALERT_MESSAGE, payload: alertMessage });
  };

  const setIsUserAllowedToType = (isAllowed) => {
    dispatch({ type: SET_IS_USER_ALLOWED_TO_TYPE, payload: isAllowed });
  };

  const setLiveChatModal = (liveChatModal) => {
    dispatch({ type: SET_LIVE_CHAT_MODAL, payload: liveChatModal });
  };

  const clearLiveChatContext = () => {
    dispatch({ type: CLEAR_LIVE_CHAT_CONTEXT });
  };

  return (
    <LiveChatContext.Provider
      value={{
        socketConnection: state.socketConnection,
        messages: state.messages,
        isAgentJoined: state.isAgentJoined,
        isSystemJoined: state.isSystemJoined,
        isAgentOrSystemJoinedAtLeastOnce:
          state.isAgentOrSystemJoinedAtLeastOnce,
        isConnecting: state.isConnecting,
        isTyping: state.isTyping,
        isTimeOut: state.isTimeOut,
        alertMessage: state.alertMessage,
        isUserAllowedToType: state.isUserAllowedToType,
        allowedToUploadMedia: state.allowedToUploadMedia,
        showMediaPreview: state.showMediaPreview,
        liveChatModal: state.liveChatModal,
        isLiteMode: state.isLiteMode,
        isModalOpen: state.isModalOpen,
        isReconnecting: state.isReconnecting,
        isAgentDisconnected: state.isAgentDisconnected,
        isOutOfWorkHours: state.isOutOfWorkHours,
        isSurveyOpened: state.isSurveyOpened,
        isModalActive: state.isModalActive,
        isEndChat: state.isEndChat,
        countDownTimerStartValue: state.countDownTimerStartValue,
        lastMessagePosition: state.lastMessagePosition,
        setIsReconnecting,
        addMessage,
        modifyMessageAtIndex,
        setIsAgentJoined,
        setIsSystemJoined,
        setIsAgentOrSystemJoinedAtLeastOnce,
        setIsConnecting,
        setIsTyping,
        setIsTimeOut,
        setCountDownTimerStartValue,
        setAlertMessage,
        setIsUserAllowedToType,
        setMediaUploadStatus,
        setShowMediaPreview,
        setLiveChatModal,
        clearLiveChatContext,
        setIsLiteMode,
        setIsModalOpen,
        setIsAgentDisconnected,
        setIsOutOfWorkHours,
        setIsSurveyOpened,
        setIsModalActive,
        setIsEndChat,
        setLastMessagePosition,
      }}>
      {props.children}
    </LiveChatContext.Provider>
  );
};

LiveChatState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LiveChatState;
