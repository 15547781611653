import './StickyHeader.scss';

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import classNames from 'classnames';

import { englishLangTextShort, notificationsTitle } from '../../../i18n/keys';

import appService from '../../../services/app.service';
import chatbotService from '../../../services/chatbot.service';

import ChatBotContext from '../../../context/chatbot/chatbotContext';
import { PLATFORM_WEB } from '../../../constants';
import Utils from '../../../utils';

import ENFlag from '../../../assets/icons/en-flag.svg';
import closeIcon from '../../../assets/icons/close.svg';
import minimizeIcon from '../../../assets/icons/minimize-btn.svg';
import InfoRounded from '../../../assets/icons/info-rounded.svg';
import SmileIconWhite from '../../../assets/icons/smile-icon-international.svg';
import LeftArrow from '../../../assets/icons/chevron-left.svg';
import {
  ENGLISH_SUPPORT_VERSION_2,
  GET_ENGLISH_SUPPORT,
  GET_INITIAL,
  GET_SHIPMENT_DETAIL,
  LANGUAGE_CODE_EN,
} from '../../../constants/chatAssistantConstant';
import i18n from 'i18next';
import CommonContext from '../../../context/common/commonContext';
import AzCountryChange from '../AzComponents/AzChangeDelivery/AzCountryChange';

const StickyHeader = ({
  onCloseAssistant,
  showSubHeader = true,
  showFront = false,
  showShipmentCountryInfo = false,
  availableShipmentLanguages,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const chatBotContext = useContext(ChatBotContext);
  const {
    setIsOpenNotification,
    isOpenNotification,
    setNotifications,
    notifications,
    isStartRequest,
    setProactiveInfo,
    isShowLanguage,
    setShowLanguage,
    requestToChatBot,
    clearChatHistory,
  } = chatBotContext;

  const commonContext = useContext(CommonContext);
  const { nextStateDelayTimeoutId } = commonContext;

  function onMinimizeClicked() {
    appService.minimizeStickyPopup();
  }

  const onBackNotification = async () => {
    setIsOpenNotification(false);
  };

  useEffect(() => {
    setShowLanguage(appService.englishSupport().isEnabled);
    setLoading(true);
    if (isStartRequest) {
      chatbotService.getChatBotNotifications().then((response) => {
        setNotifications(response);
        setLoading(false);
      });

      if (appService.isProactivePageEnabled()) {
        chatbotService
          .getProactiveInfo()
          .then(setProactiveInfo)
          .catch((error) => console.error(error))
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [isStartRequest]);

  useEffect(() => {
    if (isOpenNotification) {
      setShowLanguage(false);
    } else {
      setShowLanguage(appService.englishSupport().isEnabled);
    }
  }, [isOpenNotification]);

  const onNotificationClicked = async () => {
    if (notifications && !_.isEmpty(notifications)) {
      if (notifications?.tabs && notifications?.tabs.length > 0) {
        setIsOpenNotification(!isOpenNotification);
      }
    }
  };

  const onEngSupportClick = async () => {
    const request = {
      event: GET_ENGLISH_SUPPORT,
    };

    requestToChatBot(request);
  };

  const onChangeLanguage = async (languageCode) => {
    const request = {
      event: 'START',
    };
    const eventName = appService.getEventName();
    if (GET_SHIPMENT_DETAIL === eventName) {
      appService.setEventName(GET_SHIPMENT_DETAIL);
    } else {
      appService.setEventName(GET_INITIAL);
    }

    const acceptedLanguage = Utils.getAcceptepLanguage(languageCode);
    appService.setLanguage(acceptedLanguage);
    await i18n.changeLanguage(acceptedLanguage);

    if (LANGUAGE_CODE_EN === acceptedLanguage) {
      appService.setPrevStateMachineId(appService.getStateMachineId());
    }

    clearChatHistory();
    window.clearTimeout(nextStateDelayTimeoutId);
    requestToChatBot(request);
  };

  return (
    <>
      <div
        className={classNames([
          'chat-bot-header-sticky',
          { 'chat-bot-header-sticky-front': showFront },
          {
            dynamic: appService.isDynamicStylingChannel(),
          },
          { 'mweb-header': appService.getPlatform() !== PLATFORM_WEB },
        ])}
        data-testid="chat-bot-header">
        <div className="header-column">
          <img className="chat-icon" alt="chat-icon" src={SmileIconWhite} />
          <div className="title">
            {t(appService.getDynamicStyleValue('app-title'))}
          </div>
        </div>
        <div className="header-column actions">
          {appService.getPlatform() === PLATFORM_WEB && (
            <img
              onClick={() => onMinimizeClicked()}
              src={minimizeIcon}
              className="minimize-icon"
              alt="minimize"
            />
          )}
          <img
            onClick={() => onCloseAssistant()}
            src={closeIcon}
            className="close-icon"
            alt="close"
          />
        </div>
      </div>
      {showShipmentCountryInfo ? (
        <AzCountryChange
          openedFromSticky={true}
          languages={availableShipmentLanguages}
        />
      ) : (
        <>
          {!loading &&
            showSubHeader &&
            (isOpenNotification ||
              Utils.isLanguageTr() ||
              Utils.isShowHeaderNotification(notifications)) && (
              <div
                className={classNames({
                  'chat-bot-header-sticky-notifications': true,
                  'chat-bot-header-sticky-notifications-front': showFront,
                })}>
                <>
                  {isOpenNotification && (
                    <div className="back-button-wrapper">
                      <img
                        onClick={() => onBackNotification()}
                        src={LeftArrow}
                        alt="back-button"
                      />
                    </div>
                  )}
                  {Utils.isLanguageTr() && (
                    <div
                      onClick={() =>
                        appService.englishSupport().version ===
                        ENGLISH_SUPPORT_VERSION_2
                          ? onChangeLanguage(LANGUAGE_CODE_EN)
                          : onEngSupportClick()
                      }
                      className={classNames([
                        'language-wrapper',
                        {
                          invisible: !isShowLanguage,
                        },
                      ])}>
                      <img
                        src={ENFlag}
                        className="language-icon"
                        alt="languages"
                      />
                      <span className="language-text">
                        {t(englishLangTextShort)}
                      </span>
                    </div>
                  )}
                  {Utils.isShowHeaderNotification(notifications) && (
                    <div
                      className={classNames([
                        'notification-wrapper',
                        {
                          'single-item': !isShowLanguage,
                        },
                      ])}
                      onClick={() => onNotificationClicked()}>
                      <img
                        src={InfoRounded}
                        className="notification-icon"
                        alt="notifications"
                      />
                      {t(notificationsTitle)}
                      {notifications?.totalUnreadCount > 0 && (
                        <span className="notification-count">
                          {notifications?.totalUnreadCount}
                        </span>
                      )}
                    </div>
                  )}
                  {!Utils.isShowHeaderNotification(notifications) && (
                    <div className="notification-wrapper"></div>
                  )}
                </>
              </div>
            )}
        </>
      )}
    </>
  );
};

export default StickyHeader;
