import React from 'react';
import './ChatBotIcon.scss';

import classNames from 'classnames';
import appService from '../../../services/app.service';

const ChatBotIcon = () => {
  return (
    <div
      className={classNames([
        'icon-wrapper',
        {
          dynamic: appService.isDynamicStylingChannel(),
        },
      ])}>
      <img
        className="icon"
        src={appService.getDynamicStyleValue('typing-icon')}
        alt="chat_icon"
      />
    </div>
  );
};

export default ChatBotIcon;
