import './ClaimSimulation.scss';

import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
  claimOrderFillToContinue,
  claimOrderSelectProduct,
  claimOrderSelectProducts,
} from '../../../../../i18n/keys';
import Bubble from '../../../../layout/bubble/Bubble';
import ClaimableItem from './components/claimableItem';
import Alert from '../../../../../assets/icons/alert.svg';
import usePersistentState from '../../../../common/usePersistentState';
import usePersistentReducer from '../../../../common/usePersistentReducer';
import appService from '../../../../../services/app.service';
import ButtonGroup from '../../../buttonGroup/ButtonGroup';
import classNames from 'classnames';

function donesReducer(state, action) {
  switch (action.type) {
    case 'add':
      const index = state.findIndex((item) => {
        const payload = action.payload;
        return payload.field
          ? item[payload.field] === payload.value[payload.field]
          : item === action.payload;
      });
      if (index >= 0) {
        state[index] = action.payload.field
          ? action.payload.value
          : action.payload;
        return [...state];
      }
      return [
        ...state,
        action.payload.field ? action.payload.value : action.payload,
      ];
    case 'remove':
      const payload = action.payload;
      return [
        ...state.filter((item) =>
          payload.field
            ? item[payload.field] !== payload.value
            : item !== payload.value
        ),
      ];
  }
}

function selectedReducer(state, action) {
  switch (action.type) {
    case 'add':
      const index = state.findIndex((item) => {
        const payload = action.payload;
        return payload.field
          ? item.value[payload.field] === payload.value[payload.field]
          : item === action.payload;
      });

      if (index >= 0) {
        state[index] = action.payload.field
          ? action.payload.value
          : action.payload;
        return [...state];
      }
      return [
        ...state,
        action.payload.field ? action.payload.value : action.payload,
      ];
    case 'remove':
      const payload = action.payload;
      return [
        ...state.filter((item) =>
          payload.field
            ? item.value[payload.field] !== payload.value
            : item !== payload.value
        ),
      ];
  }
}

const ClaimSimulation = ({
  id,
  componentData,
  isSelectable,
  select,
  showHover,
  isRenderedFromOutside = false,
}) => {
  const { t } = useTranslation();
  const {
    items,
    reasons,
    isReasonRendered,
    event,
    label,
    submitButtonLabel,
    warningMessage,
    applyFirstSelectedReasonDetailToOthers,
  } = componentData;
  const [selecteds, dispatchSelected] = usePersistentReducer(
    id,
    'selecteds',
    selectedReducer,
    []
  );
  const [dones, dispatchDone] = usePersistentReducer(
    id,
    'dones',
    donesReducer,
    []
  );
  const [isDone, setIsDone] = usePersistentState(id, 'isDone', false);

  const [firstSelectedItemId, setFirstSelectedItemId] = usePersistentState(
    id,
    'firstSelectedItemId',
    ''
  );

  const [firstSelectedReasonDetailId, setFirstSelectedReasonDetailId] =
    usePersistentState(id, 'firstSelectedReasonDetailId');

  const addSelected = (lineItemId) => {
    dispatchSelected({ type: 'add', payload: lineItemId });
  };

  const removeSelected = (lineItemId) => {
    dispatchSelected({ type: 'remove', payload: { value: lineItemId } });
  };

  const addDone = (item) => {
    dispatchDone({
      type: 'add',
      payload: { field: 'lineItemId', value: item },
    });
  };

  const removeDone = (item) => {
    dispatchDone({
      type: 'remove',
      payload: { field: 'lineItemId', value: item.lineItemId },
    });
  };

  const onChangeSelectedItem = (lineItemId, selected) => {
    if (selected && !selecteds.includes(lineItemId)) {
      addSelected(lineItemId);
    } else if (!selected) {
      removeSelected(lineItemId);
    }
  };
  const onChangeDoneItem = (item) => {
    if (item.done) {
      addDone(item);
    } else {
      removeDone(item);
    }
  };

  useEffect(() => {
    setIsDone(selecteds.length !== 0 && selecteds.length === dones.length);
  }, [selecteds, dones]);

  useLayoutEffect(() => {
    items
      .filter((item) => item.externalSelectedReasonId > 0)
      .map((item) => item.lineItemId)
      .forEach((lineItemId) => addSelected(lineItemId));
  }, [items]);

  const onClickClaimOrder = () => {
    if (isSelectable && isDone) {
      const latestDoneItem = dones[dones.length - 1];
      const requestPayload = {
        event,
        data: {
          itemList: dones.map((doneItem) => ({
            ...doneItem,
          })),
          shipmentNumber: latestDoneItem.shipmentNumber,
          orderNumber: latestDoneItem.orderNumber,
        },
      };
      select(requestPayload);
    }
  };

  const buttonLabel =
    submitButtonLabel ||
    (selecteds.length > 0
      ? t(claimOrderSelectProduct).replace('{0}', selecteds.length)
      : t(claimOrderSelectProducts));

  return (
    <div className="claim-simulation-wrapper">
      <Bubble title={label} isBottomRounded={false}>
        <div
          className={classnames([
            'claim-simulation-order',
            { disabled: !isSelectable, readonly: isRenderedFromOutside },
          ])}>
          <div className="claim-simulation-order-wrapper">
            {items.map((claimableItem, i) => (
              <ClaimableItem
                id={id + 'ClaimableItem' + i}
                key={`${claimableItem.shipmentNumber}-${i}`}
                lineItemId={claimableItem.lineItemId}
                listingId={claimableItem.listingId}
                shipmentNumber={claimableItem.shipmentNumber}
                orderNumber={claimableItem.orderNumber}
                imgSource={claimableItem.images[0]}
                title={claimableItem.title}
                totalQuantity={claimableItem.quantity}
                variantName={claimableItem.variantName}
                selectedQuantity={claimableItem.selectedQuantity}
                preSelectedExternalReasonId={
                  claimableItem.externalSelectedReasonId
                }
                preDescription={claimableItem.description}
                reasons={reasons}
                isReasonRendered={isReasonRendered}
                onChangeSelectedItem={onChangeSelectedItem}
                onChangeDoneItem={onChangeDoneItem}
                firstSelectedItemId={firstSelectedItemId}
                onFirstSelectedItemIdChange={setFirstSelectedItemId}
                firstSelectedReasonDetailId={firstSelectedReasonDetailId}
                onFirstSelectedReasonDetailIdChange={
                  setFirstSelectedReasonDetailId
                }
                maxDescriptionLength={
                  claimableItem.maxDescriptionLength
                    ? claimableItem.maxDescriptionLength
                    : 500
                }
                applyFirstSelectedReasonDetailToOthers={
                  applyFirstSelectedReasonDetailToOthers ?? true
                }
                showHover={showHover}
                select={select}
              />
            ))}
          </div>
          <ButtonGroup.Button
            isSelectable={isDone && isSelectable}
            colorType="primary"
            className={classNames({
              international: appService.isInternational(),
            })}
            data-testid="request-button"
            onClick={onClickClaimOrder}
            outline={false}
            disabled={!isDone || !isSelectable}>
            {buttonLabel}
          </ButtonGroup.Button>
          {selecteds.length > 0 && !isDone && (
            <div className="alert-container">
              <img src={Alert} className="alert-icon" />
              <span>{warningMessage || t(claimOrderFillToContinue)}</span>
            </div>
          )}
        </div>
      </Bubble>
    </div>
  );
};

export default ClaimSimulation;
