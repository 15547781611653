import { useState, useEffect, useReducer } from 'react';
import { DataType } from '../../constants/chatAssistantConstant';
import usePersistentState from './usePersistentState';

const usePersistentReducer = (
  id,
  stateName,
  reducerFunc,
  initialValue,
  dataType = DataType.STRING
) => {
  const [state, setState] = usePersistentState(
    id,
    stateName,
    initialValue,
    dataType
  );

  const [dispatchState, setDispatch] = useReducer(reducerFunc, state);

  useEffect(() => {
    setState(dispatchState);
  }, [dispatchState]);

  return [dispatchState, setDispatch];
};

export default usePersistentReducer;
