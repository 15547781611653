import EchoLiveChatMessages from './EchoLiveChatMessages';
import {
  ChatStatus,
  EchoLiveChatProvider,
  useEchoLiveChat,
} from './context/echoLiveChatContext';
import EchoLiveChatHeader from './components/EchoLiveChatHeader';
import EchoChatInputBar from './components/EchoChatInputBar';
import React, { useCallback, useContext, useEffect } from 'react';
import CustomAlert from '../layout/customAlert/CustomAlert';
import {
  imageMaxSizeErrorText,
  imageReadError,
  maxTimeOutThresholdExceedMessage,
  outOfWorkOurMessage,
} from '../../i18n/keys';
import {
  EchoChatInitProvider,
  useEchoChatInit,
} from './context/echoChatInitContext';
import appService from '../../services/app.service';
import {
  pages,
  USER_APP_STATE_BACKGROUND,
  USER_APP_STATE_FOREGROUND,
} from '../../constants';
import liveChatService from '../../services/livechat.service';
import { SURVEY_SECURE_KEY } from '../../constants/sessionStorageConstants';
import { useTranslation } from 'react-i18next';
import TransitionScreen from '../chatAssistant/transitionScreen/TransitionScreen';
import EchoTimeOutWarning from './components/EchoTimeOutWarning';
import OrderModal from './components/orderModal/OrderModal';
import CustomModal from '../layout/customModal/CustomModal';
import { MODAL_INFO } from '../../constants/modalTypes';
import { ModalModel } from '../../constants/models';
import Utils from '../../utils';
import {
  EventChannel,
  SystemPayloadType,
} from '@customer-service/echo-messaging-lib';
import {
  MESSAGE_TYPE_DOCUMENT,
  MESSAGE_TYPE_IMAGE,
  MESSAGE_TYPE_PDF,
  MESSAGE_TYPE_VIDEO,
} from '../../constants/liveChatConstants';
import uploadMedia from './context/uploadMedia';
import EchoMediaPreview from './components/EchoMediaPreview';
import EchoLiveChatCloseModal from './components/EchoLiveChatCloseModal';
import liveChatContext from '../../context/liveChat/liveChatContext';
import { message } from 'antd';
import CustomToast from '../layout/customToast/CustomToast';
import { ReactComponent as ErrorIconWhite } from '../../assets/icons/circle-info-white.svg';
import uploadVideo from './context/uploadVideo';
import chatgatewayService from '../../services/chatgateway.service';
import usePersistentState from '../common/usePersistentState';
import Spinner from '@tx-components/spinner';
import Embedded from './components/embedded/Embedded';

const files = {};

export function setFile(messageId, file) {
  files[messageId] = file;
}

export function getFile(messageId) {
  return files[messageId];
}

function EchoLiveChat() {
  const { t } = useTranslation();
  const {
    allowMediaUpload,
    // loadingMessage,
    alertMessage,
    isOutOfWorkHours,
    isUserBlocked,
    loading: chatParamsLoading,
    reload,
    userIdentifier,
    chatId,
  } = useEchoChatInit();
  const {
    client,
    isConnected,
    sendMessage,
    onTextAreaChange,
    chatStatus,
    messages,
    showTimeoutModal,
    setShowTimeoutModal,
    continueChat,
    endChat,
    embeddedContent,
    setEmbeddedContent,
    showOrdersModal,
    setShowOrdersModal,
    chatEndReason,
    setChatEndReason,
    addMessage,
    updateMessage,
    showMediaModal,
    setShowMediaModal,
    mediaInfo,
    setMediaInfo,
    isTyping,
    showCloseModal,
    setShowCloseModal,
    agentStatus,
    setChatStatus,
  } = useEchoLiveChat();
  const { setIsSurveyOpened } = useContext(liveChatContext);

  const [isLoading, setIsLoading] = usePersistentState(
    'live-chat',
    'isLoading',
    false
  );

  const onMessageChange = useCallback(
    (text) => {
      onTextAreaChange(text);
    },
    [onTextAreaChange]
  );

  const onTextMessage = useCallback(
    (text) => {
      sendMessage(text);
    },
    [sendMessage]
  );

  const [messageApi, contextHolder] = message.useMessage();

  function getToastIcon() {
    return (
      <span
        role="img"
        aria-label="exclamation-circle"
        className="anticon anticon-exclamation-circle">
        <ErrorIconWhite />
      </span>
    );
  }

  const onMediaMessage = useCallback(
    async ({ fileType, file }) => {
      let isFailed = false;
      let failReason = null;
      if (!file.size) {
        messageApi.open({
          type: 'warning',
          content: t(imageReadError),
          duration: 3,
          icon: getToastIcon(),
        });
        return;
      }

      const maxFileSize = appService.getUploadSizeLimitByFileType(fileType);

      if (Utils.isCompressableImage(file, maxFileSize)) {
        setIsLoading(true);
        file = await Utils.getCompressedImage(file, maxFileSize);
        setIsLoading(false);
      }

      if (!Utils.isFileSizeInLimit(fileType, file)) {
        messageApi.open({
          type: 'warning',
          content: t(imageMaxSizeErrorText, {
            size: maxFileSize,
          }),
          duration: 3,
          icon: getToastIcon(),
        });

        return;
      }

      if (
        !(
          fileType === MESSAGE_TYPE_IMAGE ||
          fileType === MESSAGE_TYPE_PDF ||
          fileType === MESSAGE_TYPE_DOCUMENT ||
          fileType === MESSAGE_TYPE_VIDEO
        )
      ) {
        messageApi.open({
          type: 'warning',
          content: t(imageReadError),
          duration: 3,
          icon: getToastIcon(),
        });
        return;
      }

      if (!isConnected) {
        isFailed = true;
        failReason = 'NOT_CONNECTED';
      }

      const id = Utils.uuid();

      setFile(id, file);

      addMessage({
        id: id,
        message: URL.createObjectURL(file),
        direction: 'outgoing',
        system: true,
        systemPayload: {
          type: SystemPayloadType.ATTACHMENT,
          fileType,
          isFailed: isFailed,
          isLoading: !isFailed,
          failReason,
        },
      });

      // stop flow if preCheck failed
      if (failReason) {
        return;
      }

      try {
        let fileType = Utils.getFileType(file);

        let uploadedUrl;

        if (fileType === MESSAGE_TYPE_VIDEO) {
          uploadedUrl = await uploadVideo(file);
        } else {
          uploadedUrl = await uploadMedia(file);
        }

        const messageEvent = {
          id: Utils.uuid(),
          message: uploadedUrl,
          userIdentifier,
          chatId,
          system: true,
          systemPayload: {
            type: SystemPayloadType.ATTACHMENT,
          },
        };

        await client?.sendMessage(EventChannel.SendMessage, messageEvent);

        updateMessage(id, {
          message: uploadedUrl,
          systemPayload: {
            type: SystemPayloadType.ATTACHMENT,
            fileType,
            isFailed: false,
            isLoading: false,
            failReason: null,
          },
        });
      } catch (e) {
        console.error('error while upload and sendMessage', e);
        updateMessage(id, {
          systemPayload: {
            type: SystemPayloadType.ATTACHMENT,
            fileType,
            isFailed: true,
            isLoading: false,
            failReason: 'UPLOAD_ERROR',
          },
        });
      }
    },
    [isConnected, client, userIdentifier, chatId]
  );

  const onReSendMediaMessage = async (message) => {
    if (!(message && message.systemPayload && message.systemPayload.file)) {
      console.error('resend called but file is not in the context');
    }

    const id = message.id;

    try {
      const file = getFile(id);
      let fileType = Utils.getFileType(file);

      let uploadedUrl;

      if (fileType === MESSAGE_TYPE_VIDEO) {
        uploadedUrl = await uploadVideo(file);
      } else {
        uploadedUrl = await uploadMedia(file);
      }

      const messageEvent = {
        id: Utils.uuid(),
        message: uploadedUrl,
        userIdentifier,
        chatId,
        system: true,
        systemPayload: {
          type: SystemPayloadType.ATTACHMENT,
        },
      };

      await client?.sendMessage(EventChannel.SendMessage, messageEvent);

      updateMessage(id, {
        message: uploadedUrl,
        systemPayload: {
          type: SystemPayloadType.ATTACHMENT,
          fileType: message.systemPayload.fileType,
          isFailed: false,
          isLoading: false,
          failReason: null,
        },
      });
    } catch (e) {
      console.error('error while upload and sendMessage', e);
      updateMessage(id, {
        systemPayload: {
          type: SystemPayloadType.ATTACHMENT,
          fileType: message.systemPayload.fileType,
          isFailed: true,
          isLoading: false,
          failReason: 'UPLOAD_ERROR',
        },
      });
    }
  };

  const closeLiveChat = async () => {
    try {
      await endChat();
    } catch (e) {
      console.error('error while ending chat', e);
    }
    let surveySecureKey = await liveChatService.getSecureKey();
    if (surveySecureKey) {
      const surveyEligibility =
        await chatgatewayService.getChatBotSurveyEligibility(surveySecureKey);

      if (surveyEligibility.data.eligibility) {
        await window.customStorage.setItem(SURVEY_SECURE_KEY, surveySecureKey);
        await appService.setCurrentPage(pages.LIVECHAT_SURVEY);
        setIsSurveyOpened(true);
      } else {
        if (appService.getIsStickyAssistant()) {
          appService.closeStickyPopup();
        } else {
          await appService.exitAssistant();
        }
      }
    } else {
      if (appService.getIsStickyAssistant()) {
        appService.closeStickyPopup();
      } else {
        await appService.exitAssistant();
      }
    }
  };

  const handleBack = useCallback(async () => {
    let surveySecureKey = await liveChatService.getSecureKey();
    if (!surveySecureKey) {
      await appService.exitAssistant();
    } else {
      await window.customStorage.setItem(SURVEY_SECURE_KEY, surveySecureKey);
      const surveySecureKey = await liveChatService.getSecureKey();
      if (chatStatus === ChatStatus.ENDED && surveySecureKey) {
        const surveyEligibility =
          await chatgatewayService.getChatBotSurveyEligibility(surveySecureKey);
        if (surveyEligibility.data.eligibility) {
          await appService.setCurrentPage(pages.LIVECHAT_SURVEY);
          setIsSurveyOpened(true);
        } else {
          setShowCloseModal(true);
        }
      } else {
        setShowCloseModal(true);
      }
    }
  }, [chatStatus]);

  const handleBackgroundEvent = useCallback(
    async () =>
      await chatgatewayService.updateUserAppState(USER_APP_STATE_BACKGROUND),
    [chatStatus]
  );

  const handleForegroundEvent = useCallback(
    async () =>
      await chatgatewayService.updateUserAppState(USER_APP_STATE_FOREGROUND),
    [chatStatus]
  );

  useEffect(() => {
    document.addEventListener('handleAndroidBackButton', handleBack);
    document.addEventListener('handleRightSwipeAction', handleBack);
    document.addEventListener(
      'handleDidEnterBackgroundAction',
      handleBackgroundEvent
    );
    document.addEventListener(
      'handleApplicationWillEnterForegroundAction',
      handleForegroundEvent
    );
    return () => {
      document.removeEventListener('handleAndroidBackButton', handleBack);
      document.removeEventListener('handleRightSwipeAction', handleBack);
      document.removeEventListener(
        'handleDidEnterBackgroundAction',
        handleBackgroundEvent
      );
      document.removeEventListener(
        'handleApplicationWillEnterForegroundAction',
        handleForegroundEvent
      );
    };
  }, [handleBack]);

  const onTimeout = useCallback(() => {
    setShowTimeoutModal(false);
    setChatEndReason(t(maxTimeOutThresholdExceedMessage));
    setChatStatus(ChatStatus.ENDED);
  }, []);

  if (isLoading)
    return <Spinner className="loading-spinner" size="48" color="#f27a1a" />;

  return (
    <>
      <EchoLiveChatHeader />
      {(chatParamsLoading || chatStatus === ChatStatus.CONNECTING) && (
        <TransitionScreen />
      )}
      {(isOutOfWorkHours || alertMessage) && (
        <CustomAlert
          id="EchoLiveChat"
          alertMessage={alertMessage || t(outOfWorkOurMessage)}
          closeLiveChat={closeLiveChat}
          reload={reload}
          isConnecting={false}
          isError={true}
          isOutOfWorkHours={isOutOfWorkHours}
        />
      )}
      {(isUserBlocked || alertMessage) && (
        <CustomAlert
          id="EchoLiveChat"
          alertMessage={alertMessage || t(outOfWorkOurMessage)}
          closeLiveChat={closeLiveChat}
          reload={reload}
          isConnecting={false}
          isError={true}
          isUserBlocked={isUserBlocked}
        />
      )}
      {isConnected && showTimeoutModal && (
        <EchoTimeOutWarning
          targetTimestamp={showTimeoutModal}
          show={!!showTimeoutModal}
          onExit={closeLiveChat}
          onContinue={continueChat}
          onTimeout={onTimeout}
        />
      )}
      {embeddedContent && (
        <Embedded
          embeddedContent={embeddedContent}
          setEmbeddedContent={setEmbeddedContent}
        />
      )}
      {showOrdersModal && (
        <OrderModal
          isModalOpen={showOrdersModal}
          setIsModalOpen={setShowOrdersModal}
        />
      )}
      {showMediaModal && (
        <EchoMediaPreview
          show={showMediaModal}
          setShow={setShowMediaModal}
          mediaInfo={mediaInfo}
          setMediaInfo={setMediaInfo}
        />
      )}
      {showCloseModal && (
        <EchoLiveChatCloseModal
          show={showCloseModal}
          onContinue={continueChat}
          onExit={closeLiveChat}
        />
      )}
      {chatStatus === ChatStatus.ENDED && chatEndReason && (
        <CustomModal
          modalModel={new ModalModel(chatEndReason, null)}
          onCloseModal={() => setChatEndReason('')}
          type={MODAL_INFO}
        />
      )}
      <EchoLiveChatMessages
        isTyping={isTyping}
        messages={messages}
        setShowMediaModal={setShowMediaModal}
        setMediaInfo={setMediaInfo}
        isAgentJoined={agentStatus !== 'NOT_CONNECTED'}
        onReSend={onReSendMediaMessage}
      />
      <CustomToast contextHolder={contextHolder} />
      <EchoChatInputBar
        onMessageChange={onMessageChange}
        connected={isConnected}
        disabled={chatStatus !== ChatStatus.CONNECTED || !isConnected}
        onMessage={onTextMessage}
        allowUploads={allowMediaUpload}
        onMedia={onMediaMessage}
      />
    </>
  );
}

export default function EchoLiveChatWithProvider() {
  return (
    <EchoChatInitProvider>
      <EchoLiveChatProvider>
        <EchoLiveChat />
      </EchoLiveChatProvider>
    </EchoChatInitProvider>
  );
}
