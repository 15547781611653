import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { DataType } from '../../constants/chatAssistantConstant';
import appService from '../../services/app.service';

const stateMap = window.sessionStorage.getItem('stateMap')
  ? JSON.parse(window.sessionStorage.getItem('stateMap'))
  : {};

const persistState = () => {
  if (appService.getIsStickyAssistant()) {
    window.sessionStorage.setItem('stateMap', JSON.stringify(stateMap));
  }
};

const debouncedSet = debounce(persistState, 500, {});

function formatValue(objValue, dataType) {
  let value;
  if (dataType === DataType.DATE) {
    value = new Date(JSON.parse(objValue));
  } else {
    value = JSON.parse(objValue);
  }
  return value;
}

const usePersistentState = (
  id,
  stateName,
  initialValue,
  dataType = DataType.STRING
) => {
  const key = 'state/'.concat(id).concat('/').concat(stateName);
  const value = stateMap[key];

  const [state, setState] = useState(
    value ? formatValue(value, dataType) : initialValue
  );

  useEffect(() => {
    stateMap[key] = JSON.stringify(state);

    if (appService.getIsStickyAssistant()) {
      debouncedSet();
    }
  }, [state]);

  return [state, setState];
};

export default usePersistentState;
