import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './CustomerInfo.scss';

import appService from '../../../../services/app.service';

import classNames from 'classnames';
import usePersistentState from '../../../common/usePersistentState';
import {
  EMAIL_REGEX,
  ONLY_LETTER_REGEX,
  ONLY_NUMBERIC_REGEX,
} from '../../../../constants/validationRegexConstants';
import Select from '../../../Select';
import { pages } from '../../../../constants';
import EchoLiveChat from '../../../echoLiveChat/EchoLiveChat';
import StickyHeader from '../../../chatAssistant/stickyHeader/StickyHeader';
import {
  arabicLangText,
  bulgariaLangText,
  czechiaLangText,
  deliveryNumberText,
  emailText,
  englishLangText,
  germanLangText,
  greeceLangText,
  hungaryLangText,
  inputLanguageNotSupported,
  languageText,
  lastnameText,
  nameText,
  placeholderDeliveryNumber,
  placeholderEmail,
  placeholderLanguage,
  placeHolderName,
  placeHolderSurname,
  polandLangText,
  romaniaLangText,
  slovakiaLangText,
  startChatText,
  yourEmailText,
} from '../../../../i18n/keys';
import Utils from '../../../../utils';
import ChatBotContext from '../../../../context/chatbot/chatbotContext';
import chatGatewayService from '../../../../services/chatgateway.service';
import InputLabel from '../../../common/InputLabel/InputLabel';
import ButtonGroup from '../../../chatAssistant/buttonGroup/ButtonGroup';
import Alert from '../../../layout/alert/Alert';

const CustomerInfo = ({ id = 'internationalCustomerInfo' }) => {
  const chatBotContext = useContext(ChatBotContext);
  const { requestForDeepLink } = chatBotContext;

  const { t, i18n } = useTranslation();
  const [firstName, setFirstName] = usePersistentState(id, 'firstname', '');
  const [lastName, setLastName] = usePersistentState(id, 'lastname', '');
  const [email, setEmail] = usePersistentState(id, 'email', '');
  const [language, setLanguage] = usePersistentState(id, 'language', '');
  const [deliveryNumber, setDeliveryNumber] = usePersistentState(
    id,
    'deliverynumber',
    appService.getShipmentNumber() ? appService.getShipmentNumber() : ''
  );
  const [isDone, setIsDone] = usePersistentState(id, 'isDone', false);
  const [isArabicErrorOccured, setIsArabicErrorOccured] = usePersistentState(
    id,
    'isArabicErrorOccured',
    false
  );

  const [liveChatOpen, setLiveChatOpen] = usePersistentState(
    id,
    'liveChatOpen',
    false
  );

  const [languageCombobox, setLanguageCombobox] = useState([]);

  const languageOptions = [
    {
      langCode: 'en',
      value: 1,
      label: t(englishLangText),
    },
    {
      langCode: 'de',
      value: 2,
      label: t(germanLangText),
    },
    {
      langCode: 'ar',
      value: 3,
      label: t(arabicLangText),
    },
    {
      langCode: 'bg',
      value: 4,
      label: t(bulgariaLangText),
    },
    {
      langCode: 'cs',
      value: 5,
      label: t(czechiaLangText),
    },
    {
      langCode: 'el',
      value: 6,
      label: t(greeceLangText),
    },
    {
      langCode: 'hu',
      value: 7,
      label: t(hungaryLangText),
    },
    {
      langCode: 'pl',
      value: 8,
      label: t(polandLangText),
    },
    {
      langCode: 'ro',
      value: 9,
      label: t(romaniaLangText),
    },
    {
      langCode: 'sk',
      value: 10,
      label: t(slovakiaLangText),
    },
  ];

  function setIsDoneForAuthenticatedUser() {
    return setIsDone(language);
  }

  const validateNonArabic = (value) => {
    if (Utils.isInputArabic(value)) {
      setIsArabicErrorOccured(true);
      return false;
    }
    setIsArabicErrorOccured(false);
    return true;
  };

  useEffect(() => {
    if (appService.isAuthenticatedInternationalUser()) {
      setIsDoneForAuthenticatedUser();
    } else {
      setIsDone(
        firstName && lastName && language && email && validateEmail(email)
      );
    }
  }, [firstName, lastName, email, language, setIsDone]);

  function validateEmail(email) {
    return EMAIL_REGEX.test(String(email).toLowerCase());
  }

  const handleFirstName = (value) => {
    if (validateNonArabic(value)) {
      if (value.length >= 80) {
        value = value.substring(0, 80);
      }
      setFirstName(value);
      return;
    }

    if (value.length >= 80) {
      value = value.substring(0, 80);
    }
    let newValue = value.replace(ONLY_LETTER_REGEX, '');
    setFirstName(newValue);
  };

  const handleLastName = (value) => {
    if (validateNonArabic(value)) {
      if (value.length >= 80) {
        value = value.substring(0, 80);
      }
      setLastName(value);
      return;
    }

    if (value.length >= 80) {
      value = value.substring(0, 80);
    }
    let newValue = value.replace(ONLY_LETTER_REGEX, '');
    setLastName(newValue);
  };

  const handleEmail = (value) => {
    setEmail(value);
  };

  const handleLanguage = (value) => {
    setLanguage(languageCombobox.find((lang) => lang.value === value));
  };

  const handleDeliveryNumber = (value) => {
    if (value.length >= 14) {
      value = value.substring(0, 14);
    }
    let newValue = value.replace(ONLY_NUMBERIC_REGEX, '');
    setDeliveryNumber(newValue);
  };

  const onCloseAssistant = async () => {
    if (appService.getIsStickyAssistant()) {
      appService.closeStickyPopup();
    } else {
      await appService.exitAssistant();
    }
  };

  async function getLanguages() {
    await chatGatewayService
      .getLiveChatSupportedLanguages()
      .then((response) => {
        setLanguageCombobox(
          languageOptions.filter(
            (langItem) => response && response.includes(langItem.langCode)
          )
        );
      })
      .catch(() => {});
  }

  useEffect(() => {
    if (languageCombobox.length === 0) {
      getLanguages();
    }
  }, []);

  async function handleSubmit() {
    const customerInfo = {
      firstName,
      lastName,
      email,
      deliveryNumber,
    };

    const deepLinkRequest = {
      event: 'GET_DEEP_LINK',
      data: {
        deepLinkEventLabel: 'CONNECT_TO_MT',
      },
    };
    requestForDeepLink(deepLinkRequest);

    changeLanguage(language.langCode);
    appService.setInternationalCustomerInfo(customerInfo);
    appService.setIsLiveChatDeepLinkClicked(true);
    await appService.setCurrentPage(pages.LIVECHAT);
    setLiveChatOpen(true);
  }

  const changeLanguage = (lang) => {
    const acceptedLanguage = Utils.getAcceptepLanguage(lang);
    appService.setLanguage(acceptedLanguage);
    i18n.changeLanguage(acceptedLanguage);
  };

  if (liveChatOpen) {
    return <EchoLiveChat />;
  }

  return (
    <div className="customer-info-wrapper">
      <StickyHeader onCloseAssistant={onCloseAssistant} showSubHeader={false} />
      <div className="international-customer-info-container">
        <div className="content-wrapper">
          <div className="inner-content-wrapper">
            <div
              className={classNames({
                'name-fields': true,
                row: true,
                authenticated: appService.isAuthenticatedInternationalUser(),
              })}>
              <div className="first-name">
                <InputLabel text={t(nameText)} required />

                <input
                  type="text"
                  value={firstName}
                  className="assistant-input"
                  placeholder={t(placeHolderName)}
                  onChange={(e) => handleFirstName(e.target.value)}
                  alt={t(nameText)}
                />
              </div>
              <div className="last-name">
                <InputLabel text={t(lastnameText)} required />

                <input
                  type="text"
                  value={lastName}
                  className="assistant-input"
                  placeholder={t(placeHolderSurname)}
                  onChange={(e) => handleLastName(e.target.value)}
                  alt={t(lastnameText)}
                />
              </div>
            </div>
            <div
              className={classNames({
                email: true,
                row: true,
                authenticated: appService.isAuthenticatedInternationalUser(),
              })}>
              <InputLabel text={t(yourEmailText)} required />

              <input
                type="text"
                value={email}
                className="assistant-input"
                placeholder={t(placeholderEmail)}
                onChange={(e) => handleEmail(e.target.value)}
                alt={t(emailText)}
              />
            </div>
            <div className="language row">
              <Select
                label={t(languageText)}
                options={languageCombobox}
                required
                placeholder={t(placeholderLanguage)}
                value={language.value}
                onChange={handleLanguage}
              />
            </div>
            {!appService.getShipmentNumber() && (
              <div className="delivery-number row">
                <InputLabel text={t(deliveryNumberText)} />
                <div className="input-area">
                  <input
                    type="text"
                    value={deliveryNumber}
                    className="assistant-input"
                    placeholder={t(placeholderDeliveryNumber)}
                    onChange={(e) => handleDeliveryNumber(e.target.value)}
                    alt={t(deliveryNumberText)}
                  />
                </div>
              </div>
            )}
          </div>
          {isArabicErrorOccured && (
            <div className="row">
              <Alert message={t(inputLanguageNotSupported)} />
            </div>
          )}

          <ButtonGroup>
            <ButtonGroup.Button
              colorType="primary"
              outline={false}
              isSelectable={isDone}
              disabled={!isDone}
              onClick={handleSubmit}>
              {t(startChatText)}
            </ButtonGroup.Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
