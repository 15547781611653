import React from 'react';
import { useTranslation } from 'react-i18next';
import './Alert.scss';
import { ReactComponent as AlertIcon } from '../../../assets/icons/alert.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/circle-info.svg';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

const Alert = ({
  messages,
  message,
  localize = false,
  background = true,
  orange = false,
  info = false,
  theme = false,
}) => {
  const { t } = useTranslation();

  const alertMessages = messages ? messages : message ? [message] : [];

  if (!alertMessages.length) {
    return null;
  }

  return (
    <div
      className={classNames([
        'layout-alert',
        { background, info, orange, theme },
      ])}
      role="alert">
      <div
        className={classNames(['layout-alert-icon', { orange, info, theme }])}>
        {info && <InfoIcon data-testid="info-icon" />}
        {!info && <AlertIcon data-testid="alert-icon" />}
      </div>
      <div
        className={classNames([
          'layout-alert-message',
          { orange, info, theme },
        ])}>
        {alertMessages.map((message, index) => {
          return (
            <div
              key={`alert-${index}`}
              role="alert-message"
              className={classNames([
                'layout-alert-message-row',
                { orange, info },
              ])}>
              {localize ? t(message) : <ReactMarkdown children={message} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Alert;
