export class LiveChatMessage {
  constructor(message, from, type, mediaURL, file, isLoading, isFailed) {
    this.message = message;
    this.from = from;
    this.type = type;
    this.mediaURL = mediaURL;
    this.file = file;
    this.isLoading = isLoading;
    this.isFailed = isFailed;
  }
}

export class SendClickEventRequest {
  constructor(event, pid, tv003, sid, tv007, tv056, tv057, tv074, channel) {
    this.event = event;
    this.pid = pid;
    this.tv003 = tv003;
    this.sid = sid;
    this.tv007 = tv007;
    this.tv056 = tv056;
    this.tv057 = tv057;
    this.tv074 = tv074;
    this.channel = channel;
  }
}

export class SendSurveyRequest {
  constructor(chatId, rating, comment, orderId) {
    this.chatId = chatId; // string
    this.rating = rating; // number
    this.comment = comment; // string
    this.orderId = orderId; // string
  }
}

export class ModalModel {
  constructor(title, messages) {
    this.title = title; // string
    this.messages = messages; // array
  }
}
