import React from 'react';
import Select from '../../../Select';

import { comboboxDefaultValue } from '../../../../i18n/keys';
import { useTranslation } from 'react-i18next';

export default function ComboboxInput({ item, ...rest }) {
  const { t } = useTranslation();

  return (
    <Select
      {...rest}
      searchable={item.showSearch}
      placeholder={item.placeholder || t(comboboxDefaultValue)}
      allowClear={true}
      options={item.items.map((item) => ({
        value: item.id,
        label: item.name,
        disabled: item.disabled || false,
      }))}
    />
  );
}
