import React, { useContext } from 'react';
import './FileUpload.scss';
import * as PropTypes from 'prop-types';
import Utils from '../../../utils';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import classNames from 'classnames';
import usePersistentState from '../../common/usePersistentState';
import { withRouter } from 'react-router-dom';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import FileInput from '../fileInput/FileInput';
import { GO_DELIVERED_PRODUCT_RECEIPT_ACCEPTED_FORMATS } from '../../../constants';
import Alert from '../../layout/alert/Alert';
import infoIcon from '../../../assets/icons/info.svg';
import { UPLOAD_VERSION_2 } from '../../../constants/chatAssistantConstant';
import Bubble from '../../layout/bubble/Bubble';

const FileUpload = (data) => {
  const chatBotContext = useContext(ChatBotContext);
  const { requestForDeepLink } = chatBotContext;
  const { id, componentData, history, showHover, select, isSelectable } = data;

  let linkStyle;

  if (showHover) {
    linkStyle = { cursor: 'pointer' };
  } else {
    linkStyle = {
      cursor: 'unset',
      border: '1px solid #e6e6e6',
      color: '#1b1b1b',
    };
  }

  const { actionButton, fileInput, previewDeepLink } = componentData;

  const [uploadedImages, setUploadedImages] = usePersistentState(
    id,
    'uploadedImages',
    []
  );

  const [imageUploadErrors, setImageUploadErrors] = usePersistentState(
    id,
    'imageUploadErrors',
    []
  );

  const selectedButton = (button) => {
    if (!!uploadedImages && uploadedImages.length > 0) {
      const requestPayload = {
        event: button.event,
        data: {
          uploadedFileUrls: uploadedImages,
        },
      };
      select(requestPayload);
    }
  };

  const onUploadedImagesChange = (images) => {
    setUploadedImages(images.map((image) => image.response.data));
  };

  const getUploadVersion = (uploadVersion) => {
    return !uploadVersion ? UPLOAD_VERSION_2 : uploadVersion;
  };

  const onImageUploadError = (errors) => {
    setImageUploadErrors(errors);
  };

  function renderButtons() {
    let colorType = Utils.getColorType(actionButton.buttonType);
    let outline = actionButton.outline !== false;

    return (
      <ButtonGroup>
        <ButtonGroup.Button
          key={actionButton.text}
          isSelectable={
            !!uploadedImages && uploadedImages.length > 0 && isSelectable
          }
          data-testid="operation-button"
          className="operation-button-container"
          outline={outline}
          colorType={colorType}
          selected={!isSelectable}
          iconLeft={Utils.getButtonIcon(
            actionButton.buttonType,
            actionButton.iconUrl
          )}
          onClick={() => selectedButton(actionButton)}>
          {actionButton.text}
        </ButtonGroup.Button>
      </ButtonGroup>
    );
  }

  return (
    <div
      className={classNames({
        'file-upload': true,
        disabled: !isSelectable,
      })}>
      <Bubble title={componentData.title} isBottomRounded={false}>
        {
          <div
            className={classNames({
              'content-wrapper': true,
              'content-wrapper--deeplink-active': previewDeepLink,
            })}>
            <div className="assistant-label">{fileInput.inputLabel}</div>
            <div className="image-upload-body">
              <div className="image-upload-row">
                <FileInput
                  id={id + 'FileInput'}
                  placeholder={fileInput.inputPlaceHolder}
                  onImagesChange={onUploadedImagesChange}
                  onError={onImageUploadError}
                  maxFileCount={fileInput.maxFileCount}
                  useCdn={true}
                  accept={GO_DELIVERED_PRODUCT_RECEIPT_ACCEPTED_FORMATS}
                  preview={true}
                  isRequired={true}
                  uploadVersion={getUploadVersion(fileInput.uploadVersion)}
                />
              </div>
              {!!imageUploadErrors.length && (
                <div className="image-upload-row">
                  <Alert messages={imageUploadErrors} />
                </div>
              )}
              {fileInput.inputInfo && (
                <div className="image-upload-info-row">
                  <div className="image">
                    <img
                      className="alert-icon"
                      src={infoIcon}
                      alt="image-refund"
                    />
                  </div>
                  <div className="info-message">{fileInput.inputInfo}</div>
                </div>
              )}
            </div>
          </div>
        }
        {previewDeepLink && (
          <div className="deeplink-wrapper">
            {previewDeepLink.url !== undefined && (
              <button
                disabled={!isSelectable}
                className="deeplink-button"
                onClick={() =>
                  Utils.handleClickDeeplink(
                    previewDeepLink.event,
                    previewDeepLink.eventLabel,
                    previewDeepLink.url,
                    requestForDeepLink,
                    history
                  )
                }
                style={linkStyle}>
                {previewDeepLink.iconUrl && (
                  <img
                    className="img"
                    src={previewDeepLink.iconUrl}
                    alt="deeplink_icon"
                  />
                )}
                <div className="deeplink-title">{previewDeepLink.text}</div>
              </button>
            )}
          </div>
        )}
        {actionButton && renderButtons()}
      </Bubble>
    </div>
  );
};

FileUpload.propTypes = {
  data: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default withRouter(FileUpload);
