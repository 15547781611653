import React from 'react';
import {
  cargoInfoText,
  claimAmountText,
  claimCoinAmountText,
  claimReasonText,
  claimRejectReasonLabel,
  coinText,
  countAndPriceText,
} from '../../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import appService from '../../../../services/app.service';
import ReactMarkdown from 'react-markdown';
import RefundTimeline from './components/RefundTimeline';

const ClaimDetailShipment = ({
  detailItemList,
  refundInfoAmount,
  handleCargoLink,
}) => {
  const { t } = useTranslation();
  return (
    <div className="claim-detail-wrapper" data-testid="claim-detail-shipment">
      {detailItemList &&
        detailItemList.map((shipment, i) => {
          return (
            <div key={i}>
              <hr />
              <div className="claim-detail-body" key={i}>
                <div className="details">
                  <div className="image__wrap">
                    <img
                      src={shipment.imageUrl}
                      alt="shipment"
                      className="image"
                    />
                  </div>
                  <div className="detail">
                    {!!shipment.refundAmount && (
                      <div className="refund-amount">
                        <span className="text">{t(claimAmountText)}:</span>
                        <span
                          className={classnames([
                            'refund-data',
                            {
                              dynamic: appService.isDynamicStylingChannel(),
                            },
                          ])}>
                          {shipment.refundAmount + ' ' + shipment.currency}
                        </span>
                      </div>
                    )}
                    {!!shipment.refundCoinAmount && (
                      <div className="refund-amount">
                        <span className="text">{t(claimCoinAmountText)}:</span>
                        <span
                          className={classnames([
                            'refund-data',
                            {
                              dynamic: appService.isDynamicStylingChannel(),
                            },
                          ])}>
                          {shipment.refundCoinAmount + ' ' + shipment.currency}
                          {!!shipment.refundCoinQuantity &&
                            ' (' +
                              shipment.refundCoinQuantity +
                              ' ' +
                              t(coinText) +
                              ')'}
                        </span>
                      </div>
                    )}
                    <div className="status">
                      <span
                        className={classnames([
                          'status-type',
                          {
                            'status-type-rejected':
                              shipment.status.type === 'REJECTED',
                          },
                        ])}>
                        {shipment.status.text}
                      </span>
                      {shipment.claimLastModifiedDate && (
                        <>
                          <span className="status-date">
                            {shipment.claimLastModifiedDate}
                          </span>
                        </>
                      )}
                    </div>
                    {shipment.refundTargetText && (
                      <div className="refund-target">
                        {shipment.refundTargetText}
                      </div>
                    )}
                    {shipment.amount && shipment.currency && (
                      <div className="quantity-amount">
                        <span className="quantity">
                          <span className="text">{t(countAndPriceText)}</span>
                          <span className="count">{shipment.quantity}</span>
                        </span>
                        -
                        <span
                          className={classnames([
                            'amount',
                            {
                              dynamic: appService.isDynamicStylingChannel(),
                            },
                          ])}>
                          {shipment.amount + ' ' + shipment.currency}
                        </span>
                      </div>
                    )}
                    {shipment.reasonText && (
                      <div className="reason-wrapper">
                        <span className="text"> {t(claimReasonText)}:</span>
                        <span className="reason">{shipment.reasonText}</span>
                      </div>
                    )}

                    {shipment.rejectReasonText && (
                      <div className="reason-wrapper">
                        <span className="text">
                          {t(claimRejectReasonLabel)}:
                        </span>
                        <span className="reason">
                          {shipment.rejectReasonText}
                        </span>
                      </div>
                    )}
                    {shipment.claimRejectionTrackingLink && (
                      <div
                        className="cargo-link"
                        onClick={() =>
                          handleCargoLink(shipment.claimRejectionTrackingLink)
                        }>
                        <span className="link">{t(cargoInfoText)}</span>
                      </div>
                    )}

                    {(shipment.refundInfoMessage?.length ||
                      shipment.refundTargetImageUrl?.length) && (
                      <>
                        {shipment.claimDetailProgressInfo &&
                        !!refundInfoAmount ? (
                          <div className="refund-container">
                            <RefundTimeline
                              progressInfo={{
                                status: shipment.claimDetailProgressInfo.status,
                                companyImageUrl:
                                  shipment.claimDetailProgressInfo
                                    .companyImageUrl,
                              }}
                            />
                            {shipment.refundInfoMessage && (
                              <div className="refund-info-message">
                                <ReactMarkdown
                                  children={shipment.refundInfoMessage}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="int-refund-container">
                            {shipment.refundTargetImageUrl && (
                              <img
                                src={shipment.refundTargetImageUrl}
                                alt="refund"
                              />
                            )}

                            {shipment.refundInfoMessage && (
                              <div className="refund-info-message">
                                <ReactMarkdown
                                  children={shipment.refundInfoMessage}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ClaimDetailShipment;
