import {
  DEFAULT_CHANNEL_ID,
  DODO_CHANNEL_ID,
  INT_CUSTOMER_CHANNEL_ID,
} from './sessionStorageConstants';

import internationalCustomerServiceIcon from '../assets/icons/international-customer-service.svg';
import DodoLogo from '../assets/icons/dodologo.svg';
import InternationalAlert from '../assets/icons/international-alert.svg';
import DodoSad from '../assets/icons/dodo-sad.svg';
import InternationalLogo from '../assets/icons/smile-icon-international.svg';
import SmileIcon from '../assets/icons/smile-icon.svg';
import SupportIcon from '../assets/icons/support.svg';
import SadIcon from '../assets/icons/sad-icon.svg';
import DefaultAlert from '../assets/icons/alert.svg';
import DodoAlert from '../assets/icons/dodo-alert.svg';
import DodoLivechat from '../assets/icons/dodo-livechat.svg';
import InternationalLogoDark from '../assets/icons/smile-icon-international-dark.svg';

import {
  appTitle,
  appTitleDodo,
  appTitleMilla,
  appTitleTgoApp,
  liveChatTitle,
  liveChatTitleDodo,
  liveChatTitleMilla,
  liveChatTitleTgoApp,
} from '../i18n/keys';
import { MILLA_APP_NAME, TGO_APP_NAME } from './chatAssistantConstant';

export const STYLING_MAP = new Map([
  [
    INT_CUSTOMER_CHANNEL_ID,
    {
      color: '#D7735F',
      'hover-color': '#e19788',
      'text-color': '#FFFFFF',
      'icon-width': '22px',
      'icon-height': '22px',
      'client-message-bg-color': '#FBD3CB',
      'client-message-text-color': '#333333',
      'upload-content-bg-color': '#FBD3CB',
      'upload-content-more-item-color': '#FBEDEA',
      'icon-link': InternationalLogoDark,
      'typing-icon': InternationalLogo,
      /* 'icon-background-image': 'linear-gradient(to bottom, #D7735F, #D7735F)',*/
      'sad-icon-link': InternationalAlert,
      'customer-service-icon': internationalCustomerServiceIcon,
      'app-title': appTitle,
      'livechat-title': liveChatTitle,
      'order-icon': false,
      'alert-icon': InternationalAlert,
      'alert-color': '#D7735F',
      'order-delivered-color': '#0bc15c',
      'alert-info-color': '#D55959',
      'alert-info-bg-color': '#FCF0EE',
    },
  ],
  [
    DODO_CHANNEL_ID,
    {
      color: '#25D6A2',
      'hover-color': '#4ee0b5',
      'text-color': '#FFFFFF',
      'icon-width': '28px',
      'icon-height': '28px',
      'client-message-bg-color': '#CDF4E9',
      'client-message-text-color': '#333333',
      'upload-content-bg-color': '#CDF4E9',
      'upload-content-more-item-color': '#EBF9F5',
      'icon-link': DodoLogo,
      'typing-icon': InternationalLogo,
      'icon-background-image': 'unset',
      'sad-icon-link': DodoSad,
      'customer-service-icon': DodoLivechat,
      'app-title': appTitleDodo,
      'livechat-title': liveChatTitleDodo,
      'alert-icon': DodoAlert,
      'alert-color': '#EF4A6C',
      'order-delivered-color': '#25D6A2',
      'alert-info-color': '#D55959',
      'alert-info-bg-color': '#FCF0EE',
    },
  ],
  [
    TGO_APP_NAME,
    {
      color: '#f27a1a',
      'hover-color': '#f5964a',
      'text-color': '#FFFFFF',
      'icon-width': '22px',
      'icon-height': '22px',
      'client-message-bg-color': '#ffe5d0',
      'client-message-text-color': '#333333',
      'upload-content-bg-color': '#ffe5d0',
      'upload-content-more-item-color': '#FEF1E8',
      'icon-link': SmileIcon,
      'icon-background-image': 'linear-gradient(to bottom, #fab238, #f27a1a)',
      'sad-icon-link': SadIcon,
      'customer-service-icon': SupportIcon,
      'typing-icon': InternationalLogo,
      'app-title': appTitleTgoApp,
      'livechat-title': liveChatTitleTgoApp,
      'alert-icon': DefaultAlert,
      'alert-color': '#D7735F',
      'order-delivered-color': '#0bc15c',
      'alert-info-color': '#D55959',
      'alert-info-bg-color': '#FCF0EE',
    },
  ],
  [
    MILLA_APP_NAME,
    {
      color: '#f27a1a',
      'hover-color': '#f5964a',
      'text-color': '#FFFFFF',
      'icon-width': '22px',
      'icon-height': '22px',
      'client-message-bg-color': '#ffe5d0',
      'client-message-text-color': '#333333',
      'upload-content-bg-color': '#ffe5d0',
      'upload-content-more-item-color': '#FEF1E8',
      'icon-link': SmileIcon,
      'icon-background-image': 'linear-gradient(to bottom, #fab238, #f27a1a)',
      'sad-icon-link': SadIcon,
      'customer-service-icon': SupportIcon,
      'typing-icon': InternationalLogo,
      'app-title': appTitleMilla,
      'livechat-title': liveChatTitleMilla,
      'alert-icon': DefaultAlert,
      'alert-color': '#D7735F',
      'order-delivered-color': '#0bc15c',
      'alert-info-color': '#D55959',
      'alert-info-bg-color': '#FCF0EE',
    },
  ],
  [
    DEFAULT_CHANNEL_ID,
    {
      color: '#f27a1a',
      'hover-color': '#f5964a',
      'text-color': '#FFFFFF',
      'icon-width': '22px',
      'icon-height': '22px',
      'client-message-bg-color': '#ffe5d0',
      'client-message-text-color': '#333333',
      'upload-content-bg-color': '#ffe5d0',
      'upload-content-more-item-color': '#FEF1E8',
      'icon-link': SmileIcon,
      'icon-background-image': 'linear-gradient(to bottom, #fab238, #f27a1a)',
      'sad-icon-link': SadIcon,
      'customer-service-icon': SupportIcon,
      'typing-icon': InternationalLogo,
      'app-title': appTitle,
      'livechat-title': liveChatTitle,
      'alert-icon': DefaultAlert,
      'alert-color': '#D7735F',
      'order-delivered-color': '#0bc15c',
      'alert-info-color': '#D55959',
      'alert-info-bg-color': '#FCF0EE',
    },
  ],
]);

export const DYNAMIC_STYLING_CHANNELS = [
  DODO_CHANNEL_ID,
  INT_CUSTOMER_CHANNEL_ID,
  TGO_APP_NAME,
  MILLA_APP_NAME,
];

export const FONT_FAMILY_MAP = {
  ios: { fontFamily: 'Lato' },
  android: { fontFamily: 'Roboto' },
  desktop: { fontFamily: 'Roboto' },
};
