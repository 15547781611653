import React, { useEffect, useState } from 'react';
import ChatGatewayService from '../../../services/chatgateway.service';
import LiveChatService from '../../../services/livechat.service';
import './TransitionScreen.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import appService from '../../../services/app.service';
import { errorPageMessage } from '../../../i18n/keys';

const TransitionScreen = () => {
  const { t } = useTranslation();
  const [transitionScreenInfo, setTransitionScreenInfo] = useState([]);
  const [activeImg, setActiveImage] = useState(0);
  const [error, setError] = useState(false);
  const [isSecureKey, setIsSecureKey] = useState(false);

  useEffect(() => {
    ChatGatewayService.getTransitionScreenInfo()
      .then((res) => {
        setTransitionScreenInfo(res.data.messages);
        setError(false);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  useEffect(() => {
    let secureKeyInterval;
    if (!isSecureKey) {
      secureKeyInterval = setInterval(() => {
        isSecureKeySet();
      }, 1000);
    }

    return () => {
      clearInterval(secureKeyInterval);
    };
  }, [isSecureKey]);

  function isSecureKeySet() {
    const secureKey = LiveChatService.getSecureKey();
    if (secureKey) {
      setIsSecureKey(true);
    }
  }

  useEffect(() => {
    if (transitionScreenInfo) {
      const interval = setInterval(() => {
        setActiveImage((img) =>
          transitionScreenInfo.length - 1 === img ? 0 : img + 1
        );
      }, 6000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [transitionScreenInfo]);

  return (
    <div className="transition-screen">
      {error ? (
        <div>{t(errorPageMessage)}</div>
      ) : (
        <div className="main-wrapper">
          {transitionScreenInfo?.length > 0 && (
            <div className="body-wrapper">
              <div
                className={classNames([
                  'image-wrapper',
                  {
                    dynamic: appService.isDynamicStylingChannel(),
                  },
                ])}>
                <img
                  alt="animation"
                  className="image-animation"
                  src={transitionScreenInfo[activeImg]['imageUrl']}
                />
              </div>
              <div className="title">
                {transitionScreenInfo[activeImg]['title']}
              </div>
              <div className="description">
                {transitionScreenInfo[activeImg]['description']}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TransitionScreen;
