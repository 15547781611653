import * as keys from './keys';

export default {
  translation: {
    [`${keys.appTitle}`]: 'Trendyol Асистент',
    [`${keys.appTitleTgoApp}`]: 'Асистент',
    [`${keys.appTitleMilla}`]: 'Асистент',
    [`${keys.appTabTitle}`]: 'Trendyol Асистент',
    [`${keys.liveChatTitle}`]: 'Чат на живо',
    [`${keys.liveChatTitleTgoApp}`]: 'Чат на живо',
    [`${keys.liveChatTitleMilla}`]: 'Чат на живо',
    [`${keys.liveChatTabTitle}`]: 'Чат на живо',
    [`${keys.chatbotErrorMsg}`]: 'Възникна системна грешка. Опитай отново.',
    [`${keys.liveChatLoadingMessage}`]:
      'Свързваме те с наш представител за обслужване на клиенти, остани на линия...',
    [`${keys.agentsBusyMessage}`]:
      'Всички наши представители в момента обслужват други клиенти, остани на линия...',
    [`${keys.liveChatConnecting}`]: 'Свързване...',
    [`${keys.systemJoined}`]: 'Определяне на клиентски представител...',
    [`${keys.agentDisconnected}`]: 'Определяне на нов представител....',
    [`${keys.agentJoined}`]: 'На линия',
    [`${keys.fileNameNotValidError}`]: 'Името на файла не е валидно.',
    [`${keys.fileEmptyError}`]: 'Съдържанието на качения файл е празно.',
    [`${keys.systemJoinedMessage}`]:
      'Приветстваме те в чата на живо на Trendyol.',
    [`${keys.liveChatOffline}`]: 'Извън линия',
    [`${keys.makeUploadedContentBigger}`]: 'Покажи в по-голям формат',
    [`${keys.timeOutFirstPart}`]: 'Този чат ще бъде прекратен в рамките на',
    [`${keys.timeOutSecondPart}`]: 'секунди',
    [`${keys.timeOutThirdPart}`]:
      'тъй като от известно време не си писал(а) нищо.',
    [`${keys.closeLiveChatButton}`]: 'Край на чата',
    [`${keys.continueLiveChatButton}`]: 'Продължи чата',
    [`${keys.liveChatRefused}`]:
      'В момента се води друг разговор с този профил. Опитай отново по-късно, ако все още е необходимо.',
    [`${keys.maxTimeOutThresholdExceedMessage}`]:
      'Този чат е прекратен автоматично от системата, тъй като е останал неактивен известно време. Благодарим ти за разбирането.',
    [`${keys.agentLeftMessage}`]: 'Клиентският представител прекрати чата.',
    [`${keys.chatConnectionErrorMessage}`]: 'Възникна грешка, опитай отново',
    [`${keys.chatInputBarPlaceholderMessage}`]: 'Напиши съобщение...',
    [`${keys.resendMediaMessage}`]: 'Опитай отново.',
    [`${keys.resendMediaMessageForEmptyImage}`]: 'Качи отново.',
    [`${keys.unAuthorizedMessage}`]: 'Нямаш право на достъп до тази страница.',
    [`${keys.surveyMessage}`]:
      'Удовлетворен(а) ли си от току-що проведения разговор?',
    [`${keys.trendyolAsistantSurveyMessage}`]:
      'Удовлетворен(а) ли си от Trendyol Асистент?',
    [`${keys.surveyCommentMessage}`]: 'Имаш ли да добавиш нещо друго?',
    [`${keys.surveyRatingMessage}`]:
      'Оцени изживяването си с Trendyol Асистент',
    [`${keys.liveChatSurveyRatingMessage}`]: 'Оцени нашия представител',
    [`${keys.surveyRatingBadMessage}`]: 'Много лошо',
    [`${keys.surveyRatingGoodMessage}`]: 'Много добро',
    [`${keys.surveyRateButton}`]: 'Изпрати',
    [`${keys.surveyErrorModalTitle}`]: 'ВЪЗНИКНА ГРЕШКА В АНКЕТАТА',
    [`${keys.surveyErrorRatingEmpty}`]: 'Въведи оценка.',
    [`${keys.surveyErrorServiceError}`]: 'Възникна грешка, опитай отново',
    [`${keys.surveySuccessModalTitle}`]: 'ОЦЕНЯВАНЕТО Е УСПЕШНО',
    [`${keys.surveySuccessModalMessage}`]:
      'Благодарим ти за участието в нашата анкета!',
    [`${keys.liveChatNewMessageAppTitle}`]: 'Имаш ново съобщение...',
    [`${keys.errorPageMessage}`]: 'Възникна системна грешка. Опитай отново.',
    [`${keys.closeOnErrorButton}`]: 'Затвори',
    [`${keys.reconnectOnErrorButton}`]: 'Възстановяване на връзката',
    [`${keys.liveChatReconnecting}`]: 'Възстановяване на връзката...',
    [`${keys.nameText}`]: 'Собствено име',
    [`${keys.lastnameText}`]: 'Фамилия',
    [`${keys.outOfWorkOurMessage}`]:
      'Нашите представители за обслужване на клиенти са извън работно време. Можеш да препратиш съобщението си. Ще се свържем с теб възможно най-скоро.',
    [`${keys.invalidTokenInfoText}`]:
      'Не можем да анализираме информацията ти. Излез от приложението и влез отново.',
    [`${keys.newText}`]: 'НОВО',
    [`${keys.emailText}`]: 'Имейл',
    [`${keys.continueLiveChatText}`]: 'Назад',
    [`${keys.exitLiveChatModalText}`]: 'Наистина ли искаш да прекратиш чата?',
    [`${keys.exitAndOpenSurvey}`]: 'Потвърди край на чата',
    [`${keys.clientReconnectingMessage}`]:
      'Здравей, отново те препращаме към клиентския представител поради бавната ти интернет връзка. Твоят чат ще бъде възобновен. Молим да изчакаш и ти благодарим за разбирането.',
    [`${keys.clientRedirectedMessage}`]:
      'Здравей, прехвърляме те към друг клиентски представител поради бавна интернет връзка. Историята на разговора ще бъде прехвърлена на новия представител. Молим да изчакаш и ти благодарим за разбирането.',
    [`${keys.stickySurveyTitle}`]:
      'Удовлетворен(а) ли си от Trendyol Асистент?',
    [`${keys.stickySurveyRateMessage}`]: 'Оцени ни',
    [`${keys.stickySurveyCommentMessage}`]: 'Имаш ли да добавиш нещо друго?',
    [`${keys.stickySurveySuccessTitle}`]: 'Получихме оценката ти!',
    [`${keys.stickySurveySuccessText}`]:
      'Благодарим ти за оценката, която направи, за да се усъвършенстваме.',
    [`${keys.stickySurveySuccessButtonText}`]: 'Затвори',
    [`${keys.placeHolderSurname}`]: 'Въведи фамилия',
    [`${keys.placeHolderName}`]: 'Въведи първо име',
    [`${keys.yourEmailText}`]: 'Имейл',
    [`${keys.placeholderEmail}`]: 'Въведи имейл',
    [`${keys.languageText}`]: 'Език',
    [`${keys.placeholderLanguage}`]: 'Избери език',
    [`${keys.deliveryNumberText}`]: 'Номер на доставката',
    [`${keys.placeholderDeliveryNumber}`]: 'Въведи номер на доставката',
    [`${keys.startChatText}`]: 'Започни чат',
    [`${keys.internationalTicketCreateSucceedTitle}`]:
      'Благодарим, че се свърза с нас',
    [`${keys.internationalTicketCreateSucceedText}`]:
      'Получихме имейла ти. Ще го разгледаме и ще се свържем с теб скоро.',
    [`${keys.internationalTicketCreateFailedTitle}`]: '',
    [`${keys.internationalTicketCreateFailedText}`]:
      'Възникна грешка, опитай отново',
    [`${keys.closeButtonText}`]: 'Затвори',
    [`${keys.retryButtonText}`]: 'Опитай отново',
    [`${keys.placeholderSubject}`]: 'Въведи тема',
    [`${keys.subjectText}`]: 'Тема',
    [`${keys.internationalSubjectImageText}`]: 'Качи изображения',
    [`${keys.mailDescriptionText}`]: 'Напиши подробно съобщението си',
    [`${keys.mailSubmitText}`]: 'Изпрати',
    [`${keys.placeHolderDescriptionText}`]: 'Въведи описание',
    [`${keys.englishLangText}`]: 'Английски',
    [`${keys.germanLangText}`]: 'Немски',
    [`${keys.englishLangTextShort}`]: 'ENG',
    [`${keys.selectOrderMsg}`]: 'Избери',
    [`${keys.shipmentNo}`]: '№ на доставка',
    [`${keys.orderSelectedMsg}`]: 'Избрано',
    [`${keys.quantityText}`]: 'Брой',
    [`${keys.claimOrderClaimableIsNotDone}`]: 'Попълни полетата по-долу.',
    [`${keys.claimQuantityText}`]: 'Избери количество',
    [`${keys.claimReasonText}`]: 'Причина',
    [`${keys.countAndPriceText}`]: 'Количество и цена:',
    [`${keys.claimOrderReason}`]: 'Избери причина за връщане',
    [`${keys.selectProductImageText}`]: 'Качи изображения (макс. {{count}})',
    [`${keys.internationalMailSelectProductImageText}`]:
      'Качи изображения (макс. {{count}})',
    [`${keys.claimOrderDescription}`]: 'Добави подробности',
    [`${keys.claimSimulationPreviewQuantity}`]: 'Количество',
    [`${keys.claimSimulationPreviewProduct}`]: 'Артикул',
    [`${keys.claimOrderFillToContinue}`]:
      'За да продължиш, попълни изцяло полетата за количество и причина за връщане.',
    [`${keys.claimQuantityText}`]: 'Избери количество',
    [`${keys.optionalFileInfoText}`]: 'Качи изображения (по избор)',
    [`${keys.imageFormatMismatchErrorText}`]:
      'Можеш да качваш само JPG и PNG файлове.',
    [`${keys.imageMaxSizeErrorText}`]:
      'Размерът на файл не трябва да надвишава {{size}} MB.',
    [`${keys.imageCountErrorText}`]: 'Можеш да качваш до {{count}} файла.',
    [`${keys.imageReadError}`]:
      'Неуспешно обработване на файла. Опитай отново.',
    [`${keys.imageUploadError}`]:
      'Възникна грешка при качване на файла. Опитай отново.',
    [`${keys.shipmentSubtitleTotal}`]: 'Общо',
    [`${keys.shipmentSubtitleItems}`]: 'Артикул',
    [`${keys.orderNo}`]: '№ на поръчката',
    [`${keys.seeAllAnnouncement}`]: 'Виж всички известия',
    [`${keys.showMoreTicket}`]: 'Покажи повече',
    [`${keys.notificationsTitle}`]: 'Известия',
    [`${keys.totalClaimAmountText}`]: 'Общо възстановена сума',
    [`${keys.claimAmountText}`]: 'Възстановена сума',
    [`${keys.claimCoinAmountText}`]: 'Възстановени Trendyol Coins',
    [`${keys.coinText}`]: 'Coin',
    [`${keys.coinUsageMessage}`]: 'Ти използва',
    [`${keys.coinUsageOrderMessage}`]: 'за тази поръчка!',
    [`${keys.coinsWording}`]: 'coins',
    [`${keys.coinQuantityAndAmountInfo}`]:
      '{{quantity}} {{coinsWording}} ({{amount}} {{currency}})',
    [`${keys.claimStatusCargoText}`]: 'Къде е върнатата ми пратка?',
    [`${keys.showMoreShipment}`]: 'Покажи повече продукти',
    [`${keys.showMoreShipment}`]: 'Покажи повече артикули',
    [`${keys.orderText}`]: 'Моите поръчки',
    [`${keys.ordersText}`]: 'Моите поръчки',
    [`${keys.currencyText}`]: '€',
    [`${keys.copyText}`]: 'Копирай',
    [`${keys.copiedText}`]: 'Копирано',
    [`${keys.deliveryDateText}`]: 'Дата на доставка:',
    [`${keys.paymentInfoText}`]: 'Информация за плащане',
    [`${keys.subtotalText}`]: 'Междинна сума',
    [`${keys.cargoText}`]: 'Доставка',
    [`${keys.totalText}`]: 'Общо',
    [`${keys.deliveryAddressText}`]: 'Адрес за доставка',
    [`${keys.orderDate}`]: 'Дата на поръчката',
    [`${keys.orderSummary}`]: 'Обобщение на поръчката',
    [`${keys.productText}`]: 'артикул',
    [`${keys.linkCopied}`]: 'Номер на поръчката копиран',
    [`${keys.billingAddressText}`]: 'Адрес на фактурата',
    [`${keys.detail}`]: 'Подробности',
    [`${keys.deliveryTimeText}`]: 'Дата на доставка:',
    [`${keys.addExtraImage}`]: 'Добави изображения',
    [`${keys.chatbotErrorMsgKey}`]: 'Грешка!',
    [`${keys.chatbotShortErrorMsg}`]: 'Нещо се обърка.',
    [`${keys.collectionPointText}`]: 'Място на получаване',
    [`${keys.commercialText}`]: 'Продажба с фактура за фирма',
    [`${keys.fastMarketText}`]: 'Бърз пазар',
    [`${keys.mealText}`]: 'Храна',
    [`${keys.groupDealText}`]: 'Купи с приятели',
    [`${keys.wardrobeText}`]: 'DOLAP',
    [`${keys.inputLanguageNotSupported}`]:
      'Понастоящем нашите услуги за поддръжка са достъпни само на английски език. Напиши запитването си на английски.',
    [`${keys.myOrderDetail}`]: 'Подробности за моята поръчка',
    [`${keys.arabicLangText}`]: 'Арабски',
    [`${keys.showMoreContent}`]: 'Покажи повече',
    [`${keys.showLessContent}`]: 'Покажи по-малко',
    [`${keys.bulgariaLangText}`]: 'Български',
    [`${keys.polandLangText}`]: 'Полски',
    [`${keys.slovakiaLangText}`]: 'Словашки',
    [`${keys.romaniaLangText}`]: 'Румънски',
    [`${keys.czechiaLangText}`]: 'Чешки',
    [`${keys.hungaryLangText}`]: 'Унгарски',
    [`${keys.greeceLangText}`]: 'Гръцки',
    [`${keys.cancelOrderSelectProduct}`]:
      'Избери артикула и причината за анулиране.',
    [`${keys.cancelOrderSelectReason}`]: 'Избери причината за анулиране',
    [`${keys.selectReason}`]: 'Избери причина',
    [`${keys.other}`]: 'Обяснение',
    [`${keys.explanation}`]:
      'Въведи обяснение за артикула, който искаш да анулираш',
    [`${keys.userDisconnectedOnBackground}`]:
      'Този разговор е прекратен, защото сте излезли от приложението по време на разговора или сте били неактивни дълго време. Благодарим Ви за разбирането.',
    [`${keys.refundDetailText}`]: 'Виж детайли за възстановяването',
    [`${keys.refundProgressBankInfo}`]:
      'Изчакват се действия от страна на твоята финансова институция',
    [`${keys.productImageText}`]: 'Изображение на продукта',
    [`${keys.refundAmountText}`]: 'Размер на възстановяването',
    [`${keys.claimLastModifiedDateTrText}`]:
      'Дата, на която Trendyol възстановява сумата',
    [`${keys.claimLastModifiedDateMillaText}`]:
      'Дата, на която Trendyolmilla възстановява сумата',
    [`${keys.estimatedRefundDateText}`]: 'Очакван период за възстановяване',
    [`${keys.bankRefNoText}`]: 'Банков референтен номер',
    [`${keys.refundProgressTrInfo}`]: 'Trendyol издава възстановяването',
    [`${keys.refundProgressMillaInfo}`]:
      'Trendyolmilla издава възстановяването',
  },
};
