import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import {
  collectionPointText,
  commercialText,
  groupDealText,
  orderNo,
  shipmentNo,
  shipmentSubtitleItems,
  shipmentSubtitleTotal,
  wardrobeText,
} from '../../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import ChatBotContext from '../../../../context/chatbot/chatbotContext';
import {
  CANCELLED,
  CREATED,
  SUCCESS,
  WARN,
  WARN_YELLOW,
} from '../../../../constants';

import usePersistentState from '../../../common/usePersistentState';
import appService from '../../../../services/app.service';
import StatusIcon from '../../icons/statusIcon';
import BaseListingItem from '../BaseListingItem';

const ShipmentListingItem = ({
  id,
  shipment,
  orderItemActive,
  selectedShipmentNumber,
  setSelectedShipmentNumber,
  selectedOrderId,
  setSelectedOrderId,
  selectedOrderNumber,
  setSelectedOrderNumber,
  isRenderedFromOutside = false,
}) => {
  const chatBotContext = useContext(ChatBotContext);
  const { selectOrder } = chatBotContext;

  const { t } = useTranslation();
  const [isSelected, setIsSelected] = usePersistentState(
    id,
    'isSelected',
    false
  );

  const [listingItem, setListingItem] = usePersistentState(
    id,
    'listingItem',
    undefined
  );

  const {
    imageUrls = [],
    productNames = [],
    shipmentNumber,
    orderId,
    orderNumber,
    shipmentStatus = {},
    orderDate,
    channelId,
    productGroup,
    subtitleText,
    buttonLabel,
  } = shipment;

  const { type, typeForUser } = shipmentStatus;
  const typeForUserLower = (typeForUser || '').toLowerCase();

  const getStatusType = () => {
    if (
      type === SUCCESS &&
      appService.isDynamicStylingChannel() &&
      !shipment.isDraft
    ) {
      return 'success--dynamic';
    }
    if (type === SUCCESS) {
      return 'success';
    }
    if (type === WARN && !shipment.isDraft) {
      return 'warn';
    }
    if (type === WARN_YELLOW && !shipment.isDraft) {
      return 'warn-yellow';
    }
    if (type === CANCELLED && !shipment.isDraft) {
      return 'error';
    }
  };

  useEffect(() => {
    const item = {
      badge: renderStamp(channelId, productGroup),
      label: productNames[0],
      subLabel:
        imageUrls.length > 1
          ? subtitleText
            ? subtitleText
            : t(shipmentSubtitleTotal) +
              ' ' +
              productNames.length +
              ' ' +
              t(shipmentSubtitleItems)
          : '',
      imageUrls: imageUrls,
      listingNumber: shipmentNumber ? shipmentNumber : orderNumber,
      listingNumberLabel: shipmentNumber ? t(shipmentNo) : t(orderNo),
      listingDate: orderDate,
      status: {
        type: getStatusType(),
        text: shipmentStatus.text,
      },
      buttonLabel: buttonLabel,
    };
    setListingItem(item);
  }, [shipment]);

  useEffect(() => {
    if (orderItemActive && !shipment.isDraft) {
      setIsSelected(
        selectedShipmentNumber === shipmentNumber &&
          orderId === selectedOrderId &&
          orderNumber === selectedOrderNumber
      );
    }
  }, [selectedShipmentNumber, selectedOrderId, selectedOrderNumber]);

  const renderStamp = (channelId, productGroup) => {
    switch (channelId) {
      case 1:
        if (shipment.isGroupDeal) {
          return <span>{t(groupDealText)}</span>;
        } else if (shipment.isCommercial) {
          return <span>{t(commercialText)}</span>;
        } else if (shipment.isCollectionPoint) {
          return <span>{t(collectionPointText)}</span>;
        }
        break;
      case 2:
        return <span>{t(wardrobeText)}</span>;
      case 5:
        return <span>{productGroup}</span>;
      default:
        return;
    }
  };

  const onSelect = () => {
    if (orderItemActive && !shipment.isDraft) {
      let request = {
        event: shipment.event,
        data: {
          orderId: shipment.orderId,
          shipmentNumber: shipment.shipmentNumber,
          orderNumber: shipment.orderNumber,
          channelId: shipment.channelId,
        },
        requestItem: shipment,
      };

      setSelectedShipmentNumber(shipment.shipmentNumber);
      setSelectedOrderId(shipment.orderId);
      setSelectedOrderNumber(shipment.orderNumber);
      appService.setEchoCustomFields(shipment.orderListingParams);
      selectOrder(request, shipment);
      setIsSelected(orderItemActive);
    }
  };

  return (
    <>
      {listingItem && (
        <BaseListingItem
          isRenderedFromOutside={isRenderedFromOutside}
          id={shipmentNumber + '|' + orderNumber + '|' + orderId}
          selectedId={
            selectedShipmentNumber +
            '|' +
            selectedOrderNumber +
            '|' +
            selectedOrderId
          }
          active={orderItemActive}
          draft={shipment.isDraft}
          flex={false}
          onSelect={onSelect}
          item={{
            ...listingItem,
            status: {
              ...listingItem.status,
              icon: (
                <StatusIcon
                  name={typeForUserLower}
                  className={classNames({
                    'status-icon': true,
                    'status-icon--warn': type === WARN,
                    'status-icon--success':
                      type === SUCCESS || type === CREATED,
                    'status-icon--warn-yellow': type === WARN_YELLOW,
                    'status-icon--error': type === CANCELLED,
                    typeForUserLower,
                  })}
                />
              ),
            },
          }}
        />
      )}
    </>
  );
};

export default ShipmentListingItem;
