import liveChatService from '../../../services/livechat.service';
import chatGatewayService from '../../../services/chatgateway.service';

const uploadMedia = async (file) => {
  let secureKey = liveChatService.getSecureKey();
  const res = await chatGatewayService.sendFile(file, secureKey, '');

  return res.data.data;
};

export default uploadMedia;
