import React, { useEffect, useRef, useState } from 'react';
import './OrderModal.scss';
import * as PropTypes from 'prop-types';
import chatbotService from '../../../../services/chatbot.service';
import BottomSheet from '../../../common/BottomSheet/BottomSheet';
import InfiniteScroller from '@tx-components/infinite-scroller';
import OrderModalItem from './OrderModalItem/OrderModalItem';
import OrderModalDetail from './OrderModalDetail/OrderModalDetail';
import { myOrderDetail, ordersText } from '../../../../i18n/keys';
import { useTranslation } from 'react-i18next';

const OrderModal = ({ isModalOpen, setIsModalOpen }) => {
  const [isDetail, setIsDetail] = useState(false);
  const [order, setOrder] = useState([]);
  const [orders, setOrders] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [scrollerHeight, setScrollerHeight] = useState();
  const [copiedOrder, setCopiedOrder] = useState();

  const scrollerRef = useRef();

  const { t } = useTranslation();

  const getOrder = (page, pageSize) => {
    setLoading(true);
    chatbotService
      .getShipments(page, pageSize)
      .then((response) => {
        if (response && response.orders && response.orders.length > 0) {
          const newOrders = [...orders, ...response.orders];
          setOrders(newOrders);
          setLoading(false);
        } else {
          setLoading(false);
          setHasMore(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrder(1, 8);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isModalOpen && !isDetail) {
      if (scrollerRef.current && !scrollerHeight) {
        setScrollerHeight(scrollerRef.current.clientHeight);
      }

      const handleChangeScrollerSize = () => {
        setScrollerHeight(scrollerRef.current?.clientHeight);
      };

      window.addEventListener('resize', handleChangeScrollerSize);

      return () => {
        window.removeEventListener('resize', handleChangeScrollerSize);
      };
    }
    // eslint-disable-next-line
  }, [scrollerRef?.current, isModalOpen, isDetail]);

  function handleOrderDetail(order_info) {
    if (order_info) {
      setIsDetail(true);
      setOrder(order_info);
    }
  }

  const closeOrderDetail = () => {
    setIsDetail(false);
  };

  return (
    <BottomSheet
      isOpen={isModalOpen}
      title={isModalOpen && isDetail ? t(myOrderDetail) : t(ordersText)}
      onClose={() => setIsModalOpen(false)}
      onBackButtonClick={isModalOpen && isDetail ? closeOrderDetail : undefined}
      className="order-modal-bottomsheet"
      onCloseButtonClick={
        isModalOpen ? () => setIsModalOpen(false) : undefined
      }>
      {isModalOpen && isDetail && (
        <OrderModalDetail
          order={order}
          closeOrderDetail={closeOrderDetail}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
      {isModalOpen && !isDetail && (
        <div className="order-scroller-wrapper" ref={scrollerRef}>
          <InfiniteScroller
            scrollbarMode="adjacent"
            id="order-scroller"
            direction="down"
            className="order-scroller"
            pageSize={8}
            hasMore={hasMore}
            items={orders.map((item) => ({
              order: item,
              isCopied: item.orderNumber === copiedOrder,
              onCopy: setCopiedOrder,
              handleOrderDetail,
            }))}
            itemKeyPropName="orderNumber"
            loading={isLoading}
            itemComponent={OrderModalItem}
            loadMore={({ page, pageSize }) => getOrder(page, pageSize)}
            height={scrollerHeight}
          />
        </div>
      )}
    </BottomSheet>
  );
};

OrderModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};

export default OrderModal;
