import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'antd';
import './MultipleItemForm.scss';
import Utils from '../../../utils';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import Bubble from '../../layout/bubble/Bubble';
import usePersistentState from '../../common/usePersistentState';
import Alert from '../../../assets/icons/alert.svg';
import classNames from 'classnames';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import * as PropTypes from 'prop-types';
import DynamicField from './DynamicField';
import { MultipleItemFormContext, MultipleItemFormProvider } from './context';

const MultipleItemForm = ({
  id,
  componentData,
  currentIndex,
  scrollToBottom,
  history,
  showHover,
  select,
  isSelectable,
}) => {
  const chatBotContext = useContext(ChatBotContext);
  const formRef = useRef();
  const {
    event,
    label,
    submitButtonLabel,
    infoLabel,
    items,
    maxItemCount,
    addItemLabel,
    lang,
    previewDeepLink,
  } = componentData;

  const { lazyOptionsObject, sendValueItems } = useContext(
    MultipleItemFormContext
  );

  let linkStyle;

  if (showHover) {
    linkStyle = { cursor: 'pointer' };
  } else {
    linkStyle = {
      cursor: 'unset',
      border: '1px solid #e6e6e6',
      color: '#1b1b1b',
    };
  }

  const {
    requestToChatBot,
    currentChatIndex,
    totalErrorCount,
    isRequesting,
    requestForDeepLink,
  } = chatBotContext;

  const [values, setValues] = usePersistentState(id, 'values', {});
  const [fields, setFields] = usePersistentState(id, 'fields', {});
  const [initialFields] = useState(fields);

  const [form] = Form.useForm();

  const initialValues = useMemo(() => {
    for (const item of items) {
      if (item && item.name && item.defaultValue) {
        values[item.name] = item.defaultValue;
      }
    }
    return values;
  }, [items]);

  useEffect(() => {
    scrollToBottom?.(100);
    //eslint-disable-next-line
  }, []);

  const allowSendRequest = Utils.shouldSelect(
    currentIndex,
    currentChatIndex,
    totalErrorCount,
    isRequesting
  );

  const onFieldChange = (test, fields) => {
    setFields(fields);
  };

  const handleValuesOfLazyDependentComboboxes = (values) => {
    Object.entries(values.items[0]).forEach(([key, value]) => {
      if (sendValueItems && sendValueItems.find((item) => item === key)) {
        try {
          const matchedOption = lazyOptionsObject[key]?.find(
            (option) => option.key === value
          );
          if (matchedOption) {
            values.items[0][key] = matchedOption.value;
          }
        } catch (error) {}
      }
    });
    return { ...values };
  };

  const handleSubmit = (values) => {
    if (!allowSendRequest) {
      return;
    }

    formRef.current
      .validateFields()
      .then(() => {
        if (values?.items?.length > 0 && sendValueItems?.length > 0) {
          const formattedValues = handleValuesOfLazyDependentComboboxes(values);
          requestToChatBot({ event, data: formattedValues });
        } else {
          requestToChatBot({ event, data: { ...values } });
        }
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  const findSubChildsOfDependants = (mainDependant) => {
    const childDependant = items.find(
      (item) => item.dependantField === mainDependant
    );
    if (childDependant) {
      form.setFieldValue(['items', 0, childDependant.name], undefined);
      findSubChildsOfDependants(childDependant?.name);
    }
  };

  return (
    <div
      className={classNames({
        'form-container': true,
        disabled: !allowSendRequest,
      })}>
      <Bubble title={label} isBottomRounded={false}>
        <Form
          ref={formRef}
          form={form}
          name={id}
          id={id}
          onFieldsChange={(_, values) => onFieldChange(values)}
          layout="vertical"
          fields={initialFields}
          initialValues={initialValues}
          preserve={false}
          onFinish={handleSubmit}
          onValuesChange={(changedValues, _) => {
            if (
              changedValues?.items?.[0] &&
              Object.keys(changedValues?.items?.[0])?.[0]
            )
              findSubChildsOfDependants(
                Object.keys(changedValues?.items?.[0])?.[0]
              );

            setValues(form.getFieldsValue());
          }}>
          <DynamicField
            id={id}
            items={items}
            maxItemCount={maxItemCount}
            addItemLabel={addItemLabel}
            allowSendRequest={allowSendRequest}
            lang={lang}
            form={form}
          />
          {previewDeepLink && previewDeepLink.url !== undefined && (
            <Form.Item>
              <div className="deeplink-wrapper">
                <button
                  disabled={!isSelectable}
                  className="deeplink-button"
                  onClick={() =>
                    Utils.handleClickDeeplink(
                      previewDeepLink.event,
                      previewDeepLink.eventLabel,
                      previewDeepLink.url,
                      requestForDeepLink,
                      history
                    )
                  }
                  style={linkStyle}>
                  {previewDeepLink.iconUrl && (
                    <img
                      className="img"
                      src={previewDeepLink.iconUrl}
                      alt="deeplink_icon"
                    />
                  )}
                  <div className="deeplink-title">{previewDeepLink.text}</div>
                </button>
              </div>
            </Form.Item>
          )}
          <Form.Item shouldUpdate>
            {() => {
              const isButtonDisabled = () => {
                if (!allowSendRequest) {
                  return true;
                }
                if (formRef.current) {
                  return (
                    formRef.current
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length > 0
                  );
                } else {
                  return items.some(
                    (item) => item.rules && item.rules.length > 0
                  );
                }
              };

              return (
                <ButtonGroup.Button
                  type="submit"
                  colorType="primary"
                  disabled={isButtonDisabled()}
                  isSelectable={!isButtonDisabled()}
                  outline={false}>
                  {submitButtonLabel}
                </ButtonGroup.Button>
              );
            }}
          </Form.Item>
          {infoLabel && (
            <div className="alert-container">
              <img src={Alert} className="alert-icon" alt="alert-icon" />
              <span>{infoLabel}</span>
            </div>
          )}
        </Form>
      </Bubble>
    </div>
  );
};

MultipleItemForm.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }),
};

const WrapperMultipleItemProvider = (props) => (
  <MultipleItemFormProvider>
    <MultipleItemForm {...props} />
  </MultipleItemFormProvider>
);

export default WrapperMultipleItemProvider;
