import './UnlockProductInfo.scss';

import React from 'react';
import usePersistentState from '../../common/usePersistentState';
import Bubble from '../../layout/bubble/Bubble';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import Checkbox from '@tx-components/checkbox';

const UnlockProductInfo = ({ id, componentData, select }) => {
  const [verifySelected, setVerifySelected] = usePersistentState(
    id,
    'verifySelected',
    false
  );
  const [warnSelected, setWarnSelected] = usePersistentState(
    id,
    'warnSelected',
    false
  );

  const onChangeSelected = (checked, checkboxName) => {
    if (checkboxName === 'verify-checkbox') {
      setVerifySelected(checked);
    } else if (checkboxName === 'warn-checkbox') {
      setWarnSelected(checked);
    }
  };

  const isSelectable = () => {
    return verifySelected && warnSelected;
  };

  const onClickSubmit = () => {
    if (isSelectable()) {
      const requestPayload = {
        event: componentData.event,
        data: {},
      };
      select(requestPayload);
    }
  };

  return (
    <div className="unlock-product-main-wrapper">
      <Bubble title={componentData.label} isBottomRounded={false}>
        <div className="unlock-product-wrapper">
          <div className="unlock-product-content">
            <div className="unlock-product-header">
              <div className="unlock-item-image">
                <img src={componentData.imageUrl} alt="unlock-item" />
              </div>
              <div className="unlock-item-title-container">
                <div className="unlock-item-title">
                  {componentData.productName}
                </div>
              </div>
            </div>
          </div>
          <div className="unlock-product-info-container">
            <div className="reason-text">{componentData.reasonText}</div>
            <ul className="reason-checklist">
              {componentData.checkList.map((item) => (
                <li className="checklist-item">{item}</li>
              ))}
            </ul>
            <div className="line" />
            <div className="checkbox-wrapper">
              <Checkbox
                name="verify-checkbox"
                onChange={(value) => onChangeSelected(value, 'verify-checkbox')}
                endLabel={componentData.verifyChecklistCheckboxText}
                checked={verifySelected}
              />
              <Checkbox
                name="warn-checkbox"
                onChange={(value) => onChangeSelected(value, 'warn-checkbox')}
                endLabel={componentData.warningCheckboxText}
                checked={warnSelected}
              />
            </div>
          </div>
        </div>
        <ButtonGroup>
          <ButtonGroup.Button
            isSelectable={isSelectable()}
            colorType={'primary'}
            data-testid="button-confirm"
            onClick={() => onClickSubmit()}
            outline={false}
            disabled={!isSelectable()}>
            {componentData.buttonText}
          </ButtonGroup.Button>
        </ButtonGroup>
      </Bubble>
    </div>
  );
};

export default UnlockProductInfo;
