import React from 'react';
import './ShipmentStatus.scss';
import StatusIconComponent from '../../chatAssistant/icons/statusIcon';
import classNames from 'classnames';
import {
  CANCELLED,
  CREATED,
  SUCCESS,
  WARN,
  WARN_YELLOW,
} from '../../../constants';

const STATUS_CLASS_MAP = {
  [SUCCESS]: 'success',
  [CREATED]: 'success',
  [WARN]: 'warn',
  [WARN_YELLOW]: 'warn-yellow',
  [CANCELLED]: 'cancelled',
};

const ShipmentStatus = ({ typeForUser, type, statustext }) => (
  <div className="shipment-status-wrapper">
    <StatusIconComponent
      name={typeForUser}
      className={classNames('status-icon', {
        [`status-icon__${STATUS_CLASS_MAP[type]}`]: true,
      })}
    />
    <div
      className={classNames('status-text', {
        [`status-text__${STATUS_CLASS_MAP[type]}`]: true,
      })}>
      {statustext}
    </div>
  </div>
);

export default ShipmentStatus;
