import React from 'react';
import OrderDetailInfo from './orderDetailInfo/OrderDetailInfo';
import OrderDetailShipment from './orderDetailShipment/OrderDetailShipment';
import ShipmentBillAddress from './shipmentBillAddress/ShipmentBillAddress';
import ShipmentAddress from './shipmentAddress/ShipmentAddress';
import OrderPaymentInfo from './orderPaymentInfo/OrderPaymentInfo';
import * as PropTypes from 'prop-types';
import './OrderModalDetail.scss';

const OrderModalDetail = ({ order }) => {
  const shipmentInfo = order.addressInfo;
  const billInfo = order.invoiceInfo;

  return (
    <div className="detail-wrapper">
      <div className="order-detail">
        <div className="order-detail-wrapper">
          <OrderDetailInfo order={order} />
        </div>
      </div>
      <OrderDetailShipment order={order} />
      <div className="order-detail">
        <div className="order-detail-payment">
          <OrderPaymentInfo order={order} />
        </div>
      </div>
      <div className="order-detail">
        <div className="order-detail-shipment-bill">
          <ShipmentAddress info={shipmentInfo} />
        </div>
      </div>
      <div className="order-detail">
        <div className="order-detail-shipment-bill">
          <ShipmentBillAddress info={billInfo} />
        </div>
      </div>
    </div>
  );
};

OrderModalDetail.propTypes = {
  order: PropTypes.object.isRequired,
  closeOrderDetail: PropTypes.func.isRequired,
};

export default OrderModalDetail;
