import {
  CLEAR_ERRORS,
  CLEAR_NEXT_STATE_TIMEOUT,
  CLEAR_SUCCESSES,
  IS_APP_INITIALIZED,
  IS_LIVE_CHAT_INITIALIZED,
  IS_SEND_SURVEY_SUCCESS,
  SET_ERRORS,
  SET_SUCCESSES,
} from './commonTypes';

export default (state, action) => {
  switch (action.type) {
    case IS_APP_INITIALIZED:
      return {
        ...state,
        isAppInitialized: action.payload,
      };

    case CLEAR_NEXT_STATE_TIMEOUT:
      return {
        ...state,
        nextStateDelayTimeoutId: action.payload,
      };

    case IS_LIVE_CHAT_INITIALIZED:
      return {
        ...state,
        isLiveChatInitialized: action.payload,
      };

    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        errors: {
          title: null,
          messages: [],
        },
      };

    case SET_SUCCESSES:
      return {
        ...state,
        successes: action.payload,
      };

    case IS_SEND_SURVEY_SUCCESS:
      return {
        ...state,
        isSendSurveySuccess: action.payload,
      };

    case CLEAR_SUCCESSES:
      return {
        ...state,
        successes: {
          title: null,
          messages: [],
        },
      };

    default:
      return state;
  }
};
