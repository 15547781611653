import React, { useContext, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import Swiper, { SwiperSlide } from '../../swiper/Swiper';
import usePersistentState from '../../../common/usePersistentState';
import classnames from 'classnames';

import { seeAllAnnouncement } from '../../../../i18n/keys';
import { useTranslation } from 'react-i18next';

import RightArrowIcon from './RightArrowIcon';

import ChatBotContext from '../../../../context/chatbot/chatbotContext';
import Utils from '../../../../utils';

import './Announcement.scss';
import ChatBotService from '../../../../services/chatbot.service';
import { ANNOUNCEMENT_TYPE_DEFAULT } from '../../../../constants';
import livechatService from '../../../../services/livechat.service';
import {
  ChatUserStatusType,
  EventChannel,
  SystemMessageType,
} from '@customer-service/echo-messaging-lib';

const Announcement = ({ items, event, isModal = false }) => {
  const { t } = useTranslation();
  const chatBotContext = useContext(ChatBotContext);
  const { setIsOpenNotification, notifications } = chatBotContext;
  const [showNavigation, setShowNavigation] = usePersistentState(
    'announcementId',
    'showNavigation',
    true
  );
  const [seenAnnouncementIds, setSeenAnnouncementIds] = usePersistentState(
    'announcementId',
    'seenAnnouncementIds',
    []
  );
  const isAnnouncementEventSentInitialSteps = useRef(false);

  useEffect(() => {
    setShowNavigation(items.length > 1);
  }, [items]);

  useEffect(() => {
    async function sendAnnouncementEvent() {
      isAnnouncementEventSentInitialSteps.current = true;

      try {
        if (items.length > 0) {
          let uniqueAnnouncementTypes = [
            ...new Set(
              items
                .filter(
                  (itemFilter) =>
                    itemFilter.announcementType !== undefined &&
                    itemFilter.announcementType !== null &&
                    itemFilter.announcementType !== ANNOUNCEMENT_TYPE_DEFAULT
                )
                .map((item) => {
                  return item.announcementType;
                })
            ),
          ];
          uniqueAnnouncementTypes.forEach((announcementType) => {
            sendEventData(announcementType);
          });
        }
      } catch (e) {
        console.error('sent announcement event error', e);
        isAnnouncementEventSentInitialSteps.current = false;
      }
    }

    if (!isAnnouncementEventSentInitialSteps.current) {
      sendAnnouncementEvent().then();
    }
  }, [items]);

  const sendEventData = (announcementType) => {
    const announcementPostfix = isModal ? 'AnnouncementPopup' : 'Announcement';

    const executeEventRequest = {
      event: event,
      data: {
        eventLabel: announcementType + announcementPostfix,
        eventAction: 'TabSelect',
      },
    };

    ChatBotService.execute(executeEventRequest);
  };

  const slideChange = (swiper) => {
    if (isModal === false || swiper.realIndex < 0) {
      return;
    }

    const announcementId = items[swiper.realIndex].announcementId;

    const isAnnouncementAlreadySeen = seenAnnouncementIds.some(
      (item) => item === announcementId
    );

    setSeenAnnouncementIds([...seenAnnouncementIds, announcementId]);

    if (!isAnnouncementAlreadySeen) {
      ChatBotService.updateChatBotAnnouncementSeenInfo(announcementId)
        .then(() => {})
        .catch(() => {});
    }
  };

  return (
    <>
      <Swiper
        showNavigation={showNavigation}
        onSlideChange={slideChange}
        onInit={slideChange}>
        {items.map((item, index) => {
          return (
            <SwiperSlide key={`announcement-${index}`}>
              <div
                className={classnames([
                  'announcement',
                  { 'announcement-without-swipe': !showNavigation },
                ])}>
                <div className="announcement-row icon">
                  <div className="announcement-icon-container">
                    <img
                      className="announcement-icon"
                      src={item.backgroundImageUrl}
                      alt="announcement-icon"
                    />
                  </div>
                </div>
                <div className="announcement-row content">
                  <div>
                    <h6>
                      {item.title && item.titleIconUrl && (
                        <img
                          className="announcement-title-icon"
                          src={item.titleIconUrl}
                          alt="announcement-title-icon"
                        />
                      )}
                      {item.title}
                    </h6>
                    <ReactMarkdown children={item.text} />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {!isModal && Utils.isArrayNotEmpty(items) && (
        <div
          className="announcement-button"
          onClick={() => {
            if (Utils.isShowHeaderNotification(notifications)) {
              setIsOpenNotification(true, 'Announcement');
            }
          }}>
          <span>{t(seeAllAnnouncement)}</span>
          <RightArrowIcon className="announcement-button-icon" />
        </div>
      )}
    </>
  );
};

export default Announcement;
