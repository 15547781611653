import * as keys from './keys';

export default {
  translation: {
    [`${keys.appTitle}`]: 'Trendyol Asistan',
    [`${keys.appTitleDodo}`]: 'DODO',
    [`${keys.appTabTitle}`]: 'Trendyol Asistan',
    [`${keys.appTitleTgoApp}`]: 'Asistan',
    [`${keys.appTitleMilla}`]: 'Asistan',
    [`${keys.liveChatTitle}`]: 'Canlı Yardım',
    [`${keys.liveChatTitleDodo}`]: 'Locker Canlı Yardım',
    [`${keys.liveChatTitleTgoApp}`]: 'Canlı Yardım',
    [`${keys.liveChatTitleMilla}`]: 'Canlı Yardım',
    [`${keys.liveChatTabTitle}`]: 'Canlı Yardım',
    [`${keys.chatbotErrorMsg}`]:
      'Xəta baş verdi, zəhmət olmasa yenidən cəhd edin',
    [`${keys.selectOrderMsg}`]: 'Seçin',
    [`${keys.orderSelectedMsg}`]: 'Seçildi',
    [`${keys.liveChatLoadingMessage}`]:
      'Siz müştəri nümayəndəmizə qoşulursunuz, zəhmət olmasa gözləyin..',
    [`${keys.agentsBusyMessage}`]:
      'Bütün agentlərimiz hazırda digər müştərilərə xidmət göstərir, zəhmət olmasa gözləyin..',
    [`${keys.liveChatConnecting}`]: 'Qoşulur...',
    [`${keys.systemJoined}`]: 'Müştəri nümayəndəsi təyin olundu...',
    [`${keys.agentDisconnected}`]: 'Yeni nümayəndə təyin olundu...',
    [`${keys.agentJoined}`]: 'Onlayn',
    [`${keys.systemJoinedMessage}`]: 'Trendyol Live Chat-a xoş gəlmisiniz.',
    [`${keys.liveChatOffline}`]: 'Oflayn',
    [`${keys.makeUploadedContentBigger}`]: 'Daha böyük göstər',
    [`${keys.timeOutFirstPart}`]:
      ' Uzun müddət heç nə yazmadığınız üçün görüşümüz',
    [`${keys.timeOutSecondPart}`]: 'Onun',
    [`${keys.timeOutThirdPart}`]: ' müddəti "saniyələrdə" bitəcək',
    [`${keys.closeLiveChatButton}`]: 'Danışmağı bitir',
    [`${keys.continueLiveChatButton}`]: 'Danışmağa davam et',
    [`${keys.liveChatRefused}`]:
      'Hazırda bu hesab vasitəsilə başqa bir görüş keçirilir. Hələ də ehtiyacınız varsa, bir müddət sonra yenidən cəhd edə bilərsiniz.',
    [`${keys.maxTimeOutThresholdExceedMessage}`]:
      'Uzun müddətdir işlənməyən zənginiz sistem tərəfindən avtomatik olaraq dayandırılıb. Anlayışınıza görə təşəkkür edirik.',
    [`${keys.agentLeftMessage}`]: 'Müştəri Nümayəndəsi zəngi bitirdi.',
    [`${keys.chatConnectionErrorMessage}`]:
      'Sistem xətası baş verdi, zəhmət olmasa yenidən cəhd edin.',
    [`${keys.chatInputBarPlaceholderMessage}`]: 'Mesajınızı yazın...',
    [`${keys.resendMediaMessage}`]: 'Yenidən cəhd edin',
    [`${keys.resendMediaMessageForEmptyImage}`]: 'Yenidən yükləməlisiniz.',
    [`${keys.unAuthorizedMessage}`]: 'Bu səhifəyə daxil olmaq icazəniz yoxdur',
    [`${keys.surveyMessage}`]:
      'Müştəri nümayəndəmizdən aldığınız xidmətdən razı qaldınızmı?',
    [`${keys.trendyolAsistantSurveyMessage}`]:
      'Trendyol Asistan xidmətindən nə dərəcədə razı qaldınız?',
    [`${keys.surveyCommentMessage}`]: 'Fikir və Təkliflərinizi Yazın',
    [`${keys.surveyRatingMessage}`]: 'Zəhmət olmasa xal seçin',
    [`${keys.liveChatSurveyRatingMessage}`]: 'Zəhmət olmasa xal seçin',
    [`${keys.surveyRatingBadMessage}`]: 'Çox pis',
    [`${keys.surveyRatingGoodMessage}`]: 'Çox yaxşı',
    [`${keys.surveyRateButton}`]: 'Qiymətləndirin',
    [`${keys.surveyErrorModalTitle}`]: 'SORĞU XƏTASI BAŞ VERDİ',
    [`${keys.surveyErrorRatingEmpty}`]: 'Zəhmət olmasa xalları daxil edin.',
    [`${keys.surveyErrorServiceError}`]:
      'Xəta baş verdi, zəhmət olmasa yenidən cəhd edin.',
    [`${keys.surveySuccessModalTitle}`]: 'QİYMƏTLƏNDİRMƏ PROSESİ UĞURLUDUR',
    [`${keys.surveySuccessModalMessage}`]:
      'Sorğumuzda iştirak etdiyiniz üçün təşəkkür edirik!',
    [`${keys.liveChatNewMessageAppTitle}`]: 'Yeni mesajınız var...',
    [`${keys.errorPageMessage}`]:
      'Xəta baş verdi, zəhmət olmasa sonra yenidən cəhd edin.',
    [`${keys.closeOnErrorButton}`]: 'Bağlayın',
    [`${keys.reconnectOnErrorButton}`]: 'Yenidən qoşulun',
    [`${keys.liveChatReconnecting}`]: 'Yenidən qoşulur...',
    [`${keys.other}`]: 'Əlavə qeyd',
    [`${keys.quantity}`]: 'Hissə',
    [`${keys.orderText}`]: 'Sifarişlərim',
    [`${keys.nameText}`]: 'Ad',
    [`${keys.lastnameText}`]: 'Soyad',
    [`${keys.nameSurnameText}`]: 'Ad Soyad',
    [`${keys.telephoneText}`]: 'Telefon',
    [`${keys.provinceText}`]: 'Vilayət',
    [`${keys.districtText}`]: 'Rayon',
    [`${keys.streetText}`]: 'Məhəllə',
    [`${keys.addressText}`]: 'Ünvan',
    [`${keys.addressTitleText}`]: 'Ünvan Başlığı',
    [`${keys.confirmAddressText}`]: 'Ünvanı təsdiqləyin',
    [`${keys.confirmOtpAddress}`]: 'Təsdiqləyin',
    [`${keys.confirmOtpAddressNewCode}`]: 'Kodu yenidən göndərin',
    [`${keys.selectRegisteredAddressText}`]: 'Saxlanmış Ünvanlarımdan Seçin',
    [`${keys.branchText}`]: 'Filial',
    [`${keys.dateText}`]: 'Tarix',
    [`${keys.saveText}`]: 'Yadda saxla',
    [`${keys.claimOrderSelectProduct}`]: '{0} Məhsulu seçin və davam edin',
    [`${keys.claimOrderSelectProducts}`]: 'Məhsulları seçin və davam edin',
    [`${keys.claimOrderFillToContinue}`]:
      'Davam etmək üçün seçdiyiniz məhsulun miqdarı və geri qaytarılma səbəbi haqqında məlumatı tam doldurmalısınız.',
    [`${keys.claimOrderClaimableIsNotDone}`]:
      'Zəhmət olmasa aşağıdakı məlumatları doldurun.',
    [`${keys.claimOrderDescription}`]: 'İzahat yazın',
    [`${keys.claimOrderReason}`]: 'Geri qayıtma səbəbini seçin',
    [`${keys.cancelOrderSelectProduct}`]:
      'Ləğv etmək istədiyiniz məhsulu aşağıda seçə bilərsiniz',
    [`${keys.cancelOrderSelectReason}`]: 'Ləğv etmə Səbəbini seçin',
    [`${keys.explanation}`]: 'İzahat',
    [`${keys.selectReason}`]: 'Ləğv etmə səbəbi',
    [`${keys.quantityText}`]: 'Hissə',
    [`${keys.productText}`]: 'məhsul',
    [`${keys.claimCodeText}`]: 'Qaytarma kodu',
    [`${keys.branchNameText}`]: 'Əlaqədar filialın adı',
    [`${keys.wrongDeliveredPersonNameText}`]:
      'Çatdırılmanın edildiyini görə biləcəyiniz adınız və soyadınız',
    [`${keys.ordersText}`]: 'Sifarişlərim',
    [`${keys.myOrderDetail}`]: 'Sifarişin Təfərrüatı',
    [`${keys.outOfWorkOurMessage}`]:
      'İş saatımız bitdiyinə görə sizi müştəri nümayəndəsinə yönləndirə bilmirəm, anlayışınız üçün təşəkkür edirik. İş saatlarımız: Həftə içi: 8:30-00:00 Həftə sonları: 10:00-00:00',
    [`${keys.claimAmountText}`]: 'Geri qaytarılan məbləğ',
    [`${keys.countAndPriceText}`]: 'Miqdarı və Qiyməti: ',
    [`${keys.totalClaimAmountText}`]: 'Geri qaytarılan ümumi məbləğ',
    [`${keys.claimReasonText}`]: 'Səbəb',
    [`${keys.claimRejectReasonLabel}`]: 'Geri qayıtmaqdan imtina səbəbi',
    [`${keys.bankRefNoText}`]: 'Bankın istinad nömrəsi',
    [`${keys.transitionScreenWaitingAreaText}`]:
      'Gözləyənlər arasında növbəniz:',
    [`${keys.claimStatusCargoText}`]: 'Geri qaytarılan yüküm haradadır?',
    [`${keys.showMoreShipment}`]: 'Daha çox məhsul göstər',
    [`${keys.cargoInfoText}`]: 'Yüküm haradadır?',
    [`${keys.invalidTokenInfoText}`]:
      'Məlumatlarınızı təhlil edə bilmirik. Xahiş edirik proqramdan çıxıb yenidən daxil olasınız.',
    [`${keys.newText}`]: 'YENİ',
    [`${keys.addText}`]: 'Əlavə et',
    [`${keys.loadedText}`]: 'Fayl seçildi',
    [`${keys.addNewContactText}`]: 'Yeni Əlaqə Məlumatı əlavə edin',
    [`${keys.updateContactText}`]: 'Əlaqə məlumatlarını yeniləyin',
    [`${keys.deliveredProductProblemImageAgentScreenLabel}`]:
      'Şikayət edilmiş məhsul şəkli:',
    [`${keys.orderNo}`]: 'Sifariş nömrəsi',
    [`${keys.shipmentNo}`]: 'Çatdırılma no',
    [`${keys.contactTypeText}`]: 'Ünsiyyət növü',
    [`${keys.emailText}`]: 'E-poçt',
    [`${keys.contactDescription}`]: 'İzahat',
    [`${keys.comboboxDefaultValue}`]: 'Seçin',
    [`${keys.continueLiveChatText}`]: 'Danışmağa davam et',
    [`${keys.exitLiveChatModalText}`]:
      'Canlı Yardımdan çıxmaq istədiyinizə əminsinizmi?',
    [`${keys.exitAndOpenSurvey}`]: 'Çıxın və Nümayəndəni Qiymətləndirin',
    [`${keys.clientReconnectingMessage}`]:
      'Hörmətli müştərimiz, internet bağlantınızın zəifliyi ilə əlaqədar sizi yenidən müştəri nümayəndəsinə yönləndiririk. Söhbətiniz qaldığı yerdən davam edəcək. Zəhmət olmasa gözləyin, anlayışınız üçün təşəkkür edirik.',
    [`${keys.clientRedirectedMessage}`]:
      'Hörmətli müştərimiz, internet bağlantınız yavaş olduğuna görə sizi başqa müştəri təmsilçisinə yönləndiririk. Bu zəngdəki söhbət tarixçəsi yeni nümayəndəyə yönləndiriləcək. Zəhmət olmasa gözləyin, anlayışınız üçün təşəkkür edirik.',
    [`${keys.selectProductImageText}`]:
      'Məhsul şəklini seçin (Maks. {{count}} ədəd)',
    [`${keys.selectProductVideoText}`]: '1 video yükləyin (maksimum 40MB)',
    [`${keys.deleteText}`]: 'Silin',
    [`${keys.optionalFileInfoText}`]:
      'İstəyirsinizsə, şəkillər əlavə edə bilərsiniz.',
    [`${keys.imageMaxSizeErrorText}`]:
      'Faylın ölçüsü {{size}} MB-dan çox ola bilməz.',
    [`${keys.fileInputVideoInfo}`]:
      'Maksimum 30 saniyəlik 1 video yükləməlisən',
    [`${keys.imageFormatMismatchErrorText}`]:
      'Yalnız JPG və PNG formatlarında şəkillər əlavə edə bilərsiniz.',
    [`${keys.imageCountErrorText}`]: 'Maksimum {{count}} fayl seçə bilərsiniz.',
    [`${keys.fileUploadVideoErrorText}`]:
      'Təxminən 30 saniyə uzunluğunda bir video əlavə edə bilərsən.',
    [`${keys.imageReadError}`]: 'Faylı oxumaq mümkün olmadı.',
    [`${keys.fileNameNotValidError}`]: 'Faylın adını oxumaq mümkün olmadı.',
    [`${keys.fileEmptyError}`]: 'Yüklənmiş faylın məzmunu boşdur.',
    [`${keys.fileNameNotValidError}`]: 'Fayl adı yanlışdır.',
    [`${keys.fileEmptyError}`]: 'Yüklənən faylın məzmunu boşdur.',
    [`${keys.imageUploadError}`]:
      'Şəkli yükləyərkən xəta baş verdi. Zəhmət olmasa, yenidən cəhd edin.',
    [`${keys.goDeliveredTicketProductsFillToContinue}`]:
      'Davam etmək üçün seçdiyiniz məhsulun təsvirini, şəklini, təfərrüatını və miqdar məlumatlarını tam doldurmalısınız.',
    [`${keys.seller}`]: 'Satıcı',
    [`${keys.me}`]: 'Mən',
    [`${keys.street}`]: 'Prospekt / Küçə',
    [`${keys.reasonText}`]: 'Dəyişiklik səbəbi',
    [`${keys.descriptionText}`]: 'İzahat',
    [`${keys.buildingNoText}`]: 'Bina №',
    [`${keys.apartmentNoText}`]: 'Mənzil №',
    [`${keys.neighborhoodText}`]: 'Məhəllə',
    [`${keys.cancelBtnText}`]: 'İmtina edin',
    [`${keys.createTicket}`]: 'Sorğunuzu Yaradın',
    [`${keys.stickySurveyTitle}`]: 'Trendyol Asistan’dan razıs qaldınızmı?',
    [`${keys.stickySurveyRateMessage}`]: 'Zəhmət olmasa bizi qiymətləndirin',
    [`${keys.stickySurveyCommentMessage}`]:
      'Rəy və təkliflərinizi yaza bilərsiniz',
    [`${keys.stickySurveySuccessTitle}`]: 'Qiymətləndirmənizi Aldıq!',
    [`${keys.stickySurveySuccessText}`]:
      'Özümüzü təkmilləşdirək üçün etdiyiniz qiymətləndirmənizə görə təşəkkür edirik.',
    [`${keys.stickySurveySuccessButtonText}`]: 'Bağlayın',
    [`${keys.placeHolderName}`]: 'Adınızı daxil edin',
    [`${keys.placeHolderSurname}`]: 'Soyadınızı daxil edin',
    [`${keys.placeHolderProvince}`]: 'Vilayət seçin',
    [`${keys.placeHolderDistrict}`]: 'Rayon seçin',
    [`${keys.placeHolderNeighborhood}`]: 'Məhəllə seçin',
    [`${keys.placeHolderStreet}`]: 'Küçə seçin',
    [`${keys.placeHolderAddress}`]:
      'Ünvanınızı daxil edin (10 simvoldan çox olmalıdır)',
    [`${keys.placeHolderAddressTitle}`]: 'Ünvan başlığını daxil edin',
    [`${keys.placeHolderSelectReason}`]: 'Dəyişiklik səbəbini seçin',
    [`${keys.placeHolderDescription}`]: 'Təsviri daxil edin',
    [`${keys.placeHolderBuildingNo}`]: 'Bina nömrəsini daxil edin',
    [`${keys.placeHolderApartmentNo}`]: 'Mənzil nömrəsini daxil edin',
    [`${keys.badCodeErrorMsg}`]:
      'Daxil etdiyiniz doğrulama kodu yanlışdır. SMS-də göndərdiyimiz doğrulama kodunu yoxlayaraq yenidən cəhd edə bilərsiniz.',
    [`${keys.reasonSelectText}`]: 'Səbəb seçin',
    [`${keys.orderImageLabel}`]: 'Sifariş şəkli',
    [`${keys.chatbotShortErrorMsg}`]: 'Nə isə xəta baş verdi.',
    [`${keys.chatbotErrorMsgKey}`]: 'Xəta!',
    [`${keys.dateSelect}`]: 'Tarix seçin',
    [`${keys.placeHolderbranch}`]: 'Filial adını daxil edin',
    [`${keys.fileLoadedText}`]: 'Fayl yükləndi',
    [`${keys.uploadFileText}`]: 'Fayl yükləyin',
    [`${keys.collectionPointText}`]: 'Gəlin və alın nöqtəsi',
    [`${keys.groupDealText}`]: 'Dostlarınızla alın',
    [`${keys.commercialText}`]: 'Korporativ faktura',
    [`${keys.wardrobeText}`]: 'Şkaf',
    [`${keys.moreProductText}`]: 'Daha çox məhsul',
    [`${keys.barcodeText}`]: 'Barkod',
    [`${keys.placeHolderBarcodeText}`]: 'Məhsulunuzun barkodunu daxil edin',
    [`${keys.placeHolderDescriptionText}`]: 'Təsviri daxil edin.',
    [`${keys.orderDate}`]: 'Sifariş tarixi',
    [`${keys.orderSummary}`]: 'Sifarişin xülasəsi',
    [`${keys.totalText}`]: 'Cəmi',
    [`${keys.currencyText}`]: 'TL',
    [`${keys.deliveryDateText}`]: 'Çatdırılma tarixi:',
    [`${keys.deliveryTimeText}`]: 'Çatdırılma müddəti:',
    [`${keys.deliveryNumberText}`]: 'Çatdırılma nömrəsi:',
    [`${keys.deliveryAddressText}`]: 'Çatdırılma ünvanı',
    [`${keys.billingAddressText}`]: 'Faktura ünvanı',
    [`${keys.yourEmailText}`]: 'E-poçt',
    [`${keys.placeholderEmail}`]: 'E-poçtu daxil edin',
    [`${keys.languageText}`]: 'Dil',
    [`${keys.placeholderLanguage}`]: 'Dil Seçin',
    [`${keys.placeholderDeliveryNumber}`]: 'Çatdırılma nömrəsini daxil edin:',
    [`${keys.startChatText}`]: 'Söhbətə başlayın',
    [`${keys.closeButtonText}`]: 'Bağlayın',
    [`${keys.retryButtonText}`]: 'Yenidən cəhd edin',
    [`${keys.placeholderSubject}`]: 'Mövzu daxil edin',
    [`${keys.subjectText}`]: 'Mövzu',
    [`${keys.mailDescriptionText}`]:
      'Zəhmət olmasa sualınızı ətraflı izah edin',
    [`${keys.mailSubmitText}`]: 'Göndərin',
    [`${keys.paymentInfoText}`]: 'Ödəniş məlumatları',
    [`${keys.subtotalText}`]: 'Aralıq cəmi',
    [`${keys.cargoText}`]: 'Çatdırılma qiyməti',
    [`${keys.englishLangText}`]: 'İngilis dili',
    [`${keys.englishLangTextShort}`]: 'İNG',
    [`${keys.germanLangText}`]: 'Alman dili',
    [`${keys.dateErrorText}`]: ' Zəhmət olmasa etibarlı tarix daxil edin.',
    [`${keys.claimQuantityText}`]: 'Miqdar seçin',
    [`${keys.shipmentSubtitleTotal}`]: 'Cəmi',
    [`${keys.shipmentSubtitleItems}`]: 'Məhsul ',
    [`${keys.claimSimulationPreviewQuantity}`]: 'Hissə',
    [`${keys.claimSimulationPreviewProduct}`]: 'məhsul',
    [`${keys.claimSubtitleTotal}`]: 'Cəmi',
    [`${keys.claimSubtitleItems}`]: 'Məhsul ',
    [`${keys.seeAllAnnouncement}`]: 'Bütün elanlara baxın',
    [`${keys.showMoreTicket}`]: 'Daha çox göstər',
    [`${keys.notificationsTitle}`]: 'Məlumat',
    [`${keys.copyText}`]: 'Kopyala',
    [`${keys.copiedText}`]: 'Kopyalandı',
    [`${keys.linkCopied}`]: 'Sifariş nömrəsinin surəti çıxarıldı',
    [`${keys.detail}`]: 'Təfərrüat',
    [`${keys.addExtraImage}`]: 'Əlavə Şəkil əlavə edin',
    [`${keys.fastMarketText}`]: 'SÜRƏTLİ BAZAR',
    [`${keys.mealText}`]: 'YEMƏK',
    [`${keys.minumumOrderQuantityHeader}`]: 'Minimum Sifariş Miqdarı',
    [`${keys.minumumOrderQuantityMessage}`]:
      'ilə göstərilən məhsullarınız üçün bu əməliyyatı təsdiqlədiyiniz zaman bütün əlaqəli məhsullarınız ləğv ediləcək.',
    [`${keys.differentSubject}`]: 'Fərqli Mövzu üçün Burdayam',
    [`${keys.inputLanguageNotSupported}`]:
      'Burada yalnız türkcə xidmət edirik.',
    [`${keys.azLanguageDescription}`]: 'Azərbaycan ünvanına sifariş',
    [`${keys.trLanguageDescription}`]: 'Türkiyə ünvanına sifariş',
    [`${keys.shipmentCountry}`]: 'Çatdırılma Ölkəsi:',
    [`${keys.changeShipmentCountry}`]: 'Dəyişdirin',
    [`${keys.azerbeijan}`]: 'Azərbaycan',
    [`${keys.turkey}`]: 'Türkiyə',
    [`${keys.delivery}`]: 'Çatdırılma',
    [`${keys.showMoreContent}`]: 'Daha Çox Göstər',
    [`${keys.showLessContent}`]: 'Daha Az Göstər',
    [`${keys.approvalCode}`]: 'Təsdiq Kodu',
    [`${keys.seconds}`]: 'saniyə',
    [`${keys.userDisconnectedOnBackground}`]:
      'Söhbət zamanı tətbiqdən çıxdığınız və ya uzun müddət aktiv olmadığınız üçün bu söhbət sonlandı. Anlayışınız üçün təşəkkürlər.',
  },
};
