import React from 'react';
import './ItemDetailList.scss';
import * as PropTypes from 'prop-types';
import Utils from '../../../utils';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import Bubble from '../../layout/bubble/Bubble';

const ItemDetailList = (data) => {
  const { componentData, showLabel = true, select } = data;

  const selectedButton = (button) => {
    if (data.showHover) {
      let selectData = {
        data: {
          [componentData.idKey]: componentData.id,
        },
        event: button.event,
      };
      select(selectData);
    }
  };

  function renderButtons() {
    return (
      <ButtonGroup>
        {componentData &&
          componentData.buttons.map((button, index) => {
            const buttonType = button.buttonType;
            let colorType = Utils.getColorType(buttonType);
            let outline = button.outline !== false;

            return (
              <ButtonGroup.Button
                key={button.text}
                isSelectable={data.showHover}
                data-testid="item-detail-selection"
                className="item-detail-container"
                outline={outline}
                colorType={colorType}
                iconLeft={Utils.getButtonIcon(buttonType, button.iconUrl)}
                onClick={() => selectedButton(button, index)}>
                {button.text}
              </ButtonGroup.Button>
            );
          })}
      </ButtonGroup>
    );
  }

  return (
    <>
      <Bubble
        titleFragment={
          componentData.label &&
          showLabel &&
          Utils.newLineToBreak(componentData.label)
        }
        isBottomRounded={false}
        className="item-detail-list-bubble">
        <div className="content-wrapper">
          {componentData.itemDetailList.map((item) => {
            return (
              <div className="item-detail">
                <img className="item-detail-image" src={item.imageUrl} />
                <div className="item-detail-name">{item.name}</div>
              </div>
            );
          })}
        </div>
      </Bubble>
      <div className="item-detail-list" data-testid="item-detail-list">
        {componentData.buttons && renderButtons()}
      </div>
    </>
  );
};

ItemDetailList.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ItemDetailList;
