import React from 'react';
import './ActionButton.scss';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import Bubble from '../../layout/bubble/Bubble';
import appService from '../../../services/app.service';
import {
  INFLUENCER_SEGMENT_ID,
  INFLUENCER_SEGMENT_NAME,
} from '../../../constants/sessionStorageConstants';

const ActionButton = ({ componentData, select, isSelectable }) => {
  const handleClickButton = (userSegment) => {
    if (isSelectable) {
      let requestPayload = {
        event: 'START',
        userSegment,
      };

      if (userSegment === INFLUENCER_SEGMENT_NAME) {
        appService.setSegmentId(INFLUENCER_SEGMENT_ID);
        appService.setChatBotUrl(null);
      }

      select(requestPayload);
    }
  };
  return (
    <div className="action-button">
      <Bubble title={componentData.label} isBordered={false}>
        <ButtonGroup>
          {componentData.buttons.map((buttonData) => {
            return (
              <ButtonGroup.Button
                onClick={() => handleClickButton(buttonData.userSegment)}
                key={buttonData.userSegment}
                data-testid={buttonData.userSegment}
                isSelectable={isSelectable}>
                {buttonData.text}
              </ButtonGroup.Button>
            );
          })}
        </ButtonGroup>
      </Bubble>
    </div>
  );
};

export default ActionButton;
