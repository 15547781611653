import React, { useContext } from 'react';
import ChatBotContext from '../../../../context/chatbot/chatbotContext';
import './VoteFooter.scss';

const VoteFooter = ({ data, isRenderedFromOutside = false }) => {
  const chatBotContext = useContext(ChatBotContext);
  const { requestToChatBot } = chatBotContext;

  const onClick = (vote) => {
    if (!isRenderedFromOutside) {
      let requestPayload = {
        event: vote.data.event,
      };
      requestToChatBot(requestPayload);
    }
  };
  return (
    <div className="vote-footer">
      {data.map((vote, i) => {
        return (
          <div
            className={'vote ' + vote.type.toLowerCase()}
            onClick={() => onClick(vote)}
            key={i}>
            <div className="text">{vote.text}</div>
            <img src={vote.imageUrl} />
          </div>
        );
      })}
    </div>
  );
};

export default VoteFooter;
