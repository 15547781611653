import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  cargoText,
  codServiceFeeText,
  coinEarnedMessage,
  coinEarnedOrderMessage,
  coinEarnedQuantityAndAmountInfo,
  coinQuantityAndAmountInfo,
  coinsWording,
  coinUsageMessage,
  coinUsageOrderMessage,
  paymentInfoText,
  subtotalText,
  totalText,
} from '../../../../../../i18n/keys';
import appService from '../../../../../../services/app.service';
import classnames from 'classnames';
import PriceLabel from '../../../../../common/currency/priceLabel';
import CoinIcon from '../../../../../../assets/icons/coin-icon.svg';
import CoinEarnedIcon from '../../../../../../assets/icons/coin-earned.svg';

const OrderPaymentInfo = ({ order }) => {
  const { t } = useTranslation();
  const paymentInfo = order.paymentInfo;

  const renderPaymentInfo = () => {
    if (paymentInfo.paymentOptionText) {
      return <div>{paymentInfo.paymentOptionText}</div>;
    } else {
      return (
        <>
          {/* {paymentInfo.cardTypeLogoUrl && (
            <div className="order-payment-info__card-image">
              <img alt="card-logo" src={paymentInfo.cardTypeLogoUrl} />
            </div>
          )} */}
          <div className="order-payment-info__card-no">
            {paymentInfo.maskedCardNumber}
          </div>
        </>
      );
    }
  };

  return (
    <div className="order-payment-info">
      <div className="order-payment-info__text">
        <div className="order-payment-info__text-title">
          {t(paymentInfoText)}
        </div>

        <div className="order-payment-info__card">{renderPaymentInfo()}</div>
      </div>
      <div className="order-payment-info__subtotal">
        <div className="text">{t(subtotalText)}</div>
        <div className="value">
          <PriceLabel
            price={paymentInfo.productSubTotal}
            currency={order.currency}
          />
        </div>
      </div>
      <div className="order-payment-info__cargo">
        <div className="text">{t(cargoText)}</div>
        <div className="value">
          <PriceLabel
            price={paymentInfo.cargoPrice}
            currency={order.currency}
          />
        </div>
      </div>
      {!!paymentInfo.codServiceFee && (
        <div className="order-payment-info__codServiceFee">
          <div className="text">{t(codServiceFeeText)}</div>
          <div className="value">
            <PriceLabel
              price={paymentInfo.codServiceFee}
              currency={order.currency}
            />
          </div>
        </div>
      )}
      <div className="order-payment-info__discounts">
        {paymentInfo.discounts &&
          paymentInfo.discounts.map((discount, i) => (
            <div className="order-payment-info__discounts-info" key={i}>
              <div className="order-payment-info__discounts-info__name">
                {discount.name}
              </div>
              <div
                className={classnames([
                  'order-payment-info__discounts-info__price',
                  {
                    dynamic: appService.isDynamicStylingChannel(),
                  },
                ])}>
                <PriceLabel
                  price={discount.price}
                  currency={order.currency}
                  isDiscount={true}
                />
              </div>
            </div>
          ))}
      </div>
      <hr className="order-payment-info__hr" />
      <div className="order-payment-info__totalCharges">
        <div className="text">{t(totalText)}</div>
        <div
          className={classnames([
            'value',
            {
              dynamic: appService.isDynamicStylingChannel(),
            },
          ])}>
          <PriceLabel
            price={paymentInfo.totalCharges}
            currency={order.currency}
          />
        </div>
      </div>
      {paymentInfo.cashback && !!paymentInfo.cashback.quantity && (
        <div className="order-payment-info__coin">
          <div className="coin-info" style={{ background: '#FCF0EE' }}>
            <img
              src={CoinEarnedIcon}
              className="coin-earned-icon"
              alt="coin-earned-icon"
            />
            <div className="coin-info-message">
              <span className="coin-info-message-label">
                {t(coinEarnedMessage)}
              </span>
              <span
                className="coin-info-message-amount-label"
                style={{ color: '#D7735F' }}>
                {t(coinEarnedQuantityAndAmountInfo, {
                  quantity: paymentInfo.cashback.quantity,
                  value: paymentInfo.cashback.value,
                  currency: order.currency,
                  coinsWording: t(coinsWording),
                })}
              </span>
              <span className="coin-info-message-label">
                {t(coinEarnedOrderMessage)}
              </span>
            </div>
          </div>
        </div>
      )}
      {paymentInfo.coinParams && (
        <div className="order-payment-info__coin">
          <div className="coin-info" style={{ background: '#ECFFF3' }}>
            <img src={CoinIcon} className="coin-icon" alt="coin-icon" />
            <div className="coin-info-message">
              <span className="coin-info-message-label">
                {t(coinUsageMessage)}
              </span>
              <span
                className="coin-info-message-amount-label"
                style={{ color: '#088A42' }}>
                {t(coinQuantityAndAmountInfo, {
                  quantity: paymentInfo.coinParams.quantity,
                  amount: paymentInfo.coinParams.amount,
                  currency: order.currency,
                  coinsWording: t(coinsWording),
                })}
              </span>
              <span className="coin-info-message-label">
                {t(coinUsageOrderMessage)}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

OrderPaymentInfo.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderPaymentInfo;
