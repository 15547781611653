export const CancellableItem = 'CancellableItem';
export const SIZE = 'Beden';
export const QUANTITY = 'Adet';
export const SELECT_QUANTITY = 'Adet Seçiniz';

//Chatbot Survey
export const GET_SURVEY_OPEN_FOR_CHAT_BOT = 'GET_SURVEY_OPEN_FOR_CHAT_BOT';
export const GET_SAVE_SURVEY_RESULT_FOR_CHAT_BOT =
  'GET_SAVE_SURVEY_RESULT_FOR_CHAT_BOT';
export const TY_EXIT_URL = 'ty://?Page=Account';
export const INSTANT_EXIT_URL =
  'ty://?Channel=InstantDelivery&Page=TrendyolAssistant';
export const MEAL_EXIT_URL = 'ty://?Channel=Meal&Page=TrendyolAssistant';
export const COURIER_EXIT_URL = 'trendyolgo://home';
export const SELLER_EXIT_URL = 'tysc://AssistantRedirect?redirect=true';
export const MEAL_SELLER_APP_EXIT_URL =
  'tymsa://AssistantRedirect?redirect=true';
export const SELLER_PICKER_EXIT_URL = 'typa://AssistantRedirect?redirect=true';
export const EXIT_ASSISTANT_URL = 'ty://?Page=CloseAssistant';
export const ANDROID_MEAL_EXIT_ASSISTANT_URL =
  'ty://?Page=CloseAssistant&Channel=Meal';
export const ANDROID_INSTANT_EXIT_ASSISTANT_URL =
  'ty://?Page=CloseAssistant&Channel=InstantDelivery';
export const EXIT_INTERNATIONAL_ASSISTANT_URL =
  'ty://?Channel=International&Page=CloseAssistant';
export const EXIT_INTERNATIONAL_ASSISTANT_SELLER_URL =
  'tysc://AssistantRedirect?redirect=true';
export const EXIT_DOLAP_ASSISTANT_URL = 'ty://?Channel=DolapLite&Page=';
export const EXIT_DODO_URL = 'tysc://AssistantRedirect?redirect=true';
export const EXIT_CLASSIFIED_SELLER_URL =
  'tysc://AssistantRedirect?redirect=true';
//International
export const GET_INTERNATIONAL_SEND_EMAIL_RESULT =
  'GET_INTERNATIONAL_SEND_EMAIL_RESULT';
export const INTERNATIONAL_SEND_EMAIL_EVENT = 'GET_INTERNATIONAL_SEND_EMAIL';
export const INTERNATIONAL_CONNECT_LIVECHAT_EVENT =
  'GET_INTERNATIONAL_CONNECT_TO_LIVECHAT';
export const CONNECT_LIVECHAT_EVENT = 'GET_CONNECT_TO_LIVECHAT';

//Close deeplink mobile build names
export const CLOSE_ASSISTANT_ANDROID_BUILD_NAME = 500;
export const CHANNEL_BASED_CLOSE_ASSISTANT_ANDROID_BUILD_NAME = 693;
export const CLOSE_ASSISTANT_IOS_BUILD_NAME = 211;

export const DataType = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
};

export const GET_ACTION_EVENT = 'GET_ACTION_EVENT';
export const GET_ENGLISH_SUPPORT = 'GET_ENGLISH_SUPPORT';
export const GET_SHIPMENT_DETAIL = 'GET_SHIPMENT_DETAIL';
export const GET_MEAL_SHIPMENT_DETAIL = 'GET_MEAL_SHIPMENT_DETAIL';
export const GET_INITIAL = 'GET_INITIAL';

export const SELLER_GROCERY_PICKER_APP_NAME = 'groceryPickerApp';
export const TGO_APP_NAME = 'tgoApp';
export const MEAL_SELLER_APP_NAME = 'mealSellerApp';
export const MILLA_APP_NAME = 'MILLA';

// Upload version v1 for alibaba v2 for openStack
export const UPLOAD_VERSION_1 = 'V1';
export const UPLOAD_VERSION_2 = 'V2';

export const TRUE = 'TRUE';
export const FALSE = 'FALSE';

// Upload version v1 for alibaba v2 for openStack
export const ORDER_BOX_VERSION_1 = 'V1';
export const ORDER_BOX_VERSION_2 = 'V2';

export const ENGLISH_SUPPORT_VERSION_1 = 'V1';
export const ENGLISH_SUPPORT_VERSION_2 = 'V2';

export const LANGUAGE_CODE_TR = 'tr';
export const LANGUAGE_CODE_EN = 'en';

export const DEFAULT_UPLOAD_SIZE = 10;

export const DEFAULT_POPUP_WAITING_TIME_IN_SECONDS = 1;

export const COUNTRY_CODE_AZ = 'AZ';
export const CUSTOMER_COUNTRY_CODE_PROPERTY_KEY = 'CUSTOMER_COUNTRY_CODE';
export const ORDER_NUMBER_KEY = 'ORDER_NUMBER';

export const CDN_BASE_URL = 'cdn.dsmcdn.com';
