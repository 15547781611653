import React, { useContext } from 'react';
import {
  BUTTON_SELECTED_NO,
  BUTTON_SELECTED_YES,
  LIVE_CHAT_PAGE,
  pages,
} from '../../../constants';
import getButtonType from '../../../constants/buttonType';
import infoIcon from '../../../assets/icons/info.svg';
import './OperationButton.scss';
import * as PropTypes from 'prop-types';
import Utils from '../../../utils';
import utils from '../../../utils';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import classNames from 'classnames';
import usePersistentState from '../../common/usePersistentState';
import appService from '../../../services/app.service';
import Select from '../../Select';
import {
  CONNECT_LIVECHAT_EVENT,
  INTERNATIONAL_CONNECT_LIVECHAT_EVENT,
  INTERNATIONAL_SEND_EMAIL_EVENT,
} from '../../../constants/chatAssistantConstant';
import { withRouter } from 'react-router-dom';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import Bubble from '../../layout/bubble/Bubble';

const OperationButton = (data) => {
  const chatBotContext = useContext(ChatBotContext);
  const { requestForDeepLink } = chatBotContext;

  const {
    id,
    componentData,
    onClick,
    showLabel = true,
    isSelectable,
    history,
    isRenderedFromOutside = false,
  } = data;
  const [selectedButtonIndex, setSelectedButtonIndex] = usePersistentState(
    id,
    'selectedButtonIndex',
    -1
  );
  const [imageFailed, setImageFailed] = usePersistentState(
    id,
    'imageFailed',
    false
  );

  const [selectedDropdownItem, setSelectedDropdownItem] = usePersistentState(
    id,
    'selectedDropdownItem',
    -1
  );
  const [isDropdownSelected, setIsDropdownSelected] = usePersistentState(
    id,
    'isDropdownSelected',
    false
  );

  const dropDownElements = componentData.dropDownElements;

  const selectedButton = (button, index) => {
    if (data.showHover) {
      setSelectedButtonIndex(index);

      let selectData = button;
      if (isButtonHasDeepLink(button)) {
        return handleButtonDeepLink(button);
      } else if (
        button.buttonType !== BUTTON_SELECTED_YES &&
        button.buttonType !== BUTTON_SELECTED_NO
      ) {
        if (button.event === INTERNATIONAL_SEND_EMAIL_EVENT) {
          appService.setIsInternationalSendEmailOpened(true);
        } else if (button.event === INTERNATIONAL_CONNECT_LIVECHAT_EVENT) {
          appService.setIsLiveChatDeepLinkClicked(true);
        } else if (button.event === CONNECT_LIVECHAT_EVENT) {
          const deepLinkRequest = {
            event: 'GET_DEEP_LINK',
            data: {
              deepLinkEventLabel: 'CONNECT_TO_MT',
            },
          };
          requestForDeepLink(deepLinkRequest);
          appService.setIsLiveChatDeepLinkClicked(true);
          appService.setCurrentPage(pages.LIVECHAT);
          history.push(LIVE_CHAT_PAGE);
        } else {
          selectData = {
            data: {
              buttonType: button.buttonType,
            },
            event: button.event,
            componentsId: button.componentsId,
            genericStateEvent: button.genericStateEvent,
          };
        }
      }
      if (onClick && typeof onClick === 'function') {
        onClick(button, index);
      } else {
        data.select(selectData);
      }
    }
  };

  const handleButtonDeepLink = (button) => {
    return utils.handleClickDeeplink(
      button.event,
      button.eventLabel,
      button.url,
      requestForDeepLink
    );
  };

  const isButtonHasDeepLink = (button) => {
    return button.event && button.eventLabel && button.url;
  };

  const renderImages = (imageUrls) => {
    return Utils.renderShipmentImages(imageUrls, fallbackImage, imageFailed);
  };

  const fallbackImage = () => {
    setImageFailed(true);
  };

  function onClickDropdownButton(event) {
    if (isDropdownSelected) {
      const selectData = {
        data: {
          selectedDropdownItemValue: selectedDropdownItem,
        },
        event: event,
      };

      data.select(selectData);
    }
  }

  function renderDropDownButton() {
    return (
      <ButtonGroup>
        {componentData &&
          componentData.buttons.map((button) => {
            const buttonType = button.buttonType;

            let colorType = Utils.getColorType(buttonType);
            let outline = button.outline !== false;

            return (
              <ButtonGroup.Button
                key={button.text}
                isSelectable={data.showHover && isDropdownSelected}
                data-testid="operation-button"
                iconLeft={Utils.getButtonIcon(buttonType, button.iconUrl)}
                className={classNames({
                  'request-button': true,
                  'request-button--active': isDropdownSelected,
                  'request-button--passive':
                    isSelectable === false ? true : !isDropdownSelected,
                })}
                outline={outline}
                disabled={!isDropdownSelected}
                colorType={colorType}
                onClick={() => onClickDropdownButton(button.event)}>
                {button.text}
              </ButtonGroup.Button>
            );
          })}
      </ButtonGroup>
    );
  }

  function renderButtons() {
    return (
      <ButtonGroup>
        {componentData &&
          componentData.buttons.map((button, index) => {
            const buttonType = getButtonType(button.buttonType, button.outline);

            return (
              <ButtonGroup.Button
                key={button.text}
                isSelectable={data.showHover}
                data-testid="operation-button"
                className="operation-button-container"
                outline={buttonType.outline}
                colorType={buttonType.colorType}
                selected={selectedButtonIndex === index}
                iconLeft={Utils.getButtonIcon(
                  button.buttonType,
                  button.iconUrl
                )}
                onClick={() => selectedButton(button, index)}>
                {button.text}
              </ButtonGroup.Button>
            );
          })}
      </ButtonGroup>
    );
  }

  const renderReasonItems = () =>
    dropDownElements.dropDownItems.map((reason) => ({
      value: reason.value,
      label: reason.label,
    }));

  function handleSelection(data) {
    setSelectedDropdownItem(data);
    setIsDropdownSelected(true);
  }

  return (
    <div
      className={classNames('operation-buttons', {
        'with-detail': componentData.hasDetail,
        readonly: isRenderedFromOutside,
      })}
      data-testid="operation-buttons">
      {componentData.label && showLabel ? (
        <Bubble
          title={componentData.label}
          labelType={componentData.labelType}
          isBottomRounded={!componentData.hasDetail}
          isBordered={componentData.labelType === 'Info' ? false : true}>
          {componentData.hasDetail && (
            <div
              className={classNames({
                'content-wrapper': true,
                has_sub_text: componentData.subTextSubject,
              })}>
              <div className="details">
                <div
                  className={classNames({
                    image__wrap: true,
                  })}>
                  {renderImages(componentData.productImages)}
                </div>
                {componentData.productImages.length >= 1 && (
                  <div className="product-name-detail">
                    <div className="product-names">
                      {componentData.productNames.slice(0, 2).map((product) => {
                        return <span className="product-name">{product}</span>;
                      })}
                    </div>
                    {componentData.productNames.length > 2 && (
                      <div
                        className={classNames({
                          subtitle: true,
                        })}>
                        +{componentData.productNames.length - 2} Ürün Daha
                      </div>
                    )}
                  </div>
                )}
              </div>
              {dropDownElements && (
                <div className="select-container">
                  <Select
                    options={renderReasonItems()}
                    placeholder={dropDownElements.dropDownPlaceHolderText}
                    value={
                      selectedDropdownItem === -1
                        ? undefined
                        : selectedDropdownItem
                    }
                    onChange={handleSelection}
                  />
                  {renderDropDownButton()}
                  <div className="dropdown-info">
                    <img
                      alt={dropDownElements.dropDownPlaceHolderText}
                      className="dropdown-info-icon"
                      src={infoIcon}
                    />
                    <div className="dropdown-info-text">
                      {dropDownElements.infoText}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {componentData.subTextSubject && (
            <div className="subText">
              {componentData.subTextSubject}
              <span className="colon">:</span>
              <span className="value">{componentData.subTextValue}</span>
            </div>
          )}
          {componentData.subTexts &&
            componentData.subTexts.map((subText, index) => {
              return (
                <div key={index} className="subText">
                  <span>{subText.subTextSubject}</span>
                  <span className="colon">:</span>
                  <span className="value">{subText.subTextValue}</span>
                </div>
              );
            })}
          {!dropDownElements &&
            componentData &&
            componentData.buttons &&
            renderButtons()}
        </Bubble>
      ) : (
        !dropDownElements &&
        componentData &&
        componentData.buttons &&
        renderButtons()
      )}
    </div>
  );
};

OperationButton.propTypes = {
  data: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default withRouter(OperationButton);
