// sessionStorage Values
export const TOKEN_NAME = 'token';
export const SEGMENT_ID = 'SegmentId';
export const VERSION = 'Version';
export const ASSISTANT_PROPERTIES = 'assistantProperties';
export const PLATFORM_NAME = 'platform';
export const PID_NAME = 'pid';
export const SID_NAME = 'sid';
export const EVENT_NAME = 'eventName';
export const CHAT_BOT_URL = 'chatBotUrl';
export const GENERIC_EVENT_NAME = 'genericEventName';
export const SHIPMENT_NUMBER = 'shipmentNumber';
export const ORDER_NUMBER = 'orderNumber';
export const ORDER_ID = 'orderId';
export const CHAT_BOT_HISTORY = 'chatBotHistory';
export const LIVE_CHAT_CONFIG = 'liveChatConfig';
export const ASSISTANT_ID = 'assistantId';
export const LIVE_CHAT_CLICKED = 'liveChatClicked';
export const CHAT_SECURE_KEY = 'chatSecureKey';
export const CHAT_ID = 'chatId';
export const LIVE_CHAT_STYLED_MESSAGE = 'liveChatStyledMessage';
export const SURVEY_SECURE_KEY = 'surveySecureKey';
export const IS_SURVEY_OPEN = 'isSurveyOpen';
export const IS_EVENT_CHANGED = 'isEventChanged';
export const CLICKED_TOPIC_EVENT = 'clickedTopicEvent';
export const CLICKED_LAST_EVENT_FOR_SURVEY = 'clickedLastEventForSurvey';
export const BUILD_NAME = 'buildName';
export const APP_VERSION = 'appVersion';
export const COUNTRY_CODE = 'countryCode';
export const REFRESH_TOKEN_SELLER = 'refreshTokenSeller';
export const MEDIA_CENTER_TOKEN = 'mediaCenterToken';
export const CHANNEL_ID = 'ChannelId';
export const SUB_CHANNEL = 'SubChannel';
export const CHANNEL_ID_WITHOUT_DEFAULT = 'ChannelIdWithoutDefault';
export const DELIVERED_PRODUCT_PROBLEM_IMAGE = 'deliveredProductProblemImage';
export const ASSISTANT_SCROLL_POSITION = 'assistantScrollPosition';
export const LIVECHAT_SCROLL_POSITION = 'assistantScrollPosition';
export const MENU_TYPE = 'menuType';
export const LANGUAGE = 'language';
export const STOREFRONT_ID = 'storefrontId';
export const IS_GUEST_USER = 'isAuthenticated';
export const LAST_CHAT_TOPIC = 'lastChatTopic';
export const STATE_MACHINE_ID = 'StateMachineId';
export const SEARCH_LOCATION = 'SearchLocation';
export const CLIENT_CORRELATION_ID = 'ClientCorrelationId';
export const PREV_STATE_MACHINE_ID = 'PrevStateMachineId';
export const CALCULATED_STATE_MACHINE_ID = 'CalculatedStateMachineId';
export const IS_ASSISTANT_EXIT_BUTTON = 'isAssistantExitButtonEnabled';

export const ACCEPTED_LANGUAGE = [
  'tr',
  'de',
  'en',
  'ar',
  'az',
  'bg',
  'cs',
  'el',
  'hu',
  'pl',
  'ro',
  'sk',
];
export const DEFAULT_LANGUAGE = 'tr';
export const DEFAULT_STOREFRONTID = '1';
export const IS_INTERNATIONAL = 'isInternational';
export const INTERNATIONAL_CUSTOMER_INFO = 'internationalCustomerInfo';
export const INTERNATIONAL_MAIL_PAGE_OPENED = 'internationalMailPageOpened';

export const DEFAULT_CHANNEL_ID = '974feb0a-aaeb-11ea-bb37-0242ac130002';
export const SELLER_CHANNEL_ID = 'e4b2b2e2-aaeb-11ea-bb37-0242ac130002';
export const COURIER_CHANNEL_ID = '9beeb3cc-06fc-11eb-adc1-0242ac120002';
export const INT_CUSTOMER_CHANNEL_ID = 'd0345f40-59b9-11ec-bf63-0242ac130002';
export const DOLAP_CUSTOMER_CHANNEL_ID = 'b3c13d9a-7e91-11ec-90d6-0242ac120003';
export const INT_SELLER_CHANNEL_ID = '17b4bbaa-c614-11ec-9d64-0242ac120002';
export const DODO_CHANNEL_ID = 'c1008c7f-cbdb-44d0-8ff7-0242ac120003';
export const CLASSIFIED_SELLER_CHANNEL_ID =
  'a05ac342-0e3e-11ed-861d-0242ac120002';

export const CUSTOMER_SEGMENT_ID = '5ee739b4-aaee-11ea-bb37-0242ac130002';
export const SELLER_SEGMENT_ID = '77e1effe-aaee-11ea-bb37-0242ac130002';
export const INFLUENCER_SEGMENT_ID = '6e99dcae-aaee-11ea-bb37-0242ac130002';
export const INFLUENCER_SEGMENT_NAME = 'INFLUENCER';
export const COURIER_SEGMENT_ID = 'b4cd58e4-06fc-11eb-adc1-0242ac120002';
export const INT_CUSTOMER_SEGMENT_ID = 'e51b7ad8-59b9-11ec-bf63-0242ac130002';
export const INT_SELLER_SEGMENT_ID = '34a0763c-c614-11ec-9d64-0242ac120002';
export const DODO_SEGMENT_ID = '622dc024-b38e-43ce-b83c-0242ac120003';
export const CLASSIFIED_SELLER_SEGMENT_ID =
  'b6acc05a-0e3e-11ed-861d-0242ac120002';

export const CURRENT_PAGE = 'currentPage';
export const ORDER_CHANNEL_ID = 'orderChannelId';

export const MEAL_ORDER_CHANNEL_ID = 4;
export const INSTANT_ORDER_CHANNEL_ID = 3;
export const CUSTOMER_ORDER_CHANNEL_ID = 1;

export const START_REQUEST = 'startRequest';
export const START_REQUEST_FOR_EVENT_CHANGE = 'startRequestForEventChange';

export const SELLER_SINGLE_USE_TOKEN = 'singleUseToken';

export const SELLER_STICKY_ASSISTANT = 'isStickyAssistant';

export const SELLER_GROCERY_PICKER_APP = 'isSellerGroceryPickerApp';
export const RETURN_PAGE = 'returnPage';
export const APP_NAME = 'appName';
export const LC_MEMBER = 'lcMember';

export const CUSTOM_FIELDS = 'customFields';
export const NOTIFICATIONS = 'notifications';

export const CHANNEL_SEGMENT_MAP = new Map([
  [DEFAULT_CHANNEL_ID, CUSTOMER_SEGMENT_ID],
  [SELLER_CHANNEL_ID, SELLER_SEGMENT_ID],
  [COURIER_CHANNEL_ID, COURIER_SEGMENT_ID],
  [INT_CUSTOMER_CHANNEL_ID, INT_CUSTOMER_SEGMENT_ID],
  [INT_SELLER_CHANNEL_ID, INT_SELLER_SEGMENT_ID],
  [DODO_CHANNEL_ID, DODO_SEGMENT_ID],
  [CLASSIFIED_SELLER_CHANNEL_ID, CLASSIFIED_SELLER_SEGMENT_ID],
]);

export const TRENDYOL_CUSTOMMER_APP_CHANNELS = [
  DEFAULT_CHANNEL_ID,
  INT_CUSTOMER_CHANNEL_ID,
];
