import React from 'react';
import './InputLabel.scss';

const InputLabel = ({ text = '', required = false, children }) => (
  <div className="assistant-label">
    <span className="label-text">{text}</span>
    {required && text?.length > 0 && (
      <span className="assistant-mandatory">*</span>
    )}
    {children}
  </div>
);

export default InputLabel;
