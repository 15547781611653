import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import './CustomAlert.scss';
import { useTranslation } from 'react-i18next';
import { closeOnErrorButton, reconnectOnErrorButton } from '../../../i18n/keys';
import classNames from 'classnames';
import appService from '../../../services/app.service';
import usePersistentState from '../../common/usePersistentState';

const CustomAlert = ({
  id,
  isConnecting,
  isError,
  alertMessage,
  closeLiveChat,
  reload,
  isOutOfWorkHours,
  isUserBlocked,
}) => {
  const { t } = useTranslation();

  const [seconds, setSeconds] = usePersistentState(
    id,
    'seconds',
    appService.getReConnectWaitingSeconds()
  );

  const reloadAction = async () => {
    const waitingTime = await appService.getReConnectWaitingSeconds();
    setSeconds(waitingTime);
    reload();
  };

  useEffect(() => {
    let interval = null;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconds]);

  function showReconnectButton() {
    return (
      !isOutOfWorkHours &&
      isOutOfWorkHours === false &&
      !isUserBlocked &&
      isUserBlocked === false
    );
  }

  return (
    <div
      data-testid="custom-alert"
      className={classNames({
        'custom-alert': true,
        'custom-alert--is-connecting': isConnecting,
      })}>
      <div className="custom-alert__icon-wrapper">
        {isConnecting ? (
          <div className="custom-alert__icon-wrapper__icon--roll" />
        ) : (
          <div
            className={
              appService.isDynamicStylingChannel()
                ? 'alert-wrapper-dynamic'
                : 'alert-wrapper'
            }>
            <img
              className="custom-alert__icon-wrapper__icon"
              src={appService.getDynamicStyleValue('sad-icon-link')}
              alt="alert"
            />
          </div>
        )}
      </div>
      <div className="custom-alert__message">{alertMessage}</div>
      {isError && (
        <div className="custom-alert__actions">
          <div
            className="custom-alert__actions__close"
            onClick={() => closeLiveChat()}>
            {t(closeOnErrorButton)}
          </div>
          {showReconnectButton() && (
            <div
              className={classNames([
                'custom-alert__actions__reconnect',
                { disabled: seconds > 0 },
                {
                  dynamic: appService.isDynamicStylingChannel(),
                },
              ])}
              onClick={() => reloadAction()}>
              {t(reconnectOnErrorButton)}{' '}
              {seconds !== 0 ? '(' + seconds + ')' : ''}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

CustomAlert.propTypes = {
  alertMessage: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  isConnecting: PropTypes.bool.isRequired,
  closeLiveChat: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  isOutOfWorkHours: PropTypes.bool,
  isUserBlocked: PropTypes.bool,
};

export default CustomAlert;
