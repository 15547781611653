import React, { useReducer } from 'react';
import CommonReducer from './commonReducer';
import CommonContext from './commonContext';
import * as PropTypes from 'prop-types';
import {
  CLEAR_ERRORS,
  CLEAR_NEXT_STATE_TIMEOUT,
  CLEAR_SUCCESSES,
  IS_APP_INITIALIZED,
  IS_LIVE_CHAT_INITIALIZED,
  IS_SEND_SURVEY_SUCCESS,
  SET_ERRORS,
  SET_SUCCESSES,
} from './commonTypes';

const CommonState = (props) => {
  const initialState = {
    isAppInitialized: false,
    isLiveChatInitialized: false,
    isSendSurveySuccess: false,
    errors: {
      title: null,
      messages: [],
    },
    successes: {
      title: null,
      messages: [],
    },
    nextStateDelayTimeoutId: null,
  };
  const [state, dispatch] = useReducer(CommonReducer, initialState);

  const setIsAppInitialized = (isInitialized) => {
    dispatch({ type: IS_APP_INITIALIZED, payload: isInitialized });
  };

  const setNextStateDelayTimeoutId = (timeoutId) => {
    dispatch({ type: CLEAR_NEXT_STATE_TIMEOUT, payload: timeoutId });
  };

  const setErrors = (errors) => {
    dispatch({ type: SET_ERRORS, payload: errors });
  };

  const clearErrors = () => {
    dispatch({ type: CLEAR_ERRORS });
  };

  const setSuccesses = (successes) => {
    dispatch({ type: SET_SUCCESSES, payload: successes });
  };

  const setIsSendSurveySuccess = (isSuccess) => {
    dispatch({ type: IS_SEND_SURVEY_SUCCESS, payload: isSuccess });
  };

  const clearSuccesses = () => {
    dispatch({ type: CLEAR_SUCCESSES });
  };

  const setIsLiveChatInitialized = (isInitialized) => {
    dispatch({ type: IS_LIVE_CHAT_INITIALIZED, payload: isInitialized });
  };

  return (
    <CommonContext.Provider
      value={{
        isAppInitialized: state.isAppInitialized,
        isLiveChatInitialized: state.isLiveChatInitialized,
        errors: state.errors,
        successes: state.successes,
        isSendSurveySuccess: state.isSendSurveySuccess,
        nextStateDelayTimeoutId: state.nextStateDelayTimeoutId,
        setIsAppInitialized,
        setIsLiveChatInitialized,
        setErrors,
        clearErrors,
        setSuccesses,
        clearSuccesses,
        setIsSendSurveySuccess,
        setNextStateDelayTimeoutId,
      }}>
      {props.children}
    </CommonContext.Provider>
  );
};

CommonState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CommonState;
