import React, { useContext, useEffect } from 'react';
import './Welcoming.scss';
import Campaign from './campaign/Campaign';
import Announcement from './announcment/Announcement';
import classNames from 'classnames';
import Ticket from './ticket/Ticket';
import ChatBotService from '../../../services/chatbot.service';
import CommonContext from '../../../context/common/commonContext';
import OrderQuestion from './orderQuestion/OrderQuestion';
import usePersistentState from '../../common/usePersistentState';
import appService from '../../../services/app.service';

const Welcoming = ({
  id,
  componentData,
  select,
  isRenderedFromOutside = false,
}) => {
  const { nextStateEvent, nextStateCallDelaySeconds, tabs } = componentData;
  const [activeTab, setActiveTab] = usePersistentState(id, 'activeTab', null);
  const commonContext = useContext(CommonContext);
  const { setNextStateDelayTimeoutId } = commonContext;

  useEffect(() => {
    if (nextStateCallDelaySeconds) {
      const payload = {
        event: nextStateEvent,
      };

      const nextStateDelay = nextStateCallDelaySeconds * 1000;
      let timeoutId = setTimeout(() => {
        if (!isRenderedFromOutside) {
          select(payload);
        }
      }, nextStateDelay);
      setNextStateDelayTimeoutId(timeoutId);
    }
    if (tabs && !activeTab) {
      setActiveTab(tabs[0]);
      sendEventData(tabs[0]);
    }
  }, []);

  const renderWelcomingBody = (tab) => {
    setActiveTab(tab);
    sendEventData(tab);
  };

  const sendEventData = (tab) => {
    const executeEventRequest = {
      event: tab.event,
      data: {
        eventLabel: tab.type,
        eventAction: 'TabSelect',
      },
    };

    ChatBotService.execute(executeEventRequest);
  };

  function getInPageAnnouncements(activeTab) {
    return activeTab.data.items.filter(
      (item) =>
        !item.displayTargets ||
        item.displayTargets.length === 0 ||
        item.displayTargets.some((displayTarget) => displayTarget === 'IN_PAGE')
    );
  }

  return (
    <div
      className={classNames([
        'welcoming',
        {
          dynamic: appService.isDynamicStylingChannel(),
        },
      ])}>
      {tabs?.length > 1 && (
        <div className="menu-tabs">
          {tabs.map((tab, i) => (
            <div
              key={i}
              className={classNames([
                'tab',
                { active: activeTab && tab.type === activeTab.type },
              ])}
              onClick={() => renderWelcomingBody(tab)}>
              {tab.title}
            </div>
          ))}
        </div>
      )}
      <div className="body">
        {activeTab &&
          (activeTab.type === 'Announcement' ? (
            <Announcement
              items={getInPageAnnouncements(activeTab)}
              event={activeTab.event}
            />
          ) : activeTab.type === 'Campaign' ? (
            <Campaign data={activeTab.data} />
          ) : activeTab.type === 'Ticket' ? (
            <Ticket data={activeTab.data} />
          ) : activeTab.type === 'OrderQuestion' ? (
            <OrderQuestion data={activeTab.data} />
          ) : (
            ''
          ))}
      </div>
    </div>
  );
};

export default Welcoming;
