import SubjectButton from './subjectButton/SubjectButton';
import StaticText from './staticText/StaticText';
import Order from './order/Order';
import ClientMessage from './clientMessage/ClientMessage';
import DeepLink from './deepLink/DeepLink';
import CancelSimulation from './cancelOrder/cancelSimulation';
import CancelPreviewItem from './cancelSimulation/cancelPreview/CancelSimulationPreviewItem';
import ClaimSimulation from './claim/claimOrder/claimSimulation';
import ClaimSimulationPreviewItem from './claim/claimPreview/ClaimSimulationPreviewItem';
import OperationButton from './operationButton/OperationButton';
import OrderProblemsRequest from './ticket/product/OrderProblemsRequest';
import ClaimComplaint from './complaint/claimComplaint/ClaimComplaint';
import ClaimCodes from './claim/claimCodes/ClaimCodes';
import ClaimStatusDetail from './claim/claimStatusDetail/claimStatusDetail';
import Address from './address/Address';
import ConfirmOtpAddress from './address/ConfirmOtpAddress';
import NewAddress from './address/newAddress/NewAddress';
import ActionButton from './actionButton';
import WrongDelivered from './ticket/wrongDelivered/WrongDelivered';
import ClaimCargo from './claim/claimCargo';
import CallButton from './callButton';
import Welcoming from './welcoming';
import Timeline from './timeline/Timeline';
import Attachment from './attachment/Attachment';
import Contacts from './seller/contacts/Contacts';
import Contact from './seller/contact/Contact';
import Combobox from './combobox/Combobox';
import NestedDeepLink from './nestedDeepLink/NestedDeepLink';
import SellerBarcodeInput from './sellerBarcodeInput/SellerBarcodeInput';
import GoDeliveredProductsTicket from './ticket/goDeliveredProductsTicket/GoDeliveredProductsTicket';
import Form from './form/Form';
import SelectableItems from './selectableProduct/SelectableItems';
import NewAddressTicket from './address/newAddressForTicket/NewAddressTicket';
import UnlockProductInfo from './unlockProductInfo';
import ExpandableText from './expandableText/ExpandableText';
import Video from './video/Video';
import FormattedInput from './formattedInput/FormattedInput';
import GoClaimStatusDetail from './claim/goClaimStatusDetail/GoClaimStatusDetail';
import DaySelection from './daySelection/DaySelection';
import ItemDetailList from './itemDetailList/ItemDetailList';
import FileUpload from './fileUpload/FileUpload';
import ButtonWithParams from './buttonWithParams/ButtonWithParams';
import MultipleItemForm from './multipleItemForm/MultipleItemForm';
import TicketList from './ticketListing/TicketList';
import ProductList from './productListing/ProductList';
import TicketListing from './listing/ticket/TicketListing';
import Packages from './packages/Packages';
import ClaimClusterPreviewItem from './claim/claimClusterPreviewItem';

class ComponentFactory {
  static componentList = null;
  static initList() {
    this.componentList = {};
    this.componentList.Subject = SubjectButton;
    this.componentList.StaticText = StaticText;
    this.componentList.Shipments = Order;
    this.componentList.ClientMessage = ClientMessage;
    this.componentList.DeepLink = DeepLink;
    this.componentList.CancellableItem = CancelSimulation;
    this.componentList.CancelPreviewItem = CancelPreviewItem;
    this.componentList.Button = OperationButton;
    this.componentList.ClaimableItem = ClaimSimulation;
    this.componentList.ClaimPreviewItem = ClaimSimulationPreviewItem;
    this.componentList.OrderProblems = OrderProblemsRequest;
    this.componentList.Combobox = Combobox;
    this.componentList.ClaimComplaint = ClaimComplaint;
    this.componentList.Claims = ClaimCodes;
    this.componentList.ClaimDetail = ClaimStatusDetail;
    this.componentList.GoClaimDetail = GoClaimStatusDetail;
    this.componentList.UserAddresses = Address;
    this.componentList.OTP = ConfirmOtpAddress;
    this.componentList.AddAddress = NewAddress;
    this.componentList.ActionButton = ActionButton;
    this.componentList.WrongDelivered = WrongDelivered;
    this.componentList.Cargo = ClaimCargo;
    this.componentList.Phone = CallButton;
    this.componentList.Welcoming = Welcoming;
    this.componentList.ShipmentInfoStatus = Timeline;
    this.componentList.Attachment = Attachment;
    this.componentList.SellerContacts = Contacts;
    this.componentList.SellerContact = Contact;
    this.componentList.NestedDeepLink = NestedDeepLink;
    this.componentList.SellerBarcodeInput = SellerBarcodeInput;
    this.componentList.GoDeliveredProductsTicket = GoDeliveredProductsTicket;
    this.componentList.Form = Form;
    this.componentList.MultipleItemForm = MultipleItemForm;
    this.componentList.SelectableItems = SelectableItems;
    this.componentList.NewAddressTicket = NewAddressTicket;
    this.componentList.UnlockProductInfo = UnlockProductInfo;
    this.componentList.ExpandableText = ExpandableText;
    this.componentList.Video = Video;
    this.componentList.FormattedInput = FormattedInput;
    this.componentList.DaySelection = DaySelection;
    this.componentList.ItemDetailList = ItemDetailList;
    this.componentList.FileUpload = FileUpload;
    this.componentList.ButtonWithParams = ButtonWithParams;
    this.componentList.Tickets = TicketList;
    this.componentList.Products = ProductList;
    this.componentList.TicketListing = TicketListing;
    this.componentList.Packages = Packages;
    this.componentList.ClaimClusterPreviewItem = ClaimClusterPreviewItem;
  }

  static getComponentList() {
    if (!this.componentList) {
      this.initList();
    }
    return this.componentList;
  }

  static get(componentName) {
    let componentList = this.getComponentList();
    return componentList[componentName];
  }
}

export default ComponentFactory;
