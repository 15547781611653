import React from 'react';
import './ClaimInfo.scss';
import ClaimInfoIcon from '../../../assets/icons/claim-info.svg';

const ClaimInfo = ({ text = '' }) => (
  <div className="claim-info">
    <img className="claim-icon" src={ClaimInfoIcon} alt="claim" />
    <span>{text}</span>
  </div>
);

export default ClaimInfo;
