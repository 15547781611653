import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import appService from '../../services/app.service';
import * as queryString from 'query-string';
import CommonContext from '../../context/common/commonContext';
import ChatBotContext from '../../context/chatbot/chatbotContext';
import LiveChatContext from '../../context/liveChat/liveChatContext';
import utils from '../../utils';
import Utils from '../../utils';
import { pages, PLATFORM_ANDROID, PLATFORM_MWEB } from '../../constants';
import {
  CHANNEL_SEGMENT_MAP,
  DEFAULT_CHANNEL_ID,
  INT_CUSTOMER_CHANNEL_ID,
  INT_SELLER_CHANNEL_ID,
  SELLER_CHANNEL_ID,
  TRENDYOL_CUSTOMMER_APP_CHANNELS,
} from '../../constants/sessionStorageConstants';
import StickySurvey from '../pages/stickySurvey/StickySurvey';
import CustomerInfo from '../pages/liveChat/international/CustomerInfo';
import InternationalSendEmail from '../pages/liveChat/international/InternationalSendEmail';
import { useTranslation } from 'react-i18next';
import {
  LANGUAGE_CODE_TR,
  SELLER_GROCERY_PICKER_APP_NAME,
  TRUE,
} from '../../constants/chatAssistantConstant';
import EchoLiveChat from '../echoLiveChat/EchoLiveChat';
import { STYLING_MAP } from '../../constants/assistantStylingConstants';
import RatingSurvey from '../pages/ratingSurvey/RatingSurvey';
import ComponentPreviewRoutes from './PreviewRoutes';
import * as Sentry from '@sentry/browser';
import jwt from 'jsonwebtoken';

const ErrorPage = lazy(() => import('../pages/error/ErrorPage'));
const Survey = lazy(() => import('../pages/survey/Survey'));
const ChatAssistant = lazy(() =>
  import('../pages/chatAssistant/ChatAssistant')
);

const RouteDecider = ({ history, location, ...rest }) => {
  const { i18n } = useTranslation();

  const commonContext = useContext(CommonContext);
  const chatBotContext = useContext(ChatBotContext);
  const liveChatContext = useContext(LiveChatContext);
  const { isEndChat, isSurveyOpened } = liveChatContext;
  const { setIsAppInitialized, nextStateDelayTimeoutId } = commonContext;
  const { isChatBotSurvey, setIsChatBotSurvey, clearChatHistory } =
    chatBotContext;
  const [isInitialized, setIsInitialized] = useState(null);
  const [backOrSwipeEventTrigger, setBackOrSwipeEventTrigger] = useState(false);
  let isLiveChatDeepLinkClicked = appService.getIsLiveChatDeepLinkClicked();

  const handleAndroidBackButton = () => {
    let isAssistantExitButtonEnabled =
      appService.getIsAssistantExitButtonEnabled();
    if (isAssistantExitButtonEnabled === TRUE) {
      handleBackOperations();
      setBackOrSwipeEventTrigger(true);
    }
  };

  const handleRightSwipeAction = () => {
    let isAssistantExitButtonEnabled =
      appService.getIsAssistantExitButtonEnabled();
    if (isAssistantExitButtonEnabled === TRUE) {
      handleBackOperations();
      setBackOrSwipeEventTrigger(true);
    }
  };

  useEffect(() => {
    if (location.search) {
      appService.setSearchLocation(location.search);
    }
  }, [location]);

  useEffect(() => {
    window.clearTimeout(nextStateDelayTimeoutId);
  }, [backOrSwipeEventTrigger]);

  useEffect(() => {
    document.addEventListener(
      'handleAndroidBackButton',
      handleAndroidBackButton
    );
    return () => {
      document.removeEventListener(
        'handleAndroidBackButton',
        handleAndroidBackButton
      );
    };
  }, [isEndChat]);

  useEffect(() => {
    document.addEventListener('handleRightSwipeAction', handleRightSwipeAction);
    return () => {
      document.removeEventListener(
        'handleRightSwipeAction',
        handleRightSwipeAction
      );
    };
  }, [isEndChat]);

  // setting dynamic stylings
  useEffect(() => {
    const { style: bodyStyles } = document.body;

    let styling;
    if (STYLING_MAP.get(appService.getAppName())) {
      styling = STYLING_MAP.get(appService.getAppName());
    } else {
      styling =
        STYLING_MAP.get(appService.getChannelId()) ||
        STYLING_MAP.get(DEFAULT_CHANNEL_ID);
    }

    bodyStyles.setProperty('--dynamic-channel-color', styling['color']);
    bodyStyles.setProperty(
      '--dynamic-channel-text-color',
      styling['text-color']
    );
    bodyStyles.setProperty(
      '--dynamic-channel-chat-icon-width',
      styling['icon-width']
    );
    bodyStyles.setProperty(
      '--dynamic-channel-chat-icon-height',
      styling['icon-height']
    );
    bodyStyles.setProperty(
      '--dynamic-channel-chat-icon-background-image',
      styling['icon-background-image']
    );

    bodyStyles.setProperty(
      '--client-message-bg-color',
      styling['client-message-bg-color']
    );

    bodyStyles.setProperty(
      '--client-message-text-color',
      styling['client-message-text-color']
    );

    bodyStyles.setProperty(
      '--upload-content-bg-color',
      styling['upload-content-bg-color']
    );

    bodyStyles.setProperty(
      '--upload-content-more-item-color',
      styling['upload-content-more-item-color']
    );

    bodyStyles.setProperty(
      '--dynamic-channel-order-delivered-color',
      styling['order-delivered-color']
    );

    bodyStyles.setProperty('--alert-color', styling['alert-color']);
    bodyStyles.setProperty('--primary-color', styling['color']);
    bodyStyles.setProperty('--hover-color', styling['hover-color']);
    bodyStyles.setProperty('--alert-info-color', styling['alert-info-color']);
    bodyStyles.setProperty(
      '--alert-info-bg-color',
      styling['alert-info-bg-color']
    );
  }, [isInitialized]);

  const handleBackOperations = () => {
    let currentPageName = appService.getCurrentPage();
    switch (currentPageName) {
      case pages.CHATBOT:
        return checkChatBotSurvey();
      case pages.CHATBOT_SURVEY:
        return appService.exitAssistant();
      case pages.LIVECHAT:
        // echo live chat page handles gesture events inside
        return true;
      case pages.LIVECHAT_SURVEY:
        return appService.exitAssistant();
      default:
        return true;
    }
  };

  const checkChatBotSurvey = async () => {
    const checkSurvey = await appService.isChatBotSurveyWillOpen();
    if (checkSurvey.openSurvey) {
      await appService.setCurrentPage(pages.CHATBOT_SURVEY);
      setIsChatBotSurvey(true);
    } else {
      await appService.exitAssistant();
    }
  };

  function isAnyParameterChanged(qString) {
    return (
      (qString.eventName && qString.eventName !== appService.getEventName()) ||
      (qString.shipmentNumber &&
        qString.shipmentNumber !== appService.getShipmentNumber()) ||
      (qString.genericEventName &&
        qString.genericEventName !== appService.getGenericEventName())
    );
  }

  function isTrendyolCustomerApp(qString) {
    if (qString.channelId === undefined) {
      //trendyol customer channel
      return true;
    }

    if (TRENDYOL_CUSTOMMER_APP_CHANNELS.includes(qString.channelId)) {
      return true;
    }
  }

  function configureAssistantForDefaultLanguage() {
    clearChatHistory();
    appService.setPrevStateMachineId(null);
    initializeLanguage(LANGUAGE_CODE_TR);
  }

  function isAssistantInMultiLanguageMode(qString) {
    return (
      qString.ChannelId === DEFAULT_CHANNEL_ID &&
      qString.isStickyInitialized === 'true' &&
      qString.inApp === 'true' &&
      appService.getPrevStateMachineId() &&
      !Utils.isLanguageTr()
    );
  }

  function changeLanguageByCountryCode(qString) {
    if (qString.CountryCode) {
      const languageConfig = appService.getLanguageByCountryCode(
        qString.CountryCode
      );
      if (languageConfig.isExists) {
        initializeLanguage(languageConfig.language);
      }
    }
  }

  function changeLanguageByBlockedLanguageConfig(sourceLanguge) {
    const languageConfig =
      appService.getLanguageByBlockedLanguage(sourceLanguge);
    if (languageConfig.isExists) {
      initializeLanguage(languageConfig.targetLanguage);
    }
  }

  useEffect(() => {
    const htmlElement = document.documentElement;
    if (i18n.language === 'ar') {
      htmlElement.setAttribute('dir', 'rtl');
      document.body.classList.remove('ltr');
      document.body.classList.add('rtl');

      if (document.body.classList.contains('theme-desktop')) {
        document.body.classList.remove('theme-desktop');
        document.body.classList.add('theme-desktop-rtl');
      }
    } else {
      htmlElement.setAttribute('dir', 'ltr');
      document.body.classList.remove('rtl');
      document.body.classList.add('ltr');

      if (document.body.classList.contains('theme-desktop-rtl')) {
        document.body.classList.remove('theme-desktop-rtl');
        document.body.classList.add('theme-desktop');
      }
    }
  }, [i18n.language]);

  useEffect(() => {
    const asyncFunction = async () => {
      await appService.setCurrentPage(pages.CHATBOT);
      const qString = queryString.parse(location.search);

      await appService
        .liveChatConfig()
        .then((response) => appService.setLiveChatConfig(response));

      // for sticky assistant
      if (
        appService.getChannelIdWithoutDefault() &&
        appService.getChannelIdWithoutDefault() !== qString.ChannelId
      ) {
        if (
          qString.isStickyInitialized === 'true' &&
          qString.inApp === 'true'
        ) {
          appService.closeStickyPopup();
        } else {
          sessionStorage.clear();
        }
      }

      initializeLanguage(qString.lang);

      // configure language with countryCode
      changeLanguageByCountryCode(qString);

      // configure language with blockedLanguage
      changeLanguageByBlockedLanguageConfig(appService.getLanguage());

      // for sticky assistant and multi-language mode
      if (isAssistantInMultiLanguageMode(qString)) {
        configureAssistantForDefaultLanguage();
      }

      if (appService.getIsStickyAssistant() && isAnyParameterChanged(qString)) {
        appService.setIsEventChanged(true);
      }

      if (
        (qString.isStickyInitialized === 'false' &&
          qString.platform === PLATFORM_MWEB) ||
        (qString.platform === PLATFORM_ANDROID &&
          isTrendyolCustomerApp(qString))
      ) {
        history.push('survey');
      }

      if (qString.LcMember) appService.setLcMember(qString.LcMember);
      if (qString.lcMember) appService.setLcMember(qString.lcMember);

      if (qString.CountryCode) {
        appService.setCountryCode(decodeURIComponent(qString.CountryCode));
      }

      if (qString.isLiveChat === 'true') {
        appService.setChannelId(decodeURIComponent(qString.ChannelId));
        appService.setSegmentId(
          CHANNEL_SEGMENT_MAP.get(
            qString.ChannelId ? qString.ChannelId : DEFAULT_CHANNEL_ID
          )
        );

        if (qString.appName) {
          appService.setAppName(qString.appName);
        }

        await appService
          .assistantConfig()
          .then((response) => {
            appService.setAssistantId(response.assistantId);
            appService.setOrderChannelId(response.orderChannelId);
          })
          .catch(() => {});

        history.push('live-chat');
        appService.setIsLiveChatDeepLinkClicked(true);
        await appService.setCurrentPage(pages.LIVECHAT);
      }

      if (appService.isSingleTokenRequest(qString)) {
        appService.setSellerSingleUseToken(qString.singleUseToken);
      }

      if (qString.inApp === 'true') {
        appService.setIsStickyAssistant(true);
      }

      if (qString.appName) {
        appService.setAppName(qString.appName);
      }

      if (qString.appName === SELLER_GROCERY_PICKER_APP_NAME) {
        appService.setIsSellerGroceryPickerApp(true);
      }

      if (qString.isGuestUser) {
        appService.setIsGuestUser(decodeURIComponent(qString.isGuestUser));
      }

      if (qString.ReturnPage) {
        appService.setReturnPage(qString.ReturnPage);
      }

      if (qString.ChannelId === INT_CUSTOMER_CHANNEL_ID) {
        qString.storefrontId &&
          appService.setStorefrontId(decodeURIComponent(qString.storefrontId));

        appService.setInternational(true);

        qString.ChannelId &&
          appService.setChannelId(decodeURIComponent(qString.ChannelId));
      }

      if (qString.ChannelId === INT_SELLER_CHANNEL_ID) {
        qString.ChannelId &&
          appService.setChannelId(decodeURIComponent(qString.ChannelId));
      }

      if (qString.ChannelId === SELLER_CHANNEL_ID) {
        qString.subChannel &&
          appService.setSubChannel(decodeURIComponent(qString.subChannel));
      }

      if (qString && qString.platform) {
        await initializeAssistantWithPlatform(qString);
      }
      // eslint-disable-next-line
    };
    asyncFunction();
    // eslint-disable-next-line
  }, []);

  const initializeLanguage = (lang) => {
    if (lang) {
      const language = decodeURIComponent(lang);
      const acceptedLanguage = Utils.getAcceptepLanguage(language);
      appService.setLanguage(acceptedLanguage);
      i18n.changeLanguage(acceptedLanguage);
    }
  };

  useEffect(() => {
    return history.listen((location) => {
      if (
        location.pathname === '/' &&
        appService.getPlatform() === PLATFORM_MWEB
      ) {
        checkChatBotSurvey();
      }
    });
  }, []);

  function setTokenAndStartRequestForStickyAndInitAssistant(qString) {
    let orderChannelId = '';
    if (qString.orderChannelId) {
      orderChannelId = decodeURIComponent(qString.orderChannelId);
    }

    if (appService.isGuestUser()) {
      setIsAppInitialized(true);
      setIsInitialized(true);
      return;
    }

    if (appService.getSellerSingleUseToken()) {
      appService
        .processSingleUseToken(qString.singleUseToken)
        .then((response) => {
          appService.setVerificationTokensToCookie(response);
          setIsAppInitialized(true);
          setIsInitialized(true);
        })
        .catch(() => {
          setIsAppInitialized(false);
          setIsInitialized(false);
        })
        .finally(() => {
          utils.removeQueryStringsFromAddressBar();
        });
    } else if (
      appService.getIsStickyAssistant() &&
      appService.getChannelId() === DEFAULT_CHANNEL_ID
    ) {
      appService
        .setTokenAndStartRequestForCustomerSticky()
        .then(() => {
          setIsAppInitialized(true);
          setIsInitialized(true);
        })
        .catch(() => {
          setIsAppInitialized(false);
          setIsInitialized(false);
        })
        .finally(() => {
          utils.removeQueryStringsFromAddressBar();
        });
    } else {
      appService
        .setAsyncToken(
          utils.getTokenFromCookie() || decodeURIComponent(qString.token)
        )
        .then(() => {
          setIsAppInitialized(true);
          setIsInitialized(true);
          utils.removeQueryStringsFromAddressBar();
        });
    }
  }

  const initializeAssistantWithPlatform = async (qString) => {
    qString.ChannelId
      ? appService.setChannelId(decodeURIComponent(qString.ChannelId))
      : appService.removeChannelId();

    appService.setChannelIdWithoutDefault(qString.ChannelId);

    appService.setPlatform(decodeURIComponent(qString.platform));
    appService.setPid(decodeURIComponent(qString.pid));
    appService.setSid(decodeURIComponent(qString.sid));
    appService.setBuildName(decodeURIComponent(qString.buildName));
    appService.setAppVersion(decodeURIComponent(qString.Version));

    qString.shipmentNumber &&
      appService.setShipmentNumber(decodeURIComponent(qString.shipmentNumber));

    qString.orderId &&
      appService.setOrderId(decodeURIComponent(qString.orderId));

    qString.orderNumber &&
      appService.setOrderNumber(decodeURIComponent(qString.orderNumber));

    qString.eventName &&
      appService.setEventName(decodeURIComponent(qString.eventName));

    qString.genericEventName &&
      appService.setGenericEventName(
        decodeURIComponent(qString.genericEventName)
      );

    qString.chatSource &&
      appService.setMenuType(decodeURIComponent(qString.chatSource));

    qString.orderChannelId &&
      appService.setOrderChannelId(decodeURIComponent(qString.orderChannelId));

    qString.actionId &&
      appService.setStartRequest(
        JSON.stringify({
          actionId: decodeURIComponent(qString.actionId),
        })
      );

    if (
      appService.getIsStickyAssistant() !== true &&
      utils.getStartRequestFromCookie()
    ) {
      const startRequestFromCookie = utils.getStartRequestFromCookie();
      appService.setStartRequest(startRequestFromCookie);
    }

    setTokenAndStartRequestForStickyAndInitAssistant(qString);
  };

  if (isInitialized === true) {
    return (
      <Suspense fallback={<div />}>
        <Route
          {...rest}
          render={(props) => {
            if (props.location.pathname.includes('preview')) {
              return <ComponentPreviewRoutes {...props} />;
            }
            if (isChatBotSurvey) {
              if (appService.checkChatBotSurveyV2Enabled()) {
                return <RatingSurvey />;
              } else {
                return appService.getIsStickyAssistant() ||
                  appService.isInternational() ? (
                  <StickySurvey {...props} />
                ) : (
                  <Survey {...props} />
                );
              }
            } else if (appService.isInternationalSendEmailOpened()) {
              return (
                <div className="international-mail-sending-page">
                  <InternationalSendEmail />
                </div>
              );
            } else if (isLiveChatDeepLinkClicked && !isSurveyOpened) {
              try {
                if (appService.isInternational()) {
                  return (
                    <div className="international-customer-info-page">
                      <CustomerInfo />
                    </div>
                  );
                }
                return <EchoLiveChat {...props} />;
              } catch (e) {
                return (
                  <ErrorPage error={e} location={location} history={history} />
                );
              }
            } else if (isSurveyOpened) {
              return appService.isLiveChatV2Enabled() ? (
                <RatingSurvey />
              ) : appService.getIsStickyAssistant() ||
                appService.isInternational() ? (
                <StickySurvey {...props} />
              ) : (
                <Survey {...props} />
              );
            } else {
              return <ChatAssistant {...props} />;
            }
          }}
        />
      </Suspense>
    );
  } else if (isInitialized === null) {
    return null;
  } else if (isInitialized === false) {
    return <ErrorPage />;
  }
};
RouteDecider.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(RouteDecider);
