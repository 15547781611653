import './Stars.scss';

import React, { useState, useEffect, useMemo } from 'react';
import StartSelectedIcon from '../../../assets/icons/star-selected.svg';
import StartUnSelectedIcon from '../../../assets/icons/star-unselected.svg';
import usePersistentState from '../../common/usePersistentState';
import { COMPONENT_TYPES } from '../ratingSurveyArea/RatingSurveyArea';
import circleInfo from '../../../assets/icons/circle-info.svg';
import useValidation from '../hooks/useValidation';
import classNames from 'classnames';
import classnames from 'classnames';

const Stars = ({
  id,
  formItem: {
    id: formItemId,
    label,
    defaultChipsLabel,
    optionItems,
    rules,
    ratingComponentType,
  },
  value,
  onChange,
  touched,
  onTouch,
  validationRef,
}) => {
  const [selectedChips, setSelectedChips] = useState([]);
  const [rating, setRating] = usePersistentState(id, 'rating', 0);
  const [lastRating, setLastRating] = usePersistentState(id, 'lastRating', 0);
  const [isRateClicked, setIsRateClicked] = usePersistentState(
    id,
    'isRateClicked',
    false
  );

  const {
    validate: validateStar,
    validation: starValidation,
    requiredRule: starRequiredRule,
  } = useValidation({ rules, value: rating, ref: validationRef });

  const getRatingComponentText = useMemo(
    () => optionItems.find(({ value }) => +value === +rating)?.text,
    [optionItems, rating]
  );

  const isChipsRequired = useMemo(
    () =>
      !!optionItems.find(({ value }) => +value === +rating)?.isChipsRequired,
    [optionItems, rating]
  );

  const chipsLabel = useMemo(
    () => optionItems.find(({ value }) => +value === +rating)?.chipsLabel,
    [optionItems, rating]
  );

  const isChipsValid = useMemo(
    () => (isChipsRequired ? selectedChips.length > 0 : true),
    [isChipsRequired, selectedChips.length]
  );

  const getChips = () =>
    optionItems.find(({ value }) => +value === +rating)?.chips;

  const isSelectedChip = (chipValue) =>
    selectedChips.some(({ value }) => value === chipValue);

  const handleClickChip = (value) => {
    let newSelectedChips;
    if (isSelectedChip(value)) {
      newSelectedChips = selectedChips.filter(
        (selectedChip) => selectedChip.value !== value
      );
    } else if (!isMultipleChipSelectable()) {
      newSelectedChips = [{ value, type: COMPONENT_TYPES.CHIPS }];
    } else {
      newSelectedChips = [
        ...selectedChips,
        { value, type: COMPONENT_TYPES.CHIPS },
      ];
    }
    setSelectedChips(newSelectedChips);
    onChange(formItemId, {
      rate: lastRating,
      chips: newSelectedChips,
    });
    onTouch(formItemId, {
      rate: false,
      chips: false,
    });
  };

  const isMultipleChipSelectable = () =>
    optionItems.find((item) => +item.value === +value?.rate)
      ?.isMultipleChipSelectable;

  const isChipInactive = (chipValue) =>
    !isMultipleChipSelectable() &&
    selectedChips.find(({ value }) => value !== chipValue);

  const onRatingClick = () => {
    setIsRateClicked(true);
    setRating(lastRating);
    setSelectedChips([]);
    onChange(formItemId, {
      rate: lastRating,
      chips: [],
    });
    onTouch(formItemId, {
      rate: false,
      chips: false,
    });
  };

  const onMouseLeave = () => setLastRating(!isRateClicked ? 0 : rating);

  const onMouseOver = (number) => setLastRating(number);

  const validate = () => validateStar() && isChipsValid;

  const renderValidationMessage = () => {
    if (!starValidation.isValid && touched?.rate) {
      return (
        <div className="form-validation-message">
          <img src={circleInfo} className="info-icon" alt="info" />
          {starValidation.message}
        </div>
      );
    }

    if (isChipsRequired && !isChipsValid && touched?.chips) {
      return (
        <div className="form-validation-message">
          <img src={circleInfo} className="info-icon" alt="info" />
          {starRequiredRule.message}
        </div>
      );
    }
  };

  const renderRatingComponent = (componentType, number, text) => {
    if ('ICON' === componentType) {
      return (
        <div className={'stars__rate__stars__star'}>
          <div
            className={classnames([
              'stars__rate__stars__star__icon',
              { selected: lastRating === number + 1 },
            ])}>
            {getRatingComponentIcon(number + 1)}
          </div>
          <div
            className={classnames([
              'stars__rate__stars__star__text',
              { selected: lastRating === number + 1 },
            ])}>
            {text}
          </div>
        </div>
      );
    } else {
      return (
        <div className={'stars__rate__stars__star'}>
          <img
            src={
              lastRating >= number + 1 ? StartSelectedIcon : StartUnSelectedIcon
            }
            alt="star"
            className="stars__rate__stars__star__img"
          />
        </div>
      );
    }
  };

  useEffect(() => {
    if (value) {
      setRating(value.rate);
      setLastRating(value.rate);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (validationRef) {
      validationRef.validate = validate;
    }
    // eslint-disable-next-line
  }, [validate]);

  const getRatingComponentIcon = (number) => {
    switch (number) {
      case 1:
        return '😔';
      case 2:
        return '😕';
      case 3:
        return '😐';
      case 4:
        return '☺️';
      case 5:
        return '🤩';
      default:
        return '🤩';
    }
  };

  return (
    <div className="rating-survey-stars">
      <span className="label">
        {label}
        {!!starRequiredRule && <span className="mandatory-field">*</span>}
      </span>
      <div className="stars__rate__stars">
        {Array(5)
          .fill()
          .map((_, number) => {
            return (
              <div
                key={number + 1}
                onMouseOver={() => onMouseOver(number + 1)}
                onMouseLeave={onMouseLeave}
                onTouchStart={() => onMouseOver(number + 1)}
                onClick={onRatingClick}>
                {renderRatingComponent(
                  ratingComponentType,
                  number,
                  optionItems[number].text
                )}
              </div>
            );
          })}
      </div>
      {chipsLabel && !!getChips()?.length && (
        <span className="label chips-label">
          {chipsLabel}
          {isChipsRequired && <span className="mandatory-field">*</span>}
        </span>
      )}
      {!chipsLabel && defaultChipsLabel && !!getChips()?.length && (
        <span className="label chips-label">
          {defaultChipsLabel}
          {isChipsRequired && <span className="mandatory-field">*</span>}
        </span>
      )}
      <div className="chips">
        {getChips()?.map(({ value }, i) => (
          <div
            key={i}
            className={classNames('chip', {
              selected: isSelectedChip(value),
              inactive: isChipInactive(value),
            })}
            onClick={() => handleClickChip(value)}>
            {value}
          </div>
        ))}
      </div>
      {renderValidationMessage()}
    </div>
  );
};

export default Stars;
