import React, { useContext, useEffect, useState } from 'react';
import Select from '../../../Select';
import './DependantComboboxInput.scss';
import { comboboxDefaultValue } from '../../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import usePersistentState from '../../../common/usePersistentState';
import classNames from 'classnames';
import ChatBotService from '../../../../services/chatbot.service';
import { MultipleItemFormContext } from '../../multipleItemForm/context';

export default function DependantComboboxInput({
  id,
  item,
  dependentValue,
  form,
  ...rest
}) {
  const { t } = useTranslation();
  const [options, setOptions] = usePersistentState(id, 'options', []);
  const [loading, setLoading] = useState(false);

  const { setLazyOptionsObject, setSendValueItems } = useContext(
    MultipleItemFormContext
  );

  useEffect(() => {
    if (item.lazyLoad) {
      if (dependentValue) {
        setLoading(true);
        form.setFieldsValue({
          [item.name]: undefined,
        });
        ChatBotService.getFetchOptions(
          item.fetchOptionItemEndpoint,
          dependentValue
        )
          .then((result) => {
            if (item.sendValue) {
              setSendValueItems((prev) => [...new Set([...prev, item.name])]);
            }
            setLazyOptionsObject((prev) => ({
              ...prev,
              [item.name]: result,
            }));
            setOptions(
              result?.map((optionItem) => ({
                id: optionItem.key,
                name: optionItem.value,
              }))
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      if (dependentValue) {
        if (item.itemMap && item.itemMap[dependentValue]) {
          setOptions(item.itemMap[dependentValue]);
        }
        form.setFieldsValue({
          [item.name]: undefined,
        });
      } else {
        setOptions([]);
      }
    }

    //eslint-disable-next-line
  }, [dependentValue, item]);

  return (
    <Select
      {...rest}
      placeholder={item.placeholder || t(comboboxDefaultValue)}
      allowClear={true}
      disabled={
        (item?.initialVisible && !dependentValue) || rest.loading || loading
      }
      className={classNames('dependant-combobox-input', { loading: loading })}
      options={options.map((option) => ({
        value: option.id,
        label: option.name,
        disabled: option.disabled || false,
      }))}
    />
  );
}
