import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { deliveryAddressText } from '../../../../../../i18n/keys';

const ShipmentAddress = ({ info }) => {
  const { t } = useTranslation();

  return (
    <div className="shipment-address">
      <div className="shipment-address__name">{t(deliveryAddressText)}</div>
      <div className="shipment-address__shipto">
        {info.shipToFirstAndLastName}
      </div>
      <div className="shipment-address__info">{info.shipToAddress}</div>
      <div className="shipment-address__city">{info.shipToCityAndDistrict}</div>
    </div>
  );
};

ShipmentAddress.propTypes = {
  info: PropTypes.object.isRequired,
};

export default ShipmentAddress;
