import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  chatConnectionErrorMessage,
  liveChatLoadingMessage,
  liveChatRefused,
  liveChatTabTitle,
  unAuthorizedMessage,
} from '../../../i18n/keys';
import appService from '../../../services/app.service';
import chatGatewayService from '../../../services/chatgateway.service';
import liveChatService from '../../../services/livechat.service';
import usePersistentState from '../../common/usePersistentState';
import {
  BLOCK_END_DATE_KEY,
  USER_BLOCK,
} from '../../../constants/errorCodeConstants';

const EchoChatInitContext = React.createContext();

function EchoChatInitProvider({ children }) {
  const { t } = useTranslation();

  const resetCalls = React.useCallback(() => {
    try {
      appService.sendSecureKeyToParentHost(null);
    } catch (err) {
      console.error('sendSecureKeyToParentHost error', err);
    }
    document.title = t(liveChatTabTitle);
    window.onbeforeunload = function () {
      return null;
    };
  }, []);

  const getInitialState = React.useCallback(() => {
    return {
      loading: true,
      allowMediaUpload: false,
      loadingMessage: t(liveChatLoadingMessage),
      alertMessage: null,
      isOutOfWorkHours: false,
      isUserBlocked: false,
      chatId: null,
      userIdentifier: null,
      token: null,
      isReload: false,
      segmentConfig: {},
    };
  }, []);

  const [state, setState] = usePersistentState(
    'echoChatInitProvider',
    'state',
    () => getInitialState()
  );
  const [version, setVersion] = React.useState(0);

  React.useEffect(() => {
    async function init() {
      const nextState = getInitialState();
      resetCalls();

      try {
        const chatParams = await chatGatewayService.getLiveChatConfigParams();

        if (
          chatParams.mediaUploadStatus &&
          chatParams.mediaUploadStatus.status
        ) {
          nextState.allowMediaUpload = true;
        }
        nextState.isOutOfWorkHours = false;

        const stateMachineId = appService.getCalculatedStateMachineId();
        const shipmentNumber = appService.getShipmentNumber();
        const orderNumber = appService.getOrderNumber();
        try {
          if (state.chatId && state.userIdentifier && state.token) {
            liveChatService.setChatId(state.chatId);
            liveChatService.setSecureKey(state.chatId);
            nextState.chatId = state.chatId;
            nextState.userIdentifier = state.userIdentifier;
            nextState.token = state.token;
            nextState.isReload = true;
          } else {
            const {
              chatId,
              userIdentifier,
              token,
              segmentConfigs,
              liveChatStyledMessage,
            } = await chatGatewayService.createChatRoomForEchoChat(
              stateMachineId,
              shipmentNumber,
              orderNumber
            );

            liveChatService.setLiveChatStyledMessage(liveChatStyledMessage);
            liveChatService.setChatId(chatId);
            liveChatService.setSecureKey(chatId);
            nextState.chatId = chatId;
            nextState.userIdentifier = userIdentifier;
            nextState.token = token;
            nextState.isReload = false;

            if (segmentConfigs) {
              nextState.segmentConfig = {};
              segmentConfigs.forEach((config) => {
                nextState.segmentConfig[config.param] = config.value;
              });
            }
          }
        } catch (err) {
          console.error('error while chatGatewayService.generateChatId', err);
          window.onbeforeunload = null;

          if (err.response) {
            const status = err.response.status;
            const data = err.response.data;

            if (status === 403) {
              if (
                data.data &&
                data.data.errorCode &&
                data.data.errorCode === USER_BLOCK
              ) {
                const errorDetail = data.data.errorDetail;
                let message = errorDetail.message;
                const params = errorDetail.params;

                if (params && params[BLOCK_END_DATE_KEY]) {
                  const blockEndDate = params[BLOCK_END_DATE_KEY];

                  const blockEndDateLocalDateTime = new Date(
                    blockEndDate
                  ).toLocaleString();

                  message = message.replace(
                    `{${BLOCK_END_DATE_KEY}}`,
                    blockEndDateLocalDateTime
                  );
                }

                nextState.alertMessage = message;

                nextState.isUserBlocked = true;
              } else {
                nextState.isOutOfWorkHours = true;
                console.log('dataaaa', data);
                if (
                  data['errormessages'] &&
                  data['errormessages'][0] &&
                  data['errormessages'][0]['ErrorMessages']
                ) {
                  nextState.alertMessage =
                    data['errormessages'][0]['ErrorMessages'];
                }
              }
            } else if (status === 401) {
              nextState.alertMessage = t(unAuthorizedMessage);
            } else if (status === 409) {
              nextState.alertMessage = t(liveChatRefused);
            }
          }

          // add default error message
          if (!nextState.alertMessage) {
            nextState.alertMessage = t(chatConnectionErrorMessage);
          }
        }
      } catch (e) {
        console.error(
          'error while chatGatewayService.getLiveChatConfigParams',
          null
        );
        window.onbeforeunload = null;
        nextState.alertMessage = t(chatConnectionErrorMessage);
      }

      nextState.loading = false;

      setState(nextState);
    }

    console.log('initializing chat context version: ', version);
    init();
  }, [version]);

  const setAlertMessage = useCallback(
    (alertMessage) => {
      setState({
        ...state,
        alertMessage,
      });
    },
    [state]
  );

  const value = useMemo(() => {
    return {
      ...state,
      setAlertMessage,
      reload: () => setVersion((v) => v + 1),
    };
  }, [state]);

  return (
    <EchoChatInitContext.Provider value={value}>
      {children}
    </EchoChatInitContext.Provider>
  );
}

function useEchoChatInit() {
  const context = React.useContext(EchoChatInitContext);
  if (context === undefined) {
    throw new Error('useChatInit must be used within a EchoLiveChatProvider');
  }
  return context;
}

export { EchoChatInitProvider, useEchoChatInit };
