import React, { useEffect, useState } from 'react';
import ErrorIcon from '../../../assets/icons/error-icon.svg';
import SuccessIcon from '../../../assets/icons/success-icon.svg';
import CloseIcon from '../../../assets/icons/close-icon-grey.svg';

import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import {
  MODAL_ERROR,
  MODAL_INFO,
  MODAL_SUCCESS,
} from '../../../constants/modalTypes';
import './CustomModal.scss';

import appService from '../../../services/app.service';

const CustomModal = ({ modalModel, onCloseModal, type, isChatBotSurvey }) => {
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isInfoModal, setIsInfoModal] = useState(false);
  const [modalIcon, setModalIcon] = useState(null);
  const [testId, setTestId] = useState(null);
  const [isThereAnyListOfMessages, setIsThereAnyListOfMessages] =
    useState(false);

  const defineModalType = () => {
    if (modalModel && modalModel.title) {
      if (type === MODAL_INFO) {
        setIsInfoModal(true);
        setModalIcon(appService.getDynamicStyleValue('alert-icon'));
        setTestId('info-modal');
      } else if (type === MODAL_ERROR) {
        setIsErrorModal(true);
        setModalIcon(ErrorIcon);
        setTestId('error-modal');
      } else if (type === MODAL_SUCCESS) {
        setIsSuccessModal(true);
        setModalIcon(SuccessIcon);
        setTestId('success-modal');
      }
      modalModel.messages &&
        modalModel.messages.length > 0 &&
        setIsThereAnyListOfMessages(true);
    }
  };

  useEffect(() => {
    defineModalType();
    // eslint-disable-next-line
  }, [modalModel]);

  if ((isErrorModal || isSuccessModal || isInfoModal) && testId && modalIcon) {
    return (
      <div className="custom-modal" data-testid={testId}>
        <div className="custom-modal__content">
          <div className="custom-modal__content__header">
            <div className="custom-modal__content__header__close-area">
              <img
                src={CloseIcon}
                onClick={() => onCloseModal()}
                className="custom-modal__content__header__close-area__icon"
                alt="close-icon"
              />
            </div>
          </div>
          <div className="custom-modal__content__body">
            <div className="custom-modal__content__body__icon-wrapper">
              <img
                className="custom-modal__content__body__icon-wrapper__icon"
                alt="error-icon"
                src={modalIcon}
              />
            </div>
            <div
              className={classNames({
                'custom-modal__content__body__content': true,
                'custom-modal__content__body__content--success': isSuccessModal,
                'custom-modal__content__body__content--error': isErrorModal,
                'custom-modal__content__body__content--info': isInfoModal,
                dynamic: appService.isDynamicStylingChannel(),
              })}>
              <div className="custom-modal__content__body__content__title">
                {modalModel.title}
              </div>
              {isThereAnyListOfMessages && (
                <ul className="custom-modal__content__body__content__details">
                  {modalModel &&
                    modalModel.messages.map((error) => {
                      return (
                        <li
                          className="custom-modal__content__body__content__details__detail"
                          key={error}>
                          {' '}
                          {error}{' '}
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

CustomModal.propTypes = {
  modalModel: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default CustomModal;
