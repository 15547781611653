import {
  CLEAR_CHAT,
  CHATBOT_API_DOWN,
  CHATBOT_ERROR,
  CHATBOT_REQUEST,
  ERROR_CHATBOT_REQUEST,
  SET_ANNOUNCEMENT,
  SET_CHATBOT_SURVEY,
  SET_CID_FOR_CLICK_EVENTS,
  SET_CLICK_EVENT_ORDER,
  SET_DELIVERED_PRODUCT_PROBLEM_IMAGE_INDEX,
  SET_IS_REQUESTING,
  SET_IS_STATEMACHINE_ID_EXIST,
  SET_LAST_ACTIVE_SHIPMENT_COMPONENT_INDEX,
  SET_NOTIFICATIONS,
  SET_SHOW_LANGUAGE_SUPPORT,
  SET_START_REQUEST,
  SET_TYPING,
  USER_ANSWER,
  SET_PROACTIVE_INFO,
} from './chatbotTypes';
import uuid from 'uuid/v4';
import appService from '../../services/app.service';

const actualReducer = (state, action) => {
  switch (action.type) {
    case CHATBOT_REQUEST:
      let chatBotUserAnswers = [];
      if (
        action.payload.components.length > 1 &&
        action.payload.components[0].componentType === 'ClientMessage'
      ) {
        chatBotUserAnswers = [
          ...state.chatBotUserAnswers,
          action.payload.components[0].componentData.message,
        ];
      } else {
        chatBotUserAnswers = [...state.chatBotUserAnswers];
      }

      if (action.payload.components) {
        action.payload.components = action.payload.components.map((item) => {
          return { id: uuid(), ...item };
        });
      }

      var result = {
        ...state,
        chat: [...state.chat, action.payload],
        currentChatIndex: state.currentChatIndex + 1,
        typing: false,
        isErrorPresent: false,
        isRequesting: false,
        totalErrorCount: 0,
        chatBotUserAnswers: chatBotUserAnswers.slice(
          Math.max(chatBotUserAnswers.length - 5, 0)
        ),
      };

      if (action.payload.footerComponents) {
        action.payload.footerComponents = action.payload.footerComponents.map(
          (item) => {
            return { id: uuid(), ...item };
          }
        );
        result.footer = action.payload.footerComponents;
      }

      return result;

    case USER_ANSWER:
      return {
        ...state,
      };

    case ERROR_CHATBOT_REQUEST:
      return {
        ...state,
        error: true,
        totalErrorCount: state.totalErrorCount + 1,
      };

    case SET_DELIVERED_PRODUCT_PROBLEM_IMAGE_INDEX:
      return {
        ...state,
        deliveredProductProblemImageIndex: action.payload,
      };

    case SET_CHATBOT_SURVEY:
      return {
        ...state,
        isChatBotSurvey: action.payload,
      };

    case SET_TYPING:
      return {
        ...state,
        typing: true,
      };

    case SET_CLICK_EVENT_ORDER:
      return {
        ...state,
        clickEventOrder: action.payload,
      };

    case SET_CID_FOR_CLICK_EVENTS:
      return {
        ...state,
        cid: action.payload,
      };

    case SET_IS_REQUESTING:
      return {
        ...state,
        isRequesting: action.payload,
      };

    case CHATBOT_ERROR:
      return {
        ...state,
        chat: [...state.chat, action.payload],
        typing: false,
        isRequesting: false,
        totalErrorCount: state.totalErrorCount + 1,
        currentChatIndex: state.currentChatIndex + 1,
      };

    case CHATBOT_API_DOWN:
      return {
        ...state,
        chat: [...state.chat, action.payload],
        typing: false,
        isRequesting: false,
        totalErrorCount: 0,
        currentChatIndex: state.currentChatIndex + 1,
      };

    case SET_LAST_ACTIVE_SHIPMENT_COMPONENT_INDEX:
      return {
        ...state,
        lastActiveShipmentComponentIndex: action.payload,
      };

    case SET_ANNOUNCEMENT:
      return {
        ...state,
        isOpenNotification: action.payload.isOpenNotification,
        notificationActiveTabName: action.payload.notificationActiveTabName,
      };

    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case SET_PROACTIVE_INFO:
      return {
        ...state,
        proactiveInfo: action.payload,
      };
    case SET_START_REQUEST:
      return {
        ...state,
        isStartRequest: action.payload,
      };

    case SET_IS_STATEMACHINE_ID_EXIST:
      return {
        ...state,
        isStateMachineIdExist: action.payload,
      };

    case SET_SHOW_LANGUAGE_SUPPORT:
      return {
        ...state,
        isShowLanguage: action.payload,
      };

    case CLEAR_CHAT:
      return {
        ...state,
        chat: [],
        currentChatIndex: 0,
      };

    default:
      return state;
  }
};

const persistentReducer = (state, action) => {
  const nextState = actualReducer(state, action);
  if (appService.getIsStickyAssistant()) {
    setTimeout(() => {
      window.sessionStorage.setItem('state', JSON.stringify(nextState));
    }, 0);
  }

  return nextState;
};

export default persistentReducer;
