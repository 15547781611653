import * as keys from './keys';

export default {
  translation: {
    [`${keys.appTitle}`]: 'Asistent Trendyol',
    [`${keys.appTitleTgoApp}`]: 'Asistent',
    [`${keys.appTitleMilla}`]: 'Asistent',
    [`${keys.appTabTitle}`]: 'Asistent Trendyol',
    [`${keys.liveChatTitle}`]: 'Živý chat',
    [`${keys.liveChatTitleTgoApp}`]: 'Živý chat',
    [`${keys.liveChatTitleMilla}`]: 'Živý chat',
    [`${keys.liveChatTabTitle}`]: 'Živý chat',
    [`${keys.chatbotErrorMsg}`]:
      'Došlo k systémové chybě. Zkuste to prosím znovu.',
    [`${keys.liveChatLoadingMessage}`]:
      'Spojování s naším zástupcem zákaznického servisu, vyčkejte prosím...',
    [`${keys.agentsBusyMessage}`]:
      'Všichni naši zástupci v současné době obsluhují jiné zákazníky, vyčkejte prosím...',
    [`${keys.liveChatConnecting}`]: 'Spojování...',
    [`${keys.systemJoined}`]: 'Přiřazení zástupce zákazníka...',
    [`${keys.agentDisconnected}`]: 'Přiřazení nového zástupce....',
    [`${keys.agentJoined}`]: 'Online',
    [`${keys.systemJoinedMessage}`]:
      'Vítejte v živém chatu společnosti Trendyol.',
    [`${keys.fileNameNotValidError}`]: 'Název souboru není platný.',
    [`${keys.fileEmptyError}`]: 'Obsah nahraného souboru je prázdný.',
    [`${keys.liveChatOffline}`]: 'Offline',
    [`${keys.makeUploadedContentBigger}`]: 'Zobrazit ve větším formátu',
    [`${keys.timeOutFirstPart}`]: 'Tento chat bude ukončen během',
    [`${keys.timeOutSecondPart}`]: 'sekund',
    [`${keys.timeOutThirdPart}`]: 'protože jste už dlouho nic nenapsali.',
    [`${keys.closeLiveChatButton}`]: 'Ukončit chat',
    [`${keys.continueLiveChatButton}`]: 'Pokračovat v chatování',
    [`${keys.liveChatRefused}`]:
      'S tímto účtem v současnosti probíhá jiný rozhovor. V případě potřeby to prosím zkuste později.',
    [`${keys.maxTimeOutThresholdExceedMessage}`]:
      'Tento chat byl systémem automaticky ukončen, protože byl po určitou dobu nečinný. Děkujeme za pochopení.',
    [`${keys.agentLeftMessage}`]: 'Zástupce zákazníka ukončil chat.',
    [`${keys.chatConnectionErrorMessage}`]:
      'Došlo k chybě, zkuste to prosím znovu.',
    [`${keys.chatInputBarPlaceholderMessage}`]: 'Napište zprávu...',
    [`${keys.resendMediaMessage}`]: 'Zkuste to prosím znovu.',
    [`${keys.resendMediaMessageForEmptyImage}`]: 'Nahrajte prosím znovu.',
    [`${keys.unAuthorizedMessage}`]:
      'K přístupu na tuto stránku nejste oprávněni.',
    [`${keys.surveyMessage}`]:
      'Jste spokojeni s rozhovorem, který jste právě vedli?',
    [`${keys.trendyolAsistantSurveyMessage}`]:
      'Jste s asistentem Trendyol spokojeni?',
    [`${keys.surveyCommentMessage}`]: 'Chcete ještě něco dodat?',
    [`${keys.surveyRatingMessage}`]:
      'Ohodnoťte svůj zážitek s asistentem Trendyol',
    [`${keys.liveChatSurveyRatingMessage}`]: 'Ohodnoťte našeho zástupce',
    [`${keys.surveyRatingBadMessage}`]: 'Velmi špatný',
    [`${keys.surveyRatingGoodMessage}`]: 'Velmi dobrý',
    [`${keys.surveyRateButton}`]: 'Odeslat',
    [`${keys.surveyErrorModalTitle}`]: 'DOŠLO K CHYBĚ PRŮZKUMU',
    [`${keys.surveyErrorRatingEmpty}`]: 'Zadejte prosím hodnocení.',
    [`${keys.surveyErrorServiceError}`]:
      'Došlo k chybě, zkuste to prosím znovu.',
    [`${keys.surveySuccessModalTitle}`]: 'HODNOCENÍ PROBĚHLO ÚSPĚŠNĚ',
    [`${keys.surveySuccessModalMessage}`]:
      'Děkujeme vám za účast v našem průzkumu!',
    [`${keys.liveChatNewMessageAppTitle}`]: 'Máte novou zprávu...',
    [`${keys.errorPageMessage}`]:
      'Došlo k systémové chybě. Zkuste to prosím znovu.',
    [`${keys.closeOnErrorButton}`]: 'Zavřít',
    [`${keys.reconnectOnErrorButton}`]: 'Znovu připojit',
    [`${keys.liveChatReconnecting}`]: 'Znovu se připojuji...',
    [`${keys.nameText}`]: 'Křestní jméno',
    [`${keys.lastnameText}`]: 'Příjmení',
    [`${keys.outOfWorkOurMessage}`]:
      'Naši zástupci zákaznického servisu nemají momentálně pracovní dobu. Můžete zanechat zprávu. Ozveme se vám co nejdříve.',
    [`${keys.invalidTokenInfoText}`]:
      'Vaše informace nemůžeme analyzovat. Ukončete prosím aplikaci a přihlaste se znovu.',
    [`${keys.newText}`]: 'NOVINKA',
    [`${keys.emailText}`]: 'E-mail',
    [`${keys.continueLiveChatText}`]: 'Vrátit se zpět',
    [`${keys.exitLiveChatModalText}`]: 'Opravdu chcete chat ukončit?',
    [`${keys.exitAndOpenSurvey}`]: 'Potvrdit ukončení chatu',
    [`${keys.clientReconnectingMessage}`]:
      'Vážený zákazníku, z důvodu pomalého připojení k internetu vás opět odkazujeme na zástupce. Váš chat bude obnoven. Prosíme vás o vyčkání a děkujeme za pochopení.',
    [`${keys.clientRedirectedMessage}`]:
      'Vážený zákazníku, z důvodu pomalého internetového připojení vás přepojujeme na jiného zástupce. Historie tohoto hovoru bude předána novému zástupci. Vyčkejte prosím a děkujeme za pochopení.',
    [`${keys.stickySurveyTitle}`]: 'Jste s asistentem Trendyol spokojeni?',
    [`${keys.stickySurveyRateMessage}`]: 'Ohodnoťte nás prosím',
    [`${keys.stickySurveyCommentMessage}`]: 'Chcete ještě něco dodat?',
    [`${keys.stickySurveySuccessTitle}`]: 'Obdrželi jsme vaše hodnocení!',
    [`${keys.stickySurveySuccessText}`]:
      'Děkuji vám za hodnocení. Využijeme ho, abychom se zlepšili.',
    [`${keys.stickySurveySuccessButtonText}`]: 'Zavřít',
    [`${keys.placeHolderSurname}`]: 'Zadejte příjmení',
    [`${keys.placeHolderName}`]: 'Zadejte křestní jméno',
    [`${keys.yourEmailText}`]: 'E-mail',
    [`${keys.placeholderEmail}`]: 'Zadejte e-mail',
    [`${keys.languageText}`]: 'Jazyk',
    [`${keys.placeholderLanguage}`]: 'Vyberte jazyk',
    [`${keys.deliveryNumberText}`]: 'Dodací číslo',
    [`${keys.placeholderDeliveryNumber}`]: 'Zadejte dodací číslo',
    [`${keys.startChatText}`]: 'Chatuj',
    [`${keys.internationalTicketCreateSucceedTitle}`]:
      'Děkujeme, že jste se ozvali',
    [`${keys.internationalTicketCreateSucceedText}`]:
      'Obdrželi jsme váš e-mail. Podíváme se na něj a brzy se vám ozveme.',
    [`${keys.internationalTicketCreateFailedTitle}`]: '',
    [`${keys.internationalTicketCreateFailedText}`]:
      'Došlo k chybě, zkuste to prosím znovu.',
    [`${keys.closeButtonText}`]: 'Zavřít',
    [`${keys.retryButtonText}`]: 'Zkuste to znovu',
    [`${keys.placeholderSubject}`]: 'Zadejte předmět',
    [`${keys.subjectText}`]: 'Předmět',
    [`${keys.internationalSubjectImageText}`]: 'Nahrát obrázky',
    [`${keys.mailDescriptionText}`]: 'Napište podrobně svou zprávu',
    [`${keys.mailSubmitText}`]: 'Odeslat',
    [`${keys.placeHolderDescriptionText}`]: 'Zadejte popis',
    [`${keys.englishLangText}`]: 'Angličtina',
    [`${keys.germanLangText}`]: 'Němčina',
    [`${keys.englishLangTextShort}`]: 'ENG',
    [`${keys.selectOrderMsg}`]: 'Vyberte',
    [`${keys.shipmentNo}`]: 'Dodací č.',
    [`${keys.orderSelectedMsg}`]: 'Vybrané',
    [`${keys.quantityText}`]: 'Kus',
    [`${keys.claimOrderClaimableIsNotDone}`]:
      'Vyplňte prosím níže uvedená pole.',
    [`${keys.claimQuantityText}`]: 'Vyberte množství',
    [`${keys.claimReasonText}`]: 'Důvod',
    [`${keys.countAndPriceText}`]: 'Množství a cena:',
    [`${keys.claimOrderReason}`]: 'Vyberte důvod vrácení',
    [`${keys.selectProductImageText}`]: 'Nahrát obrázky (maximálně {{count}})',
    [`${keys.internationalMailSelectProductImageText}`]:
      'Nahrát obrázky (maximálně {{count}})',
    [`${keys.claimOrderDescription}`]: 'Doplňte prosím podrobnosti',
    [`${keys.claimSimulationPreviewQuantity}`]: 'Množství',
    [`${keys.claimSimulationPreviewProduct}`]: 'Položka',
    [`${keys.claimOrderFillToContinue}`]:
      'Pro pokračování vyplňte pole množství a důvod vrácení zboží.',
    [`${keys.claimQuantityText}`]: 'Vyberte množství',
    [`${keys.optionalFileInfoText}`]: 'Nahrát obrázky (volitelné)',
    [`${keys.imageFormatMismatchErrorText}`]:
      'Můžete nahrávat pouze soubory JPG a PNG.',
    [`${keys.imageMaxSizeErrorText}`]:
      'Velikost souboru by neměla přesáhnout {{size}} MB.',
    [`${keys.imageCountErrorText}`]: 'Můžete nahrát až {{count}} souborů.',
    [`${keys.imageReadError}`]:
      'Soubor se nepodařilo zpracovat. Zkuste to prosím znovu.',
    [`${keys.imageUploadError}`]:
      'Při nahrávání souboru došlo k chybě. Zkuste to prosím znovu.',
    [`${keys.shipmentSubtitleTotal}`]: 'Celková částka',
    [`${keys.shipmentSubtitleItems}`]: 'Položka',
    [`${keys.orderNo}`]: 'Č. objednávky',
    [`${keys.seeAllAnnouncement}`]: 'Zobrazit všechna oznámení',
    [`${keys.showMoreTicket}`]: 'Ukázat více',
    [`${keys.notificationsTitle}`]: 'Oznámení',
    [`${keys.totalClaimAmountText}`]: 'Celková vrácená částka',
    [`${keys.claimAmountText}`]: 'Vrácená částka',
    [`${keys.claimCoinAmountText}`]: 'Vrácené Trendyol Coiny',
    [`${keys.coinText}`]: 'Trendyol Coiny',
    [`${keys.coinUsageMessage}`]: 'Použil jste',
    [`${keys.coinUsageOrderMessage}`]: 'na tuto objednávku!',
    [`${keys.coinsWording}`]: 'mince',
    [`${keys.coinQuantityAndAmountInfo}`]:
      '{{quantity}} {{coinsWording}} ({{amount}} {{currency}})',
    [`${keys.claimStatusCargoText}`]: 'Kde je moje vrácené zboží?',
    [`${keys.showMoreShipment}`]: 'Zobrazit další produkty',
    [`${keys.showMoreShipment}`]: 'Zobrazit další položky',
    [`${keys.orderText}`]: 'Moje objednávky',
    [`${keys.ordersText}`]: 'Moje objednávky',
    [`${keys.currencyText}`]: '€',
    [`${keys.copyText}`]: 'Kopírovat',
    [`${keys.copiedText}`]: 'Zkopírováno',
    [`${keys.deliveryDateText}`]: 'Datum doručení:',
    [`${keys.paymentInfoText}`]: 'Platební údaje',
    [`${keys.subtotalText}`]: 'Mezisoučet',
    [`${keys.cargoText}`]: 'Přeprava',
    [`${keys.totalText}`]: 'Celkem',
    [`${keys.deliveryAddressText}`]: 'Doručovací adresa',
    [`${keys.orderDate}`]: 'Datum objednávky',
    [`${keys.orderSummary}`]: 'Shrnutí objednávky',
    [`${keys.productText}`]: 'položka',
    [`${keys.linkCopied}`]: 'Číslo objednávky zkopírováno',
    [`${keys.billingAddressText}`]: 'Fakturační adresa',
    [`${keys.detail}`]: 'Podrobnosti',
    [`${keys.deliveryTimeText}`]: 'Datum doručení:',
    [`${keys.addExtraImage}`]: 'Přidat obrázky',
    [`${keys.chatbotErrorMsgKey}`]: 'Chyba!',
    [`${keys.chatbotShortErrorMsg}`]: 'Něco se pokazilo.',
    [`${keys.collectionPointText}`]: 'Místo vyzvednutí',
    [`${keys.commercialText}`]: 'Prodej na firemní fakturu',
    [`${keys.fastMarketText}`]: 'Rychlý trh',
    [`${keys.mealText}`]: 'Jídlo',
    [`${keys.groupDealText}`]: 'Koupit s přáteli',
    [`${keys.wardrobeText}`]: 'DOLAP',
    [`${keys.inputLanguageNotSupported}`]:
      'Naše služby podpory jsou v současné době dostupné pouze v angličtině. Svůj dotaz prosím zadejte v angličtině.',
    [`${keys.myOrderDetail}`]: 'Podrobnosti o mé objednávce',
    [`${keys.arabicLangText}`]: 'arabština',
    [`${keys.showMoreContent}`]: 'Ukázat více',
    [`${keys.showLessContent}`]: 'Zobrazit méně',
    [`${keys.bulgariaLangText}`]: 'Bulharština',
    [`${keys.polandLangText}`]: 'Polština',
    [`${keys.slovakiaLangText}`]: 'Slovenština',
    [`${keys.romaniaLangText}`]: 'Rumunština',
    [`${keys.czechiaLangText}`]: 'Čeština',
    [`${keys.hungaryLangText}`]: 'Maďarština',
    [`${keys.greeceLangText}`]: 'Řečtina',
    [`${keys.cancelOrderSelectProduct}`]: 'Vyber položku a důvod zrušení.',
    [`${keys.cancelOrderSelectReason}`]: 'Vyber prosím důvod zrušení',
    [`${keys.selectReason}`]: 'Vyber důvod',
    [`${keys.other}`]: 'Vysvětlení',
    [`${keys.explanation}`]: 'Zadej vysvětlení k položce, kterou chceš zrušit',
    [`${keys.userDisconnectedOnBackground}`]:
      'Tato konverzace byla ukončena, protože jsi během ní opustil/a aplikaci nebo jsi byl/a dlouho neaktivní. Děkujeme za pochopení.',
    [`${keys.refundDetailText}`]: 'Zobrazit podrobnosti vrácení peněz',
    [`${keys.refundProgressBankInfo}`]:
      'Čeká se na zpracování finanční institucí',
    [`${keys.productImageText}`]: 'Obrázek produktu',
    [`${keys.refundAmountText}`]: 'Vrácená částka',
    [`${keys.claimLastModifiedDateTrText}`]:
      'Datum, kdy společnost Trendyol vrátila peníze',
    [`${keys.claimLastModifiedDateMillaText}`]:
      'Datum, kdy společnost Trendyolmilla vrátila peníze',
    [`${keys.estimatedRefundDateText}`]: 'Odhadovaná doba vrácení peněz',
    [`${keys.bankRefNoText}`]: 'Referenční číslo banky',
    [`${keys.refundProgressTrInfo}`]: 'Vrátili jsme ti peníze',
    [`${keys.refundProgressMillaInfo}`]: 'Vrátili jsme ti peníze',
  },
};
