import React from 'react';
import * as PropTypes from 'prop-types';
import './ClientMessage.scss';
import classNames from 'classnames';
import appService from '../../../services/app.service';

const ClientMessage = ({ componentData }) => {
  return (
    <div
      className={classNames([
        'client-message',
        {
          dynamic: appService.isDynamicStylingChannel(),
        },
      ])}
      data-testid="client-message">
      <div className="bubble">
        <div className="message">{componentData.message}</div>
      </div>
    </div>
  );
};

ClientMessage.propTypes = {
  componentData: PropTypes.object.isRequired,
};

export default React.memo(ClientMessage);
