import React from 'react';
import './ProductItem.scss';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { quantity } from '../../../i18n/keys';

const ProductItem = ({ item, fromState = false }) => {
  const { t } = useTranslation();

  return (
    <div className={classnames(['product-base', { 'from-state': fromState }])}>
      <div className="product-body">
        <div className="info-wrapper">
          <div className="item-info">
            <div className="product-item-image">
              <img src={item.imageUrl} alt={item.productName} />
            </div>
            <div className="product-item-title-container">
              <div className="product-item-title" title={item.productName}>
                {item.productName}
              </div>
              <div className="product-item-quantity">
                {item.quantity}{' '}
                <span className="product-item-quantity">{t(quantity)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
