import './Switch.scss';

import React from 'react';
import classNames from 'classnames';
import circleInfo from '../../../assets/icons/circle-info.svg';
import useValidation from '../hooks/useValidation';

const Switch = ({
  formItem,
  value,
  onChange,
  touched,
  onTouch,
  validationRef,
}) => {
  const { id, label, optionItems, rules } = formItem;

  const handleChange = (option) => (e) => {
    e.preventDefault();
    onChange(id, option.value);
    onTouch(id, false);
  };

  const { validation, requiredRule } = useValidation({
    rules,
    value,
    ref: validationRef,
  });

  return (
    <div className="rating-survey-switch">
      <span className="label">
        {label} {!!requiredRule && <span className="mandatory-field">*</span>}
      </span>
      <div className="switch-container">
        {optionItems.map((option, i) => (
          <div
            key={`${option.key}-${i}`}
            className={classNames('switch-item', {
              active: value === option.value,
              inactive: value && value !== option.value,
            })}
            onClick={handleChange(option)}>
            {option.iconUrl && (
              <img
                width={16}
                height={16}
                src={option.iconUrl}
                alt="option icon"
              />
            )}
            <span>{option.value}</span>
          </div>
        ))}
      </div>
      {!validation.isValid && touched && (
        <div className="form-validation-message">
          <img src={circleInfo} className="info-icon" alt="info" />
          {validation.message}
        </div>
      )}
    </div>
  );
};

export default Switch;
