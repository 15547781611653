import React, { useContext, useMemo, useState } from 'react';
import './Packages.scss';
import { useTranslation } from 'react-i18next';
import {
  itemText,
  packageText,
  productText,
  showLessContent,
  showMoreContent,
  totalText,
} from '../../../i18n/keys';
import Image from '../../common/Image';
import classNames from 'classnames';
import Bubble from '../../layout/bubble/Bubble';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg';

import ShipmentStatus from '../../common/ShipmentStatus';
import ChatBotContext from '../../../context/chatbot/chatbotContext';

const DEFAULT_VISIBLE_COUNT = 3;
const DEFAULT_FULLY_VISIBLE_COUNT = 2;

const Packages = ({ componentData }) => {
  const { t } = useTranslation();
  const chatBotContext = useContext(ChatBotContext);
  const { selectOrder } = chatBotContext;

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleProducts = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedPackages = useMemo(
    () =>
      isExpanded
        ? componentData.packages
        : componentData.packages.slice(0, DEFAULT_VISIBLE_COUNT),
    [isExpanded]
  );

  const onClickShipmentStatus = (item) => {
    if (item.event) {
      const executeEventRequest = {
        event: item.event,
        data: {
          shipmentNumber: item.shipmentNumber,
          orderId: item.orderId,
          orderNumber: item.orderNumber,
        },
      };
      selectOrder(executeEventRequest);
    }
  };

  return (
    <Bubble
      title={componentData.label}
      isBordered={false}
      isBottomRounded={false}
      className="packages-container">
      <div
        className={classNames([
          'packages-wrapper',
          {
            expanded: isExpanded,
            collapsed:
              componentData?.packages?.length > DEFAULT_FULLY_VISIBLE_COUNT &&
              !isExpanded,
          },
        ])}>
        <div className="package-list">
          {displayedPackages?.map((item, i) => {
            const imagesCount = item.imageUrls.length || 0;
            const images = item.imageUrls.slice(0, 3);

            return (
              <div key={i}>
                <div className="package-body">
                  <div className="package-content">
                    <div className="package-info">
                      <span className="package-label">
                        {`${t(packageText)} ${item.packageNumber}`}
                      </span>
                      <ShipmentStatus
                        type={item.shipmentStatus.type}
                        typeForUser={(
                          item.shipmentStatus.typeForUser || ''
                        ).toLowerCase()}
                        statustext={item.shipmentStatus.text}
                      />
                      <span className="package-count">
                        <span>{t(totalText)}:</span>
                        {`${item.totalItemAmount} ${t(itemText)}`}
                      </span>
                    </div>

                    <div className="product-images">
                      {images.map((src, i) =>
                        i < 2 ? (
                          <div className="product-image" key={src}>
                            <Image src={src} alt="product-image" />
                          </div>
                        ) : (
                          <div
                            key={src}
                            className={classNames('product-image', {
                              'more-item': imagesCount > 3 && i === 2,
                            })}>
                            <Image src={src} />
                            <div
                              className="quantity"
                              data-testid="product-quantity">
                              <span>+{imagesCount - 2}</span> {t(productText)}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                {!!item.button && (
                  <ButtonGroup>
                    <ButtonGroup.Button
                      className="shipping-progress-button"
                      onClick={() => onClickShipmentStatus(item)}>
                      {item.button}
                    </ButtonGroup.Button>
                  </ButtonGroup>
                )}
                {i < componentData.packages.length - 1 && (
                  <hr className="divider"></hr>
                )}
              </div>
            );
          })}
        </div>
        {componentData?.packages?.length > DEFAULT_FULLY_VISIBLE_COUNT && (
          <div className="expand-wrapper" onClick={toggleProducts}>
            <span>{isExpanded ? t(showLessContent) : t(showMoreContent)}</span>
            {isExpanded ? (
              <ArrowUp className="show-more-less-icon" />
            ) : (
              <ArrowDown className="show-more-less-icon" />
            )}
          </div>
        )}
      </div>
    </Bubble>
  );
};

export default Packages;
