import React from 'react';
import './MessageBox.scss';
import { Modal } from 'antd';
import { ReactComponent as SucceedIcon } from '../../../assets/icons/messageBox/succeed.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/messageBox/error.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/messageBox/info.svg';

const callModal = ({
  msg,
  iconElement,
  onClose,
  className,
  width = '320px',
  ...props
}) => {
  Modal.info({
    icon: <></>,
    content: (
      <>
        {iconElement}
        <p>{msg}</p>
      </>
    ),
    onCancel: () => {
      if (onClose) onClose();
    },
    className: 'chat-message-box',
    width,
    closable: true,
    ...props,
  });
};

const Succeed = ({ msg, onOk, width, ...props }) => {
  callModal({
    msg,
    iconElement: <SucceedIcon className="icon" />,
    onOk,
    className: 'ok-button',
    width,
    ...props,
  });
};

const Error = ({ msg, onOk, width, ...props }) => {
  callModal({
    msg,
    iconElement: <ErrorIcon className="icon" />,
    onOk,
    className: 'ok-button',
    width,
    ...props,
  });
};

const Info = ({ msg, onOk, width, ...props }) => {
  callModal({
    msg,
    iconElement: <InfoIcon className="icon" />,
    onOk,
    className: 'informed-ok-button',
    width,
    ...props,
  });
};

export default { Succeed, Error, Info };
