import './TextArea.scss';

import React, { useEffect } from 'react';
import circleInfo from '../../../assets/icons/circle-info.svg';
import useValidation from '../hooks/useValidation';
import MessageBox from '../../common/Modal/MessageBox';
import { inputLanguageNotSupported } from '../../../i18n/keys';
import Utils from '../../../utils';
import { useTranslation } from 'react-i18next';

const TextArea = ({
  formItem,
  value,
  onChange,
  touched,
  onTouch,
  validationRef,
}) => {
  const { id, label, placeholder, rules } = formItem;
  const { t } = useTranslation();
  const handleChange = (e) => {
    e.preventDefault();
    if (Utils.isInputArabic(e.target.value)) {
      MessageBox.Error({
        msg: t(inputLanguageNotSupported),
      });
      return;
    }
    onChange(id, e.target.value);
  };

  const { validation, requiredRule } = useValidation({
    rules,
    value,
    ref: validationRef,
  });

  useEffect(() => {
    if (validation.isValid && touched) {
      onTouch(formItem.id, false);
    }
    // eslint-disable-next-line
  }, [formItem.id, touched, validation]);

  return (
    <div className="rating-survey-text-area">
      {label && label !== '' && (
        <span className="label">
          {label} {!!requiredRule && <span className="mandatory-field">*</span>}
        </span>
      )}
      <textarea
        className="survey-text-area"
        placeholder={placeholder}
        value={value ?? ''}
        onChange={handleChange}
      />
      {!validation.isValid && touched && (
        <div className="form-validation-message">
          <img src={circleInfo} className="info-icon" alt="info" />
          {validation.message}
        </div>
      )}
    </div>
  );
};

export default TextArea;
