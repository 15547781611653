import './RatingSurveyArea.scss';

import React, { useEffect, useRef } from 'react';
import Switch from '../switch/Switch';
import TextArea from '../textArea/TextArea';
import Stars from '../stars/Stars';
import uuid from 'uuid/v4';
import classNames from 'classnames';
import RequestingIcon from '../../../assets/icons/refresh-page-option.svg';
import appService from '../../../services/app.service';

export const COMPONENT_TYPES = {
  SWITCH: 'SWITCH',
  STARS: 'STARS',
  TEXT_AREA: 'TEXT_AREA',
  CHIPS: 'CHIPS',
};

const componentMap = {
  [COMPONENT_TYPES.SWITCH]: Switch,
  [COMPONENT_TYPES.STARS]: Stars,
  [COMPONENT_TYPES.TEXT_AREA]: TextArea,
};

const RatingSurveyArea = ({
  formItems,
  formValues,
  formTouched,
  submitButtonText,
  isSendingSurvey,
  onChange,
  onTouch,
  toggleFormTouched,
  onSubmit,
}) => {
  const validationRef = useRef({});

  const validateForm = () =>
    Object.values(validationRef.current || {}).every((formItem) =>
      formItem.validate ? formItem.validate() : true
    );

  const handleSendSurvey = () => {
    if (isSendingSurvey === true) {
      return;
    }

    toggleFormTouched(formItems, true);
    validateForm() && onSubmit();
  };

  useEffect(() => {
    validationRef.current = formItems.reduce((acc, formItem) => {
      acc[formItem.id] = {};
      return acc;
    }, {});
  }, [formItems]);

  return (
    <div className="rating-survey-component">
      <div className="survey-container">
        {formItems.map((formItem, index) => {
          const Component = componentMap[formItem.type];
          return (
            <React.Fragment key={`${formItem.id}-${index}`}>
              <Component
                formItem={formItem}
                value={formValues[formItem.id]}
                onChange={onChange}
                id={uuid()}
                touched={formTouched[formItem.id]}
                onTouch={onTouch}
                validationRef={validationRef.current[formItem.id]}
              />
              {index !== formItems.length - 1 && (
                <div className="form-divider" />
              )}
            </React.Fragment>
          );
        })}
      </div>
      <div className="rate-action">
        <button
          className={classNames(
            'submit-button',
            {
              requesting: isSendingSurvey,
              'survey-rate-area__send__button': true,
            },
            { dynamic: appService.isDynamicStylingChannel() }
          )}
          onClick={handleSendSurvey}>
          {isSendingSurvey && (
            <img src={RequestingIcon} className="button-loader" alt="loading" />
          )}
          {submitButtonText}
        </button>
      </div>
    </div>
  );
};
export default RatingSurveyArea;
