import appService from '../services/app.service';

const store = {};

export default window.customStorage = {
  setItem(key, value) {
    store[key] = value;

    if (appService.getIsStickyAssistant()) {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    }
  },
  removeItem(key) {
    delete store[key];
    window.sessionStorage.removeItem(key);
  },
  getItems() {
    return Array.from(
      new Set([...Object.keys(sessionStorage), ...Object.keys(store)])
    );
  },
  getItem(key) {
    const sessionStorageValue =
      window.sessionStorage.getItem(key) &&
      window.sessionStorage.getItem(key) !== 'undefined'
        ? JSON.parse(window.sessionStorage.getItem(key))
        : null;
    let item = store[key] ? store[key] : sessionStorageValue;
    return item === 'undefined' || item === 'null' ? null : item;
  },
};
