import {
  CHAT_ID,
  CHAT_SECURE_KEY,
  LIVE_CHAT_STYLED_MESSAGE,
  SURVEY_SECURE_KEY,
} from '../constants/sessionStorageConstants';

class LiveChatService {
  static getSecureKey() {
    return window.customStorage.getItem(CHAT_SECURE_KEY);
  }

  static getSurveySecureKey() {
    return window.customStorage.getItem(SURVEY_SECURE_KEY);
  }

  static removeSurveySecureKey() {
    window.sessionStorage.removeItem(SURVEY_SECURE_KEY);
  }

  static setSecureKey(chatSecureKey) {
    window.customStorage.setItem(CHAT_SECURE_KEY, chatSecureKey);
  }

  static removeSecureKey() {
    window.sessionStorage.removeItem(CHAT_SECURE_KEY);
  }

  static getChatId() {
    return window.customStorage.getItem(CHAT_ID);
  }

  static setChatId(chatId) {
    window.customStorage.setItem(CHAT_ID, chatId);
  }

  static getLiveChatStyledMessage() {
    let message = window.customStorage.getItem(LIVE_CHAT_STYLED_MESSAGE);
    if (
      null === message ||
      'null' === message ||
      undefined === message ||
      'undefined' === message
    ) {
      return '';
    }
    return message;
  }

  static setLiveChatStyledMessage(styledMessage) {
    window.customStorage.setItem(LIVE_CHAT_STYLED_MESSAGE, styledMessage);
  }

  static removeChatId() {
    window.sessionStorage.removeItem(CHAT_ID);
  }

  static clearChatParamsFromSessionStorage() {
    window.sessionStorage.removeItem(CHAT_ID);
    window.sessionStorage.removeItem(CHAT_SECURE_KEY);
  }
}

export default LiveChatService;
