import React from 'react';
import './ClaimSimulationPreview.scss';
import { useTranslation } from 'react-i18next';
import {
  claimSimulationPreviewProduct,
  claimSimulationPreviewQuantity,
  orderImageLabel,
} from '../../../../i18n/keys';
import Bubble from '../../../layout/bubble/Bubble';
import classNames from 'classnames';

const ClaimSimulationPreviewItem = ({ componentData }) => {
  const { t } = useTranslation();

  const items = componentData;
  const label = componentData.label;

  return (
    <div className="claim-simulation-preview">
      <Bubble
        title={label}
        className={classNames({ 'no-title': !label })}
        isBottomRounded={false}>
        <div className="content">
          <div className="body">
            {items.slice(0, 4).map((selectedItem, index) => {
              return index && index > 2 ? (
                <div key={index} className="order-item">
                  <div className="image-wrapper">
                    <div className="more-item">
                      <img
                        className="image"
                        src={selectedItem.imageUrl}
                        alt={t(orderImageLabel)}
                      />
                      <div className="text">
                        <span className="count">+{items.length - 3}</span>{' '}
                        {t(claimSimulationPreviewProduct)}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div key={index} className="order-item">
                  <div className="image-wrapper">
                    <img
                      className="image"
                      src={selectedItem.imageUrl}
                      alt={t(orderImageLabel)}
                    />
                  </div>
                  <div className="quantity">
                    {selectedItem.quantity} {t(claimSimulationPreviewQuantity)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Bubble>
    </div>
  );
};
export default ClaimSimulationPreviewItem;
