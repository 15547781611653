import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import './DeepLink.scss';
import utils from '../../../utils';
import appService from '../../../services/app.service';
import Bubble from '../../layout/bubble/Bubble';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import classNames from 'classnames';
import NextComponent from '../nextComponent/NextComponent';

const DeepLink = ({ componentData, ...data }) => {
  const { showHover, history, currentIndex, scrollToBottom } = data;

  const { isFooter } = componentData;

  let deeplinkData = componentData;
  let linkStyle;
  if (showHover) {
    linkStyle = { cursor: 'pointer' };
  } else {
  }
  const chatBotContext = useContext(ChatBotContext);
  const { requestForDeepLink, deliveredProductProblemImageIndex } =
    chatBotContext;

  const [renderNextComponent, setRenderNextComponent] = useState(false);

  const handleScroll = (height) => {
    setTimeout(() => {
      try {
        scrollToBottom(height);
      } catch (e) {}
    }, 200);
  };

  const onDeepLinkClick = () => {
    if (deeplinkData.nextComponents) {
      setRenderNextComponent(true);
      handleScroll(-100);
      return;
    }
    utils.handleClickDeeplink(
      deeplinkData.event,
      deeplinkData.eventLabel,
      deeplinkData.url,
      requestForDeepLink,
      history
    );
  };

  useEffect(() => {
    if (
      !currentIndex ||
      currentIndex - 1 !== deliveredProductProblemImageIndex
    ) {
      appService.clearDeliveredProductProblemImage();
    }
  }, []);

  return (
    <>
      <>
        {!isFooter ? (
          <Bubble
            title={deeplinkData.label}
            isBottomRounded={false}
            isBordered={false}
            className="deeplink-bubble">
            <button
              className={classNames('deeplink-button', {
                dynamic: appService.isDynamicStylingChannel(),
              })}
              disabled={
                deeplinkData.url === undefined &&
                deeplinkData.nextComponents === undefined
              }
              onClick={() => onDeepLinkClick()}
              type="button"
              style={linkStyle}>
              {deeplinkData.iconUrl && (
                <img src={deeplinkData.iconUrl} alt="deeplink_icon" />
              )}
              <span>{deeplinkData.text}</span>
            </button>
          </Bubble>
        ) : (
          <Fragment>
            {(deeplinkData.url !== undefined ||
              deeplinkData.nextComponents !== undefined) && (
              <ButtonGroup.Button
                className={classNames('deeplink-button', {
                  dynamic: appService.isDynamicStylingChannel(),
                })}
                {...(deeplinkData.iconUrl && {
                  iconLeft: deeplinkData.iconUrl,
                })}
                onClick={() => onDeepLinkClick()}
                isSelectable={true}
                title={deeplinkData.text}
              />
            )}
          </Fragment>
        )}
      </>
      {renderNextComponent &&
        deeplinkData.nextComponents.map((nextComponent) => (
          <NextComponent next={nextComponent} scrollToBottom={scrollToBottom} />
        ))}
    </>
  );
};

DeepLink.propTypes = {
  componentData: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default withRouter(DeepLink);
