import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import './ExpandableText.scss';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import usePersistentState from '../../common/usePersistentState';
import { GET_ACTION_EVENT } from '../../../constants/chatAssistantConstant';
import ChatBotService from '../../../services/chatbot.service';

const ExpandableText = ({
  id,
  componentData,
  handleToggleExpand,
  isRenderedFromOutside = false,
  setShouldResizeIframe = () => {},
}) => {
  const eventLabel = componentData.eventLabel;
  const [opened, setOpened] = usePersistentState(id, 'opened', false);

  const [showDetail, setShowDetail] = usePersistentState(
    id,
    'showDetail',
    false
  );

  const onClick = () => {
    if (isRenderedFromOutside) {
      setShouldResizeIframe(true);
    } else {
      handleToggleExpand();
    }

    setShowDetail(!showDetail);
    if (!opened) {
      sendEventData();
    }
    setOpened(true);
  };

  const sendEventData = () => {
    const executeEventRequest = {
      event: GET_ACTION_EVENT,
      data: {
        actionEventLabel: eventLabel,
      },
    };

    ChatBotService.execute(executeEventRequest);
  };

  const expandMessage = componentData.expandMessage;
  const messages = componentData.message;

  return (
    <div
      className={classNames('expandable_text', { open: showDetail })}
      data-testid="static-text">
      {showDetail ? <Details messages={messages} /> : null}

      <p onClick={onClick} className="paragraph">
        {expandMessage}
        {'  '}
        <i
          className={classNames({
            'arrow down': !showDetail,
            'arrow up': showDetail,
          })}
        />
      </p>
    </div>
  );
};

const Details = ({ messages }) => {
  return (
    <div
      className="expandable_text_detail"
      data-testid="expandable_text_detail">
      <div className="container">
        <div className="content-wrapper">
          <div className="message">
            {messages.split('\r\n').map((item, i) => {
              if (item === '') {
                return <br />;
              }
              return (
                <p key={i} className="text" data-testid="message-text">
                  <ReactMarkdown children={item} />
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

ExpandableText.propTypes = {
  componentData: PropTypes.object.isRequired,
};

export default React.memo(ExpandableText);
