import { FALLBACK_IMAGE_URL } from '../../../constants';
import fallbackHorizontal from '../../../assets/icons/fallback.png';
import { useMemo } from 'react';

export default function Image({
  src,
  isHorizantolFallback,
  fallbackImage,
  ...rest
}) {
  const onImageError = (e) => {
    e.target.src = fallbackImage;
  };

  const _fallBackImage = useMemo(
    () => (isHorizantolFallback ? fallbackHorizontal : FALLBACK_IMAGE_URL),
    [isHorizantolFallback]
  );

  return (
    <img
      alt="sample-product"
      src={src ? src : _fallBackImage}
      onError={onImageError}
      {...rest}
    />
  );
}

Image.defaultProps = {
  isHorizantolFallback: false,
  fallbackImage: FALLBACK_IMAGE_URL,
};
