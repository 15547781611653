import appService from './app.service';
import { EVENT_CHANNEL, EVENT_NAME } from '../constants';
import utils from '../utils';
import { SendClickEventRequest } from '../constants/models';
import {
  MESSAGE_TYPE_IMAGE,
  MESSAGE_TYPE_VIDEO,
} from '../constants/liveChatConstants';
import liveChatService from './livechat.service';
import axiosLib from 'axios';
import axios from 'axios';
import { TOKEN_NAME } from '../constants/sessionStorageConstants';
import { UPLOAD_VERSION_1 } from '../constants/chatAssistantConstant';
import { replaceWithCurrentDomain } from './env.service';

const chatGatewayURL = replaceWithCurrentDomain(
  process.env.REACT_APP_CHAT_GATEWAY_API_URL
);
const chatUploadApiURL = replaceWithCurrentDomain(
  process.env.REACT_APP_CHAT_UPLOAD_API_URL
);
const orderPageSize = 4;

const axiosGateway = axiosLib.create();

axiosGateway.interceptors.request.use(
  async (config) => {
    if (appService.getSellerRefreshToken()) {
      let access_token = null;

      const accessTokenSeller = JSON.parse(
        window.customStorage.getItem('access_token_seller')
      );

      const expireTime = accessTokenSeller
        ? new Date(accessTokenSeller.expireTime)
        : null;

      if (expireTime && expireTime > new Date()) {
        access_token = accessTokenSeller.accessTokenSeller;
      }
      if (!access_token) {
        const response = await ChatGatewayService.refreshToken(
          appService.getSellerRefreshToken()
        );

        if (response.accessToken) {
          access_token = response.accessToken;
          appService.setSellerAccessToken(response.accessToken);
          window.customStorage.setItem(TOKEN_NAME, response.accessToken);
        }
      }

      config.headers['Authorization'] = access_token;
    }

    if (appService.canAddCredentials()) {
      config.withCredentials = true;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

class ChatGatewayService {
  static assistantConfigHeaders() {
    const token = appService.getToken();
    return {
      Authorization: token,
      'Content-Type': 'application/json',
      Platform: appService.getPlatform(),
      BuildName: appService.getBuildName(),
      Version: appService.getAppVersion(),
      SegmentId: appService.getSegmentId(),
      ChannelId: appService.getChannelId(),
      OrderChannelId: appService.getOrderChannelId(),
      Language: appService.getLanguage(),
      CountryCode: appService.getCountryCode(),
      StorefrontId: appService.getStoreFrontId(),
      SubChannel: appService.getSubChannel(),
      AppName: appService.getAppName(),
      LcMember: appService.getLcMember(),
      'X-Correlation-Id': appService.getStateMachineId()
        ? appService.getStateMachineId()
        : appService.getClientCorrelationId(),
    };
  }

  static headers() {
    const token = appService.getToken();
    return {
      Authorization: token,
      'Content-Type': 'application/json',
      Platform: appService.getPlatform(),
      BuildName: appService.getBuildName(),
      Version: appService.getAppVersion(),
      SegmentId: appService.getSegmentId(),
      ChannelId: appService.getChannelId(),
      Language: appService.getLanguage(),
      CountryCode: appService.getCountryCode(),
      StorefrontId: appService.getStoreFrontId(),
      SubChannel: appService.getSubChannel(),
      AppName: appService.getAppName(),
      AssistantId: appService.getAssistantId(),
      OrderChannelId: appService.getOrderChannelId(),
      'X-Correlation-Id': !appService.getStateMachineId()
        ? appService.getClientCorrelationId()
        : appService.getStateMachineId(),
      LcMember: appService.getLcMember(),
    };
  }

  static uploadApiHeaders() {
    const token = appService.getToken();
    return {
      Authorization: token,
      'Content-Type': 'application/json',
      Platform: appService.getPlatform(),
      BuildName: appService.getBuildName(),
      Version: appService.getAppVersion(),
      SegmentId: appService.getSegmentId(),
      ChannelId: appService.getChannelId(),
      CountryCode: appService.getCountryCode(),
      Language: appService.getLanguage(),
      StorefrontId: appService.getStoreFrontId(),
      CorrelationId: appService.getStateMachineId(),
      AssistantId: appService.getAssistantId(),
      OrderChannelId: appService.getOrderChannelId(),
      LcMember: appService.getLcMember(),
      'X-Correlation-Id': appService.getStateMachineId()
        ? appService.getStateMachineId()
        : appService.getClientCorrelationId(),
    };
  }

  static async sendClickEvent(choice, cid, clickEventOrder) {
    const pid = appService.getPid();
    const sid = appService.getSid();
    const clickEventActionName = utils.getEventActionNameForClickEvent(
      choice,
      clickEventOrder
    );
    const title = choice.subTitle ? choice.subTitle : choice.title;
    if (pid && sid) {
      const sendClickEventRequest = new SendClickEventRequest(
        EVENT_NAME,
        pid,
        clickEventActionName,
        sid,
        utils.getUserIdFromJwt(),
        title,
        clickEventOrder.toString(),
        cid,
        EVENT_CHANNEL
      );
      return await axiosGateway.post(
        `${chatGatewayURL}/chatbot/event`,
        sendClickEventRequest,
        { headers: this.headers() }
      );
    }
  }

  static async getLiveChatConfigParams() {
    const response = await axiosGateway.get(
      `${chatGatewayURL}/chatrule/chatloadparameters`,
      {
        headers: this.headers(),
        timeout: process.env.REACT_APP_GENERATECHATID_TIMEOUT,
      }
    );

    return new Promise(async (resolve, reject) => {
      response && response.data && response.data.success
        ? resolve(response.data.data)
        : reject(response);
    });
  }

  static async getLiveChatSupportedLanguages() {
    let headers = this.headers();

    const response = await axiosGateway.get(
      `${chatGatewayURL}/chatrule/livechat-supported-languages`,
      {
        headers: headers,
        timeout: process.env.REACT_APP_GENERATECHATID_TIMEOUT,
      }
    );

    return new Promise(async (resolve, reject) => {
      response && response.data && response.data.success
        ? resolve(response.data.data)
        : reject(response);
    });
  }

  static async refreshToken(refreshToken) {
    const response = await axios.post(`${chatGatewayURL}/sso/refresh-token`, {
      refreshToken,
    });
    return new Promise(async (resolve, reject) => {
      response && response.data && response.data.success
        ? resolve(response.data.data)
        : reject(response);
    });
  }

  static async getMediaAccessToken() {
    let headers = this.headers();

    const response = await axios.post(
      `${chatGatewayURL}/media-access-token`,
      {},
      {
        headers: headers,
      }
    );
    return new Promise(async (resolve, reject) => {
      response && response.data && response.data.success
        ? resolve(response.data.data)
        : reject(response);
    });
  }

  static async getLiveChatConfig() {
    const response = await axiosGateway.get(`${chatGatewayURL}/config`, {
      headers: this.headers(),
      timeout: process.env.REACT_APP_GENERATECHATID_TIMEOUT,
    });
    return new Promise(async (resolve, reject) => {
      response && response.data && response.data.success
        ? resolve(response.data.data)
        : reject(response);
    });
  }

  static async getAssistantConfig() {
    const response = await axiosGateway.get(
      `${chatGatewayURL}/config/assistant`,
      {
        headers: this.assistantConfigHeaders(),
        timeout: process.env.REACT_APP_GENERATECHATID_TIMEOUT,
      }
    );
    return new Promise(async (resolve, reject) => {
      response && response.data && response.data.success
        ? resolve(response.data.data)
        : reject(response);
    });
  }

  static async getVerificationTokens(singleUseToken) {
    const response = await axios.post(
      `${chatGatewayURL}/sso/verification-token`,
      { singleUseToken }
    );

    return new Promise(async (resolve, reject) => {
      response && response.data && response.data.success
        ? resolve(response.data.data)
        : reject(response);
    });
  }

  static async sendFile(
    file,
    secureKey,
    fileId,
    uploadVersion = UPLOAD_VERSION_1
  ) {
    let fileType = utils.getFileType(file);

    let endPointAccordingToFileType;

    if (fileType === MESSAGE_TYPE_IMAGE) {
      endPointAccordingToFileType = 'image';
    } else if (fileType === MESSAGE_TYPE_VIDEO) {
      endPointAccordingToFileType = 'video';
    } else {
      endPointAccordingToFileType = 'document';
    }
    let bodyFormData = new FormData();
    bodyFormData.set('file', file);

    let fieldHeader = !fileId ? '' : fileId;
    let headers = this.uploadApiHeaders();
    headers.SecureKey = secureKey;
    headers.FileId = fieldHeader;
    headers.UploadVersion = uploadVersion;

    let url = `${chatUploadApiURL}/media/${endPointAccordingToFileType}`;

    if (appService.isGuestUser()) {
      url = `${chatUploadApiURL}/guest/media/${endPointAccordingToFileType}`;
    }
    const response = await axiosGateway.post(url, bodyFormData, {
      headers: headers,
    });
    return new Promise(async (resolve, reject) => {
      response && response.data && response.data.success
        ? resolve(response)
        : reject(response);
    });
  }

  static async createChatRoomForEchoChat(
    stateMachineId,
    shipmentNumber,
    orderNumber
  ) {
    if (appService.isGuestUser()) {
      return this.createChatRoomForEchoChatForGuest(stateMachineId);
    } else {
      shipmentNumber = Number(shipmentNumber);
      const orderChannelId = await appService.getOrderChannelId();
      const clickedTopicEvent = await appService.getClickedTopicEvent();
      const menuType = await appService.getMenuType();
      const customFields = await appService.getEchoCustomFields();
      const chatTopic = await appService.getLastChatTopic();

      let requestObject = {
        stateMachineId,
        shipmentNumber,
        orderChannelId,
        clickedTopicEvent,
        menuType,
        customFields,
        chatTopic,
        orderNumber,
      };

      if (appService.isInternational()) {
        const internationalInfo = appService.getInternationalCustomerInfo();
        requestObject.shipmentNumber = parseInt(
          internationalInfo.deliveryNumber
        );
      }

      const response = await axiosGateway.post(
        `${chatGatewayURL}/chat/room`,
        requestObject,
        {
          headers: this.headers(),
          timeout: process.env.REACT_APP_GENERATECHATID_TIMEOUT,
        }
      );

      return new Promise(async (resolve, reject) => {
        response && response.data && response.data.success
          ? resolve(response.data.data)
          : reject(response);
      });
    }
  }

  static async updateUserAppState(appState) {
    const chatId = await liveChatService.getChatId();

    let requestObject = {
      userIdentifier: chatId,
      appState,
    };

    const response = await axiosGateway.put(
      `${chatGatewayURL}/user-app-state/update`,
      requestObject,
      {
        headers: this.headers(),
        timeout: process.env.REACT_APP_UPDATE_USER_APP_STATE_TIMEOUT,
      }
    );

    return new Promise(async (resolve, reject) => {
      response?.data?.success ? resolve(response.data.data) : reject(response);
    });
  }

  static async createChatRoomForEchoChatForGuest(stateMachineId) {
    const orderChannelId = await appService.getOrderChannelId();
    const clickedTopicEvent = await appService.getClickedTopicEvent();
    const menuType = await appService.getMenuType();
    const customFields = await appService.getEchoCustomFields();
    const chatTopic = await appService.getLastChatTopic();

    let requestObject = {
      stateMachineId,
      orderChannelId,
      clickedTopicEvent,
      menuType,
      customFields,
      chatTopic,
    };

    if (appService.isInternational()) {
      const internationalInfo = await appService.getInternationalCustomerInfo();
      requestObject.shipmentNumber = parseInt(internationalInfo.deliveryNumber);
      requestObject.FirstName = internationalInfo.firstName;
      requestObject.LastName = internationalInfo.lastName;
      requestObject.Email = internationalInfo.email;
    }

    const response = await axiosGateway.post(
      `${chatGatewayURL}/chat/guest/room`,
      requestObject,
      {
        headers: this.headers(),
        timeout: process.env.REACT_APP_GENERATECHATID_TIMEOUT,
      }
    );

    return new Promise(async (resolve, reject) => {
      response && response.data && response.data.success
        ? resolve(response.data.data)
        : reject(response);
    });
  }

  static async sendSurvey(surveyModel) {
    const orderChannelId = await appService.getOrderChannelId();
    let surveyHeaders = this.headers();
    surveyHeaders.ChannelId = appService.getChannelId();
    surveyHeaders.SegmentId = appService.getSegmentId();
    surveyHeaders.OrderChannelId = orderChannelId;

    let response = null;

    if (appService.isGuestUser()) {
      response = await axiosGateway.post(
        `${chatGatewayURL}/chat/guest/report`,
        surveyModel,
        { headers: surveyHeaders }
      );
    } else {
      response = await axiosGateway.post(
        `${chatGatewayURL}/chat/report`,
        surveyModel,
        { headers: surveyHeaders }
      );
    }

    return new Promise(async (resolve, reject) => {
      response && response.data && response.data.data
        ? resolve(response.data.data)
        : reject(response);
    });
  }

  static async getOrder(page) {
    const response = await axiosGateway.get(`${chatGatewayURL}/orders`, {
      headers: this.headers(),
      params: { page, pageSize: orderPageSize },
    });
    return new Promise(async (resolve, reject) => {
      response && response.data && response.data.data
        ? resolve(response.data.data)
        : reject(response);
    });
  }

  static async getTransitionScreenInfo() {
    let transitionHeaders = this.headers();
    transitionHeaders.OrderChannelId = appService.getOrderChannelId();

    const response = await axiosGateway.get(
      `${chatGatewayURL}/welcoming-messages`,
      {
        headers: transitionHeaders,
      }
    );
    return new Promise(async (resolve, reject) => {
      response && response.data ? resolve(response.data) : reject(response);
    });
  }

  static async getSurvey() {
    const orderChannelId = await appService.getOrderChannelId();
    let headers = this.headers();
    headers.OrderChannelId = orderChannelId ?? 1;
    const response = await axiosGateway.get(`${chatGatewayURL}/survey/config`, {
      headers: headers,
      params: {
        orderChannelId: orderChannelId ?? 1,
      },
    });
    return new Promise(async (resolve, reject) => {
      response && response.data ? resolve(response.data) : reject(response);
    });
  }

  static async getChatBotSurveyEligibility(chatId) {
    let headers = this.headers();
    headers['ChatId'] = chatId;

    const response = await axiosGateway.get(
      `${chatGatewayURL}/survey/eligibility`,
      {
        headers: headers,
      }
    );
    return new Promise(async (resolve, reject) => {
      response && response.data ? resolve(response.data) : reject(response);
    });
  }
}

export default ChatGatewayService;
