import React, { useContext, useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import chatbotService from '../../../services/chatbot.service';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import CommonContext from '../../../context/common/commonContext';
import { ModalModel } from '../../../constants/models';
import usePersistentState from '../../common/usePersistentState';
import { chatbotErrorMsgKey, chatbotShortErrorMsg } from '../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import ListArrows from '../../common/ListArrows';
import './Order.scss';
import AppService from '../../../services/app.service';
import ShipmentListingItem from '../listing/shipment/ShipmentListingItem';

function Order({
  isRenderedFromOutside = false,
  id,
  componentData,
  currentIndex,
  showHover,
}) {
  const { t } = useTranslation();

  const { page, pageSize, begin, until, shipments, isPaginationEnabled } =
    componentData;

  const [currentPage, setCurrentPage] = usePersistentState(
    id,
    'currentPage',
    null
  );

  const chatBotContext = useContext(ChatBotContext);
  const {
    setLastActiveShipmentComponentIndex,
    lastActiveShipmentComponentIndex,
  } = chatBotContext;
  const inputEl = useRef(null);
  const [prevDisable, setPrevDisable] = usePersistentState(
    id,
    'prevDisable',
    true
  );
  const [nextDisable, setNextDisable] = usePersistentState(
    id,
    'nextDisable',
    false
  );

  const [current, setCurrent] = useState(null);
  const [shipmentData, setShipmentData] = usePersistentState(
    id,
    'shipmentData',
    []
  );
  const [event, setEvent] = usePersistentState(id, 'event', '');
  const [isLoading, setIsLoading] = usePersistentState(id, 'isLoading', false);
  const [last, setLast] = usePersistentState(id, 'last', false);
  const commonContext = useContext(CommonContext);
  const { setErrors } = commonContext;
  const [orderItemActive, setOrderItemActive] = usePersistentState(
    id,
    'orderItemActive',
    true
  );
  const [selectedShipmentNumber, setSelectedShipmentNumber] =
    usePersistentState(id, 'selectedShipmentNumber', null);
  const [selectedOrderId, setSelectedOrderId] = usePersistentState(
    id,
    'selectedOrderId',
    null
  );

  const [selectedOrderNumber, setSelectedOrderNumber] = usePersistentState(
    id,
    'selectedOrderNumber',
    null
  );

  useEffect(() => {
    setCurrent(inputEl.current);
    setShipmentData(shipments);
    setEvent(shipments[0] != null ? shipments[0].event : '');
    setLastActiveShipmentComponentIndex(currentIndex);
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    setOrderItemActive(currentIndex === lastActiveShipmentComponentIndex);
  }, [lastActiveShipmentComponentIndex]);

  useEffect(() => {
    if (current && !isLoading && !last) {
      current.addEventListener('scroll', handleScroll);
      return () => current.removeEventListener('scroll', handleScroll);
    }
  });

  const handleScroll = () => {
    let newScrollLeft = AppService.isRtl()
      ? current.scrollLeft - current.offsetWidth / 2
      : current.scrollLeft + current.offsetWidth / 2;
    checkButtons(newScrollLeft);
  };

  const handleClickLeft = () => {
    let newScrollLeft = current.scrollLeft - current.offsetWidth / 2;
    current.scrollLeft = newScrollLeft;
    checkButtons(newScrollLeft);
  };

  const handleClickRight = () => {
    let newScrollLeft = current.scrollLeft + current.offsetWidth / 2;
    current.scrollLeft = newScrollLeft;
    checkButtons(newScrollLeft);
  };

  const checkButtons = (newScrollLeft) => {
    let nextCheck =
      (AppService.isRtl() ? -1 : 1) * newScrollLeft + current.offsetWidth >=
      current.scrollWidth;

    if (nextCheck) {
      if (isPaginationEnabled === false) {
        setLast(true);
        setNextDisable(true);
        return;
      }

      if (isLoading) {
        return;
      }
      setIsLoading(true);
      if (!isRenderedFromOutside) {
        chatbotService
          .getChatBotShipments(currentPage + 1, pageSize, begin, until, event)
          .then((response) => {
            setCurrentPage(response.page);
            setShipmentData([...shipmentData, ...response.shipments]);
            setIsLoading(false);
            if (
              response.shipments.length === 0 ||
              response.shipments === [] ||
              response.shipments === null
            ) {
              setLast(true);
              setNextDisable(true);
            }
          })
          .catch(() => {
            const errorModal = new ModalModel(t(chatbotErrorMsgKey), [
              t(chatbotShortErrorMsg),
            ]);
            setErrors(errorModal);
          });
      }
    }

    let prevCheck = newScrollLeft <= 0;
    setPrevDisable(prevCheck);
  };
  return (
    <div className="shipments order-container">
      <div className="order__items">
        <div className="slider-wrapper">
          <div className="items" ref={inputEl}>
            {shipmentData &&
              shipmentData.map((shipment, i) => {
                return (
                  <ShipmentListingItem
                    id={id + 'OrderItem' + i}
                    isRenderedFromOutside={isRenderedFromOutside}
                    shipment={shipment}
                    key={shipment.shipmentNumber}
                    currentIndex={currentIndex}
                    orderItemActive={orderItemActive}
                    showHover={showHover}
                    selectedShipmentNumber={selectedShipmentNumber}
                    setSelectedShipmentNumber={setSelectedShipmentNumber}
                    selectedOrderId={selectedOrderId}
                    setSelectedOrderId={setSelectedOrderId}
                    selectedOrderNumber={selectedOrderNumber}
                    setSelectedOrderNumber={setSelectedOrderNumber}
                  />
                );
              })}
          </div>

          <ListArrows
            prevDisable={prevDisable}
            nextDisable={nextDisable}
            handleClickLeft={handleClickLeft}
            handleClickRight={handleClickRight}
          />
        </div>
      </div>
    </div>
  );
}

Order.propTypes = {
  componentData: PropTypes.array.isRequired,
};

export default Order;
