import * as React from 'react';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import throttle from 'lodash.throttle';
import appService from '../../../services/app.service';
import {
  AckType,
  ChatUserStatusType,
  ConnectionState,
  EchoClient,
  EchoSocketEventType,
  EventChannel,
  SubscriptionChannel,
  SystemMessageType,
  SystemPayloadType,
} from '@customer-service/echo-messaging-lib';
import { useEchoChatInit } from './echoChatInitContext';
import {
  agentLeftMessage,
  chatConnectionErrorMessage,
  clientReconnectingMessage,
  clientRedirectedMessage,
  deliveredProductProblemImageAgentScreenLabel,
  invalidTokenInfoText,
  maxTimeOutThresholdExceedMessage,
  systemJoinedMessage,
  userDisconnectedOnBackground,
} from '../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import Utils from '../../../utils';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import useIsTyping from './useIsTyping';
import usePersistentState from '../../common/usePersistentState';
import livechatService from '../../../services/livechat.service';

const DEFAULT_CUSTOMER_ON_HOLD_TIMESPAN_IN_SECONDS = 60;

const EchoLiveChatContext = React.createContext();

export const ChatStatus = {
  CONNECTING: 'CONNECTING',
  RECONNECTING: 'RECONNECTING',
  CONNECTED: 'CONNECTED',
  AGENT_DISCONNECTED: 'AGENT_DISCONNECTED',
  REDIRECTED: 'REDIRECTED',
  ENDED: 'ENDED',
};

/*
function getEchoLiveChatInitialState() {
  return {
    showOrdersModal: false,
    isTyping: false,
    connectionStatus: ConnectionState.Disconnected,
    agentStatus: 'NOT_CONNECTED',
    chatStatus: ChatStatus.CONNECTING,
    messages: [],
    showTimeoutModal: false,
    showCloseModal: false,
    chatEndReason: '',
  };
}

function echoLiveChatReducer(state, action) {
  const payload = action.payload || {};
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...payload };
    case 'UPDATE_MESSAGE':
      return {
        ...state,
        messages: state.messages.map((item) => {
          if (item.id === payload.id) {
            return {
              ...item,
              ...(payload.message || {}),
            };
          }
          return item;
        }),
      };
    case 'RESET':
      return getEchoLiveChatInitialState();
    default:
      throw new Error();
  }
}
*/

const getTimestamp = () => {
  return Math.ceil(Date.now() / 1000);
};

function getSystemJoinedMessage() {
  return appService.getLiveChatSystemJoinedMessage();
}

function shouldResetTimeoutModal(message) {
  if (
    typeof message.isDelayedMessage === undefined || // backward compatibility
    message.isDelayedMessage === true // skip old messages
  ) {
    return false;
  }
  if (typeof message?.systemPayload?.type === 'number') {
    return (
      message.systemPayload.type === SystemPayloadType.REDIRECT ||
      message.systemPayload.type === SystemPayloadType.ATTACHMENT ||
      message.systemPayload.type === SystemPayloadType.ACCOUNT_JOINED ||
      message.systemPayload.type === SystemMessageType.OUTBOUND_CALL_START
    );
  }
  return true;
}

function EchoLiveChatProvider({ children }) {
  const { t } = useTranslation();
  const {
    userIdentifier,
    chatId,
    token,
    loading: isParamsLoading,
    alertMessage,
    setAlertMessage,
    isReload,
    segmentConfig,
  } = useEchoChatInit();

  const { chatBotUserAnswers } = useContext(ChatBotContext);

  const [client, setClient] = useState(null);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [mediaInfo, setMediaInfo] = useState(null);
  const [embeddedContent, setEmbeddedContent] = useState(false);
  const [showOrdersModal, setShowOrdersModal] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState(
    ConnectionState.Disconnected
  );
  const [agentStatus, setAgentStatus] = usePersistentState(
    'echoLiveChatContext',
    'agentStatus',
    'NOT_CONNECTED'
  );

  const [lastInteractionTimestamp, setLastInteractionTimestampState] =
    usePersistentState('echoLiveChatContext', 'lastInteractionTimestamp', 0);

  const [chatStatus, setChatStatusReal] = usePersistentState(
    'echoLiveChatContext',
    'chatStatus',
    ChatStatus.CONNECTING
  );
  const chatStatusRef = useRef(chatStatus);
  const setChatStatus = useCallback((value) => {
    chatStatusRef.current = value;
    setChatStatusReal(value);
  }, []);
  const [messages, setMessages] = usePersistentState(
    'echoLiveChatContext',
    'messages',
    []
  );
  const [showTimeoutModal, setShowTimeoutModalState] = usePersistentState(
    'echoLiveChatContext',
    'showTimeoutModal',
    false
  );
  const lastInteractionTimestampRef = useRef(lastInteractionTimestamp);
  const showTimeoutModalRef = useRef(showTimeoutModal);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [chatEndReason, setChatEndReason] = usePersistentState(
    'echoLiveChatContext',
    'chatEndReason',
    ''
  );
  const isSentInitialSteps = useRef(isReload);
  const isStyledMessageSentInitialSteps = useRef(isReload);
  const shouldAddReconnectMessage = useRef(false);
  const isDisconnecting = useRef(false);
  const [textMessage, setTextMessage] = useState('');
  const disabled =
    !client ||
    connectionStatus !== ConnectionState.Connected ||
    chatStatus === ChatStatus.ENDED;

  const setLastInteractionTimestamp = useCallback((value) => {
    lastInteractionTimestampRef.current = value;
    setLastInteractionTimestampState(value);
  }, []);

  const setShowTimeoutModal = useCallback((value) => {
    showTimeoutModalRef.current = value;
    setShowTimeoutModalState(value);
  }, []);

  const addMessage = useCallback(
    (message) => {
      setMessages((value) => {
        const isMessageAdded = !!value.find((item) => item.id === message.id);

        if (isMessageAdded) {
          return value;
        }
        return [...value, message];
      });
    },
    [messages]
  );

  const updateMessage = useCallback(
    (id, message) => {
      setMessages((value) =>
        value.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              ...message,
            };
          }
          return item;
        })
      );
    },
    [messages]
  );

  const onConnectionStatusChange = useCallback((status) => {
    setConnectionStatus(status);
    if (status === ConnectionState.Disconnected) {
      setChatStatus(ChatStatus.ENDED);
    } else if (status === ConnectionState.Reconnecting) {
      // if never connected we should not set chat status to reconnecting
      if (chatStatusRef.current === ChatStatus.CONNECTING) {
        return;
      }
      setChatStatus(ChatStatus.RECONNECTING);
      if (shouldAddReconnectMessage.current === true) {
        shouldAddReconnectMessage.current = false;
        addMessage({
          id: Utils.uuid(),
          message: t(clientReconnectingMessage),
          direction: 'incoming',
          system: false,
        });
      }
    }
  }, []);

  const onMessage = useCallback(
    (event) => {
      const payload = event.payload;
      const channel = event.channel;

      if (event.type === EchoSocketEventType.ERROR) {
        if (payload.message === 'UNAUTHORIZED') {
          setChatEndReason(t(invalidTokenInfoText));
          setChatStatus(ChatStatus.ENDED);
        } else {
          setChatEndReason(t(chatConnectionErrorMessage));
          setChatStatus(ChatStatus.ENDED);
        }
        console.log('error message', event);
      }

      if (SubscriptionChannel.RoomSubscription === channel && payload.message) {
        if (
          payload.system &&
          payload.systemPayload &&
          typeof payload.systemPayload.type === 'number'
        ) {
          if (payload.systemPayload.type === SystemPayloadType.REDIRECT) {
            setAgentStatus(ChatUserStatusType[ChatUserStatusType.GONE]);
            setChatStatus(ChatStatus.REDIRECTED);
            payload.message = t(clientRedirectedMessage);
          }

          if (payload.systemPayload.type === SystemPayloadType.ACCOUNT_JOINED) {
            setAgentStatus(ChatUserStatusType[ChatUserStatusType.ACTIVE]);
            setChatStatus(ChatStatus.CONNECTED);
          }

          if (payload.systemPayload.type === SystemPayloadType.INACTIVE) {
            setAgentStatus(ChatUserStatusType[ChatUserStatusType.INACTIVE]);
          }

          if (
            payload.systemPayload.type === SystemPayloadType.TIMEOUT_WARNING
          ) {
            let createdAtTimestamp = Math.ceil(payload.createdAt / 1000);

            if (createdAtTimestamp > lastInteractionTimestampRef.current) {
              const customerOnHoldTimeout =
                segmentConfig['CUSTOMER_ON_HOLD_TIMESPAN_IN_SECONDS'] ||
                DEFAULT_CUSTOMER_ON_HOLD_TIMESPAN_IN_SECONDS;

              const timeoutTimestamp = payload.createdAt
                ? createdAtTimestamp + customerOnHoldTimeout
                : Math.ceil(Date.now() / 1000) + customerOnHoldTimeout;

              setShowTimeoutModal(timeoutTimestamp);
            }
          }

          if (
            payload.systemPayload.type === SystemPayloadType.TIMEOUT_END_CHAT
          ) {
            setChatEndReason(t(maxTimeOutThresholdExceedMessage));
          }

          if (
            payload.systemPayload.type === SystemPayloadType.END_CHAT ||
            payload.systemPayload.type === SystemPayloadType.TIMEOUT_END_CHAT
          ) {
            const customFields = payload.systemPayload.customFields;

            if (
              customFields?.['CHAT_END_REASON'] ===
              'DISCONNECTED_USER_BACKGROUND'
            ) {
              setChatEndReason(t(userDisconnectedOnBackground));
            }

            setShowTimeoutModal(false);
            setEmbeddedContent(false);
            setShowOrdersModal(false);
            setShowMediaModal(false);
            setChatStatus(ChatStatus.ENDED);
          }

          if (
            payload.systemPayload.type === SystemMessageType.OUTBOUND_CALL_START
          ) {
            if (showTimeoutModalRef.current) {
              setShowTimeoutModal(false);
            }
          }

          if (
            payload.systemPayload.type === SystemPayloadType.ATTACHMENT &&
            payload.isUser
          ) {
            isSentInitialSteps.current = true;
          }

          if (
            payload.systemPayload.type === SystemMessageType.STYLED_MESSAGE &&
            payload.isUser
          ) {
            isStyledMessageSentInitialSteps.current = true;
          }

          if (
            payload.systemPayload.type === SystemMessageType.EMBEDDED_CONTENT
          ) {
            try {
              const obj = JSON.parse(payload.message);
              payload.message = { ...obj, isEmbedded: true };
            } catch {
              return;
            }
          }

          // only redirect and account joined messages should be added to message list
          if (
            ![
              SystemPayloadType.ACCOUNT_JOINED,
              SystemPayloadType.REDIRECT,
              SystemMessageType.REALTIME_TRANSLATION_NOTIFICATION,
              SystemMessageType.EMBEDDED_CONTENT,
            ].includes(payload.systemPayload.type)
          ) {
            return;
          }
        } else {
          setChatStatus(ChatStatus.CONNECTED);
          setAgentStatus(ChatUserStatusType[ChatUserStatusType.ACTIVE]);
        }

        if (shouldResetTimeoutModal(payload)) {
          if (showTimeoutModalRef.current) {
            setShowTimeoutModal(false);
          }
        }

        shouldAddReconnectMessage.current = true;

        if (
          Math.ceil(payload.createdAt / 1000) >
          lastInteractionTimestampRef.current
        ) {
          setLastInteractionTimestamp(payload.createdAt / 1000);
        }

        addMessage({
          id: payload.id,
          message: payload.message,
          direction: payload.isUser ? 'outgoing' : 'incoming',
          system: payload.system || false,
          systemPayload: payload.systemPayload,
          createdAt: payload.createdAt,
        });
      } else if (
        SubscriptionChannel.UserStatusSubscription &&
        payload.chatUserState
      ) {
        const nextAgentStatus = payload.chatUserState;

        if (payload.from === userIdentifier) {
          // console.log('user update from it self');
        } else {
          setAgentStatus(nextAgentStatus);

          if (nextAgentStatus === ChatUserStatusType[ChatUserStatusType.GONE]) {
            setChatStatus(ChatStatus.ENDED);
            setChatEndReason(t(agentLeftMessage));
          } else {
            setChatStatus(ChatStatus.CONNECTED);
          }

          if (
            nextAgentStatus ===
            ChatUserStatusType[ChatUserStatusType.START_TYPING]
          ) {
            setIsTyping(true);
            setLastInteractionTimestamp(getTimestamp());
            setShowTimeoutModal(false);
          } else if (
            nextAgentStatus ===
            ChatUserStatusType[ChatUserStatusType.STOP_TYPING]
          ) {
            setIsTyping(false);
          }
        }
      }
    },
    [userIdentifier]
  );

  /**
   * when the app send to background then came after
   * if the client is disconnected we will show the popup
   **/
  useEffect(() => {
    const pageVisibilityListener = async function () {
      if (!document.hidden) {
        if (
          !client ||
          (client &&
            !client.isConnected() &&
            chatStatus !== ChatStatus.RECONNECTING &&
            !chatEndReason)
        ) {
          // wait for getting exact chatEndReason from socket before setting default message
          setTimeout(() => {
            setChatEndReason(
              chatEndReason || t(maxTimeOutThresholdExceedMessage)
            );
          }, 500);
        }
      }
    };

    document.addEventListener('visibilitychange', pageVisibilityListener);

    return () => {
      document.removeEventListener('visibilitychange', pageVisibilityListener);
    };
  }, [client]);

  const subscriptionTimeoutRef = useRef(null);

  const onSubscriptionsChange = useCallback((client, subscriptions) => {
    // subscription canceled
    if (subscriptions && subscriptions.length <= 0) {
      // and we still dont have ended status
      if (chatStatusRef.current !== ChatStatus.ENDED) {
        // if client is not connected set status as ended we dont have to deal with reconnect
        if (!client || !client.isConnected()) {
          setChatStatus(ChatStatus.ENDED);
        } else {
          if (subscriptionTimeoutRef.current) {
            clearTimeout(subscriptionTimeoutRef.current);
          }
          subscriptionTimeoutRef.current = setTimeout(() => {
            client
              .subscribe(SubscriptionChannel.RoomSubscription, {
                chatId,
                userIdentifier,
              })
              .then(() => console.log('onSubscriptionsChange subscribe called'))
              .catch((err) =>
                console.error('onSubscriptionsChange subscribe failed', err)
              )
              .finally(() => {
                subscriptionTimeoutRef.current = null;
              });
          }, 2000);
        }
      }
    }
  }, []);

  //#region initial connection
  useEffect(() => {
    if (alertMessage && alertMessage.length > 0 && chatId) {
      setChatStatus(ChatStatus.ENDED);
    }
  }, [alertMessage, chatId]);

  useEffect(() => {
    if (isReload) {
      // do not send initial steps on page refresh
      isSentInitialSteps.current = true;
      isStyledMessageSentInitialSteps.current = true;
    }
  }, [isReload]);

  useEffect(() => {
    if (!showTimeoutModal && sessionStorage) {
      Object.keys(sessionStorage)
        .filter((key) => key.startsWith('echoLiveChat/timeout'))
        .forEach((key) => {
          sessionStorage.removeItem(key);
        });
    }
  }, [showTimeoutModal]);

  useEffect(() => {
    let message = getSystemJoinedMessage();

    let messageEvent = {
      message: message ? message : t(systemJoinedMessage),
      direction: 'incoming',
      system: true,
      createdAt: Date.now(),
    };

    addMessage(messageEvent);
  }, []);

  useEffect(() => {
    async function sendChatBotUserAnswers() {
      isSentInitialSteps.current = true;

      try {
        const messageEvent = {
          id: Utils.uuid(),
          message: (chatBotUserAnswers || []).join('\n'),
          userIdentifier,
          chatId,
          system: true,
          systemPayload: {
            type: SystemPayloadType.ATTACHMENT,
          },
        };

        await client?.sendMessage(EventChannel.SendMessage, messageEvent);

        const liveChatImage = appService.getDeliveredProductProblemImage();

        if (liveChatImage) {
          const messageEvent = {
            id: Utils.uuid(),
            message: `${t(
              deliveredProductProblemImageAgentScreenLabel
            )} ${liveChatImage}`,
            userIdentifier,
            chatId,
            system: true,
            systemPayload: {
              type: SystemPayloadType.ATTACHMENT,
            },
          };

          await client?.sendMessage(EventChannel.SendMessage, messageEvent);
        }
      } catch (e) {
        console.error('sent initial message error', e);
        isSentInitialSteps.current = false;
      }
    }

    if (
      agentStatus === ChatUserStatusType[ChatUserStatusType.ACTIVE] &&
      !isSentInitialSteps.current &&
      Array.isArray(chatBotUserAnswers) &&
      chatBotUserAnswers.length > 0
    ) {
      sendChatBotUserAnswers().then();
    }
  }, [chatBotUserAnswers, agentStatus]);

  useEffect(() => {
    async function sendLiveChatStyledMessage() {
      isStyledMessageSentInitialSteps.current = true;

      try {
        const liveChatStyledMessage =
          livechatService.getLiveChatStyledMessage();

        if (liveChatStyledMessage) {
          const messageEvent = {
            id: Utils.uuid(),
            message: `${liveChatStyledMessage}`,
            userIdentifier,
            chatId,
            system: true,
            systemPayload: {
              type: SystemMessageType.STYLED_MESSAGE,
            },
          };

          await client?.sendMessage(EventChannel.SendMessage, messageEvent);
        }
      } catch (e) {
        console.error('sent live chat styled message error', e);
        isStyledMessageSentInitialSteps.current = false;
      }
    }

    if (
      agentStatus === ChatUserStatusType[ChatUserStatusType.ACTIVE] &&
      !isStyledMessageSentInitialSteps.current &&
      livechatService.getLiveChatStyledMessage()
    ) {
      sendLiveChatStyledMessage().then();
    }
  }, [agentStatus]);

  useEffect(() => {
    if (isParamsLoading || !chatId) {
      console.log('waiting chatId to start websocket');
      return;
    }
    let echoClientRef;

    async function init() {
      if (chatStatus === ChatStatus.ENDED) {
        console.log('chat status ENDED no need to reconnect');
        return;
      }

      const chatClient = new EchoClient({
        socketUri: process.env.REACT_APP_USER_MESSAGING_SERVICE_WEBSOCKET_URL,
        getAccessToken: () => token,
        onMessage,
        onConnectionStatusChange,
        onConnectionQualityChange: (...args) =>
          console.log('onConnectionQualityChange', ...args),
        onSubscriptionsChange: (subscriptions) =>
          inlineOnSubscriptionsChange(subscriptions),
      });

      function inlineOnSubscriptionsChange(subs) {
        onSubscriptionsChange(chatClient, subs);
      }

      try {
        await chatClient.connect();
      } catch (e) {
        console.error('error while connecting to websocket', e);
        setAlertMessage(t(chatConnectionErrorMessage));
        setChatStatus(ChatStatus.ENDED);
        return;
      }

      try {
        await chatClient.subscribe(SubscriptionChannel.RoomSubscription, {
          chatId,
          userIdentifier,
        });
      } catch (e) {
        console.error('error while subscribing room', e);
        setAlertMessage(t(chatConnectionErrorMessage));
        setChatStatus(ChatStatus.ENDED);
        return;
      }

      setClient(chatClient);
      echoClientRef = chatClient;

      setTimeout(() => {
        try {
          const updateUserStatusEvent = {
            chatId,
            userIdentifier,
            chatUserStatusType: ChatUserStatusType.ACTIVE,
          };

          chatClient.sendMessage(
            EventChannel.UpdateUserStatus,
            updateUserStatusEvent
          );

          setLastInteractionTimestamp(getTimestamp());
          setShowTimeoutModal(false);
        } catch (e) {
          console.error('sending active status failed', e);
        }
      }, 50);
    }

    init().catch((err) => console.error(err));

    return () => {
      // skip unmount event if already disconnecting
      if (isDisconnecting.current) {
        return;
      }
      isDisconnecting.current = true;
      if (echoClientRef && echoClientRef.disconnect) {
        echoClientRef
          .disconnect()
          .then(() => (echoClientRef = null))
          .catch((e) => console.error('disconnect error', e));
      }
    };
  }, [isParamsLoading, chatId]);
  //#endregion

  useEffect(() => {
    if (client && chatStatus === ChatStatus.ENDED) {
      if (isDisconnecting.current) {
        return;
      }
      isDisconnecting.current = true;
      try {
        setTimeout(() => {
          setClient(null);
          client
            .disconnect()
            ?.catch((e) =>
              console.error('chatStatus ended disconnect error', e)
            );
        }, 2500);
      } catch (e) {
        console.error('chatStatus ended disconnect error', e);
      }
    }
  }, [chatStatus, client]);

  //#region sendMessage
  const sendMessage = (message) => {
    const messageEvent = {
      id: Utils.uuid(),
      message,
      userIdentifier,
      chatId,
    };

    client?.sendMessage(EventChannel.SendMessage, messageEvent);
  };
  //#endregion

  //#region endChat
  const endChat = useCallback(async () => {
    if (isDisconnecting.current) {
      return;
    }
    isDisconnecting.current = true;
    if (client) {
      if (!client.isConnected()) {
        console.info('endChat called but client is already disconnected');
      } else {
        const leaveRoomEvent = {
          chatId,
          userIdentifier,
        };

        await client.requestResponse(
          EventChannel.LeaveRoom,
          leaveRoomEvent,
          AckType.REQUEST_RESPONSE
        );
      }

      setShowTimeoutModal(false);
      setEmbeddedContent(false);
      setShowOrdersModal(false);
      setShowMediaModal(false);
      setShowCloseModal(false);
      setChatStatus(ChatStatus.ENDED);
      setClient(null);

      setTimeout(() => {
        client
          .disconnect()
          ?.then(() => console.log('disconnected with end chat'))
          ?.catch((e) => console.error('endChat disconnect error', e));
      }, 100);
    } else {
      setShowTimeoutModal(false);
      setEmbeddedContent(false);
      setShowOrdersModal(false);
      setShowMediaModal(false);
      setShowCloseModal(false);
      setChatStatus(ChatStatus.ENDED);
      setClient(null);
    }
  }, [client, chatId, userIdentifier]);
  //#endregion

  //#region onTextAreaChange
  const onTextAreaChange = useCallback(
    (content) => {
      setTextMessage(content);
    },
    [setTextMessage]
  );

  const updateTypingStatusCallback = useCallback(
    async (isTyping) => {
      if (client) {
        const updateUserStatusEvent = {
          chatId,
          userIdentifier,
          chatUserStatusType: isTyping
            ? ChatUserStatusType.START_TYPING
            : ChatUserStatusType.STOP_TYPING,
        };

        try {
          await client.sendMessage(
            EventChannel.UpdateUserStatus,
            updateUserStatusEvent
          );
        } catch (e) {
          console.error('send status error', e);
        }
      } else {
        console.error('onTextAreaChange client is not ready');
      }
    },
    [client, userIdentifier]
  );

  const throttledUpdateTypingStatusCallback = useMemo(() => {
    return throttle(updateTypingStatusCallback, 1000);
  }, [updateTypingStatusCallback]);

  // Stop the invocation of the debounced function
  // after unmounting
  useEffect(() => {
    return () => {
      throttledUpdateTypingStatusCallback.cancel();
    };
  }, [throttledUpdateTypingStatusCallback]);

  useIsTyping(textMessage, (isTyping) => {
    throttledUpdateTypingStatusCallback(isTyping);
  });
  //#endregion

  const continueChat = useCallback(() => {
    setShowTimeoutModal(false);
    setShowCloseModal(false);
    setLastInteractionTimestamp(getTimestamp());

    const updateUserStatusEvent = {
      chatId,
      userIdentifier,
      chatUserStatusType: ChatUserStatusType.ACTIVE,
    };
    if (client) {
      client.sendMessage(EventChannel.UpdateUserStatus, updateUserStatusEvent);
    } else {
      console.warn('continueChat called when client is not connected');
    }
  }, [chatId, userIdentifier, client]);

  const value = useMemo(() => {
    return {
      client,
      disabled,
      showMediaModal,
      setShowMediaModal,
      mediaInfo,
      setMediaInfo,
      isChatEnded: chatStatus === ChatStatus.ENDED,
      isTyping,
      connectionStatus,
      agentStatus,
      onTextAreaChange,
      sendMessage,
      active: chatStatus !== ChatStatus.CONNECTING,
      updateMessage,
      endChat,
      embeddedContent,
      setEmbeddedContent,
      showOrdersModal,
      setShowOrdersModal,
      chatStatus,
      setChatStatus,
      addMessage,
      messages,
      showTimeoutModal,
      setShowTimeoutModal,
      isConnected: client && connectionStatus === ConnectionState.Connected,
      continueChat,
      showCloseModal,
      setShowCloseModal,
      chatEndReason,
      setChatEndReason,
    };
  }, [
    client,
    disabled,
    showMediaModal,
    setShowMediaModal,
    mediaInfo,
    setMediaInfo,
    isTyping,
    connectionStatus,
    agentStatus,
    onTextAreaChange,
    sendMessage,
    updateMessage,
    endChat,
    showOrdersModal,
    chatStatus,
    setChatStatus,
    addMessage,
    messages,
    showTimeoutModal,
    setShowTimeoutModal,
    continueChat,
    showCloseModal,
    setShowCloseModal,
    chatEndReason,
    setChatEndReason,
  ]);

  return (
    <EchoLiveChatContext.Provider value={value}>
      {children}
    </EchoLiveChatContext.Provider>
  );
}

function useEchoLiveChat() {
  const context = React.useContext(EchoLiveChatContext);
  if (context === undefined) {
    throw new Error(
      'useEchoLiveChat must be used within a EchoLiveChatProvider'
    );
  }
  return context;
}

export { EchoLiveChatProvider, useEchoLiveChat };
