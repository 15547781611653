import appService from './app.service';
import {
  CANCEL_SIMULATION_PREVIEW_ITEM,
  CLAIM_CANCEL_SIMULATION_DOWN,
  CLAIM_CANCEL_SIMULATION_UP,
  CLAIM_RETURN_SIMULATION_DOWN,
  CLAIM_RETURN_SIMULATION_UP,
  CONFLICT_HTTP_STATUS_CODE,
  RETURN_SIMULATION_PREVIEW_ITEM,
} from '../constants';
import {
  INFLUENCER_SEGMENT_ID,
  INFLUENCER_SEGMENT_NAME,
  TOKEN_NAME,
} from '../constants/sessionStorageConstants';
import axiosLib from 'axios';
import ChatGatewayService from './chatgateway.service';
import {
  GET_INITIAL,
  ORDER_NUMBER_KEY,
} from '../constants/chatAssistantConstant';
import { calculateChatBotUrl } from './chatbotgateway.service';

const axios = axiosLib.create();

function getChatBotUrl() {
  let assistantChatBotUrl = appService.getChatBotUrl();

  if (!assistantChatBotUrl) {
    assistantChatBotUrl = calculateChatBotUrl(
      appService.getChannelId(),
      appService.getOrderChannelId(),
      appService.getSegmentId()
    );

    appService.setChatBotUrl(assistantChatBotUrl);
  }
  return assistantChatBotUrl;
}

axios.interceptors.request.use(
  async (config) => {
    if (appService.getSellerRefreshToken()) {
      let access_token = null;

      const accessTokenSeller = JSON.parse(
        window.customStorage.getItem('access_token_seller')
      );

      const expireTime = accessTokenSeller
        ? new Date(accessTokenSeller.expireTime)
        : null;

      if (expireTime && expireTime > new Date()) {
        access_token = accessTokenSeller.accessTokenSeller;
      }

      if (!access_token) {
        const response = await ChatGatewayService.refreshToken(
          appService.getSellerRefreshToken()
        );

        if (response.accessToken) {
          access_token = response.accessToken;
          appService.setSellerAccessToken(response.accessToken);
          window.customStorage.setItem(TOKEN_NAME, response.accessToken);
        }
      }

      config.headers['Authorization'] = access_token;
    }

    if (appService.canAddCredentials()) {
      config.withCredentials = true;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

class ChatBotService {
  static headers() {
    return {
      Authorization: appService.getToken(),
      'Content-Type': 'application/json',
      Platform: appService.getPlatform(),
      'x-req-v': appService.getVersion(),
      stateMachineId: appService.getStateMachineId(),
      segmentId: appService.getSegmentId(),
      'Accept-Language': appService.getLanguage(),
      'Assistant-Language': appService.getLanguage(),
      StorefrontId: appService.getStoreFrontId(),
      ChannelId: appService.getChannelId(),
      orderChannelId: appService.getOrderChannelId(),
      AssistantId: appService.getAssistantId(),
      EventName: appService.getEventName(),
      LcMember: appService.getLcMember(),
      Accept: 'application/json, text/plain, */*',
      'X-Correlation-Id': appService.getStateMachineId()
        ? appService.getStateMachineId()
        : appService.getClientCorrelationId(),
    };
  }

  static getStartWithUrl(keyValuePair) {
    let startWithUrl = '/startWith?';

    for (let key in keyValuePair) {
      if (keyValuePair.hasOwnProperty(key) && keyValuePair[key]) {
        startWithUrl += `${key}=${keyValuePair[key]}&`;
      }
    }

    startWithUrl = startWithUrl.slice(0, -1); //removes & operator

    return startWithUrl;
  }

  static getProactiveUrl(keyValuePair) {
    let proactiveUrl = 'proactive-info?';

    for (let key in keyValuePair) {
      if (keyValuePair.hasOwnProperty(key) && keyValuePair[key]) {
        proactiveUrl += `${key}=${keyValuePair[key]}&`;
      }
    }

    proactiveUrl = proactiveUrl.slice(0, -1); //removes & operator

    return proactiveUrl;
  }

  static async execute(request) {
    if (request && request.event && request.event === 'START') {
      await appService
        .assistantConfig()
        .then((response) => {
          appService.setAssistantId(response.assistantId);
          appService.setOrderChannelId(response.orderChannelId);
        })
        .catch(() => {});

      return this.startRequest(request);
    } else {
      let url;
      if (
        request &&
        request.event &&
        request.event === 'GET_CONVERSATION_TOPICS'
      ) {
        url = `${getChatBotUrl()}/api/conversation`;
      } else {
        url = `${getChatBotUrl()}/api/execute`;
      }

      try {
        let resp = await axios.post(url, request, {
          headers: this.headers(),
        });

        if (resp.data.stateMachineId) {
          appService.setStateMachineId(resp.data.stateMachineId);
        }

        return resp;
      } catch (err) {
        if (err.response.status === CONFLICT_HTTP_STATUS_CODE) {
          await appService
            .assistantConfig()
            .then((response) => {
              appService.setAssistantId(response.assistantId);
              appService.setOrderChannelId(response.orderChannelId);
            })
            .catch(() => {});

          return this.startRequest(request);
        }
      }
    }
  }

  static async startRequest(request) {
    const eventName = appService.getEventName();
    const genericEventName = appService.getGenericEventName();
    const shipmentNumber = appService.getShipmentNumber();
    const orderId = appService.getOrderId();
    const orderChannelId = appService.getOrderChannelId();
    const prevStateMachineId = appService.getPrevStateMachineId();
    const appName = appService.getAppName();

    let keyValuePair = {};
    keyValuePair['eventName'] = eventName;
    keyValuePair['genericEventName'] = genericEventName;
    keyValuePair['shipmentNumber'] = shipmentNumber;
    keyValuePair['orderId'] = orderId;
    keyValuePair['orderChannelId'] = orderChannelId;

    let startWithUrl = this.getStartWithUrl(keyValuePair);
    let isStartWithUrl =
      eventName &&
      eventName !== GET_INITIAL &&
      eventName !== 'GET_INSTANT_INITIAL' &&
      eventName !== 'GET_MEAL_INITIAL';

    const requestUrl = `/api${isStartWithUrl ? startWithUrl : '/start'}`;

    let startHeaders = this.headers();
    startHeaders['Pid'] = appService.getPid();
    startHeaders['Sid'] = appService.getSid();
    startHeaders['buildName'] = appService.getBuildName();
    startHeaders['stateMachineId'] = null;
    startHeaders['UserSegment'] = request.userSegment;
    startHeaders['SubChannel'] = appService.getSubChannel();
    startHeaders['CountryCode'] = appService.getCountryCode();

    if (request.userSegment === INFLUENCER_SEGMENT_NAME) {
      startHeaders['segmentId'] = INFLUENCER_SEGMENT_ID;
    }
    if (prevStateMachineId) {
      startHeaders['prevStateMachineId'] = prevStateMachineId;
    }

    if (appName) {
      startHeaders['AppName'] = appName;
    }

    const claims = JSON.parse(appService.getStartRequest());

    let resp = await axios.post(
      `${getChatBotUrl()}${requestUrl}`,
      { data: claims },
      {
        headers: startHeaders,
      }
    );

    appService.setStateMachineId(resp.data.stateMachineId);
    appService.setSegmentId(resp.data.segmentId);
    appService.setVersion(resp.data.version);
    appService.setProperties(resp.data.properties);

    if (resp.data.properties) {
      const propertiesMap = new Map(Object.entries(resp.data.properties));
      appService.setOrderNumber(propertiesMap.get(ORDER_NUMBER_KEY));
    }

    return resp;
  }

  static async answer(choice) {
    if (
      choice &&
      choice.topic &&
      choice.topic === CANCEL_SIMULATION_PREVIEW_ITEM
    ) {
      // eslint-disable-next-line no-return-await
      return await axios.post(
        `${getChatBotUrl()}/cancelPreview`,
        choice.selectedItem,
        { headers: this.headers() }
      );
    } else if (
      choice &&
      choice.topic &&
      choice.topic === CLAIM_CANCEL_SIMULATION_DOWN
    ) {
      // eslint-disable-next-line no-return-await
      return await axios.post(
        `${getChatBotUrl()}/cancelItemNoVote`,
        choice.payload,
        { headers: this.headers() }
      );
    } else if (
      choice &&
      choice.topic &&
      choice.topic === CLAIM_CANCEL_SIMULATION_UP
    ) {
      // eslint-disable-next-line no-return-await
      return await axios.post(
        `${getChatBotUrl()}/cancelItems`,
        choice.payload,
        {
          headers: this.headers(),
        }
      );
    } else if (
      choice &&
      choice.topic &&
      choice.topic === CLAIM_RETURN_SIMULATION_UP
    ) {
      // eslint-disable-next-line no-return-await
      return await axios.post(
        `${getChatBotUrl()}/returnPreviewYesVote`,
        choice.payload,
        { headers: this.headers() }
      );
    } else if (
      choice &&
      choice.topic &&
      choice.topic === CLAIM_RETURN_SIMULATION_DOWN
    ) {
      // eslint-disable-next-line no-return-await
      return await axios.post(
        `${getChatBotUrl()}/returnPreviewNoVote`,
        choice.payload,
        { headers: this.headers() }
      );
    } else if (
      choice &&
      choice.topic &&
      choice.topic === RETURN_SIMULATION_PREVIEW_ITEM
    ) {
      // eslint-disable-next-line no-return-await
      return await axios.post(
        `${getChatBotUrl()}/returnPreview`,
        choice.selectedItem,
        { headers: this.headers() }
      );
    } else {
      // eslint-disable-next-line no-return-await
      return await axios.post(`${getChatBotUrl()}/answer`, choice, {
        headers: this.headers(),
      });
    }
  }

  static async getShipments(page, pageSize) {
    const orderChannelId = appService.getOrderChannelId();
    const response = await axios.get(`${getChatBotUrl()}/api/shipments`, {
      headers: this.headers(),
      params: { page, pageSize, orderChannelId: orderChannelId },
    });
    return new Promise(async (resolve, reject) => {
      response && response.data ? resolve(response.data) : reject(response);
    });
  }

  static async getChatBotShipments(page, pageSize, begin, until, event) {
    const shipmentChannelId = await appService.getOrderChannelId();
    const response = await axios.get(
      `${getChatBotUrl()}/api/ty-assistant/shipments`,
      {
        headers: this.headers(),
        params: {
          page,
          pageSize,
          begin,
          until,
          event: event,
          shipmentChannelId: shipmentChannelId,
          storefrontId: appService.getStoreFrontId(),
        },
      }
    );
    return new Promise(async (resolve, reject) => {
      response && response.data ? resolve(response.data) : reject(response);
    });
  }

  static async getChatBotNotifications() {
    let notificationHeaders = this.headers();
    notificationHeaders['buildName'] = appService.getBuildName();
    notificationHeaders['SubChannel'] = appService.getSubChannel();
    notificationHeaders['stateMachineId'] = appService.getStateMachineId();

    if (appService.getAppName()) {
      notificationHeaders['AppName'] = appService.getAppName();
    }

    const response = await axios.get(`${getChatBotUrl()}/api/notifications`, {
      headers: notificationHeaders,
    });
    return new Promise(async (resolve, reject) => {
      response && response.data ? resolve(response.data) : reject(response);
    });
  }

  static async getProactiveInfo() {
    const shipmentNumber = appService.getShipmentNumber();
    const orderId = appService.getOrderId();

    let keyValuePair = {};
    keyValuePair['shipmentNumber'] = shipmentNumber;
    keyValuePair['orderId'] = orderId;
    let proactiveUrl = this.getProactiveUrl(keyValuePair);

    const response = await axios.get(`${getChatBotUrl()}/api/${proactiveUrl}`, {
      headers: {
        AssistantId: appService.getAssistantId(),
        ChannelId: appService.getChannelId(),
        stateMachineId: appService.getStateMachineId(),
        Platform: appService.getPlatform(),
        Authorization: appService.getToken(),
        orderChannelId: appService.getOrderChannelId(),
        'Accept-Language': appService.getLanguage(),
        'Assistant-Language': appService.getLanguage(),
        LcMember: appService.getLcMember(),
      },
    });
    return new Promise(async (resolve, reject) => {
      response && !!response.data ? resolve(response.data) : reject(response);
    });
  }

  static async updateChatBotUserNotificationTabInfo(notificationTabType) {
    const response = await axios.put(
      `${getChatBotUrl()}/api/user-info/update-notification-tab/${notificationTabType}`,
      {},
      { headers: this.headers() }
    );
    return new Promise(async (resolve, reject) => {
      response && response.status && response.status === 200
        ? resolve(response.status)
        : reject(response);
    });
  }

  static async updateChatBotAnnouncementSeenInfo(announcementId) {
    const response = await axios.put(
      `${getChatBotUrl()}/api/user-info/increase-announcement-seen-count/${announcementId}`,
      {},
      { headers: this.headers() }
    );
    return new Promise(async (resolve, reject) => {
      response && response.status && response.status === 200
        ? resolve(response.status)
        : reject(response);
    });
  }

  static async getClaimItems(page, pageSize, event, claimItemStatusName) {
    const response = await axios.get(`${getChatBotUrl()}/api/claim-items`, {
      headers: this.headers(),
      params: {
        page,
        pageSize,
        event: event,
        claimItemStatusName: claimItemStatusName,
      },
    });
    return new Promise(async (resolve, reject) => {
      response && response.data ? resolve(response.data) : reject(response);
    });
  }

  static async getDistricts(provinceId) {
    const response = await axios.get(
      `${getChatBotUrl()}/api/cities/${provinceId}/districts`,
      {
        headers: this.headers(),
      }
    );
    return new Promise(async (resolve, reject) => {
      response && response.data ? resolve(response.data) : reject(response);
    });
  }

  static async getNeighborhoods(provinceId, districtId) {
    const response = await axios.get(
      `${getChatBotUrl()}/api/cities/${provinceId}/districts/${districtId}/neighborhoods`,
      {
        headers: this.headers(),
      }
    );
    return new Promise(async (resolve, reject) => {
      response && response.data ? resolve(response.data) : reject(response);
    });
  }

  static async checkOtpCodeValidation(
    otpCode,
    shipmentNumber,
    orderNumber,
    orderId,
    phoneNumber,
    event
  ) {
    const response = await axios.put(
      `${getChatBotUrl()}/api/otp/validate`,
      {
        otpCode,
        shipmentNumber,
        orderNumber,
        orderId,
        phoneNumber,
        event,
      },
      { headers: this.headers() }
    );
    return new Promise(async (resolve, reject) => {
      response ? resolve(response) : reject(response);
    });
  }

  static async reSendOtpCode(
    shipmentNumber,
    orderNumber,
    orderId,
    phoneNumber,
    event
  ) {
    const response = await axios.post(
      `${getChatBotUrl()}/api/otp/sendOtp`,
      {
        shipmentNumber,
        orderNumber,
        orderId,
        phoneNumber,
        event,
      },
      { headers: this.headers() }
    );
    return new Promise(async (resolve, reject) => {
      response ? resolve(response) : reject(response);
    });
  }

  static async getFetchOptions(fetchOptionItemEndpoint, key) {
    const orderChannelId = appService.getOrderChannelId();
    const response = await axios.get(
      `${getChatBotUrl()}${fetchOptionItemEndpoint}`,
      {
        headers: this.headers(),
        params: { key, orderChannelId: orderChannelId },
      }
    );
    return new Promise(async (resolve, reject) => {
      response && response.data ? resolve(response.data) : reject(response);
    });
  }

  static async sendChatBotSurvey(surveyModel) {
    const response = await axios.post(
      `${getChatBotUrl()}/api/chat/survey`,
      surveyModel,
      {
        headers: this.headers(),
      }
    );

    return new Promise(async (resolve, reject) => {
      response && response.data ? resolve(response.data) : reject(response);
    });
  }

  static async getChatBotSurveyConfig() {
    let lastClickedEvent = await appService.getClickedLastEventForSurvey();
    let lastChatTopicName = await appService.getLastChatTopic();
    let headers = this.headers();
    return await axios.get(`${getChatBotUrl()}/api/chat/survey/config`, {
      headers: headers,
      params: {
        lastClickedEvent,
        lastChatTopicName,
      },
    });
  }
}

export default ChatBotService;
