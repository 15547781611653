import React from 'react';
import * as PropTypes from 'prop-types';
import './Video.scss';
import { BigPlayButton, Player } from 'video-react';
import '../../../../node_modules/video-react/dist/video-react.css';

import Bubble from '../../layout/bubble/Bubble';
import ExpandableText from '../expandableText/ExpandableText';
import usePersistentState from '../../common/usePersistentState';
import ChatBotService from '../../../services/chatbot.service';
import { GET_ACTION_EVENT } from '../../../constants/chatAssistantConstant';
import classNames from 'classnames';

const Video = ({
  id,
  componentData,
  isRenderedFromOutside = false,
  setShouldResizeIframe = () => {},
}) => {
  const label = componentData.label;
  const messages = componentData.message;
  const url = componentData.url;
  const previewUrl = componentData.previewUrl;
  const expandableTextElement = componentData.expandableTextElement;
  const eventLabel = componentData.eventLabel;

  const [videoStarted, setVideoStarted] = usePersistentState(
    id,
    'videoStarted',
    false
  );

  const onStart = () => {
    if (!videoStarted) {
      sendEventData();
    }
    setVideoStarted(true);
  };

  const handleToggleExpand = () => {
    if (isRenderedFromOutside) {
      setShouldResizeIframe(true);
    }
  };

  const sendEventData = () => {
    const executeEventRequest = {
      event: GET_ACTION_EVENT,
      data: {
        actionEventLabel: eventLabel,
      },
    };

    ChatBotService.execute(executeEventRequest);
  };

  function VideoPlayer() {
    return (
      <Player
        onPlay={onStart}
        width="100%"
        height="100%"
        playsInline
        src={url}
        poster={previewUrl}>
        <BigPlayButton position="center" />
      </Player>
    );
  }
  return (
    <div
      className={classNames('video-main', {
        'has-expandable-text':
          expandableTextElement && expandableTextElement.expandMessage,
      })}>
      <Bubble isBordered={true} title={label} isBottomRounded={false}>
        <div className="player-wrapper">{VideoPlayer()}</div>
        <div className="player-footer">
          <div className="message">{messages}</div>
        </div>
        <div>
          {expandableTextElement && expandableTextElement.expandMessage && (
            <ExpandableText
              id={'ExpandableText' + id}
              componentData={{
                expandMessage: expandableTextElement.expandMessage,
                message: expandableTextElement.message,
                eventLabel: expandableTextElement.eventLabel,
              }}
              handleToggleExpand={handleToggleExpand}
            />
          )}
        </div>
      </Bubble>
    </div>
  );
};

Video.propTypes = {
  componentData: PropTypes.object.isRequired,
};

export default React.memo(Video);
