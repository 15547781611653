import React, { useEffect } from 'react';
import classNames from 'classnames';
import usePersistentState from '../../../common/usePersistentState';
import Checkbox from '@tx-components/checkbox';
import { quantityText } from '../../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import Select from '../../../Select';

const OrderProblemsProduct = ({
  id,
  product,
  selectShipment,
  unSelectShipment,
  shouldSelect,
}) => {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = usePersistentState(
    id,
    'isSelected',
    null
  );
  const [checked, setChecked] = usePersistentState(id, 'checked', false);
  const [selectedQuantity, setSelectedQuantity] = usePersistentState(
    id,
    'selectedQuantity',
    null
  );
  const [selectedShipment, setSelectedShipment] = usePersistentState(
    id,
    'selectedShipment',
    null
  );

  useEffect(() => {
    if (product.quantity === 1) {
      setSelectedQuantity(1);
    }
    //eslint-disable-next-line
  }, []);

  const handleCheckboxChange = (e, shipment) => {
    // e.stopPropagation();
    e.preventDefault();
    // e.stopImmediatePropagation();
    if (!shouldSelect) return;
    const isChecked = !checked;
    setChecked(!checked);

    if (isChecked) {
      setSelectedShipment(shipment);
      if (product.quantity === 1) {
        setSelectedQuantity(1);
        setIsSelected(true);
        selectShipment(shipment, product.quantity);
      } else if (selectedQuantity !== null) {
        selectShipment(shipment, selectedQuantity);
        setIsSelected(true);
      }
    } else {
      setSelectedShipment(null);
      setIsSelected(false);
      setSelectedQuantity(null);
      unSelectShipment(shipment);
    }
  };

  const handleQuantityChange = (value) => {
    setSelectedQuantity(value);
    if (value) {
      selectShipment(product, value);
      setSelectedShipment(product);
      setIsSelected(true);
      setChecked(true);
    } else {
      setIsSelected(false);
      setChecked(false);
      unSelectShipment(selectedShipment);
    }
  };

  return (
    <div
      data-testid={'order-problems-product'}
      className={classNames('order-problems-product', {
        'checkbox-active': isSelected,
        'passive-selected': isSelected && !shouldSelect,
      })}
      onClick={(e) => handleCheckboxChange(e, product)}>
      <div className="order-checkbox">
        <Checkbox
          checked={checked}
          disabled={!shouldSelect}
          data-testid="order-problems-product-checkbox"
        />
      </div>
      <div className="product-info">
        <div className="product-image">
          <img
            className="image"
            src={product.imageUrl}
            alt="shipment"
            data-testid={'order-problems-product-img'}
          />
        </div>
        <div className="product-details" data-testid={'product-name'}>
          <div className="product-name">{product.name}</div>
          <Select
            options={
              product.quantity &&
              new Array(product.quantity).fill(null).map((_, index) => {
                return {
                  value: index + 1,
                  label: `${index + 1} ${t(quantityText)}`,
                };
              })
            }
            disabled={!shouldSelect}
            small
            placeholder={t(quantityText)}
            value={selectedQuantity || undefined}
            onChange={handleQuantityChange}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderProblemsProduct;
