import * as keys from './keys';

export default {
  translation: {
    [`${keys.appTitle}`]: 'مساعد ترينديول',
    [`${keys.appTitleDodo}`]: 'DODO',
    [`${keys.appTabTitle}`]: 'مساعد ترينديول',
    [`${keys.liveChatTitle}`]: 'دردشة حية',
    [`${keys.liveChatTitleDodo}`]: 'دردشة دولاب الحية',
    [`${keys.liveChatTabTitle}`]: 'دردشة حية',
    [`${keys.chatbotErrorMsg}`]: 'حدث خطأ بالنظام، يرجى المحاولة مرة أخرى.',
    [`${keys.liveChatLoadingMessage}`]:
      'جارِ التوصيل بأحد موظفي خدمة العملاء لدينا، يرجى الانتظار...',
    [`${keys.agentsBusyMessage}`]:
      'جميع موظفينا يساعدون عملاء آخرين في الوقت الحالي، يرجى الانتظار...',
    [`${keys.liveChatConnecting}`]: 'جارِ الاتصال...',
    [`${keys.systemJoined}`]: 'جارِ تعيين أحد موظفي خدمة العملاء...',
    [`${keys.agentDisconnected}`]: 'جارِ تعيين موظف جديد....',
    [`${keys.agentJoined}`]: 'متصل',
    [`${keys.systemJoinedMessage}`]: 'أهلاً بك في دردشة Trendyol المباشرة.',
    [`${keys.liveChatOffline}`]: 'غير متصل',
    [`${keys.makeUploadedContentBigger}`]: 'العرض بتنسيق أكبر',
    [`${keys.timeOutFirstPart}`]: 'سيتم إنهاء هذه الدردشة خلال',
    [`${keys.timeOutSecondPart}`]: 'ثوانِ',
    [`${keys.timeOutThirdPart}`]: 'حيث أنك لم تكتب أي شيء لمدة طويلة.',
    [`${keys.closeLiveChatButton}`]: 'إنهاء الدردشة',
    [`${keys.continueLiveChatButton}`]: 'الاستمرار في الدردشة',
    [`${keys.liveChatRefused}`]:
      'هناك دردشة مفتوحة بالفعل في هذا الحساب. يمكنك المحاولة مرة أخرى لاحقًا إذا كنت لا تزال تحتاج للمساعدة.',
    [`${keys.maxTimeOutThresholdExceedMessage}`]:
      'هذه الدردشة كانت خاملة لفترة طويلة وتم إنهائها تلقائيًا بواسطة النظام. نشكرك على تفهمك.',
    [`${keys.agentLeftMessage}`]: 'قام موظف خدمة العملاء بإنهاء الدردشة.',
    [`${keys.chatConnectionErrorMessage}`]: 'حدث خطأ، يرجى المحاولة مرة أخرى.',
    [`${keys.chatInputBarPlaceholderMessage}`]: 'اكتب رسالة...',
    [`${keys.resendMediaMessage}`]: 'يرجى المحاولة مرة أخرى.',
    [`${keys.resendMediaMessageForEmptyImage}`]: 'يرجى الرفع مرة أخرى.',
    [`${keys.unAuthorizedMessage}`]: 'غير مصرح لك بالوصول إلى هذه الصفحة.',
    [`${keys.surveyMessage}`]: 'هل أنت راضِ عن المحادثة التي أجريتها؟',
    [`${keys.trendyolAsistantSurveyMessage}`]: 'هل أنت راضِ عن مساعد ترينديول؟',
    [`${keys.surveyCommentMessage}`]: 'أي شيء آخر تريد إضافته؟',
    [`${keys.surveyRatingMessage}`]: 'قم بتقييم تجربتك مع مساعد ترينديول',
    [`${keys.liveChatSurveyRatingMessage}`]: 'قم بتقييم موظفنا',
    [`${keys.surveyRatingBadMessage}`]: 'سيء للغاية',
    [`${keys.surveyRatingGoodMessage}`]: 'جيد للغاية',
    [`${keys.surveyRateButton}`]: 'إرسال',
    [`${keys.surveyErrorModalTitle}`]: 'حدث خطأ في استطلاع الرأي',
    [`${keys.surveyErrorRatingEmpty}`]: 'يرجى إدخال تقييم.',
    [`${keys.surveyErrorServiceError}`]: 'حدث خطأ، يرجى المحاولة مرة أخرى.',
    [`${keys.surveySuccessModalTitle}`]: 'تم تقديم استطلاع الرأي بنجاح',
    [`${keys.surveySuccessModalMessage}`]:
      'نشكرك على المشاركة في استطلاع الرأي الخاص بنا!',
    [`${keys.liveChatNewMessageAppTitle}`]: 'لديك رسالة جديدة.',
    [`${keys.errorPageMessage}`]: 'حدث خطأ بالنظام، يرجى المحاولة مرة أخرى.',
    [`${keys.closeOnErrorButton}`]: 'غلق',
    [`${keys.reconnectOnErrorButton}`]: 'إعادة الاتصال',
    [`${keys.liveChatReconnecting}`]: 'جارِ إعادة الاتصال...',
    [`${keys.nameText}`]: 'الاسم الأول',
    [`${keys.lastnameText}`]: 'الاسم الأخير',
    [`${keys.outOfWorkOurMessage}`]:
      'موظفي خدمة العملاء لدينا خارج ساعات العمل في الوقت الحالي. يمكنك إعادة توجيه رسالتك. سنقوم بالرجوع إليك في أقرب وقت ممكن.',
    [`${keys.invalidTokenInfoText}`]:
      'لا يمكننا معالجة معلوماتك. يرجى الخروج من التطبيق وتسجيل الدخول مرة أخرى.',
    [`${keys.newText}`]: 'جديد',
    [`${keys.emailText}`]: 'البريد الإلكتروني',
    [`${keys.continueLiveChatText}`]: 'العودة',
    [`${keys.exitLiveChatModalText}`]: 'هل أنت متأكد من إنهاء الدردشة؟',
    [`${keys.exitAndOpenSurvey}`]: 'تأكيد إنهاء الدردشة',
    [`${keys.clientReconnectingMessage}`]:
      'عزيزي العميل، نقوم بتحويلك إلى موظف خدمة العملاء مرة أخرى بسبب بطء سرعة الإنترنت لديك. سيتم استكمال الدردشة معك. نرجو منك الانتظار، ونشكرك على تفهمك.',
    [`${keys.clientRedirectedMessage}`]:
      'عزيزي العميل، نقوم بتحويلك إلى موظف خدمة عملاء مختلف بسبب بطء سرعة الإنترنت. سجل المحادثة في هذا الاتصال سيتم تحويله إلى الموظف الجديد. يرجى الانتظار، نشكرك على تفهمك.',
    [`${keys.stickySurveyTitle}`]: 'هل أنت راضِ عن مساعد ترينديول؟',
    [`${keys.stickySurveyRateMessage}`]: 'يرجى تقييمنا',
    [`${keys.stickySurveyCommentMessage}`]: 'أي شيء آخر تريد إضافته؟',
    [`${keys.stickySurveySuccessTitle}`]: 'لقد استلمنا رأيك!',
    [`${keys.stickySurveySuccessText}`]:
      'نشكرك على الإجابة على الاستطلاع ومساعدتنا على تحسين خدمتنا.',
    [`${keys.stickySurveySuccessButtonText}`]: 'غلق',
    [`${keys.placeHolderSurname}`]: 'أدخل اسمك الأخير',
    [`${keys.placeHolderName}`]: 'أدخل اسمك الأول',
    [`${keys.yourEmailText}`]: 'البريد الإلكتروني',
    [`${keys.placeholderEmail}`]: 'أدخل بريدك الإلكتروني',
    [`${keys.languageText}`]: 'اللغة',
    [`${keys.placeholderLanguage}`]: 'اختر لغة',
    [`${keys.deliveryNumberText}`]: 'رقم التوصيل',
    [`${keys.placeholderDeliveryNumber}`]: 'أدخل رقم التوصيل',
    [`${keys.startChatText}`]: 'بدء الدردشة',
    [`${keys.internationalTicketCreateSucceedTitle}`]: 'نشكرك على التواصل معنا',
    [`${keys.internationalTicketCreateSucceedText}`]:
      'لقد استلمنا رسالتك عبر البريد الإلكتروني. سنقوم بفحصها والرجوع إليك قريبًا.',
    [`${keys.internationalTicketCreateFailedTitle}`]: '',
    [`${keys.internationalTicketCreateFailedText}`]:
      'حدث خطأ، يرجى المحاولة مرة أخرى.',
    [`${keys.closeButtonText}`]: 'غلق',
    [`${keys.retryButtonText}`]: 'حاول مرة أخرى',
    [`${keys.placeholderSubject}`]: 'أدخل الموضوع',
    [`${keys.subjectText}`]: 'الموضوع',
    [`${keys.internationalSubjectImageText}`]: 'رفع صور',
    [`${keys.mailDescriptionText}`]: 'اكتب رسالتك بالتفاصيل',
    [`${keys.mailSubmitText}`]: 'إرسال',
    [`${keys.placeHolderDescriptionText}`]: 'أدخل الوصف',
    [`${keys.englishLangText}`]: 'الإنجليزية',
    [`${keys.germanLangText}`]: 'الألمانية',
    [`${keys.englishLangTextShort}`]: 'الإنجليزية',
    [`${keys.selectOrderMsg}`]: 'اختر',
    [`${keys.shipmentNo}`]: 'رقم التوصيل',
    [`${keys.orderSelectedMsg}`]: 'تم اختيار',
    [`${keys.quantityText}`]: 'قطعة',
    [`${keys.claimOrderClaimableIsNotDone}`]: 'يرجى ملء الخانات بالأسفل.',
    [`${keys.claimQuantityText}`]: 'اختر الكمية',
    [`${keys.claimReasonText}`]: 'السبب',
    [`${keys.countAndPriceText}`]: 'الكمية والسعر: ',
    [`${keys.claimOrderReason}`]: 'اختر سبب الإرجاع',
    [`${keys.cancelOrderSelectProduct}`]:
      'يُرجى تحديد المنتج وذِكر سبب الإلغاء.',
    [`${keys.cancelOrderSelectReason}`]: 'الرجاء تحديد سبب إلغاء الطلب',
    [`${keys.selectReason}`]: 'اختار سبب الإلغاء',
    [`${keys.other}`]: 'الشرح',
    [`${keys.explanation}`]: 'أكتب شرحاً عن سبب إلغاء الطلب',
    [`${keys.selectProductImageText}`]: 'رفع صور ({{count}} على الأكثر)',
    [`${keys.internationalMailSelectProductImageText}`]:
      'رفع صور ({{count}} على الأكثر)',
    [`${keys.claimOrderDescription}`]: 'يرجى إضافة تفاصيل',
    [`${keys.claimSimulationPreviewQuantity}`]: 'الكمية',
    [`${keys.claimSimulationPreviewProduct}`]: 'المنتج',
    [`${keys.claimOrderFillToContinue}`]:
      'يرجى ملء خانات سبب الإرجاع والكمية بالكامل من أجل المتابعة.',
    [`${keys.optionalFileInfoText}`]: 'رفع صور (اختياري)',
    [`${keys.imageFormatMismatchErrorText}`]:
      'يمكنك رفع ملفات بصيغة JPG و PNG فقط.',
    [`${keys.imageMaxSizeErrorText}`]:
      'حجم الملف يجب ألا يتجاوز {{size}} ميجابايت.',
    [`${keys.imageCountErrorText}`]: 'يمكنك رفع ما يصل إلى {{count}} ملف.',
    [`${keys.imageReadError}`]: 'لا يمكن معالجة الملف. يرجى المحاولة مرة أخرى.',
    [`${keys.imageUploadError}`]:
      'حدث خطأ أثناء رفع الملف. يرجى المحاولة مرة أخرى.',
    [`${keys.shipmentSubtitleTotal}`]: 'إجمالي المبلغ',
    [`${keys.shipmentSubtitleItems}`]: 'المنتج',
    [`${keys.orderNo}`]: 'رقم الطلب',
    [`${keys.seeAllAnnouncement}`]: 'مشاهدة جميع الإخطارات',
    [`${keys.showMoreTicket}`]: 'عرض المزيد',
    [`${keys.notificationsTitle}`]: 'الإشعارات',
    [`${keys.totalClaimAmountText}`]: 'إجمالي المبلغ المسترد',
    [`${keys.claimAmountText}`]: 'المبلغ المسترد',
    [`${keys.claimCoinAmountText}`]: 'العملة المستردة',
    [`${keys.fileNameNotValidError}`]: 'اسم الملف غير صالح.',
    [`${keys.fileEmptyError}`]: 'محتوى الملف المحمل فارغ.',
    [`${keys.coinText}`]: 'عملة',
    [`${keys.claimStatusCargoText}`]: 'أين طلب الإرجاع الخاص بي؟',
    [`${keys.showMoreShipment}`]: 'عرض المزيد من المنتجات',
    [`${keys.orderText}`]: 'طلباتي',
    [`${keys.ordersText}`]: 'طلباتي',
    [`${keys.currencyText}`]: '€',
    [`${keys.copyText}`]: 'نسخ',
    [`${keys.copiedText}`]: 'تم النسخ',
    [`${keys.deliveryDateText}`]: 'تاريخ التوصيل:',
    [`${keys.paymentInfoText}`]: 'بيانات الدفع',
    [`${keys.subtotalText}`]: 'الإجمالي الفرعي',
    [`${keys.cargoText}`]: 'الشحن',
    [`${keys.codServiceFeeText}`]: 'رسم خدمة الدفع عند التسليم',
    [`${keys.totalText}`]: 'الإجمالي',
    [`${keys.deliveryAddressText}`]: 'عنوان التوصيل',
    [`${keys.orderDate}`]: 'تاريخ الطلب',
    [`${keys.orderSummary}`]: 'ملخص الطلب',
    [`${keys.productText}`]: 'المنتج',
    [`${keys.linkCopied}`]: 'تم نسخ رقم الطلب',
    [`${keys.billingAddressText}`]: 'عنوان الفاتورة',
    [`${keys.detail}`]: 'التفاصيل',
    [`${keys.deliveryTimeText}`]: 'تاريخ التوصيل:',
    [`${keys.addExtraImage}`]: 'أضف صور',
    [`${keys.chatbotErrorMsgKey}`]: 'خطأ!',
    [`${keys.chatbotShortErrorMsg}`]: 'حدث خطأ ما.',
    [`${keys.collectionPointText}`]: 'نقطة الاستلام',
    [`${keys.commercialText}`]: 'فاتورة شركات',
    [`${keys.fastMarketText}`]: 'سوق سريع',
    [`${keys.mealText}`]: 'وجبة',
    [`${keys.groupDealText}`]: 'شراء مع الأصدقاء',
    [`${keys.wardrobeText}`]: 'دولاب',
    [`${keys.inputLanguageNotSupported}`]:
      'الدعم لدينا متاح حاليًا فقط باللغة الإنجليزية. يرجى كتابة رسالتك باللغة الإنجليزية.',
    [`${keys.myOrderDetail}`]: 'تفاصيل طلبي',
    [`${keys.arabicLangText}`]: 'العربية',
    [`${keys.showMoreContent}`]: 'عرض المزيد',
    [`${keys.showLessContent}`]: 'عرض أقل',
    [`${keys.coinUsageMessage}`]: 'لقد استخدمت',
    [`${keys.coinUsageOrderMessage}`]: 'في هذا الطلب!',
    [`${keys.coinsWording}`]: 'قطعة نقدية',
    [`${keys.coinQuantityAndAmountInfo}`]:
      '{{quantity}} {{coinsWording}} ({{amount}} {{currency}})',
    [`${keys.coinEarnedMessage}`]: 'لقد ربحت',
    [`${keys.coinEarnedOrderMessage}`]: 'من هذا الطلب!',
    [`${keys.coinEarnedQuantityAndAmountInfo}`]:
      '{{quantity}} {{coinsWording}} ({{value}} {{currency}})',
    [`${keys.comboboxDefaultValue}`]: 'حدد الحملة',
    [`${keys.packageText}`]: 'الطلبية',
    [`${keys.itemText}`]: 'المنتج',
    [`${keys.userDisconnectedOnBackground}`]:
      'لقد أنهينا هذه المحادثة لأنك غادرت التطبيق خلال المحادثة ولم تتفاعل معنا لفترة طويلة. نشكرك على تفهمك.',
    [`${keys.refundProgressTrInfo}`]: 'أصدرت Trendyol أموالك المردودة',
    [`${keys.refundDetailText}`]: 'تفاصيل الدفع',
    [`${keys.refundProgressBankInfo}`]:
      'في انتظار المعالجة من طرف مؤسستك المالية',
    [`${keys.productImageText}`]: 'صورة المنتج',
    [`${keys.refundAmountText}`]: 'قيمة الأموال المردودة',
    [`${keys.claimLastModifiedDateTrText}`]:
      'Trendyol تاريخ ردّ الأموال من طرف',
    [`${keys.claimLastModifiedDateMillaText}`]:
      'Trendyolmilla تاريخ ردّ الأموال من طرف',
    [`${keys.estimatedRefundDateText}`]: 'فترة استلام الأموال المتوقعة',
    [`${keys.bankRefNoText}`]: 'رقم البنك المرجعي',
    [`${keys.refundProgressTrInfo}`]: 'أصدرت Trendyol أموالك المردودة',
    [`${keys.refundProgressMillaInfo}`]: 'أصدرت Trendyolmilla أموالك المردودة',
  },
};
