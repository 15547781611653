import React from 'react';
import './InfoMessage.scss';
import { ReactComponent as InfoRounded } from '../../../../../../assets/icons/info-rounded.svg';
import ReactMarkdown from 'react-markdown';

const InfoMessage = ({ message }) => {
  return (
    <div className="info-message-wrapper">
      <span className="info-message-wrapper__icon">
        <InfoRounded />
      </span>
      <ReactMarkdown children={message} />
    </div>
  );
};

export default InfoMessage;
