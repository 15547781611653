import appService from './app.service';
import axiosLib from 'axios';
import ChatGatewayService from './chatgateway.service';
import jwt from 'jsonwebtoken';

const mediaCenterUrl = process.env.REACT_APP_MEDIA_CENTER_API_URL;

const axiosGateway = axiosLib.create();

function isTokenExpired(token) {
  const decodedToken = jwt.decode(token, { complete: true });
  const dateNow = new Date();

  return decodedToken.payload.exp < dateNow.getTime() / 1000;
}

axiosGateway.interceptors.request.use(
  async (config) => {
    const isExpiredOrEmpty =
      appService.getMediaCenterToken() !== null
        ? isTokenExpired(appService.getMediaCenterToken())
        : true;

    let token;

    if (isExpiredOrEmpty) {
      const response = await ChatGatewayService.getMediaAccessToken();
      token = response.accessToken;
      appService.setMediaCenterToken(token);
    } else {
      token = appService.getMediaCenterToken();
    }

    config.headers['Authorization'] = token;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

class MediaCenterService {
  static async uploadVideo(file) {
    let bodyFormData = new FormData();
    bodyFormData.set('videofile', file);

    const response = await axiosGateway.post(
      `${mediaCenterUrl}/video-livechat`,
      bodyFormData,
      {
        timeout: process.env.REACT_APP_UPLOAD_VIDEO_TIMEOUT,
      }
    );

    return new Promise(async (resolve, reject) => {
      response && response.data ? resolve(response.data) : reject(response);
    });
  }
}

export default MediaCenterService;
