import React, { useState } from 'react';
import { Divider, message } from 'antd';
import './OrderModalItem.scss';
import 'antd/dist/antd.css';
import classNames from 'classnames';
import classnames from 'classnames';
import utils from '../../../../../utils';
import { useTranslation } from 'react-i18next';
import {
  copiedText,
  copyText,
  deliveryDateText,
  detail,
  linkCopied,
  orderDate,
  orderNo,
  shipmentNo,
} from '../../../../../i18n/keys';
import AttachmentIcon from '../../../../../assets/icons/copy.svg';
import CheckIcon from '../../../../../assets/icons/check.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as CheckIconWhite } from '../../../../../assets/icons/icon_check.svg';
import appService from '../../../../../services/app.service';
import StatusIcon from '../../../../chatAssistant/icons/statusIcon';
import {
  CANCELLED,
  CREATED,
  SUCCESS,
  WARN_YELLOW,
} from '../../../../../constants';
import Chevron2 from '../../../../../assets/icons/chevron-2.svg';
import CustomToast from '../../../../layout/customToast';

const OrderModalItem = ({ order, handleOrderDetail, isCopied, onCopy }) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [imageFailed, setImageFailed] = useState(false);
  const [showDeliveryDateInfo, setShowDeliveryDateInfo] = useState(false);

  const getToastIcon = () => {
    return (
      <span role="img" aria-label="check" className="anticon anticon-check">
        <CheckIconWhite />
      </span>
    );
  };

  const onCopyClicked = () => {
    messageApi.open({
      type: 'info',
      content: t(linkCopied),
      duration: 3,
      icon: getToastIcon(),
    });
    onCopy(order.orderNumber);
  };

  const fallbackImage = () => {
    setImageFailed(true);
  };

  const renderImages = (imageUrls) => {
    return utils.renderShipmentImagesForAvatarComponent(
      imageUrls,
      fallbackImage,
      imageFailed
    );
  };

  return (
    <div className="order-modal-item">
      <CustomToast contextHolder={contextHolder} />

      <div
        className={classNames('info', {
          'info-with-status': order.orderStatus,
        })}>
        <div>
          <div className="order-no">
            <div className="text">{t(orderNo)}:</div>
            <div className="value">{order.orderNumber}</div>
            <CopyToClipboard
              text={order.orderNumber}
              onCopy={() => onCopyClicked()}>
              {isCopied ? (
                <div className="copy copied">
                  <img src={CheckIcon} alt="tick-icon" className="icon" />
                  <div className="text">{t(copiedText)}</div>
                </div>
              ) : (
                <div className="copy">
                  <img src={AttachmentIcon} alt="copy-icon" className="icon" />
                  <div className="text">{t(copyText)}</div>
                </div>
              )}
            </CopyToClipboard>
          </div>
          <div className="order-date-status">
            {order.orderStatus && (
              <div className="status-wrapper">
                <StatusIcon
                  name={(order.orderStatus.typeForUser || '').toLowerCase()}
                  className={classNames({
                    'status-icon': true,
                    'status-icon--success':
                      order.orderStatus.type === SUCCESS ||
                      order.orderStatus.type === CREATED,
                    'status-icon--warn-yellow':
                      order.orderStatus.type === WARN_YELLOW,
                    'status-icon--cancelled':
                      order.orderStatus.type === CANCELLED,
                  })}
                />
                <div
                  className={classNames({
                    'order-shipment-status': true,
                    success: order.orderStatus.type === 'Success',
                    warn: order.orderStatus.type === 'Warn',
                    cancelled: order.orderStatus.type === 'Cancelled',
                    warn_yellow: order.orderStatus.type === 'Warn Yellow',
                  })}>
                  {order.orderStatus.text}
                </div>
              </div>
            )}
            <div
              className={classNames({
                date: true,
                'date-with-status': order.orderStatus,
              })}>
              <span className="text">{t(orderDate)}:</span>
              <span className="value">{order.orderDate}</span>
            </div>
          </div>
          {order.showDetail !== false && (
            <div
              className={classnames([
                'detail-button',
                {
                  dynamic: appService.isDynamicStylingChannel(),
                },
              ])}>
              <div
                className={classnames([
                  'button-text',
                  {
                    dynamic: appService.isDynamicStylingChannel(),
                  },
                ])}
                onClick={() => handleOrderDetail(order)}>
                {t(detail)}
                <span>
                  <img src={Chevron2} alt="copy-icon" className="icon" />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      {order.shipmentPreviewList &&
        order.shipmentPreviewList.map((shipment, i) => (
          <div className="order-shipment-wrapper">
            <div className="order-shipment" key={i}>
              <div className="detail">
                {shipment.status && (
                  <div className="status-wrapper">
                    <StatusIcon
                      name={(shipment.status.typeForUser || '').toLowerCase()}
                      className={classNames({
                        'status-icon': true,
                        'status-icon--success':
                          shipment.status.type === SUCCESS ||
                          shipment.status.type === CREATED,
                        'status-icon--warn-yellow':
                          shipment.status.type === WARN_YELLOW,
                        'status-icon--cancelled':
                          shipment.status.type === CANCELLED,
                      })}
                    />
                    <div
                      className={classNames({
                        'order-shipment-status': true,
                        success: shipment.status.type === 'Success',
                        warn: shipment.status.type === 'Warn',
                        cancelled: shipment.status.type === 'Cancelled',
                        warn_yellow: shipment.status.type === 'Warn Yellow',
                      })}>
                      {shipment.status.text}
                    </div>
                  </div>
                )}
                {showDeliveryDateInfo && shipment.shipmentNumber && (
                  <div className="shipment-date">
                    <div className="text">{t(deliveryDateText)} </div>
                    <div className="value">{order.orderDate}</div>
                  </div>
                )}
                {shipment.shipmentNumber && (
                  <div className="order-shipment-no">
                    <div className="order-shipment-no__container">
                      <div className="text">{t(shipmentNo)}: </div>
                      <div className="value">{shipment.shipmentNumber}</div>
                    </div>
                  </div>
                )}
                {shipment.productName && (
                  <div className="shipment-product-name">
                    <div className="value">{shipment.productName}</div>
                  </div>
                )}
              </div>
              <div className="order-image-container">
                <div className="body">
                  <div className="details">
                    <div className="avatars">
                      {renderImages(shipment.imageUrls)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Divider />
          </div>
        ))}
    </div>
  );
};

export default OrderModalItem;
