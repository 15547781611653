import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';

// initialize i18n for the whole app
import './i18n';

import './custom.polyfill';
import 'normalize.css';
import './styles/_settings.scss';
import './styles/_globals.scss';

import App from './App';

import smoothScroll from 'smoothscroll-polyfill';
import ChatBotContext from './context/chatbot/ChatBotState';
import CommonContext from './context/common/CommonState';
import LiveChatContext from './context/liveChat/LiveChatState';

//Custom store for manage data. Do not delete here.
import './customStore';
import * as queryString from 'query-string';
import appService from './services/app.service';
import Utils from './utils';

import * as Sentry from '@sentry/react';

if (['production'].includes(process.env.REACT_APP_ENV)) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.1,
    ignoreErrors: [
      /^Request failed with status code/i,
      /^Loading chunk [0-9]* failed./i,
      /^Loading *(CSS|css) chunk [0-9]* failed./i,
      'Network Error',
      'ResizeObserver loop limit exceeded',
      'Request aborted',
      /timeout of 0ms exceeded/i,
      /e.originalEvent.origin/g,
      "undefined is not an object (evaluating 'e.originalEvent.origin')",
      'TIMEOUT',
      /timeout of 3000ms exceeded/i,
      /Java bridge method invocation error/i,
    ],
    beforeSend(event) {
      event.tags = {
        ...event.tags,
        state_machine_id: appService.getStateMachineId(),
      };
      return event;
    },
  });
  Sentry.setExtra('state_machine_id', appService.getStateMachineId());
  Sentry.setExtra('assistant_id', appService.getAssistantId());
  window.Sentry = Sentry;
}

smoothScroll.polyfill();

if (queryString.parse(window.location.search).isStickyInitialized === 'false') {
  sessionStorage.clear();
}

appService.clearAndCloseStickyAfterLogout();

const os = Utils.getOS();
document.getElementsByTagName('body')[0].classList.add(os.toLowerCase());

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <CommonContext>
    <ChatBotContext>
      <LiveChatContext>
        <App />
      </LiveChatContext>
    </ChatBotContext>
  </CommonContext>
);
