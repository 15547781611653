import React from 'react';
import { ReactComponent as Ticketcreated } from '../../../assets/icons/ticketStatus/check.svg';
import { ReactComponent as Ticketrecreated } from '../../../assets/icons/ticketStatus/vector.svg';
import { ReactComponent as Ticketinprogress } from '../../../assets/icons/ticketStatus/fill-no.svg';
import { ReactComponent as Ticketclosed } from '../../../assets/icons/ticketStatus/check-double.svg';

const iconTypes = {
  ASSIGNED: Ticketinprogress,
  CREATED: Ticketcreated,
  CLOSED: Ticketclosed,
  CANCELLED: Ticketclosed,
  ONHOLD: Ticketinprogress,
  INPOOL: Ticketinprogress,
  RESOLVED: Ticketclosed,
  REOPENED: Ticketrecreated,
};

const TicketStatusIconComponent = ({ name, ...props }) => {
  let Icon = iconTypes[name];

  if (Icon === undefined) {
    Icon = iconTypes['CREATED'];
  }

  return <Icon {...props} />;
};

export default TicketStatusIconComponent;
