import ComboboxInput from './ComboboxInput';
import TextInput from './TextInput';
import DependantComboboxInput from './DependantComboboxInput';
import FormFileInput from './FormFileInput';
import TextInputArea from './TextInputArea';
import PhoneInput from './PhoneInput';
import DataDisplay from './DataDisplay';
import AssistantMultiValueInput from './MultiValueInput';

export default {
  COMBOBOX: ComboboxInput,
  INPUT: TextInput,
  INPUT_AREA: TextInputArea,
  DEPENDANT_COMBOBOX: DependantComboboxInput,
  FILE_INPUT: FormFileInput,
  PHONE: PhoneInput,
  DATA_DISPLAY: DataDisplay,
  MULTI_VALUE_INPUT: AssistantMultiValueInput,
};
