import './CancelableItem.scss';

import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Select from '../../../../../Select';
import {
  minumumOrderQuantityHeader,
  quantity,
  quantityText,
} from '../../../../../../i18n/keys';
import usePersistentState from '../../../../../common/usePersistentState';
import Alert from '../../../../../layout/alert/Alert';
import Checkbox from '@tx-components/checkbox';

const CancelableItem = ({
  id,
  item,
  listingId,
  onChangeSelectedItem,
  onChangeDoneItem,
  imgSource,
  totalQuantity,
  salesCampaignId,
  shipmentNumber,
  variantName,
}) => {
  const [checked, setChecked] = usePersistentState(id, 'checked', false);
  const [selectedQuantity, setSelectedQuantity] = usePersistentState(
    id,
    'selectedQuantity',
    null
  );
  const [isDone, setIsDone] = usePersistentState(id, 'isDone', false);
  const [selected, setSelected] = usePersistentState(id, 'selected', false);
  const { t } = useTranslation();

  useEffect(() => {
    if (item.minOrderQuantity) {
      setSelectedQuantity(item.quantity);
    } else if (item.quantity === 1) {
      setSelectedQuantity(1);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const done = !!(selectedQuantity && checked);
    setIsDone(done);
    setSelected(done);
    const newItem = {
      listingId,
      quantity: selectedQuantity,
      totalQuantity,
      salesCampaignId,
      shipmentNumber,
      done,
      checked,
    };
    onChangeDoneItem(newItem);

    done && onChangeSelectedItem(listingId, newItem, checked);
    //eslint-disable-next-line
  }, [selectedQuantity, checked]);

  const resetState = () => {
    setSelectedQuantity(null);
  };

  const handleChangeSelected = (e) => {
    const isChecked = e;
    setChecked(isChecked);
    onChangeSelectedItem(listingId, item, isChecked);
    if (!isChecked) {
      resetState();
    } else if (item.quantity === 1) {
      setSelectedQuantity(1);
    }
  };

  const handleChangeQuantity = (value) => {
    setSelectedQuantity(value);
    setChecked(true);
  };

  return (
    <div
      data-testid={'cancelable-item' + listingId}
      className={classNames([
        'orderitem cancelable-item',
        { selected, done: isDone },
        item.minOrderQuantity && 'min-order-info',
      ])}>
      <div className="order-wrapper">
        <div className="order-checkbox">
          <Checkbox
            onChange={handleChangeSelected}
            checked={checked}
            data-testid="item-checkbox"
          />
        </div>
        <div className="product-info">
          <div className="product-image">
            <img className="image" src={imgSource} alt="shipment-product" />
          </div>
          <div className="product-name">
            <div
              data-testid="item-title"
              className={
                item.minOrderQuantity
                  ? 'item-title-min-order-quantity'
                  : 'item-title'
              }>
              {item.title}
            </div>
            <div
              className={
                item.minOrderQuantity
                  ? 'cancelable-item-variant-name-min-order-quantity'
                  : 'cancelable-item-variant-name'
              }>
              {variantName}
            </div>
            {item.minOrderQuantity ? (
              <div className="min-order-quantity-info">
                <Alert
                  message={
                    <none className="message">
                      {t(minumumOrderQuantityHeader)}
                    </none>
                  }
                  background={false}
                  orange
                />
                <div className="min-order-quantity-area">
                  <span className="quantity-label">{t(quantity)}: </span>
                  <span className="quantity-value">{item.quantity}</span>
                </div>
              </div>
            ) : (
              <Select
                options={
                  item.quantity &&
                  new Array(item.quantity).fill(null).map((_, index) => {
                    return {
                      value: index + 1,
                      label: `${index + 1} ${t(quantityText)}`,
                    };
                  })
                }
                isSelectPortal
                small
                placeholder={t(quantityText)}
                value={selectedQuantity || undefined}
                onChange={handleChangeQuantity}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelableItem;
