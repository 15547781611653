import React from 'react';
import ComponentFactory from '../ComponentFactory';

const NextComponent = ({ next, scrollToBottom, showHover, select }) => {
  let component = ComponentFactory.get(next.componentType);
  return (
    <>
      {React.createElement(component, {
        componentData: next.componentData,
        scrollToBottom: scrollToBottom,
        showHover: showHover,
        select: select,
      })}
    </>
  );
};

export default NextComponent;
