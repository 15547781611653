import React, { useContext } from 'react';
import Select from '../../Select';
import './Combobox.scss';
import Utils from '../../../utils';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import { useTranslation } from 'react-i18next';
import { comboboxDefaultValue } from '../../../i18n/keys';
import usePersistentState from '../../common/usePersistentState';
import Bubble from '../../layout/bubble/Bubble';
import ButtonGroup from '../buttonGroup/ButtonGroup';

const Combobox = ({ id, componentData, currentIndex }) => {
  const { t } = useTranslation();
  const chatBotContext = useContext(ChatBotContext);

  const { requestToChatBot, currentChatIndex, totalErrorCount, isRequesting } =
    chatBotContext;

  const [isComboboxSelected, setIsComboboxSelected] = usePersistentState(
    id,
    'isComboboxSelected',
    false
  );
  const [selectedValue, setSelectedValue] = usePersistentState(
    id,
    'selectedValue',
    '-1'
  );
  const { label, buttonName, event, innerLabel } = componentData;

  let shouldSelect = Utils.shouldSelect(
    currentIndex,
    currentChatIndex,
    totalErrorCount,
    isRequesting
  );

  const handleSelectedValue = (value) => {
    setSelectedValue(value);

    if (parseInt(value) !== -1) {
      setIsComboboxSelected(true);
    } else {
      setIsComboboxSelected(false);
    }
  };

  const handleSendRequest = () => {
    if (isComboboxSelected) {
      let request = {
        event: event,
        data: {
          selectedId: selectedValue,
        },
      };

      requestToChatBot(request);
    }
  };

  return (
    <div className="combobox">
      <Bubble titleFragment={label} isBottomRounded={false}>
        <div className="combobox-actions">
          {innerLabel && (
            <div className="combobox-inner-label">{innerLabel}</div>
          )}
          <div className="combobox-option">
            <div className="select-option">
              <Select
                value={selectedValue}
                onChange={handleSelectedValue}
                disabled={!shouldSelect}
                placeholder={t(comboboxDefaultValue)}
                options={componentData.values.map((data) => ({
                  value: data.id,
                  label: data.name,
                }))}
              />
            </div>
          </div>
        </div>
        <ButtonGroup>
          <ButtonGroup.Button
            isSelectable={shouldSelect === false ? false : isComboboxSelected}
            colorType={'primary'}
            data-testid="button-confirm"
            onClick={handleSendRequest}
            outline={false}
            disabled={shouldSelect === false ? true : !isComboboxSelected}>
            {buttonName}
          </ButtonGroup.Button>
        </ButtonGroup>
      </Bubble>
    </div>
  );
};

export default Combobox;
