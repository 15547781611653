import * as keys from './keys';

export default {
  translation: {
    [`${keys.appTitle}`]: 'Trendyol Assistant',
    [`${keys.appTitleTgoApp}`]: 'Asistent',
    [`${keys.appTitleMilla}`]: 'Asistent',
    [`${keys.appTabTitle}`]: 'Trendyol Assistant',
    [`${keys.liveChatTitle}`]: 'Chat live',
    [`${keys.liveChatTitleTgoApp}`]: 'Chat live',
    [`${keys.liveChatTitleMilla}`]: 'Chat live',
    [`${keys.liveChatTabTitle}`]: 'Chat live',
    [`${keys.chatbotErrorMsg}`]:
      'A apărut o eroare de sistem. Te rugăm să încerci din nou.',
    [`${keys.liveChatLoadingMessage}`]:
      'Îți facem legătura cu reprezentantul nostru de Asistență clienți, te rugăm să aștepți...',
    [`${keys.agentsBusyMessage}`]:
      'Momentan, toți reprezentanții noștri sunt ocupați cu alți clienți, te rugăm să aștepți...',
    [`${keys.liveChatConnecting}`]: 'Se conectează...',
    [`${keys.systemJoined}`]: 'Se alocă un reprezentant...',
    [`${keys.agentDisconnected}`]: 'Se alocă un nou reprezentant....',
    [`${keys.agentJoined}`]: 'Online',
    [`${keys.systemJoinedMessage}`]: 'Bine ai venit la Trendyol Live Chat.',
    [`${keys.liveChatOffline}`]: 'Offline',
    [`${keys.makeUploadedContentBigger}`]: 'Arată într-un format mai mare',
    [`${keys.timeOutFirstPart}`]: 'Acest chat se va închide în',
    [`${keys.timeOutSecondPart}`]: 'secunde',
    [`${keys.timeOutThirdPart}`]:
      'pentru că nu ai mai scris nimic de ceva vreme.',
    [`${keys.closeLiveChatButton}`]: 'Închide chat',
    [`${keys.continueLiveChatButton}`]: 'Continuă chat',
    [`${keys.fileNameNotValidError}`]: 'Numele fișierului nu este valid.',
    [`${keys.fileEmptyError}`]: 'Conținutul fișierului încărcat este gol.',
    [`${keys.liveChatRefused}`]:
      'O altă conversație are loc în prezent pe acest cont. Te rugăm să încerci din nou mai târziu, dacă mai este nevoie.',
    [`${keys.maxTimeOutThresholdExceedMessage}`]:
      'Această conversație a fost încheiată automat de către sistem, deoarece a fost inactivă pentru o perioadă de timp. Îți mulțumim pentru înțelegere.',
    [`${keys.agentLeftMessage}`]: 'Reprezentantul a încheiat discuția.',
    [`${keys.chatConnectionErrorMessage}`]:
      'A apărut o eroare, te rugăm să încerci din nou.',
    [`${keys.chatInputBarPlaceholderMessage}`]: 'Scrie un mesaj...',
    [`${keys.resendMediaMessage}`]: 'Te rugăm să încerci din nou.',
    [`${keys.resendMediaMessageForEmptyImage}`]: 'Te rugăm să încarci din nou.',
    [`${keys.unAuthorizedMessage}`]:
      'Nu ai permisiunea să accesezi această pagină.',
    [`${keys.surveyMessage}`]: 'Ești mulțumit(ă) de cum a decurs conversația?',
    [`${keys.trendyolAsistantSurveyMessage}`]:
      'Ești mulțumit(ă) de Trendyol Assistant?',
    [`${keys.surveyCommentMessage}`]: 'Mai dorești să adaugi ceva?',
    [`${keys.surveyRatingMessage}`]:
      'Evaluează-ți experiența cu Trendyol Assistant',
    [`${keys.liveChatSurveyRatingMessage}`]: 'Evaluează reprezentantul',
    [`${keys.surveyRatingBadMessage}`]: 'Foarte slab',
    [`${keys.surveyRatingGoodMessage}`]: 'Foarte bun',
    [`${keys.surveyRateButton}`]: 'Trimite',
    [`${keys.surveyErrorModalTitle}`]: 'A APĂRUT O EROARE DE SONDAJ',
    [`${keys.surveyErrorRatingEmpty}`]: 'Te rugăm să introduci o evaluare.',
    [`${keys.surveyErrorServiceError}`]:
      'A apărut o eroare, te rugăm să încerci din nou.',
    [`${keys.surveySuccessModalTitle}`]: 'EVALUARE REUȘITĂ',
    [`${keys.surveySuccessModalMessage}`]:
      'Îți mulțumim pentru participarea la sondajul nostru!',
    [`${keys.liveChatNewMessageAppTitle}`]: 'Ai primit un mesaj nou...',
    [`${keys.errorPageMessage}`]:
      'A apărut o eroare de sistem. Te rugăm să încerci din nou.',
    [`${keys.closeOnErrorButton}`]: 'Închide',
    [`${keys.reconnectOnErrorButton}`]: 'Reconectare',
    [`${keys.liveChatReconnecting}`]: 'Se reconectează...',
    [`${keys.nameText}`]: 'Prenume',
    [`${keys.lastnameText}`]: 'Numele de familie',
    [`${keys.outOfWorkOurMessage}`]:
      'Reprezentanții serviciului nostru de Asistență clienți sunt în afara programului de lucru. Poți transmite mesajul acum, iar noi îți vom răspunde în cel mai scurt timp posibil.',
    [`${keys.invalidTokenInfoText}`]:
      'Nu putem analiza informațiile. Te rugăm să ieși din aplicație și să te conectezi din nou.',
    [`${keys.newText}`]: 'NOU',
    [`${keys.emailText}`]: 'E-mail',
    [`${keys.continueLiveChatText}`]: 'Înapoi',
    [`${keys.exitLiveChatModalText}`]: 'Sigur dorești să închei conversația?',
    [`${keys.exitAndOpenSurvey}`]: 'Confirm încheierea conversației',
    [`${keys.clientReconnectingMessage}`]:
      'Stimate client, îți vom face din nou legătura cu reprezentantul nostru, din cauza conexiunii lente la internet. Conversația va fi reluată. Te rugăm să aștepți și îți mulțumim pentru înțelegere.',
    [`${keys.clientRedirectedMessage}`]:
      'Stimate client, îți vom face legătura cu un alt reprezentant al echipei noastre, din cauza conexiunii lente la internet. Istoricul conversației pentru acest apel va fi transferat către noul agent. Te rugăm să aștepți și îți mulțumim pentru înțelegere.',
    [`${keys.stickySurveyTitle}`]: 'Ești mulțumit(ă) de Trendyol Assistant?',
    [`${keys.stickySurveyRateMessage}`]: 'Te rugăm să ne evaluezi',
    [`${keys.stickySurveyCommentMessage}`]: 'Mai dorești să adaugi ceva?',
    [`${keys.stickySurveySuccessTitle}`]: 'Am primit evaluarea ta!',
    [`${keys.stickySurveySuccessText}`]:
      'Îți mulțumim pentru evaluarea pe care ne-ai făcut-o, ne va ajuta să ne îmbunătățim serviciile.',
    [`${keys.stickySurveySuccessButtonText}`]: 'Închide',
    [`${keys.placeHolderSurname}`]: 'Introdu numele',
    [`${keys.placeHolderName}`]: 'Introdu prenumele',
    [`${keys.yourEmailText}`]: 'E-mail',
    [`${keys.placeholderEmail}`]: 'Introdu e-mailul',
    [`${keys.languageText}`]: 'Limba',
    [`${keys.placeholderLanguage}`]: 'Selectează o limbă',
    [`${keys.deliveryNumberText}`]: 'Numărul de livrare',
    [`${keys.placeholderDeliveryNumber}`]: 'Introdu numărul de livrare',
    [`${keys.startChatText}`]: 'Deschide discuția / conversația',
    [`${keys.internationalTicketCreateSucceedTitle}`]:
      'Îți mulțumim că ne-ai contactat',
    [`${keys.internationalTicketCreateSucceedText}`]:
      'Am primit e-mailul tău. Ne vom ocupa de el și vom reveni cu un răspuns în curând.',
    [`${keys.internationalTicketCreateFailedTitle}`]: '',
    [`${keys.internationalTicketCreateFailedText}`]:
      'A apărut o eroare, te rugăm să încerci din nou.',
    [`${keys.closeButtonText}`]: 'Închide',
    [`${keys.retryButtonText}`]: 'Încearcă din nou',
    [`${keys.placeholderSubject}`]: 'Introdu subiectul',
    [`${keys.subjectText}`]: 'Subiect',
    [`${keys.internationalSubjectImageText}`]: 'Încarcă imaginile',
    [`${keys.mailDescriptionText}`]: 'Scrie-ți mesajul oferind detalii',
    [`${keys.mailSubmitText}`]: 'Trimite',
    [`${keys.placeHolderDescriptionText}`]: 'Introdu o descriere',
    [`${keys.englishLangText}`]: 'Engleză',
    [`${keys.germanLangText}`]: 'Germană',
    [`${keys.englishLangTextShort}`]: 'ENG',
    [`${keys.selectOrderMsg}`]: 'Selectează',
    [`${keys.shipmentNo}`]: 'Livrare nr.',
    [`${keys.orderSelectedMsg}`]: 'Selectat',
    [`${keys.quantityText}`]: 'Bucată',
    [`${keys.claimOrderClaimableIsNotDone}`]:
      'Te rugăm să completezi câmpurile de mai jos.',
    [`${keys.claimQuantityText}`]: 'Selectează cantitatea',
    [`${keys.claimReasonText}`]: 'Motivul',
    [`${keys.countAndPriceText}`]: 'Cantitate și preț:',
    [`${keys.claimOrderReason}`]: 'Selectează motivul returului',
    [`${keys.selectProductImageText}`]: 'Încarcă imagini (maxim {{count}})',
    [`${keys.internationalMailSelectProductImageText}`]:
      'Încarcă imagini (maxim {{count}})',
    [`${keys.claimOrderDescription}`]: 'Te rugăm să adaugi detalii',
    [`${keys.claimSimulationPreviewQuantity}`]: 'Cantitate',
    [`${keys.claimSimulationPreviewProduct}`]: 'Articol',
    [`${keys.claimOrderFillToContinue}`]:
      'Te rugăm să completezi în totalitate câmpurile privind cantitatea și motivul returnării pentru a putea continua.',
    [`${keys.claimQuantityText}`]: 'Selectează cantitatea',
    [`${keys.optionalFileInfoText}`]: 'Încarcă imagini (opțional)',
    [`${keys.imageFormatMismatchErrorText}`]:
      'Poți încărca doar fișiere JPG și PNG.',
    [`${keys.imageMaxSizeErrorText}`]:
      'Mărimea fișierului nu trebuie să depășească {{size}} MB.',
    [`${keys.imageCountErrorText}`]: 'Poți încărca până la {{count}} fișiere.',
    [`${keys.imageReadError}`]:
      'Fișierul nu a putut fi procesat. Te rugăm să încerci din nou.',
    [`${keys.imageUploadError}`]:
      'A apărut o eroare la încărcarea fișierului. Te rugăm să încerci din nou.',
    [`${keys.shipmentSubtitleTotal}`]: 'Suma totală',
    [`${keys.shipmentSubtitleItems}`]: 'Articol',
    [`${keys.orderNo}`]: 'Nr. comandă',
    [`${keys.seeAllAnnouncement}`]: 'Vezi toate anunțurile',
    [`${keys.showMoreTicket}`]: 'Arată mai mult',
    [`${keys.notificationsTitle}`]: 'Notificări',
    [`${keys.totalClaimAmountText}`]: 'Suma totală rambursată',
    [`${keys.claimAmountText}`]: 'Suma rambursată',
    [`${keys.claimCoinAmountText}`]: 'Trendyol Coins rambursate',
    [`${keys.coinText}`]: 'Trendyol Coins',
    [`${keys.coinUsageMessage}`]: 'Ai folosit',
    [`${keys.coinUsageOrderMessage}`]: 'la această comandă!',
    [`${keys.coinsWording}`]: 'coins',
    [`${keys.coinQuantityAndAmountInfo}`]:
      '{{quantity}} {{coinsWording}} ({{amount}} {{currency}})',
    [`${keys.claimStatusCargoText}`]: 'Unde este returul meu?',
    [`${keys.showMoreShipment}`]: 'Arată mai multe produse',
    [`${keys.showMoreShipment}`]: 'Arată mai multe articole',
    [`${keys.orderText}`]: 'Comenzile mele',
    [`${keys.ordersText}`]: 'Comenzile mele',
    [`${keys.currencyText}`]: '€',
    [`${keys.copyText}`]: 'Copiază',
    [`${keys.copiedText}`]: 'Copiat',
    [`${keys.deliveryDateText}`]: 'Data livrării:',
    [`${keys.paymentInfoText}`]: 'Informații despre plată',
    [`${keys.subtotalText}`]: 'Subtotal',
    [`${keys.cargoText}`]: 'Livrare',
    [`${keys.totalText}`]: 'Total',
    [`${keys.deliveryAddressText}`]: 'Adresa de livrare',
    [`${keys.orderDate}`]: 'Data comenzii',
    [`${keys.orderSummary}`]: 'Rezumatul comenzii',
    [`${keys.productText}`]: 'articol',
    [`${keys.linkCopied}`]: 'Numărul comenzii a fost copiat',
    [`${keys.billingAddressText}`]: 'Adresa de facturare',
    [`${keys.detail}`]: 'Detalii',
    [`${keys.deliveryTimeText}`]: 'Data livrării:',
    [`${keys.addExtraImage}`]: 'Adaugă imagini',
    [`${keys.chatbotErrorMsgKey}`]: 'Eroare!',
    [`${keys.chatbotShortErrorMsg}`]: 'Ceva a mers prost.',
    [`${keys.collectionPointText}`]: 'Punct de preluare',
    [`${keys.commercialText}`]: 'Reducere factură corporativă',
    [`${keys.fastMarketText}`]: 'Piața rapidă',
    [`${keys.mealText}`]: 'Masă',
    [`${keys.groupDealText}`]: 'Cumpără cu prietenii',
    [`${keys.wardrobeText}`]: 'DOLAP',
    [`${keys.inputLanguageNotSupported}`]:
      'În prezent, serviciile noastre de asistență sunt disponibile doar în limba engleză. Te rugăm să ne scrii în limba engleză.',
    [`${keys.myOrderDetail}`]: 'Detalii comandă',
    [`${keys.arabicLangText}`]: 'Arabă',
    [`${keys.showMoreContent}`]: 'Arată mai mult',
    [`${keys.showLessContent}`]: 'Arată mai puțin',
    [`${keys.bulgariaLangText}`]: 'Bulgară',
    [`${keys.polandLangText}`]: 'Poloneză',
    [`${keys.slovakiaLangText}`]: 'Slovacă',
    [`${keys.romaniaLangText}`]: 'Română',
    [`${keys.czechiaLangText}`]: 'Cehă',
    [`${keys.hungaryLangText}`]: 'Maghiară',
    [`${keys.greeceLangText}`]: 'Greacă',
    [`${keys.cancelOrderSelectProduct}`]:
      'Te rugăm să selectezi articolul și motivul anulării.',
    [`${keys.cancelOrderSelectReason}`]:
      'Te rugăm să selectezi motivul anulării',
    [`${keys.selectReason}`]: 'Selectează un motiv',
    [`${keys.other}`]: 'Explicație',
    [`${keys.explanation}`]:
      'Scrie o explicație despre articolul pe care dorești să îl anulezi',
    [`${keys.userDisconnectedOnBackground}`]:
      'Conversația s-a încheiat deoarece ai părăsit aplicația în timpul conversației sau ai avut o perioadă lungă de inactivitate. Îți mulțumim pentru înțelegere.',
    [`${keys.refundDetailText}`]: 'Vezi detaliile de rambursare',
    [`${keys.refundProgressBankInfo}`]:
      'Acțiune în așteptare din partea băncii tale',
    [`${keys.productImageText}`]: 'Imaginea produsului',
    [`${keys.refundAmountText}`]: 'Suma rambursării',
    [`${keys.claimLastModifiedDateTrText}`]:
      'Data la care Trendyol a emis rambursarea ta',
    [`${keys.claimLastModifiedDateMillaText}`]:
      'Data la care Trendyolmilla a emis rambursarea ta',
    [`${keys.estimatedRefundDateText}`]: 'Perioada de rambursare estimată',
    [`${keys.bankRefNoText}`]: 'Numărul de referință al băncii',
    [`${keys.refundProgressTrInfo}`]: 'Trendyol a emis rambursarea ta',
    [`${keys.refundProgressMillaInfo}`]: 'Trendyolmilla a emis rambursarea ta',
  },
};
