import React, { useEffect } from 'react';
import './BaseListingItem.scss';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { orderSelectedMsg, selectOrderMsg } from '../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import utils from '../../../utils';

import usePersistentState from '../../common/usePersistentState';
import appService from '../../../services/app.service';

const BaseListingItem = ({
  id,
  selectedId,
  draft,
  active,
  flex,
  onSelect,
  item,
  isRenderedFromOutside = false,
}) => {
  const {
    status,
    badge,
    label,
    subLabel,
    listingDate,
    listingNumber,
    listingNumberLabel,
    buttonLabel,
    imageUrls = [],
  } = item;

  const [imageFailed, setImageFailed] = usePersistentState(
    id,
    'imageFailed',
    false
  );
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = usePersistentState(
    id,
    'isSelected',
    false
  );
  const [selectable, setSelectable] = usePersistentState(
    id,
    'selectable',
    false
  );

  useEffect(() => {
    if (selectable) {
      setIsSelected(id === selectedId);
    }
  }, [id, selectedId, selectable]);

  useEffect(() => {
    if (active && !draft) {
      setSelectable(true);
      return;
    }
    setSelectable(false);
  }, [active, draft]);

  const onClick = (id, item) => {
    if (selectable) {
      onSelect(id, item);
    }
  };

  const fallbackImage = () => {
    setImageFailed(true);
  };

  const renderImages = (imageUrls) => {
    return utils.renderShipmentImagesForAvatarComponent(
      imageUrls,
      fallbackImage,
      imageFailed
    );
  };

  return (
    <div
      className={classNames({
        'listing-item-container': true,
        'listing-item-container--selected': isSelected,
        'listing-item-container--hover': !draft,
        'listing-item-container--link': selectable,
        'listing-item-container--unlink': !selectable,
        'listing-item-container--flex': flex,
        'listing-item-container--readonly': isRenderedFromOutside,
        dynamic: appService.isDynamicStylingChannel(),
      })}
      onClick={() => onClick(id, item)}
      data-testid="listingitem">
      <div
        className={classNames({
          body: true,
          'body--selected': isSelected,
        })}>
        <div className="details">
          <div className="listing-item-info">
            <div className={'area area--' + status.type}>
              {status.icon}
              <span> {status.text}</span>
            </div>
          </div>
          <div className="avatars">{renderImages(imageUrls)}</div>
          <div
            className={classNames({
              detail: true,
              'detail--flex': flex,
            })}>
            <div
              className={classNames({
                title: true,
                'title--draft': draft,
                'title--flex': flex,
              })}>
              {label}
            </div>
            {subLabel && (
              <div
                className={classNames({
                  subtitle: true,
                  'subtitle--dynamic': appService.isDynamicStylingChannel(),
                  'subtitle--draft': draft,
                })}>
                {subLabel}
              </div>
            )}
          </div>
        </div>
        {(badge || !flex) && (
          <div className="badge">{badge && <span>{badge}</span>}</div>
        )}

        <div className="listing-date">{listingDate}</div>
        <div className="listing-number-info">
          <div className="listing-number-label">
            {listingNumber && <>{listingNumberLabel}:</>}
          </div>
          <div className="listing-number-value">{listingNumber}</div>
        </div>
        <div
          className={classNames({
            pick: true,
            'pick--selected': isSelected,
            'pick--dynamic': appService.isDynamicStylingChannel(),
            'pick--draft': draft,
          })}>
          {buttonLabel ? (
            <div className="title">{buttonLabel}</div>
          ) : (
            <div className="title">
              {isSelected ? t(orderSelectedMsg) : t(selectOrderMsg)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

BaseListingItem.propTypes = {
  status: {
    type: PropTypes.oneOf([
      'success',
      'warn',
      'warn-yellow',
      'error',
      'success--dynamic',
    ]),
  },
};

export default BaseListingItem;
