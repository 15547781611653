import React, { useContext, useEffect, useRef } from 'react';
import './InputBar.scss';
import ChatBotContext from '../../../context/chatbot/chatbotContext';
import classNames from 'classnames';
import AutoTextArea from '../../common/AutoTextArea';
import { ENTER_KEYCODE, PLATFORM_WEB } from '../../../constants';
import appService from '../../../services/app.service';
import SendIcon from '../../../assets/icons/send-icon.png';
import usePersistentState from '../../common/usePersistentState';

const InputBar = React.forwardRef((props, ref) => {
  const chatBotContext = useContext(ChatBotContext);
  const { requestToChatBot } = chatBotContext;
  const { id } = props;
  const { maxLength = '2000', isRenderedFromOutside = false } = props;
  const { button, defaultMessage, placeHolder } = props.componentData;
  const [message, setMessage] = usePersistentState(
    id,
    'message',
    defaultMessage || ''
  );
  const inputBarRef = useRef(null);

  useEffect(() => {
    if (appService.getPlatform() === PLATFORM_WEB) {
      ref.current.focus();
    }

    props.scrollToBottom(300);
  }, []);

  const sendMessage = (e) => {
    e.preventDefault();
    if (message.trim().length >= 3 && !isRenderedFromOutside) {
      const requestPayload = {
        data: {
          message: message,
        },
        event: button.event,
      };
      requestToChatBot(requestPayload);
      setMessage('');
    }
  };

  const onStartTyping = (message) => {
    setMessage(message);
  };

  const onEnterPress = (e) => {
    if (
      e.keyCode === ENTER_KEYCODE &&
      e.shiftKey === false &&
      !isRenderedFromOutside &&
      PLATFORM_WEB === appService.getPlatform()
    ) {
      sendMessage(e);
    }
  };

  useEffect(() => {
    changeHeightHandler();
  }, [message]);

  const changeHeightHandler = () => {
    inputBarRef.current.scrollTo({
      top: inputBarRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className="inputbar-wrapper" ref={inputBarRef}>
      <form className="form-wrapper" onSubmit={(e) => sendMessage(e)}>
        <AutoTextArea
          placeholder={placeHolder}
          className="inputbar"
          maxLength={maxLength}
          onChange={(e) => onStartTyping(e.target.value)}
          parentstyle="inputbar-parent"
          onSubmit={sendMessage}
          message={message}
          setMessage={setMessage}
          ref={ref}
          onKeyDown={onEnterPress}
          changeHeightHandler={changeHeightHandler}
        />
        <button
          type="submit"
          className={classNames({
            'submit-button': true,
            'button-passive':
              message.trim().length < 3 || isRenderedFromOutside,

            dynamic: appService.isDynamicStylingChannel(),
          })}>
          <img
            src={SendIcon}
            alt="submit"
            className={classNames(
              'chat-input-bar__area__actions__button__icon',
              {
                rtl: appService.isRtl(),
              }
            )}
          />
        </button>
      </form>
    </div>
  );
});

export default InputBar;
