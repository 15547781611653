import turkish from './tr';
import deutsch from './de';
import english from './en';
import azerbaijan from './az';
import arabic from './ar';
import greek from './el';
import hungarian from './hu';
import czech from './cs';
import romanian from './ro';
import polish from './pl';
import slovak from './sk';
import bulgarian from './bg';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: english,
  tr: turkish,
  de: deutsch,
  az: azerbaijan,
  ar: arabic,
  el: greek,
  hu: hungarian,
  cs: czech,
  ro: romanian,
  pl: polish,
  sk: slovak,
  bg: bulgarian,
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'tr', // get language parameter from cookie in the future
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
