import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import './TicketList.scss';

import Bubble from '../../layout/bubble/Bubble';
import TicketItem from './TicketItem';
import Chevron2 from '../../../assets/icons/chevron-2.svg';

const TicketList = ({
  id,
  componentData,
  scrollToBottom,
  isRenderedFromOutside = false,
  setShouldResizeIframe = () => {},
}) => {
  const title = componentData.title;
  const items = componentData.items;
  const pageSize = componentData.pageSize;
  const showMoreLabel = componentData.showMoreLabel;

  const [counter, setCounter] = useState(1);

  const groupItems = useMemo(() => {
    const newItems = [];
    let size;
    if (counter * pageSize > items.length) size = items.length;
    else size = counter * pageSize;
    for (let i = 0; i < size; i++) {
      newItems[i] = items[i];
    }
    return newItems;
  }, [items, counter]);

  const renderShownMoreButton = useMemo(() => {
    return items.length > pageSize && groupItems.length !== items.length;
  }, [items, counter]);

  const handleShowMore = () => {
    setCounter(counter + 1);
    handleScroll(100);
    if (isRenderedFromOutside) {
      setShouldResizeIframe(true);
    }
  };

  const handleScroll = (height) => {
    setTimeout(() => {
      try {
        scrollToBottom(height);
      } catch (e) {}
    }, 200);
  };

  return (
    <div className="ticket-main">
      <Bubble isBottomRounded={false} title={title}>
        <div className="ticket-wrapper">
          {groupItems.map((item, i) => {
            return <TicketItem item={item} fromState={true} />;
          })}
        </div>

        {renderShownMoreButton && (
          <div
            className="show_more_text"
            data-testid="static-text"
            onClick={handleShowMore}>
            <p>{showMoreLabel}</p>
            <img src={Chevron2} alt="more-button" />
          </div>
        )}
      </Bubble>
    </div>
  );
};

TicketList.propTypes = {
  componentData: PropTypes.object.isRequired,
};

export default React.memo(TicketList);
