import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Bubble from '../../../layout/bubble/Bubble';
import NumberFormat from 'react-number-format';
import chatBotService from '../../../../services/chatbot.service';
import classnames from 'classnames';
import usePersistentState from '../../../common/usePersistentState';
import Select from '../../../Select/Select';
import './newAddressTicket.scss';
import {
  apartmentNoText,
  buildingNoText,
  cancelBtnText,
  createTicket,
  descriptionText,
  districtText,
  lastnameText,
  nameText,
  neighborhoodText,
  placeHolderApartmentNo,
  placeHolderBuildingNo,
  placeHolderDescription,
  placeHolderDistrict,
  placeHolderName,
  placeHolderNeighborhood,
  placeHolderProvince,
  placeHolderSelectReason,
  placeHolderStreet,
  placeHolderSurname,
  provinceText,
  reasonText,
  street,
  telephoneText,
} from '../../../../i18n/keys';
import {
  ONLY_LETTER_REGEX,
  ONLY_ONE_SPACE_REGEX,
} from '../../../../constants/validationRegexConstants';
import InputLabel from '../../../common/InputLabel';
import TextArea from '../../../common/TextArea';
import ButtonGroup from '../../buttonGroup/ButtonGroup';
import TextInput from '../../../common/TextInput';

const NewAddressTicket = ({ id, componentData, select, isSelectable }) => {
  const { t } = useTranslation();
  const [name, setName] = usePersistentState(id, 'name', '');
  const [lastname, setLastName] = usePersistentState(id, 'lastname', '');
  const [telephone, setTelephone] = usePersistentState(id, 'telephone', '');
  const [provinceName, setProvinceName] = usePersistentState(
    id,
    'provinceName',
    ''
  );
  const [provinceId, setProvinceId] = usePersistentState(
    id,
    'provinceId',
    '-1'
  );
  const [provinceCode, setProvinceCode] = usePersistentState(
    id,
    'provinceCode',
    ''
  );
  const [districtName, setDistrictName] = usePersistentState(
    id,
    'districtName',
    ''
  );
  const [districtId, setDistrictId] = usePersistentState(
    id,
    'districtId',
    '-1'
  );
  const [districts, setDistricts] = usePersistentState(id, 'districts', []);
  const [neighborhoodName, setNeighborhood] = usePersistentState(
    id,
    'neighborhoodName',
    ''
  );
  const [neighborhoodId, setNeighborhoodId] = usePersistentState(
    id,
    'neighborhoodId',
    '-1'
  );
  const [streetPostCode, setStreetPostCode] = usePersistentState(
    id,
    'streetPostCode',
    ''
  );
  const [neighborhoods, setNeighborhoods] = usePersistentState(
    id,
    'neighborhoods',
    []
  );
  const [isActive, setIsActive] = usePersistentState(id, 'isActive', false);
  const [streetName, setStreetName] = usePersistentState(id, 'streetName', '');
  const [buildingNo, setBuildingNo] = usePersistentState(id, 'buildingNo', '');
  const [apartmentNo, setApartmentNo] = usePersistentState(
    id,
    'apartmentNo',
    ''
  );
  const [reasonId, setReasonId] = usePersistentState(id, 'reasonId', '-1');
  const [descriptionAvailable, setDescriptionAvailable] = usePersistentState(
    id,
    'descriptionAvailable',
    false
  );
  const [description, setDescription] = usePersistentState(
    id,
    'description',
    ''
  );
  const [descriptionAvailableMap, setDescriptionAvailableMap] =
    usePersistentState(id, 'descriptionAvailableMap', {});
  const [reasonSelected, setReasonSelected] = usePersistentState(
    id,
    'reasonSelected',
    false
  );

  const MAX_CHARACTER_LIMIT = 240;

  const MAX_CHARACTER_LIMIT_FOR_NAME_SURNAME = 50;

  const MIN_CHARACTER_LIMIT_FOR_DESCRIPTION = 10;

  const MAX_CHARACTER_LIMIT_FOR_DESCRIPTION = 496;

  useEffect(() => {
    checkNewAddress();
  });

  useEffect(() => {
    const availableMap = {};
    componentData.reasons
      .filter((reason) => reason.descriptionAvailable)
      .forEach((reason) => {
        availableMap[reason.id] = true;
      });
    setDescriptionAvailableMap(availableMap);
  }, []);

  const getProvince = () => {
    const cities = [];
    componentData.cities.map((province) =>
      cities.push({
        value: province.id,
        label: province.name,
      })
    );
    return cities;
  };

  const getReasons = () => {
    const reasons = [];
    componentData.reasons.map((reason) =>
      reasons.push({
        value: reason.id,
        label: reason.name,
      })
    );
    return reasons;
  };

  const getDistrict = () => {
    let districtList = [];
    districts.map((district) =>
      districtList.push({
        value: district.id,
        label: district.name,
      })
    );
    return districtList;
  };

  const getNeighborhood = () => {
    let neighborhoodsList = [];
    neighborhoods.map((street) =>
      neighborhoodsList.push({
        value: street.id,
        label: street.name,
      })
    );
    return neighborhoodsList;
  };

  const checkNewAddress = () => {
    let descError;

    if (descriptionAvailable) {
      if (
        description !== '' &&
        description.length > MIN_CHARACTER_LIMIT_FOR_DESCRIPTION &&
        description.length < MAX_CHARACTER_LIMIT_FOR_DESCRIPTION
      ) {
        descError = false;
      } else {
        descError = true;
      }
    } else {
      descError = false;
    }

    if (
      name !== '' &&
      lastname !== '' &&
      telephone.length === 9 &&
      provinceId !== '-1' &&
      districtId !== '-1' &&
      neighborhoodId !== '-1' &&
      streetName !== '' &&
      buildingNo !== '' &&
      apartmentNo !== '' &&
      reasonSelected &&
      !descError
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const handleNewAddress = () => {
    if (isSelectable && isActive) {
      setIsActive(false);
      let request = {
        event: componentData.event,
        data: {
          reasonId: reasonId,
          addressModel: {
            cityId: provinceId,
            city: provinceName,
            cityCode: provinceCode,
            districtId: districtId,
            district: districtName,
            neighborhoodId: neighborhoodId,
            neighborhood: neighborhoodName,
            phone: '05' + telephone,
            firstName: name,
            lastName: lastname,
            postalCode: streetPostCode,
            street: streetName,
            buildingNo: buildingNo,
            apartmentNo: apartmentNo,
            description: descriptionAvailable ? description : '',
          },
        },
      };
      select(request);
    }
  };

  const handleTelephone = (e) => {
    setTelephone(e.value);
  };

  const handleCancelBtn = () => {
    if (isSelectable) {
      setIsActive(false);

      let request = {
        event: componentData.cancelEvent,
      };
      select(request);
    }
  };

  const handleProvince = (id) => {
    let city = componentData.cities.find((city) => city.id === id);
    setProvinceId(id);
    setProvinceName(city.name);
    setProvinceCode(city.code);
    setDistrictId('-1');
    setDistrictName('');
    setNeighborhoodId('-1');
    setNeighborhood('');
    chatBotService
      .getDistricts(id)
      .then((response) => {
        setDistricts(response);
      })
      .catch(() => {});
  };

  const handleNeighborhood = (id) => {
    let neighborhood = neighborhoods.find((city) => city.id === id);
    if (neighborhood) {
      setNeighborhood(neighborhood.name);
      setNeighborhoodId(id);
      setStreetPostCode(neighborhood.postCode);
    }
  };

  const handleDistricts = (id) => {
    let district = districts.find((d) => d.id === id);

    setDistrictId(id);
    setDistrictName(district.name);
    setNeighborhoodId('-1');
    setNeighborhood('');
    chatBotService
      .getNeighborhoods(provinceId, id)
      .then((response) => {
        setNeighborhoods(response);
      })
      .catch(() => {});
  };

  const handleDescription = (value) => {
    if (value.length >= MAX_CHARACTER_LIMIT_FOR_DESCRIPTION) {
      value = value.substring(0, MAX_CHARACTER_LIMIT_FOR_DESCRIPTION);
    }
    let newValue = value.replace(ONLY_ONE_SPACE_REGEX, '');
    setDescription(newValue);
  };

  const handleApartmentNo = (value) => {
    let parsedText = handleLongText(value);
    let newValue = parsedText.replace(ONLY_ONE_SPACE_REGEX, '');
    setApartmentNo(newValue);
  };

  const handleBuildingNo = (value) => {
    let parsedText = handleLongText(value);
    let newValue = parsedText.replace(ONLY_ONE_SPACE_REGEX, '');
    setBuildingNo(newValue);
  };

  const handleStreetName = (value) => {
    let parsedText = handleLongText(value);
    let newValue = parsedText.replace(ONLY_ONE_SPACE_REGEX, '');
    setStreetName(newValue);
  };

  const handleName = (value) => {
    if (value.length >= MAX_CHARACTER_LIMIT_FOR_NAME_SURNAME) {
      value = value.substring(0, MAX_CHARACTER_LIMIT_FOR_NAME_SURNAME);
    }
    let newValue = value.replace(ONLY_LETTER_REGEX, '');
    newValue = newValue.replace(ONLY_ONE_SPACE_REGEX, '');
    setName(newValue);
  };

  const handleLastname = (value) => {
    if (value.length >= MAX_CHARACTER_LIMIT_FOR_NAME_SURNAME) {
      value = value.substring(0, MAX_CHARACTER_LIMIT_FOR_NAME_SURNAME);
    }
    let newValue = value.replace(ONLY_LETTER_REGEX, '');
    newValue = newValue.replace(ONLY_ONE_SPACE_REGEX, '');
    setLastName(newValue);
  };

  const handleLongText = (value) => {
    if (value.length >= MAX_CHARACTER_LIMIT) {
      return value.substring(0, MAX_CHARACTER_LIMIT).trim();
    }
    return value;
  };

  const onKeyDown = (e) => {
    let nameAttr = e.target.name;

    if (e.keyCode === 8) {
      if (e.target.value.length > MAX_CHARACTER_LIMIT_FOR_NAME_SURNAME) {
        let newValue = e.target.value.substring(0, e.target.value.length - 1);
        if (nameAttr === 'name') {
          setName(newValue);
        } else if (nameAttr === 'lastname') {
          setLastName(newValue);
        }
      }
    }
  };

  const onKeyDownForApartmentBuildingStreet = (e) => {
    let nameAttr = e.target.name;

    if (e.keyCode === 8) {
      if (e.target.value.length > MAX_CHARACTER_LIMIT) {
        let newValue = e.target.value.substring(0, e.target.value.length - 1);
        if (nameAttr === 'buildingNo') {
          setBuildingNo(newValue);
        } else if (nameAttr === 'apartmentNo') {
          setApartmentNo(newValue);
        } else if (nameAttr === 'street') {
          setStreetName(newValue);
        }
      }
    }
  };

  const handleReason = (id) => {
    let selectedReasonId = componentData.reasons.find(
      (reason) => reason.id === id
    );

    setReasonId(selectedReasonId.id);
    setDescriptionAvailable(descriptionAvailableMap[selectedReasonId.id]);
    setReasonSelected(true);
  };

  return (
    <div
      className={classnames([
        'new-address-ticket-wrapper',
        { disabled: !isSelectable },
      ])}>
      <Bubble isBottomRounded={false} title={componentData.title}>
        <div className="reasons">
          <Select
            options={getReasons()}
            label={t(reasonText)}
            required
            value={reasonId}
            onChange={handleReason}
            disabled={!isSelectable}
            placeholder={t(placeHolderSelectReason)}
            data-testid="select-reason"
          />
        </div>
        {descriptionAvailable && (
          <div className="description">
            <InputLabel text={t(descriptionText)} required />
            <TextArea
              data-testid="description"
              value={description}
              placeholder={t(placeHolderDescription)}
              onChange={(e) => handleDescription(e.target.value)}
              disabled={!isSelectable}
            />
          </div>
        )}
        <TextInput
          label={t(nameText)}
          required
          name="name"
          data-testid="name"
          value={name}
          placeholder={t(placeHolderName)}
          onKeyDown={onKeyDown}
          onChange={(e) => handleName(e.target.value)}
          disabled={!isSelectable}
        />
        <TextInput
          label={t(lastnameText)}
          required
          name="lastname"
          data-testid="lastname"
          value={lastname}
          placeholder={t(placeHolderSurname)}
          onKeyDown={onKeyDown}
          onChange={(e) => handleLastname(e.target.value)}
          disabled={!isSelectable}
        />
        <div className="telephone">
          <InputLabel text={t(telephoneText)} required />
          <NumberFormat
            data-testid="telephone"
            disabled={!isSelectable}
            className="assistant-input"
            format="0 (5##) ### ## ##"
            mask="_"
            onValueChange={(value) => handleTelephone(value)}
            allowEmptyFormatting
          />
        </div>
        <div className="province-district">
          <div className="province">
            <Select
              options={getProvince()}
              data-testid="select-province"
              placeholder={t(placeHolderProvince)}
              label={t(provinceText)}
              required
              value={provinceId}
              onChange={handleProvince}
              disabled={!isSelectable}
            />
          </div>
          <div className="district">
            <Select
              options={getDistrict()}
              data-testid="select-district"
              placeholder={t(placeHolderDistrict)}
              label={t(districtText)}
              required
              value={districtId === '-1' ? undefined : districtId}
              onChange={handleDistricts}
              disabled={!isSelectable}
            />
          </div>
        </div>
        <div className="neighborhood">
          <InputLabel />
          <Select
            options={getNeighborhood()}
            data-testid="select-neighborhood"
            placeholder={t(placeHolderNeighborhood)}
            label={t(neighborhoodText)}
            required
            value={neighborhoodId === '-1' ? undefined : neighborhoodId}
            onChange={handleNeighborhood}
            disabled={!isSelectable}
          />
        </div>
        <TextInput
          label={t(street)}
          required
          name="street"
          data-testid="street"
          value={streetName}
          placeholder={t(placeHolderStreet)}
          onKeyDown={onKeyDownForApartmentBuildingStreet}
          onChange={(e) => handleStreetName(e.target.value)}
          disabled={!isSelectable}
        />
        <div className="building-apartment">
          <TextInput
            label={t(buildingNoText)}
            required
            name="buildingNo"
            data-testid="buildingNo"
            value={buildingNo}
            placeholder={t(placeHolderBuildingNo)}
            onKeyDown={onKeyDownForApartmentBuildingStreet}
            onChange={(e) => handleBuildingNo(e.target.value)}
            disabled={!isSelectable}
          />
          <TextInput
            label={t(apartmentNoText)}
            required
            name="apartmentNo"
            data-testid="apartmentNo"
            value={apartmentNo}
            placeholder={t(placeHolderApartmentNo)}
            onKeyDown={onKeyDownForApartmentBuildingStreet}
            onChange={(e) => handleApartmentNo(e.target.value)}
            disabled={!isSelectable}
          />
        </div>
        <ButtonGroup>
          <ButtonGroup.Button
            isSelectable={isSelectable && isActive}
            colorType={'primary'}
            data-testid="button-select-registered"
            onClick={handleNewAddress}
            outline={false}
            disabled={!isSelectable || !isActive}>
            {t(createTicket)}
          </ButtonGroup.Button>
          <ButtonGroup.Button
            isSelectable={true}
            data-testid="button-confirm"
            onClick={handleCancelBtn}
            outline={true}
            disabled={false}>
            {t(cancelBtnText)}
          </ButtonGroup.Button>
        </ButtonGroup>
      </Bubble>
    </div>
  );
};

export default NewAddressTicket;
