import React from 'react';
import '../OrderModalDetail.scss';
import * as PropTypes from 'prop-types';
import {
  currencyText,
  orderDate,
  orderNo,
  orderSummary,
  productText,
  totalText,
} from '../../../../../../i18n/keys';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import appService from '../../../../../../services/app.service';
import PriceLabel from '../../../../../common/currency/priceLabel';

const OrderDetailInfo = ({ order }) => {
  const { t } = useTranslation();

  const getPriceWithCurrency = (price, currency) => {
    const isInternational = appService.isInternational();
    if (isInternational) {
      return (
        <>
          {currency ? (
            <>
              {price} {currency}
            </>
          ) : (
            <>
              {t(currencyText)} {price}
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {price} {t(currencyText)}
        </>
      );
    }
  };

  return (
    <div className="order-detail-info">
      <div className="order-detail-no">
        <div className="text">{t(orderNo)}:</div>
        <div className="value">#{order.orderNumber}</div>
      </div>
      <div className="order-detail-date">
        <div className="text">{t(orderDate)}:</div>
        <div className="value">{order.orderDate}</div>
      </div>
      <div className="order-detail-summary">
        <div className="text">{t(orderSummary)}:</div>
        <div className="value">
          {order.orderCount} {t(productText)}
        </div>
      </div>
      <div className="order-detail-total-payment">
        <div className="text">{t(totalText)}:</div>
        <div
          className={classnames([
            'total-price',
            {
              dynamic: appService.isDynamicStylingChannel(),
            },
          ])}>
          <PriceLabel price={order.orderPrice} currency={order.currency} />
        </div>
      </div>
    </div>
  );
};

OrderDetailInfo.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderDetailInfo;
