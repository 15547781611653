const RightArrowIcon = (props) => {
  const { className } = props;
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      className={className}
      style={{ 'enable-background': 'new 0 0 512 512' }}>
      <g>
        <path d="M163.8,511.9c-8.5,0-16.8-3-23.4-8.4c-15.5-12.9-17.7-35.9-4.8-51.4l0.1-0.1l163.5-195.6l-157.6-196 c-12.7-15.6-10.3-38.6,5.3-51.3l0.1-0.1c15.1-13.3,38.2-11.8,51.5,3.3l1.8,2.2l176.2,218.9c11.1,13.5,11.1,32.9,0,46.3L194,498.8 C186.6,507.7,175.4,512.6,163.8,511.9z" />
      </g>
    </svg>
  );
};

export default RightArrowIcon;
