import React from 'react';
import ReactMarkdown from 'react-markdown';
import './Campaign.scss';
import RightArrow from '../../../../assets/icons/right-arrow-white.svg';
import appService from '../../../../services/app.service';
import ChatBotService from '../../../../services/chatbot.service';

const Campaign = ({ data }) => {
  const { items } = data;
  const handleCampaignLink = (item) => {
    sendEventData(item);

    appService.navigateOnParentHost(item.hyperlink);
  };

  const sendEventData = (campaign) => {
    const executeEventRequest = {
      event: campaign.event,
      data: {
        eventLabel: campaign.eventLabel,
        eventAction: campaign.eventAction,
      },
    };

    ChatBotService.execute(executeEventRequest);
  };

  return (
    <div className="campaign">
      {items &&
        items.map((item, i) => {
          return (
            <div
              onClick={() => handleCampaignLink(item)}
              className="item"
              key={i}
              style={{
                fontSize: item.fontSize,
                backgroundImage: `url(${item.backgroundImageUrl})`,
                backgroundColor: item.backgroundColor,
                fontStyle: item.fontStyle,
                fontWeight: item.fontWeight,
                color: item.fontColor,
              }}>
              <div className="icon-title-wrapper">
                {item.iconUrl && (
                  <div className="campaign-icon-wrapper">
                    <img
                      className="campaign-icon"
                      src={item.iconUrl}
                      alt="campaign-icon"
                    />
                  </div>
                )}
                <div className="title">
                  <ReactMarkdown children={item.text} />
                </div>
              </div>
              <div className="arrow-wrapper">
                <img src={RightArrow} className="arrow" alt="arrow" />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Campaign;
