import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import './ProductList.scss';

import Bubble from '../../layout/bubble/Bubble';
import ChevronDown from '../../../assets/icons/chevron-2.svg';
import ChevronUp from '../../../assets/icons/chevron-up.svg';
import ProductItem from './ProductItem';

const ProductList = ({
  componentData,
  scrollToBottom,
  isRenderedFromOutside = false,
  setShouldResizeIframe = () => {},
}) => {
  const label = componentData.label;
  const items = componentData.items;
  const pageSize = componentData.pageSize;
  const showMoreLabel = componentData.showMoreLabel;
  const showLessLabel = componentData.showLessLabel;
  const moreRecordThanPage = pageSize < items.length;

  const [shownMore, setShownMore] = useState(false);

  const shownItems = useMemo(() => {
    let shownItemLength = items.length;
    if (moreRecordThanPage && !shownMore) {
      shownItemLength = pageSize;
    }
    return items.slice(0, shownItemLength);
  }, [items, pageSize, shownMore, moreRecordThanPage]);

  const handleShowMore = () => {
    if (isRenderedFromOutside) {
      setShouldResizeIframe(true);
    }
    setShownMore(true);
    handleScroll(100);
  };

  const handleShowLess = () => {
    if (isRenderedFromOutside) {
      setShouldResizeIframe(true);
    }
    setShownMore(false);
    handleScroll(-100);
  };

  const handleScroll = (height) => {
    setTimeout(() => {
      try {
        scrollToBottom(height);
      } catch (e) {}
    }, 200);
  };

  return (
    <div className="products-main">
      <Bubble isBottomRounded={false} title={label}>
        <div className="products-wrapper">
          {shownItems.map((item, i) => {
            return (
              <ProductItem key={item.productId} item={item} fromState={true} />
            );
          })}
        </div>

        {moreRecordThanPage &&
          (shownMore ? (
            <div
              className="show_more_text"
              data-testid="static-text"
              onClick={handleShowLess}>
              <p>{showLessLabel}</p>
              <img src={ChevronUp} alt="more-button" />
            </div>
          ) : (
            <div
              className="show_more_text"
              data-testid="static-text"
              onClick={handleShowMore}>
              <p>{showMoreLabel}</p>
              <img src={ChevronDown} alt="more-button" />
            </div>
          ))}
      </Bubble>
    </div>
  );
};

ProductList.propTypes = {
  componentData: PropTypes.object.isRequired,
};

export default React.memo(ProductList);
