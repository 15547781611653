import React from 'react';
import './TextArea.scss';

const TextArea = ({ value, placeholder, required = false, ...rest }) => {
  return (
    <div className="textarea-container">
      {!value && (
        <span className="placeholder">
          {required && <span className="assistant-mandatory">*</span>}
          {placeholder}
        </span>
      )}
      <textarea className="assistant-textarea" {...rest} value={value} />
    </div>
  );
};

export default TextArea;
