import React, { useMemo } from 'react';

export default function useRules(item) {
  return useMemo(() => {
    const rules = [];
    if (item.rules && item.rules.length) {
      for (const rule of item.rules) {
        const filteredRule = { ...rule };

        Object.keys(filteredRule).forEach((key) => {
          if (filteredRule[key] === null || filteredRule[key] === undefined) {
            delete filteredRule[key];
          }
        });

        if (filteredRule.pattern) {
          filteredRule.pattern = new RegExp(filteredRule.pattern);
        }

        // java does not allow enum as prop
        if (filteredRule.enumValues) {
          filteredRule.enum = filteredRule.enumValues;
          delete filteredRule.enumValues;
        }
        rules.push(filteredRule);
      }
    }
    return rules;
  }, [item]);
}
