import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Bubble from '../../../layout/bubble/Bubble';
import NumberFormat from 'react-number-format';
import Select from '../../../Select';
import classnames from 'classnames';
import { EMAIL_REGEX } from '../../../../constants/validationRegexConstants';
import './Contact.scss';
import {
  contactDescription,
  contactTypeText,
  emailText,
  nameText,
  lastnameText,
  telephoneText,
} from '../../../../i18n/keys';
import usePersistentState from '../../../common/usePersistentState';
import InputLabel from '../../../common/InputLabel';
import TextArea from '../../../common/TextArea';
import TextInput from '../../../common/TextInput';
import ButtonGroup from '../../buttonGroup/ButtonGroup';

const Contact = ({ id, componentData, select, isSelectable }) => {
  const { t } = useTranslation();
  const [firstName, setFirstname] = usePersistentState(
    id,
    'firstName',
    componentData.firstName
  );
  const [lastName, setLastname] = usePersistentState(
    id,
    'lastName',
    componentData.lastName
  );
  const [contactType, setContactType] = usePersistentState(
    id,
    'contactType',
    componentData.selectedSellerContactType
  );
  const [email, setEmail] = usePersistentState(id, 'email', componentData.mail);
  const [telephone, setTelephone] = usePersistentState(
    id,
    'telephone',
    componentData.telephone
  );
  const [description, setDescription] = usePersistentState(
    id,
    'description',
    componentData.description
  );
  const [isModified, setIsModified] = usePersistentState(
    id,
    'isModified',
    false
  );
  const [isActive, setIsActive] = usePersistentState(id, 'isActive', false);

  useEffect(() => {
    checkContact();
  });

  const getSelectedContactType = (type) => {
    setIsModified(true);

    const selectedContactTypes = componentData.contactTypes.filter(
      (contactType) => contactType.type === type
    );
    if (!selectedContactTypes) {
      setContactType(null);
    }
    setContactType(selectedContactTypes[0].type);
  };

  const getContactTypes = () => {
    const contactTypes = [];
    componentData.contactTypes.map((contactType) => {
      contactTypes.push({
        value: contactType.type,
        label: contactType.name,
      });
    });
    return contactTypes;
  };

  const checkContact = () => {
    if (
      isModified === true &&
      firstName !== '' &&
      lastName !== '' &&
      telephone !== null &&
      telephone.length === 10 &&
      validateEmail(email) &&
      contactType !== -1 &&
      (description === null || description.length <= 248)
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  function validateEmail(email) {
    return EMAIL_REGEX.test(String(email).toLowerCase());
  }

  const handleContact = () => {
    if (isSelectable && isActive) {
      setIsActive(false);
      let request = {
        event: componentData.event,
        data: {
          contactModel: {
            firstName: firstName,
            lastName: lastName,
            telephone: telephone,
            email: email,
            description: description,
            contactType: contactType,
          },
        },
      };
      select(request);
    }
  };

  const handleTelephone = (e) => {
    setIsModified(true);
    setTelephone(e.value);
  };

  const handleCancelButton = (event) => {
    if (isSelectable) {
      setIsActive(false);
      let request = {
        event: event,
      };
      select(request);
    }
  };

  const handleDescription = (value) => {
    setIsModified(true);
    if (description.length <= 248) {
      setDescription(value);
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 8) {
      if (description.length > 248) {
        let newContact = description.substring(0, description.length - 1);
        setDescription(newContact);
      }
    }
  };

  function handleFirstname(value) {
    setIsModified(true);
    setFirstname(value);
  }

  function handleLastname(value) {
    setIsModified(true);
    setLastname(value);
  }
  function handleEmail(value) {
    setIsModified(true);
    setEmail(value);
  }

  return (
    <div
      className={classnames([
        'new-contact-wrapper',
        { disabled: !isSelectable },
      ])}>
      <Bubble title={componentData.label} isBottomRounded={false}>
        <div className="contact-type">
          <Select
            options={getContactTypes()}
            className="assistant-select"
            label={t(contactTypeText)}
            required
            placeholder={t(contactTypeText)}
            onChange={getSelectedContactType}
            value={contactType}
            disabled={!isSelectable}
          />
        </div>
        <TextInput
          label={t(nameText)}
          required
          value={firstName}
          placeholder={t(nameText)}
          onChange={(e) => handleFirstname(e.target.value)}
          disabled={!isSelectable}
        />
        <TextInput
          label={t(lastnameText)}
          required
          value={lastName}
          placeholder={t(lastnameText)}
          onChange={(e) => handleLastname(e.target.value)}
          disabled={!isSelectable}
        />
        <TextInput
          label={t(emailText)}
          required
          value={email}
          placeholder={'Email'}
          onChange={(e) => handleEmail(e.target.value)}
          disabled={!isSelectable}
        />
        <div className="telephone">
          <InputLabel text={t(telephoneText)} required />
          <NumberFormat
            disabled={!isSelectable}
            className="assistant-input"
            format="0 (###) ### ## ##"
            mask="_"
            value={telephone}
            onValueChange={(value) => handleTelephone(value)}
            allowEmptyFormatting
          />
        </div>
        <div className="description">
          <InputLabel text={t(contactDescription)} />
          <TextArea
            placeholder={t(contactDescription)}
            onChange={(e) => handleDescription(e.target.value)}
            onKeyDown={onKeyDown}
            disabled={!isSelectable}
            value={description}
          />
        </div>
        <ButtonGroup>
          <ButtonGroup.Button
            isSelectable={isSelectable && isActive}
            colorType="primary"
            onClick={handleContact}
            outline={false}
            disabled={!isSelectable || !isActive}>
            {componentData.buttons[0].text}
          </ButtonGroup.Button>
          <ButtonGroup.Button
            isSelectable={true}
            onClick={() => handleCancelButton(componentData.buttons[1].event)}
            outline={true}
            disabled={false}>
            {componentData.buttons[1].text}
          </ButtonGroup.Button>
        </ButtonGroup>
      </Bubble>
    </div>
  );
};

export default Contact;
