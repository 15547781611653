import './AzCountryChange.scss';
import AzCircleFlag from '../../../../assets/icons/az-circle-flag.svg';
import TrCircleFlag from '../../../../assets/icons/tr-circle-flag.svg';
import TrRectFlag from '../../../../assets/icons/tr-rect-flag.svg';
import AzRectFlag from '../../../../assets/icons/az-rect-flag.svg';
import { ReactComponent as Check2 } from '../../../../assets/icons/check-2.svg';
import { ReactComponent as Chevron3 } from '../../../../assets/icons/chevron-3.svg';
import { ReactComponent as LeftArrow } from '../../../../assets/icons/arrow-2.svg';
import Utils from '../../../../utils';
import classNames from 'classnames';
import appService from '../../../../services/app.service';
import {
  azLanguageDescription,
  azerbeijan,
  changeShipmentCountry,
  delivery,
  englishLangTextShort,
  shipmentCountry,
  trLanguageDescription,
  turkey,
} from '../../../../i18n/keys';
import {
  ENGLISH_SUPPORT_VERSION_2,
  GET_ENGLISH_SUPPORT,
  GET_INITIAL,
  GET_SHIPMENT_DETAIL,
  LANGUAGE_CODE_EN,
} from '../../../../constants/chatAssistantConstant';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import ChatBotContext from '../../../../context/chatbot/chatbotContext';
import CommonContext from '../../../../context/common/commonContext';
import BellIcon from '../../../../assets/icons/bell.svg';
import _ from 'lodash';
import ENFlag from '../../../../assets/icons/en-flag.svg';
import BottomSheet from '../../../common/BottomSheet/BottomSheet';

const COUNTRY_LIST = [
  {
    countryCode: 'az',
    name: azerbeijan,
    flag: AzCircleFlag,
    rectFlag: AzRectFlag,
    description: azLanguageDescription,
  },
  {
    countryCode: 'tr',
    name: turkey,
    flag: TrCircleFlag,
    rectFlag: TrRectFlag,
    description: trLanguageDescription,
  },
];

const AzCountryChange = ({ languages, openedFromSticky = false }) => {
  const { t, i18n } = useTranslation();
  const chatBotContext = useContext(ChatBotContext);
  const {
    setIsOpenNotification,
    isOpenNotification,
    notifications,
    isShowLanguage,
    requestToChatBot,
    clearChatHistory,
  } = chatBotContext;

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  const commonContext = useContext(CommonContext);
  const { nextStateDelayTimeoutId } = commonContext;

  const onChangeLanguage = async (languageCode) => {
    if (i18n.language === languageCode) return;
    const request = {
      event: 'START',
    };
    const eventName = appService.getEventName();
    if (GET_SHIPMENT_DETAIL === eventName) {
      appService.setEventName(GET_SHIPMENT_DETAIL);
    } else {
      appService.setEventName(GET_INITIAL);
    }

    const acceptedLanguage = Utils.getAcceptepLanguage(languageCode);
    appService.setLanguage(acceptedLanguage);
    await i18n.changeLanguage(acceptedLanguage);

    if (LANGUAGE_CODE_EN === acceptedLanguage) {
      appService.setPrevStateMachineId(appService.getStateMachineId());
    }

    clearChatHistory();
    window.clearTimeout(nextStateDelayTimeoutId);
    requestToChatBot(request);

    setIsBottomSheetOpen(false);
  };

  const onEngSupportClick = async () => {
    const request = {
      event: GET_ENGLISH_SUPPORT,
    };

    requestToChatBot(request);
  };

  const onNotificationClicked = async () => {
    if (notifications && !_.isEmpty(notifications)) {
      if (notifications?.tabs && notifications?.tabs.length > 0) {
        setIsOpenNotification(!isOpenNotification);
      }
    }
  };

  return (
    <div
      className={classNames('az-country-change', {
        'sticky-submenu': openedFromSticky,
        'mobile-submenu': !openedFromSticky,
        'hide-submenu': isOpenNotification && !openedFromSticky,
      })}>
      {!isOpenNotification ? (
        <div className="subheader-row">
          <div
            className="shipment-country-select"
            onClick={() => {
              setIsBottomSheetOpen(true);
            }}>
            <span className="delivery-country-name">{t(shipmentCountry)}</span>
            <div>
              <img
                src={
                  COUNTRY_LIST.find(
                    (country) => country.countryCode === i18n.language
                  )?.flag
                }
                alt="country-flag"
                className="country-flag"
              />
              <span className="country-name">
                {t(
                  COUNTRY_LIST.find(
                    (country) => country.countryCode === i18n.language
                  )?.name
                ) || '-'}
              </span>
            </div>
            {openedFromSticky && <Chevron3 stroke="#f27a1a" />}
          </div>
          {openedFromSticky ? (
            <div className="chat-bot-header-columns">
              {Utils.isLanguageTr() && (
                <div
                  className={classNames([
                    'language-wrapper',
                    {
                      invisible: !isShowLanguage,
                    },
                  ])}
                  onClick={() =>
                    appService.englishSupport().version ===
                    ENGLISH_SUPPORT_VERSION_2
                      ? onChangeLanguage(LANGUAGE_CODE_EN)
                      : onEngSupportClick()
                  }>
                  <img src={ENFlag} className="language-icon" alt="languages" />
                  <span className="language-text">
                    {t(englishLangTextShort)}
                  </span>
                </div>
              )}
              {Utils.isShowHeaderNotification(notifications) && (
                <div
                  className="notification-wrapper"
                  onClick={() => onNotificationClicked()}>
                  <img
                    src={BellIcon}
                    className="notification-icon"
                    alt="notifications"
                  />
                  {notifications?.totalUnreadCount > 0 && (
                    <span className="notification-count">
                      {notifications?.totalUnreadCount}
                    </span>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div
              className="change-az-language-button"
              onClick={() => setIsBottomSheetOpen(true)}>
              <span>{t(changeShipmentCountry)}</span>
              <Chevron3 />
            </div>
          )}
        </div>
      ) : (
        <span
          className="back-button"
          onClick={() => setIsOpenNotification(false)}>
          <LeftArrow />
        </span>
      )}

      <BottomSheet
        isOpen={isBottomSheetOpen}
        title={t(delivery)}
        className="country-change-bottomsheet"
        onClose={() => setIsBottomSheetOpen(false)}>
        <div className="bottomsheet-body">
          {COUNTRY_LIST.filter((country) =>
            (languages || []).includes(country.countryCode)
          ).map((country) => (
            <div
              key={country.countryCode}
              className="country-item"
              onClick={() => onChangeLanguage(country.countryCode)}>
              <div className="country-select-item">
                <img
                  src={country?.rectFlag}
                  alt="country-flag"
                  className="country-flag"
                />
                <div className="country-body">
                  <span className="country-name">{t(country.name) || '-'}</span>
                  <span className="country-description">
                    {t(country.description)}
                  </span>
                </div>
              </div>
              {country.countryCode === i18n.language && (
                <Check2 stroke="#f27a1a" />
              )}
            </div>
          ))}
        </div>
      </BottomSheet>
    </div>
  );
};

export default AzCountryChange;
