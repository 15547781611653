import './GoDeliveredProduct.scss';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { claimOrderClaimableIsNotDone, quantity } from '../../../../i18n/keys';
import FileInput from '../../fileInput/FileInput';
import Alert from '../../../layout/alert/Alert';
import {
  GO_DELIVERED_PRODUCT_IMAGE_UPLOAD_ACCEPTED_FORMATS,
  GO_DELIVERED_PRODUCT_RECEIPT_ACCEPTED_FORMATS,
} from '../../../../constants';
import usePersistentState from '../../../common/usePersistentState';
import ReactHtmlParser from 'react-html-parser';
import appService from '../../../../services/app.service';
import TextArea from '../../../common/TextArea';
import ClaimInfo from '../../../common/ClaimInfo';
import Select from '../../../Select';
import Checkbox from '@tx-components/checkbox';

const GoDeliveredProduct = ({
  id,
  productId,
  productQuantity,
  productImageUrl,
  imageWarningMessage,
  productName,
  lineItemIds,
  receiptImagePlaceHolderText,
  productImagePlaceholderText,
  descriptionPlaceholderText,
  onChangeSelectedItem,
  onChangeDoneItem,
  reasons = [],
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = usePersistentState(id, 'selected', false);
  const [selectedQuantity, setSelectedQuantity] = usePersistentState(
    id,
    'selectedQuantity'
  );
  const [reason, setReason] = usePersistentState(id, 'reason');
  const [reasonId, setReasonId] = usePersistentState(id, 'reasonId');
  const [reasonDetailId, setReasonDetailId] = usePersistentState(
    id,
    'reasonDetailId'
  );
  const [description, setDescription] = usePersistentState(
    id,
    'description',
    ''
  );
  const [quantityList, setQuantityList] = usePersistentState(id, []);
  const [isDone, setIsDone] = usePersistentState(id, 'quantityList', false);
  const [productImages, setProductImages] = usePersistentState(
    id,
    'productImages',
    []
  );
  const [receiptImages, setReceiptImages] = usePersistentState(
    id,
    'receiptImages',
    []
  );
  const [imageUploadErrors, setImageUploadErrors] = usePersistentState(
    id,
    'imageUploadErrors',
    []
  );

  useEffect(() => {
    const newQuantityList = [];
    for (let i = 0; i < productQuantity; i++) {
      newQuantityList.push(i + 1);
    }
    setQuantityList(newQuantityList);
  }, [productQuantity]);

  useEffect(() => {
    if (productQuantity === 1) {
      setSelectedQuantity(1);
    }
  }, []);

  useEffect(() => {
    const isReasonValid =
      !reasons || reasons.length === 0 || (reasonId && isReasonDetailIdDone());

    const done =
      selectedQuantity && isReasonValid && description && productImages.length;
    setIsDone(done);

    onChangeDoneItem({
      productId,
      productName,
      reasonId,
      reasonDetailId,
      quantity: selectedQuantity,
      lineItemIds,
      description,
      done,
      productImages,
      receiptImages,
    });
  }, [
    selectedQuantity,
    description,
    reasonId,
    reasonDetailId,
    productImages,
    receiptImages,
    imageUploadErrors,
  ]);

  const isReasonDetailIdDone = () => {
    return (
      !reason.ticketReasonDetailModel ||
      !reason.ticketReasonDetailModel.rendered ||
      (reason.ticketReasonDetailModel && reasonDetailId)
    );
  };

  useEffect(() => {
    if (selected) {
      if (reasons && reasons.length === 1) {
        setReason(reasons[0]);
        setReasonId(reasons[0].id);
      }
    }
  }, [selected]);

  const resetState = () => {
    setSelectedQuantity(1);
    setDescription('');
  };

  const onChangeSelected = (checked) => {
    setSelected(checked);
    onChangeSelectedItem(productId, checked);
    if (!checked) {
      resetState();
    }

    if (checked && productQuantity === 1) {
      setSelectedQuantity(1);
    }
  };

  const onChangeReasonDetail = (reasonDetailId) => {
    setReasonDetailId(reasonDetailId);
  };

  const onClickHeader = () => {
    onChangeSelected(!selected);
  };

  const onChangeQuantity = (value) => {
    setSelectedQuantity(value);
  };

  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const onProductImagesChange = (images) => {
    setProductImages(images.map((image) => image.response.data));
  };

  const onReceiptImagesChange = (images) => {
    setReceiptImages(images.map((image) => image.response.data));
  };

  const onImageUploadError = (errors) => {
    setImageUploadErrors(errors);
  };

  return (
    <div
      data-testid="delivered-product"
      className={classnames(['delivered-product', { selected, done: isDone }])}>
      <div className="delivered-product-header" onClick={onClickHeader}>
        <Checkbox
          data-testid="delivered-product-checkbox"
          onChange={onChangeSelected}
          checked={selected}
        />
        <div className="delivered-product-image">
          <img src={productImageUrl} alt="product-avatar" />
        </div>
        <div className="delivered-product-title-container">
          <div className="delivered-product-title" title={productName}>
            {productName}
          </div>
          {selected && (
            <div
              className={classnames([
                'delivered-product-subtitle',
                { done: isDone },
              ])}>
              {!isDone && t(claimOrderClaimableIsNotDone)}
            </div>
          )}
        </div>
      </div>
      {selected && (
        <div className="delivered-product-body">
          <div className="delivered-product-row">
            <Select
              placeholder={t(quantity)}
              onChange={onChangeQuantity}
              value={selectedQuantity}
              options={quantityList.map((quantityItem) => ({
                label: `${quantityItem} ${t(quantity)}`,
                value: quantityItem,
              }))}
            />
          </div>
          {reason &&
            reason.ticketReasonDetailModel &&
            reason.ticketReasonDetailModel.rendered && (
              <div className="delivered-product-row">
                <Select
                  placeholder={reason.ticketReasonDetailModel.placeHolder}
                  onChange={onChangeReasonDetail}
                  value={reasonDetailId}
                  disabled={
                    reason.ticketReasonDetailModel.items &&
                    reason.ticketReasonDetailModel.items.length === 1
                  }
                  options={reason.ticketReasonDetailModel.items.map(
                    (reasonDetail) => ({
                      value: reasonDetail.id,
                      label: reasonDetail.name,
                    })
                  )}
                />
              </div>
            )}
          {imageWarningMessage && (
            <div className="delivered-product-row">
              <ClaimInfo text={ReactHtmlParser(imageWarningMessage)} />
            </div>
          )}
          <div className="delivered-product-row">
            <FileInput
              id={id + 'FileInput'}
              placeholder={productImagePlaceholderText}
              onImagesChange={onProductImagesChange}
              onError={onImageUploadError}
              maxFileCount={3}
              useCdn={true}
              accept={GO_DELIVERED_PRODUCT_IMAGE_UPLOAD_ACCEPTED_FORMATS}
              isRequired={true}
              uploadVersion={appService.getClaimUploadTargetVersion()}
            />
          </div>
          <div className="delivered-product-row">
            <FileInput
              id={id + 'FileInput2'}
              placeholder={receiptImagePlaceHolderText}
              onImagesChange={onReceiptImagesChange}
              onError={onImageUploadError}
              maxFileCount={3}
              useCdn={true}
              accept={GO_DELIVERED_PRODUCT_RECEIPT_ACCEPTED_FORMATS}
              uploadVersion={appService.getClaimUploadTargetVersion()}
            />
          </div>
          {!!imageUploadErrors.length && (
            <div className="delivered-product-row">
              <Alert messages={imageUploadErrors} />
            </div>
          )}
          <div className="delivered-product-row">
            <TextArea
              data-testid="delivered-product-description"
              placeholder={descriptionPlaceholderText}
              value={description}
              required
              maxLength="500"
              onChange={onChangeDescription}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GoDeliveredProduct;
