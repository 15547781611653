import React from 'react';
import { ReactComponent as Accepted } from '../../../assets/icons/status/accepted.svg';
import { ReactComponent as Appointed } from '../../../assets/icons/status/appointed.svg';
import { ReactComponent as Assistantcancelclaim } from '../../../assets/icons/status/assistantcancelclaim.svg';
import { ReactComponent as Assistantclaimcompleted } from '../../../assets/icons/status/assistantclaimcompleted.svg';
import { ReactComponent as Assistantinclaim } from '../../../assets/icons/status/assistantinclaim.svg';
import { ReactComponent as Atcollectionpoint } from '../../../assets/icons/status/atcollectionpoint.svg';
import { ReactComponent as Completed } from '../../../assets/icons/status/completed.svg';
import { ReactComponent as Created } from '../../../assets/icons/status/created.svg';
import { ReactComponent as Delivered } from '../../../assets/icons/status/delivered.svg';
import { ReactComponent as Failed } from '../../../assets/icons/status/failed.svg';
import { ReactComponent as Finished } from '../../../assets/icons/status/finished.svg';
import { ReactComponent as Fulfilled } from '../../../assets/icons/status/fulfilled.svg';
import { ReactComponent as Preparing } from '../../../assets/icons/status/preparing.svg';
import { ReactComponent as Ready_to_shipment } from '../../../assets/icons/status/ready_to_shipment.svg';
import { ReactComponent as Rejected } from '../../../assets/icons/status/rejected.svg';
import { ReactComponent as Returned } from '../../../assets/icons/status/returned.svg';
import { ReactComponent as Scheduledcreated } from '../../../assets/icons/status/scheduledcreated.svg';
import { ReactComponent as Shipped } from '../../../assets/icons/status/shipped.svg';
import { ReactComponent as Uncompleted } from '../../../assets/icons/status/uncompleted.svg';
import { ReactComponent as Undelivered } from '../../../assets/icons/status/undelivered.svg';
import { ReactComponent as Cancelled } from '../../../assets/icons/status/cancelled.svg';
import { ReactComponent as AtPickupPoint } from '../../../assets/icons/status/at_pickup_point.svg';
import { INTERNATIONAL_SUFFIX } from '../../../constants';
import appService from '../../../services/app.service';

const iconTypes = {
  accepted: Accepted,
  appointed: Appointed,
  assistantcancelclaim: Assistantcancelclaim,
  assistantclaimcompleted: Assistantclaimcompleted,
  assistantinclaim: Assistantinclaim,
  atcollectionpoint: Atcollectionpoint,
  completed: Completed,
  created: Created,
  delivered: Delivered,
  failed: Failed,
  finished: Finished,
  fulfilled: Fulfilled,
  preparing: Preparing,
  ready_to_shipment: Ready_to_shipment,
  rejected: Rejected,
  returned: Returned,
  scheduledcreated: Scheduledcreated,
  shipped: Shipped,
  uncompleted: Uncompleted,
  undelivered: Undelivered,
  cancelled: Cancelled,
  atcollectionpointinternational: AtPickupPoint,
};

const StatusIconComponent = ({ name, ...props }) => {
  let Icon = iconTypes[name];

  if (
    appService.isInternational() &&
    iconTypes[name + INTERNATIONAL_SUFFIX] !== undefined
  ) {
    Icon = iconTypes[name + INTERNATIONAL_SUFFIX];
  }

  if (Icon === undefined) {
    Icon = iconTypes['created'];
  }

  return <Icon {...props} />;
};

export default StatusIconComponent;
