import React from 'react';
import './DaySelection.scss';
import * as PropTypes from 'prop-types';
import Utils from '../../../utils';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import usePersistentState from '../../common/usePersistentState';
import classNames from 'classnames';
import { BUTTON_PRIMARY } from '../../../constants';
import Bubble from '../../layout/bubble/Bubble';

const DaySelection = (data) => {
  const { id, componentData, showLabel = true, select } = data;
  const [selectedSlotId, setSelectedSlotId] = usePersistentState(
    id,
    'selectedSlotId',
    ''
  );

  const selectedButton = (button) => {
    if (data.showHover && isNotPrimaryOrSlotIsSelected(button.buttonType)) {
      let selectData = {
        data: {
          selectedSlotId,
        },
        event: button.event,
      };
      select(selectData);
    }
  };

  function selectSlot(id) {
    setSelectedSlotId(id);
  }

  function isNotPrimaryOrSlotIsSelected(buttonType) {
    return (
      buttonType !== BUTTON_PRIMARY ||
      (buttonType === BUTTON_PRIMARY && selectedSlotId !== '')
    );
  }

  function renderButtons() {
    return (
      <ButtonGroup>
        {componentData &&
          componentData.buttons.map((button, index) => {
            const buttonType = button.buttonType;
            let colorType = Utils.getColorType(buttonType);
            let outline = button.outline !== false;

            return (
              <ButtonGroup.Button
                key={button.text}
                isSelectable={
                  data.showHover && isNotPrimaryOrSlotIsSelected(buttonType)
                }
                data-testid="day-selection"
                className="day-selection-container"
                outline={outline}
                colorType={colorType}
                iconLeft={Utils.getButtonIcon(buttonType, button.iconUrl)}
                onClick={() => selectedButton(button, index)}
                disabled={
                  buttonType === BUTTON_PRIMARY && selectedSlotId === ''
                }>
                {button.text}
              </ButtonGroup.Button>
            );
          })}
      </ButtonGroup>
    );
  }

  return (
    <div className="day-selections" data-testid="day-selections">
      <Bubble
        isBottomRounded={false}
        titleFragment={
          componentData.label &&
          showLabel &&
          Utils.newLineToBreak(componentData.label)
        }>
        <div className="content-wrapper">
          <div className="address-container">
            <div className="address-label">{componentData.addressLabel}</div>
            <div className="address-info">{componentData.address}</div>
          </div>
          <div className="day-container">
            <div className="days-label">{componentData.daysLabel}</div>
            <div className="days">
              {componentData.days.map((dayItem) => {
                return (
                  <div
                    className={classNames({
                      'day-item': true,
                      selected: selectedSlotId === dayItem.slotId,
                      'not-eligible': !dayItem.eligible,
                    })}
                    onClick={() =>
                      data.showHover &&
                      dayItem.eligible &&
                      selectSlot(dayItem.slotId)
                    }>
                    <div className="day-date">{dayItem.date}</div>
                    <div
                      className={classNames({
                        'day-name': true,
                      })}>
                      {dayItem.eligible
                        ? dayItem.dayName
                        : dayItem.eligibleNote}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {componentData.buttons && renderButtons()}
      </Bubble>
    </div>
  );
};

DaySelection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DaySelection;
