import './BottomSheet.scss';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-v2.svg';
import { createPortal } from 'react-dom';

const BottomSheet = ({
  title,
  isOpen,
  onClose,
  children,
  onBackButtonClick,
  onCloseButtonClick,
  className = '',
}) => {
  return createPortal(
    <div
      className={classNames('bottomsheet-backdrop', {
        active: isOpen,
        [className]: !!className,
      })}
      onClick={onClose}>
      <div className="bottom-sheet" onClick={(e) => e.stopPropagation()}>
        <div className="bottom-sheet-header">
          <div>
            {onBackButtonClick && (
              <ArrowIcon onClick={onBackButtonClick} className="back-icon" />
            )}
          </div>
          <span className="title">{title}</span>
          <div className="close-icon">
            {onCloseButtonClick && (
              <CloseIcon onClick={onCloseButtonClick} className="arrow-icon" />
            )}
          </div>
        </div>
        <div className="body">{children}</div>
      </div>
    </div>,
    document.getElementById('root')
  );
};

export default BottomSheet;
